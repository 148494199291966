import s from "../steps.module.scss";
import cn from "classnames";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";

import Form from "../../Form";
import Button from "../../Button/Button";
import {
  setAddStep,
  setAddData,
} from "../../../../../store/reducer/lk/form/slice";
import { selectLkForm } from "../../../../../store/reducer/lk/form/selector";

const Step3 = () => {
  const [width, setWidth] = useState(window?.innerWidth);
  const dispatch = useDispatch();
  const { data, step } = useSelector(selectLkForm);

  useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

  useEffect(() => {
    localStorage.setItem("stepLk", JSON.stringify(step));
  }, []);

  const schema = yup
    .object({
      ["Опыт работы на руководящих должностях"]: yup.string().required(),
      ["Есть опыт работы в It-компаниях?"]: yup.string().required(),
      ["Есть предпринимательский опыт?"]: yup.string().required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ["Опыт работы на руководящих должностях"]:
        data["Опыт работы на руководящих должностях"],
      ["Есть опыт работы в It-компаниях?"]:
        data["Есть опыт работы в It-компаниях?"],
      ["Есть предпринимательский опыт?"]:
        data["Есть предпринимательский опыт?"],
    },
  });

  const onSubmit = (data) => {
    dispatch(setAddStep(4));
    dispatch(setAddData(data));

    localStorage.setItem("data", JSON.stringify(data));
    localStorage.setItem("stepLk", JSON.stringify(4));
  };

  return (
    <div className={s.step3}>
      <Form onSubmit={handleSubmit(onSubmit)}>
       <div> 
        <div className={s.form_item}>
          <h4 className={s.form_title}>
            Опыт работы на руководящих должностях
          </h4>

          <div className={s.experience}>
            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.experience,
                })}
                {...register("Опыт работы на руководящих должностях")}
                id="experience1"
                type="radio"
                name="Опыт работы на руководящих должностях"
                autoComplete="off"
                value="Нет"
              />

              <label htmlFor="experience1" className={s.label}>
                Нет
              </label>
            </div>

            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.experience,
                })}
                {...register("Опыт работы на руководящих должностях")}
                id="experience2"
                type="radio"
                name="Опыт работы на руководящих должностях"
                autoComplete="off"
                value="Менее года"
              />
              <label htmlFor="experience2" className={s.label}>
                Менее года
              </label>
            </div>

            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.experience,
                })}
                {...register("Опыт работы на руководящих должностях")}
                id="experience3"
                type="radio"
                name="Опыт работы на руководящих должностях"
                autoComplete="off"
                value="От года до трех лет"
              />
              <label htmlFor="experience3" className={s.label}>
                От года до трех лет
              </label>
            </div>

            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.experience,
                })}
                {...register("Опыт работы на руководящих должностях")}
                id="experience4"
                type="radio"
                name="Опыт работы на руководящих должностях"
                autoComplete="off"
                value="Больше трех лет"
              />
              <label htmlFor="experience4" className={s.label}>
                Больше трех лет
              </label>
            </div>
          </div>
          <p className={s.errorText}>
            {errors["Опыт работы на руководящих должностях"]
              ? "Необходимо указать"
              : ""}
          </p>
        </div>

        <div className={s.form_item}>
          <h4 className={s.form_title}>Есть опыт работы в It-компаниях?</h4>

          <div className={s.education}>
            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.experience,
                })}
                {...register("Есть опыт работы в It-компаниях?")}
                id="experience5"
                type="radio"
                name="Есть опыт работы в It-компаниях?"
                autoComplete="off"
                value="Нет"
              />

              <label htmlFor="experience5" className={s.label}>
                Нет
              </label>
            </div>

            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.experience,
                })}
                {...register("Есть опыт работы в It-компаниях?")}
                id="experience6"
                type="radio"
                name="Есть опыт работы в It-компаниях?"
                autoComplete="off"
                value="Да, как сотрудник"
              />
              <label htmlFor="experience6" className={s.label}>
                Да, как сотрудник
              </label>
            </div>

            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.experience,
                })}
                {...register("Есть опыт работы в It-компаниях?")}
                id="experience7"
                type="radio"
                name="Есть опыт работы в It-компаниях?"
                autoComplete="off"
                value="Да, как руководитель"
              />
              <label htmlFor="experience7" className={s.label}>
                Да, как руководитель
              </label>
            </div>
          </div>
          <p className={s.errorText}>
            {errors["Есть опыт работы в It-компаниях?"]
              ? "Необходимо указать"
              : ""}
          </p>
        </div>

        <div className={s.form_item}>
          <h4 className={s.form_title}>Есть предпринимательский опыт?</h4>

          <div className={s.experience}>
            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.experience,
                })}
                {...register("Есть предпринимательский опыт?")}
                id="experience8"
                type="radio"
                name="Есть предпринимательский опыт?"
                autoComplete="off"
                value="Нет"
              />

              <label htmlFor="experience8" className={s.label}>
                Нет
              </label>
            </div>

            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.experience,
                })}
                {...register("Есть предпринимательский опыт?")}
                id="experience9"
                type="radio"
                name="Есть предпринимательский опыт?"
                autoComplete="off"
                value="Да"
              />
              <label htmlFor="experience9" className={s.label}>
                Да
              </label>
            </div>
          </div>
          <p className={s.errorText}>
            {errors["Есть предпринимательский опыт?"]
              ? "Необходимо указать"
              : ""}
          </p>
        </div>
      </div>
     

        <div className={s.btns}>
          <Button callback={() => dispatch(setAddStep(2))} back>
             {width > 575 ? 'Назад' : <img src='/static/lk/IconBackward.svg' alt='назад' />}
          </Button>
          <Button>Далее 3 из 8</Button>
        </div>
      </Form>

      <img
        className={s.illustration}
        src="/static/lk/illustration/illustration.png"
        alt="Иллюстрация"
      />
    </div>
  );
};

export default Step3;
