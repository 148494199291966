import cn from 'classnames';
import s from '../steps.module.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import {
	setAddData,
	setAddStep,
} from '../../../../../store/reducer/lk/formExam/slice';

import { selectLkFormExam } from '../../../../../store/reducer/lk/formExam/selector';
import Button from '../../Button/Button';
import Form from '../../Form';

const Step9 = () => {
	const [width, setWidth] = useState(window?.innerWidth);
	const dispatch = useDispatch();
	const { data, step } = useSelector(selectLkFormExam);
	const [isError25, setError25] = useState(false);
    const [isError27, setError27] = useState(false);
	const [isSendForm, setIsSendForm] = useState(false);

	useEffect(() => {
		localStorage.setItem('stepLkExam', JSON.stringify(step));
		localStorage.setItem('dataExam', JSON.stringify(data));
	}, []);

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

	const schema26 = yup
		.object({
			['Какие модули в системе являются обязательными для создания?']: yup.string(),
		})
		.required();

	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
		watch,
	} = useForm({
		resolver: yupResolver(schema26),
		defaultValues: {
			['Каких офисных сотрудников необходимо нанять в штат?']: data['Каких офисных сотрудников необходимо нанять в штат?'],
			['Какие модули в системе являются обязательными для создания?']: data['Какие модули в системе являются обязательными для создания?'],
			['С каких источников могут прийти клиенты ?']: data['С каких источников могут прийти клиенты ?'],
		},
	});

	
	const ckbox25 = watch('Каких офисных сотрудников необходимо нанять в штат?');
    const ckbox27 = watch('С каких источников могут прийти клиенты ?');
	
	useEffect(() => {
		if (!ckbox25?.length) {
			setError25(true);
		} else {
			setError25(false);
		}
	}, [ckbox25]);

    useEffect(() => {
		if (!ckbox27?.length) {
			setError27(true);
		} else {
			setError27(false);
		}
	}, [ckbox27]);

	const onSubmit = data => {
		setIsSendForm(true);

		if (!isError25 && !isError27) {
			dispatch(setAddStep(10));
			dispatch(setAddData(data));
			setIsSendForm(false);

			localStorage.setItem('dataExam', JSON.stringify(data));
			localStorage.setItem('stepLkExam', JSON.stringify(9));
		}
	};

	return (
		<div className={s.step9}>
      <div className={s.wrapper_heading}>
			  <h5>Роли. Директор. Skilla</h5>
			</div>
			<Form onSubmit={handleSubmit(onSubmit)}>

                <div className={s.form_item}>
					<h4 className={s.form_title}>
					25. Каких офисных сотрудников необходимо нанять в штат?
					</h4>

					<div className={s.question25}>
						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question25,
								})}
								{...register('Каких офисных сотрудников необходимо нанять в штат?')}
								id='exam251'
								type='checkbox'
								name='Каких офисных сотрудников необходимо нанять в штат?'
								autoComplete='off'
								value='Менеджер по работе с клиентами/Бухгалтер'/>
							<label htmlFor='exam251' className={s.label}>
							Менеджер по работе с клиентами/Бухгалтер
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question25,
								})}
								{...register('Каких офисных сотрудников необходимо нанять в штат?')}
								id='exam252'
								type='checkbox'
								name='Каких офисных сотрудников необходимо нанять в штат?'
								autoComplete='off'
								value='Программист'/>
							<label htmlFor='exam252' className={s.label}>
							Программист
							</label>
						</div>

                        <div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question25,
								})}
								{...register('Каких офисных сотрудников необходимо нанять в штат?')}
								id='exam253'
								type='checkbox'
								name='Каких офисных сотрудников необходимо нанять в штат?'
								autoComplete='off'
								value='Менеджер по персоналу'/>
							<label htmlFor='exam253' className={s.label}>
							Менеджер по персоналу
							</label>
						</div>

                        <div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question25,
								})}
								{...register('Каких офисных сотрудников необходимо нанять в штат?')}
								id='exam254'
								type='checkbox'
								name='Каких офисных сотрудников необходимо нанять в штат?'
								autoComplete='off'
								value='Главный бухгалтер'/>
							<label htmlFor='exam254' className={s.label}>
							Главный бухгалтер
							</label>
						</div>

						<p className={s.errorText2}>
							{isError25 &&
								isSendForm &&
								'Необходимо указать'}
						</p>
					</div>
				</div>

				<div className={s.form_item}>
					<h4 className={s.form_title}>26. Какие модули в системе являются обязательными для создания?</h4>

					<div className={s.question26}>
						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question26,
								})}
								{...register('Какие модули в системе являются обязательными для создания?')}
								id='exam261'
								type='radio'
								name='Какие модули в системе являются обязательными для создания?'
								autoComplete='off'
								value='Менеджер по персоналу и Оператор'
							/>

							<label htmlFor='exam261' className={s.label}>
							Менеджер по персоналу и Оператор
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question26,
								})}
								{...register('Какие модули в системе являются обязательными для создания?')}
								id='exam262'
								type='radio'
								name='Какие модули в системе являются обязательными для создания?'
								autoComplete='off'
								value='Бухгалтер и Оператор'
							/>

							<label htmlFor='exam262' className={s.label}>
							Бухгалтер и Оператор
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question26,
								})}
								{...register('Какие модули в системе являются обязательными для создания?')}
								id='exam263'
								type='radio'
								name='Какие модули в системе являются обязательными для создания?'
								autoComplete='off'
								value='Бухгалтер и Менеджер по персоналу'
							/>

							<label htmlFor='exam263' className={s.label}>
							Бухгалтер и Менеджер по персоналу
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question26,
								})}
								{...register('Какие модули в системе являются обязательными для создания?')}
								id='exam264'
								type='radio'
								name='Какие модули в системе являются обязательными для создания?'
								autoComplete='off'
								value='HR и Бухгалтер'
							/>

							<label htmlFor='exam264' className={s.label}>
							HR и Бухгалтер
							</label>
						</div>   
					</div>

                    <p className={s.errorText}>
						{errors['Какие модули в системе являются обязательными для создания?'] ? 'Необходимо указать' : ''}
					</p>
                </div>

                <div className={s.form_item}>
					<h4 className={s.form_title}>
					27. С каких источников могут прийти клиенты ?
					</h4>

					<div className={s.question27}>
						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question27,
								})}
								{...register('С каких источников могут прийти клиенты ?')}
								id='exam271'
								type='checkbox'
								name='С каких источников могут прийти клиенты ?'
								autoComplete='off'
								value='Контекстная реклама и СЕО'/>
							<label htmlFor='exam271' className={s.label}>
							Контекстная реклама и СЕО
							</label>
						</div>

                        <div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question27,
								})}
								{...register('С каких источников могут прийти клиенты ?')}
								id='exam272'
								type='checkbox'
								name='С каких источников могут прийти клиенты ?'
								autoComplete='off'
								value='Холодный поиск'/>
							<label htmlFor='exam272' className={s.label}>
							Холодный поиск
							</label>
						</div>

                        <div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question27,
								})}
								{...register('С каких источников могут прийти клиенты ?')}
								id='exam273'
								type='checkbox'
								name='С каких источников могут прийти клиенты ?'
								autoComplete='off'
								value='Тендеры'/>
							<label htmlFor='exam273' className={s.label}>
							Тендеры
							</label>
						</div>

                        <div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question27,
								})}
								{...register('С каких источников могут прийти клиенты ?')}
								id='exam274'
								type='checkbox'
								name='С каких источников могут прийти клиенты ?'
								autoComplete='off'
								value='Клиентов искать не нужно'/>
							<label htmlFor='exam274' className={s.label}>
							Клиентов искать не нужно
							</label>
						</div>

                        <div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question27,
								})}
								{...register('С каких источников могут прийти клиенты ?')}
								id='exam275'
								type='checkbox'
								name='С каких источников могут прийти клиенты ?'
								autoComplete='off'
								value='Социальные сети (VK, Одноклассники)'/>
							<label htmlFor='exam275' className={s.label}>
							Социальные сети (VK, Одноклассники)
							</label>
						</div>

                        <div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question27,
								})}
								{...register('С каких источников могут прийти клиенты ?')}
								id='exam276'
								type='checkbox'
								name='С каких источников могут прийти клиенты ?'
								autoComplete='off'
								value='Радио и телевидение'/>
							<label htmlFor='exam276' className={s.label}>
							Радио и телевидение
							</label>
						</div>

						<p className={s.errorText2}>
							{isError27 &&
								isSendForm &&
								'Необходимо указать'}
						</p>
					</div>
				</div>

                

				<div className={s.btns}>
        <Button callback={() => dispatch(setAddStep(8))} back>
						{width > 575 ? (
							'   Назад'
						) : (
							<img src='/static/lk/IconBackward.svg' alt='назад' />
						)}
					</Button>
					<Button>Далее 9 из 35</Button>
				</div>
			</Form>

			<img
				className={s.illustration}
				src='/static/lk/illustration/illustration.png'
				alt='Иллюстрация'
			/>
		</div>
	);
};

export default Step9;
