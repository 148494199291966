import React, { useEffect } from 'react';
import styled from 'styled-components';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import HeaderPoliticApp from '../components/HeaderPoliticApp';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const PoliticApp = ({ activeTarget }) => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const changeStyleHeader = () => {
    switch (activeTarget) {
      case 'customer':
        return (
          <Navbar page="customer" activeTarget={activeTarget} type="article" activePage="article" />
        );
      case 'business':
        return (
          <Navbar page="business" activeTarget={activeTarget} type="article" activePage="article" />
        );
      case 'worker':
        return (
          <Navbar page="worker" activeTarget={activeTarget} type="article" activePage="article" />
        );
    }
  };

  const changeBackground = () => {
    switch (activeTarget) {
      case 'customer':
        return <HeaderPoliticApp activeTarget="customer" />;
      case 'business':
        return <HeaderPoliticApp activeTarget="business" />;
      case 'worker':
        return <HeaderPoliticApp activeTarget="worker" />;
    }
  };

  const changeStyleFooter = () => {
    switch (activeTarget) {
      case 'customer':
        return (
          <Footer page="customer" activeTarget={activeTarget} type="faq" activePage="article" />
        );
      case 'business':
        return (
          <Footer page="business" activeTarget={activeTarget} type="faq" activePage="article" />
        );
      case 'worker':
        return <Footer page="worker" activeTarget={activeTarget} type="faq" activePage="article" />;
    }
  };

  return (
    <StyledCustomer>
      {changeStyleHeader()}
      {changeBackground()}
      {changeStyleFooter()}
    </StyledCustomer>
  );
};

const StyledCustomer = styled.div`
  background-color: #000729;

  .header {
    background: radial-gradient(55.7% 97% at 0% 3%, #000e50 21.6%, rgba(0, 23, 134, 0) 80.42%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(20.81% 43.51% at 87.01% 11.68%, #001fb2 0%, rgba(0, 23, 134, 0) 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(66.95% 140.01% at 66.95% 90.11%, #000e50 0%, #001786 42.05%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    mix-blend-mode: normal;
    min-height: 100vh;
    position: relative;
    margin: auto;
    padding-bottom: 100px;

    &--business {
      background: radial-gradient(
          41.09% 72.88% at 10.68% 15.98%,
          #00341c 0%,
          rgba(0, 91, 48, 0) 100%
        ),
        radial-gradient(37.63% 76.37% at 100% 0%, #007e43 0%, rgba(0, 91, 48, 0) 100%),
        radial-gradient(79.17% 174.71% at 79.17% 78.17%, #00361c 0%, #006636 100%);
      mix-blend-mode: normal;
    }

    &--worker {
      background: radial-gradient(63.02% 48.11% at 50% 23.37%, #4c0f7b 0%, rgba(85, 20, 142, 0) 100%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(22.68% 53.3% at 96.51% -3.22%, #4c1279 0%, rgba(191, 119, 255, 0) 100%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(31.72% 67.58% at -2.76% 107.52%, #ffe9c0 0%, rgba(123, 34, 197, 0) 100%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(45.31% 97.19% at -0.62% 55.77%, #cc79ff 0%, rgba(123, 34, 197, 0) 100%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(70.6% 284.26% at 81.04% 82.79%, #2f0058 0%, #8800ff 100%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    }
  }

  .header__content {
    position: relative;
    padding-top: calc(50vh - 330px);
  }

  .header__title {
    font-size: 54px;
    max-width: 690px;
    line-height: 120%;
    font-weight: 800;
    color: #fff;
    margin-bottom: 25px;
  }

  .header__description {
    font-size: 22px;
    line-height: 120%;
    color: #e3e4e8;
    font-weight: 500;

    & span {
      display: block;
    }
  }

  .header__info {
    font-size: 14px;
    color: #786c6c99;
    padding: 35px 0;

    & a {
      color: #786c6c99;
      transition: color 0.3s ease;
      text-decoration: underline;
      margin-left: 5px;

      &:hover {
        color: #97a8ff;
      }
    }
  }

  @media (max-width: 1905px) {
    .header__image {
      width: 50vw;
    }
  }

  @media (max-height: 1020px) {
    .header__content {
      padding-top: 200px;
    }
  }

  @media screen and (max-width: 1600px) {
    .container {
      width: 1200px;
    }

    .scrollnav__links {
      gap: 25px !important;
    }

    .footer-header__image {
      position: absolute;
      right: 20px;
      bottom: 70px;
    }
  }

  @media screen and (max-width: 768px) {
    .container {
      max-width: 343px;
      width: 100%;
      margin: 0 auto;
    }

    .header {
      padding-top: 150px;
    }

    & .header__content {
      padding: 0 0 40px;
    }

    & .header {
      padding: 150px 0 0 0 !important;
      min-height: auto !important;
    }

    .header__title {
      font-size: 30px;
      line-height: 120%;
      margin-bottom: 16px;
    }

    .header__description {
      font-size: 18px;
    }

    .header__info {
      padding: 0;
      font-size: 13px;
    }

    .container_smail {
      width: 315px;
    }

    & .footer-header__title {
      margin-bottom: 30px;
    }

    & .footer-header__image {
      width: 100%;
      position: static;
      margin-left: 0;
    }
  }
`;

export default PoliticApp;
