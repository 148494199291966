import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { sendForm } from '../../../../Api';
import RadioGroup from '../../../../components/RadioGroup';
import Spinner from '../../../../components/SpinnerSmall';
import { selectForm } from '../../../../store/reducer/form/selector.js';
import {
	setAddIdCopy,
	setAddPhoneSave,
} from '../../../../store/reducer/form/slice.js';
import { selectMain } from '../../../../store/reducer/main/selector.js';
import s from './formSmall.module.scss';

const FormSmall = () => {
	const [width, setWidth] = useState(window?.innerWidth);
	const [isLoading, setIsLoading] = useState(false);
	const { activeLink, utm } = useSelector(selectMain);
	const { id, idCopy, phoneSave, phoneFromBookFooter } =
		useSelector(selectForm);
	const { pageBook } = useSelector(selectMain);

	const dispatch = useDispatch();

	const phoneRegExp = /^\+7 \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}$/;

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.scroll(0, 0);
		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

	const schema = yup.object({
		number: yup.string().matches(phoneRegExp).required(),
	});

	const {
		register,
		handleSubmit,
		reset,

		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	const fetchDate = async number => {
		setIsLoading(true);

		dispatch(setAddPhoneSave(number));
		localStorage.setItem('phoneSave', JSON.stringify(number));

		try {
			const { data } = await sendForm(number, 'book', '', null, id, utm);

			if (data.result === 'success') {
				readSnippet();
				dispatch(setAddIdCopy(id));
				reset();

				if (id !== idCopy) {
					window.ym(90102156, 'reachGoal', 'submitting');
				}
			}
		} catch (e) {
			console.log(e);
			setIsLoading(true);
		}
	};

	const onSubmit = ({ number }) => {
		if (!phoneSave && !phoneFromBookFooter) {
			fetchDate(number);
		} else {
			readSnippet();
		}
	};

	const readSnippet = () => {
		window.open('https://skilla.ru/docs/book_demo.pdf', '_blank');

		setIsLoading(false);
	};

	return !isLoading ? (
		<>
			<form
				onSubmit={handleSubmit(onSubmit)}
				className={cn(s.form, {
					[s.form_transform]: phoneSave || phoneFromBookFooter,
				})}
			>
				<div className={s.wrapper}>
					<ReactInputMask
						className={cn(s.input, {
							[s.input_error]: errors.number,
							[s.input_hidden]: phoneSave || phoneFromBookFooter,
						})}
						{...register('number')}
						name='number'
						mask='+7 (\999) 999-99-99'
						maskChar=''
						alwaysShowMask={false}
						placeholder='+7 (___) ___-__-__'
						autoComplete='off'
						defaultValue={phoneFromBookFooter || phoneSave}
					/>

					<button
						className={cn(s.btn, {
							[s.btn_customer]: pageBook
								? pageBook === 'customer'
								: window.location.pathname.substring(1) === 'book',
							[s.btn_business]: pageBook === 'business',
							[s.btn_worker]: pageBook === 'worker',
							[s.btn_transform]: phoneSave || phoneFromBookFooter,
						})}
					>
						{phoneSave || phoneFromBookFooter
							? 'Читать бесплатный фрагмент'
							: 'Бесплатный фрагмент'}
						<span
							className={
								pageBook === 'business' ? 'union' : 'union union_white'
							}
						></span>
					</button>
				</div>

				{
					!phoneSave && !phoneFromBookFooter && <RadioGroup defaultValue='telegram' type='book' />
				}
			</form>

			{!phoneSave && !phoneFromBookFooter && (
				<p className={s.politic}>Пришлем ссылку на бесплатный фрагмент</p>
			)}
		</>
	) : (
		<Spinner widthSize={100} heightSize={100} />
	);
};

export default FormSmall;
