import cn from 'classnames';
import s from '../steps.module.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import {
	setAddData,
	setAddStep,
} from '../../../../../store/reducer/lk/form/slice';

import { selectLkForm } from '../../../../../store/reducer/lk/form/selector';
import Button from '../../Button/Button';
import Form from '../../Form';

const Step2 = () => {
	const [width, setWidth] = useState(window?.innerWidth);
	const dispatch = useDispatch();
	const { data, step } = useSelector(selectLkForm);
	const [error, setError] = useState(false);
	const [isSendForm, setIsSendForm] = useState(false);

	useEffect(() => {
		localStorage.setItem('stepLk', JSON.stringify(step));
		localStorage.setItem('data', JSON.stringify(data));
	}, []);

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

	const schema = yup
		.object({
			['Образование']: yup.string(),
		})
		.required();

	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
		watch,
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			['Образование']: data['Образование'],

			['Расскажите о вашей карьере за последние 5 лет']:
				data['Расскажите о вашей карьере за последние 5 лет'],

			['Расскажите о вашей карьере за последние 5 лет (Свой вариант)']:
				data['Расскажите о вашей карьере за последние 5 лет (Свой вариант)'],
		},
	});

	const hasText = watch(
		'Расскажите о вашей карьере за последние 5 лет (Свой вариант)'
	);

	const hasSheckbox = watch('Расскажите о вашей карьере за последние 5 лет');

	useEffect(() => {
		if (!hasSheckbox?.length && !hasText) {
			setError(true);
		} else if (hasSheckbox?.length || hasText) {
			setError(false);
		}
	}, [hasSheckbox, hasText]);

	const onSubmit = data => {
		setIsSendForm(true);

		if (!error) {
			dispatch(setAddStep(3));
			dispatch(setAddData(data));
			setIsSendForm(false);
			setIsSendForm(false);

			localStorage.setItem('data', JSON.stringify(data));
			localStorage.setItem('stepLk', JSON.stringify(3));
		}
	};

	return (
		<div className={s.step2}>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<div className={s.form_item}>
					<h4 className={s.form_title}>Образование</h4>
                <div>
					<div className={s.education}>
						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.education,
								})}
								{...register('Образование')}
								id='education1'
								type='radio'
								name='Образование'
								autoComplete='off'
								value='Высшее'
							/>

							<label htmlFor='education1' className={s.label}>
								Высшее
							</label>
						</div>

						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.education,
								})}
								{...register('Образование')}
								id='education2'
								type='radio'
								name='Образование'
								autoComplete='off'
								value='Неоконченное высшее'
							/>
							<label htmlFor='education2' className={s.label}>
								Неоконченное высшее
							</label>
						</div>

						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.education,
								})}
								{...register('Образование')}
								id='education3'
								type='radio'
								name='Образование'
								autoComplete='off'
								value='Среднее специальное'
							/>
							<label htmlFor='education3' className={s.label}>
								Среднее специальное
							</label>
						</div>

						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.education,
								})}
								{...register('Образование')}
								id='education4'
								type='radio'
								name='Образование'
								autoComplete='off'
								value='Среднее'
							/>
							<label htmlFor='education4' className={s.label}>
								Среднее
							</label>
						</div>
					</div>

					<p className={s.errorText}>
						{errors['Образование'] ? 'Необходимо указать' : ''}
					</p>
				</div>

				<div className={s.form_item}>
					<h4 className={s.form_title}>
						Расскажите о вашей карьере за последние 5 лет
					</h4>

					<div className={s.experience}>
						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.experience,
								})}
								{...register('Расскажите о вашей карьере за последние 5 лет')}
								id='experience1'
								type='checkbox'
								name='Расскажите о вашей карьере за последние 5 лет'
								autoComplete='off'
								value='Общественное питание'
							/>
							<label htmlFor='experience1' className={s.label}>
								Общественное питание
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.experience,
								})}
								{...register('Расскажите о вашей карьере за последние 5 лет')}
								id='experience2'
								type='checkbox'
								name='Расскажите о вашей карьере за последние 5 лет'
								autoComplete='off'
								value='Оптовая и розничная торговля'
							/>
							<label htmlFor='experience2' className={s.label}>
								Оптовая и розничная торговля
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.experience,
								})}
								{...register('Расскажите о вашей карьере за последние 5 лет')}
								id='experience3'
								type='checkbox'
								name='Расскажите о вашей карьере за последние 5 лет'
								autoComplete='off'
								value='Гостиничный бизнес'
							/>
							<label htmlFor='experience3' className={s.label}>
								Гостиничный бизнес
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.experience,
								})}
								{...register('Расскажите о вашей карьере за последние 5 лет')}
								id='experience4'
								type='checkbox'
								name='Расскажите о вашей карьере за последние 5 лет'
								autoComplete='off'
								value='Транспорт и связь'
							/>
							<label htmlFor='experience4' className={s.label}>
								Транспорт и связь
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.experience,
								})}
								{...register('Расскажите о вашей карьере за последние 5 лет')}
								id='experience5'
								type='checkbox'
								name='Расскажите о вашей карьере за последние 5 лет'
								autoComplete='off'
								value='Финансовая деятельность'
							/>
							<label htmlFor='experience5' className={s.label}>
								Финансовая деятельность
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.experience,
								})}
								{...register('Расскажите о вашей карьере за последние 5 лет')}
								id='experience6'
								type='checkbox'
								name='Расскажите о вашей карьере за последние 5 лет'
								autoComplete='off'
								value='Страхование'
							/>
							<label htmlFor='experience6' className={s.label}>
								Страхование
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.experience,
								})}
								{...register('Расскажите о вашей карьере за последние 5 лет')}
								id='experience7'
								type='checkbox'
								name='Расскажите о вашей карьере за последние 5 лет'
								autoComplete='off'
								value='Операции с недвижимостью'
							/>
							<label htmlFor='experience7' className={s.label}>
								Операции с недвижимостью
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.experience,
								})}
								{...register('Расскажите о вашей карьере за последние 5 лет')}
								id='experience8'
								type='checkbox'
								name='Расскажите о вашей карьере за последние 5 лет'
								autoComplete='off'
								value='IT-услуги и разработка'
							/>
							<label htmlFor='experience8' className={s.label}>
								IT-услуги и разработка
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.experience,
								})}
								{...register('Расскажите о вашей карьере за последние 5 лет')}
								id='experience9'
								type='checkbox'
								name='Расскажите о вашей карьере за последние 5 лет'
								autoComplete='off'
								value='Строительство'
							/>
							<label htmlFor='experience9' className={s.label}>
								Строительство
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.experience,
								})}
								{...register('Расскажите о вашей карьере за последние 5 лет')}
								id='experience10'
								type='checkbox'
								name='Расскажите о вашей карьере за последние 5 лет'
								autoComplete='off'
								value='Промышленность и производство'
							/>
							<label htmlFor='experience10' className={s.label}>
								Промышленность и производство
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.experience,
								})}
								{...register('Расскажите о вашей карьере за последние 5 лет')}
								id='experience11'
								type='checkbox'
								name='Расскажите о вашей карьере за последние 5 лет'
								autoComplete='off'
								value='Лесное и сельское хозяйство'
							/>
							<label htmlFor='experience11' className={s.label}>
								Лесное и сельское хозяйство
							</label>
						</div>

						<p className={s.errorText2}>
							{error &&
								isSendForm &&
								'Необходимо указать, или напиши свой вариант'}
						</p>
						<textarea
							{...register(
								'Расскажите о вашей карьере за последние 5 лет (Свой вариант)'
							)}
							style={{ marginBottom: '32px' }}
							className={s.textarea}
							placeholder='Свой вариант'
							name='Расскажите о вашей карьере за последние 5 лет (Свой вариант)'
						></textarea>
					</div>
				</div>
			</div>	

				<div className={s.btns}>
					<Button callback={() => dispatch(setAddStep(1))} back>
						{width > 575 ? (
							'   Назад'
						) : (
							<img src='/static/lk/IconBackward.svg' alt='назад' />
						)}
					</Button>
					<Button>Далее 2 из 8</Button>
				</div>
			</Form>

			<img
				className={s.illustration}
				src='/static/lk/illustration/illustration.png'
				alt='Иллюстрация'
			/>
		</div>
	);
};

export default Step2;
