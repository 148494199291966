import classNames from 'classnames';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectLkBook } from '../../../../store/reducer/lk/book/selector';
import {
	setAddActiveLink,
	setShowSidebar,
} from '../../../../store/reducer/lk/sidebar/slice';
import s from './item.module.scss';

const Item = ({ index, activeLink, name, status }) => {
	const dispatch = useDispatch();
	const [indicator, setIndicator] = useState(true);
	const [indicatorForm, setIndicatorForm] = useState(true);
	const [indicatorContract, setIndicatorContract] = useState(true);
	const [indicatorPayment, setIndicatorPayment] = useState(true);
	const { waitingBook } = useSelector(selectLkBook);

	const setActiveLink = () => {
		dispatch(setAddActiveLink(index));
		dispatch(setShowSidebar(false));
		document.body.classList.remove('overlay');
		document.body.style.overflow = '';
		document.body.removeAttribute('style');

		{
			waitingBook && window.location.reload();
		}

		localStorage.setItem('activeLink', JSON.stringify(index));
		localStorage.setItem('showMobile', JSON.stringify(false));
	};

	const handleIndicator = () => {
		dispatch(setAddActiveLink(4));
		dispatch(setShowSidebar(false));
		document.body.classList.remove('overlay');
		localStorage.setItem('activeLink', JSON.stringify(4));
		localStorage.setItem('showMobile', JSON.stringify(false));
	};

	const handleIndicatorForm = () => {
		dispatch(setAddActiveLink(6));
		setIndicatorForm(true);
		dispatch(setShowSidebar(false));
		document.body.classList.remove('overlay');
		localStorage.setItem('activeLink', JSON.stringify(6));
		localStorage.setItem('showMobile', JSON.stringify(false));
	};

	const handleIndicatorContract = () => {
		dispatch(setAddActiveLink(8));
		setIndicatorContract(true);
		dispatch(setShowSidebar(false));
		document.body.classList.remove('overlay');
		localStorage.setItem('activeLink', JSON.stringify(8));
		localStorage.setItem('showMobile', JSON.stringify(false));
	};

	const handleIndicatorPayment = () => {
		dispatch(setAddActiveLink(9));
		setIndicatorPayment(true);
		dispatch(setShowSidebar(false));
		document.body.classList.remove('overlay');
		localStorage.setItem('activeLink', JSON.stringify(9));
		localStorage.setItem('showMobile', JSON.stringify(false));
	};

	return (
		<>
			{name === 'Бизнес-план' && status === 'enabled' ? (
				<>
					<li className={s.item}>
						<Link
							to={e => e.preventDefault()}
							onClick={() => setActiveLink()}
							className={classNames(s.link, {
								[s.active]: activeLink === index,
								[s.disabled]: status === 'disabled',
								[s.enabled]: status === 'enabled',
								[s.hidden]: status === 'hidden',
							})}
						>
							<div>
								<span className={s.count}>{index}</span>
								<span className={s.name}>{name}</span>
							</div>

							{status === 'finished' ? (
								<img src='/static/lk/iconDone.svg' alt={status} />
							) : status === 'waiting' ? (
								<img
									className={s.waiting}
									src='/static/lk/iconTime.svg'
									alt={status}
								/>
							) : status === 'disbaled' ? (
								''
							) : (
								''
							)}
						</Link>
					</li>

					{indicator && (
						<button onClick={handleIndicator} className={s.indicator}>
							Смотреть расчет
						</button>
					)}
				</>
			) : name === 'Анкета партнера' && status === 'enabled' ? (
				<>
					<li className={s.item}>
						<Link
							to={e => e.preventDefault()}
							onClick={() => setActiveLink()}
							className={classNames(s.link, {
								[s.active]: activeLink === index,
								[s.disabled]: status === 'disabled',
								[s.enabled]: status === 'enabled',
								[s.hidden]: status === 'hidden',
							})}
						>
							<div>
								<span className={s.count}>{index}</span>
								<span className={s.name}>{name}</span>
							</div>

							{status === 'finished' ? (
								<img src='/static/lk/iconDone.svg' alt={status} />
							) : status === 'waiting' ? (
								<img
									className={s.waiting}
									src='/static/lk/iconTime.svg'
									alt={status}
								/>
							) : status === 'disbaled' ? (
								''
							) : (
								''
							)}
						</Link>
					</li>

					<button onClick={handleIndicatorForm} className={s.indicator}>
						Заполнить анкету
					</button>
				</>
			) : name === 'Договор-оферта' && status === 'enabled' ? (
				<>
					<li className={s.item}>
						<Link
							to={e => e.preventDefault()}
							onClick={() => setActiveLink()}
							className={classNames(s.link, {
								[s.active]: activeLink === index,
								[s.disabled]: status === 'disabled',
								[s.enabled]: status === 'enabled',
								[s.hidden]: status === 'hidden',
							})}
						>
							<div>
								<span className={s.count}>{index}</span>
								<span className={s.name}>{name}</span>
							</div>

							{status === 'finished' ? (
								<img src='/static/lk/iconDone.svg' alt={status} />
							) : status === 'waiting' ? (
								<img
									className={s.waiting}
									src='/static/lk/iconTime.svg'
									alt={status}
								/>
							) : status === 'disbaled' ? (
								''
							) : (
								''
							)}
						</Link>
					</li>

					<button onClick={handleIndicatorContract} className={s.indicator}>
						Изучить условия
					</button>
				</>
			) : name === 'Предоплата' && status === 'enabled' ? (
				<>
					<li className={s.item}>
						<Link
							to={e => e.preventDefault()}
							onClick={() => setActiveLink()}
							className={classNames(s.link, {
								[s.active]: activeLink === index,
								[s.disabled]: status === 'disabled',
								[s.enabled]: status === 'enabled',
								[s.hidden]: status === 'hidden',
							})}
						>
							<div>
								<span className={s.count}>{index}</span>
								<span className={s.name}>{name}</span>
							</div>

							{status === 'finished' ? (
								<img src='/static/lk/iconDone.svg' alt={status} />
							) : status === 'waiting' ? (
								<img
									className={s.waiting}
									src='/static/lk/iconTime.svg'
									alt={status}
								/>
							) : status === 'disbaled' ? (
								''
							) : (
								''
							)}
						</Link>
					</li>

					<button onClick={handleIndicatorPayment} className={s.indicator}>
						Внести оплату
					</button>
				</>
			) : (
				<li className={s.item}>
					<Link
						to={e => e.preventDefault()}
						onClick={() => setActiveLink()}
						className={classNames(s.link, {
							[s.active]: activeLink === index,
							[s.disabled]: status === 'disabled',
							[s.enabled]: status === 'enabled',
							[s.hidden]: status === 'hidden',
						})}
					>
						<div>
							<span className={s.count}>{index}</span>
							<span className={s.name}>{name}</span>
						</div>

						{status === 'finished' && (
							<img src='/static/lk/iconDone.svg' alt={status} />
						)}

						{status === 'waiting' && (
							<img
								className={s.waiting}
								src='/static/lk/iconTime.svg'
								alt={status}
							/>
						)}

						{status === 'rejected' && (
							<img
								className={s.rejected}
								src='/static/lk/rejected.svg'
								alt={status}
							/>
						)}

						{status === 'disbaled' && ''}
					</Link>
				</li>
			)}
		</>
	);
};

export default Item;
