import cn from 'classnames';
import { memo } from 'react';
import s from '../../access.module.scss';
import AdminPanel from '../../components/adminPanel/AdminPanel';

const Done = memo(({ width, information, clientWithoutCours }) => {
	return (
		<div className={s.page_done}>
			<div className={cn(s.heading)}>
				<div className={cn(s.wrapper_heading, s.heading_done)}>
					<img src='/static/lk/iconDoneBig.svg' alt='иконка' />
					<h5>{width > 575 ? `${clientWithoutCours ? 10 : 11}. Доступ в Skilla IS` : `Шаг ${clientWithoutCours ? 10 : 11}`}</h5>
				</div>

				{width > 575 ? (
					<h2>
						Добро пожаловать <br></br> в Клуб предпринимателей!
					</h2>
				) : (
					<h2>Добро пожаловать в Клуб предпринимателей!</h2>
				)}
			</div>

			<AdminPanel information={information} />

			<div className={s.footer_block}>
				<p>
					Дата предоставления доступа (прав на ПО) –{' '}
					{information.access.date.replace(/-/g, '.')}
				</p>

				<p>
					Момент предоставления прав является фактом подписания акта
					приемки-передачи в соответствии со Статьей 5. Договора
				</p>
			</div>

			<img
				className={cn(s.illustration, s.illustration_done)}
				src={
					width > 575
						? '/static/lk/illustration/illustrationThreePeople.png'
						: '/static/lk/illustration/illustrationThreePeople-mobile.png'
				}
				alt='иллюстрация'
			/>
		</div>
	);
});
export default Done;
