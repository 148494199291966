import cn from 'classnames';
import s from '../steps.module.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import {
	setAddData,
	setAddStep,
} from '../../../../../store/reducer/lk/formExam/slice';

import { selectLkFormExam } from '../../../../../store/reducer/lk/formExam/selector';
import Button from '../../Button/Button';
import Form from '../../Form';

const Step21 = () => {
	const [width, setWidth] = useState(window?.innerWidth);
	const dispatch = useDispatch();
	const { data, step } = useSelector(selectLkFormExam);
	const [isSendForm, setIsSendForm] = useState(false);

	useEffect(() => {
		localStorage.setItem('stepLkExam', JSON.stringify(step));
		localStorage.setItem('dataExam', JSON.stringify(data));
	}, []);

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

	const schema = yup
		.object({
			['Как установить приложение?']: yup.string(),
            ['Что нужно сделать исполнителю после установки приложения?']: yup.string(),
            ['Если у исполнителя уже установлено приложение, что делать?']: yup.string(),
		})
		.required();

	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
		watch,
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			['Как установить приложение?']: data['Как установить приложение?'],
			['Что нужно сделать исполнителю после установки приложения?']: data['Что нужно сделать исполнителю после установки приложения?'],
			['Если у исполнителя уже установлено приложение, что делать?']: data['Если у исполнителя уже установлено приложение, что делать?'],
		},
	});


	const onSubmit = data => {
		setIsSendForm(true);

			dispatch(setAddStep(22));
			dispatch(setAddData(data));
			setIsSendForm(false);

			localStorage.setItem('dataExam', JSON.stringify(data));
			localStorage.setItem('stepLkExam', JSON.stringify(21));
		
	};

	return (
		<div className={s.step21}>
      <div className={s.wrapper_heading}>
			  <h5>Исполнители. Менеджер по персоналу</h5>
			</div>
			<Form onSubmit={handleSubmit(onSubmit)}>

				<div className={s.form_item}>
					<h4 className={s.form_title}>58. Как установить приложение?</h4>

					<div className={s.question58}>
                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question58,
								})}
								{...register('Как установить приложение?')}
								id='exam581'
								type='radio'
								name='Как установить приложение?'
								autoComplete='off'
								value='Навести на QR-код камеру смартфона, перейти по ссылке в AppStore или в Google Play, скачать приложение'
							/>
							<label htmlFor='exam581' className={s.label}>
							Навести на QR-код камеру смартфона, перейти по ссылке в AppStore или в Google Play, скачать приложение
							</label>
						</div>

						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question58,
								})}
								{...register('Как установить приложение?')}
								id='exam582'
								type='radio'
								name='Как установить приложение?'
								autoComplete='off'
								value='Зайти на work.skilla.ru, нажать кнопку “Скачать приложение”'
							/>
							<label htmlFor='exam582' className={s.label}>
							Зайти на work.skilla.ru, нажать кнопку “Скачать приложение”
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question58,
								})}
								{...register('Как установить приложение?')}
								id='exam583'
								type='radio'
								name='Как установить приложение?'
								autoComplete='off'
								value='Найти приложение в AppStore или Google Play с мобильного устройства'
							/>
							<label htmlFor='exam583' className={s.label}>
							Найти приложение в AppStore или Google Play с мобильного устройства
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question58,
								})}
								{...register('Как установить приложение?')}
								id='exam584'
								type='radio'
								name='Как установить приложение?'
								autoComplete='off'
								value='Все варианты ответов верные'
							/>
							<label htmlFor='exam584' className={s.label}>
							Все варианты ответов верные
							</label>
						</div>
					</div>

					<p className={s.errorText}>
						{errors['Как установить приложение?'] ? 'Необходимо указать' : ''}
					</p>
				</div>

                <div className={s.form_item}>
					<h4 className={s.form_title}>59. Что нужно сделать исполнителю после установки приложения?</h4>

					<div className={s.question59}>
                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question59,
								})}
								{...register('Что нужно сделать исполнителю после установки приложения?')}
								id='exam591'
								type='radio'
								name='Что нужно сделать исполнителю после установки приложения?'
								autoComplete='off'
								value='Разрешить доступ к геопозиции при использовании'
							/>
							<label htmlFor='exam591' className={s.label}>
							Разрешить доступ к геопозиции при использовании
							</label>
						</div>

						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question59,
								})}
								{...register('Что нужно сделать исполнителю после установки приложения?')}
								id='exam592'
								type='radio'
								name='Что нужно сделать исполнителю после установки приложения?'
								autoComplete='off'
								value='Разрешить отправку PUSH-уведомлений'
							/>
							<label htmlFor='exam592' className={s.label}>
							Разрешить отправку PUSH-уведомлений
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question59,
								})}
								{...register('Что нужно сделать исполнителю после установки приложения?')}
								id='exam593'
								type='radio'
								name='Что нужно сделать исполнителю после установки приложения?'
								autoComplete='off'
								value='Выбрать город, в котором готов принимать заказы'
							/>
							<label htmlFor='exam593' className={s.label}>
							Выбрать город, в котором готов принимать заказы
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question59,
								})}
								{...register('Что нужно сделать исполнителю после установки приложения?')}
								id='exam594'
								type='radio'
								name='Что нужно сделать исполнителю после установки приложения?'
								autoComplete='off'
								value='Сделать фотографии своего паспорта (основная страница, страница с актуальной регистрацией)'
							/>
							<label htmlFor='exam594' className={s.label}>
							Сделать фотографии своего паспорта (основная страница, страница с актуальной регистрацией)
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question59,
								})}
								{...register('Что нужно сделать исполнителю после установки приложения?')}
								id='exam595'
								type='radio'
								name='Что нужно сделать исполнителю после установки приложения?'
								autoComplete='off'
								value='Сделать селфи с паспортом'
							/>
							<label htmlFor='exam595' className={s.label}>
							Сделать селфи с паспортом
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question59,
								})}
								{...register('Что нужно сделать исполнителю после установки приложения?')}
								id='exam596'
								type='radio'
								name='Что нужно сделать исполнителю после установки приложения?'
								autoComplete='off'
								value='Указать код работодателя'
							/>
							<label htmlFor='exam596' className={s.label}>
							Указать код работодателя
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question59,
								})}
								{...register('Что нужно сделать исполнителю после установки приложения?')}
								id='exam597'
								type='radio'
								name='Что нужно сделать исполнителю после установки приложения?'
								autoComplete='off'
								value='Вся последовательность действий описана верно'
							/>
							<label htmlFor='exam597' className={s.label}>
							Вся последовательность действий описана верно
							</label>
						</div>
					</div>

					<p className={s.errorText}>
						{errors['Что нужно сделать исполнителю после установки приложения?'] ? 'Необходимо указать' : ''}
					</p>
				</div>

                <div className={s.form_item}>
					<h4 className={s.form_title}>60. Если у исполнителя уже установлено приложение, что делать?</h4>

					<div className={s.question60}>
                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question60,
								})}
								{...register('Если у исполнителя уже установлено приложение, что делать?')}
								id='exam601'
								type='radio'
								name='Если у исполнителя уже установлено приложение, что делать?'
								autoComplete='off'
								value='Выдать анкету, взять паспорт и сделать копию'
							/>
							<label htmlFor='exam601' className={s.label}>
							Выдать анкету, взять паспорт и сделать копию
							</label>
						</div>

						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question60,
								})}
								{...register('Если у исполнителя уже установлено приложение, что делать?')}
								id='exam602'
								type='radio'
								name='Если у исполнителя уже установлено приложение, что делать?'
								autoComplete='off'
								value='Занести его номер телефона в систему и исполнителю придет приглашение в раздел "Мои заказчики"'
							/>
							<label htmlFor='exam602' className={s.label}>
							Занести его номер телефона в систему и исполнителю придет приглашение в раздел "Мои заказчики"
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question60,
								})}
								{...register('Если у исполнителя уже установлено приложение, что делать?')}
								id='exam603'
								type='radio'
								name='Если у исполнителя уже установлено приложение, что делать?'
								autoComplete='off'
								value='Удалить приложение и установить заново'
							/>
							<label htmlFor='exam603' className={s.label}>
							Удалить приложение и установить заново
							</label>
						</div>
					</div>

					<p className={s.errorText}>
						{errors['Если у исполнителя уже установлено приложение, что делать?'] ? 'Необходимо указать' : ''}
					</p>
				</div>

				<div className={s.btns}>
                    <Button callback={() => dispatch(setAddStep(20))} back>
						{width > 575 ? (
							'   Назад'
						) : (
							<img src='/static/lk/IconBackward.svg' alt='назад' />
						)}
					</Button>
					<Button>Далее 21 из 35</Button>
				</div>
			</Form>

			<img
				className={s.illustration}
				src='/static/lk/illustration/illustration.png'
				alt='Иллюстрация'
			/>
		</div>
	);
};

export default Step21;
