import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {setAddOverlay} from '../store/reducer/lk/contract/slice'

export const useOnClickOutside = isInit => {
	const [isShow, setIsShow] = useState(isInit);

	const dispatch = useDispatch()

	const refBtn = useRef(null);
	const refPopup = useRef(null);


	const handleClickOutside = e => {
		if (!refPopup?.current?.contains(e.target) && !refBtn?.current?.contains(e.target)
		) {
			setIsShow(false);
			dispatch(setAddOverlay(false))
			document.body.classList.remove('overlay');
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside);
		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, []);

	return { refBtn, refPopup, isShow, setIsShow };
};
