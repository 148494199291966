import { useEffect, useRef, useState } from 'react';
import s from './ModalHH.module.scss';

function ModalHH({ setOpenModal }) {
    const [anim, setAnim] = useState(false);
    const picRef = useRef();
    useEffect(() => {
        setTimeout(() => {
            setAnim(true)
        })
    }, []);

    function closeModal(e) {
        e.stopPropagation()
        if (picRef.current && !picRef.current.contains(e.target)) {
            setAnim(false)
            setTimeout(() => {
                setOpenModal(false);
            }, 400)

        }
    }

    useEffect(() => {
        document.addEventListener('mouseup', closeModal);

        return () => document.removeEventListener('mouseup', closeModal);
    }, []);

    return (
        <div className={`${s.window} ${anim && s.window_anim}`}>



            <picture className={`${s.picture} ${anim && s.picture_anim}`} ref={picRef}>
                <div onClick={() => {setAnim(false); setTimeout(() => {setOpenModal(false)}, 400)}} className={`${s.close} ${anim && s.close_anim}`}>
                    <img src='/static/customer/footer/main/iconClose.png' alt='iconClose.png' />
                </div>
                <source
                    srcSet='/static/customer/footer/main/hhFinall.jpg'
                    type='image/webp'
                />
                <img
                    src='/static/customer/footer/main/hhFinall.jpg'
                    alt='qr-code'
                    className='footer-main-important__image-hh'
                />
            </picture>
        </div>
    )
};

export default ModalHH;