import classNames from 'classnames';
import { memo, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
	getLkReqAccessCheck,
	getLkReqAccess,
	getLkReqTraining,
	getLkReqTrainingCheck,
	getUser,
} from '../../../../../Api';
import { selectLkAccess } from '../../../../../store/reducer/lk/access/selector';
import { setAddModalCode } from '../../../../../store/reducer/lk/access/slice.js';
import { selectLKAuth } from '../../../../../store/reducer/lk/authChech/selector.js';
import {
	setAddUser,
	setHelpSupport,
} from '../../../../../store/reducer/lk/authChech/slice.js';
import { selectLkTraining } from '../../../../../store/reducer/lk/training/selector.js';
import {
	setAddLoading,
	setAddModal,
} from '../../../../../store/reducer/lk/training/slice.js';
import Spinner from '../../../../Spinner';
import Button from '../../../Auth/Button/Button';
import s from './modalCode.module.scss';

const ModalCode = memo(({ page, getInfoTraining, getAccessInfo }) => {
	const [showMobileBtn, setShowMobileBtn] = useState(false);
	const [modalLoading, setModalLoading] = useState(false);
	const [width, setWidth] = useState(window?.innerWidth);
	const [count, setCount] = useState(30);
	const [valueInputLength, setValueInputLength] = useState(0);
	const [smsFailure, setSmsFailure] = useState(false);
	const [smsRepeat, setSmsRepeat] = useState(false);
	const [error, setError] = useState(false);
	const dispatch = useDispatch();

	const inputRef = useRef(null);

	const { isLoading, isModal } = useSelector(selectLkTraining);
	const { isModalCode } = useSelector(selectLkAccess);
	const { user, helpSupport, phoneСonfirmed } = useSelector(selectLKAuth);
	const { placePicker, dateForApi } = useSelector(selectLkTraining);

	const phoneNumber =
		user?.user?.phones[0]?.phone || user?.user?.phones[1]?.phone;

	let num = phoneNumber.slice(8);
	const transformNumber = (num = '*' + num);

	const refModal = useRef(null);

	const { register, handleSubmit, reset } = useForm({});

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

	useEffect(() => {
		const handleClickBody = e => {
			const path = e.path || (e.composedPath && e.composedPath());
			const event = e.target.classList;

			const button = event == 'training_btn__rDJ7s';

			if (
				!button &&
				refModal.current &&
				path &&
				!path.includes(refModal.current)
			) {
				closeModalForm();
			}
		};

		document.body.addEventListener('click', handleClickBody);

		return () => document.body.removeEventListener('click', handleClickBody);
	}, []);

	useEffect(() => {
		setCount(30);
		handleSmsCode();
	}, []);

	const maxLengthCheck = x => {
		if (x.value.length > x.maxLength) x.value = x.value.slice(0, x.maxLength);
	};

	function jump(x) {
		const ml = x.getAttribute('maxlength');

		if (ml && x.value.length >= ml) {
			do {
				x = x.nextSibling;
			} while (x && !/text/.test(x.type));

			if (x && /text/.test(x.type)) {
				x.focus();
			}
		}
	}

	function jumpMobile(x) {
		const ml = x.getAttribute('maxlength');

		if (ml && x.value.length >= ml) {
			inputRef.current.focus();
			setShowMobileBtn(true);
		} else {
			setShowMobileBtn(false);
			setError(false);
		}

		maxLengthCheck(x);
	}

	const checkInputLength = ({ input, input2, input3, input4 }) => {
		setValueInputLength(input + input2 + input3 + input4);

		if (input || input2 || input3 || input4) {
			setError(false);
		}
	};

	const getUserInfo = async () => {
		try {
			const { data } = await getUser(user?.user?.code);

			dispatch(setAddUser(data));
			localStorage.setItem('user', JSON.stringify(data));
		} catch (e) {
			console.log(e);
		}
	};


	const checkCode = async (input, input2, input3, input4) => {
		setModalLoading(true);

		try {
			if (page === 'training') {
				const { data } = await getLkReqTrainingCheck(
					user?.user?.code,
					phoneNumber,
					width > 575 ? input + input2 + input3 + input4 : input
				);

				if (data.result === 'success') {
					getUserInfo();
					closeModalForm();
					setError(false);
					getInfoTraining();
					getAccessInfo();
					setModalLoading(false);
					localStorage.removeItem('placePicker');
					localStorage.removeItem('dateForApi');
					localStorage.setItem('trainingInfo', JSON.stringify(data));
				} else {
					setModalLoading(false);
					reset();
					setError(true);
					dispatch(setAddLoading(false));
					setValueInputLength('');
				}
			}

			if (page === 'access') {
				const { data } = await getLkReqAccessCheck(
					user?.user?.code,
					width > 575 ? input + input2 + input3 + input4 : input
				);

				if (data.result === 'success') {
					getUserInfo();
					closeModalForm();
					setError(false);
					getAccessInfo();
					setModalLoading(false);
				} else {
					setModalLoading(false);
					reset();
					setError(true);
					setValueInputLength('');
				}
			}
		} catch (e) {
			console.log(e);
		}
	};

	const onSubmit = ({ input, input2, input3, input4 }) => {
		checkCode(input, input2, input3, input4);
	};


	const closeModalForm = () => {
		dispatch(setAddLoading(false));
		dispatch(setAddModal(false));
		dispatch(setAddModalCode(false));
		setCount(30);
		document.body.classList.remove('overlay');
	};

	const getSmsCode = async () => {
		handleSmsCode();
		setSmsRepeat(false);
		try {
			if (page === 'training') {
				const { data } = await getLkReqTraining(user?.user?.code, phoneNumber, placePicker, dateForApi);

				if (data.result === 'failure') {
					setSmsFailure(true);
				}
			}

			if (page === 'access') {
				const { data } = await getLkReqAccess(user?.user?.code);

				if (data.result === 'failure') {
					setSmsFailure(true);
				}
			}
			
	
		} catch (e) {
			console.log(e);
		}
	};

	const stopCount = interval => {
		setSmsRepeat(true);
		clearInterval(interval);
		setCount(30);
	};

	const handleSmsCode = () => {
		const interval = setInterval(() => {
			setCount(prev => (prev !== 0 ? prev - 1 : stopCount(interval)));
		}, 1000);
	};

	return (
		<div
			className={classNames(s.overlay, {
				[s.overlay_active]: isModal || isModalCode,
			})}
		>
			{!modalLoading ? (
				<form
					ref={refModal}
					onChange={handleSubmit(checkInputLength)}
					onSubmit={handleSubmit(onSubmit)}
					className={s.form}
				>
					<div onClick={closeModalForm} className={s.close}>
						<img src='/static/lk/iconClose.svg' alt='закрыть' />
					</div>

					<h4 className={s.title}>
						{width > 575
							? 'Подтвердите подписание акта доступа кодом из СМС'
							: `Код отправлен на ${transformNumber}`}
					</h4>

					{width > 575 ? (
						<div className={s.wrapper}>
							<div className={s.inputs}>
								<input
									className={classNames(s.input, {
										[s.error]: error,
									})}
									{...register('input')}
									name='input'
									type='text'
									onChange={e => jump(e.target)}
									maxLength='1'
									autoComplete='off'
								/>
								<input
									className={classNames(s.input, {
										[s.error]: error,
									})}
									{...register('input2')}
									name='input2'
									type='text'
									onChange={e => jump(e.target)}
									maxLength='1'
									autoComplete='off'
								/>
								<input
									className={classNames(s.input, {
										[s.error]: error,
									})}
									{...register('input3')}
									name='input3'
									type='text'
									onChange={e => jump(e.target)}
									maxLength='1'
									autoComplete='off'
								/>
								<input
									className={classNames(s.input, {
										[s.error]: error,
									})}
									{...register('input4')}
									name='input4'
									type='text'
									maxLength='1'
									autoComplete='off'
								/>
							</div>
							<div className={s.check_code}>
								{!smsRepeat && !smsFailure ? (
									<p className={s.descr}>
										Запросить повторно <span>{count}</span> сек
									</p>
								) : smsRepeat ? (
									<button
										onClick={() => getSmsCode(phoneNumber)}
										className={s.repeat}
									>
										Запросить повторно
									</button>
								) : smsFailure && !helpSupport ? (
									<Button
										onCkick={() => dispatch(setHelpSupport(true))}
										className={'btn-noSms'}
									>
										Не приходит СМС
									</Button>
								) : helpSupport || phoneСonfirmed ? (
									<div className={s.info}>
										<p>
											Позвони по номеру{' '}
											<a href='tel:88003331721'>8 800 333 17 21</a> и мы поможем
										</p>
									</div>
								) : (
									''
								)}
							</div>
						</div>
					) : (
						<div className={s.wrapper}>
							<div className={s.inputs}>
								<input
									className={classNames(s.input, {
										[s.error]: error,
									})}
									{...register('input')}
									name='input'
									type='text'
									maxLength='4'
									onChange={e => jumpMobile(e.target)}
									autoComplete='off'
								/>
							</div>
							<div className={s.check_code}>
								{!smsRepeat && !smsFailure ? (
									<p className={s.descr}>
										Запросить повторно <span>{count}</span> сек
									</p>
								) : smsRepeat ? (
									<button
										onClick={() => getSmsCode(phoneNumber)}
										className={s.repeat}
									>
										Запросить повторно
									</button>
								) : smsFailure && !helpSupport ? (
									<Button
										onCkick={() => dispatch(setHelpSupport(true))}
										className={'btn-noSms'}
									>
										Не приходит СМС
									</Button>
								) : helpSupport || phoneСonfirmed ? (
									<div className={s.info}>
										<p>
											Позвони по номеру{' '}
											<a href='tel:88003331721'>8 800 333 17 21</a> и мы поможем
										</p>
									</div>
								) : (
									''
								)}
							</div>
						</div>
					)}

					{width > 575 ? (
						<div className={s.buttons}>
							<Button
								className={classNames('btn-send', {
									['disabled']: String(valueInputLength).length !== 4,
									['active']: String(valueInputLength).length === 4,
								})}
							>
								Подтвердить
							</Button>
						</div>
					) : (
						<div className={s.buttons}>
							<Button
								inputRef={inputRef}
								className={classNames('btn-send', {
									['disabled']: !showMobileBtn,
									['active']: showMobileBtn,
								})}
							>
								Подтвердить
							</Button>
						</div>
					)}
				</form>
			) : (
				<Spinner />
			)}
		</div>
	);
});

export default ModalCode;
