import s from './Documents.module.scss';
import { ReactComponent as IconCheck } from '../../../../image/lk/iconCheck.svg';
import { ReactComponent as IconDelete } from '../../../../image/lk/iconDelete.svg';
import { useEffect, useState } from 'react';

function File({ index, el, files, setFiles, setErr }) {
    const [anim, setAnim] = useState(false);
    const [img, setImg] = useState('')

    useEffect(() => {
        setAnim(true);
    }, []);

     function getBase64(file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            setImg(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
        console.log(reader)
        return reader;
    }

    getBase64(el)

    function handleDeleteFile(e) {
        const id = e.currentTarget.id;
        if (index == Number(id)) {
            setAnim(false);
        }

        setErr('');
        setTimeout(() => {
            const arr = files.filter(el => files.indexOf(el) !== Number(id));
            setFiles(arr);
        }, 200)
    }
    return (
        <div style={{ marginBottom: files.length === index + 1 ? '0' : ''}} className={`${s.file} ${anim ? s.file_anim : s.file}`}>
            <div className={s.block}>
                <IconCheck />
                <p>{el.name}</p>
            </div>
            <div id={index} onClick={handleDeleteFile} className={s.delete}>
                <IconDelete />
              
            </div>
        </div>
    )
};

export default File;