import cn from 'classnames';
import s from '../steps.module.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import {
	setAddData,
	setAddStep,
} from '../../../../../store/reducer/lk/formExam/slice';

import { selectLkFormExam } from '../../../../../store/reducer/lk/formExam/selector';
import Button from '../../Button/Button';
import Form from '../../Form';

const Step29 = () => {
	const [width, setWidth] = useState(window?.innerWidth);
	const dispatch = useDispatch();
	const { data, step } = useSelector(selectLkFormExam);
	const [isError82, setError82] = useState(false);
	const [isSendForm, setIsSendForm] = useState(false);

	useEffect(() => {
		localStorage.setItem('stepLkExam', JSON.stringify(step));
		localStorage.setItem('dataExam', JSON.stringify(data));
	}, []);

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);
    
	const schema = yup
		.object({
			['Для чего нужен анализ конкуренции?']: yup.string(),
            ['Как делать анализ конкуренции?']: yup.string(),
		})
		.required();

	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
		watch,
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			['Для каких целей регистрируется ООО на ОСНО 20%']: data['Для каких целей регистрируется ООО на ОСНО 20%'],
			['Для чего нужен анализ конкуренции?']: data['Для чего нужен анализ конкуренции?'],
			['Как делать анализ конкуренции?']: data['Как делать анализ конкуренции?'],
		},
	});

	
	const ckbox82 = watch('Для каких целей регистрируется ООО на ОСНО 20%');
    
	
	useEffect(() => {
		if (!ckbox82?.length) {
			setError82(true);
		} else {
			setError82(false);
		}
	}, [ckbox82]);

	const onSubmit = data => {
		setIsSendForm(true);

		if (!isError82) {
			dispatch(setAddStep(30));
			dispatch(setAddData(data));
			setIsSendForm(false);

			localStorage.setItem('dataExam', JSON.stringify(data));
			localStorage.setItem('stepLkExam', JSON.stringify(29));
		}
	};

	return (
		<div className={s.step29}>
      <div className={s.wrapper_heading}>
			  <h5>Запуск</h5>
			</div>
			<Form onSubmit={handleSubmit(onSubmit)}>

            <div className={s.form_item}>
					<h4 className={s.form_title}>
					82. Для каких целей регистрируется ООО на ОСНО 20%
					</h4>

					<div className={s.question82}>
						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question82,
								})}
								{...register('Для каких целей регистрируется ООО на ОСНО 20%')}
								id='exam821'
								type='checkbox'
								name='Для каких целей регистрируется ООО на ОСНО 20%'
								autoComplete='off'
								value='Для работы с крупными клиентами, чаще всего они работают с НДС'/>
							<label htmlFor='exam821' className={s.label}>
							Для работы с крупными клиентами, чаще всего они работают с НДС
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question82,
								})}
								{...register('Для каких целей регистрируется ООО на ОСНО 20%')}
								id='exam822'
								type='checkbox'
								name='Для каких целей регистрируется ООО на ОСНО 20%'
								autoComplete='off'
								value='Для работы с любыми юр.лицами при запросе НДС'/>
							<label htmlFor='exam822' className={s.label}>
							Для работы с любыми юр.лицами при запросе НДС
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question82,
								})}
								{...register('Для каких целей регистрируется ООО на ОСНО 20%')}
								id='exam823'
								type='checkbox'
								name='Для каких целей регистрируется ООО на ОСНО 20%'
								autoComplete='off'
								value='Для простых условий снятия денежных средств с расчетного счета'/>
							<label htmlFor='exam823' className={s.label}>
							Для простых условий снятия денежных средств с расчетного счета
							</label>
						</div>

                        <div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question82,
								})}
								{...register('Для каких целей регистрируется ООО на ОСНО 20%')}
								id='exam824'
								type='checkbox'
								name='Для каких целей регистрируется ООО на ОСНО 20%'
								autoComplete='off'
								value='Для перевода оплаты через расчетный счет всем исполнителям'/>
							<label htmlFor='exam824' className={s.label}>
							Для перевода оплаты через расчетный счет всем исполнителям
							</label>
						</div>

						<p className={s.errorText2}>
							{isError82 &&
								isSendForm &&
								'Необходимо указать'}
						</p>
					</div>
				</div>

                <div className={s.form_item}>
					<h4 className={s.form_title}>83. Для чего нужен анализ конкуренции?</h4>

					<div className={s.question83}>
                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question83,
								})}
								{...register('Для чего нужен анализ конкуренции?')}
								id='exam831'
								type='radio'
								name='Для чего нужен анализ конкуренции?'
								autoComplete='off'
								value='Для понимания, что происходит на рынке'
							/>
							<label htmlFor='exam831' className={s.label}>
							Для понимания, что происходит на рынке
							</label>
						</div>

						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question83,
								})}
								{...register('Для чего нужен анализ конкуренции?')}
								id='exam832'
								type='radio'
								name='Для чего нужен анализ конкуренции?'
								autoComplete='off'
								value='Для понимания ценообразования'
							/>
							<label htmlFor='exam832' className={s.label}>
							Для понимания ценообразования
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question83,
								})}
								{...register('Для чего нужен анализ конкуренции?')}
								id='exam833'
								type='radio'
								name='Для чего нужен анализ конкуренции?'
								autoComplete='off'
								value='Чтобы выявить недостатки конкурентов и быть лучшим'
							/>
							<label htmlFor='exam833' className={s.label}>
							Чтобы выявить недостатки конкурентов и быть лучшим
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question83,
								})}
								{...register('Для чего нужен анализ конкуренции?')}
								id='exam834'
								type='radio'
								name='Для чего нужен анализ конкуренции?'
								autoComplete='off'
								value='Установить в будущем партнерские отношения'
							/>
							<label htmlFor='exam834' className={s.label}>
							Установить в будущем партнерские отношения
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question83,
								})}
								{...register('Для чего нужен анализ конкуренции?')}
								id='exam835'
								type='radio'
								name='Для чего нужен анализ конкуренции?'
								autoComplete='off'
								value='Все варианты верные'
							/>
							<label htmlFor='exam835' className={s.label}>
							Все варианты верные
							</label>
						</div>

					</div>

					<p className={s.errorText}>
						{errors['Для чего нужен анализ конкуренции?'] ? 'Необходимо указать' : ''}
					</p>
				</div>

                <div className={s.form_item}>
					<h4 className={s.form_title}>84. Как делать анализ конкуренции?</h4>

					<div className={s.question84}>
                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question84,
								})}
								{...register('Как делать анализ конкуренции?')}
								id='exam841'
								type='radio'
								name='Как делать анализ конкуренции?'
								autoComplete='off'
								value='Посмотреть цены на сайте'
							/>
							<label htmlFor='exam841' className={s.label}>
							Посмотреть цены на сайте
							</label>
						</div>

						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question84,
								})}
								{...register('Как делать анализ конкуренции?')}
								id='exam842'
								type='radio'
								name='Как делать анализ конкуренции?'
								autoComplete='off'
								value='Позвонить как тайный покупатель по заказам с разными видами работ'
							/>
							<label htmlFor='exam842' className={s.label}>
							Позвонить как тайный покупатель по заказам с разными видами работ
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question84,
								})}
								{...register('Как делать анализ конкуренции?')}
								id='exam843'
								type='radio'
								name='Как делать анализ конкуренции?'
								autoComplete='off'
								value='Встретиться с конкурентом лично'
							/>
							<label htmlFor='exam843' className={s.label}>
							Встретиться с конкурентом лично
							</label>
						</div>
					</div>

					<p className={s.errorText}>
						{errors['Как делать анализ конкуренции?'] ? 'Необходимо указать' : ''}
					</p>
				</div>

                
				<div className={s.btns}>
        <Button callback={() => dispatch(setAddStep(28))} back>
						{width > 575 ? (
							'   Назад'
						) : (
							<img src='/static/lk/IconBackward.svg' alt='назад' />
						)}
					</Button>
					<Button>Далее 29 из 35</Button>
				</div>
			</Form>

			<img
				className={s.illustration}
				src='/static/lk/illustration/illustration.png'
				alt='Иллюстрация'
			/>
		</div>
	);
};

export default Step29;
