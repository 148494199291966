import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { setAddPlacePicker } from '../../../../../store/reducer/lk/training/slice.js';
import s from '../../training.module.scss';

const PlacePicker = () => {
	const [ulMenu, setUlMenu] = useState(false);
	const [allPlace, setAllPlace] = useState(false);
	const [activePlace, setActivePlace] = useState(1);

	const dispatch = useDispatch();

	const place = [{ text: '1 место' }, { text: '2 места' }, { text: '3 места' }];

	useEffect(() => {
		dispatch(setAddPlacePicker(activePlace));
		localStorage.setItem('placePicker', JSON.stringify(activePlace));
	}, [activePlace]);

	const showModalMenu = (bol, type, value, i) => {
		setUlMenu(bol => !bol);
		setAllPlace(bol);
		setValue(type, value);
		setActivePlace(i + 1);
	};

	const onClickArrow = bol => {
		setUlMenu(bol => !bol);
		setAllPlace(bol);
	};

	const { setValue, register } = useForm({});

	return (
		<div className={s.place_picker}>
			<div className={s.place_title}>Забронируйте для меня</div>

			<div className={s.item_place}>
				<div className={s.wrapperInput}>
					<input
						className={s.input_place}
						{...register('place')}
						name='place'
						id='place'
						defaultValue='1 место'
						autoComplete='off'
						disabled
					/>

					{!allPlace ? (
						<svg
							className={s.arrow}
							onClick={() => onClickArrow(true)}
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path d='M6.5 9L12 15L17.5 9H6.5Z' fill='#71869D' />
						</svg>
					) : (
						<svg
							className={s.arrow}
							onClick={() => onClickArrow(false)}
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path d='M6.5 15L12 9L17.5 15H6.5Z' fill='#71869D' />
						</svg>
					)}

					{ulMenu && (
						<ul className={s.menu}>
							{place.map((item, i) => (
								<li
									onClick={() => showModalMenu(false, 'place', item.text, i)}
									key={item.text}
									className={cn(s.city, {
										[s.place_active]: i + 1 == activePlace,
									})}
								>
									{item.text}
								</li>
							))}
						</ul>
					)}
				</div>
			</div>
		</div>
	);
};

export default PlacePicker;
