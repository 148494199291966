import cn from 'classnames';
import s from '../steps.module.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import {
	setAddData,
	setAddStep,
} from '../../../../../store/reducer/lk/formExam/slice';

import { selectLkFormExam } from '../../../../../store/reducer/lk/formExam/selector';
import Button from '../../Button/Button';
import Form from '../../Form';

const Step34 = () => {
	const [width, setWidth] = useState(window?.innerWidth);
	const dispatch = useDispatch();
	const { data, step } = useSelector(selectLkFormExam);
	const [isError98, setError98] = useState(false);
	const [isSendForm, setIsSendForm] = useState(false);

	useEffect(() => {
		localStorage.setItem('stepLkExam', JSON.stringify(step));
		localStorage.setItem('dataExam', JSON.stringify(data));
	}, []);
    console.log(data)
	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);
    
	const schema = yup
		.object({
			['Что необходимо дополнительно приобрести для исходящих вызовов?']: yup.string(),
            ['Куда идет переадресация при звонке от клиентов и соискателей?']: yup.string(),
		})
		.required();

	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
		watch,
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			['Что необходимо дополнительно приобрести для исходящих вызовов?']: data['Что необходимо дополнительно приобрести для исходящих вызовов?'],
			['Куда идет переадресация при звонке от клиентов и соискателей?']: data['Куда идет переадресация при звонке от клиентов и соискателей?'],
			['Какие линии настраиваются в телефонии?']: data['Какие линии настраиваются в телефонии?'],
		},
	});

	
	const ckbox98 = watch('Какие линии настраиваются в телефонии?');
    
	
	useEffect(() => {
		if (!ckbox98?.length) {
			setError98(true);
		} else {
			setError98(false);
		}
	}, [ckbox98]);

	const onSubmit = data => {
		setIsSendForm(true);

		if (!isError98) {
			dispatch(setAddStep(35));
			dispatch(setAddData(data));
			setIsSendForm(false);

			localStorage.setItem('dataExam', JSON.stringify(data));
			localStorage.setItem('stepLkExam', JSON.stringify(34));
		}
	};

	return (
		<div className={s.step34}>
            <div className={s.wrapper_heading}>
			  <h5>Телефония</h5>
			</div>
			<Form onSubmit={handleSubmit(onSubmit)}>

                <div className={s.form_item}>
					<h4 className={s.form_title}>96. Что необходимо дополнительно приобрести для исходящих вызовов?</h4>

					<div className={s.question96}>
                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question96,
								})}
								{...register('Что необходимо дополнительно приобрести для исходящих вызовов?')}
								id='exam961'
								type='radio'
								name='Что необходимо дополнительно приобрести для исходящих вызовов?'
								autoComplete='off'
								value='2 физические сим карты, 1 или 2 SIP -аппарата'
							/>
							<label htmlFor='exam961' className={s.label}>
							2 физические сим карты, 1 или 2 SIP -аппарата
							</label>
						</div>

						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question96,
								})}
								{...register('Что необходимо дополнительно приобрести для исходящих вызовов?')}
								id='exam962'
								type='radio'
								name='Что необходимо дополнительно приобрести для исходящих вызовов?'
								autoComplete='off'
								value='3 физические сим карты, 2 SIP -аппарата'
							/>
							<label htmlFor='exam962' className={s.label}>
							3 физические сим карты, 2 SIP -аппарата
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question96,
								})}
								{...register('Что необходимо дополнительно приобрести для исходящих вызовов?')}
								id='exam963'
								type='radio'
								name='Что необходимо дополнительно приобрести для исходящих вызовов?'
								autoComplete='off'
								value='Ничего не нужно дополнительно приобретать'
							/>
							<label htmlFor='exam963' className={s.label}>
							Ничего не нужно дополнительно приобретать
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question96,
								})}
								{...register('Что необходимо дополнительно приобрести для исходящих вызовов?')}
								id='exam964'
								type='radio'
								name='Что необходимо дополнительно приобрести для исходящих вызовов?'
								autoComplete='off'
								value='Только SIP -аппараты'
							/>
							<label htmlFor='exam964' className={s.label}>
							Только SIP -аппараты
							</label>
						</div>
					</div>

					<p className={s.errorText}>
						{errors['Что необходимо дополнительно приобрести для исходящих вызовов?'] ? 'Необходимо указать' : ''}
					</p>
				</div>

                <div className={s.form_item}>
					<h4 className={s.form_title}>97. Куда идет переадресация при звонке от клиентов и соискателей?</h4>

					<div className={s.question97}>
                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question97,
								})}
								{...register('Куда идет переадресация при звонке от клиентов и соискателей?')}
								id='exam971'
								type='radio'
								name='Куда идет переадресация при звонке от клиентов и соискателей?'
								autoComplete='off'
								value='На компьютер'
							/>
							<label htmlFor='exam971' className={s.label}>
							На компьютер
							</label>
						</div>

						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question97,
								})}
								{...register('Куда идет переадресация при звонке от клиентов и соискателей?')}
								id='exam972'
								type='radio'
								name='Куда идет переадресация при звонке от клиентов и соискателей?'
								autoComplete='off'
								value='Никуда'
							/>
							<label htmlFor='exam972' className={s.label}>
							Никуда
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question97,
								})}
								{...register('Куда идет переадресация при звонке от клиентов и соискателей?')}
								id='exam973'
								type='radio'
								name='Куда идет переадресация при звонке от клиентов и соискателей?'
								autoComplete='off'
								value='В Контактный центр'
							/>
							<label htmlFor='exam973' className={s.label}>
							В Контактный центр
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question97,
								})}
								{...register('Куда идет переадресация при звонке от клиентов и соискателей?')}
								id='exam974'
								type='radio'
								name='Куда идет переадресация при звонке от клиентов и соискателей?'
								autoComplete='off'
								value='На физическую сим карту'
							/>
							<label htmlFor='exam974' className={s.label}>
							На физическую сим карту
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question97,
								})}
								{...register('Куда идет переадресация при звонке от клиентов и соискателей?')}
								id='exam975'
								type='radio'
								name='Куда идет переадресация при звонке от клиентов и соискателей?'
								autoComplete='off'
								value='На SIP- аппарат'
							/>
							<label htmlFor='exam975' className={s.label}>
							На SIP- аппарат
							</label>
						</div>
					</div>

					<p className={s.errorText}>
						{errors['Куда идет переадресация при звонке от клиентов и соискателей?'] ? 'Необходимо указать' : ''}
					</p>
				</div>

                <div className={s.form_item}>
					<h4 className={s.form_title}>
					98. Какие линии настраиваются в телефонии?
					</h4>

					<div className={s.question98}>
						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question98,
								})}
								{...register('Какие линии настраиваются в телефонии?')}
								id='exam981'
								type='checkbox'
								name='Какие линии настраиваются в телефонии?'
								autoComplete='off'
								value='Линия клиентов'/>
							<label htmlFor='exam981' className={s.label}>
							Линия клиентов
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question98,
								})}
								{...register('Какие линии настраиваются в телефонии?')}
								id='exam982'
								type='checkbox'
								name='Какие линии настраиваются в телефонии?'
								autoComplete='off'
								value='Нет отдельных линий'/>
							<label htmlFor='exam982' className={s.label}>
							Нет отдельных линий
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question98,
								})}
								{...register('Какие линии настраиваются в телефонии?')}
								id='exam983'
								type='checkbox'
								name='Какие линии настраиваются в телефонии?'
								autoComplete='off'
								value='Линия соискателей'/>
							<label htmlFor='exam983' className={s.label}>
							Линия соискателей
							</label>
						</div>

                        <div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question98,
								})}
								{...register('Какие линии настраиваются в телефонии?')}
								id='exam984'
								type='checkbox'
								name='Какие линии настраиваются в телефонии?'
								autoComplete='off'
								value='Линия  приложения'/>
							<label htmlFor='exam984' className={s.label}>
							Линия  приложения
							</label>
						</div>

						<p className={s.errorText2}>
							{isError98 &&
								isSendForm &&
								'Необходимо указать'}
						</p>
					</div>
				</div>
                
				<div className={s.btns}>
        <Button callback={() => dispatch(setAddStep(33))} back>
						{width > 575 ? (
							'   Назад'
						) : (
							<img src='/static/lk/IconBackward.svg' alt='назад' />
						)}
					</Button>
					<Button>Далее 34 из 35</Button>
				</div>
			</Form>

			<img
				className={s.illustration}
				src='/static/lk/illustration/illustration.png'
				alt='Иллюстрация'
			/>
		</div>
	);
};

export default Step34;
