import cn from 'classnames'
import s from '../steps.module.scss'

import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'
import { setAddData, setAddStep } from '../../../../../store/reducer/lk/formExam/slice'

import { selectLkFormExam } from '../../../../../store/reducer/lk/formExam/selector'
import Button from '../../Button/Button'
import Form from '../../Form'

const Step5 = () => {
  const [width, setWidth] = useState(window?.innerWidth)
  const dispatch = useDispatch()
  const { data, step } = useSelector(selectLkFormExam)
  const [isError14, setError14] = useState(false)
  const [isSendForm, setIsSendForm] = useState(false)

  useEffect(() => {
    localStorage.setItem('stepLkExam', JSON.stringify(step))
    localStorage.setItem('dataExam', JSON.stringify(data))
  }, [])

  useEffect(() => {
    const cb = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', cb)

    return () => window.removeEventListener('resize', cb)
  }, [])

  const schema13 = yup
    .object({
      ['Кто важнее для нашего бизнеса: исполнители или клиенты?']: yup.string(),
      ['В какие месяцы самый низкий клиентский спрос?']: yup.string(),
    })
    .required()

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm({
    resolver: yupResolver(schema13),
    defaultValues: {
      ['Кто важнее для нашего бизнеса: исполнители или клиенты?']:
        data['Кто важнее для нашего бизнеса: исполнители или клиенты?'],
      ['Какие месяцы являются пиковыми по клиентскому спросу?']:
        data['Какие месяцы являются пиковыми по клиентскому спросу?'],
      ['В какие месяцы самый низкий клиентский спрос?']:
        data['В какие месяцы самый низкий клиентский спрос?'],
    },
  })

  const ckbox14 = watch('Какие месяцы являются пиковыми по клиентскому спросу?')

  useEffect(() => {
    if (!ckbox14?.length) {
      setError14(true)
    } else {
      setError14(false)
    }
  }, [ckbox14])

  const onSubmit = (data) => {
    setIsSendForm(true)

    if (!isError14) {
      dispatch(setAddStep(6))
      dispatch(setAddData(data))
      setIsSendForm(false)

      localStorage.setItem('dataExam', JSON.stringify(data))
      localStorage.setItem('stepLkExam', JSON.stringify(5))
    }
  }

  return (
    <div className={s.step5}>
      <div className={s.wrapper_heading}>
        <h5>Скилла. Бизнес-модель</h5>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={s.form_item}>
          <h4 className={s.form_title}>13. Кто важнее для нашего бизнеса: исполнители или клиенты?</h4>

          <div className={s.question13}>
            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question13,
                })}
                {...register('Кто важнее для нашего бизнеса: исполнители или клиенты?')}
                id="exam131"
                type="radio"
                name="Кто важнее для нашего бизнеса: исполнители или клиенты?"
                autoComplete="off"
                value="Исполнители"
              />

              <label htmlFor="exam131" className={s.label}>
                Исполнители
              </label>
            </div>

            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question13,
                })}
                {...register('Кто важнее для нашего бизнеса: исполнители или клиенты?')}
                id="exam132"
                type="radio"
                name="Кто важнее для нашего бизнеса: исполнители или клиенты?"
                autoComplete="off"
                value="Клиенты"
              />
              <label htmlFor="exam132" className={s.label}>
                Клиенты
              </label>
            </div>

            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question13,
                })}
                {...register('Кто важнее для нашего бизнеса: исполнители или клиенты?')}
                id="exam133"
                type="radio"
                name="Кто важнее для нашего бизнеса: исполнители или клиенты?"
                autoComplete="off"
                value="И те,  и другие"
              />
              <label htmlFor="exam133" className={s.label}>
                И те, и другие
              </label>
            </div>
          </div>

          <p className={s.errorText}>
            {errors['Кто важнее для нашего бизнеса: исполнители или клиенты?'] ? 'Необходимо указать' : ''}
          </p>
        </div>

        <div className={s.form_item}>
          <h4 className={s.form_title}>14. Какие месяцы являются пиковыми по клиентскому спросу?</h4>

          <div className={s.question14}>
            <div className={s.checkbox}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question14,
                })}
                {...register('Какие месяцы являются пиковыми по клиентскому спросу?')}
                id="exam141"
                type="checkbox"
                name="Какие месяцы являются пиковыми по клиентскому спросу?"
                autoComplete="off"
                value="Январь, Февраль"
              />
              <label htmlFor="exam141" className={s.label}>
                Январь, Февраль
              </label>
            </div>

            <div className={s.checkbox}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question14,
                })}
                {...register('Какие месяцы являются пиковыми по клиентскому спросу?')}
                id="exam142"
                type="checkbox"
                name="Какие месяцы являются пиковыми по клиентскому спросу?"
                autoComplete="off"
                value="Март, Апрель, Май, Август"
              />
              <label htmlFor="exam142" className={s.label}>
                Март, Апрель, Май, Август
              </label>
            </div>

            <div className={s.checkbox}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question14,
                })}
                {...register('Какие месяцы являются пиковыми по клиентскому спросу?')}
                id="exam143"
                type="checkbox"
                name="Какие месяцы являются пиковыми по клиентскому спросу?"
                autoComplete="off"
                value="Июнь, Июль, Декабрь"
              />
              <label htmlFor="exam143" className={s.label}>
                Июнь, Июль, Декабрь
              </label>
            </div>

            <div className={s.checkbox}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question14,
                })}
                {...register('Какие месяцы являются пиковыми по клиентскому спросу?')}
                id="exam144"
                type="checkbox"
                name="Какие месяцы являются пиковыми по клиентскому спросу?"
                autoComplete="off"
                value="Сентябрь, Октябрь, Ноябрь"
              />
              <label htmlFor="exam144" className={s.label}>
                Сентябрь, Октябрь, Ноябрь
              </label>
            </div>

            <p className={s.errorText2}>{isError14 && isSendForm && 'Необходимо указать'}</p>
          </div>
        </div>

        <div className={s.form_item}>
          <h4 className={s.form_title}>15. В какие месяцы самый низкий клиентский спрос?</h4>

          <div className={s.question15}>
            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question15,
                })}
                {...register('В какие месяцы самый низкий клиентский спрос?')}
                id="exam151"
                type="radio"
                name="В какие месяцы самый низкий клиентский спрос?"
                autoComplete="off"
                value="Январь, Февраль"
              />
              <label htmlFor="exam151" className={s.label}>
                Январь, Февраль
              </label>
            </div>

            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question15,
                })}
                {...register('В какие месяцы самый низкий клиентский спрос?')}
                id="exam152"
                type="radio"
                name="В какие месяцы самый низкий клиентский спрос?"
                autoComplete="off"
                value="Март, Апрель, Май, Август"
              />
              <label htmlFor="exam152" className={s.label}>
                Март, Апрель, Май, Август
              </label>
            </div>

            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question15,
                })}
                {...register('В какие месяцы самый низкий клиентский спрос?')}
                id="exam153"
                type="radio"
                name="В какие месяцы самый низкий клиентский спрос?"
                autoComplete="off"
                value="Июнь, Июль, Декабрь"
              />
              <label htmlFor="exam153" className={s.label}>
                Июнь, Июль, Декабрь
              </label>
            </div>

            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question15,
                })}
                {...register('В какие месяцы самый низкий клиентский спрос?')}
                id="exam154"
                type="radio"
                name="В какие месяцы самый низкий клиентский спрос?"
                autoComplete="off"
                value="Сентябрь, Октябрь, Ноябрь"
              />
              <label htmlFor="exam154" className={s.label}>
                Сентябрь, Октябрь, Ноябрь
              </label>
            </div>
          </div>

          <p className={s.errorText}>
            {errors['В какие месяцы самый низкий клиентский спрос?'] ? 'Необходимо указать' : ''}
          </p>
        </div>

        <div className={s.btns}>
          <Button callback={() => dispatch(setAddStep(4))} back>
            {width > 575 ? '   Назад' : <img src="/static/lk/IconBackward.svg" alt="назад" />}
          </Button>
          <Button>Далее 5 из 35</Button>
        </div>
      </Form>

      <img className={s.illustration} src="/static/lk/illustration/illustration.png" alt="Иллюстрация" />
    </div>
  )
}

export default Step5
