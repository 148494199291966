import cn from 'classnames';
import s from '../steps.module.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import {
	setAddData,
	setAddStep,
} from '../../../../../store/reducer/lk/formExam/slice';

import { selectLkFormExam } from '../../../../../store/reducer/lk/formExam/selector';
import Button from '../../Button/Button';
import Form from '../../Form';

const Step19 = () => {
	const [width, setWidth] = useState(window?.innerWidth);
	const dispatch = useDispatch();
	const { data, step } = useSelector(selectLkFormExam);
	const [isError52, setError52] = useState(false);
    const [isError53, setError53] = useState(false);
	const [isSendForm, setIsSendForm] = useState(false);

	useEffect(() => {
		localStorage.setItem('stepLkExam', JSON.stringify(step));
		localStorage.setItem('dataExam', JSON.stringify(data));
	}, []);

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

	const schema = yup
		.object({
			['Какие документы можно сформировать в блоке Бухгалтера?']: yup.string(),
		})
		.required();

	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
		watch,
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			['Что делает оператор КЦ, если клиент отказался от оформления заказа?']: data['Что делает оператор КЦ, если клиент отказался от оформления заказа?'],
			['Какие плюсы дает размещение предварительных заказов в системе?']: data['Какие плюсы дает размещение предварительных заказов в системе?'],
			['Какие документы можно сформировать в блоке Бухгалтера?']: data['Какие документы можно сформировать в блоке Бухгалтера?'],
		},
	});

	
	const ckbox52 = watch('Что делает оператор КЦ, если клиент отказался от оформления заказа?');
    const ckbox53 = watch('Какие плюсы дает размещение предварительных заказов в системе?');
	
	useEffect(() => {
		if (!ckbox52?.length) {
			setError52(true);
		} else {
			setError52(false);
		}
	}, [ckbox52]);

    useEffect(() => {
		if (!ckbox53?.length) {
			setError53(true);
		} else {
			setError53(false);
		}
	}, [ckbox53]);

	const onSubmit = data => {
		setIsSendForm(true);

		if (!isError52 && !isError53) {
			dispatch(setAddStep(20));
			dispatch(setAddData(data));
			setIsSendForm(false);

			localStorage.setItem('dataExam', JSON.stringify(data));
			localStorage.setItem('stepLkExam', JSON.stringify(19));
		}
	};

	return (
		<div className={s.step19}>
      <div className={s.wrapper_heading}>
			  <h5>Клиенты. Менеджер/Бухгалтер</h5>
			</div>
			<Form onSubmit={handleSubmit(onSubmit)}>

                <div className={s.form_item}>
					<h4 className={s.form_title}>
					52. Что делает оператор КЦ, если клиент отказался от оформления заказа?
					</h4>

					<div className={s.question52}>
						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question52,
								})}
								{...register('Что делает оператор КЦ, если клиент отказался от оформления заказа?')}
								id='exam521'
								type='checkbox'
								name='Что делает оператор КЦ, если клиент отказался от оформления заказа?'
								autoComplete='off'
								value='Добавляет заказ в предварительные, нажав на копку "Не оформлять" в активном скрипте, чтобы информацию по заказу сохранилась'/>
							<label htmlFor='exam521' className={s.label}>
							Добавляет заказ в предварительные, нажав на копку "Не оформлять" в активном скрипте, чтобы информацию по заказу сохранилась
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question52,
								})}
								{...register('Что делает оператор КЦ, если клиент отказался от оформления заказа?')}
								id='exam522'
								type='checkbox'
								name='Что делает оператор КЦ, если клиент отказался от оформления заказа?'
								autoComplete='off'
								value='Закрывает скрипт и забывает о нем'/>
							<label htmlFor='exam522' className={s.label}>
							Закрывает скрипт и забывает о нем
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question52,
								})}
								{...register('Что делает оператор КЦ, если клиент отказался от оформления заказа?')}
								id='exam523'
								type='checkbox'
								name='Что делает оператор КЦ, если клиент отказался от оформления заказа?'
								autoComplete='off'
								value='Предлагает скидку'/>
							<label htmlFor='exam523' className={s.label}>
							Предлагает скидку
							</label>
						</div>

						<p className={s.errorText2}>
							{isError52 &&
								isSendForm &&
								'Необходимо указать'}
						</p>
					</div>
				</div>

                <div className={s.form_item}>
					<h4 className={s.form_title}>
					53. Какие плюсы дает размещение предварительных заказов в системе?
					</h4>

					<div className={s.question53}>
						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question53,
								})}
								{...register('Какие плюсы дает размещение предварительных заказов в системе?')}
								id='exam531'
								type='checkbox'
								name='Какие плюсы дает размещение предварительных заказов в системе?'
								autoComplete='off'
								value='Номера клиентов и имена остаются в базе'/>
							<label htmlFor='exam531' className={s.label}>
							Номера клиентов и имена остаются в базе
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question53,
								})}
								{...register('Какие плюсы дает размещение предварительных заказов в системе?')}
								id='exam532'
								type='checkbox'
								name='Какие плюсы дает размещение предварительных заказов в системе?'
								autoComplete='off'
								value='Сохраняется статистика по услугам, ценам и описанию задач клиентов'/>
							<label htmlFor='exam532' className={s.label}>
							Сохраняется статистика по услугам, ценам и описанию задач клиентов
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question53,
								})}
								{...register('Какие плюсы дает размещение предварительных заказов в системе?')}
								id='exam533'
								type='checkbox'
								name='Какие плюсы дает размещение предварительных заказов в системе?'
								autoComplete='off'
								value='Система показывает сумму минимальной выручки, которую вы упустили'/>
							<label htmlFor='exam533' className={s.label}>
							Система показывает сумму минимальной выручки, которую вы упустили
							</label>
						</div>

                        <div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question53,
								})}
								{...register('Какие плюсы дает размещение предварительных заказов в системе?')}
								id='exam534'
								type='checkbox'
								name='Какие плюсы дает размещение предварительных заказов в системе?'
								autoComplete='off'
								value='В будущем можно звонить данным клиентам с предложением своих услуг'/>
							<label htmlFor='exam534' className={s.label}>
							В будущем можно звонить данным клиентам с предложением своих услуг
							</label>
						</div>

                        <div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question53,
								})}
								{...register('Какие плюсы дает размещение предварительных заказов в системе?')}
								id='exam535'
								type='checkbox'
								name='Какие плюсы дает размещение предварительных заказов в системе?'
								autoComplete='off'
								value='Использовать номер клиента для СМС рассылки с предложением своих услуг'/>
							<label htmlFor='exam535' className={s.label}>
							Использовать номер клиента для СМС рассылки с предложением своих услуг
							</label>
						</div>

						<p className={s.errorText2}>
							{isError53 &&
								isSendForm &&
								'Необходимо указать'}
						</p>
					</div>
				</div>

				<div className={s.form_item}>
					<h4 className={s.form_title}>54. Какие документы можно сформировать в блоке Бухгалтера?</h4>

					<div className={s.question54}>
                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question54,
								})}
								{...register('Какие документы можно сформировать в блоке Бухгалтера?')}
								id='exam541'
								type='radio'
								name='Какие документы можно сформировать в блоке Бухгалтера?'
								autoComplete='off'
								value='Договор'
							/>
							<label htmlFor='exam541' className={s.label}>
							Договор
							</label>
						</div>

						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question54,
								})}
								{...register('Какие документы можно сформировать в блоке Бухгалтера?')}
								id='exam542'
								type='radio'
								name='Какие документы можно сформировать в блоке Бухгалтера?'
								autoComplete='off'
								value='Счет'
							/>
							<label htmlFor='exam542' className={s.label}>
							Счет
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question54,
								})}
								{...register('Какие документы можно сформировать в блоке Бухгалтера?')}
								id='exam543'
								type='radio'
								name='Какие документы можно сформировать в блоке Бухгалтера?'
								autoComplete='off'
								value='Акт'
							/>
							<label htmlFor='exam543' className={s.label}>
							Акт
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question54,
								})}
								{...register('Какие документы можно сформировать в блоке Бухгалтера?')}
								id='exam544'
								type='radio'
								name='Какие документы можно сформировать в блоке Бухгалтера?'
								autoComplete='off'
								value='Счет-фактура'
							/>
							<label htmlFor='exam544' className={s.label}>
							Счет-фактура
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question54,
								})}
								{...register('Какие документы можно сформировать в блоке Бухгалтера?')}
								id='exam545'
								type='radio'
								name='Какие документы можно сформировать в блоке Бухгалтера?'
								autoComplete='off'
								value='Детализация'
							/>
							<label htmlFor='exam545' className={s.label}>
							Детализация
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question54,
								})}
								{...register('Какие документы можно сформировать в блоке Бухгалтера?')}
								id='exam546'
								type='radio'
								name='Какие документы можно сформировать в блоке Бухгалтера?'
								autoComplete='off'
								value='Все варианты ответов правильные'
							/>
							<label htmlFor='exam546' className={s.label}>
							Все варианты ответов правильные
							</label>
						</div>
					</div>

					<p className={s.errorText}>
						{errors['Какие документы можно сформировать в блоке Бухгалтера?'] ? 'Необходимо указать' : ''}
					</p>
				</div>

				<div className={s.btns}>
        <Button callback={() => dispatch(setAddStep(18))} back>
						{width > 575 ? (
							'   Назад'
						) : (
							<img src='/static/lk/IconBackward.svg' alt='назад' />
						)}
					</Button>
					<Button>Далее 19 из 35</Button>
				</div>
			</Form>

			<img
				className={s.illustration}
				src='/static/lk/illustration/illustration.png'
				alt='Иллюстрация'
			/>
		</div>
	);
};

export default Step19;
