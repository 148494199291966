import s from "../steps.module.scss";
import cn from "classnames";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  setAddStep,
  setAddData,
} from "../../../../../store/reducer/lk/form/slice";
import { selectLkForm } from "../../../../../store/reducer/lk/form/selector";

import Form from "../../Form";
import Button from "../../Button/Button";

const Step6 = () => {
  const [width, setWidth] = useState(window?.innerWidth);
  const dispatch = useDispatch();
  const { data, step } = useSelector(selectLkForm);

  useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

  useEffect(() => {
    localStorage.setItem("stepLk", JSON.stringify(step));
    localStorage.setItem("data", JSON.stringify(data));
  }, []);

  const schema = yup
    .object({
      ["Какой срок окупаемости вкладываемых средств в проект вы ожидаете?"]: yup
        .string()
        .required(),
      ["Какую роль вы отводите себе в проекте со Skilla?"]: yup
        .string()
        .required(),
        ["Вы понимаете, что партнерство со Skilla - это бизнес, предпринимательство, а не работа по найму?"]: yup
        .string()
        .required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ["Какой срок окупаемости вкладываемых средств в проект вы ожидаете?"]:
        data[
          "Какой срок окупаемости вкладываемых средств в проект вы ожидаете?"
        ],
      ["Какую роль вы отводите себе в проекте со Skilla?"]:
        data["Какую роль вы отводите себе в проекте со Skilla?"],
        ["Вы понимаете, что партнерство со Skilla - это бизнес, предпринимательство, а не работа по найму?"]:
        data["Вы понимаете, что партнерство со Skilla - это бизнес, предпринимательство, а не работа по найму?"],
    },
  });

  const onSubmit = (data) => {
    dispatch(setAddStep(7));
    dispatch(setAddData(data));

    localStorage.setItem("data", JSON.stringify(data));
    localStorage.setItem("stepLk", JSON.stringify(7));
  };

  return (
    <div className={s.step6}>
      <h1 className={s.title_step5}>Структура инвестиций в проект</h1>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={s.form_item}>
          <h4 className={s.form_title}>
            Какой срок окупаемости вкладываемых средств в проект вы ожидаете?
          </h4>
          <div className={s.radio}>
            <input
              className={cn(s.input, {
                [s.error]: errors.radio,
              })}
              {...register(
                "Какой срок окупаемости вкладываемых средств в проект вы ожидаете?"
              )}
              id="radio1"
              type="radio"
              name="Какой срок окупаемости вкладываемых средств в проект вы ожидаете?"
              autoComplete="off"
              value="1 месяц"
            />

            <label htmlFor="radio1" className={s.label}>
              1 месяц
            </label>
          </div>

          <div className={s.radio}>
            <input
              className={cn(s.input, {
                [s.error]: errors.radio,
              })}
              {...register(
                "Какой срок окупаемости вкладываемых средств в проект вы ожидаете?"
              )}
              id="radio2"
              type="radio"
              name="Какой срок окупаемости вкладываемых средств в проект вы ожидаете?"
              autoComplete="off"
              value="6 месяцев"
            />
            <label htmlFor="radio2" className={s.label}>
              6 месяцев
            </label>
          </div>

          <div className={s.radio}>
            <input
              className={cn(s.input, {
                [s.error]: errors.radio,
              })}
              {...register(
                "Какой срок окупаемости вкладываемых средств в проект вы ожидаете?"
              )}
              value="1 год"
              id="radio3"
              type="radio"
              name="Какой срок окупаемости вкладываемых средств в проект вы ожидаете?"
              autoComplete="off"
            />
            <label htmlFor="radio3" className={s.label}>
              1 год
            </label>
          </div>

          <div className={s.radio}>
            <input
              className={cn(s.input, {
                [s.error]: errors.radio,
              })}
              {...register(
                "Какой срок окупаемости вкладываемых средств в проект вы ожидаете?"
              )}
              value="2 года"
              id="radio4"
              type="radio"
              name="Какой срок окупаемости вкладываемых средств в проект вы ожидаете?"
              autoComplete="off"
            />
            <label htmlFor="radio4" className={s.label}>
              2 года
            </label>
          </div>

          <div className={s.radio}>
            <input
              className={cn(s.input, {
                [s.error]: errors.radio,
              })}
              {...register(
                "Какой срок окупаемости вкладываемых средств в проект вы ожидаете?"
              )}
              id="radio5"
              type="radio"
              name="Какой срок окупаемости вкладываемых средств в проект вы ожидаете?"
              autoComplete="off"
              value="3 года"
            />
            <label htmlFor="radio5" className={s.label}>
              3 года
            </label>
          </div>
          <p className={s.errorText}>
            {errors[
              "Какой срок окупаемости вкладываемых средств в проект вы ожидаете?"
            ]
              ? "Необходимо указать"
              : ""}
          </p>
        </div>

        <div className={s.form_item}>
          <h4 className={s.form_title}>
            Какую роль вы отводите себе в проекте со Skilla?
          </h4>

          <div
            className={cn(s.radio, {
              [s.radio_mb12]: "mb2",
            })}
          >
            <input
              className={cn(s.input, {
                [s.error]: errors.radio,
              })}
              {...register("Какую роль вы отводите себе в проекте со Skilla?")}
              id="radio6"
              type="radio"
              name="Какую роль вы отводите себе в проекте со Skilla?"
              autoComplete="off"
              value="На первом этапе самостоятельно погружусь во все процессы и потом найму управляющего"
            />

            <label htmlFor="radio6" className={s.label_moreText}>
              На первом этапе самостоятельно погружусь во все процессы и потом
              найму управляющего
            </label>
          </div>

          <div
            className={cn(s.radio, {
              [s.radio_mb12]: "mb2",
            })}
          >
            <input
              className={cn(s.input, {
                [s.error]: errors.radio,
              })}
              {...register("Какую роль вы отводите себе в проекте со Skilla?")}
              id="radio7"
              type="radio"
              name="Какую роль вы отводите себе в проекте со Skilla?"
              autoComplete="off"
              value="Сразу планирую назначить управляющего, который будет выстраивать все процессы"
            />
            <label htmlFor="radio7" className={s.label_more}>
              Сразу планирую назначить управляющего, который будет выстраивать
              все процессы
            </label>
          </div>

          <div
            className={cn(s.radio, {
              [s.radio_mb12]: "mb2",
            })}
          >
            <input
              className={cn(s.input, {
                [s.error]: errors.radio,
              })}
              {...register("Какую роль вы отводите себе в проекте со Skilla?")}
              id="radio8"
              type="radio"
              name="Какую роль вы отводите себе в проекте со Skilla?"
              autoComplete="off"
              value="Планирую только инвестировать, без активного участия в управлении"
            />
            <label htmlFor="radio8" className={s.label_moreText}>
              Планирую только инвестировать, без активного участия в управлении
            </label>
          </div>

          <p className={s.errorText}>
            {errors["Какую роль вы отводите себе в проекте со Skilla?"]
              ? "Необходимо указать"
              : ""}
          </p>
        </div>

        <div className={s.form_item}>
          <h4 className={s.form_title}>
          Вы понимаете, что партнерство со Skilla - это бизнес, предпринимательство, а не работа по найму?
          </h4>

          <div
            className={cn(s.radio, {
              [s.radio_mb12]: "mb2",
            })}
          >
            <input
              className={cn(s.input, {
                [s.error]: errors.radio,
              })}
              {...register("Вы понимаете, что партнерство со Skilla - это бизнес, предпринимательство, а не работа по найму?")}
              id="radio9"
              type="radio"
              name="Вы понимаете, что партнерство со Skilla - это бизнес, предпринимательство, а не работа по найму?"
              autoComplete="off"
              value="Да, понимаю"
            />

            <label htmlFor="radio9" className={s.label_moreText}>
              Да, понимаю
            </label>
          </div>

          <div
            className={cn(s.radio, {
              [s.radio_mb12]: "mb2",
            })}
          >
            <input
              className={cn(s.input, {
                [s.error]: errors.radio,
              })}
              {...register("Вы понимаете, что партнерство со Skilla - это бизнес, предпринимательство, а не работа по найму?")}
              id="radio10"
              type="radio"
              name="Вы понимаете, что партнерство со Skilla - это бизнес, предпринимательство, а не работа по найму?"
              autoComplete="off"
              value="Нет, меня устраивает исключительно доход от работы по найму"
            />
            <label htmlFor="radio10" className={s.label_more}>
              Нет, меня устраивает исключительно доход от работы по найму
            </label>
          </div>

          <p className={s.errorText}>
            {errors["Вы понимаете, что партнерство со Skilla - это бизнес, предпринимательство, а не работа по найму?"]
              ? "Необходимо указать"
              : ""}
          </p>
        </div>
       
        <div style={{ marginTop: "214px" }} className={s.btns}>
          <Button callback={() => dispatch(setAddStep(5))} back>
            {width > 575 ? 'Назад' : <img src='/static/lk/IconBackward.svg' alt='назад' />}
          </Button>
          <Button>Далее 6 из 8</Button>
        </div>
      </Form>

      <img
        className={s.illustration}
        src="/static/lk/illustration/illustration.png"
        alt="Иллюстрация"
      />
    </div>
  );
};

export default Step6;
