import cn from 'classnames';
import s from '../steps.module.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import {
	setAddData,
	setAddStep,
} from '../../../../../store/reducer/lk/formExam/slice';

import { selectLkFormExam } from '../../../../../store/reducer/lk/formExam/selector';
import Button from '../../Button/Button';
import Form from '../../Form';

const Step18 = () => {
	const [width, setWidth] = useState(window?.innerWidth);
	const dispatch = useDispatch();
	const { data, step } = useSelector(selectLkFormExam);
	const [isError50, setError50] = useState(false);
	const [isSendForm, setIsSendForm] = useState(false);

	useEffect(() => {
		localStorage.setItem('stepLkExam', JSON.stringify(step));
		localStorage.setItem('dataExam', JSON.stringify(data));
	}, []);

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

	const schema = yup
		.object({
			['В каких модулях (блоках) работает Менеджер по работе с клиентами?']: yup.string(),
            ['Регламент приема входящих звонков от новых клиентов']: yup.string(),
		})
		.required();

	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
		watch,
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			['В каких модулях (блоках) работает Менеджер по работе с клиентами?']: data['В каких модулях (блоках) работает Менеджер по работе с клиентами?'],
			['Обязанности Менеджера по работе с клиентами/Бухгалтера']: data['Обязанности Менеджера по работе с клиентами/Бухгалтера'],
			['Регламент приема входящих звонков от новых клиентов']: data['Регламент приема входящих звонков от новых клиентов'],
		},
	});

	
	const ckbox50 = watch('Обязанности Менеджера по работе с клиентами/Бухгалтера');
	
	useEffect(() => {
		if (!ckbox50?.length) {
			setError50(true);
		} else {
			setError50(false);
		}
	}, [ckbox50]);

	const onSubmit = data => {
		setIsSendForm(true);

		if (!isError50) {
			dispatch(setAddStep(19));
			dispatch(setAddData(data));
			setIsSendForm(false);

			localStorage.setItem('dataExam', JSON.stringify(data));
			localStorage.setItem('stepLkExam', JSON.stringify(18));
		}
	};

	return (
		<div className={s.step18}>
      <div className={s.wrapper_heading}>
			  <h5>Клиенты. Менеджер/Бухгалтер</h5>
			</div>
			<Form onSubmit={handleSubmit(onSubmit)}>

				<div className={s.form_item}>
					<h4 className={s.form_title}>49. В каких модулях (блоках) работает Менеджер по работе с клиентами?</h4>

					<div className={s.question49}>
						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question49,
								})}
								{...register('В каких модулях (блоках) работает Менеджер по работе с клиентами?')}
								id='exam491'
								type='radio'
								name='В каких модулях (блоках) работает Менеджер по работе с клиентами?'
								autoComplete='off'
								value='Блок Клиентского Менеджера'
							/>

							<label htmlFor='exam491' className={s.label}>
							Блок Клиентского Менеджера
							</label>
						</div>

						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question49,
								})}
								{...register('В каких модулях (блоках) работает Менеджер по работе с клиентами?')}
								id='exam492'
								type='radio'
								name='В каких модулях (блоках) работает Менеджер по работе с клиентами?'
								autoComplete='off'
								value='Блок Бухглатера и Бригадира'
							/>

							<label htmlFor='exam492' className={s.label}>
							Блок Бухглатера и Бригадира
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question49,
								})}
								{...register('В каких модулях (блоках) работает Менеджер по работе с клиентами?')}
								id='exam493'
								type='radio'
								name='В каких модулях (блоках) работает Менеджер по работе с клиентами?'
								autoComplete='off'
								value='Блок Бухглатера'
							/>

							<label htmlFor='exam493' className={s.label}>
							Блок Бухглатера
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question49,
								})}
								{...register('В каких модулях (блоках) работает Менеджер по работе с клиентами?')}
								id='exam494'
								type='radio'
								name='В каких модулях (блоках) работает Менеджер по работе с клиентами?'
								autoComplete='off'
								value='Блок Бухгалтера и Клиентского менеджера'
							/>

							<label htmlFor='exam494' className={s.label}>
							Блок Бухгалтера и Клиентского менеджера
							</label>
						</div>
					</div>

					<p className={s.errorText}>
						{errors['В каких модулях (блоках) работает Менеджер по работе с клиентами?'] ? 'Необходимо указать' : ''}
					</p>
				</div>

        <div className={s.form_item}>
					<h4 className={s.form_title}>
					50. Обязанности Менеджера по работе с клиентами/Бухгалтера
					</h4>

					<div className={s.question50}>
					    <div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question50,
								})}
								{...register('Обязанности Менеджера по работе с клиентами/Бухгалтера')}
								id='exam504'
								type='checkbox'
								name='Обязанности Менеджера по работе с клиентами/Бухгалтера'
								autoComplete='off'
								value='Прием первичных звонков от клиентов'/>
							<label htmlFor='exam504' className={s.label}>
							Прием первичных звонков от клиентов
							</label>
						</div>
						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question50,
								})}
								{...register('Обязанности Менеджера по работе с клиентами/Бухгалтера')}
								id='exam506'
								type='checkbox'
								name='Обязанности Менеджера по работе с клиентами/Бухгалтера'
								autoComplete='off'
								value='Добавление заказов в CRM систему'/>
							<label htmlFor='exam506' className={s.label}>
							Добавление заказов в CRM систему
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question50,
								})}
								{...register('Обязанности Менеджера по работе с клиентами/Бухгалтера')}
								id='exam507'
								type='checkbox'
								name='Обязанности Менеджера по работе с клиентами/Бухгалтера'
								autoComplete='off'
								value='Решение конфликтных ситуаций'/>
							<label htmlFor='exam507' className={s.label}>
							Решение конфликтных ситуаций
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question50,
								})}
								{...register('Обязанности Менеджера по работе с клиентами/Бухгалтера')}
								id='exam509'
								type='checkbox'
								name='Обязанности Менеджера по работе с клиентами/Бухгалтера'
								autoComplete='off'
								value='Прием заявок на обратный звонок с сайта'/>
							<label htmlFor='exam509' className={s.label}>
							Прием заявок на обратный звонок с сайта
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question50,
								})}
								{...register('Обязанности Менеджера по работе с клиентами/Бухгалтера')}
								id='exam503'
								type='checkbox'
								name='Обязанности Менеджера по работе с клиентами/Бухгалтера'
								autoComplete='off'
								value='Обработка заказов, добавленных контактным центром'/>
							<label htmlFor='exam503' className={s.label}>
							Обработка заказов, добавленных контактным центром
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question50,
								})}
								{...register('Обязанности Менеджера по работе с клиентами/Бухгалтера')}
								id='exam505'
								type='checkbox'
								name='Обязанности Менеджера по работе с клиентами/Бухгалтера'
								autoComplete='off'
								value='Работа с первичными документами'/>
							<label htmlFor='exam505' className={s.label}>
							Работа с первичными документами
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question50,
								})}
								{...register('Обязанности Менеджера по работе с клиентами/Бухгалтера')}
								id='exam508'
								type='checkbox'
								name='Обязанности Менеджера по работе с клиентами/Бухгалтера'
								autoComplete='off'
								value='Контроль дебиторской задолженности'/>
							<label htmlFor='exam508' className={s.label}>
							Контроль дебиторской задолженности
							</label>
						</div>


						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question50,
								})}
								{...register('Обязанности Менеджера по работе с клиентами/Бухгалтера')}
								id='exam501'
								type='checkbox'
								name='Обязанности Менеджера по работе с клиентами/Бухгалтера'
								autoComplete='off'
								value='Переговоры с ключевыми клиентами'/>
							<label htmlFor='exam501' className={s.label}>
							Переговоры с ключевыми клиентами
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question50,
								})}
								{...register('Обязанности Менеджера по работе с клиентами/Бухгалтера')}
								id='exam502'
								type='checkbox'
								name='Обязанности Менеджера по работе с клиентами/Бухгалтера'
								autoComplete='off'
								value='Назначение исполнителей на заказ'/>
							<label htmlFor='exam502' className={s.label}>
							Назначение исполнителей на заказ
							</label>
						</div>

						<p className={s.errorText2}>
							{isError50 &&
								isSendForm &&
								'Необходимо указать'}
						</p>
					</div>
				</div>

				<div className={s.form_item}>
					<h4 className={s.form_title}>51. Регламент приема входящих звонков от новых клиентов</h4>

					<div className={s.question51}>
                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question51,
								})}
								{...register('Регламент приема входящих звонков от новых клиентов')}
								id='exam511'
								type='radio'
								name='Регламент приема входящих звонков от новых клиентов'
								autoComplete='off'
								value='Принимать звонки без готового сценария разговора (скрипт)'
							/>
							<label htmlFor='exam511' className={s.label}>
							Принимать звонки без готового сценария разговора (скрипт)
							</label>
						</div>

						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question51,
								})}
								{...register('Регламент приема входящих звонков от новых клиентов')}
								id='exam512'
								type='radio'
								name='Регламент приема входящих звонков от новых клиентов'
								autoComplete='off'
								value='Принимать звонки строго по скрипту в независимости от местоположения (активный и классический)'
							/>
							<label htmlFor='exam512' className={s.label}>
							Принимать звонки строго по скрипту в независимости от местоположения (активный и классический)
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question51,
								})}
								{...register('Регламент приема входящих звонков от новых клиентов')}
								id='exam513'
								type='radio'
								name='Регламент приема входящих звонков от новых клиентов'
								autoComplete='off'
								value='В офисе принимать звонки по скрипту, вне офиса  скрипт соблюдать не обязательно'
							/>
							<label htmlFor='exam513' className={s.label}>
							В офисе принимать звонки по скрипту, вне офиса  скрипт соблюдать не обязательно
							</label>
						</div>
					</div>

					<p className={s.errorText}>
						{errors['Регламент приема входящих звонков от новых клиентов'] ? 'Необходимо указать' : ''}
					</p>
				</div>

				<div className={s.btns}>
        <Button callback={() => dispatch(setAddStep(17))} back>
						{width > 575 ? (
							'   Назад'
						) : (
							<img src='/static/lk/IconBackward.svg' alt='назад' />
						)}
					</Button>
					<Button>Далее 18 из 35</Button>
				</div>
			</Form>

			<img
				className={s.illustration}
				src='/static/lk/illustration/illustration.png'
				alt='Иллюстрация'
			/>
		</div>
	);
};

export default Step18;
