import { useParams, Link } from 'react-router-dom'
import { getArticleService, getListAll } from '../../Api'
import { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import parse from 'html-react-parser'
import Slider from 'react-slick'
import ArticlesForm from '../../components/articleForm/ArticlesForm'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import ArticleBtns from '../../components/articleBtns/ArticleBtns'
import SliderArticle from '../../components/sliderArticle/SliderArticle'
import FormLive from '../../components/formLive/FormLive'
import { Helmet } from 'react-helmet-async'
import classNames from 'classnames'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

function ArticlesService({ activeTarget }) {
  const { alias } = useParams()
  const [data, setData] = useState({})
  const [content, setContent] = useState('')
  const [otherServices, setOtherService] = useState([])
  const [index, setIndex] = useState(2)
  const [clickOption, setClickOption] = useState(false)

  const {
    name,
    service_icon,
    subname,
    business_title,
    business_subtitle,
    live_title,
    live_subtitle,
    option1,
    option2,
    option3,
    option4,
    answer,
    title,
  } = data

  const nextArticle = () => {
    showQuestion(false)
    if (otherServices.length !== index + 1) {
      setIndex((prev) => prev + 1)
    } else {
      setIndex(0)
    }
  }

  useEffect(() => {
    getArticleService(alias).then(({ data }) => {
      setData(data.article)
      setContent(data.article.content)
    })
    window.scroll(0, 0)
  }, [alias, index, otherServices])

  useEffect(() => {
    getListAll('service')
      .then((response) => {
        setOtherService(response.data.results)
      })
      .catch((e) => {
        console.log(e)
      })
  }, [])

  const settings = {
    className: activeTarget === 'business' ? 'article-slider-business' : 'article-slider-worker',
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          infinite: true,
          speed: 300,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        },
      },
    ],
  }

  const settingsCustomer = {
    className: 'article-slider-customer',
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          infinite: true,
          speed: 300,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        },
      },
    ],
  }

  const showQuestion = useCallback((bool) => {
    setClickOption(bool)
  }, [])

  const setActiveTarget = useCallback((activeTarget) => {
    switch (activeTarget) {
      case 'business':
        return <Navbar page="business" type="article" activePage="article" />

      case 'customer':
        return <Navbar page="customer" type="article" activePage="article" />

      case 'worker':
        return <Navbar page="worker" type="article" activePage="article" />
    }
  }, [])

  const setActiveTargetFooter = useCallback((activeTarget) => {
    switch (activeTarget) {
      case 'business':
        return <Footer page="business" type="faq" />

      case 'customer':
        return <Footer page="customer" type="faq" />

      case 'worker':
        return <Footer page="worker" type="faq" />
    }
  }, [])

  return (
    <div className="articles">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={business_title} />
      </Helmet>
      {setActiveTarget(activeTarget)}
      <div className="articles__header header-articles">
        <div className="articles__header-service">
          <img src={`https://static.skilla.ru/articles/${service_icon}`} alt={name} />
        </div>

        <div className="container">
          <div className="header-articles__intro">
            <h1 className="title-header-h1">{name}</h1>
            <h2 className="title-header-h2">{subname}</h2>

            {data.services && data.services.length > 0 ? (
              <ArticleBtns type={'header'} keys="services" />
            ) : null}
          </div>
        </div>
      </div>

      <StyledContainer>
        <div className="articles__content">
          <div className="articles__content-padding" style={{ paddingTop: '40px' }}>
            {parse(content)}
          </div>

          <FormLive
            live_subtitle={live_subtitle}
            live_title={live_title}
            option={{ option1, option2, option3, option4 }}
            answer={answer}
            showQuestion={showQuestion}
            clickOption={clickOption}
          />
        </div>

        <div className="articles__static">
          {data.services && data.services.length > 0 ? <ArticleBtns type={'footer'} keys="services" /> : null}

          <ArticlesForm title={business_title} subtitle={business_subtitle} />

          <div className="article__next-article article-next">
            <h3 className="article-next__title">Следующая статья</h3>
            {otherServices[index] && (
              <div className="article-next__wrapper">
                <img
                  className="article-next__banner"
                  src={otherServices[index].service_icon}
                  alt="Слудующая статья"
                />
                <div className="article-next__name">{otherServices[index].name}</div>
                <div className="article-next__descr">{otherServices[index].service_slogan}</div>

                <Link
                  onClick={nextArticle}
                  to={`/${otherServices[index].alias}`}
                  className={classNames({
                    'article-next__wrapper-btn article-next__wrapper-btn--customer':
                      activeTarget === 'customer',
                    'article-next__wrapper-btn article-next__wrapper-btn--worker': activeTarget === 'worker',
                    'article-next__wrapper-btn article-next__wrapper-btn--business':
                      activeTarget === 'business',
                  })}
                >
                  <span>Читать</span>

                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 10 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.29286 5.00003H0V4.00003H7.29285L4.64641 1.35359L5.35352 0.646484L9.20707 4.50004L5.35352 8.35359L4.64641 7.64648L7.29286 5.00003Z"
                      fill="#000729"
                    />
                  </svg>
                </Link>
              </div>
            )}
          </div>

          <div className="article__other-articles articles-other">
            <h2 className="articles-other__title">Похожие статьи</h2>
            {activeTarget === 'customer' ? (
              <Slider {...settingsCustomer}>
                {otherServices.map((item) => (
                  <SliderArticle
                    key={item.alias}
                    {...item}
                    showQuestion={showQuestion}
                    type="services"
                    activeTarget={activeTarget}
                  />
                ))}
              </Slider>
            ) : (
              <Slider {...settings}>
                {otherServices.map((item) => (
                  <SliderArticle
                    key={item.alias}
                    {...item}
                    showQuestion={showQuestion}
                    type="services"
                    activeTarget={activeTarget}
                  />
                ))}
              </Slider>
            )}
          </div>
        </div>
      </StyledContainer>
      {setActiveTargetFooter(activeTarget)}
    </div>
  )
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1400px;
  margin: 0 auto;

  .articles__content-padding {
    padding-top: 70px !important;
  }

  a {
    color: #009953;
  }

  .article-next__wrapper-btn {
    &--worker {
      &:hover {
        background-color: #8800ff;
        span {
          color: #fff;
        }

        svg {
          path {
            fill: #fff;
          }
        }
      }
    }

    &--customer {
      &:hover {
        background-color: #002cfb;
        span {
          color: #fff;
        }

        svg {
          path {
            fill: #fff;
          }
        }
      }
    }

    &--business {
      &:hover {
        background-color: #00ff88;
        span {
          color: #000;
        }

        svg {
          path {
            fill: #000;
          }
        }
      }
    }
  }

  .slick-next,
  .slick-prev {
    width: 40px;
    height: 40px;

    border-radius: 300px;
    background-color: #fff !important;

    z-index: 1;

    &:hover {
      background-color: #3357ff;
      border: none;
    }
  }

  .slick-prev {
    top: 80px;
    left: 0;
  }
  .slick-next {
    top: 80px;
    right: 0;
  }

  .slick-prev::before {
    content: url('/static/article/icon/arrow-slide-left.svg');

    display: block;
  }

  .slick-prev:hover::before {
    content: url('/static/article/icon/arrow-slide-left-hover.svg');
  }

  .slick-next::before {
    content: url('/static/article/icon/arrow-slide.svg');

    display: block;
  }

  .slick-next:hover::before {
    content: url('/static/article/icon/arrow-slide-hover.svg');
  }

  .slick-prev {
    @media (max-width: 768px) {
      left: 130px;
    }
  }

  .slick-next {
    @media (max-width: 768px) {
      left: 270px;
    }
  }

  .slick-arrow {
    background-color: #f9f9f9;
    transition: all 0.3s ease;
    border: 1px solid #0f1324;

    @media (max-width: 768px) {
      position: absolute;
      top: -30px;
    }
  }

  .slick-slider {
    @media (max-width: 768px) {
      top: 60px;
    }
  }

  .article-slider-customer {
    .slick-next,
    .slick-prev {
      width: 40px;
      height: 40px;

      border-radius: 300px;
      background-color: #fff !important;

      z-index: 1;

      &:hover {
        background-color: #002cfb !important;
        border: none;
      }
    }
  }

  .article-slider-business {
    .slick-next,
    .slick-prev {
      width: 40px;
      height: 40px;

      border-radius: 300px;
      background-color: #fff !important;

      z-index: 1;

      &:hover {
        background-color: #00ff88 !important;
        border: none;
      }
    }
  }

  .slick-track {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  .article-slider-worker {
    .slick-next,
    .slick-prev {
      width: 40px;
      height: 40px;

      border-radius: 300px;
      background-color: #fff !important;

      z-index: 1;

      &:hover {
        background-color: #8800ff !important;
        border: none;
      }
    }
  }

  .articles__content {
    width: 100%;

    img {
      display: flex;
    }
  }

  .articles-other__title {
    margin: 16px 0 !important;
    @media (max-width: 768px) {
      //width: 343px;
      margin: 16px 0;
      text-align: center;
    }
  }

  .articlesForm-footer__descr {
    @media (max-width: 768px) {
      margin: 0;
    }
  }
`

export default ArticlesService
