import { useEffect, useState } from 'react';
import s from './Entity.module.scss';
import Documents from './Documents/Documents';
import { sendInn } from '../../../Api';

function Entity() {
    const [currentEntity, setCurrentEntity] = useState(false);
    const [typeOrg, setTypeOrg] = useState(false);
    const [name, setName] = useState(JSON.parse(localStorage.getItem('name')) || '');
    const [surname, setSurame] = useState(JSON.parse(localStorage.getItem('surname')) || '');
    const [surname2, setSurame2] = useState(JSON.parse(localStorage.getItem('surname2')) || '');
    const [tel, setTel] = useState(JSON.parse(localStorage.getItem('tel')) || '');
    const [telErr, settelErr] = useState(false);
    const [snils, setSnils] = useState(JSON.parse(localStorage.getItem('snils')) || '');
    const [snilsErr, setSnilsErr] = useState(false);
    const [email, setEmail] = useState(JSON.parse(localStorage.getItem('email')) || '');
    const [emailErr, setEmailErr] = useState(false);
    const [inn, setInn] = useState(JSON.parse(localStorage.getItem('inn')) || '');
    const [org, setOrg] = useState(JSON.parse(localStorage.getItem('org')) || '');
    const [orgList, setOrgList] = useState([]);
    const [buttonActive, setButtonActive] = useState(false);
    const [documents, setDocuments] = useState(false);
    const [done, setDone] = useState(false);
    const EMAIL_REG = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
    const SNILS_REG = /[^0-9]/;


    console.log(name, surname, surname2, tel, snils, email)

    useEffect(() => {
        if (!typeOrg && !currentEntity) {
            if (name.length > 1 && surname.length > 1 && surname2.length > 1 && tel.length > 5 && snils.length == 11 && email.length > 1) {
                setButtonActive(true);
            } else {
                setButtonActive(false);
            }
            return
        }

        if (currentEntity) {
            if (inn.length > 1 && org.length > 1) {
                setButtonActive(true);
            } else {
                setButtonActive(false);
            }
            return
        }



    }, [name, surname, surname2, tel, snils, email, typeOrg, inn, org, currentEntity])

    function handleCurrent() {
        setCurrentEntity(true)
    }

    function handleNew() {
        setCurrentEntity(false)
    }

    function handleOrgIp() {
        setTypeOrg(false)
    }

    function handleOrgOoo() {
        setTypeOrg(true)
    }

    function handleInput(e) {
        const id = e.target.id;
        const value = e.target.value;

        if (id === 'фамилия') {
            setSurame(value);
            localStorage.setItem('surname', JSON.stringify(value));
            return
        }

        if (id === 'имя') {
            setName(value);
            localStorage.setItem('name', JSON.stringify(value));
            return
        }

        if (id === 'отчество') {
            setSurame2(value);
            localStorage.setItem('surname2', JSON.stringify(value));
            return
        }

        if (id === 'телефон') {
            setTel(value);
            localStorage.setItem('tel', JSON.stringify(value));
            return
        }

        if (id === 'снилс') {
            setSnils(value);
            localStorage.setItem('snils', JSON.stringify(value));
            return
        }

        if (id === 'email') {
            setEmail(value);
            localStorage.setItem('email', JSON.stringify(value));
            return
        }

        if (id === 'ИНН') {
            setInn(value);
            localStorage.removeItem('inn');
            localStorage.removeItem('org');
            setOrg('');
            setOrgList([]);
            console.log(value);
            localStorage.setItem('inn', JSON.stringify(value));
            sendInn(value)
                .then((res) => {
                    const data = res.data.suggestions;
                    console.log(data)
                    if (data.length === 1) {
                        setOrg(data[0].value);
                        localStorage.setItem('org', JSON.stringify(data[0].value));
                    } else if (data.length > 1) {
                        setOrgList(data)
                    }
                })
                .catch(err => console.log(err))
            return
        }

        if (id === 'организация') {
            setOrg(value);
            localStorage.setItem('org', JSON.stringify(value));
            return
        }
    }

    function handleNextStep() {
        setDocuments(true)
    }

    function handleOrg(e) {
        const name = e.target.textContent
        console.log(name)
        setOrg(name);
        localStorage.setItem('org', JSON.stringify(name));
        setOrgList([]);
    }

    return (
        <div className={s.entity}>
            <div className={s.header}>
                <p className={s.title}>10. Регистрация ИП или ООО</p>
                {done && <p className={s.text_big}>Данные отправлены!</p>}
                {!done && <p className={s.text_big}>Заполни данные</p>}
                {done && <p className={s.descript}>Ожидай звонок представителя банка в течение 1 рабочего <br></br> дня для регистрации в налоговой.<br></br> Записывайся на обучение!</p>}
                {!done && <p className={s.descript}>Укажи данные действующего ИП или ООО, если его нет — <br></br>заполни анкету для регистрации</p>}
            </div>
            {!done && <div className={s.buttons}>
                <div onClick={handleNew} className={`${s.button} ${documents && s.button_dis} ${!currentEntity && s.button_active}`}>Регистрация нового юр.лица</div>
                <div onClick={handleCurrent} className={`${s.button} ${documents && s.button_dis} ${currentEntity && s.button_active}`}>Действующее юр. лицо</div>
            </div>
            }
            {documents && !done && <Documents setButtonActive={setButtonActive} />}
            {!currentEntity && !documents && !done &&
                <div className={s.block}>

                    <p className={s.subtitle}>Вид организации</p>

                    <div onClick={handleOrgIp} className={s.radio}>
                        <input id='1' type='radio' autoComplete='off' name='entity' checked={!typeOrg ? true : false} />
                        <label for='1' className={s.label}>
                            Индивидуальный предприниматель
                        </label>
                    </div>

                    <div onClick={handleOrgOoo} className={s.radio}>
                        <input id='2' type='radio' autoComplete='off' value='2' name='entity' checked={typeOrg ? true : false} />
                        <label for='2' className={s.label}>
                            Общество с ограниченной ответственностью
                        </label>
                    </div>

                </div>
            }

            {currentEntity && !documents && !done &&
                <div className={s.block}>
                    <p className={s.subtitle}>Данные юридического лица</p>
                    <div className={`${s.block_inputs} ${s.block_inputs_org}`}>
                        <div>
                            <input onChange={handleInput} value={inn || ''} placeholder='ИНН' type='number' id='ИНН'></input>
                        </div>

                        <div style={{position: 'relative'}}>
                            <input  onChange={handleInput} disabled value={org || ''} placeholder='Название организации' type='text' id='организация'></input>
                            {orgList.length > 1 &&
                                <ul className={s.orglist}>
                                    {orgList.map((el) => {
                                        return <li onClick={handleOrg}>{el.value}</li>
                                    })}
                                </ul>
                            }
                        </div>
                    </div>
                </div>
            }

            {!currentEntity && !documents && !done && !typeOrg &&
                <div className={s.block}>
                    <p className={s.subtitle}>Личные данные</p>
                    <div className={s.block_inputs}>
                        <div>
                            <input onChange={handleInput} value={surname || ''} placeholder='Фамилия' type='text' id='фамилия'></input>
                        </div>

                        <div>
                            <input onChange={handleInput} value={name || ''} placeholder='Имя' type='text' id='имя'></input>
                        </div>

                        <div>
                            <input onChange={handleInput} value={surname2 || ''} placeholder='Отчество' type='text' id='отчество'></input>
                        </div>

                        <div>
                            <input onChange={handleInput} value={tel || ''} placeholder='Телефон' type='tel' id='телефон'></input>
                        </div>

                        <div>
                            <input onChange={handleInput} value={snils || ''} placeholder='СНИЛС' type='number' id='снилс'></input>
                        </div>

                        <div>
                            <input onChange={handleInput} value={email || ''} placeholder='E-mail' type='email' id='email'></input>
                        </div>
                    </div>

                </div>
            }

            {!currentEntity && !documents && !done && typeOrg &&
                <div className={s.block}>
                    <p className={s.subtitle}>Общая информация</p>
                    <p className={s.text}>Не упоминайте в названии компании государства, органы власти,<br></br> общественные и правительственные организации и аморальные слова</p>
                    <div className={`${s.block_inputs} ${s.block_inputs_ooo}`}>
                        <div style={{ height: '68px' }}>
                            <textarea maxlength="114" placeholder='Полное название компании' type='text' id='orgName'></textarea>
                        </div>
                        <div>
                            <input onChange={handleInput} value={''} placeholder='Уставный капитал' type='text' id='orgName'></input>
                        </div>

                        <span>Минимальный размер уставного капитала - 10 000 ₽</span>
                    </div>

                    <p className={s.subtitle}>Информация об учредителе</p>
                    <div className={`${s.block_inputs} ${s.block_inputs_org}`}>

                        <div>
                            <input onChange={handleInput} value={''} placeholder='СНИЛС' type='text' id='orgName'></input>
                        </div>
                        <div>
                            <input onChange={handleInput} value={''} placeholder='E-mail' type='text' id='orgName'></input>
                        </div>

                    </div>
                </div>
            }

            {!done && <div className={`${s.step} `}>
                <button onClick={() => { setDocuments(false); setButtonActive(true) }} className={`${s.button2} ${documents && s.button2_visible}`}>{'Назад'}</button>
                <button className={`${s.button1} ${!buttonActive && s.button1_dis}`} onClick={handleNextStep}>{documents || currentEntity ? 'Отправить' : 'Далее 1 из 2'}</button>
            </div>
            }

            {done && <button className={`${s.button1}`} >{'Записаться на обучение'}</button>}

        </div>
    )
}

export default Entity;