import React, { memo, useEffect, useState } from 'react'
import s from './Navbar.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { selectMain } from '../store/reducer/main/selector'
import {
  setAddActiveLink,
  setAddActiveLoadingPage,
  setAddActiveTarget,
  setAddOnClickForm,
} from '../store/reducer/main/slice'

const Navbar = memo(function Navbar({ page, type, formRef, hideNavBar, state = false, inputRef }) {
  const [isScrollNavbar, setIsScrollNavbar] = useState(false)
  const [isBurgerActive, setIsBurgerActive] = useState(false)
  const [isMainBurgerActive, setIsMainBurgerActive] = useState(false)
  const [width, setWidth] = useState(window?.innerWidth)
  const { activeLink, activeColorLink, activeBurgerColorLink } = useSelector(selectMain)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const currentYear = new Date().getUTCFullYear()
  const location = useLocation()
  const changeIsBurgerActive = (e) => {
    if (e.target.classList.value.includes('burger-close')) setIsBurgerActive(false)
  }

  const scrollToForm = (type) => {
    formRef.current.scrollIntoView({ behavior: 'smooth' })
    dispatch(setAddActiveLink(type))
  }

  const scrollToFormFranchise = () => {
    window.scroll({ top: 160, behavior: 'smooth' })
    inputRef.current.getInputDOMNode().focus()
  }

  useEffect(() => {
    const cb = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', cb)

    return () => window.removeEventListener('resize', cb)
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY >= 150) {
        setIsScrollNavbar(true)
      } else {
        setIsScrollNavbar(false)
      }
    })
  }, [])

  const onActiveTarget = (type, section) => {
    dispatch(setAddActiveTarget(type))
    dispatch(setAddActiveLoadingPage(section))
    dispatch(setAddOnClickForm(false))
  }

  if (page === 'customer')
    return (
      <>
        <StyledScrollNavbar className={isScrollNavbar ? 'scrollnav_active' : ''}>
          <div className="container scrollnav_container">
            <Link
              to="/"
              onClick={() => onActiveTarget('customer', 'customer')}
              className="scrollnav__link-customer"
            >
              <picture>
                {/*<source srcSet="/static/customer/logo.webp" type="image/webp" />
                <img src="/static/customer/logo.png" alt="Skilla заказчикам" className="scrollnav__logo" />*/}
                <source srcSet="/static/customer/Logo_customer.webp" type="image/webp" />
                <img
                  src="/static/customer/Logo_customer.svg"
                  alt="Skilla заказчикам"
                  className="scrollnav__logo"
                  width={211}
                  height={30}
                />
              </picture>
            </Link>

            <div className="scrollnav__links">
              <Link
                onClick={() => onActiveTarget('customer', 'customer')}
                to="/customer/orders"
                className={
                  activeColorLink === 'www.skilla.ru/customer/orders'
                    ? 'scrollnav__link scrollnav__link_active--customer'
                    : 'scrollnav__link'
                }
              >
                Виды услуг
              </Link>
              <Link
                onClick={() => onActiveTarget('customer', 'customer')}
                to="/customer/geography"
                className={
                  activeColorLink === 'www.skilla.ru/customer/geography'
                    ? 'scrollnav__link scrollnav__link_active--customer'
                    : 'scrollnav__link'
                }
              >
                Карта присутствия
              </Link>
              <Link
                onClick={() => onActiveTarget('customer', 'customer')}
                to="/customer/technologies"
                className={
                  activeColorLink === 'www.skilla.ru/customer/technologies'
                    ? 'scrollnav__link scrollnav__link_active--customer'
                    : 'scrollnav__link'
                }
              >
                IT-технологии
              </Link>

              <Link
                onClick={() => onActiveTarget('customer', 'customer')}
                to="/customer/contracts"
                className={
                  activeColorLink === 'www.skilla.ru/customer/contracts'
                    ? 'scrollnav__link scrollnav__link_active--customer'
                    : 'scrollnav__link'
                }
              >
                Госконтракты и тендеры
              </Link>
              <Link
                onClick={() => onActiveTarget('customer', 'customer')}
                to="/customer/faq"
                className={
                  activeColorLink === 'www.skilla.ru/customer/faq'
                    ? 'scrollnav__link scrollnav__link_active--customer'
                    : 'scrollnav__link'
                }
              >
                Вопрос-ответ
              </Link>
              {activeLink === 'www.skilla.ru/' ? (
                <button
                  className="nav__button"
                  onClick={() =>
                    location.pathname === '/business/franchise2'
                      ? scrollToFormFranchise()
                      : (window.location = 'https://www.skilla.ru/enter')
                  }
                >
                  Вход в Skilla IS
                  <span className="cabinet-link icon" />
                </button>
              ) : (
                /*<Link
                    onClick={() => dispatch(setAddActiveLink(`www.skilla.ru/customer`))}
                    to="/customer"
                    className="scrollnav__button"
                  >
                    Разместить заказ
                  </Link>*/
                <button
                  className="nav__button"
                  onClick={() =>
                    location.pathname === '/business/franchise2'
                      ? scrollToFormFranchise()
                      : (window.location = 'https://www.skilla.ru/enter')
                  }
                >
                  Вход в Skilla IS
                  <span className="cabinet-link icon" />
                </button>
              )}
            </div>
          </div>
        </StyledScrollNavbar>
        <StyledNavbar>
          <div className="container">
            <header className="nav__header">
              <div className="nav__info">
                <Link onClick={() => onActiveTarget('customer', 'customer')} to="/">
                  <picture>
                    {/*<source srcSet="/static/logo.webp" type="image/webp" />
                    <img src="/static/logo.png" alt="Skilla" className="nav__logo" />*/}
                    <source srcSet="/static/Logo_white.webp" type="image/webp" />
                    <img
                      src="/static/Logo_white.svg"
                      alt="Skilla"
                      className="nav__logo"
                      width={130}
                      height={28}
                    />
                  </picture>
                </Link>
                <div className="nav__tabs">
                  {/*Заказчикам*/}
                  <Link
                    onClick={() => onActiveTarget('customer', 'customer')}
                    to="/"
                    className="nav__tab_active nav__tab"
                  >
                    Заказчикам
                  </Link>
                  <Link
                    onClick={() => onActiveTarget('business', 'business')}
                    to="/business"
                    className="nav__tab"
                  >
                    Предпринимателям
                  </Link>
                  <Link onClick={() => onActiveTarget('worker', 'worker')} to="/worker" className="nav__tab">
                    Исполнителям
                  </Link>
                </div>
              </div>

              {activeLink === 'www.skilla.ru/' ? (
                <button
                  className="nav__button"
                  onClick={() =>
                    location.pathname === '/business/franchise2'
                      ? scrollToFormFranchise()
                      : (window.location = 'https://www.skilla.ru/enter')
                  }
                >
                  Вход в Skilla IS
                  <span className="cabinet-link icon" />
                </button>
              ) : (
                /*  <Link
                   onClick={() => dispatch(setAddActiveLink('www.skilla.ru/customer'))}
                   to="/customer"
                   className="nav__button">
                   Разместить заказ
                   <span className="arrow-right icon" />
                 </Link> */

                <button
                  className="nav__button"
                  onClick={() =>
                    location.pathname === '/business/franchise2'
                      ? scrollToFormFranchise()
                      : (window.location = 'https://www.skilla.ru/enter')
                  }
                >
                  Вход в Skilla IS
                  <span className="cabinet-link icon" />
                </button>
              )}
            </header>

            <footer className="nav__footer">
              <Link
                onClick={() => onActiveTarget('customer', 'customer')}
                to="/"
                className={
                  activeBurgerColorLink === 'www.skilla.ru/'
                    ? 'nav__link nav__link_active nav__link_active--customer'
                    : 'nav__link'
                }
              >
                Главная
              </Link>
              <Link
                onClick={() => onActiveTarget('customer', 'customer')}
                to="/customer/orders"
                className={
                  activeBurgerColorLink === 'www.skilla.ru/customer/orders'
                    ? 'nav__link nav__link_active nav__link_active--customer'
                    : 'nav__link'
                }
              >
                Виды услуг
              </Link>
              <Link
                onClick={() => onActiveTarget('customer', 'customer')}
                to="/customer/geography"
                className={
                  activeBurgerColorLink === 'www.skilla.ru/customer/geography'
                    ? 'nav__link nav__link_active nav__link_active--customer'
                    : 'nav__link'
                }
              >
                Карта присутствия
              </Link>
              <Link
                onClick={() => onActiveTarget('customer', 'customer')}
                to="/customer/technologies"
                className={
                  activeBurgerColorLink === 'www.skilla.ru/customer/technologies'
                    ? 'nav__link nav__link_active nav__link_active--customer'
                    : 'nav__link'
                }
              >
                IT-технологии
              </Link>
              <Link
                onClick={() => onActiveTarget('customer', 'customer')}
                to="/customer/contracts"
                className={
                  activeBurgerColorLink === 'www.skilla.ru/customer/contracts'
                    ? 'nav__link nav__link_active nav__link_active--customer'
                    : 'nav__link'
                }
              >
                Госконтракты и тендеры
              </Link>
              <Link
                onClick={() => onActiveTarget('customer', 'customer')}
                to={`/${page}/faq`}
                className={
                  activeBurgerColorLink === 'www.skilla.ru/customer/faq'
                    ? 'nav__link nav__link_active nav__link_active--customer'
                    : 'nav__link'
                }
              >
                Вопрос-ответ
              </Link>
            </footer>
          </div>
        </StyledNavbar>
        {width <= 960 && (
          <StyledBurger>
            <div className="container">
              <div className="burger">
                <Link onClick={() => onActiveTarget('customer', 'customer')} to="/" className="burger__logo">
                  <picture>
                    {/*<source srcSet="/static/customer/logo.webp" type="image/webp" />
                    <img
                      src="/static/customer/logo.png"
                      alt="Skilla заказчикам"
                      className="scrollnav__logo"
                    />*/}
                    <source srcSet="/static/customer/Logo_customer.webp" type="image/webp" />
                    <img
                      src="/static/customer/Logo_customer.svg"
                      alt="Skilla заказчикам"
                      className="scrollnav__logo"
                      width={211}
                      height={30}
                    />
                  </picture>
                </Link>
                <div
                  className={isBurgerActive ? 'burger__icon hidden' : 'burger__icon'}
                  onClick={() => {
                    if (isBurgerActive) setIsBurgerActive(false)
                    else setIsBurgerActive(true)
                  }}
                >
                  <picture>
                    <source srcSet="/static/nav/burger.webp" type="image/webp" />
                    <img src="/static/nav/burger.png" alt="открыть меню" />
                  </picture>
                </div>
              </div>

              <button
                className={s.button_mob}
                onClick={() =>
                  location.pathname === '/business/franchise2'
                    ? scrollToFormFranchise()
                    : (window.location = 'https://www.skilla.ru/enter')
                }
              >
                Вход в Skilla IS
                <span className="cabinet-link icon" />
              </button>
              <div
                className={isBurgerActive ? 'burger-menu burger-menu_active burger-close' : 'burger-menu'}
                onClick={changeIsBurgerActive}
              >
                <div
                  className={
                    isBurgerActive ? 'burger-menu__content vov fade-in-left' : 'burger-menu__content'
                  }
                >
                  <header className="burger-menu__header">
                    <div className={isMainBurgerActive ? 'burger-menu__nav open' : 'burger-menu__nav'}>
                      <button
                        className={
                          isMainBurgerActive
                            ? 'mainnav__button mainnav__button_customer open'
                            : 'mainnav__button mainnav__button_customer'
                        }
                        onClick={() => {
                          if (isMainBurgerActive) setIsMainBurgerActive(false)
                          else setIsMainBurgerActive(true)
                          onActiveTarget('customer', 'customer')
                        }}
                      >
                        Заказчикам
                      </button>
                      <span
                        onClick={() => setIsBurgerActive(false)}
                        className="burger-menu__cross close-menu icon"
                      ></span>
                      <div className="mainnav__burger">
                        <Link
                          onClick={() => onActiveTarget('business', 'business')}
                          to="/business"
                          className="mainnav__link"
                        >
                          Скилла Предпринимателям
                        </Link>
                        <span className="mainnav__del"></span>
                        <Link
                          onClick={() => onActiveTarget('worker', 'worker')}
                          to="/worker"
                          className="mainnav__link"
                        >
                          Скилла Исполнителям
                        </Link>
                      </div>
                    </div>

                    <div className="burger-menu__links">
                      <Link
                        onClick={() => onActiveTarget('customer', 'customer')}
                        to="/"
                        className={
                          activeBurgerColorLink === 'www.skilla.ru/'
                            ? 'nav__link-burger active-customer'
                            : 'nav__link-burger'
                        }
                      >
                        Главная
                      </Link>
                      <Link
                        to="/customer/orders"
                        onClick={() => onActiveTarget('customer', 'customer')}
                        className={
                          activeBurgerColorLink === 'www.skilla.ru/customer/orders'
                            ? 'nav__link-burger active-customer'
                            : 'nav__link-burger'
                        }
                      >
                        Виды услуг
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('customer', 'customer')}
                        to="/customer/geography"
                        className={
                          activeBurgerColorLink === 'www.skilla.ru/customer/geography'
                            ? 'nav__link-burger active-customer'
                            : 'nav__link-burger'
                        }
                      >
                        Карта присутствия
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('customer', 'customer')}
                        to="/customer/technologies"
                        className={
                          activeBurgerColorLink === 'www.skilla.ru/customer/technologies'
                            ? 'nav__link-burger active-customer'
                            : 'nav__link-burger'
                        }
                      >
                        IT-технологии
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('customer', 'customer')}
                        to="/customer/contracts"
                        className={
                          activeBurgerColorLink === 'www.skilla.ru/customer/contracts'
                            ? 'nav__link-burger active-customer'
                            : 'nav__link-burger'
                        }
                      >
                        Госконтракты и тендеры
                      </Link>
                      <Link
                        to={`/${page}/faq`}
                        onClick={() => onActiveTarget('customer', 'customer')}
                        className={
                          activeBurgerColorLink === 'www.skilla.ru/customer/faq'
                            ? 'nav__link-burger active-customer'
                            : 'nav__link-burger'
                        }
                      >
                        Вопрос-ответ
                      </Link>
                    </div>
                  </header>
                  <footer className="burger-menu__footer">
                    <Link to="/" className="burger-menu__button">
                      Разместить заказ
                      <span className="icon arrow-right-big"></span>
                    </Link>
                    <p className="burger-menu__copyright">
                      Скилла © 2010-{currentYear} Копирование информации запрещено
                    </p>
                  </footer>
                </div>
              </div>
            </div>
          </StyledBurger>
        )}
        {width <= 960 && (
          <StyledBurgerScroll className={isBurgerActive ? '' : isScrollNavbar ? 'scrollnav_active' : ''}>
            <div className="container">
              <div className="burger">
                <Link to="/" className="burger__logo">
                  <picture>
                    {/*<source srcSet="/static/logo.webp" type="image/webp" />
                    <img src="/static/logo.png" alt="Skilla" />*/}
                    <source srcSet="/static/Logo_white.webp" type="image/webp" />
                    <img
                      src="/static/Logo_white.svg"
                      alt="Skilla"
                      className="nav__logo"
                      width={130}
                      height={28}
                    />
                  </picture>
                </Link>
                <div
                  className="burger__icon"
                  onClick={() => {
                    if (isBurgerActive) setIsBurgerActive(false)
                    else setIsBurgerActive(true)
                  }}
                >
                  <picture>
                    <source srcSet="/static/nav/burger.webp" type="image/webp" />
                    <img src="/static/nav/burger.png" alt="открыть меню" />
                  </picture>
                </div>
              </div>

              <button
                className={s.button_mob}
                onClick={() =>
                  location.pathname === '/business/franchise2'
                    ? scrollToFormFranchise()
                    : (window.location = 'https://www.skilla.ru/enter')
                }
              >
                Вход в Skilla IS
                <span className="cabinet-link icon" />
              </button>
            </div>
          </StyledBurgerScroll>
        )}
      </>
    )

  if (page === 'worker')
    return (
      <>
        <StyledScrollNavbar className={isScrollNavbar ? 'scrollnav_active' : ''}>
          <div className="container scrollnav_container">
            <Link onClick={() => onActiveTarget('worker', 'worker')} to="/worker">
              {/*<img src="/static/worker/logo.png" alt="Skilla исполнителям" className="scrollnav__logo" />*/}
              <img
                src="/static/worker/Logo_worker.svg"
                alt="Skilla исполнителям"
                className="scrollnav__logo"
                width={276}
                height={30}
              />
            </Link>

            <div className="scrollnav__links scrollnav__links_worker scrollnav__links_center">
              <Link
                onClick={() => onActiveTarget('worker', 'worker')}
                to="/worker/orders"
                className={
                  activeColorLink === 'www.skilla.ru/worker/orders'
                    ? 'scrollnav__link scrollnav__link_active--worker'
                    : 'scrollnav__link'
                }
              >
                Виды заказов
              </Link>
              <Link
                onClick={() => onActiveTarget('worker', 'worker')}
                to="/worker/performers/reviews"
                className={
                  activeColorLink === 'www.skilla.ru/worker/performers/reviews'
                    ? 'scrollnav__link scrollnav__link_active--worker'
                    : 'scrollnav__link'
                }
              >
                Отзывы
              </Link>
              <Link
                onClick={() => onActiveTarget('worker', 'worker')}
                to="/worker/cases"
                className={
                  activeColorLink === 'www.skilla.ru/worker/cases'
                    ? 'scrollnav__link scrollnav__link_active--worker'
                    : 'scrollnav__link'
                }
              >
                Какие бывают заказчики
              </Link>
              <Link
                onClick={() => onActiveTarget('worker', 'worker')}
                to="/worker/faq"
                className={
                  activeColorLink === 'www.skilla.ru/worker/faq'
                    ? 'scrollnav__link scrollnav__link_active--worker'
                    : 'scrollnav__link'
                }
              >
                Вопрос-ответ
              </Link>
              <Link
                onClick={() => onActiveTarget('worker', 'worker')}
                to="/worker/download"
                className={
                  activeColorLink === 'www.skilla.ru/worker/download'
                    ? 'scrollnav__link scrollnav__link_active--worker'
                    : 'scrollnav__link'
                }
              >
                Стать исполнителем
              </Link>
            </div>
          </div>
        </StyledScrollNavbar>
        <StyledNavbar>
          <div className="container">
            <header className="nav__header">
              <div className="nav__info">
                <Link to="/worker">
                  <picture>
                    {/*<source srcSet="/static/logo.webp" type="image/webp" />
                    <img src="/static/logo.png" alt="Skilla" className="nav__logo" />*/}
                    <source srcSet="/static/Logo_white.webp" type="image/webp" />
                    <img
                      src="/static/Logo_white.svg"
                      alt="Skilla"
                      className="nav__logo"
                      width={130}
                      height={28}
                    />
                  </picture>
                </Link>
                <div className="nav__tabs nav__tabs_worker">
                  <Link onClick={() => onActiveTarget('customer', 'customer')} to="/" className="nav__tab ">
                    Заказчикам
                  </Link>
                  <Link
                    onClick={() => onActiveTarget('business', 'business')}
                    to="/business"
                    className="nav__tab"
                  >
                    Предпринимателям
                  </Link>
                  <Link
                    onClick={() => onActiveTarget('worker', 'worker')}
                    to="/worker"
                    className="nav__tab nav__tab_active"
                  >
                    Исполнителям
                  </Link>
                </div>
              </div>

              {/*<button
                className="nav__button nav__button_worker"
                onClick={() =>
                  location.pathname === '/business/franchise2'
                    ? scrollToFormFranchise()
                    : (window.location = 'https://www.skilla.ru/enter')
                }
              >
                Вход в Skilla IS
                <span className="cabinet-link icon" />
              </button>*/}
            </header>

            <footer className="nav__footer nav__footer_worker">
              <Link
                onClick={() => onActiveTarget('worker', 'worker')}
                to="/worker"
                className={
                  activeColorLink === 'www.skilla.ru/worker'
                    ? 'nav__link nav__link_worker-active'
                    : 'nav__link'
                }
              >
                Главная
              </Link>
              <Link
                onClick={() => onActiveTarget('worker', 'worker')}
                to="/worker/orders"
                className={
                  activeColorLink === 'www.skilla.ru/worker/orders'
                    ? 'nav__link nav__link_worker-active'
                    : 'nav__link'
                }
              >
                Виды заказов
              </Link>
              <Link
                onClick={() => onActiveTarget('worker', 'worker')}
                to="/worker/performers/reviews"
                className={
                  activeColorLink === 'www.skilla.ru/worker/performers/reviews'
                    ? 'nav__link nav__link_worker-active'
                    : 'nav__link'
                }
              >
                Отзывы исполнителей
              </Link>
              <Link
                onClick={() => onActiveTarget('worker', 'worker')}
                to="/worker/cases"
                className={
                  activeColorLink === 'www.skilla.ru/worker/cases'
                    ? 'nav__link nav__link_worker-active'
                    : 'nav__link'
                }
              >
                Какие бывают заказчики
              </Link>
              <Link
                onClick={() => onActiveTarget('worker', 'worker')}
                to={`/${page}/faq`}
                className={
                  activeColorLink === 'www.skilla.ru/worker/faq'
                    ? 'nav__link nav__link_worker-active'
                    : 'nav__link'
                }
              >
                Вопрос-ответ
              </Link>
              <Link
                onClick={() => onActiveTarget('worker', 'worker')}
                to="/worker/download"
                className={
                  activeColorLink === 'www.skilla.ru/worker/download'
                    ? 'nav__link nav__link_worker-active'
                    : 'nav__link'
                }
              >
                Стать исполнителем
              </Link>
            </footer>
          </div>
        </StyledNavbar>
        {width <= 960 && (
          <StyledBurger>
            <div className="container">
              <div className="burger">
                <Link
                  onClick={() => onActiveTarget('worker', 'worker')}
                  to="/worker"
                  className="burger__logo"
                >
                  {/*<img src="/static/worker/logo.png" alt="Skilla исполнителям" />*/}
                  <img
                    src="/static/worker/Logo_worker.svg"
                    alt="Skilla исполнителям"
                    className="scrollnav__logo"
                    width={276}
                    height={30}
                  />
                </Link>
                <div
                  className={isBurgerActive ? 'burger__icon hidden' : 'burger__icon'}
                  onClick={() => {
                    if (isBurgerActive) setIsBurgerActive(false)
                    else setIsBurgerActive(true)
                  }}
                >
                  <picture>
                    <source srcSet="/static/nav/burger.webp" type="image/webp" />
                    <img src="/static/nav/burger.png" alt="открыть меню" />
                  </picture>
                </div>
              </div>
              {/*<button
                className={s.button_mob}
                onClick={() =>
                  location.pathname === '/business/franchise2'
                    ? scrollToFormFranchise()
                    : (window.location = 'https://www.skilla.ru/enter')
                }
              >
                Вход в Skilla IS
                <span className="cabinet-link icon" />
              </button>*/}
              <div
                className={isBurgerActive ? 'burger-menu burger-menu_active burger-close' : 'burger-menu'}
                onClick={changeIsBurgerActive}
              >
                <div
                  className={
                    isBurgerActive
                      ? 'burger-menu__content vov fade-in-left burger-menu__content_worker'
                      : 'burger-menu__content burger-menu__content_worker'
                  }
                >
                  <header className="burger-menu__header">
                    <div className={isMainBurgerActive ? 'burger-menu__nav open' : 'burger-menu__nav'}>
                      <button
                        className={
                          isMainBurgerActive
                            ? 'mainnav__button open mainnav__button_worker'
                            : 'mainnav__button mainnav__button_worker'
                        }
                        onClick={() => {
                          if (isMainBurgerActive) setIsMainBurgerActive(false)
                          else setIsMainBurgerActive(true)
                          onActiveTarget('worker', 'worker')
                        }}
                      >
                        Исполнителям
                      </button>
                      <span
                        onClick={() => setIsBurgerActive(false)}
                        className="burger-menu__cross close-menu icon"
                      ></span>
                      <div className="mainnav__burger">
                        <Link
                          onClick={() => onActiveTarget('business', 'business')}
                          to="/business"
                          className="mainnav__link"
                        >
                          Skilla Предпринимателям
                        </Link>
                        <span className="mainnav__del"></span>
                        <Link
                          onClick={() => onActiveTarget('customer', 'customer')}
                          to="/"
                          className="mainnav__link"
                        >
                          Skilla Заказчикам
                        </Link>
                      </div>
                    </div>

                    <div className="burger-menu__links">
                      <Link
                        to="/worker"
                        onClick={() => onActiveTarget('worker', 'worker')}
                        className={
                          activeBurgerColorLink === 'www.skilla.ru/worker'
                            ? 'burger-menu__link burger-menu__link_active'
                            : 'burger-menu__link'
                        }
                      >
                        Главная
                      </Link>
                      <Link
                        to="/worker/orders"
                        onClick={() => onActiveTarget('worker', 'worker')}
                        className={
                          activeBurgerColorLink === 'www.skilla.ru/worker/orders'
                            ? 'burger-menu__link burger-menu__link_active'
                            : 'burger-menu__link'
                        }
                      >
                        Виды заказов
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('worker', 'worker')}
                        to="/worker/performers/reviews"
                        className={
                          activeBurgerColorLink === 'www.skilla.ru/worker/performers/reviews'
                            ? 'burger-menu__link burger-menu__link_active'
                            : 'burger-menu__link'
                        }
                      >
                        Отзывы исполнителей
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('worker', 'worker')}
                        to="/worker/cases"
                        className={
                          activeBurgerColorLink === 'www.skilla.ru/worker/cases'
                            ? 'burger-menu__link burger-menu__link_active'
                            : 'burger-menu__link'
                        }
                      >
                        Какие бывают заказчики
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('worker', 'worker')}
                        to={`/${page}/faq`}
                        className={
                          activeBurgerColorLink === 'www.skilla.ru/worker/faq'
                            ? 'burger-menu__link burger-menu__link_active'
                            : 'burger-menu__link'
                        }
                      >
                        Вопрос-ответ
                      </Link>
                    </div>
                  </header>
                  <footer className="burger-menu__footer">
                    <button className="burger-menu__button" onClick={() => navigate('/worker/download')}>
                      Скачать приложение
                      <span className="icon download"></span>
                    </button>
                    <p className="burger-menu__copyright">
                      Skilla © 2010-{currentYear} Копирование информации запрещено
                    </p>
                  </footer>
                </div>
              </div>
            </div>
          </StyledBurger>
        )}
        {width <= 960 && (
          <StyledBurgerScroll className={isBurgerActive ? '' : isScrollNavbar ? 'scrollnav_active' : ''}>
            <div className="container">
              <div className="burger">
                <Link to="/worker" className="burger__logo">
                  <picture>
                    {/*<source srcSet="/static/logo.webp" type="image/webp" />
                    <img src="/static/logo.png" alt="Skilla" />*/}
                    <source srcSet="/static/Logo_white.webp" type="image/webp" />
                    <img
                      src="/static/Logo_white.svg"
                      alt="Skilla"
                      className="nav__logo"
                      width={130}
                      height={28}
                    />
                  </picture>
                </Link>
                <div
                  className="burger__icon"
                  onClick={() => {
                    if (isBurgerActive) setIsBurgerActive(false)
                    else setIsBurgerActive(true)
                  }}
                >
                  <picture>
                    <source srcSet="/static/nav/burger.webp" type="image/webp" />
                    <img src="/static/nav/burger.png" alt="открыть меню" />
                  </picture>
                </div>
              </div>
              {/*<button
                className={s.button_mob}
                onClick={() =>
                  location.pathname === '/business/franchise2'
                    ? scrollToFormFranchise()
                    : (window.location = 'https://www.skilla.ru/enter')
                }
              >
                Вход в Skilla IS
                <span className="cabinet-link icon" />
              </button>*/}
            </div>
          </StyledBurgerScroll>
        )}
      </>
    )

  if (page === 'business')
    return (
      <>
        {!hideNavBar ? (
          <StyledScrollNavbar className={isScrollNavbar ? 'scrollnav_active' : ''}>
            {type !== 'business' ? (
              <div className="container scrollnav_container scrollnav_container_business">
                <Link className="scrollnav__link-business" to="/business">
                  <img
                    src="/static/business/Logo_business.svg"
                    alt="Skilla предпринемателям"
                    className="scrollnav__logo"
                    width={276}
                    height={30}
                  />
                </Link>

                {!hideNavBar ? (
                  <div className="scrollnav__links scrollnav__links_business">
                    {/*<Link
                      onClick={() => onActiveTarget('business', 'business')}
                      to="/business/founder"
                      className={
                        activeColorLink === 'www.skilla.ru/business/founder'
                          ? 'scrollnav__link scrollnav__link_active--business'
                          : 'scrollnav__link'
                      }
                    >
                      Основатель
                    </Link>*/}
                    <Link
                      onClick={() => onActiveTarget('business', 'business')}
                      to="/business/partners"
                      className={
                        activeColorLink === 'www.skilla.ru/business/partners'
                          ? 'scrollnav__link scrollnav__link_active--business'
                          : 'scrollnav__link'
                      }
                    >
                      Кейсы
                    </Link>
                    <Link
                      onClick={() => onActiveTarget('business', 'business')}
                      to="/business/training"
                      className={
                        activeColorLink === 'www.skilla.ru/business/training'
                          ? 'scrollnav__link scrollnav__link_active--business'
                          : 'scrollnav__link'
                      }
                    >
                      Обучение
                    </Link>
                    <Link
                      onClick={() => onActiveTarget('business', 'business')}
                      to="/business/heroes"
                      className={
                        activeColorLink === 'www.skilla.ru/business/heroes'
                          ? 'scrollnav__link scrollnav__link_active--business'
                          : 'scrollnav__link'
                      }
                    >
                      Бизнес-Герои
                    </Link>
                    <Link
                      onClick={() => onActiveTarget('business', 'business')}
                      to="/business/reviews"
                      className={
                        activeColorLink === 'www.skilla.ru/business/reviews'
                          ? 'scrollnav__link scrollnav__link_active--business'
                          : 'scrollnav__link'
                      }
                    >
                      Отзывы
                    </Link>
                    <Link
                      onClick={() => onActiveTarget('business', 'business')}
                      to="/business/faq"
                      className={
                        activeColorLink === 'www.skilla.ru/business/faq'
                          ? 'scrollnav__link scrollnav__link_active--business'
                          : 'scrollnav__link'
                      }
                    >
                      Вопрос-ответ
                    </Link>
                    <Link
                      onClick={() => onActiveTarget('business', 'business')}
                      to="/business/about"
                      className={
                        activeColorLink === 'www.skilla.ru/business/about'
                          ? 'scrollnav__link scrollnav__link_active--business'
                          : 'scrollnav__link'
                      }
                    >
                      О компании
                    </Link>
                    {/*<Link
                      onClick={() => onActiveTarget('business', 'business')}
                      to="/business/franchise"
                      className={
                        activeColorLink === 'www.skilla.ru/business/franchise'
                          ? 'scrollnav__link scrollnav__link_active--business'
                          : 'scrollnav__link'
                      }
                    >
                      Франшиза
                    </Link>*/}
                    <Link
                      onClick={() => onActiveTarget('business', 'business')}
                      to="/business/franchise"
                      className={
                        activeColorLink === 'www.skilla.ru/business/franchise'
                          ? 'scrollnav__link scrollnav__link_active--business'
                          : 'scrollnav__link'
                      }
                    >
                      Стать партнером
                    </Link>
                  </div>
                ) : null}
              </div>
            ) : (
              <div className="container scrollnav_container scrollnav_container_business_only">
                <a href="#">
                  <img
                    alt="Как открыть свой бизнес со 100% гарантией успеха"
                    src="/static/business/nav/image.png"
                    className="scrollnav__description"
                  />
                </a>

                <p className="scrollnav__time">Бесплатный вебинар 18 июля в 19:30 мск</p>
              </div>
            )}
          </StyledScrollNavbar>
        ) : null}

        <StyledNavbar>
          <div className="container">
            <header className="nav__header">
              <div className="nav__info">
                {location.pathname === '/business/franchise2' ? (
                  <picture>
                    {/*<source srcSet="/static/logo.webp" type="image/webp" />
                    <img src="/static/logo.png" alt="Skilla" className="nav__logo" />*/}
                    <source srcSet="/static/Logo_white.webp" type="image/webp" />
                    <img
                      src="/static/Logo_white.svg"
                      alt="Skilla"
                      className="nav__logo"
                      width={130}
                      height={28}
                    />
                  </picture>
                ) : (
                  <Link to="/business">
                    <picture>
                      {/*<source srcSet="/static/logo.webp" type="image/webp" />
                      <img src="/static/logo.png" alt="Skilla" className="nav__logo" />*/}
                      <source srcSet="/static/Logo_white.webp" type="image/webp" />
                      <img
                        src="/static/Logo_white.svg"
                        alt="Skilla"
                        className="nav__logo"
                        width={130}
                        height={28}
                      />
                    </picture>
                  </Link>
                )}

                <div
                  className="nav__tabs nav__tabs_business"
                  onClick={() =>
                    location.pathname === '/business/franchise2' ? scrollToFormFranchise() : ''
                  }
                  style={{ cursor: 'pointer' }}
                >
                  <Link
                    onClick={() => onActiveTarget('customer', 'customer')}
                    to="/"
                    className={`nav__tab ${location.pathname === '/business/franchise2' ? 'nav__tab_block' : ''}`}
                  >
                    Заказчикам
                  </Link>
                  <Link
                    onClick={() => onActiveTarget('business', 'business')}
                    to="/business"
                    className={`nav__tab nav__tab_active ${location.pathname === '/business/franchise2' ? 'nav__tab_block' : ''}`}
                  >
                    Предпринимателям
                  </Link>
                  <Link
                    onClick={() => onActiveTarget('worker', 'worker')}
                    to="/worker"
                    className={`nav__tab ${location.pathname === '/business/franchise2' ? 'nav__tab_block' : ''}`}
                  >
                    Исполнителям
                  </Link>
                </div>
              </div>

              {location.pathname !== '/business/franchise2' && (
                <button
                  className="nav__button nav__button_business"
                  onClick={() =>
                    location.pathname === '/business/franchise2'
                      ? scrollToFormFranchise()
                      : (window.location = 'https://www.skilla.ru/enter')
                  }
                >
                  Вход в Skilla IS
                  <span className="cabinet-link icon" />
                </button>
              )}
            </header>

            {!hideNavBar ? (
              <footer className="nav__footer nav__footer_business">
                <Link
                  onClick={() => onActiveTarget('business', 'business')}
                  to="/business"
                  className={
                    activeLink === 'www.skilla.ru/business' ? 'nav__link nav__link_active' : 'nav__link'
                  }
                >
                  Главная
                </Link>
                {/*<Link
                  onClick={() => onActiveTarget('business', 'business')}
                  to="/business/founder"
                  className={
                    activeLink === 'www.skilla.ru/business/founder'
                      ? 'nav__link nav__link_active'
                      : 'nav__link'
                  }
                >
                  Основатель
                </Link>*/}
                <Link
                  onClick={() => onActiveTarget('business', 'business')}
                  to="/business/partners"
                  className={
                    activeLink === 'www.skilla.ru/business/partners'
                      ? 'nav__link nav__link_active'
                      : 'nav__link'
                  }
                >
                  Кейсы
                </Link>
                <Link
                  onClick={() => onActiveTarget('business', 'business')}
                  to="/business/training"
                  className={
                    activeLink === 'www.skilla.ru/business/training'
                      ? 'nav__link nav__link_active'
                      : 'nav__link'
                  }
                >
                  Обучение
                </Link>
                <Link
                  onClick={() => onActiveTarget('business', 'business')}
                  to="/business/heroes"
                  className={
                    activeLink === 'www.skilla.ru/business/heroes'
                      ? 'nav__link nav__link_active'
                      : 'nav__link'
                  }
                >
                  Бизнес-Герои
                </Link>
                <Link
                  onClick={() => onActiveTarget('business', 'business')}
                  to="/business/reviews"
                  className={
                    activeLink === 'www.skilla.ru/business/reviews'
                      ? 'nav__link nav__link_active'
                      : 'nav__link'
                  }
                >
                  Отзывы
                </Link>
                <Link
                  onClick={() => onActiveTarget('business', 'business')}
                  to={`/${page}/faq`}
                  className={
                    activeLink === 'www.skilla.ru/business/faq' ? 'nav__link nav__link_active' : 'nav__link'
                  }
                >
                  Вопрос-ответ
                </Link>
                <Link
                  onClick={() => onActiveTarget('business', 'business')}
                  to="/business/about"
                  className={
                    activeLink === 'www.skilla.ru/business/about' ? 'nav__link nav__link_active' : 'nav__link'
                  }
                >
                  О компании
                </Link>
                {/*<Link
                  onClick={() => onActiveTarget('business', 'business')}
                  to="/business/franchise"
                  state={{ is_partner: false }}
                  className={
                    activeLink === 'www.skilla.ru/business/franchise' && !state?.is_partner
                      ? 'nav__link nav__link_active'
                      : 'nav__link'
                  }
                >
                  Франшиза
                </Link>*/}
                <Link
                  onClick={() => onActiveTarget('business', 'business')}
                  to="/business/franchise"
                  state={{ is_partner: true }}
                  className={
                    activeLink === 'www.skilla.ru/business/franchise' && !state?.is_partner
                      ? 'nav__link nav__link_active'
                      : 'nav__link'
                  }
                >
                  Стать партнером
                </Link>
              </footer>
            ) : null}
          </div>
        </StyledNavbar>
        {width <= 960 && (
          <StyledBurger>
            <div className="container">
              <div className="burger">
                <Link
                  onClick={() => onActiveTarget('business', 'business')}
                  to="/business"
                  className={`'burger__logo' ${location.pathname === '/business/franchise2' ? 'nav__tab_block' : ''}`}
                >
                  {/*<img src="/static/business/logo.png" alt="логотип" />*/}
                  <img src="/static/business/Logo_business.svg" alt="логотип" width={276} height={30} />
                </Link>
                <div
                  style={{ display: `${location.pathname === '/business/franchise2' ? 'none' : ''}` }}
                  className={isBurgerActive ? 'burger__icon hidden' : 'burger__icon'}
                  onClick={() => {
                    if (isBurgerActive) setIsBurgerActive(false)
                    else setIsBurgerActive(true)
                  }}
                >
                  <picture>
                    <source srcSet="/static/nav/burger.webp" type="image/webp" />
                    <img src="/static/nav/burger.png" alt="открыть меню" />
                  </picture>
                </div>
              </div>
              {location.pathname !== '/business/franchise2' && (
                <button
                  className={s.button_mob}
                  onClick={() =>
                    location.pathname === '/business/franchise2'
                      ? scrollToFormFranchise()
                      : (window.location = 'https://www.skilla.ru/enter')
                  }
                >
                  Вход в Skilla IS
                  <span className="cabinet-link icon" />
                </button>
              )}
              <div
                className={
                  isBurgerActive ? 'burger-menu burger-menu_active burger-close' : 'burger-menu burger-close'
                }
                onClick={changeIsBurgerActive}
              >
                <div
                  className={
                    isBurgerActive
                      ? 'burger-menu__content vov fade-in-left burger-menu__content_business'
                      : 'burger-menu__content burger-menu__content_business'
                  }
                >
                  <header className="burger-menu__header">
                    <div className={isMainBurgerActive ? 'burger-menu__nav open' : 'burger-menu__nav'}>
                      <button
                        className={
                          isMainBurgerActive ? 'mainnav__button business open' : 'mainnav__button business'
                        }
                        onClick={() => {
                          if (isMainBurgerActive) setIsMainBurgerActive(false)
                          else setIsMainBurgerActive(true)
                          onActiveTarget('business', 'business')
                        }}
                      >
                        Предпринимателям
                      </button>
                      <span
                        onClick={() => setIsBurgerActive(false)}
                        className="burger-menu__cross close-menu icon"
                      ></span>
                      <div className="mainnav__burger">
                        <Link
                          onClick={() => onActiveTarget('worker', 'worker')}
                          to="/worker"
                          className={`mainnav__link ${location.pathname === '/business/franchise2' ? 'nav__tab_block' : ''}`}
                        >
                          Skilla Исполнителям
                        </Link>
                        <span className="mainnav__del"></span>
                        <Link
                          onClick={() => onActiveTarget('customer', 'customer')}
                          to="/"
                          className={`mainnav__link ${location.pathname === '/business/franchise2' ? 'nav__tab_block' : ''}`}
                        >
                          Skilla Заказчикам
                        </Link>
                      </div>
                    </div>

                    {!hideNavBar ? (
                      <div className="burger-menu__links burger-menu__links_business">
                        <Link
                          to="/business"
                          onClick={() => onActiveTarget('business', 'business')}
                          className={
                            activeBurgerColorLink === 'www.skilla.ru/business'
                              ? 'burger-menu__link burger-menu__link_active'
                              : 'burger-menu__link'
                          }
                        >
                          Главная
                        </Link>
                        {/*<Link
                          to="/business/founder"
                          className={
                            activeBurgerColorLink === 'www.skilla.ru/business/founder'
                              ? 'burger-menu__link burger-menu__link_active'
                              : 'burger-menu__link'
                          }
                          onClick={() => onActiveTarget('business', 'business')}
                        >
                          Основатель
                        </Link>*/}
                        <Link
                          to="/business/partners"
                          onClick={() => onActiveTarget('business', 'business')}
                          className={
                            activeBurgerColorLink === 'www.skilla.ru/business/partners'
                              ? 'burger-menu__link burger-menu__link_active'
                              : 'burger-menu__link'
                          }
                        >
                          Кейсы
                        </Link>
                        <Link
                          to="/business/training"
                          className={
                            activeBurgerColorLink === 'www.skilla.ru/business/training'
                              ? 'burger-menu__link burger-menu__link_active'
                              : 'burger-menu__link'
                          }
                          onClick={() => onActiveTarget('business', 'business')}
                        >
                          Обучение
                        </Link>
                        <Link
                          to="/business/heroes"
                          onClick={() => onActiveTarget('business', 'business')}
                          className={
                            activeBurgerColorLink === 'www.skilla.ru/business/heroes'
                              ? 'burger-menu__link burger-menu__link_active'
                              : 'burger-menu__link'
                          }
                        >
                          Бизнес-Герои
                        </Link>
                        <Link
                          to="/business/reviews"
                          onClick={() => onActiveTarget('business', 'business')}
                          className={
                            activeBurgerColorLink === 'www.skilla.ru/business/reviews'
                              ? 'burger-menu__link burger-menu__link_active'
                              : 'burger-menu__link'
                          }
                        >
                          Отзывы
                        </Link>
                        <Link
                          to={`/${page}/faq`}
                          onClick={() => onActiveTarget('business', 'business')}
                          className={
                            activeBurgerColorLink === 'www.skilla.ru/business/faq'
                              ? 'burger-menu__link burger-menu__link_active'
                              : 'burger-menu__link'
                          }
                        >
                          Вопрос-ответ
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('business', 'business')}
                          to="/business/about"
                          className={
                            activeBurgerColorLink === 'www.skilla.ru/business/about'
                              ? 'burger-menu__link burger-menu__link_active'
                              : 'burger-menu__link'
                          }
                        >
                          О компании
                        </Link>

                        {/*<Link
                          to="/business/franchise"
                          onClick={() => onActiveTarget('business', 'business')}
                          className={
                            activeBurgerColorLink === 'www.skilla.ru/business/franchise'
                              ? 'burger-menu__link burger-menu__link_active'
                              : 'burger-menu__link'
                          }
                        >
                          Франшиза
                        </Link>*/}
                        <Link
                          to="/business/franchise"
                          onClick={() => onActiveTarget('business', 'business')}
                          className={
                            activeBurgerColorLink === 'www.skilla.ru/business/franchise'
                              ? 'burger-menu__link burger-menu__link_active'
                              : 'burger-menu__link'
                          }
                        >
                          Стать партнером
                        </Link>
                      </div>
                    ) : null}
                  </header>
                  <footer className="burger-menu__footer">
                    <button
                      className="burger-menu__button burger-menu__button_business"
                      onClick={() => (window.location = 'https://www.skilla.ru/enter')}
                    >
                      Вход в Skilla IS
                      <span className="icon cabinet-link"></span>
                    </button>
                    <p className="burger-menu__copyright">
                      Skilla © 2010-{currentYear} Копирование информации запрещено
                    </p>
                  </footer>
                </div>
              </div>
            </div>
          </StyledBurger>
        )}
        {width <= 960 && !hideNavBar ? (
          <StyledBurgerScroll className={isBurgerActive ? '' : isScrollNavbar ? 'scrollnav_active' : ''}>
            <div className="container">
              <div className="burger">
                <Link to="/business" className="burger__logo">
                  <picture>
                    {/*<source srcSet="/static/logo.webp" type="image/webp" />
                    <img src="/static/logo.png" alt="Skilla" />*/}
                    <source srcSet="/static/Logo_white.webp" type="image/webp" />
                    <img
                      src="/static/Logo_white.svg"
                      alt="Skilla"
                      className="nav__logo"
                      width={130}
                      height={28}
                    />
                  </picture>
                </Link>
                <div
                  className="burger__icon"
                  onClick={() => {
                    if (isBurgerActive) setIsBurgerActive(false)
                    else setIsBurgerActive(true)
                  }}
                >
                  <picture>
                    <source srcSet="/static/nav/burger.webp" type="image/webp" />
                    <img src="/static/nav/burger.png" alt="открыть меню" />
                  </picture>
                </div>
              </div>
              {location.pathname !== '/business/franchise2' && (
                <button
                  className={s.button_mob}
                  onClick={() =>
                    location.pathname === '/business/franchise2'
                      ? scrollToFormFranchise()
                      : (window.location = 'https://www.skilla.ru/enter')
                  }
                >
                  Вход в Skilla IS
                  <span className="cabinet-link icon" />
                </button>
              )}
            </div>
          </StyledBurgerScroll>
        ) : null}
      </>
    )

  return null
})

const StyledScrollNavbar = styled.nav`
  position: fixed;
  z-index: 15;
  top: -65px;
  left: 0;
  padding: 11px 0;
  width: 100%;
  height: 65px;
  background-color: #000729;
  display: flex;
  align-items: center;
  transition: top 0.3s ease;

  .scrollnav_container_business_only {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    & .scrollnav__time {
      color: #fff;
      font-weight: 400;
      font-size: 18px;
      line-height: 120%;
    }

    & .scrollnav__description {
      margin-bottom: -16px;
    }
  }

  &.scrollnav_active {
    top: 0;
  }

  .scrollnav__links {
    display: flex;
    align-items: center;
    gap: 42px;
  }

  .scrollnav__links_center {
    flex-grow: 1;
    justify-content: space-around;
    margin-left: 10px;
    margin-right: 10px;
  }

  .scrollnav__link {
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    line-height: 120%;
    color: #e3e4e8;
    transition: color 0.3s ease;

    &-active {
      font-size: 18px;
      font-weight: 500;
      color: #00ff88;
    }

    &_active--customer {
      font-weight: 600;
      color: #97a8ff;
    }

    &_active--business {
      font-size: 16px;
      font-weight: 600;
      color: #00ff88;
    }

    &_active--worker {
      font-size: 16px;
      font-weight: 600;
      color: #cf99ff;
    }

    &:hover {
      color: #fff;
    }

    &-business {
      margin-right: 53px;
    }

    &-customer {
      margin-right: 118px;
    }

    @media (max-width: 1600px) {
      font-size: 12px;
    }
  }

  .scrollnav__link_partnerCase {
    color: #00ff88;
  }

  .scrollnav__links_worker {
    gap: 74px;
  }

  .scrollnav__button {
    border: 0;
    font-weight: 600;
    padding: 9.5px 20px;
    font-size: 16px;
    line-height: 120%;
    color: #e3e4e8;
    border-radius: 4px;
    background-color: #303550;
    cursor: pointer;
    transition:
      background-color 0.3s ease,
      color 0.3s ease;

    &:hover {
      background-color: #002cfb;
    }
  }

  .scrollnav__button_worker:hover {
    background-color: #8800ff;
  }

  .scrollnav__button_business:hover {
    background-color: #00ff88;
    color: #000729;

    & span {
    }
  }

  .scrollnav_container {
    max-width: 1500px; /* Максимальная ширина контейнера */
    width: 100%; /* Занимает всю доступную ширину родителя до максимальной ширины */
    margin: 0 auto; /* Центрирует контейнер по горизонтали */
    display: flex;
    align-items: center; /* Вертикальное выравнивание содержимого по центру */
    justify-content: center; /* Центрирует содержимое по горизонтали */

    @media (max-width: 1600px) {
      max-width: 1300px; /* Изменение максимальной ширины на меньших экранах */
    }
  }

  .scrollnav_container_business {
    width: 1568px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`

const StyledNavbar = styled.nav`
  position: absolute;
  left: 0;
  right: 0;
  top: 40px;
  z-index: 10;

  .nav__tabs_worker {
    & .nav__tab_active {
      background-color: #8800ff;
    }
  }

  .nav__tabs_business {
    background-color: rgba(0, 7, 41, 0.5) !important;

    & .nav__tab {
      &:first-child {
        border-radius: 4px 0 0 4px;
      }

      &:last-child {
        border-radius: 0 4px 4px 0;
      }

      &:not(.nav__tab_active):hover {
        color: rgba(143, 146, 163, 1) !important;
      }
    }

    & .nav__tab_active {
      background-color: #00ff88;
      color: #0f1324;
      font-weight: 600;
    }
  }

  .nav__link_active {
    font-weight: 700;
    &--customer {
      color: #97a8ff !important;
    }
  }

  .nav__link_worker-active {
    color: #ffd500 !important;
  }

  .nav__tabs {
    display: flex;
    border-radius: 4px;
    width: min-content;
    margin-left: 25px;
    background-color: rgba(149, 168, 255, 0.2);
  }

  .nav__tab {
    display: block;
    padding: 10px 20px;
    font-size: 16px;
    line-height: 21px;
    text-decoration: none;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
    transition: color 0.3s ease;

    &:hover:not(.nav__tab_active) {
      color: #97a8ff;
    }
  }

  .nav__tab_active {
    background-color: #002cfb;
    border-radius: 4px;
  }

  .nav__tab_block {
    pointer-events: none;
  }

  .nav__info {
    display: flex;
    align-items: center;
  }

  .nav__button {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    font-family: inherit;
    font-weight: 600;
    color: #fff;
    border: 0;
    font-size: 16px;
    border-radius: 4px;
    background-color: rgba(149, 168, 255, 0.2);
    cursor: pointer;
    transition:
      background-color 0.3s ease,
      color 0.3s ease;

    & .icon {
      margin-left: 15px;
    }

    &.nav__button_worker:hover {
      background-color: #8800ff;
    }

    &.nav__button_business {
      background-color: rgba(0, 7, 41, 0.5);
    }

    &.nav__button_business:hover {
      background-color: #00ff88;
      color: #000729;

      & span {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6 1H14V15H6' stroke='%23000729' stroke-width='1.5'/%3e%3cpath d='M6.37986 4.20896L10.0854 7.91455M10.0854 7.91455L6.37986 11.6201M10.0854 7.91455H1' stroke='%23000729' stroke-width='1.5'/%3e%3c/svg%3e ");
      }
    }

    &:hover {
      background-color: #002cfb;
    }
  }

  .nav__header {
    display: flex;
    justify-content: space-between;

    @media (max-width: 960px) {
      display: none;
    }
  }

  .nav__footer {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;

    @media (max-width: 960px) {
      display: none;
    }
  }

  .nav__footer_business .nav__link_active {
    color: #00ff88;
    padding: 4px 12px;
    border-radius: 4px;
    margin-top: -4px;
  }

  .nav__link {
    font-size: 18px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.7);
    text-decoration: none;
    font-weight: 500;
    transition:
      background-color 0.3s ease,
      padding 0.3s ease,
      border-radius 0.3s ease,
      margin-top 0.3s ease,
      color 0.3s ease;

    &:hover:not(.nav__link_active) {
      color: rgba(255, 255, 255, 1);
    }

    @media (max-width: 1600px) {
      font-size: 15px;
    }
  }

  .nav__link_active {
    font-weight: 700;
  }

  .nav__link_active-business {
    font-weight: 600;
    font-size: 16px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`

const StyledBurger = styled.nav`
  background-color: transparent;
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 15;

  .nav__link-burger {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.7);
    font-size: 18px;
    line-height: 120%;
    transition: color 0.3s ease;

    &.active-customer {
      color: #97a8ff !important;
      text-decoration: none;
    }
  }

  .burger {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  .burger__icon {
    &.hidden {
      display: none;
    }
  }

  .close-menu {
    margin-right: -50px;

    @media (max-width: 768px) {
      margin-right: 0;
    }
  }

  .burger-menu {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 50;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 7, 41, 0.4);
  }

  .burger-menu__content {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 60px 16px 40px 16px;
    width: 315px;
    height: 100%;
    background:
      radial-gradient(55.7% 97% at 0% 3%, #000e50 21.6%, rgba(0, 23, 134, 0) 80.42%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(20.81% 43.51% at 87.01% 11.68%, #001fb2 0%, rgba(0, 23, 134, 0) 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(66.95% 140.01% at 66.95% 90.11%, #000e50 0%, #001786 42.05%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    mix-blend-mode: normal;
  }

  .burger-menu__content_worker {
    background:
      radial-gradient(63.02% 48.11% at 50% 23.37%, #4c0f7b 0%, rgba(85, 20, 142, 0) 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(22.68% 53.3% at 96.51% -3.22%, #4c1279 0%, rgba(191, 119, 255, 0) 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(31.72% 67.58% at -2.76% 107.52%, #ffe9c0 0%, rgba(123, 34, 197, 0) 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(45.31% 97.19% at -0.62% 55.77%, #cc79ff 0%, rgba(123, 34, 197, 0) 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(70.6% 284.26% at 81.04% 82.79%, #2f0058 0%, #8800ff 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  }

  .burger-menu__content_business {
    background:
      radial-gradient(41.09% 72.88% at 10.68% 15.98%, #00341c 0%, rgba(0, 91, 48, 0) 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(37.63% 76.37% at 100% 0%, #007e43 0%, rgba(0, 91, 48, 0) 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(79.17% 174.71% at 79.17% 78.17%, #00361c 0%, #006636 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    mix-blend-mode: normal;
  }

  .burger-menu_active {
    display: block;
  }

  .burger-menu__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    &.open .mainnav__burger {
      display: block;
    }
  }

  .mainnav__button {
    display: flex;
    align-items: center;
    width: 227px;
    height: 40px;
    background: #00ff88;
    border-radius: 4px;
    border: 0px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    padding-left: 15px;
    color: #000729;
    cursor: pointer;
    position: relative;

    &.mainnav__button_customer {
      background: #002cfb;
      color: #fff;
    }
    &.mainnav__button_worker {
      background: #8800ff;
      color: #fff;
    }

    &.open {
      width: 279px;
      background: #fff;
      &::after {
        transform: rotate(180deg);
        background: url('/static/icons/arrow-down.png') no-repeat;
        height: 8px;
      }
      &.mainnav__button_customer {
        color: #000729;
      }
      &.mainnav__button_worker {
        color: #000729;
      }
    }
  }

  .mainnav__button::after {
    content: '';
    position: absolute;
    display: block;
    width: 12px;

    content: url("data:image/svg+xml;charset=UTF-8,%3csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M10.59 0.589844L6 5.16984L1.41 0.589844L0 1.99984L6 7.99984L12 1.99984L10.59 0.589844Z' fill='white'/%3e%3c/svg%3e ");
    right: 10px;
  }

  .business::after {
    content: '';
    position: absolute;
    display: block;
    width: 12px;
    background: url('/static/icons/arrow-down.png') no-repeat;
    height: 8px;
  }

  .mainnav__burger {
    display: none;
    position: absolute;
    top: 42px;
    left: 0;
    width: 279px;
    height: 99px;
    background: #303550;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
  }

  .mainnav__del {
    display: block;
    width: 100%;
    height: 1px;
    background: rgba(255, 255, 255, 0.1);
  }

  .mainnav__link {
    display: flex;
    height: 47px;
    width: 100%;
    align-items: center;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 130%;
    color: #fff;
    text-decoration: none;
    padding-left: 15px;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      display: block;
      width: 13px;
      height: 13px;
      background: url('/static/icons/arrow-right-link.png') no-repeat;
      right: 10px;
    }
  }

  .nav__tab_block {
    pointer-events: none;
  }

  .burger-menu__links {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    grid-gap: 24px;
    margin-top: 60px;
    margin-bottom: 5px;
  }

  .burger-menu__link {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.7);
    font-size: 18px;
    line-height: 120%;
    transition: color 0.3s ease;

    &.active-customer {
      color: #97a8ff !important;
      text-decoration: none;
    }

    &:not(.burger-menu__link_active):hover {
      text-decoration: underline;
      color: #fff;
    }
  }

  .burger-menu__link_active {
    color: #ffd500;
  }

  .burger-menu__links_business {
    grid-gap: 15px;
  }

  .burger-menu__links_business .burger-menu__link {
    text-decoration: none;
    color: #e3e4e8;
    padding: 4px 8px;
    border-radius: 4px;
    background-color: transparent;
    font-size: 18px;
    line-height: 120%;
    width: max-content;
    transition:
      background-color 0.3s ease,
      color 0.3s ease;

    &.burger-menu__link_active {
      font-weight: 500;
      font-size: 18px;
      color: #00ff88;
    }

    &:not(.burger-menu__link_active):hover {
      color: #00ff88;
      text-decoration: none;
    }
  }

  .burger-menu__button {
    width: 100%;
    padding: 13px 10px;
    border-radius: 4px;
    border: 0;
    background-color: rgba(231, 204, 255, 0.25);
    color: #fff;
    font-family: inherit;
    font-size: 16px;
    font-weight: 600;
    line-height: 120%;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    transition:
      background-color 0.3s ease,
      color 0.3s ease;

    & span {
      margin-left: 15px;
      width: 16px;
      height: 23px;
    }

    &:hover {
      background-color: #8800ff;
    }
  }

  .burger-menu__button_business {
    background-color: rgba(0, 7, 41, 0.5);

    &:hover {
      background-color: #00ff88;
      color: #000729;

      & span {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6 1H14V15H6' stroke='%23000729' stroke-width='1.5'/%3e%3cpath d='M6.37986 4.20896L10.0854 7.91455M10.0854 7.91455L6.37986 11.6201M10.0854 7.91455H1' stroke='%23000729' stroke-width='1.5'/%3e%3c/svg%3e ");
      }
    }

    & span {
      width: 24px;
      height: 24px;
    }
  }

  .burger-menu__copyright {
    font-size: 14px;
    line-height: 120%;
    margin-top: 32px;
    color: rgba(255, 255, 255, 0.4);
  }
`

const StyledBurgerScroll = styled.nav`
  background-color: #000729;
  padding: 18px 0;
  position: fixed;
  z-index: 50;
  top: -138px;
  left: 0;
  right: 0;
  margin: auto;
  transition: top 0.3s ease;

  &.scrollnav_active {
    display: flex;
    justify-content: center;
    top: 0;
  }

  .burger {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  .burger-menu {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 7, 41, 0.4);
    z-index: 10;
  }

  .burger-menu__content {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 60px 16px 40px 16px;
    width: 315px;
    height: 100%;
    z-index: 15;
    background:
      radial-gradient(55.7% 97% at 0% 3%, #000e50 21.6%, rgba(0, 23, 134, 0) 80.42%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(20.81% 43.51% at 87.01% 11.68%, #001fb2 0%, rgba(0, 23, 134, 0) 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(66.95% 140.01% at 66.95% 90.11%, #000e50 0%, #001786 42.05%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    mix-blend-mode: normal;
  }

  .burger-menu__content_worker {
    background:
      radial-gradient(63.02% 48.11% at 50% 23.37%, #4c0f7b 0%, rgba(85, 20, 142, 0) 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(22.68% 53.3% at 96.51% -3.22%, #4c1279 0%, rgba(191, 119, 255, 0) 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(31.72% 67.58% at -2.76% 107.52%, #ffe9c0 0%, rgba(123, 34, 197, 0) 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(45.31% 97.19% at -0.62% 55.77%, #cc79ff 0%, rgba(123, 34, 197, 0) 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(70.6% 284.26% at 81.04% 82.79%, #2f0058 0%, #8800ff 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  }

  .burger-menu__content_business {
    background:
      radial-gradient(41.09% 72.88% at 10.68% 15.98%, #00341c 0%, rgba(0, 91, 48, 0) 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(37.63% 76.37% at 100% 0%, #007e43 0%, rgba(0, 91, 48, 0) 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(79.17% 174.71% at 79.17% 78.17%, #00361c 0%, #006636 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    mix-blend-mode: normal;
  }

  .burger-menu_active {
    display: block;
  }

  .burger-menu__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .burger-menu__links {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    grid-gap: 24px;
    margin-top: 60px;
  }

  .burger-menu__link {
    text-decoration: none;
    color: #e3e4e8;
    font-size: 18px;
    line-height: 120%;
    transition: color 0.3s ease;

    &:hover {
      text-decoration: underline;
      color: #fff;
    }
  }

  .burger-menu__links_business {
    grid-gap: 20px;
  }

  .burger-menu__links_business .burger-menu__link {
    text-decoration: none;
    color: #e3e4e8;
    padding: 4px 8px;
    border-radius: 4px;
    background-color: transparent;
    font-size: 18px;
    line-height: 120%;
    width: max-content;
    transition:
      background-color 0.3s ease,
      color 0.3s ease;

    &.burger-menu__link_active {
      font-weight: 500;
      font-size: 18px;
      color: #00ff88;
    }

    &:hover {
      color: #00ff88;
    }
  }

  .burger-menu__button {
    width: 100%;
    padding: 13px 10px;
    border-radius: 4px;
    border: 0;
    background-color: rgba(231, 204, 255, 0.25);
    color: #fff;
    font-family: inherit;
    font-size: 16px;
    font-weight: 600;
    line-height: 120%;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    transition: background-color 0.3s ease;

    & span {
      margin-left: 15px;
      width: 16px;
      height: 23px;
    }

    &:hover {
      background-color: #8800ff;
    }
  }

  .burger-menu__button_business {
    background-color: rgba(0, 7, 41, 0.5);

    &:hover {
      background-color: #00ff88;
      color: #000729;
    }

    & span {
      width: 24px;
      height: 24px;
    }
  }

  .burger-menu__copyright {
    font-size: 14px;
    line-height: 120%;
    margin-top: 32px;
    color: rgba(255, 255, 255, 0.4);
  }
`

export default Navbar
