import classNames from 'classnames'
import { memo, useEffect, useRef, useState } from 'react'
import ReactInputMask from 'react-input-mask'
import { useDispatch, useSelector } from 'react-redux'
import { sendForm } from '../Api'
import s from '../pages/Vip/vip.module.scss'
import { selectForm } from '../store/reducer/form/selector'
import {
  setAddformSendBook,
  setAddformSendCustomer,
  setAddformSendFounder,
  setAddFormSubmitDefault,
  setAddIdCopy,
  setAddLoading,
  setAddPhoneBusiness,
  setAddPhoneSave,
  setAddSendBusiness,
  setAddSendDiagnostics,
  setAddStep,
} from '../store/reducer/form/slice'
import { selectLKAuth } from '../store/reducer/lk/authChech/selector'
import { selectMain } from '../store/reducer/main/selector'
import { setAddActiveLink, setAddOnClickForm } from '../store/reducer/main/slice'
import FormModal from './FormModal'
import ModalAnswer from './ModalAnswer'
import RadioGroup from './RadioGroup'
import Spinner from './Spinner'
import ModalWindow from './FormModalCustomer'

const Form = memo(function Form({ page, type, title, inputRef, inputRefFooter }) {
  const [phone, setPhone] = useState('')
  const [formSend, setFormSend] = useState(false)
  const [inn, setInn] = useState(null)
  const [phoneError, setPhoneError] = useState(false)
  const [innError, setInnError] = useState(false)
  const [reqCode, setReqCode] = useState(JSON.parse(localStorage.getItem('reqCode')))
  const [message, setMessage] = useState('')
  const [width, setWidth] = useState(window?.innerWidth)
  const [isFormSubmit, setIsFormSubmit] = useState(false)
  const [isFormSubmitDiagnostics, setIsFormSubmitDiagnostics] = useState(false)
  const [addOnClick, setAddOnClick] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const { activeLink, activeTarget, onClickForm, onClickFormDiagnostics } = useSelector(selectMain)
  const { user } = useSelector(selectLKAuth)

  const dispatch = useDispatch()
  const isMounted = useRef(false)

  const {
    loading,
    step,
    phoneBusiness,
    formSendBusiness,
    formSendDiagnostics,
    nameBusiness,
    isExpPeoples,
    isExpBusiness,
    messageWhy,
    emailBusiness,
    sendRequest,
    id,
    idCopy,
    formSendCustomer,
  } = useSelector(selectForm)

  const { utm } = useSelector(selectMain)

  useEffect(() => {
    const cb = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', cb)

    return () => window.removeEventListener('resize', cb)
  }, [])

  useEffect(() => {
    if (isMounted.current) {
      localStorage.setItem('id', JSON.stringify(id))
      localStorage.setItem('idCopy', JSON.stringify(idCopy))
      localStorage.setItem('phoneBusiness', JSON.stringify(phoneBusiness))
      localStorage.setItem('formSendBusiness', JSON.stringify(formSendBusiness))
      localStorage.setItem('step', JSON.stringify(step))
      localStorage.setItem('nameBusiness', JSON.stringify(nameBusiness))
      localStorage.setItem('emailBusiness', JSON.stringify(emailBusiness))
      localStorage.setItem('reqCode', JSON.stringify(reqCode))
    }
    isMounted.current = true
  }, [id, idCopy, phoneBusiness, formSendBusiness, step, nameBusiness, emailBusiness, reqCode])

  useEffect(() => {
    const anchor = window.location.hash.split('#')[1]
    if (anchor) {
      const anchorEl = document.getElementById(anchor)
      if (anchorEl) {
        anchorEl.scrollIntoView({
          top: anchorEl.offsetTop,
        })
      }
    }
  }, [])

  const onPhoneNumber = (value) => {
    setPhone(value)

    dispatch(setAddPhoneSave(value))
    localStorage.setItem('phoneSave', JSON.stringify(value))
  }

  const onPhoneNumberBusiness = (value) => {
    dispatch(setAddPhoneBusiness(value))

    dispatch(setAddPhoneSave(value))
    localStorage.setItem('phoneSave', JSON.stringify(value))
  }

  const submitFormFranchise2023 = (e) => {
    e.preventDefault()
    let error = false
    if (
      phone.match(/^\+7 \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}$/) ||
      phoneBusiness.match(/^\+7 \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}$/)
    ) {
      setPhoneError(false)
    } else {
      setPhoneError(true)
      error = true
    }
    dispatch(setAddLoading(true))
    sendForm(phone, 'franchise2023', message, inn, id, utm)
      .then((response) => {
        if (response.data.result === 'success') {
          //setReqCode(response.data.req.code);
          dispatch(setAddLoading(false))
          setFormSend(true)
          return true
        } else {
          setFormSend(false)
          return false
        }
      })
      .catch((e) => {
        setFormSend(false)
        console.log(e)
        return false
      })
  }

  const submitForm = (e) => {
    e.preventDefault()
    let error = false
    if (
      phone.match(/^\+7 \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}$/) ||
      phoneBusiness.match(/^\+7 \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}$/)
    ) {
      setPhoneError(false)
    } else {
      setPhoneError(true)
      error = true
    }
    if (!error && page !== 'business') {
      dispatch(setAddLoading(true))
      sendForm(phone, activeLink, message, inn, id, utm)
        .then((response) => {
          if (response.data.result === 'success') {
            setReqCode(response.data.req.code)
            dispatch(setAddFormSubmitDefault(true))
            dispatch(setAddLoading(false))
            dispatch(setAddformSendCustomer(true))
            setFormSend(true)
            return true
          } else {
            setFormSend(false)
            dispatch(setAddformSendCustomer(false))
            return false
          }
        })
        .catch((e) => {
          setFormSend(false)
          dispatch(setAddformSendCustomer(false))
          console.log(e)
          return false
        })
    } else if (!error && page === 'business') {
      dispatch(setAddLoading(true))
      setFormSend(true)
      sendForm(phoneBusiness, activeLink, messageWhy, inn, id, utm)
        .then((response) => {
          if (response.data.result === 'success') {
            setReqCode(response.data.req.code)
            setIsFormSubmit(true)
            dispatch(setAddLoading(false))
            dispatch(setAddSendBusiness(true))
            dispatch(setAddOnClickForm(true))
            setAddOnClick(true)
            dispatch(setAddIdCopy(id))

            if (id !== idCopy) {
              window.ym(90102156, 'reachGoal', 'submitting')
            }

            return true
          } else {
            dispatch(setAddSendBusiness(false))
            return false
          }
        })
        .catch((e) => {
          dispatch(setAddSendBusiness(false))
          console.log(e)
          return false
        })
    }
  }

  const submitFormDiagnostics = (e) => {
    e.preventDefault()
    let error = false
    if (phoneBusiness.match(/^\+7 \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}$/)) {
      setPhoneError(false)
    } else {
      setPhoneError(true)
      error = true
    }
    if (!error && page === 'diagnostics') {
      dispatch(setAddLoading(true))
      setFormSend(true)
      sendForm(phoneBusiness, activeLink, messageWhy, inn, id, utm)
        .then((response) => {
          if (response.data.result === 'success') {
            setReqCode(response.data.req.code)
            setIsFormSubmitDiagnostics(true)
            dispatch(setAddLoading(false))
            dispatch(setAddSendDiagnostics(true))
            setAddOnClick(true)
            dispatch(setAddIdCopy(id))

            if (id !== idCopy) {
              window.ym(90102156, 'reachGoal', 'submitting')
            }

            return true
          } else {
            dispatch(setAddSendDiagnostics(false))
            return false
          }
        })
        .catch((e) => {
          dispatch(setAddSendDiagnostics(false))
          console.log(e)
          return false
        })
    }
  }

  const submitFormContracts = (e) => {
    e.preventDefault()
    let error = false
    if (phone.match(/^\+7 \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}$/)) {
      setPhoneError(false)
    } else {
      setPhoneError(true)
      error = true
    }
    if (!error) {
      dispatch(setAddLoading(true))
      sendForm(phone, activeLink, message, inn, id, utm)
        .then((response) => {
          if (response.data.result === 'success') {
            setReqCode(response.data.req.code)
            dispatch(setAddFormSubmitDefault(true))
            dispatch(setAddformSendCustomer(true))
            dispatch(setAddOnClickForm(true))
            dispatch(setAddLoading(false))
            return true
          } else {
            dispatch(setAddformSendCustomer(false))
            return false
          }
        })
        .catch((e) => {
          dispatch(setAddformSendCustomer(false))
          console.log(e)
          return false
        })
    }
  }

  const submitFormFounder = (e) => {
    e.preventDefault()
    let error = false
    if (phone.match(/^\+7 \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}$/)) {
      setPhoneError(false)
    } else {
      setPhoneError(true)
      error = true
    }
    if (!error) {
      dispatch(setAddLoading(true))
      sendForm(phone, activeLink, message, inn, id, utm)
        .then((response) => {
          if (response.data.result === 'success') {
            setReqCode(response.data.req.code)
            dispatch(setAddFormSubmitDefault(true))
            dispatch(setAddLoading(false))
            dispatch(setAddformSendFounder(true))

            setTimeout(() => {
              dispatch(setAddformSendFounder(false))
            }, 2000)
            setFormSend(true)
            return true
          } else {
            setFormSend(false)
            return false
          }
        })
        .catch((e) => {
          setFormSend(false)
          console.log(e)
          return false
        })
    }
  }

  const submitFormOrderBooks = (e) => {
    e.preventDefault()
    let error = false
    if (phone.match(/^\+7 \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}$/)) {
      setPhoneError(false)
    } else {
      setPhoneError(true)
      error = true
    }
    if (!error) {
      dispatch(setAddLoading(true))
      sendForm(phone, activeLink, message, inn, id, utm)
        .then((response) => {
          if (response.data.result === 'success') {
            setReqCode(response.data.req.code)
            dispatch(setAddFormSubmitDefault(true))
            dispatch(setAddLoading(false))
            dispatch(setAddformSendBook(true))

            setTimeout(() => {
              window.open('https://skilla.ru/book', '_blank')
            })

            setTimeout(() => {
              dispatch(setAddformSendBook(false))
            }, 2000)
            setFormSend(true)
            return true
          } else {
            setFormSend(false)
            return false
          }
        })
        .catch((e) => {
          setFormSend(false)
          console.log(e)
          return false
        })
    }
  }

  const submitFormWithInn = (e) => {
    e.preventDefault()
    let error = false
    if (phone.match(/^\+7 \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}$/)) {
      setPhoneError(false)
    } else {
      setPhoneError(true)
      error = true
    }
    if (inn.match(/\d/g).length == 10 || inn.match(/\d/g).length == 12) {
      setInnError(false)
    } else {
      setInnError(true)
      error = true
    }

    if (!error) {
      dispatch(setAddLoading(true))
      sendForm(phone, activeLink, message, inn, id, utm)
        .then((response) => {
          if (response.data.result === 'success') {
            setReqCode(response.data.req.code)
            dispatch(setAddFormSubmitDefault(true))
            dispatch(setAddOnClickForm(true))
            dispatch(setAddLoading(false))
            dispatch(setAddformSendCustomer(true))
            return true
          } else {
            dispatch(setAddformSendCustomer(false))
            return false
          }
        })
        .catch((e) => {
          dispatch(setAddformSendCustomer(false))
          console.log(e)
          return false
        })
    } else {
      dispatch(setAddformSendCustomer(false))
    }
  }

  /*MODAL*/
  /*const submitFormWithInn = (e) => {
    e.preventDefault()

    let error = false

    // Валидация телефона
    if (phone.match(/^\+7 \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}$/)) {
      setPhoneError(false)
    } else {
      setPhoneError(true)
      error = true
    }

    // Валидация ИНН
    if (inn.match(/\d/g).length === 10 || inn.match(/\d/g).length === 12) {
      setInnError(false)
    } else {
      setInnError(true)
      error = true
    }

    // Если ошибок нет, показать модальное окно
    if (!error) {
      setShowModal(true)
    }
  }

  // Обработчик для отправки формы из модального окна
  const submitModalForm = (modalData) => {
    dispatch(setAddLoading(true))

    console.log('modalData', modalData)

    // Вызов функции sendForm с включением данных из модального окна
    sendForm(phone, activeLink, message, inn, id, utm, modalData)
      .then((response) => {
        if (response.data.result === 'success') {
          setReqCode(response.data.req.code)
          dispatch(setAddFormSubmitDefault(true))
          dispatch(setAddOnClickForm(true))
          dispatch(setAddLoading(false))
          dispatch(setAddformSendCustomer(true))
          setShowModal(false) // Закрываем модальное окно после успешной отправки
          return true
        } else {
          dispatch(setAddformSendCustomer(false))
          return false
        }
      })
      .catch((e) => {
        dispatch(setAddformSendCustomer(false))
        console.log(e)
        return false
      })
  }

  const closeModal = () => {
    setShowModal(false)
  }*/

  if (page === 'customer')
    return loading ? (
      <Spinner />
    ) : (
      <>
        <form onSubmit={submitFormWithInn} className="header__form footer-header__form">
          {formSendCustomer && <div className="header__form-result">Спасибо. Ваша заявка принята.</div>}
          {!formSendCustomer && (
            <>
              <ReactInputMask
                className={phoneError ? 'header__input error' : 'header__input'}
                mask="+7 (\999) 999-99-99"
                maskChar=""
                alwaysShowMask={false}
                placeholder="+7 (___) ___-__-__"
                value={phone}
                required
                style={{ color: '#fff' }}
                onChange={({ target: { value } }) => onPhoneNumber(value)}
              />
              <button className="header__button">
                Разместить заказ
                <span className="arrow-right-big icon" />
              </button>
              <ReactInputMask
                className={innError ? 'header__input error' : 'header__input'}
                mask="999999999999"
                maskChar=""
                alwaysShowMask={false}
                placeholder="ИНН"
                value={inn}
                required
                style={{ color: '#fff' }}
                onChange={({ target: { value } }) => setInn(value)}
              />
            </>
          )}
        </form>
        {/*<ModalWindow
          isOpen={showModal}
          onRequestClose={closeModal}
          submitModalForm={submitModalForm}
          phone={phone}
          onPhoneNumber={onPhoneNumber}
          inn={inn}
          setInn={setInn}
          phoneError={phoneError}
        />*/}
      </>
    )

  if (page === 'business' || type === 'calculator')
    return !isFormSubmit && !addOnClick ? (
      loading ? (
        <Spinner />
      ) : (
        <form
          onSubmit={submitForm}
          method="GET"
          className={classNames('header-form business', {
            [s.form]: (type === 'vip' && !formSendBusiness) || !user?.user?.code,
            calculator: type === 'calculator',
            modalAnswer: formSendBusiness,
            modalInUnsubscribe: type === 'unsubscribe',
          })}
        >
          {formSendBusiness && !onClickForm && (
            <form>
              <ModalAnswer
                phone={phoneBusiness}
                step={step}
                nameBusiness={nameBusiness}
                phoneBusiness={phoneBusiness}
                formSendBusiness={formSendBusiness}
                messageWhy={messageWhy}
                sendRequest={sendRequest}
                setAddOnClick={setAddOnClick}
              />
            </form>
          )}

          {!formSendBusiness && (
            <>
              <div
                className={classNames('header-form__flex', {
                  [s.wrapperField]: type === 'vip',
                })}
              >
                <ReactInputMask
                  ref={inputRef}
                  className={classNames('header-form__input', {
                    [s.field]: type === 'vip',
                    [s.error]: phoneError,
                    error: phoneError,
                  })}
                  mask="+7 (\999) 999-99-99"
                  maskChar=""
                  alwaysShowMask={false}
                  placeholder="+7 (___) ___-__-__"
                  value={phoneBusiness}
                  required
                  style={{ color: '#fff' }}
                  onChange={({ target: { value } }) => {
                    onPhoneNumberBusiness(value)
                  }}
                />
                {type === 'calculator' ||
                  (width <= 768 && <RadioGroup defaultValue="telegram" type="calculator" />)}

                {type === 'unsubscribe' && (
                  <button
                    onClick={() => dispatch(setAddStep(1))}
                    type="submit"
                    className="header-form__button"
                  >
                    Присоединиться
                    <span className="arrow-right-business icon"></span>
                  </button>
                )}

                {type === 'calculator' && (
                  <button
                    onClick={() => dispatch(setAddStep(1), dispatch(setAddActiveLink('business-calculator')))}
                    type="submit"
                    className="header-form__button header-form__button--mobile"
                  >
                    Получить расчет
                    {width > 768 && <span className="arrow-right-business icon"></span>}
                  </button>
                )}

                {type === 'franchise' && (
                  <button
                    onClick={() => dispatch(setAddStep(1))}
                    type="submit"
                    className="header-form__button"
                  >
                    Получить презентацию
                    <span className="arrow-right-business icon"></span>
                  </button>
                )}

                {type === 'main' && (
                  <button
                    onClick={() => dispatch(setAddStep(1))}
                    type="submit"
                    className="header-form__button"
                  >
                    Узнать больше
                    <span className="arrow-right-business icon"></span>
                  </button>
                )}

                {type === 'vip' && (
                  <button onClick={() => dispatch(setAddStep(1))} type="submit" className={s.form_button}>
                    Получить Премиум-пакет
                  </button>
                )}

                {type === 'about' && (
                  <button
                    onClick={() => dispatch(setAddStep(1))}
                    type="submit"
                    className="header-form__button"
                  >
                    Записаться на экскурсию
                    <span className="arrow-right-business icon"></span>
                  </button>
                )}

                {type === 'about-footer' && (
                  <button
                    onClick={() => dispatch(setAddStep(1))}
                    type="submit"
                    className="header-form__button"
                  >
                    Записаться
                    <span className="arrow-right-business icon"></span>
                  </button>
                )}
              </div>
              {width > 768 && <RadioGroup defaultValue="telegram" type="calculator" />}
            </>
          )}
        </form>
      )
    ) : (
      <FormModal
        type={type}
        isFormSubmit={isFormSubmit}
        setIsFormSubmit={setIsFormSubmit}
        setAddOnClick={setAddOnClick}
        activeTarget={activeTarget}
        nameBusiness={nameBusiness}
        reqCode={reqCode}
        isExpPeoples={isExpPeoples}
        isExpBusiness={isExpBusiness}
        messageWhy={messageWhy}
        emailBusiness={emailBusiness}
        step={step}
        phoneBusiness={phoneBusiness}
        setAddStep={setAddStep}
      />
    )

  if (page === 'diagnostics')
    return !isFormSubmitDiagnostics && !addOnClick ? (
      loading ? (
        <Spinner />
      ) : (
        <form
          onSubmit={submitFormDiagnostics}
          method="GET"
          className={classNames('header-form business', {
            modalAnswer: formSendDiagnostics,
          })}
        >
          {formSendDiagnostics && !onClickFormDiagnostics && (
            <form>
              <ModalAnswer
                page="diagnostics"
                phone={phoneBusiness}
                step={step}
                nameBusiness={nameBusiness}
                phoneBusiness={phoneBusiness}
                formSendDiagnostics={formSendDiagnostics}
                messageWhy={messageWhy}
                sendRequest={sendRequest}
                setAddOnClick={setAddOnClick}
              />
            </form>
          )}

          {!formSendDiagnostics && (
            <>
              <div className="header-form__flex">
                <ReactInputMask
                  className={phoneError ? 'header-form__input error' : 'header-form__input'}
                  mask="+7 (\999) 999-99-99"
                  maskChar=""
                  alwaysShowMask={false}
                  placeholder="+7 (___) ___-__-__"
                  value={phoneBusiness}
                  required
                  style={{ color: '#fff' }}
                  onChange={({ target: { value } }) => {
                    onPhoneNumberBusiness(value)
                  }}
                />
                {type === 'calculator' ||
                  (width <= 768 && <RadioGroup defaultValue="telegram" type="calculator" />)}

                <button onClick={() => dispatch(setAddStep(1))} type="submit" className="header-form__button">
                  Записаться
                  <span className="arrow-right-business icon"></span>
                </button>
              </div>
              {width > 768 && <RadioGroup defaultValue="telegram" type="calculator" />}
            </>
          )}
        </form>
      )
    ) : (
      <FormModal
        type={type}
        isFormSubmit={isFormSubmit}
        setIsFormSubmit={setIsFormSubmit}
        setAddOnClick={setAddOnClick}
        activeTarget={activeTarget}
        nameBusiness={nameBusiness}
        reqCode={reqCode}
        isExpPeoples={isExpPeoples}
        isExpBusiness={isExpBusiness}
        messageWhy={messageWhy}
        emailBusiness={emailBusiness}
        step={step}
        phoneBusiness={phoneBusiness}
        setAddStep={setAddStep}
      />
    )

  if (page === 'founder')
    return loading ? (
      <Spinner />
    ) : (
      <form
        onSubmit={submitFormFounder}
        method="GET"
        style={{
          margin: width <= 768 && '0 auto',
          width: formSend && width <= 768 && '343px',
          margin: formSend && width > 768 && '102px 0 124px',
        }}
        className="header-form header-form--founder"
      >
        {formSend && <div className="header__form-result">Спасибо. Ваша заявка принята.</div>}
        {!formSend && (
          <>
            <textarea
              className="header-form__textarea"
              onChange={(e) => setMessage(e.target.value)}
              required
              value={message}
              name="textarea"
              placeholder="Текст сообщения"
            ></textarea>
            <div className="header-form__flex">
              <ReactInputMask
                className={phoneError ? 'header-form__input error' : 'header-form__input  opacity'}
                mask="+7 (\999) 999-99-99"
                maskChar=""
                alwaysShowMask={false}
                placeholder="+7 (___) ___-__-__"
                value={phone}
                required
                style={{ color: '#fff' }}
                onChange={({ target: { value } }) => onPhoneNumber(value)}
              />

              <button
                onClick={() => dispatch(setAddOnClick(true), dispatch(setAddActiveLink('founder')))}
                className="header-form__button footer-btn"
              >
                Написать Кириллу
                <span className="arrow-right-business icon"></span>
              </button>
            </div>
            <RadioGroup defaultValue="telegram" />
          </>
        )}
      </form>
    )

  if (page === 'contracts')
    return loading ? (
      <Spinner />
    ) : (
      <form onSubmit={submitFormContracts} method="GET" className="header-form contracts">
        {formSendCustomer && <div className="header__form-result">Спасибо. Ваша заявка принята.</div>}
        {!formSendCustomer && (
          <>
            <textarea
              className="header-form__textarea"
              onChange={(e) => setMessage(e.target.value)}
              required
              value={message}
              name="textarea"
              placeholder="Введите описание"
            ></textarea>
            <div className="header-form__flex">
              <ReactInputMask
                className={phoneError ? 'header-form__input error' : 'header-form__input gray'}
                mask="+7 (\999) 999-99-99"
                maskChar=""
                alwaysShowMask={false}
                placeholder="+7 (___) ___-__-__"
                value={phone}
                required
                style={{ color: '#fff' }}
                onChange={({ target: { value } }) => onPhoneNumber(value)}
              />
              {title === 'contracts' ? (
                <button type="submit" className="header-form__button contracts">
                  Пригласить на тендер
                  <span className="arrow-right-big icon"></span>
                </button>
              ) : title === 'technologies' ? (
                <button type="submit" className="header-form__button contracts">
                  Узнать больше
                  <span className="arrow-right-big icon"></span>
                </button>
              ) : (
                ''
              )}
            </div>
          </>
        )}
      </form>
    )

  if (page === 'franchise2023')
    return loading ? (
      <Spinner />
    ) : (
      <form
        id={width <= 768 && 'order-book'}
        onSubmit={submitFormFranchise2023}
        method="GET"
        style={{
          width: formSend && width <= 768 && '343px',
          margin: formSend && width <= 768 && '0 auto',
        }}
        className={classNames('header-form', {
          'header-form__books': type === 'books',
          'calculator-result__form': type === 'calculator',
        })}
      >
        {formSend && <div className="header__form-result">Спасибо. Ваша заявка принята.</div>}
        {!formSend && (
          <>
            <div className="header-form__flex">
              <ReactInputMask
                className={phoneError ? 'header-form__input error' : 'header-form__input opacity'}
                mask="+7 (\999) 999-99-99"
                maskChar=""
                alwaysShowMask={false}
                placeholder="+7 (___) ___-__-__"
                value={phone}
                required
                style={{ color: '#fff' }}
                onChange={({ target: { value } }) => onPhoneNumber(value)}
              />

              {width <= 768 && <RadioGroup defaultValue="telegram" />}
              <button className="header-form__button">
                Узнать больше
                <span className="arrow-right-business icon"></span>
              </button>
            </div>
            {width > 768 && <RadioGroup defaultValue="telegram" />}
          </>
        )}
      </form>
    )

  return loading ? (
    <Spinner />
  ) : (
    <form
      id={width <= 768 && 'order-book'}
      onSubmit={submitFormOrderBooks}
      method="GET"
      style={{
        width: formSend && width <= 768 && '343px',
        margin: formSend && width <= 768 && '0 auto',
      }}
      className={classNames('header-form', {
        'header-form__books': type === 'books',
        'calculator-result__form': type === 'calculator',
      })}
    >
      {formSend && <div className="header__form-result">Спасибо. Ваша заявка принята.</div>}
      {!formSend && (
        <>
          <div className="header-form__flex">
            <ReactInputMask
              className={phoneError ? 'header-form__input error' : 'header-form__input opacity'}
              mask="+7 (\999) 999-99-99"
              maskChar=""
              alwaysShowMask={false}
              placeholder="+7 (___) ___-__-__"
              value={phone}
              required
              style={{ color: '#fff' }}
              onChange={({ target: { value } }) => onPhoneNumber(value)}
            />

            {width <= 768 && <RadioGroup defaultValue="telegram" />}
            {type !== 'books' ? (
              <button className="header-form__button">
                Узнать больше
                <span className="arrow-right-business icon"></span>
              </button>
            ) : (
              <button
                onClick={() => dispatch(setAddActiveLink('founder(order-book)'))}
                className="header-form__button"
              >
                Предзаказ
                <span className="arrow-right-business icon"></span>
              </button>
            )}
          </div>
          {width > 768 && <RadioGroup defaultValue="telegram" />}
        </>
      )}
    </form>
  )
})
export default Form
