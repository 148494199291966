import s from './button.module.scss';
import cn from 'classnames';


function Button({ callback, children, back }) {

   function scrollToTop() {
		window.scroll(0, 0);
	 };

   return (
      <button
         onClick={callback}
         onMouseUp={scrollToTop}
         className={cn(s.button, {
            [s.button_back]: back
         })} >
         {children}
      </button>
   );
}

export default Button;
