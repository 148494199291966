import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { getUser } from '../../../../../Api';
import { setAddUser } from '../../../../../store/reducer/lk/authChech/slice';
import { selectLkFormExam } from '../../../../../store/reducer/lk/formExam/selector';
import {
	setAddData,
	setAddStep,
	setAddIncorrect,
} from '../../../../../store/reducer/lk/formExam/slice';

import s from '../steps.module.scss';

import { selectLKAuth } from '../../../../../store/reducer/lk/authChech/selector';
import { Spinner } from '../../../../spinner/Spinner';
import Button from '../../Button/Button';
import Form from '../../Form';
import { sendExamResult } from '../../../../../Api';
import { questionsExam } from '../../../../../utils/questionExam';
import { answersExam } from '../../../../../utils/questionExam';

const Step35 = () => {
	const [width, setWidth] = useState(window?.innerWidth);
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const { step, status, data } = useSelector(selectLkFormExam);
	const { user } = useSelector(selectLKAuth);

  
	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

	useEffect(() => {
		localStorage.setItem('stepLkExam', JSON.stringify(step));
		localStorage.setItem('dataExam', JSON.stringify(data));
	}, []);

	const schema = yup
		.object({
			['В каких случаях первичный звонок от клиента поступает напрямую партнеру?']:
				yup.string().required(),

			['Для чего нужен номер для приложения?']:
				yup.string().required(),
		})
		.required();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			['В каких случаях первичный звонок от клиента поступает напрямую партнеру?']: data['В каких случаях первичный звонок от клиента поступает напрямую партнеру?'],

			['Для чего нужен номер для приложения?']: data ['Для чего нужен номер для приложения?'],
		},
	});

	const onSubmit = dataEnd => {

		setLoading(true);
		dispatch(setAddData({ ...data, ...dataEnd }));
		const exam = checkExam(dataEnd);
		localStorage.setItem('scoreExam', JSON.stringify(exam.scores))
        const bodyFormData = new FormData();
		bodyFormData.append('answers', JSON.stringify(exam.answers));

		sendExamResult(user.user.code, exam, bodyFormData)
		.then(({data}) => {

			if(data.result === 'success') {
				getUser(user?.user?.code).then(({ data }) => {
					dispatch(setAddUser(data));
					localStorage.setItem('user', JSON.stringify(data));
				});
				
				dispatch(setAddStep(36));
				localStorage.removeItem('dataExam');
				localStorage.setItem('stepLkExam', JSON.stringify(36));
			}
		})
		.finally(setLoading(false));
	};
    function checkExam(dataEnd) {
		let count = 0;
		let exam;
		const incorrect = []

		questionsExam.map((item, i) => {
			const newData = {...data, ...dataEnd}
			const answer = Array.isArray(newData[item]) ? newData[item] : [newData[item]];
			if(JSON.stringify(answer) === JSON.stringify(answersExam[i])) {
				count++
			} else {
              incorrect.push({question: item,
			                  answer: answer, 
							  correct: answersExam[i]})
			}
		
		});
		
		if(count >= 70) {
			exam = {
				status: 'success', 
				scores: count,	
				answers: incorrect
			   }
		} else {
			exam = {
					status: 'failed', 
					scores: count,	
				    answers: incorrect
			       }
			
		}
		dispatch(setAddIncorrect(incorrect));
		localStorage.setItem('inccorectExam', JSON.stringify(incorrect));
        return exam;
	}

	

	return (
		<div className={s.step35}>
			{loading && <Spinner />}
            <div className={s.wrapper_heading}>
			  <h5>Телефония</h5>
			</div>
			<Form onSubmit={handleSubmit(onSubmit)}>

                <div className={s.form_item}>
					<h4 className={s.form_title}>99. В каких случаях первичный звонок от клиента поступает напрямую партнеру?</h4>

					<div className={s.question99}>
                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question99,
								})}
								{...register('В каких случаях первичный звонок от клиента поступает напрямую партнеру?')}
								id='exam991'
								type='radio'
								name='В каких случаях первичный звонок от клиента поступает напрямую партнеру?'
								autoComplete='off'
								value='Когда клиент сам звонит с контекстной рекламы'
							/>
							<label htmlFor='exam991' className={s.label}>
							Когда клиент сам звонит с контекстной рекламы
							</label>
						</div>

						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question99,
								})}
								{...register('В каких случаях первичный звонок от клиента поступает напрямую партнеру?')}
								id='exam992'
								type='radio'
								name='В каких случаях первичный звонок от клиента поступает напрямую партнеру?'
								autoComplete='off'
								value='Когда клиент оставляет заявку на обратный звонок с сайта'
							/>
							<label htmlFor='exam992' className={s.label}>
							Когда клиент оставляет заявку на обратный звонок с сайта
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question99,
								})}
								{...register('В каких случаях первичный звонок от клиента поступает напрямую партнеру?')}
								id='exam993'
								type='radio'
								name='В каких случаях первичный звонок от клиента поступает напрямую партнеру?'
								autoComplete='off'
								value='Когда клиент знает рабочий номер Менеджера'
							/>
							<label htmlFor='exam993' className={s.label}>
							Когда клиент знает рабочий номер Менеджера
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question99,
								})}
								{...register('В каких случаях первичный звонок от клиента поступает напрямую партнеру?')}
								id='exam994'
								type='radio'
								name='В каких случаях первичный звонок от клиента поступает напрямую партнеру?'
								autoComplete='off'
								value='Когда линия контактного центра занята'
							/>
							<label htmlFor='exam994' className={s.label}>
							Когда линия контактного центра занята
							</label>
						</div>
					</div>

					<p className={s.errorText}>
						{errors['В каких случаях первичный звонок от клиента поступает напрямую партнеру?'] ? 'Необходимо указать' : ''}
					</p>
				</div>

                <div className={s.form_item}>
					<h4 className={s.form_title}>100. Для чего нужен номер для приложения?</h4>

					<div className={s.question100}>
                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question100,
								})}
								{...register('Для чего нужен номер для приложения?')}
								id='exam1001'
								type='radio'
								name='Для чего нужен номер для приложения?'
								autoComplete='off'
								value='Чтобы исполнители могли звонить клиенту, не видя его реальный номер'
							/>
							<label htmlFor='exam1001' className={s.label}>
							Чтобы исполнители могли звонить клиенту, не видя его реальный номер
							</label>
						</div>

						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question99,
								})}
								{...register('Для чего нужен номер для приложения?')}
								id='exam1002'
								type='radio'
								name='Для чего нужен номер для приложения?'
								autoComplete='off'
								value='Чтобы клиенты могли связываться с исполнителями на заказе'
							/>
							<label htmlFor='exam1002' className={s.label}>
							Чтобы клиенты могли связываться с исполнителями на заказе
							</label>
						</div>
					</div>

					<p className={s.errorText}>
						{errors['Для чего нужен номер для приложения?'] ? 'Необходимо указать' : ''}
					</p>
				</div>

				
				<div style={{ marginTop: '232px' }} className={s.btns}>
					<Button callback={() => dispatch(setAddStep(34))} back>
						{width > 575 ? 'Назад' : <img src='/static/lk/IconBackward.svg' alt='назад' />}
					</Button>
					<Button>{width > 575 ? 'Отправить вопросы на проверку' : 'Отправить'}</Button>
				</div>
			</Form>

			<img
				className={s.illustration}
				src='/static/lk/illustration/illustration.png'
				alt='Иллюстрация'
			/>
		</div>
	);
};

export default Step35;
