import cn from 'classnames';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { selectLKPayment } from '../../../../../store/reducer/lk/payment/selector.js';

import SpinnerSmall from '../../../../SpinnerSmall';
import s from './pay.module.scss';

const Pay = () => {
	const { paymentInfo, isLoading } = useSelector(selectLKPayment);
	const [choicePay, setChoicePay] = useState(1);
	const formApi = paymentInfo?.payment?.form;
	console.log(formApi)

	return (
		<>
			<div className={s.checkboxs}>
				{/* <div className={s.radio}>
					<input
						className={cn(s.input, {})}
						onClick={() => setChoicePay(1)}
						id='radio'
						type='radio'
						name='radio'
						autoComplete='off'
						value='Предоплата 20%'
						defaultChecked
					/>
					<label htmlFor='radio' className={s.label}>
						Предоплата 20%
					</label>
				</div> */}

				{/* <div className={s.radio}>
					<input
						className={cn(s.input, {})}
						onClick={() => setChoicePay(2)}
						id='radio2'
						type='radio'
						name='radio'
						autoComplete='off'
						value='Полная оплата договора'
					/>
					<label htmlFor='radio2' className={s.label}>
						Полная оплата договора
					</label>
				</div> */}
			</div>

			<div className={s.current}>
				<div className={s.current_info}>
					<h5>Итого к оплате:</h5>
					{!isLoading ? (
						<div>
							{choicePay === 1 &&
								`${paymentInfo?.payment?.prepaid_sum.toLocaleString(
									'ru-RU'
								)} ₽`}

							{choicePay === 2 &&
								`${paymentInfo?.payment?.sum.toLocaleString('ru-RU')} ₽`}
						</div>
					) : (
						<SpinnerSmall widthSize='70px' heightSize='70px' />
					)}
				</div>
			</div>

			{choicePay === 1 ? (
				<div
					dangerouslySetInnerHTML={{ __html: formApi }}
					className={s.form}
				></div>
			) : (
				<div className={s.details}>
					<h5>Полная оплата возможна только по реквизитам:</h5>
					<ul>
						<li>ООО "СКИЛЛА ИННОВАЦИИ"</li>
						<li> ИНН 4706050219</li>
						<li>КПП 470601001</li>
						<li>Банк АО "ТИНЬКОФФ БАНК"</li>
						<li>БИК банка 044525974</li>
						<li>Расчетный счет 40702810810001131177</li>
						<li>Корреспондентский счет банка 30101810145250000974</li>
					</ul>
				</div>
			)}
		</>
	);
};

export default Pay;
