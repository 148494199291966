import cn from 'classnames';
import { memo } from 'react';
import s from '../../access.module.scss';

const Waiting = memo(({ width, clientWithoutCours }) => {

	return (
		<div className={s.waiting}>
			<div className={cn(s.heading, s.heading_waiting)}>
				<div className={s.wrapper_heading}>
					<img src='/static/lk/iconTime.svg' alt='иконка' />
					<h5>{width > 575 ? `${clientWithoutCours ? 10 : 11}. Доступ в Skilla IS` : `Шаг ${clientWithoutCours ? 10 : 11}`}</h5>
				</div>

				<h2>Ожидаем доступ</h2>

				<p>Здесь появятся данные для доступа в Skilla IS</p>
			</div>

			<img
				className={cn(s.illustration, s.illustration_waiting)}
				src={width > 575 ? '/static/lk/illustration/access.png' : '/static/lk/illustration/access-mobile.png'}
				alt='иллюстрация'
			/>
		</div>
	);
});
export default Waiting;
