import cn from 'classnames';
import s from '../steps.module.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import {
	setAddData,
	setAddStep,
} from '../../../../../store/reducer/lk/formExam/slice';

import { selectLkFormExam } from '../../../../../store/reducer/lk/formExam/selector';
import Button from '../../Button/Button';
import Form from '../../Form';

const Step17 = () => {
	const [width, setWidth] = useState(window?.innerWidth);
	const dispatch = useDispatch();
	const { data, step } = useSelector(selectLkFormExam);
	const [isError48, setError48] = useState(false);
	const [isSendForm, setIsSendForm] = useState(false);

	useEffect(() => {
		localStorage.setItem('stepLkExam', JSON.stringify(step));
		localStorage.setItem('dataExam', JSON.stringify(data));
	}, []);

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

	const schema47= yup
		.object({
			['Норма повторных заказов от физ и юр лиц']: yup.string(),
            ['1']: yup.string()
		})
		.required();

	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
		watch,
	} = useForm({
		resolver: yupResolver(schema47),
		defaultValues: {
			['Норма повторных заказов от физ и юр лиц']: data['Норма повторных заказов от физ и юр лиц'],
			['Ваши основные задачи по контролю за контекстной рекламной']: data['Ваши основные задачи по контролю за контекстной рекламной'],
		},
	});

	
	const ckbox48 = watch('Ваши основные задачи по контролю за контекстной рекламной');
    
	useEffect(() => {
		if (!ckbox48?.length) {
			setError48(true);
		} else {
			setError48(false);
		}
	}, [ckbox48]);


	const onSubmit = data => {
		setIsSendForm(true);

		if (!isError48) {
			dispatch(setAddStep(18));
			dispatch(setAddData(data));
			setIsSendForm(false);

			localStorage.setItem('dataExam', JSON.stringify(data));
			localStorage.setItem('stepLkExam', JSON.stringify(17));
		}
	};

	return (
		<div className={s.step17}>
      <div className={s.wrapper_heading}>
			  <h5>Реклама</h5>
			</div>
			<Form onSubmit={handleSubmit(onSubmit)}>

                <div className={s.form_item}>
					<h4 className={s.form_title}>47. Норма повторных заказов от физ. и юр. лиц</h4>

					<div className={s.question47}>
						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question47,
								})}
								{...register('Норма повторных заказов от физ и юр лиц')}
								id='exam471'
								type='radio'
								name='Норма повторных заказов от физ и юр лиц'
								autoComplete='off'
								value='10% от физ. лиц, 80% от юр лиц'/>

							<label htmlFor='exam471' className={s.label}>
							10% от физ. лиц, 80% от юр. лиц
							</label>
						</div>

						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question47,
								})}
								{...register('Норма повторных заказов от физ и юр лиц')}
								id='exam472'
								type='radio'
								name='Норма повторных заказов от физ и юр лиц'
								autoComplete='off'
								value='80% от физ. лиц, 10% от юр. лиц'/>

							<label htmlFor='exam472' className={s.label}>
							80% от физ. лиц, 10% от юр. лиц
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question47,
								})}
								{...register('Норма повторных заказов от физ и юр лиц')}
								id='exam473'
								type='radio'
								name='Норма повторных заказов от физ и юр лиц'
								autoComplete='off'
								value='50% от физ. лиц, 50% от юр. лиц'/>

							<label htmlFor='exam473' className={s.label}>
							50% от физ лиц, 50% от юр лиц
							</label>
						</div>
					</div>

					<p className={s.errorText}>
						{errors['Норма повторных заказов от физ и юр лиц'] ? 'Необходимо указать' : ''}
					</p>
			    </div>

                <div className={s.form_item}>
					<h4 className={s.form_title}>
					48. Ваши основные задачи по контролю за контекстной рекламной
					</h4>

					<div className={s.question48}>
						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question48,
								})}
								{...register('Ваши основные задачи по контролю за контекстной рекламной')}
								id='exam481'
								type='checkbox'
								name='Ваши основные задачи по контролю за контекстной рекламной'
								autoComplete='off'
								value='Следить за показателем кликабельности CTR'/>
							<label htmlFor='exam481' className={s.label}>
							Следить за показателем кликабельности CTR
							</label>
						</div>

                        <div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question48,
								})}
								{...register('Ваши основные задачи по контролю за контекстной рекламной')}
								id='exam482'
								type='checkbox'
								name='Ваши основные задачи по контролю за контекстной рекламной'
								autoComplete='off'
								value='Следить за средней позицией показа объявления'/>
							<label htmlFor='exam482' className={s.label}>
							Следить за средней позицией показа объявления
							</label>
						</div>

                        <div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question48,
								})}
								{...register('Ваши основные задачи по контролю за контекстной рекламной')}
								id='exam483'
								type='checkbox'
								name='Ваши основные задачи по контролю за контекстной рекламной'
								autoComplete='off'
								value='Вовремя пополнять баланс аккаунта Яндекс'/>
							<label htmlFor='exam483' className={s.label}>
							Вовремя пополнять баланс аккаунта Яндекс
							</label>
						</div>

                        <div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question48,
								})}
								{...register('Ваши основные задачи по контролю за контекстной рекламной')}
								id='exam484'
								type='checkbox'
								name='Ваши основные задачи по контролю за контекстной рекламной'
								autoComplete='off'
								value='Контролировать среднюю цену клика'/>
							<label htmlFor='exam484' className={s.label}>
							Контролировать среднюю цену клика
							</label>
						</div>

						<p className={s.errorText2}>
							{isError48 &&
								isSendForm &&
								'Необходимо указать'}
						</p>
					</div>
				</div>

                

				<div className={s.btns}>
        <Button callback={() => dispatch(setAddStep(16))} back>
						{width > 575 ? (
							'   Назад'
						) : (
							<img src='/static/lk/IconBackward.svg' alt='назад' />
						)}
					</Button>
					<Button>Далее 17 из 35</Button>
				</div>
			</Form>

			<img
				className={s.illustration}
				src='/static/lk/illustration/illustration.png'
				alt='Иллюстрация'
			/>
		</div>
	);
};

export default Step17;
