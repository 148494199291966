import cn from 'classnames'
import { Helmet } from 'react-helmet-async'
import ReactInputMask from 'react-input-mask'
import { Link } from 'react-router-dom'
import s from './enter.module.scss'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { getAuthLk } from '../../Api'
import { selectLKAuth } from '../../store/reducer/lk/authChech/selector'
import { ReactComponent as IconChewron } from '../../image/iconChewron.svg'
import { ReactComponent as IconCheck } from '../../image/lk/iconCheck.svg'
import ModalHH from '../../components/ModalHh/ModalHH'

import {
  setAddAuth,
  setAddPhoneNumber,
  setAddPhoneNumberFromUrl,
} from '../../store/reducer/lk/authChech/slice'

const Enter = () => {
  const [valuePhone, setValuePhone] = useState('')
  const [width, setWidth] = useState(window?.innerWidth)
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { user, logOut, phoneNumberFromUrl } = useSelector(selectLKAuth)

  const userCode = user?.user?.code

  const phoneRegExp = /^\+7 \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}$/
  const currentYear = new Date().getUTCFullYear()

  let phoneFromUrl = {}

  useEffect(() => {
    if (phoneFromUrl['phone']) {
      dispatch(setAddPhoneNumberFromUrl(phoneFromUrl.phone.replace(/^\d/, '7')))
    }
  }, [])

  window.location.search
    .substring(1)
    .split('&')
    .map((item) => {
      let param = item.split('=')
      phoneFromUrl[param[0]] = param[1]
    })

  const schema = yup.object({
    number: yup.string().matches(phoneRegExp).required(),
  })

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: (!phoneFromUrl['phone'] || valuePhone) && yupResolver(schema),
  })

  const hasPhoneNumber = watch('number')

  useEffect(() => {
    setValuePhone(hasPhoneNumber)
  }, [hasPhoneNumber])

  useEffect(() => {
    const cb = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', cb)
    window.scroll(0, 0)

    return () => window.removeEventListener('resize', cb)
  }, [])

  useEffect(() => {
    if (userCode && !logOut) {
      navigate('/lk')
    }
  }, [])

  const onSubmit = ({ number }) => {
    setLoading(true)
    dispatch(setAddPhoneNumber(number || phoneNumberFromUrl))
    localStorage.setItem('phoneNumber', JSON.stringify(number || phoneNumberFromUrl))

    const authLk = async (number) => {
      try {
        const { data } = await getAuthLk(number || phoneNumberFromUrl)
        dispatch(setAddAuth(true))
        setLoading(false)
        navigate('/lk')
        localStorage.setItem('auth', JSON.stringify(true))
      } catch (e) {
        setLoading(false)
        console.log(e)
      }
    }

    authLk(number)
  }

  const handleOpenModal = () => {
    setOpenModal(true)
  }

  return (
    <div className={s.enter}>
      {openModal && <ModalHH setOpenModal={setOpenModal} />}
      <Helmet>
        <title>Личный кабинет партнера</title>
      </Helmet>
      {!loading ? (
        <div className={s.inner}>
          <div className={s.item_logo}>
            <div className={s.logo}>
              <Link to="/worker" target="_blank">
                <img src="/static/lk/logo-skilla.svg" alt="Логотип" />
              </Link>
            </div>
            {/* {width > 575 && (
							<div className={s.rectangle}>
								<img src='/static/lk/rectangle.png' alt='' />
							</div>
						)} */}
          </div>

          <h1 className={s.title}>Личный кабинет</h1>
          <p className={`${s.text} ${s.text_2}`}>управляющего партнера</p>
          <p className={s.text}>Работай с самозанятыми {/* <br></br> */}в своем городе</p>

          <div className={`${s.list}`}>
            <ul>
              <li>
                <IconCheck />
                <p>
                  Аккредитация{' '}
                  <a target="_blank" href="https://reestr.digital.gov.ru/reestr/1284748/">
                    МИНЦИФРЫ РФ
                  </a>
                </p>
              </li>
              <li>
                <IconCheck />
                <p>
                  Привлекательный работодатель <a onClick={handleOpenModal}>HH.ru 2023</a>
                </p>
              </li>
              <li>
                <IconCheck />
                <p>
                  300 тыс. скачиваний в{' '}
                  <a
                    target="_blank"
                    href="https://apps.apple.com/ru/app/skilla-%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%B0/id1531442328/"
                  >
                    App Store
                  </a>{' '}
                  и{' '}
                  <a target="_blank" href="https://play.google.com/store/apps/details?id=com.skilla.Skilla">
                    Google Play
                  </a>
                </p>
              </li>
              <li>
                <IconCheck />
                <p>
                  Оператор №62 по выплатам самозанятым{' '}
                  <a target="_blank" href="https://npd.nalog.ru/aggregators/">
                    Налоговой РФ
                  </a>
                </p>
              </li>
              <li>
                <IconCheck />
                <p>
                  Поставщик{' '}
                  <a target="_blank" href="https://zachestnyibiznes.ru/company/ul/1224700011733_4706050219/">
                    российского ПО на 100 млн
                  </a>{' '}
                  за 2023
                </p>
              </li>
              {/* <li><IconCheck /><p>При поддержке национальныепроекты.рф</p></li>
							<li><IconCheck /><p>География сервиса — РФ</p></li> */}
            </ul>
          </div>

          <form className={s.form} onSubmit={handleSubmit(onSubmit)}>
            <ReactInputMask
              className={cn(s.input, {
                [s.input_error]: errors.number,
              })}
              {...register('number')}
              name="number"
              mask="+7 (999) 999-99-99"
              maskChar=""
              alwaysShowMask={false}
              placeholder="+7 (___) ___-__-__"
              autoComplete="off"
              value={hasPhoneNumber || phoneNumberFromUrl}
            />

            <div className={s.wrapper_btn}>
              <button>
                {width > 575 ? 'Войти в личный кабинет' : 'Войти в кабинет'}
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z" fill="white" />
                </svg>
              </button>
            </div>
          </form>

          {/* <p className={s.descr}>Skilla отправит СМС с кодом</p> */}

          {/* {width <= 575 && (
						<div className={s.rectangle}>
							<img src='/static/lk/rectangle.png' alt='' />
						</div>
					)} */}
          <p className={s.descrlink}>
            Отправляя форму, вы соглашаетесь с{' '}
            <a target="_blank" href=" https://skilla.ru/politics">
              условиями
            </a>
          </p>

          <Link className={s.link} to="/business">
            Skilla.ru
          </Link>
          <span className={s.years}>© 2013-{currentYear} </span>
        </div>
      ) : (
        <img className={s.loading} src="/static/loading-page/business.gif" alt="Загрузка..." />
      )}
    </div>
  )
}

export default Enter
