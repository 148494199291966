import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLKAuth } from '../../../store/reducer/lk/authChech/selector.js';
import { selectLKContract } from '../../../store/reducer/lk/contract/selector.js';
import { setIsCheckFromPage } from '../../../store/reducer/lk/contract/slice.js';
import { selectLKPayment } from '../../../store/reducer/lk/payment/selector.js';
import s from './book.module.scss';
import Buy from './components/buy/Buy';
import Comments from './components/comments/Comments';
import Cover from './components/cover/Cover';
import Modal from './components/modal/Modal';

const Book = memo(({ endDatePremium }) => {
	const [width, setWidth] = useState(window?.innerWidth);
	const { persent } = useSelector(selectLKContract);

	const dispatch = useDispatch();

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.scroll(0, 0);
		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

	const { paymentInfo } = useSelector(selectLKPayment);
	const statusPayment = paymentInfo?.payment?.status;

	const toGoVipPage = () => {
		dispatch(setIsCheckFromPage(true));
		localStorage.setItem('isCheckFromPage', JSON.stringify(true));
		window.open('/premium', '_blank');
	};

	return (
		<>
			{statusPayment &&
				persent &&
				statusPayment !== 'prepaid' &&
				statusPayment !== 'paid' &&
				persent <= 4 &&
				endDatePremium &&
				(width > 575 ? (
					<header onClick={toGoVipPage} className={s.header}>
						<img src='/static/lk/background.png' alt='Баннер' />

						<div className={s.header_btn}>Узнать больше</div>
					</header>
				) : (
					<header className={s.header}>
						<div onClick={toGoVipPage}>
							<img
								style={{ width: '100%' }}
								src='/static/lk/background-mobile.png'
								alt='Баннер'
							/>
						</div>
					</header>
				))}
			<div className={s.book}>
				<Modal />
				<div className={s.top}>
					{width > 575 && <Cover />}
					<Buy />
				</div>

				<Comments />
			</div>
		</>
	);
});
export default Book;
