import s from "../steps.module.scss";
import cn from "classnames";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  setAddStep,
  setAddData,
} from "../../../../../store/reducer/lk/form/slice";
import { selectLkForm } from "../../../../../store/reducer/lk/form/selector";

import Form from "../../Form";
import Button from "../../Button/Button";

const Step5 = () => {
  const [width, setWidth] = useState(window?.innerWidth);
  const dispatch = useDispatch();
  const { data, step } = useSelector(selectLkForm);

  useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

  useEffect(() => {
    localStorage.setItem("stepLk", JSON.stringify(step));
    localStorage.setItem("data", JSON.stringify(data));
  }, []);

  const schema = yup
    .object({
      ["Личные средства млн руб на текущий момент"]: yup.string().required(),

      ["Кредитные млн руб"]: yup.string().required(),

      ["Деньги инвестора млн руб"]: yup.string().required(),

      ["Какую сумму средств, в случае непредвиденных расходов, вы дополнительно готовы вложить в данный бизнес млн руб ?"]:
        yup.string().required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ["Личные средства млн руб на текущий момент"]:
        data["Личные средства млн руб на текущий момент"],

      ["Кредитные млн руб"]: data["Кредитные млн руб"],

      ["Деньги инвестора млн руб"]: data["Деньги инвестора млн руб"],

      ["Какую сумму средств, в случае непредвиденных расходов, вы дополнительно готовы вложить в данный бизнес млн руб ?"]:
        data[
          "Какую сумму средств, в случае непредвиденных расходов, вы дополнительно готовы вложить в данный бизнес млн руб ?"
        ],
    },
  });

  const onSubmit = (data) => {
    dispatch(setAddStep(6));
    dispatch(setAddData(data));

    localStorage.setItem("data", JSON.stringify(data));
    localStorage.setItem("stepLk", JSON.stringify(6));
  };

  return (
    <div className={s.step5}>
      <h1 className={s.title_step5}>Структура инвестиций в проект</h1>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={s.form_item}>
          <h4 className={s.form_title}>
            Личные средства (млн. руб) на текущий момент
          </h4>

          <input
            className={cn(s.input_text, {
              [s.error]: errors["Личные средства млн руб на текущий момент"],
            })}
            {...register("Личные средства млн руб на текущий момент")}
            name="Личные средства млн руб на текущий момент"
            placeholder="Развернутый ответ увеличит вероятность одобрения кандидата на партнерство"
            maxLength="50"
            autoComplete="off"
          />
        </div>

        <div className={s.form_item}>
          <h4 className={s.form_title}>Кредитные (млн. руб)</h4>

          <input
            className={cn(s.input_text, {
              [s.error]: errors["Кредитные млн руб"],
            })}
            {...register("Кредитные млн руб")}
            name="Кредитные млн руб"
            placeholder="Развернутый ответ увеличит вероятность одобрения кандидата на партнерство"
            maxLength="50"
            autoComplete="off"
          />
        </div>

        <div className={s.form_item}>
          <h4 className={s.form_title}>Деньги инвестора (млн. руб)</h4>

          <input
            className={cn(s.input_text, {
              [s.error]: errors["Деньги инвестора млн руб"],
            })}
            {...register("Деньги инвестора млн руб")}
            name="Деньги инвестора млн руб"
            placeholder="Развернутый ответ увеличит вероятность одобрения кандидата на партнерство"
            maxLength="50"
            autoComplete="off"
          />
        </div>

        <div className={s.form_item}>
          <h4 className={s.form_title}>
            Какую сумму средств, в случае непредвиденных расходов, вы
            дополнительно готовы вложить в данный бизнес (млн руб)?
          </h4>

          <input
            className={cn(s.input_text, {
              [s.error]:
                errors[
                  "Какую сумму средств, в случае непредвиденных расходов, вы дополнительно готовы вложить в данный бизнес млн руб ?"
                ],
            })}
            {...register(
              "Какую сумму средств, в случае непредвиденных расходов, вы дополнительно готовы вложить в данный бизнес млн руб ?"
            )}
            name="Какую сумму средств, в случае непредвиденных расходов, вы дополнительно готовы вложить в данный бизнес млн руб ?"
            placeholder="Развернутый ответ увеличит вероятность одобрения кандидата на партнерство"
            maxLength="50"
            autoComplete="off"
          />
        </div>

      
        <div style={{ marginTop: "88px" }} className={s.btns}>
          <Button callback={() => dispatch(setAddStep(4))} back>
           {width > 575 ? ' Назад' :  <img src='/static/lk/IconBackward.svg' alt='назад' />}
          </Button>
          <Button>Далее 5 из 8</Button>
        </div>
      </Form>

      <img
        className={s.illustration}
        src="/static/lk/illustration/illustration.png"
        alt="Иллюстрация"
      />
    </div>
  );
};

export default Step5;
