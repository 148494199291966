import cn from 'classnames'
import s from '../steps.module.scss'

import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'
import { setAddData, setAddStep } from '../../../../../store/reducer/lk/formExam/slice'

import { selectLkFormExam } from '../../../../../store/reducer/lk/formExam/selector'
import Button from '../../Button/Button'
import Form from '../../Form'

const Step1 = () => {
  const [width, setWidth] = useState(window?.innerWidth)
  const dispatch = useDispatch()
  const { data, step } = useSelector(selectLkFormExam)
  const [isError2, setError2] = useState(false)
  const [isError3, setError3] = useState(false)
  const [isSendForm, setIsSendForm] = useState(false)

  useEffect(() => {
    localStorage.setItem('stepLkExam', JSON.stringify(step))
    localStorage.setItem('dataExam', JSON.stringify(data))
  }, [])
  console.log(data)

  useEffect(() => {
    const cb = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', cb)

    return () => window.removeEventListener('resize', cb)
  }, [])

  const schema1 = yup
    .object({
      ['Какой тип деятельности предполагает наша бизнес-модель?']: yup.string(),
    })
    .required()

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm({
    resolver: yupResolver(schema1),
    defaultValues: {
      ['Какой тип деятельности предполагает наша бизнес-модель?']:
        data['Какой тип деятельности предполагает наша бизнес-модель?'],

      ['Экосистема Скилла представляет собой:']: data['Экосистема Скилла представляет собой:'],
      ['Какие две основные линии предполагает наша бизнес-модель?']:
        data['Какие две основные линии предполагает наша бизнес-модель?'],
    },
  })

  const ckbox2 = watch('Экосистема Скилла представляет собой:')
  const ckbox3 = watch('Какие две основные линии предполагает наша бизнес-модель?')

  useEffect(() => {
    if (!ckbox2?.length) {
      setError2(true)
    } else {
      setError2(false)
    }
  }, [ckbox2])

  useEffect(() => {
    if (!ckbox3?.length) {
      setError3(true)
    } else {
      setError3(false)
    }
  }, [ckbox3])

  const onSubmit = (data) => {
    setIsSendForm(true)

    if (!isError2 && !isError3) {
      dispatch(setAddStep(2))
      dispatch(setAddData(data))
      setIsSendForm(false)

      localStorage.setItem('dataExam', JSON.stringify(data))
      localStorage.setItem('stepLkExam', JSON.stringify(1))
    }
  }

  return (
    <div className={s.step1}>
      <div className={s.wrapper_heading}>
        <h5>Скилла. Бизнес-модель</h5>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={s.form_item}>
          <h4 className={s.form_title}>1. Какой тип деятельности предполагает наша бизнес-модель?</h4>

          <div className={s.question1}>
            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question1,
                })}
                {...register('Какой тип деятельности предполагает наша бизнес-модель?')}
                id="exam11"
                type="radio"
                name="Какой тип деятельности предполагает наша бизнес-модель?"
                autoComplete="off"
                value="Аутсорсинг"
              />

              <label htmlFor="exam11" className={s.label}>
                Аутсорсинг
              </label>
            </div>

            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question1,
                })}
                {...register('Какой тип деятельности предполагает наша бизнес-модель?')}
                id="exam12"
                type="radio"
                name="Какой тип деятельности предполагает наша бизнес-модель?"
                autoComplete="off"
                value="Аустаффинг"
              />
              <label htmlFor="exam12" className={s.label}>
                Аустаффинг
              </label>
            </div>
          </div>

          <p className={s.errorText}>
            {errors['Какой тип деятельности предполагает наша бизнес-модель?'] ? 'Необходимо указать' : ''}
          </p>
        </div>

        <div className={s.form_item}>
          <h4 className={s.form_title}>2. Экосистема Скилла представляет собой:</h4>

          <div className={s.question2}>
            <div className={s.checkbox}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question2,
                })}
                {...register('Экосистема Скилла представляет собой:')}
                id="exam21"
                type="checkbox"
                name="Экосистема Скилла представляет собой:"
                autoComplete="off"
                value="Skilla IS (CRM - система)"
              />
              <label htmlFor="exam21" className={s.label}>
                Skilla IS (CRM - система)
              </label>
            </div>

            <div className={s.checkbox}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question2,
                })}
                {...register('Экосистема Скилла представляет собой:')}
                id="exam22"
                type="checkbox"
                name="Экосистема Скилла представляет собой:"
                autoComplete="off"
                value="Приложение Скилла.Работа"
              />
              <label htmlFor="exam22" className={s.label}>
                Приложение Скилла.Работа
              </label>
            </div>

            <div className={s.checkbox}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question2,
                })}
                {...register('Экосистема Скилла представляет собой:')}
                id="exam23"
                type="checkbox"
                name="Экосистема Скилла представляет собой:"
                autoComplete="off"
                value="Контактный центр"
              />
              <label htmlFor="exam23" className={s.label}>
                Контактный центр
              </label>
            </div>

            <div className={s.checkbox}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question2,
                })}
                {...register('Экосистема Скилла представляет собой:')}
                id="exam24"
                type="checkbox"
                name="Экосистема Скилла представляет собой:"
                autoComplete="off"
                value="Программное обеспечение на компьютер"
              />
              <label htmlFor="exam24" className={s.label}>
                Программное обеспечение на компьютер
              </label>
            </div>

            <div className={s.checkbox}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question2,
                })}
                {...register('Экосистема Скилла представляет собой:')}
                id="exam25"
                type="checkbox"
                name="Экосистема Скилла представляет собой:"
                autoComplete="off"
                value="Служба психологической поддержки"
              />
              <label htmlFor="exam25" className={s.label}>
                Служба психологической поддержки
              </label>
            </div>

            <p className={s.errorText2}>{isError2 && isSendForm && 'Необходимо указать'}</p>
          </div>
        </div>

        <div className={s.form_item}>
          <h4 className={s.form_title}>3. Какие две основные линии предполагает наша бизнес-модель?</h4>

          <div className={s.question3}>
            <div className={s.checkbox}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question3,
                })}
                {...register('Какие две основные линии предполагает наша бизнес-модель?')}
                id="exam31"
                type="checkbox"
                name="Какие две основные линии предполагает наша бизнес-модель?"
                autoComplete="off"
                value="Клиенты"
              />
              <label htmlFor="exam31" className={s.label}>
                Клиенты
              </label>
            </div>

            <div className={s.checkbox}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question3,
                })}
                {...register('Какие две основные линии предполагает наша бизнес-модель?')}
                id="exam32"
                type="checkbox"
                name="Какие две основные линии предполагает наша бизнес-модель?"
                autoComplete="off"
                value="Исполнители"
              />
              <label htmlFor="exam32" className={s.label}>
                Исполнители
              </label>
            </div>

            <div className={s.checkbox}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question3,
                })}
                {...register('Какие две основные линии предполагает наша бизнес-модель?')}
                id="exam33"
                type="checkbox"
                name="Какие две основные линии предполагает наша бизнес-модель?"
                autoComplete="off"
                value="Офисные сотрудники"
              />
              <label htmlFor="exam33" className={s.label}>
                Офисные сотрудники
              </label>
            </div>

            <div className={s.checkbox}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question3,
                })}
                {...register('Какие две основные линии предполагает наша бизнес-модель?')}
                id="exam34"
                type="checkbox"
                name="Какие две основные линии предполагает наша бизнес-модель?"
                autoComplete="off"
                value="Поставщики"
              />
              <label htmlFor="exam34" className={s.label}>
                Поставщики
              </label>
            </div>

            <p className={s.errorText2}>{isError3 && isSendForm && 'Необходимо указать'}</p>
          </div>
        </div>

        <div className={s.btns}>
          <Button>Далее 1 из 35</Button>
        </div>
      </Form>

      <img className={s.illustration} src="/static/lk/illustration/illustration.png" alt="Иллюстрация" />
    </div>
  )
}

export default Step1
