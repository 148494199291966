import s from './Documents.module.scss';
import { useState, useRef, useEffect } from 'react';
import File from './File';
import { nanoid } from 'nanoid';

function Documents({setButtonActive}) {
    const [files, setFiles] = useState([]);
    const [error, setError] = useState(false);
    const [drag, setDrag] = useState(false);
    const [err, setErr] = useState('');
    const fileInputRef = useRef()
   console.log(files)

   useEffect(() => {
    if(files.length === 0) {
        setButtonActive(false)
    } else {
        setButtonActive(true)
    }
    
   }, [files])

    function getBase64(file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
        return reader.result;
    }



    const handleFile = async (e) => {
        const filess = e.currentTarget.files;

        if (filess.size < 20000000) {
            setErr('Размер файла превышает 20 Мбайт')
            setError(true)
        }
        else {
            /* const fileBase = getBase64(file); */
            setError(false);
            setFiles([...files, ...filess]);
            setErr('')

            fileInputRef.current && (fileInputRef.current.value = '');
        }
    }

    const handleDrop = (e) => {
        e.stopPropagation();
        e.preventDefault();

        console.log("File(s) dropped");
        
        const filesArr = Array.from(e.dataTransfer.files);
        console.log(filesArr)
        /* const file = e.dataTransfer.files[0] */
            if (files.length + filesArr.length > 3) {
                setErr('Загрузить можно не больше 3 файлов');
                setDrag(false);
                return
            }
       
            if (filesArr?.every((file) => file.size < 20000000 && file.type === "image/png" || file.type === "image/gif" || file.type === "image/jpg" || file.type === "image/jpeg" || file.type === "application/pdf")) {
                const uniqSet = [...new Set([...files, ...filesArr])]
                console.log(uniqSet)
                setFiles(uniqSet);
              
                setErr('');
            } else {
                setErr('Размер файла превышает 20 Мбайт')
                setFiles(files)
            };
        
       
        setDrag(false);
    }

    function handleDragOver(e) {
        e.stopPropagation();
        e.preventDefault();
        setDrag(true)
    }

    function handleDragLeave(e) {
        e.stopPropagation();
        e.preventDefault();
        setDrag(false)
    }

    


    return (
        <div className={s.documents}>
            <p className={s.title}>Документы</p>
            <p className={s.subtitle}>Для налоговой службы нужны следующие страницы:</p>
            <div className={s.list}>
                <div className={s.item}>
                    <div><img src="/static/lk/entity/iconDone.png"></img></div>
                    <p>Основной разворот паспорта с фото (стр. 2-3)</p>
                </div>

                <div className={s.item}>
                    <div><img src="/static/lk/entity/iconDone.png"></img></div>
                    <p>Разворот паспорта регистрацией (стр. 4-5)</p>
                </div>

                <div className={s.item}>
                    <div><img src="/static/lk/entity/iconDone.png"></img></div>
                    <p>Временная регистрация, если регистрации в паспорте нет или она недействительна</p>
                </div>
            </div>
            <p style={{ fontSize: '14px' }} className={s.subtitle}>Прикрепите необходимые документы в хорошем качестве, без бликов и посторонних предметов. Проверьте, что страницы видны полностью, текст хорошо читается.</p>
            <div onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop} className={`${s.load} ${drag && s.load_drag}`}>
                <input ref={fileInputRef} id="image-input" type='file' accept=".png,.jpg,.jpeg,.gif" onInput={handleFile}></input>
                {files.length >= 3 && <p>Загруженно максимальное кол-во файлов</p>}
                {files.length < 3 && <p>Перетащите или <label for="image-input">загрузите файлы</label></p>}
                {files.length < 3 && <p className={s.sub}>Файлы объемом до 20 Мбайт в форматах PDF, PNG, JPG, JPEG</p>}
                {files.length >= 3 && <p className={s.sub}>Удалите файлы что бы загрузить новые</p>}
                <div className={`${s.download} ${drag && s.download_drag}`}>
                    {files.length == 3 && <p>Загруженно максимальное кол-во файлов</p>}
                </div>
                <div className={`${s.files} ${ files.length < 1 && s.files_hiden}`}>
                    {files.length > 0 && files.map((el, index) => {
                        return <File key={el.lastModified + el.name.length} index={index} el={el} setFiles={setFiles} files={files} setErr={setErr}/>
                    })}
                </div>
                <p className={s.err}>{err}</p>
            </div>
            
        </div>
    )
};

export default Documents;