import styled from 'styled-components'
import { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setAddTextarea,
  setAddDoNotUnsubscribe,
  setAddUnsubscribe,
} from '../store/reducer/formUnsubscribe/slice'
import { useForm } from 'react-hook-form'
import { sendFormUnsubscribe } from '../Api'
import { selectMain } from '../store/reducer/main/selector'

const FormUnsubscribe = memo(function FormUnsubscribe({ textarea }) {
  const { eid } = useSelector(selectMain)
  const dispatch = useDispatch()

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  const onSubmit = ({ value, message }) => {
    dispatch(setAddUnsubscribe(false))

    sendFormUnsubscribe(eid.eid, value, message)

    dispatch(setAddUnsubscribe(true))
    dispatch(setAddTextarea(false))
    reset()
  }

  const doNotUnsubscribe = (e, bool) => {
    e.preventDefault()
    dispatch(setAddDoNotUnsubscribe(bool))
    dispatch(setAddTextarea(false))
  }

  const inputs = [
    {
      reg: 'value',
      label: 'Слишком часто приходят письма',
      id: 'radio-1',
      type: 'radio',
      callback: false,
    },

    { reg: 'value', label: 'Не интересно', id: 'radio-2', type: 'radio', callback: false },

    {
      reg: 'value',
      label: 'Я уже стал партнером Скилла',
      id: 'radio-3',
      type: 'radio',
      callback: false,
    },

    { reg: 'value', label: 'Другое', id: 'radio-4', type: 'radio', callback: true },
  ]

  return (
    <StyledFormUnsubscribe>
      <form onSubmit={handleSubmit(onSubmit)} className="form">
        <div className="form-unsubscribe">
          {inputs.map((item) => {
            return (
              <div key={item.label} className="radio">
                <input
                  onClick={() => dispatch(setAddTextarea(item.callback))}
                  className="radio__input"
                  id={item.id}
                  type={item.type}
                  value={item.label}
                  {...register(item.reg, { required: true })}
                />

                <label className="radio__label" htmlFor={item.id}>
                  {item.label}
                </label>
              </div>
            )
          })}

          {textarea ? (
            <textarea
              className="textarea"
              placeholder="Ваш вариант"
              {...register('message', { required: true })}
            ></textarea>
          ) : null}
          {errors.message?.type === 'required' && textarea && (
            <div className="error-textarea" style={{ color: 'red' }}>
              Пожалуйста, введите сообщение
            </div>
          )}

          <button onClick={(e) => doNotUnsubscribe(e, true)} className="button">
            Не отписываться
            <span className="arrow-right-business icon"></span>
          </button>

          {errors.value?.type === 'required' && (
            <div style={{ color: 'red', fontSize: '14px', marginTop: '10px' }}>Пожалуйста, укажите ответ</div>
          )}
        </div>

        <button className={textarea ? 'button-unsubscribe top' : 'button-unsubscribe'}>
          <span>Все-таки отписаться</span>
          <svg width="12" height="12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M6.34315 6.3424V8.33644L14.2415 8.34351L5.63604 16.949L7.05025 18.3632L15.6557 9.75773L15.6628 17.6561H17.6569V6.3424H6.34315Z"
              fill="#fff"
            />
          </svg>
        </button>
      </form>
    </StyledFormUnsubscribe>
  )
})

const StyledFormUnsubscribe = styled.div`
  .form-unsubscribe {
    position: relative;
    z-index: 14;
    max-width: 451px;
    background: #303550;
    border-radius: 12px;
    padding: 24px 48px 24px 24px;

    @media (max-width: 1600px) {
      max-width: 360px;
    }

    @media (max-width: 768px) {
      max-width: initial;
    }

    @media (max-width: 768px) {
      width: 100%;
      padding: 16px;
    }
  }

  .radio {
    position: relative;
    margin-bottom: 20px;
    &__input {
      appearance: none;
      position: absolute;
    }

    &__label {
      padding-left: 28px;
      font-weight: 500;
      font-size: 22px;
      color: #fff;
      cursor: pointer;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        z-index: 1;
        display: block;
        width: 16px;
        height: 16px;
        border: 1px solid #fff;
        border-radius: 50%;
        background-color: transparent;
        transform: translateY(-50%);

        transition: border 0.1s linear;
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 5px;
        z-index: 2;
        display: block;
        width: 6px;
        height: 6px;
        background: #00ff88;
        border-radius: 50%;

        transform: translateY(-50%);
        transition: opacity 0.1s linear;
        opacity: 0;
      }

      @media (max-width: 1600px) {
        font-weight: 500;
        font-size: 15px;
      }

      @media (max-width: 768px) {
        font-weight: 500;
        font-size: 17px;
        line-height: 120%;
      }
    }
  }

  .radio__input:checked + .radio__label:before {
    border: 1px solid #00ff88;
  }

  .radio__input:checked + .radio__label:after {
    opacity: 1;
  }

  .button {
    display: inline-block;
    margin-left: -4px;
    display: flex;
    align-items: center;
    padding: 13.5px 29px;
    font-size: 18px;
    line-height: 120%;
    font-weight: 600;
    cursor: pointer;
    background: #00ff88;
    border-radius: 4px;
    border: 0;
    transition: background-color 0.3s ease;
    color: #000;
    margin-top: 40px;

    & span {
      margin-left: 12px;
    }

    &:hover {
      background-color: #00cc6e;
    }

    @media (max-width: 768px) {
      width: 100%;
      justify-content: center;
    }
  }

  .textarea {
    resize: none;
    padding: 12px;
    width: 100%;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    outline: none;
    background: rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    min-height: 105px;
    color: #fff;
    font-family: inherit;

    &::placeholder {
      color: #abadba;
      font-family: inherit;

      @media (max-width: 768px) {
        font-weight: 500;
        font-size: 16px;
      }
    }

    @media (max-width: 768px) {
      font-weight: 500;
      font-size: 16px;
    }
  }

  .error-textarea {
    @media (max-width: 768px) {
      margin-top: 10px;
    }
  }

  .button-unsubscribe {
    position: relative;
    z-index: 14;
    font-weight: 600;
    font-size: 14px;
    color: #fff;
    margin-top: 16px;
    cursor: pointer;
    span {
      border-bottom: 1px solid #fff;
    }

    svg {
      margin-left: 8px;
      cursor: pointer;
    }

    @media (max-width: 768px) {
      margin-left: 10px;
    }
  }
`

export default FormUnsubscribe
