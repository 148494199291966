import s from './done.module.scss';

function IncorrectAnswer({question, answers, correctAnswers}) {
    return (
<div className={s.incorrect}>
						<h4>{question}</h4>
						<p className={`${s.answer}`}>Ваш ответ:</p>
						<div>
							{answers.map(item => {
								 return <div className={s.text}><span className={s.point}></span><p>{item}</p></div>
							})}
						</div>
						<p className={`${s.answer} ${s.answer_correct}`}>Правильный ответ:</p>
						<div>
							{correctAnswers.map(item => {
								return <div className={`${s.text} ${s.text_correct}`}><span className={s.point}></span><p>{item}</p></div>
							})}
						</div>
					</div>
    )
};

export default IncorrectAnswer;