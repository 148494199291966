import cn from 'classnames';
import { useForm } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import { getLkReqTraining } from '../../../../../Api';
import { selectLKAuth } from '../../../../../store/reducer/lk/authChech/selector';
import { selectLkTraining } from '../../../../../store/reducer/lk/training/selector';
import {
	setAddCheckedDateInput,
	setAddModal,
	setAddSendData
} from '../../../../../store/reducer/lk/training/slice.js';

import s from '../../training.module.scss';

const Reservation = () => {
	const dispatch = useDispatch();
	const { user } = useSelector(selectLKAuth);
	const { placePicker, dateForApi, checkedDateInput } = useSelector(selectLkTraining);

	const phoneNumber =
		user?.user?.phones[0]?.phone || user?.user?.phones[1]?.phone;

	const {
		handleSubmit,
		register,
		formState: { errors },
	} = useForm({});

	const onSubmit = () => {
		if (checkedDateInput) document.body.classList.add('overlay');

		dispatch(setAddSendData(true))
		if (!dateForApi) {
			dispatch(setAddCheckedDateInput(false));
		} else {
			dispatch(setAddCheckedDateInput(true));
		}

		const getСontractSign = async () => {
			try {
				const { data } = await getLkReqTraining(
					user?.user?.code,
					phoneNumber,
					placePicker,
					dateForApi
				);

				dispatch(setAddModal(true));
			} catch (e) {
				console.log(e);
			}
		};

		if (dateForApi) {
			getСontractSign();
		}
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} className={s.reservation}>
			<div className={s.checkbox}>
				<input
					className={s.input}
					{...register('checkbox', {
						required: true,
					})}
					id='checkbox'
					type='checkbox'
					name='checkbox'
					autoComplete='off'
				/>
				<label
					htmlFor='checkbox'
					className={cn(s.label, {
						[s.error]: errors.checkbox,
					})}
				>
					Я принимаю{' '}
					<a href='https://skilla.ru/agreement.html' target='_blank'>
						Соглашение
					</a>{' '}
					о подписании документов с использованием СМС (простая электронная
					подпись)
				</label>
			</div>

			<div className={s.consent_right}>
				<div className={s.item_number}>
					<div className={s.wrapperInput}>
						<ReactInputMask
							className={cn(s.inputNumber, {
								[s.input_error]: errors.number,
							})}
							{...register('number')}
							disabled
							name='number'
							mask='+7 (\999) 999-99-99'
							maskChar=''
							alwaysShowMask={false}
							placeholder={user?.user?.phones[0].phone}
							defaultValue={user?.user?.phones[0].phone}
							autoComplete='off'
						/>
					</div>
				</div>

				<button className={s.btn}>Забронировать места</button>
			</div>
		</form>
	);
};

export default Reservation;
