import React from 'react'
// import { hydrate, render } from 'react-dom'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { store } from './store'

import App from './App'

import './styles/vendor/icons.scss'
import './styles/index.scss'
import { HelmetProvider } from 'react-helmet-async'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <HelmetProvider>
    <Provider store={store}>
      <App />
    </Provider>
  </HelmetProvider>,
)
/*const rootElement = document.getElementById('root')
if (rootElement.hasChildNodes()) {
  hydrate(
    <HelmetProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </HelmetProvider>,
    rootElement,
  )
} else {
  render(
    <HelmetProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </HelmetProvider>,
    rootElement,
  )
}*/
