import { useEffect, useState, memo } from 'react';
import { useParams } from 'react-router-dom';
import { getArticlePartners } from '../../Api';
import styled from 'styled-components';

import './articleBtns.scss';

const ArticleBtns = memo(function ArticleBtns({ type, keys, page, categories }) {
  const { alias } = useParams();
  const [data, setData] = useState([]);

  useEffect(() => {
    getArticlePartners(keys, alias).then(({ data }) => {
      setData(data.article.services);
    });
  }, [alias]);

  if (type === 'header')
    return (
      <div className="articleBtns">
        <div className="articleBtns__items">
          {data &&
            data.map((item) => (
              <span>
                {' '}
                <a key={item.id} href={`/${item.alias}`} className="articleBtns__item-header">
                  {item.service_name}
                </a>
              </span>
            ))}
        </div>
      </div>
    );

  if (type === 'footer') {
    return (
      <div className="articleBtns">
        <div className="articleBtns__items articleBtns__items-footer">
          {data &&
            data.map((item) => (
              <a key={item.id} href={`/${item.alias}`} className="articleBtns__item-footer">
                {item.service_name}
              </a>
            ))}
        </div>
      </div>
    );
  }

  if (page === 'customerCases')
    return (
      <StyledForCustomerCases>
        <div className="tabs">
          <div className="tabs__customerCases">
            {categories &&
              categories.map((item) => (
                <div key={item.id} className="tabs__customerCases-tab">
                  {item.name}
                </div>
              ))}
          </div>
        </div>
      </StyledForCustomerCases>
    );
});

const StyledForCustomerCases = styled.div`
  .tabs {
    max-width: 1097px;
    width: 100%;

    &__customerCases {
      gap: 8px;
      display: flex;
      flex-wrap: wrap;

      &-tab {
        font-weight: 600;
        font-size: 14px;
        line-height: 32px;
        padding: 0 0.65em;
        border: 1px solid #e3e4e8;
        border-radius: 4px;
        color: #abadba;
      }
    }
  }
`;

export default ArticleBtns;
