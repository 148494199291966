import cn from 'classnames';
import s from '../steps.module.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import {
	setAddData,
	setAddStep,
} from '../../../../../store/reducer/lk/formExam/slice';

import { selectLkFormExam } from '../../../../../store/reducer/lk/formExam/selector';
import Button from '../../Button/Button';
import Form from '../../Form';

const Step15 = () => {
	const [width, setWidth] = useState(window?.innerWidth);
	const dispatch = useDispatch();
	const { data, step } = useSelector(selectLkFormExam);
	const [isSendForm, setIsSendForm] = useState(false);

	useEffect(() => {
		localStorage.setItem('stepLkExam', JSON.stringify(step));
		localStorage.setItem('dataExam', JSON.stringify(data));
	}, []);

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

	const schema41 = yup
		.object({
			['Что такое СЕО продвижение сайта (органическая выдача)?']: yup.string(),
            ['Сколько времени потребуется для выхода в ТОП-10 в органической выдачи?']: yup.string(),
            ['Как приходят отчеты по СЕО продвижению?']: yup.string(),
		})
		.required();

	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
		watch,
	} = useForm({
		resolver: yupResolver(schema41),
		defaultValues: {
			['Что такое СЕО продвижение сайта (органическая выдача)?']: data['Что такое СЕО продвижение сайта (органическая выдача)?'],
			['Сколько времени потребуется для выхода в ТОП-10 в органической выдачи?']: data['Сколько времени потребуется для выхода в ТОП-10 в органической выдачи?'],
			['Как приходят отчеты по СЕО продвижению?']: data['Как приходят отчеты по СЕО продвижению?'],
		},
	});

	const onSubmit = data => {
		dispatch(setAddStep(16));
		dispatch(setAddData(data));
		localStorage.setItem('dataExam', JSON.stringify(data));
		localStorage.setItem('stepLkExam', JSON.stringify(15));
		
	};

	return (
		<div className={s.step15}>
      <div className={s.wrapper_heading}>
			  <h5>Реклама</h5>
			</div>
			<Form onSubmit={handleSubmit(onSubmit)}>

				<div className={s.form_item}>
					<h4 className={s.form_title}>41. Что такое СЕО продвижение сайта (органическая выдача)?</h4>

					<div className={s.question41}>
						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question41,
								})}
								{...register('Что такое СЕО продвижение сайта (органическая выдача)?')}
								id='exam411'
								type='radio'
								name='Что такое СЕО продвижение сайта (органическая выдача)?'
								autoComplete='off'
								value='Платные объявления Яндекс со значком "реклама"'
							/>

							<label htmlFor='exam411' className={s.label}>
							Платные объявления Яндекс со значком "реклама"
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question41,
								})}
								{...register('Что такое СЕО продвижение сайта (органическая выдача)?')}
								id='exam412'
								type='radio'
								name='Что такое СЕО продвижение сайта (органическая выдача)?'
								autoComplete='off'
								value='Продвижение сайта для его поднятия на первые позиции в поисковой выдаче'
							/>

							<label htmlFor='exam412' className={s.label}>
							Продвижение сайта для его поднятия на первые позиции в поисковой выдаче
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question41,
								})}
								{...register('Что такое СЕО продвижение сайта (органическая выдача)?')}
								id='exam413'
								type='radio'
								name='Что такое СЕО продвижение сайта (органическая выдача)?'
								autoComplete='off'
								value='Всплывающая реклама, которая везде преследует клиента'
							/>

							<label htmlFor='exam413' className={s.label}>
							Всплывающая реклама, которая везде преследует клиента
							</label>
						</div>
					</div>

					<p className={s.errorText}>
						{errors['Что такое СЕО продвижение сайта (органическая выдача)?'] ? 'Необходимо указать' : ''}
					</p>
				</div>

				<div className={s.form_item}>
					<h4 className={s.form_title}>42. Сколько времени потребуется для выхода в ТОП-10 в органической выдачи?</h4>

					<div className={s.question42}>
                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question42,
								})}
								{...register('Сколько времени потребуется для выхода в ТОП-10 в органической выдачи?')}
								id='exam421'
								type='radio'
								name='Сколько времени потребуется для выхода в ТОП-10 в органической выдачи?'
								autoComplete='off'
								value='Сразу после оплаты'
							/>
							<label htmlFor='exam421' className={s.label}>
							Сразу после оплаты
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question42,
								})}
								{...register('Сколько времени потребуется для выхода в ТОП-10 в органической выдачи?')}
								id='exam422'
								type='radio'
								name='Сколько времени потребуется для выхода в ТОП-10 в органической выдачи?'
								autoComplete='off'
								value='От 6 до 12 месяцев'
							/>
							<label htmlFor='exam422' className={s.label}>
							От 6 до 12 месяцев
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question42,
								})}
								{...register('Сколько времени потребуется для выхода в ТОП-10 в органической выдачи?')}
								id='exam423'
								type='radio'
								name='Сколько времени потребуется для выхода в ТОП-10 в органической выдачи?'
								autoComplete='off'
								value='5 лет'
							/>
							<label htmlFor='exam423' className={s.label}>
							5 лет
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question42,
								})}
								{...register('Сколько времени потребуется для выхода в ТОП-10 в органической выдачи?')}
								id='exam424'
								type='radio'
								name='Сколько времени потребуется для выхода в ТОП-10 в органической выдачи?'
								autoComplete='off'
								value='От 2 лет'
							/>
							<label htmlFor='exam424' className={s.label}>
							От 2 лет
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question42,
								})}
								{...register('Сколько времени потребуется для выхода в ТОП-10 в органической выдачи?')}
								id='exam425'
								type='radio'
								name='Сколько времени потребуется для выхода в ТОП-10 в органической выдачи?'
								autoComplete='off'
								value='1 неделя'
							/>
							<label htmlFor='exam425' className={s.label}>
							1 неделя
							</label>
						</div>
					</div>

					<p className={s.errorText}>
						{errors['Сколько времени потребуется для выхода в ТОП-10 в органической выдачи?'] ? 'Необходимо указать' : ''}
					</p>
				</div>

                <div className={s.form_item}>
					<h4 className={s.form_title}>43. Как приходят отчеты по СЕО продвижению?</h4>

					<div className={s.question43}>
                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question43,
								})}
								{...register('Как приходят отчеты по СЕО продвижению?')}
								id='exam431'
								type='radio'
								name='Как приходят отчеты по СЕО продвижению?'
								autoComplete='off'
								value='Ежемесячно приходят развернутые отчеты по всем выполненным работам'
							/>
							<label htmlFor='exam431' className={s.label}>
							Ежемесячно приходят развернутые отчеты по всем выполненным работам
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question43,
								})}
								{...register('Как приходят отчеты по СЕО продвижению?')}
								id='exam432'
								type='radio'
								name='Как приходят отчеты по СЕО продвижению?'
								autoComplete='off'
								value='Еженедельно приходят отчеты по позициям от ТОПвизор'
							/>
							<label htmlFor='exam432' className={s.label}>
							Еженедельно приходят отчеты по позициям от ТОПвизор
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question43,
								})}
								{...register('Как приходят отчеты по СЕО продвижению?')}
								id='exam433'
								type='radio'
								name='Как приходят отчеты по СЕО продвижению?'
								autoComplete='off'
								value='Отчетов нет'
							/>
							<label htmlFor='exam433' className={s.label}>
							Отчетов нет
							</label>
						</div>
					</div>

					<p className={s.errorText}>
						{errors['Как приходят отчеты по СЕО продвижению?'] ? 'Необходимо указать' : ''}
					</p>
				</div>

				<div className={s.btns}>
        <Button callback={() => dispatch(setAddStep(14))} back>
						{width > 575 ? (
							'   Назад'
						) : (
							<img src='/static/lk/IconBackward.svg' alt='назад' />
						)}
					</Button>
					<Button>Далее 15 из 35</Button>
				</div>
			</Form>

			<img
				className={s.illustration}
				src='/static/lk/illustration/illustration.png'
				alt='Иллюстрация'
			/>
		</div>
	);
};

export default Step15;
