import styled from 'styled-components'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setAddActiveTarget } from '../store/reducer/main/slice'
import { selectForm } from '../store/reducer/form/selector'

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Form from '../components/Form'

function Diagnostics() {
  const [width, setWidth] = useState(window?.innerWidth)
  const { formSendDiagnostics } = useSelector(selectForm)
  const [video, setVideo] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    const cb = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', cb)
    window.scroll(0, 0)

    return () => window.removeEventListener('resize', cb)
  }, [])
  return (
    <DiagnosticsStyle className="diagnostics">
      <Helmet>
        <title>Проверь, подходит ли тебе бизнес – Скилла</title>
        <meta
          name="description"
          content="Бесплатная диагностика от эксперта. Ты узнаешь, как и сколько ты будешь зарабатывать"
        />
        <meta
          name="keywords"
          content="бизнес, модель, советы, открыть, франшиза, купить, как, консультация, наставник, коуч, мастер, класс, обучение, с, нуля"
        />
      </Helmet>

      {formSendDiagnostics ? <Navbar page="business" type="diagnostics" activePage="diagnostics" /> : ''}

      <section style={{ padding: formSendDiagnostics ? '170px 0 0' : '' }} className="header">
        {width <= 768 && !formSendDiagnostics && (
          <div className="header__icon">
            <Link to="/business">
              <img src="/static/icons/skilla.svg" alt="skilla" />
            </Link>
          </div>
        )}

        <div className="container">
          {formSendDiagnostics && (
            <div style={{ marginTop: formSendDiagnostics ? '0' : '' }} className="header__success">
              <h3>Твоя заявка принята!</h3>
              {width > 768 ? (
                <p>Позвоним и согласуем время для проведения видео-встречи</p>
              ) : (
                <p>Скоро свяжемся с тобой и согласуем время для проведения бесплатной диагностики</p>
              )}
            </div>
          )}

          <div
            style={{ display: width <= 768 && formSendDiagnostics ? 'none' : '' }}
            className="header__inner"
          >
            <div
              style={{ paddingTop: formSendDiagnostics && '0' }}
              className={formSendDiagnostics ? 'header__content success' : 'header__content'}
            >
              {!formSendDiagnostics && width > 768 ? (
                <Link to="/business">
                  <img src="/static/icons/skilla.svg" alt="skilla" />
                </Link>
              ) : (
                ''
              )}

              {!formSendDiagnostics ? (
                <div className="header__block">
                  {width > 768 ? (
                    <h2 className="header__title">
                      <span>Бесплатная</span> диагностика
                    </h2>
                  ) : (
                    <h2 className="header__title">
                      <span>Бесплатная</span> <br></br> диагностика
                    </h2>
                  )}
                  <p className="header__descr">За 30 минут с экспертом ты:</p>

                  <ul className="header__list list">
                    <li className="header__item item-list">
                      <p>Разберешься подходит ли тебе открытие офиса Скилла</p>
                    </li>
                    <li className="header__item item-list">
                      <p>Определишь сколько сможешь зарабатывать конкретно в твоем случае</p>
                    </li>
                    <li className="header__item item-list">
                      <p>Уточнишь точку А и построишь четкий путь к точке Б</p>
                    </li>
                  </ul>
                </div>
              ) : (
                ''
              )}
              <div className="form-block">
                {!formSendDiagnostics && (
                  <div className="form-block__top">
                    <p className="form-block__price">8 500 ₽</p>
                    <p className="form-block__free">Бесплатно</p>
                  </div>
                )}
                {width > 768 && <Form page="diagnostics" type="diagnostics" />}

                {width > 768 && (
                  <p style={{ 'padding-bottom': formSendDiagnostics ? '59px' : '' }} className="header__info">
                    Отправляя форму, вы соглашаетесь с{' '}
                    <Link
                      target="_blank"
                      onClick={() => dispatch(setAddActiveTarget('business'))}
                      to="/politics"
                    >
                      условиями
                    </Link>
                  </p>
                )}
              </div>
            </div>

            {!formSendDiagnostics ? (
              <div className="header__illustration">
                {width > 768 && (
                  <picture>
                    <source srcSet="/static/business/diagnostics/photo.webp" type="image/webp" />
                    <img src="/static/business/diagnostics/photo.png" alt="Основатель" />
                  </picture>
                )}
              </div>
            ) : formSendDiagnostics && width > 768 ? (
              <div className="header__video">
                {video ? (
                  <iframe
                    className="header__video-iframe"
                    width={width <= 1600 ? '347' : '593'}
                    height={width <= 1600 ? '197' : '336'}
                    src="https://www.youtube.com/embed/MzX6ND6Mnqk"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                ) : (
                  <picture>
                    <source srcSet="/static/business/diagnostics/success.webp" type="image/webp" />
                    <img
                      onClick={() => setVideo(true)}
                      src="/static/business/diagnostics/success.png"
                      alt="Видео"
                    />
                  </picture>
                )}

                <p>
                  Посмотри видео, узнай что тебя ждет и{' '}
                  <a href="https://skilla.ru/business/checklist" target="_blank">
                    скачай чек-лист
                  </a>
                </p>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>

        {!formSendDiagnostics && width <= 768 && (
          <div className="header__illustration">
            <picture>
              <source srcSet="/static/business/diagnostics/photo-mobile.webp" type="image/webp" />
              <img src="/static/business/diagnostics/photo-mobile.png" alt="Основатель" />
            </picture>
          </div>
        )}

        {width <= 768 && !formSendDiagnostics && (
          <div className="header__name">
            <span>Кирилл Упоров, основатель Скилла</span>
          </div>
        )}
        {width <= 768 && <Form page="diagnostics" type="diagnostics" />}

        {width <= 768 && formSendDiagnostics && (
          <div className="container">
            <div className="header__video">
              {video ? (
                <iframe
                  className="header__video-iframe"
                  width="343"
                  height="194"
                  src="https://www.youtube.com/embed/MzX6ND6Mnqk"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              ) : (
                <picture>
                  <source srcSet="/static/business/diagnostics/success-mobile.webp" type="image/webp" />
                  <img
                    onClick={() => setVideo(true)}
                    src="/static/business/diagnostics/success-mobile.png"
                    alt="Видео"
                  />
                </picture>
              )}
              <p>
                Посмотри видео, узнай что тебя ждет и{' '}
                <a href="https://skilla.ru/business/checklist">скачай чек-лист</a>
              </p>
            </div>
          </div>
        )}

        {!formSendDiagnostics && width > 768 && (
          <div className="header__name">
            <span>Кирилл Упоров, основатель Скилла</span>
          </div>
        )}

        {width <= 768 && !formSendDiagnostics && (
          <div className="container">
            <p style={{ 'padding-bottom': formSendDiagnostics ? '59px' : '' }} className="header__info">
              Отправляя форму, вы соглашаетесь с{' '}
              <Link target="_blank" onClick={() => dispatch(setAddActiveTarget('business'))} to="/politics">
                условиями
              </Link>
            </p>
          </div>
        )}
      </section>

      {!formSendDiagnostics ? (
        <Footer page="business" type="diagnostics" />
      ) : (
        <Footer page="business" type="faq" />
      )}
    </DiagnosticsStyle>
  )
}

const DiagnosticsStyle = styled.div`
  .header {
    position: relative;
    color: #fff;
    padding: 0 0 0;
    
    background: radial-gradient(
      146.1% 692.06% at 98.79% 0%,
      rgba(0, 80, 41, 0.81) 0%,
      rgba(0, 7, 41, 0) 100%
    ),
    #000729;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.15);
    
    
    @media (max-width: 768px) {
      padding: 0 0 60px;
    }
    
    &__title {
      margin-bottom: 24px;
      @media (max-width: 768px) {
        margin-bottom: 16px;
      }
    }
    
    &__icon {
      @media (max-width: 768px) {
        max-width: 343px;
        width: 100%;
        margin: 0 auto;
        display: block;
        padding-top: 44px;
      }
    }
    
    &__inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      
      @media (max-width: 1600px) {
        align-items: center;
      }
    }
    
    &__content {
      max-width: 775px;
      width: 100%;
      padding-top: 98px;
      
      &.success {
        width: initial;
      }
      
      @media (max-width: 1600px) {
        padding-top: 55px;
      }
      
      @media (max-width: 768px) {
        padding: 0 0 98px 0;
      }
    }
    
    &__illustration {
      position: absolute;
      bottom: -5px;
      right: 156px;
      
      
      @media (max-width: 1600px) {
        position: static;
        width: 100%;
        
        img {
          object-fit: cover;
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
      
      @media (max-width: 768px) {
        position: absolute;
        bottom: 261px;
        width: 100%;
        right: 26px;
        
        img {
          height: initial;
          width: initial;
        }
      }
    }
    
    &__name {
      min-height: 148px;
      text-align: end;
      position: relative;
      color: #E3E4E8;
      font-weight: 400;
      font-size: 24px;
      padding-right: 260px;
      padding-bottom: 44px;
      background: linear-gradient(0.01deg, rgba(0, 0, 0, 0.8) 0.01%, rgba(2, 15, 40, 0) 71.05%);
      
      display: flex;
      flex-direction: column;
      justify-content: end;
      
      @media (max-width: 1600px) {
        width: 100%;
        padding-right: 130px;
        min-height: 110px;
      }
      
      @media (max-width: 768px) {
        padding-right: 16px;
        padding-bottom: 35px;
        font-weight: 400;
        font-size: 20px;
        background: linear-gradient(0deg, #020D25 0%, rgba(2, 15, 40, 0) 85.11%);
        text-align: center;
        min-height: 166px;
      }
    }
    
    &__block {
      margin-top: 60px;
      
      @media (max-width: 768px) {
        margin-top: 24px;
        font-weight: 800;
        font-size: 26px;
      }
    }
    
    &__title {
      font-size: 54px;
      @media (max-width: 768px) {
        font-size: 26px;
      }
      
      span {
        display: inline;
        color: #00ff88;
      }
    }
    
    &__descr {
      font-weight: 600;
      font-size: 24px;
      line-height: 130%;
      color: #e3e4e8;
      
      @media (max-width: 768px) {
        font-weight: 400;
        font-size: 16px;
      }
    }
    
    &__success {
      margin-top: 122px;
      
      h3 {
        margin-bottom: 24px;
        font-weight: 800;
        font-size: 46px;
        
        
        @media (max-width: 1600px) {
          font-size: 40px;
        }
        
        @media (max-width: 768px) {
          font-weight: 800;
          font-size: 26px;
        }
        
        @media (max-width: 768px) {
          margin-bottom: 16px;
        }
      }
      
      p {
        color: #E3E4E8;
        font-weight: 500;
        font-size: 22px;
        
        @media (max-width: 1600px) {
          font-size: 15px;
        }
        
        @media (max-width: 768px) {
          font-weight: 400;
          font-size: 16px;
          line-height: 130%;
        }
      }
      
      
      @media (max-width: 768px) {
        margin-bottom: 48px;
      }
    }
    
    &__video {
      font-weight: 700;
      font-size: 18px;
      color: #E3E4E8;
      cursor: pointer;
      
      img {
        margin-bottom: 20px;
      }
      
      &-iframe {
        margin-bottom: 20px;
      }
      
      p {
        padding-bottom: 44px;
        @media (max-width: 768px) {
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
        }
      }
      
      a {
        color: #E3E4E8;
        text-decoration: underline;
      }
      
      @media (max-width: 1600px) {
        max-width: 450px;
        margin-left: 20px;
        
        img {
          width: 100%;
          object-fit: cover;
        }
      }
      
      @media (max-width: 768px) {
        margin-left: 0;
      }
    }
    
  }
  
  li {
    list-style-type: none;
  }
  
  ul {
    padding: 0;
  }
  
  .header-form {
    padding: 24px;
    background-color: #303550;
    border-radius: 12px;
    width: max-content;
    margin: 40px 0 0 0;
    position: relative;
    z-index: 1;
    
    &.modalAnswer {
      background: transparent;
      padding: 0;
      position: relative;
      z-index: 1;
      
      @media (max-width: 1600px) {
      
      }
      
      @media (max-width: 768px) {
        width: 343px;
        margin: 32px auto;
      }
    }
    
    @media (max-width: 768px) {
      margin: 0;
      width: 100%;
      padding: 20px;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
    }
  }
  
  .header__form-result {
    color: #fff;
    padding: 14px;
  }
  
  .header-form__flex {
    display: flex;
    
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
  }
  
  .header-form__input {
    font-size: 18px;
    display: block;
    height: 50px;
    background-color: rgba(0, 7, 41, 0.3);
    padding: 12.5px 13px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 4px 0 0 4px;
    width: 275px;
    color: #fff;
    
    &.error {
      border: 1px solid red;
    }
    
    &.opacity {
      background: rgba(0, 0, 0, 0.2);
      border: 1px solid rgba(255, 255, 255, 0.2);
    }
    
    &::placeholder {
      color: #fff;
    }
    
    
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  
  .header-form__button {
    margin-left: -4px;
    display: flex;
    align-items: center;
    height: 50px;
    padding: 12.5px 24px;
    font-size: 18px;
    line-height: 120%;
    font-weight: 600;
    cursor: pointer;
    background-color: #00ff88;
    color: #000729;
    border-radius: 4px;
    border: 0;
    transition: background-color 0.3s ease;
    font-family: inherit;
    
    &.finished {
      @media (max-width: 768px) {
        max-width: 280px;
        width: 100%;
      }
    }
    
    & span {
      margin-left: 12px;
    }
    
    &:hover {
      background-color: #00cc6e;
    }
    
    
    @media (max-width: 768px) {
      width: 100%;
      margin: 32px auto 0;
      font-size: 18px;
      justify-content: center;
    }
  }
  
  .header-form__labels {
    display: flex;
    grid-gap: 32px;
    margin-top: 18px;
    
    @media (max-width: 768px) {
      padding-left: 16px;
    }
  }
  
  .header-form__label {
    font-size: 16px;
    line-height: 120%;
    color: #c7c9d1;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .header__img {
    position: absolute;
    bottom: 61px;
    right: 0;
    
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  
  .header-form__checkbox {
    margin: 0 8px 0 0;
    width: 16px;
    height: 16px;
    cursor: pointer;
    
    &:checked::before {
      border: 1px solid #00ff88;
      background: #303550 url("data:image/svg+xml;charset=UTF-8,%3csvg width='6' height='7' viewBox='0 0 6 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='3' cy='3.5' r='3' fill='%2300FF88'/%3e%3c/svg%3e ") no-repeat 4px 4px;
    }
    
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: #303550;
      border: 1px solid rgba(199, 201, 209, 0.3);
      border-radius: 50%;
    }
  }
  
  .header__info {
    position: relative;
    z-index: 1;
    color: #786c6c99;
    padding-top: 48px;
    
    
    a {
      color: #786c6c99;
      transition: all 0.2s linear;
      text-decoration: underline;
      
      &:hover {
        color: #97a8ff;
      }
    }
    
    
    @media (max-width: 768px) {
      font-size: 13px;
      position: absolute;
      bottom: 32px;
      padding: 0;
      z-index: 10;
      font-weight: 400;
      font-size: 14px;
    }
  }
  
  &.top {
    padding-top: 72px;
  }
}

.form-block {
  margin-top: 72px;
  
  &__price {
    margin-bottom: 12px;
    color: #E3E4E8;
    text-decoration-line: line-through;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 24px;
    
    @media (max-width: 1600px) {
      font-size: 20px;
    }
    
    @media (max-width: 768px) {
      display: none;
    }
  }
  
  &__free {
    color: #E3E4E8;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 32px;
    
    @media (max-width: 1600px) {
      font-size: 24px;
    }
    
    @media (max-width: 768px) {
      display: none;
    }
  }
}

.modal--- {
  position: fixed;
  z-index: 20;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 7, 41, 0.4);
}

.modal---diagnostics {
  position: relative;
  z-index: 20;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  
  @media (max-width: 768px) {
    margin-bottom: 48px;
  }
}

.modal---calc {
  position: fixed;
  z-index: 20;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 7, 41, 0.4);
}

.modal__content {
  width: 688px;
  background-color: #303550;
  color: #fff;
  position: relative;
  text-align: center;
  
  @media (max-width: 768px) {
    width: 375px;
  }
}

.modal__cross {
  position: absolute;
  right: 24px;
  top: 24px;
}

.modal__button {
  cursor: pointer;
  padding: 0 50px;
  background-color: #00ff88;
  color: #000729;
  border: 0;
  border-radius: 4px;
  font-size: 18px;
  line-height: 48px;
  font-weight: 500;
  font-family: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: auto;
  transition: background-color 0.3s ease;
  
  &::after {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    margin-left: 20px;
    margin-bottom: 0;
    background: url("data:image/svg+xml;charset=UTF-8,%3csvg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.5 4L11.09 5.41L16.67 11H4.5V13H16.67L11.09 18.59L12.5 20L20.5 12L12.5 4Z' fill='%23000729'/%3e%3c/svg%3e ") no-repeat center top / cover;
  }
  
  &:hover {
    background-color: #00cc6e;
  }
  
  @media (max-width: 768px) {
    max-width: 280px;
    width: 100%;
    font-weight: 600;
  }
}

.modal__title {
  font-size: 36px;
  line-height: 120%;
  font-weight: 800;
  max-width: 450px;
  margin: auto;
  margin-top: 64px;
  margin-bottom: 16px;
  
  @media (max-width: 768px) {
    font-weight: 800;
    font-size: 26px;
    margin-top: 24px;
    margin-bottom: 32px;
  }
}

.modal__text {
  font-size: 18px;
  line-height: 120%;
  color: #e3e4e8;
  max-width: 450px;
  margin: auto;
  
  @media (max-width: 768px) {
    font-size: 16px;
  }
}

.modal__input {
  width: 300px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  padding: 11.5px 13px;
  font-family: inherit;
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  color: #fff;
  
  &::placeholder {
    font-family: inherit;
    color: #abadba;
  }
}

.modal__input_step1 {
  margin-top: 56px;
  margin-bottom: 15px;
}

.modal__input_step2 {
  margin: 55px 0 135px 0;
  
  @media (max-width: 768px) {
    margin: 32px 0
  }
}

.modal__input_menu {
  display: none;
  height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  position: absolute;
  bottom: -200px;
  cursor: pointer;
  z-index: 10;
  
  &.active {
    display: block;
  }
}

.modal__input_city_step1 {
  margin-bottom: 70px !important;
}

.modal__input_city {
  margin: auto;
  position: relative;
  width: 300px;
  
  &:focus-within .modal__input_menu {
    display: block;
  }
  
  &::after {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    position: absolute;
    right: 12px;
    top: 0;
    bottom: 0;
    margin: auto;
    background: url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.5 9L12 15L17.5 9H6.5Z' fill='%23ABADBA'/%3e%3c/svg%3e ") no-repeat center top / cover;
    cursor: pointer;
  }
}

.modal__input_city-item {
  padding: 10.5px 13px;
  background-color: #262a40;
  font-size: 18px;
  color: rgba(171, 173, 186, 1);
  font-weight: 500;
  text-align: left;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: #44485c;
  }
}

.modal__question {
  max-width: 450px;
  margin: auto;
  margin-top: 45px;
  
  & .header-form__checkbox::before {
    background-color: #303550;
  }
  
  & .header-form__checkbox:checked::before {
    background-color: #303550;
  }
}

.modal__input_step3 {
  width: 100%;
  resize: none;
  height: 105px;
  margin-bottom: 55px;
  outline: none;
  
  @media (max-width: 768px) {
    max-width: 343px;
    width: 100%;
  }
}

.modal__button_step3::after {
  display: none;
}

.modal__question-text {
  font-size: 22px;
  line-height: 120%;
  margin-bottom: 17px;
  text-align: left;
  
  @media (max-width: 768px) {
    font-size: 16px;
    font-weight: 500;
    padding-left: 16px;
  }
}

.modal__progress {
  margin-top: 52px;
  display: flex;
  width: 100%;
  height: 6px;
  background-color: #abadba;
  
  & span {
    display: block;
    width: 25%;
    height: 6px;
    background-color: #00cc6e;
  }
}

.modal__back {
  position: absolute;
  left: 24px;
  top: 24px;
}

.modal-video {
  width: 450px;
  margin: 40px auto 55px auto;
}

.modal-video__content {
  text-align: left;
  
  @media (max-width: 768px) {
    margin-left: 20px;
  }
}

.modal-video__title {
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 4px;
  font-weight: 700;
  margin-top: 20px;
}

.modal-video__description {
  font-size: 16px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.7);
}

.tabs__content_flex {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 25px;
}

.modal-video__video {
  height: 255px;
}


@media screen and (max-width: 1600px) {
  .header-form {
    padding: 23px;
    
    &__input {
      width: 100%;
      font-size: 14px;
    }
    
    &__button {
      font-size: 17px;
    }
  }
  
  
  .header-form__labels {
    padding-left: 0;
  }
  
  .modal--- .header-form__labels {
    padding-left: 16px;
  }
  
  .modal__content,
  .modal-video,
  .modal-video__content {
    max-width: 540px;
  }
  
  .modal-video__content {
    text-align: center;
  }
  
  .modal__input_step2 {
    margin: 55px 0 70px 0;
  }
}


@media screen and (max-width: 495px) {
  
  .header-form__labels {
    padding-left: 0;
  }
  
  .modal--- .header-form__labels {
    padding-left: 16px;
  }
  
  .header-form__input {
    width: 100%;
  }
  
  
  .modal__content,
  .modal-video,
  .modal-video__content {
    max-width: 343px;
    width: 100%;
  }
  
  .modal-video__content {
    text-align: center;
  }
  
  .modal__input_step2 {
    margin: 55px 0 70px 0;
  }
`

export default Diagnostics
