
import cn from 'classnames';
import s from '../steps.module.scss';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
	setAddData,
	setAddStep,
} from '../../../../../store/reducer/lk/formExam/slice';

import { selectLkFormExam } from '../../../../../store/reducer/lk/formExam/selector';
import Button from '../../Button/Button';
import Form from '../../Form';

const Step12 = () => {
	const [width, setWidth] = useState(window?.innerWidth);
	const dispatch = useDispatch();
	const { data, step } = useSelector(selectLkFormExam);
	const [isError32, setError32] = useState(false);
	const [isError33, setError33] = useState(false);
	const [isError34, setError34] = useState(false);
	const [isSendForm, setIsSendForm] = useState(false);

	useEffect(() => {
		localStorage.setItem('stepLkExam', JSON.stringify(step));
		localStorage.setItem('dataExam', JSON.stringify(data));
	}, []);

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
		watch,
	} = useForm({
		defaultValues: {
			['Что нужно сделать на Авито, чтобы Skilla приступила к публикации объявлений?']: data['Что нужно сделать на Авито, чтобы Skilla приступила к публикации объявлений?'],
			['Выберите определения, относящиеся к SЕО-продвижению']: data['Выберите определения, относящиеся к SЕО-продвижению'],
			['Выберите определения, относящиеся к контекстной рекламе']: data['Выберите определения, относящиеся к контекстной рекламе'],
		},
	});
	
	const ckbox32 = watch('Что нужно сделать на Авито, чтобы Skilla приступила к публикации объявлений?');
	const ckbox33 = watch('Выберите определения, относящиеся к SЕО-продвижению');
	const ckbox34 = watch('Выберите определения, относящиеся к контекстной рекламе');
	
	useEffect(() => {
		if (!ckbox32?.length) {
			setError32(true);
		} else {
			setError32(false);
		}
	}, [ckbox32]);

	useEffect(() => {
		if (!ckbox33?.length) {
			setError33(true);
		} else {
			setError33(false);
		}
	}, [ckbox33]);

	useEffect(() => {
		if (!ckbox34?.length) {
			setError34(true);
		} else {
			setError34(false);
		}
	}, [ckbox34]);

	const onSubmit = data => {
		setIsSendForm(true);

		if (!isError32 && !isError33 && !isError34) {
			dispatch(setAddStep(13));
			dispatch(setAddData(data));
			setIsSendForm(false);

			localStorage.setItem('dataExam', JSON.stringify(data));
			localStorage.setItem('stepLkExam', JSON.stringify(12));
		}
	};

	return (
		<div className={s.step12}>
      <div className={s.wrapper_heading}>
			  <h5>Реклама</h5>
			</div>
			<Form onSubmit={handleSubmit(onSubmit)}>

                <div className={s.form_item}>
					<h4 className={s.form_title}>
					32. Что нужно сделать на Авито, чтобы Skilla приступила к публикации объявлений?
					</h4>

					<div className={s.question32}>
						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question32,
								})}
								{...register('Что нужно сделать на Авито, чтобы Skilla приступила к публикации объявлений?')}
								id='exam221'
								type='checkbox'
								name='Что нужно сделать на Авито, чтобы Skilla приступила к публикации объявлений?'
								autoComplete='off'
								value='Зарегистрировать личный кабинет как физ.лицо'/>
							<label htmlFor='exam221' className={s.label}>
							Зарегистрировать личный кабинет как физ.лицо
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question32,
								})}
								{...register('Что нужно сделать на Авито, чтобы Skilla приступила к публикации объявлений?')}
								id='exam222'
								type='checkbox'
								name='Что нужно сделать на Авито, чтобы Skilla приступила к публикации объявлений?'
								autoComplete='off'
								value='Пополнить кошелек на Авито'/>
							<label htmlFor='exam222' className={s.label}>
							Пополнить кошелек на Авито
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question32,
								})}
								{...register('Что нужно сделать на Авито, чтобы Skilla приступила к публикации объявлений?')}
								id='exam223'
								type='checkbox'
								name='Что нужно сделать на Авито, чтобы Skilla приступила к публикации объявлений?'
								autoComplete='off'
								value='Ничего, просто написать в чат поддержки запрос'/>
							<label htmlFor='exam223' className={s.label}>
							Ничего, просто написать в чат поддержки запрос
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question32,
								})}
								{...register('Что нужно сделать на Авито, чтобы Skilla приступила к публикации объявлений?')}
								id='exam224'
								type='checkbox'
								name='Что нужно сделать на Авито, чтобы Skilla приступила к публикации объявлений?'
								autoComplete='off'
								value='Предоставить доступ в личный кабинет Авито'/>
							<label htmlFor='exam224' className={s.label}>
							Предоставить доступ в личный кабинет Авито
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question32,
								})}
								{...register('Что нужно сделать на Авито, чтобы Skilla приступила к публикации объявлений?')}
								id='exam225'
								type='checkbox'
								name='Что нужно сделать на Авито, чтобы Skilla приступила к публикации объявлений?'
								autoComplete='off'
								value='Подтвердить привязку виртуального номера'/>
							<label htmlFor='exam225' className={s.label}>
							Подтвердить привязку виртуального номера
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question32,
								})}
								{...register('Что нужно сделать на Авито, чтобы Skilla приступила к публикации объявлений?')}
								id='exam226'
								type='checkbox'
								name='Что нужно сделать на Авито, чтобы Skilla приступила к публикации объявлений?'
								autoComplete='off'
								value='Все варианты верные'/>
							<label htmlFor='exam226' className={s.label}>
							Все варианты верные
							</label>
						</div>

						<p className={s.errorText2}>
							{isError32 &&
								isSendForm &&
								'Необходимо указать'}
						</p>
					</div>
				</div>

				<div className={s.form_item}>
					<h4 className={s.form_title}>33. Выберите определения, относящиеся к SЕО-продвижению</h4>
                    <div className={s.question33}>
						
						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question33,
								})}
								{...register('Выберите определения, относящиеся к SЕО-продвижению')}
								id='exam331'
								type='checkbox'
								name='Выберите определения, относящиеся к SЕО-продвижению'
								autoComplete='off'
								value='Оплата за клики'/>
							<label htmlFor='exam331' className={s.label}>
							Оплата за клики
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question33,
								})}
								{...register('Выберите определения, относящиеся к SЕО-продвижению')}
								id='exam332'
								type='checkbox'
								name='Выберите определения, относящиеся к SЕО-продвижению'
								autoComplete='off'
								value='Постоянное нахождение в выдаче 24/7'/>
							<label htmlFor='exam332' className={s.label}>
							Постоянное нахождение в выдаче 24/7
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question33,
								})}
								{...register('Выберите определения, относящиеся к SЕО-продвижению')}
								id='exam333'
								type='checkbox'
								name='Выберите определения, относящиеся к SЕО-продвижению'
								autoComplete='off'
								value='Длительный процесс выхода в ТОП'/>
							<label htmlFor='exam333' className={s.label}>
							Длительный процесс выхода в ТОП
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question33,
								})}
								{...register('Выберите определения, относящиеся к SЕО-продвижению')}
								id='exam334'
								type='checkbox'
								name='Выберите определения, относящиеся к SЕО-продвижению'
								autoComplete='off'
								value='Большее доверие юридических лиц'/>
							<label htmlFor='exam334' className={s.label}>
							Большее доверие юридических лиц
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question33,
								})}
								{...register('Выберите определения, относящиеся к SЕО-продвижению')}
								id='exam335'
								type='checkbox'
								name='Выберите определения, относящиеся к SЕО-продвижению'
								autoComplete='off'
								value='Быстрый результат'/>
							<label htmlFor='exam335' className={s.label}>
							Быстрый результат
							</label>
						</div>

                    
						<p className={s.errorText2}>
							{isError33 &&
								isSendForm &&
								'Необходимо указать'}
						</p>
					</div>
				
				</div>

				<div className={s.form_item}>
					<h4 className={s.form_title}>34. Выберите определения, относящиеся к контекстной рекламе</h4>
                    <div className={s.question34}>
						
						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question34,
								})}
								{...register('Выберите определения, относящиеся к контекстной рекламе')}
								id='exam341'
								type='checkbox'
								name='Выберите определения, относящиеся к контекстной рекламе'
								autoComplete='off'
								value='Оплата за клики'/>
							<label htmlFor='exam341' className={s.label}>
							Оплата за клики
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question34,
								})}
								{...register('Выберите определения, относящиеся к контекстной рекламе')}
								id='exam342'
								type='checkbox'
								name='Выберите определения, относящиеся к контекстной рекламе'
								autoComplete='off'
								value='Постоянное нахождение в выдаче 24/7'/>
							<label htmlFor='exam342' className={s.label}>
							Постоянное нахождение в выдаче 24/7
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question34,
								})}
								{...register('Выберите определения, относящиеся к контекстной рекламе')}
								id='exam343'
								type='checkbox'
								name='Выберите определения, относящиеся к контекстной рекламе'
								autoComplete='off'
								value='Длительный процесс выхода в ТОП'/>
							<label htmlFor='exam343' className={s.label}>
							Длительный процесс выхода в ТОП
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question34,
								})}
								{...register('Выберите определения, относящиеся к контекстной рекламе')}
								id='exam344'
								type='checkbox'
								name='Выберите определения, относящиеся к контекстной рекламе'
								autoComplete='off'
								value='Большее доверие юридических лиц'/>
							<label htmlFor='exam344' className={s.label}>
							Большее доверие юридических лиц
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question34,
								})}
								{...register('Выберите определения, относящиеся к контекстной рекламе')}
								id='exam345'
								type='checkbox'
								name='Выберите определения, относящиеся к контекстной рекламе'
								autoComplete='off'
								value='Быстрый результат'/>
							<label htmlFor='exam345' className={s.label}>
							Быстрый результат
							</label>
						</div>

						<p className={s.errorText2}>
							{isError34 &&
								isSendForm &&
								'Необходимо указать'}
						</p>
					</div>
				</div>

				<div className={s.btns}>
        <Button callback={() => dispatch(setAddStep(11))} back>
						{width > 575 ? (
							'   Назад'
						) : (
							<img src='/static/lk/IconBackward.svg' alt='назад' />
						)}
					</Button>
					<Button>Далее 12 из 35</Button>
				</div>
			</Form>

			<img
				className={s.illustration}
				src='/static/lk/illustration/illustration.png'
				alt='Иллюстрация'
			/>
		</div>
	);
};

export default Step12;

