import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCheckApp, getLkForm, getLkGetZoom } from '../../../../Api';
import { selectLKAuth } from '../../../../store/reducer/lk/authChech/selector';
import { setCheckDownloadedApp } from '../../../../store/reducer/lk/authChech/slice';
import { setAddStatus } from '../../../../store/reducer/lk/form/slice';
import { setAddActiveLink } from '../../../../store/reducer/lk/sidebar/slice';
import { setAddStatusZoom } from '../../../../store/reducer/lk/zoom/slice';
import s from './save.module.scss';

import { selectLKContract } from '../../../../store/reducer/lk/contract/selector';
import { setIsCheckFromPage } from '../../../../store/reducer/lk/contract/slice.js';
import { selectLKPayment } from '../../../../store/reducer/lk/payment/selector';

const Save = memo(({ endDatePremium }) => {
	const { user } = useSelector(selectLKAuth);
	const [width, setWidth] = useState(window?.innerWidth);
	const menuActive = user?.user?.menu;
	const { persent } = useSelector(selectLKContract);
	const { paymentInfo } = useSelector(selectLKPayment);

	const statusPayment = paymentInfo?.payment?.status;

	const dispatch = useDispatch();

	

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);
		window.scroll(0, 0);

		return () => window.removeEventListener('resize', cb);
	}, []);

	useEffect(() => {
		getLkGetZoom(user?.user?.code).then(({ data }) => {
			dispatch(setAddStatusZoom(data));
			localStorage.setItem('statusZoom', JSON.stringify(data));
		});
	}, []);

	useEffect(() => {
		const checkApp = async () => {
			try {
				const { data } = await getCheckApp(user?.user?.code);
				dispatch(setCheckDownloadedApp(data.result));
				localStorage.setItem('checkDownloadedApp', JSON.stringify(data.result));
			} catch (e) {
				console.log(e);
			}
		};

		checkApp();
	}, []);

	useEffect(() => {
		getLkForm(user?.user?.code).then(({ data }) => {
			dispatch(setAddStatus(data));
			localStorage.setItem('status', JSON.stringify(data));
		});
	}, []);

	const goToMaterial = () => {
		
			if(menuActive) {
				const newArr = Object.entries(menuActive).map((el) => el[1]);
				const active = newArr?.find((el) => (el.status == 'enabled'||  el.status == 'waiting') && el.name !== 'Моб. приложение')
				dispatch(setAddActiveLink(active?.id));
				console.log(newArr)
			}
		  
	
	};

	const phoneFormat = (s, plus = true) => {
		const startsWith = plus ? '+7' : '8';

		let phone = s?.replace(/[^0-9]/g, '');
		if (phone?.startsWith('7') && plus) {
			phone = phone.substr(1);
		}
		if (phone?.startsWith('8')) {
			phone = phone.substr(1);
		}

		return phone?.replace(
			/(\d{3})(\d{3})(\d{2})(\d{2})/g,
			`${startsWith} $1 $2-$3-$4`
		);
	};

	const toGoVipPage = () => {
		dispatch(setIsCheckFromPage(true));
		localStorage.setItem('isCheckFromPage', JSON.stringify(true));
		window.open('/premium', '_blank');
	};

	return (
		<>
			{statusPayment &&
				persent &&
				statusPayment !== 'prepaid' &&
				statusPayment !== 'paid' &&
				persent <= 4 &&
				endDatePremium &&
				(width > 575 ? (
					<header onClick={toGoVipPage} className={s.header}>
						<img src='/static/lk/background.png' alt='Баннер' />

						<div className={s.header_btn}>Узнать больше</div>
					</header>
				) : (
					<header className={s.header}>
						<div onClick={toGoVipPage}>
							<img
								style={{ width: '100%' }}
								src='/static/lk/background-mobile.png'
								alt='Баннер'
							/>
						</div>
					</header>
				))}

			<div className={s.save}>
				{width > 575 ? (
					<h1 className={`${s.title} lk-title`}>Привет, {user?.user?.name}!</h1>
				) : (
					<h1 className={`${s.title} lk-title`}>
						Привет, <br></br> {user?.user?.name}!
					</h1>
				)}

				<div style={{ marginBottom: '455px' }} className={s.block}>
					<div className={s.data}>
						<div className={s.label}>
							{phoneFormat(
								user?.user?.phones[0]?.phone || user?.user?.phones[1]?.phone
							)}
						</div>
						{(user?.user?.phones[0]?.is_verified ||
							user?.user?.phones[1]?.is_verified) && (
							<div className={s.done}>
								<svg
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z'
										fill='#002CFB'
									/>
									<path
										d='M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z'
										fill='#002CFB'
									/>
									<path
										d='M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z'
										fill='#002CFB'
									/>
								</svg>

								{width > 575 && <span>Подтвержден</span>}
							</div>
						)}
					</div>

					<div className={s.data}>
						<div className={s.label}>
							{user?.user?.emails[0]?.email || user?.user?.emails[1]?.email}
						</div>

						{(user?.user?.emails[0]?.is_verified ||
							user?.user?.emails[0]?.is_verified) && (
							<div className={s.done}>
								<svg
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z'
										fill='#002CFB'
									/>
									<path
										d='M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z'
										fill='#002CFB'
									/>
									<path
										d='M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z'
										fill='#002CFB'
									/>
								</svg>

								{width > 575 && <span>Подтвержден</span>}
							</div>
						)}
					</div>
				</div>

				{/* <Button className={"addNumberPhone"}>Добавить номер телефона</Button> */}

				{width <= 575 && (
					<div className={s.btn_material}>
						<button onClick={goToMaterial}>Перейти к материалам</button>
					</div>
				)}
			</div>
		</>
	);
});

export default Save;
