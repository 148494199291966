import { useRadioGroupState } from '@react-stately/radio';
import React from 'react';
import { useDispatch } from 'react-redux';
import {
	setAddIsExpBusiness,
	setAddIsExpPeoples,
} from '../store/reducer/form/slice';

const RadioGroup = props => {
	const dispatch = useDispatch();
	let state = useRadioGroupState({
		defaultValue: props.defaultValue,
		onChange: props.onChange,
	});

	if (props.type === 'people')
		return (
			<div className='header-form__labels'>
				<label className='header-form__label'>
					<input
						required
						className='header-form__checkbox'
						type='radio'
						name={state.name}
						onChange={() => dispatch(setAddIsExpPeoples(1))}
					/>
					Да
				</label>
				<label className='header-form__label'>
					<input
						required
						className='header-form__checkbox'
						type='radio'
						name={state.name}
						onChange={() => dispatch(setAddIsExpPeoples(0))}
					/>
					Нет
				</label>
			</div>
		);

	if (props.type === 'business')
		return (
			<div className='header-form__labels'>
				<label className='header-form__label'>
					<input
						required
						className='header-form__checkbox'
						type='radio'
						name={state.name}
						onChange={() => dispatch(setAddIsExpBusiness(1))}
					/>
					Да
				</label>
				<label className='header-form__label'>
					<input
						required
						className='header-form__checkbox'
						type='radio'
						name={state.name}
						onChange={() => dispatch(setAddIsExpBusiness(0))}
					/>
					Нет
				</label>
			</div>
		);

	return (
		<div
			className={
				props.type !== 'calculator'
					? 'header-form__labels'
					: 'header-form__labels header-form__labels--calculator'
			}
		>
			<label className='header-form__label'>
				<input
					className='header-form__checkbox'
					type='radio'
					name={state.name}
					checked={state.selectedValue === 'telegram'}
					onChange={() => state.setSelectedValue('telegram')}
				/>
				Telegram
			</label>
			<label className='header-form__label'>
				<input
					className='header-form__checkbox'
					type='radio'
					name={state.name}
					checked={state.selectedValue === 'whatsapp'}
					onChange={() => state.setSelectedValue('whatsapp')}
				/>
				WhatsApp
			</label>

			<label className='header-form__label'>
				<input
					className='header-form__checkbox'
					type='radio'
					name={state.name}
					checked={state.selectedValue === 'звонок'}
					onChange={() => state.setSelectedValue('звонок')}
				/>
				{props.type !== 'book' ? 'Звонок' : 'СМС'}
			</label>
		</div>
	);
};

export default RadioGroup;
