import { useEffect, useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLkPaymentInformation, getUser, getViewMenu } from '../../../Api';
import { selectLKAuth } from '../../../store/reducer/lk/authChech/selector';
import { setAddUser } from '../../../store/reducer/lk/authChech/slice.js';
import { selectLKContract } from '../../../store/reducer/lk/contract/selector.js';
import { setIsCheckFromPage } from '../../../store/reducer/lk/contract/slice.js';
import { selectLKPayment } from '../../../store/reducer/lk/payment/selector.js';
import {
	setAddLoading,
	setAddPaymentInfo,
} from '../../../store/reducer/lk/payment/slice.js';
import { selectLKSidebar } from '../../../store/reducer/lk/sidebar/selector';
import Done from '../Payment/pages/done/Done';
import FullPayment from './pages/fullPayment/FullPayment';
import s from './payment.module.scss';

const Payment = memo(({ endDatePremium }) => {
	const dispatch = useDispatch();
	const { user } = useSelector(selectLKAuth);
	const [clientWithoutCours, setClientWithoutCours] = useState(false)
	const [width, setWidth] = useState(window?.innerWidth);
	const { paymentInfo } = useSelector(selectLKPayment);
	const { activeLink, socketData } = useSelector(selectLKSidebar);

	const status = paymentInfo?.payment?.status;

	const { persent } = useSelector(selectLKContract);
	const statusPayment = paymentInfo?.payment?.status;

	useEffect(() => {
		user?.user?.menu[7].name == 'Договор-оферта' ? setClientWithoutCours(true) : setClientWithoutCours(false)
	  }, [user?.user?.menu[7].name])

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);
		window.scroll(0, 0);

		return () => window.removeEventListener('resize', cb);
	}, []);

	useEffect(() => {
		dispatch(setAddLoading(true));
		const getPaymentInfo = async () => {
			try {
				const { data } = await getLkPaymentInformation(user?.user?.code);

				dispatch(setAddLoading(false));
				dispatch(setAddPaymentInfo(data));
				localStorage.setItem('paymentInfo', JSON.stringify(data));
			} catch (e) {
				dispatch(setAddLoading(false));
				console.log(e);
			}
		};

		getPaymentInfo();
	}, [socketData]);

	useEffect(() => {
		const checkViewMenu = async () => {
			try {
				const { data } = await getViewMenu(user?.user?.code, /* activeLink */8);
			} catch (e) {
				console.log(e);
			}
		};

		checkViewMenu();
	}, []);

	useEffect(() => {
		const getUserInfo = async () => {
			try {
				const { data } = await getUser(user?.user?.code);

				dispatch(setAddUser(data));
				localStorage.setItem('user', JSON.stringify(data));
			} catch (e) {
				console.log(e);
			}
		};

		if (user?.user?.code) {
			getUserInfo();
		}
	}, [socketData]);

	const toGoVipPage = () => {
		dispatch(setIsCheckFromPage(true));
		localStorage.setItem('isCheckFromPage', JSON.stringify(true));
		window.open('/premium', '_blank');
	};

	return (
		<>
			{statusPayment &&
				persent &&
				statusPayment !== 'prepaid' &&
				statusPayment !== 'paid' &&
				persent <= 4 &&
				endDatePremium &&
				(width > 575 ? (
					<header onClick={toGoVipPage} className={s.header}>
						<img src='/static/lk/background.png' alt='Баннер' />

						<div className={s.header_btn}>Узнать больше</div>
					</header>
				) : (
					<header className={s.header}>
						<div onClick={toGoVipPage}>
							<img
								style={{ width: '100%' }}
								src='/static/lk/background-mobile.png'
								alt='Баннер'
							/>
						</div>
					</header>
				))}
			{status === 'waiting' ? <FullPayment statusPayment={statusPayment} clientWithoutCours={clientWithoutCours}/> : <Done clientWithoutCours={clientWithoutCours}/>}
		</>
	);
})

export default Payment;
