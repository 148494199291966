import cn from 'classnames';
import s from '../steps.module.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import {
	setAddData,
	setAddStep,
} from '../../../../../store/reducer/lk/formExam/slice';

import { selectLkFormExam } from '../../../../../store/reducer/lk/formExam/selector';
import Button from '../../Button/Button';
import Form from '../../Form';

const Step14 = () => {
	const [width, setWidth] = useState(window?.innerWidth);
	const dispatch = useDispatch();
	const { data, step } = useSelector(selectLkFormExam);
	const [isSendForm, setIsSendForm] = useState(false);

	useEffect(() => {
		localStorage.setItem('stepLkExam', JSON.stringify(step));
		localStorage.setItem('dataExam', JSON.stringify(data));
	}, []);

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

	const schema38= yup
		.object({
			['Закрепляются ли объявления  на желаемой позиции показа?']: yup.string(),
            ['Где рекомендовано смотреть среднюю позицию показа объявления?']: yup.string(),
            ['Где смотреть всю рекламную статистику?']: yup.string(),
		})
		.required();

	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
		watch,
	} = useForm({
		resolver: yupResolver(schema38),
		defaultValues: {
			['Закрепляются ли объявления  на желаемой позиции показа?']: data['Закрепляются ли объявления  на желаемой позиции показа?'],
			['Где рекомендовано смотреть среднюю позицию показа объявления?']: data['Где рекомендовано смотреть среднюю позицию показа объявления?'],
			['Где смотреть всю рекламную статистику?']: data['Где смотреть всю рекламную статистику?'],
		},
	});

	const onSubmit = data => {
		dispatch(setAddStep(15));
		dispatch(setAddData(data));
		localStorage.setItem('dataExam', JSON.stringify(data));
		localStorage.setItem('stepLkExam', JSON.stringify(14));
		
	};

	return (
		<div className={s.step14}>
      <div className={s.wrapper_heading}>
			  <h5>Реклама</h5>
			</div>
			<Form onSubmit={handleSubmit(onSubmit)}>

				<div className={s.form_item}>
					<h4 className={s.form_title}>38. Закрепляются ли объявления  на желаемой позиции показа?</h4>

					<div className={s.question38}>
						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question38,
								})}
								{...register('Закрепляются ли объявления  на желаемой позиции показа?')}
								id='exam381'
								type='radio'
								name='Закрепляются ли объявления  на желаемой позиции показа?'
								autoComplete='off'
								value='Да, объявления закрепляются на желаемой позиции по запросу'
							/>

							<label htmlFor='exam381' className={s.label}>
							Да, объявления закрепляются на желаемой позиции по запросу
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question38,
								})}
								{...register('Закрепляются ли объявления  на желаемой позиции показа?')}
								id='exam382'
								type='radio'
								name='Закрепляются ли объявления  на желаемой позиции показа?'
								autoComplete='off'
								value='Нет, объявление не закрепляется на определенной позиции, они всегда плавающие'
							/>

							<label htmlFor='exam382' className={s.label}>
							Нет, объявление не закрепляется на определенной позиции, они всегда плавающие
							</label>
						</div>
					</div>

					<p className={s.errorText}>
						{errors['Закрепляются ли объявления  на желаемой позиции показа?'] ? 'Необходимо указать' : ''}
					</p>
				</div>

				<div className={s.form_item}>
					<h4 className={s.form_title}>39. Где рекомендовано смотреть среднюю позицию показа объявления?</h4>

					<div className={s.question39}>
                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question39,
								})}
								{...register('Где рекомендовано смотреть среднюю позицию показа объявления?')}
								id='exam391'
								type='radio'
								name='Где рекомендовано смотреть среднюю позицию показа объявления?'
								autoComplete='off'
								value='В Яндекс через поисковой запрос'
							/>
							<label htmlFor='exam391' className={s.label}>
							В Яндекс через поисковой запрос
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question39,
								})}
								{...register('Где рекомендовано смотреть среднюю позицию показа объявления?')}
								id='exam392'
								type='radio'
								name='Где рекомендовано смотреть среднюю позицию показа объявления?'
								autoComplete='off'
								value='CRM - Директор - Итоги - Рекламная статистика'
							/>
							<label htmlFor='exam392' className={s.label}>
							CRM - Директор - Итоги - Рекламная статистика
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question39,
								})}
								{...register('Где рекомендовано смотреть среднюю позицию показа объявления?')}
								id='exam393'
								type='radio'
								name='Где рекомендовано смотреть среднюю позицию показа объявления?'
								autoComplete='off'
								value='Спрашивать в поддержке'
							/>
							<label htmlFor='exam393' className={s.label}>
							Спрашивать в поддержке
							</label>
						</div>
					</div>

					<p className={s.errorText}>
						{errors['Где рекомендовано смотреть среднюю позицию показа объявления?'] ? 'Необходимо указать' : ''}
					</p>
				</div>

                <div className={s.form_item}>
					<h4 className={s.form_title}>40. Где смотреть всю рекламную статистику?</h4>

					<div className={s.question40}>
                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question40,
								})}
								{...register('Где смотреть всю рекламную статистику?')}
								id='exam401'
								type='radio'
								name='Где смотреть всю рекламную статистику?'
								autoComplete='off'
								value='У меня будет доступ в аккаунт  Яндекс'
							/>
							<label htmlFor='exam401' className={s.label}>
							У меня будет доступ в аккаунт  Яндекс
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question40,
								})}
								{...register('Где смотреть всю рекламную статистику?')}
								id='exam402'
								type='radio'
								name='Где смотреть всю рекламную статистику?'
								autoComplete='off'
								value='CRM - Директор - Итоги - Рекламная статистика'
							/>
							<label htmlFor='exam402' className={s.label}>
							CRM - Директор - Итоги - Рекламная статистика
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question40,
								})}
								{...register('Где смотреть всю рекламную статистику?')}
								id='exam403'
								type='radio'
								name='Где смотреть всю рекламную статистику?'
								autoComplete='off'
								value='Мне не надо смотреть статистику, Skilla все контролирует'
							/>
							<label htmlFor='exam403' className={s.label}>
							Мне не надо смотреть статистику, Skilla все контролирует
							</label>
						</div>
					</div>

					<p className={s.errorText}>
						{errors['Где смотреть всю рекламную статистику?'] ? 'Необходимо указать' : ''}
					</p>
				</div>

				<div className={s.btns}>
        <Button callback={() => dispatch(setAddStep(13))} back>
						{width > 575 ? (
							'   Назад'
						) : (
							<img src='/static/lk/IconBackward.svg' alt='назад' />
						)}
					</Button>
					<Button>Далее 14 из 35</Button>
				</div>
			</Form>

			<img
				className={s.illustration}
				src='/static/lk/illustration/illustration.png'
				alt='Иллюстрация'
			/>
		</div>
	);
};

export default Step14;
