import cn from 'classnames';
import s from '../steps.module.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import {
	setAddData,
	setAddStep,
} from '../../../../../store/reducer/lk/formExam/slice';

import { selectLkFormExam } from '../../../../../store/reducer/lk/formExam/selector';
import Button from '../../Button/Button';
import Form from '../../Form';

const Step20 = () => {
	const [width, setWidth] = useState(window?.innerWidth);
	const dispatch = useDispatch();
	const { data, step } = useSelector(selectLkFormExam);
	const [isSendForm, setIsSendForm] = useState(false);

	useEffect(() => {
		localStorage.setItem('stepLkExam', JSON.stringify(step));
		localStorage.setItem('dataExam', JSON.stringify(data));
	}, []);

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

	const schema = yup
		.object({
			['Подписанными оригиналами каких документов обязательно необходимо обменяться с заказчиком?']: yup.string(),
            ['Какой сотрудник закрывает заказ в системе по факту его завершения?']: yup.string(),
            ['Что делать с транспортом для переезда?']: yup.string(),
		})
		.required();

	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
		watch,
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			['Подписанными оригиналами каких документов обязательно необходимо обменяться с заказчиком?']: data['Подписанными оригиналами каких документов обязательно необходимо обменяться с заказчиком?'],
			['Какой сотрудник закрывает заказ в системе по факту его завершения?']: data['Какой сотрудник закрывает заказ в системе по факту его завершения?'],
			['Что делать с транспортом для переезда?']: data['Что делать с транспортом для переезда?'],
		},
	});


	const onSubmit = data => {
		setIsSendForm(true);

		
			dispatch(setAddStep(21));
			dispatch(setAddData(data));
			setIsSendForm(false);

			localStorage.setItem('dataExam', JSON.stringify(data));
			localStorage.setItem('stepLkExam', JSON.stringify(20));
		
	};

	return (
		<div className={s.step20}>
      <div className={s.wrapper_heading}>
			  <h5>Клиенты. Менеджер/Бухгалтер</h5>
			</div>
			<Form onSubmit={handleSubmit(onSubmit)}>

				<div className={s.form_item}>
					<h4 className={s.form_title}>55. Подписанными оригиналами каких документов обязательно необходимо обменяться с заказчиком?</h4>

					<div className={s.question55}>
                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question55,
								})}
								{...register('Подписанными оригиналами каких документов обязательно необходимо обменяться с заказчиком?')}
								id='exam551'
								type='radio'
								name='Подписанными оригиналами каких документов обязательно необходимо обменяться с заказчиком?'
								autoComplete='off'
								value='Договор, счет, закрывающие (акт, с/ф)'
							/>
							<label htmlFor='exam551' className={s.label}>
							Договор, счет, закрывающие (акт, с/ф)
							</label>
						</div>

						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question55,
								})}
								{...register('Подписанными оригиналами каких документов обязательно необходимо обменяться с заказчиком?')}
								id='exam552'
								type='radio'
								name='Подписанными оригиналами каких документов обязательно необходимо обменяться с заказчиком?'
								autoComplete='off'
								value='Счет, закрывающие (акт, с/ф)'
							/>
							<label htmlFor='exam552' className={s.label}>
							Счет, закрывающие (акт, с/ф)
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question55,
								})}
								{...register('Подписанными оригиналами каких документов обязательно необходимо обменяться с заказчиком?')}
								id='exam553'
								type='radio'
								name='Подписанными оригиналами каких документов обязательно необходимо обменяться с заказчиком?'
								autoComplete='off'
								value='Договор, закрывающие (акт, с/ф)'
							/>
							<label htmlFor='exam553' className={s.label}>
							Договор, закрывающие (акт, с/ф)
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question55,
								})}
								{...register('Подписанными оригиналами каких документов обязательно необходимо обменяться с заказчиком?')}
								id='exam554'
								type='radio'
								name='Подписанными оригиналами каких документов обязательно необходимо обменяться с заказчиком?'
								autoComplete='off'
								value='Договор, счет, детализация'
							/>
							<label htmlFor='exam554' className={s.label}>
							Договор, счет, детализация
							</label>
						</div>
					</div>

					<p className={s.errorText}>
						{errors['Подписанными оригиналами каких документов обязательно необходимо обменяться с заказчиком?'] ? 'Необходимо указать' : ''}
					</p>
				</div>

                <div className={s.form_item}>
					<h4 className={s.form_title}>56. Какой сотрудник закрывает заказ в системе по факту его завершения?</h4>

					<div className={s.question56}>

						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question56,
								})}
								{...register('Какой сотрудник закрывает заказ в системе по факту его завершения?')}
								id='exam562'
								type='radio'
								name='Какой сотрудник закрывает заказ в системе по факту его завершения?'
								autoComplete='off'
								value='Менеджер по персоналу'
							/>
							<label htmlFor='exam562' className={s.label}>
							Менеджер по персоналу
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question56,
								})}
								{...register('Какой сотрудник закрывает заказ в системе по факту его завершения?')}
								id='exam563'
								type='radio'
								name='Какой сотрудник закрывает заказ в системе по факту его завершения?'
								autoComplete='off'
								value='Директор'
							/>
							<label htmlFor='exam563' className={s.label}>
							Директор
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question56,
								})}
								{...register('Какой сотрудник закрывает заказ в системе по факту его завершения?')}
								id='exam564'
								type='radio'
								name='Какой сотрудник закрывает заказ в системе по факту его завершения?'
								autoComplete='off'
								value='Бухгалтер'
							/>
							<label htmlFor='exam564' className={s.label}>
							Бухгалтер
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question56,
								})}
								{...register('Какой сотрудник закрывает заказ в системе по факту его завершения?')}
								id='exam565'
								type='radio'
								name='Какой сотрудник закрывает заказ в системе по факту его завершения?'
								autoComplete='off'
								value='Любой из вышеперечисленных'
							/>
							<label htmlFor='exam565' className={s.label}>
							Любой из вышеперечисленных
							</label>
						</div>
					</div>

					<p className={s.errorText}>
						{errors['Какой сотрудник закрывает заказ в системе по факту его завершения?'] ? 'Необходимо указать' : ''}
					</p>
				</div>

                <div className={s.form_item}>
					<h4 className={s.form_title}>57. Что делать с транспортом для переезда?</h4>

					<div className={s.question57}>
                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question57,
								})}
								{...register('Что делать с транспортом для переезда?')}
								id='exam571'
								type='radio'
								name='Что делать с транспортом для переезда?'
								autoComplete='off'
								value='Иметь собственный автопарк'
							/>
							<label htmlFor='exam571' className={s.label}>
							Иметь собственный автопарк
							</label>
						</div>

						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question57,
								})}
								{...register('Что делать с транспортом для переезда?')}
								id='exam572'
								type='radio'
								name='Что делать с транспортом для переезда?'
								autoComplete='off'
								value='Заключить договор с транспортной компанией'
							/>
							<label htmlFor='exam572' className={s.label}>
							Заключить договор с транспортной компанией
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question57,
								})}
								{...register('Что делать с транспортом для переезда?')}
								id='exam573'
								type='radio'
								name='Что делать с транспортом для переезда?'
								autoComplete='off'
								value='Договориться с частниками'
							/>
							<label htmlFor='exam573' className={s.label}>
							Договориться с частниками
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question57,
								})}
								{...register('Что делать с транспортом для переезда?')}
								id='exam574'
								type='radio'
								name='Что делать с транспортом для переезда?'
								autoComplete='off'
								value='Транспорт не нужен, буду предоставлять только исполнителей'
							/>
							<label htmlFor='exam574' className={s.label}>
							Транспорт не нужен, буду предоставлять только исполнителей
							</label>
						</div>
					</div>

					<p className={s.errorText}>
						{errors['Что делать с транспортом для переезда?'] ? 'Необходимо указать' : ''}
					</p>
				</div>

				<div className={s.btns}>
                    <Button callback={() => dispatch(setAddStep(19))} back>
						{width > 575 ? (
							'   Назад'
						) : (
							<img src='/static/lk/IconBackward.svg' alt='назад' />
						)}
					</Button>
					<Button>Далее 20 из 35</Button>
				</div>
			</Form>

			<img
				className={s.illustration}
				src='/static/lk/illustration/illustration.png'
				alt='Иллюстрация'
			/>
		</div>
	);
};

export default Step20;
