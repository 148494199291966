import { useParams, Link } from 'react-router-dom'
import { getArticlePartners, getListAll } from '../../Api'
import { useState, memo, useEffect } from 'react'
import styled from 'styled-components'
import parse from 'html-react-parser'
import { Helmet } from 'react-helmet-async'

import Slider from 'react-slick'
import ArticlesForm from '../../components/articleForm/ArticlesForm'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import ArticleBtns from '../../components/articleBtns/ArticleBtns'
import Chart from '../../components/Chart'
import SliderArticle from '../../components/sliderArticle/SliderArticle'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './articles.scss'

const ArticlesPartner = memo(function ArticlesPartner({ activeLink, formSendBusiness }) {
  const { alias } = useParams()
  const [data, setData] = useState({})
  const [content, setContent] = useState('')
  const [partnerInfo, setPartnerInfo] = useState({})
  const [otherPartners, setOtherPartners] = useState([])
  const [index, setIndex] = useState(2)
  const [width, setWidth] = useState(window?.innerWidth)

  const {
    name,
    main_photo,
    main_photo_mobile,
    subname,
    case_partner_city,
    business_title,
    business_subtitle,
    title,
  } = data

  useEffect(() => {
    getListAll('partner_case')
      .then((response) => {
        setOtherPartners(response.data.results)
      })
      .catch((e) => {
        console.log(e)
      })

    window.scroll(0, 0)
  }, [])

  useEffect(() => {
    getArticlePartners('partners', alias).then(({ data }) => {
      setData(data.article)
      setContent(data.article.content)
      setPartnerInfo(data.article.partner_data)
    })
    window.scroll(0, 0)
  }, [alias, index, otherPartners])

  const nextArticle = () => {
    if (otherPartners.length !== index + 1) {
      setIndex((prev) => prev + 1)
    } else {
      setIndex(0)
    }
  }

  useEffect(() => {
    const cb = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', cb)
    return () => window.removeEventListener('resize', cb)
  }, [])

  const { inn, address, age } = partnerInfo

  const settings = {
    className: 'article-slider',
    infinite: true,
    variableWidth: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          variableWidth: true,
          infinite: true,
          speed: 300,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        },
      },
    ],
  }

  console.log('content', content)

  return (
    <div className="articles">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={business_title} />
      </Helmet>
      <Navbar page="business" type="article" activePage="article" />

      <div className="articles__header header-articles">
        <div style={{ backgroundColor: '#000' }}>
          {width > 768 && <img className="articles__header-background" src={main_photo} alt={name} />}
          {width <= 768 && <img className="articles__header-background" src={main_photo_mobile} alt={name} />}
        </div>

        <div className="container">
          <div className="header-articles__intro partners">
            <h1 className="title-header-h1">{name}</h1>
            {formSendBusiness && width <= 768 && (
              <StyledForm>
                <ArticlesForm
                  page="partners"
                  title={business_title}
                  subtitle={business_subtitle}
                  activeLink={activeLink}
                />
              </StyledForm>
            )}
            <h2 className="title-header-h2">{subname}</h2>

            {data.services && data.services.length > 0 ? (
              <ArticleBtns type={'header'} keys={'partners'} />
            ) : null}
          </div>
        </div>
      </div>

      <StyledContainer>
        <div className="articles__content">
          {formSendBusiness && width > 768 && (
            <StyledForm>
              <ArticlesForm
                page="partners"
                title={business_title}
                subtitle={business_subtitle}
                activeLink={activeLink}
              />
            </StyledForm>
          )}
          <div className="articles__content-padding" style={{ paddingTop: '40px' }}>
            {parse(content)}
          </div>

          <div className="articles__content-graph">
            {data.partner_graph && data.partner_graph[0].revenue > 0 ? (
              <Chart page="article" dataPartner={data.partner_graph} />
            ) : null}
          </div>

          <div className="articles__partner-info info-partner">
            <h3 className="info-partner__name">{name}</h3>

            <p className="info-partner__age">
              Возраст: {age}; {case_partner_city}
            </p>
            <div className="info-partner__company company-partner">
              <p className="company-partner__company-name">{partnerInfo.name}</p>
              <p className="company-partner__inn">ИНН {inn}</p>
              <p className="company-partner__address">{address}</p>
            </div>
          </div>
        </div>

        <div className="articles__static">
          {data.services && data.services.length > 0 ? <ArticleBtns type={'footer'} keys="partners" /> : null}

          <StyledForm>
            <ArticlesForm
              page="partners"
              type={'footer'}
              title={business_title}
              subtitle={business_subtitle}
              activeLink={activeLink}
            />
          </StyledForm>

          <div className="article__next-article article-next">
            <h3 className="article-next__title">Следующая статья</h3>

            {otherPartners[index] && (
              <div className="article-next__wrapper">
                <img
                  className="article-next__banner partners"
                  src={width > 768 ? otherPartners[index].main_photo : otherPartners[index].main_photo_mobile}
                  alt="Слудующая статья"
                />
                <div className="article-next__name">{otherPartners[index].name}</div>
                <div className="article-next__descr">{otherPartners[index].case_partner_slogan}</div>

                <Link
                  onClick={nextArticle}
                  to={`/${otherPartners[index].alias}`}
                  className="article-next__wrapper-btn"
                >
                  <span>Читать</span>

                  <img src="/static/article/icon/arrow-left-black.svg" alt="" />
                </Link>
              </div>
            )}
          </div>

          <div className="article__other-articles articles-other">
            <h2 className="articles-other__title">Читайте другие истории успеха</h2>

            <Slider {...settings}>
              {otherPartners.map((item) => (
                <SliderArticle key={item.alias} {...item} type="partners" />
              ))}
            </Slider>
          </div>
        </div>
      </StyledContainer>
      <Footer page="business" type="faq" />
    </div>
  )
})

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  .slick-next,
  .slick-prev {
    width: 40px;
    height: 40px;

    border-radius: 300px;
    background-color: #fff !important;
    top: 30%;
    z-index: 1;

    &:hover {
      background-color: #00cc6e !important;
      border: none;
    }
  }

  .slick-prev {
    @media (max-width: 768px) {
      left: 70px;
    }
  }

  .slick-next {
    @media (max-width: 768px) {
      left: 270px;
    }
  }

  .slick-arrow {
    background-color: #f9f9f9;
    transition: all 0.3s ease;
    border: 1px solid #0f1324;

    @media (max-width: 768px) {
      position: absolute;
      top: -38px;
    }
    }
  }

  .slick-slider {
    @media (max-width: 768px) {
      top: 50px;
    }
  }


  .slick-prev::before {
    content: url('/static/article/icon/arrow-slide-left.svg');

    display: block;
  }

  .slick-prev:hover::before {
    content: url('/static/article/icon/arrow-slide-left-hover.svg');
  }

  .slick-next::before {
    content: url('/static/article/icon/arrow-slide.svg');

    display: block;
  }

  .slick-next:hover::before {
    content: url('/static/article/icon/arrow-slide-hover.svg');
  }

  .recharts-cartesian-axis-tick {
    font-size: 10px;
    font-weight: 500;
    margin-top: 8.5px;
  }

  .recharts-wrapper {
    margin: 37px auto 13px;

    @media (max-width: 768px) {
      right: 25px;
    }
  }

  .article-info {
    display: flex;
    justify-content: space-between;
    align-items: center;

    max-width: 865px;
    margin: 0 auto;
    &__text {
      color: #4d5268;
      font-style: italic;
      font-weight: 400;
      font-size: 14px;
    }
    &__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        color: ##4d5268;
        margin-right: 10px;
        font-style: italic;
        font-size: 14px;
        font-weight: 400;
        font-size: 14px;
      }
    }
    &__img {
      width: 70px !important;
      margin: 0 12px 0 0 !important;
    }

    @media (max-width: 1600px) {
      margin-bottom: 15px;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: baseline;
      gap: 5px;
    }
  }

  .articles__content {
    width: 100%;

    img {
      display: flex;
    }
    &-graph {
      width: 1400px;
      margin: 0 auto;

      @media (max-width: 1600px) {
        max-width: 1200px;
      }

      @media (max-width: 768px) {
        max-width: initial;
        width: initial;
      }
    }
  }

  .articles__static {
    max-width: 1400px;

  }

  .chart-tooltip {
    width: 169px;
    border-radius: 4px;
    background-color: #fff;
    padding: 4px 8px 8px 8px;
    color: #000;
    &__title {
      font-weight: 700;
    }
    &__revenue {
      color: #00cc6e;
      font-size: 20px;
      font-weight: 400;
      span {
        color: #000729;
        margin-top: 12px;
        font-size: 14px;
      }
    }
  }

  .articles__content-padding {
    @media (max-width: 768px) {
      padding-top: 600px !important;
    }
  }

  .slick-track {
    display: flex;
}


  .tabs__content_flex {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 25px;
  }

  .modal-video__video {
    height: 255px;
  }

  .repeat-wrapper {
    max-width: 205px;
    display: flex;
    align-items: baseline;
    &:hover {
      .repeat {
        color: #4d6a4d !important;
      }
      .repeat__icon {
        path {
          fill: #4d6a4d;
        }
      }
    }

    @media (max-width: 768px) {
      margin-left: 16px;
      padding-bottom: 16px;
    }
  }

  .repeat {
    font-weight: 600;
    color: #000  !important;
  }

  .repeat__icon {
    path {
      fill: #000 !important;
    }
  }

  .articles-other__title {
    @media(max-width: 1600px) {
      margin: 16px 0 !important;
    }
    @media (max-width: 768px) {
      width: 343px;
      margin: 16px 0 !important;
      text-align: center;
    }
  }
`

const StyledForm = styled.div`
  @media screen and (max-width: 495px) {
    .modal__title {
      font-size: 24px;
      margin-bottom: 12px;
    }

    .modal__text {
      font-size: 16px;
      padding: 0 10px;
    }

    .modal__question {
      margin-top: 30px;
    }

    .modal__question-text {
      font-size: 16px;
      font-weight: 500;
      padding-left: 16px;
    }

    .header-form__labels {
      padding-left: 0;
    }

    .modal--- .header-form__labels {
      padding-left: 16px;
    }

    .modal__content,
    .modal-video,
    .modal-video__content {
      max-width: 343px;
      width: 100%;
    }

    .modal-video__content {
      text-align: center;
      color: #303550;
    }

    .modal__input_step2 {
      margin: 55px 0 70px 0;
    }
  }

  .modal--- {
    position: fixed;
    z-index: 20;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 7, 41, 0.4);
  }

  .modal__content {
    width: 688px;
    background-color: #fff;
    color: #fff;
    position: relative;
    text-align: center;
  }

  .modal__cross {
    position: absolute;
    right: 24px;
    top: 24px;
  }

  .modal__button {
    cursor: pointer;
    padding: 0 50px;
    background-color: #00cc6e;
    color: #fff;
    border: 0;
    border-radius: 4px;
    font-size: 18px;
    line-height: 48px;
    font-weight: 500;
    font-family: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: auto;
    transition: background-color 0.3s ease;

    &::after {
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      margin-left: 20px;
      background: url(data:image/webp;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAQCAYAAADwMZRfAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABQSURBVHgB7ZSxCQAgDASDE7iBbupIjuAIjuAo74tgKRLFKgcPaf6KQCKyAUBkChNEC8sZk6YWseiZaiKFiEOCnjZETn5zvRcTLMmTAzx6BR1sCyV6CTVkTAAAAABJRU5ErkJggg==)
        no-repeat center top / cover;
    }

    &:hover {
      background-color: #08b766;
    }
  }

  .modal__title {
    color: #303550;
    font-size: 38px;
    line-height: 120%;
    font-weight: 800;
    max-width: 450px;
    margin: auto;
    margin-top: 64px;
    margin-bottom: 16px;
  }

  .modal__text {
    font-size: 18px;
    line-height: 120%;
    color: #303550;
    max-width: 450px;
    margin: auto;
  }

  .modal__input {
    width: 300px;
    height: 100%;
    background: #f9f9f9;
    border: 1px solid #e3e4e8;
    border-radius: 4px;
    padding: 10.5px 13px;
    font-family: inherit;
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;
    color: #abadba;

    &::placeholder {
      font-family: inherit;
      color: #abadba;
    }
  }

  .modal__input_step1 {
    margin-top: 56px;
    margin-bottom: 15px;
  }

  .modal__input_step2 {
    margin: 55px 0 135px 0;
  }

  .modal__input_menu {
    display: none;
    height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    position: absolute;
    bottom: -200px;
    cursor: pointer;
    z-index: 10;

    &.active {
      display: block;
    }
  }

  .modal__input_city_step1 {
    margin-bottom: 70px !important;
  }

  .modal__input_city {
    margin: auto;
    position: relative;
    width: 300px;

    &:focus-within .modal__input_menu {
      display: block;
    }

    &::after {
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      position: absolute;
      right: 12px;
      top: 0;
      bottom: 0;
      margin: auto;
      background: url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.5 9L12 15L17.5 9H6.5Z' fill='%23ABADBA'/%3e%3c/svg%3e ")
        no-repeat center top / cover;
      cursor: pointer;
    }
  }

  .modal__input_city-item {
    padding: 10.5px 13px;
    background-color: #262a40;
    font-size: 18px;
    color: rgba(171, 173, 186, 1);
    font-weight: 500;
    text-align: left;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #44485c;
    }
  }

  .modal__question {
    max-width: 450px;
    margin: auto;
    margin-top: 45px;

    & .header-form__checkbox::before {
      background-color: transparent;
      cursor: pointer;
    }

    & .header-form__checkbox:checked::before {
      border: 1px solid #e3e4e8;
      background: url("data:image/svg+xml;charset=UTF-8,%3csvg width='10' height='10' viewBox='0 0 6 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='3' cy='3.5' r='3' fill='%2300FF88'/%3e%3c/svg%3e ")
        no-repeat center !important;
      background-position: 4px 3.5px;
    }
  }

  .modal__input_step3 {
    width: 100%;
    resize: none;
    height: 105px;
    margin-bottom: 55px;
    outline: none;

    @media (max-width: 768px) {
      max-width: 343px;
      width: 100%;
    }
  }

  .modal__button_step3::after {
    display: none;
  }

  .modal__question-text {
    font-weight: 500;
    font-size: 22px;
    line-height: 120%;
    margin-bottom: 17px;
    text-align: left;
    color: #303550;
  }

  .modal__progress {
    margin-top: 52px;
    display: flex;
    width: 100%;
    height: 6px;
    background-color: #abadba;

    & span {
      display: block;
      width: 25%;
      height: 6px;
      background-color: #00cc6e;
    }
  }

  .modal__back {
    position: absolute;
    left: 24px;
    top: 24px;
  }

  .modal-video {
    width: 450px;
    margin: 40px auto 55px auto;
  }

  .modal-video__content {
    text-align: left;
    color: #303550;
  }

  .modal-video__title {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 4px;
    font-weight: 700;
    margin-top: 20px;
  }

  .modal-video__description {
    font-size: 16px;
    line-height: 20px;
    color: #303550;
  }

  .articlesForm-footer__descr {
    @media (max-width: 768px) {
      margin: 0;
    }
  }
`

export default ArticlesPartner
