import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { selectFormUbscribe } from '../store/reducer/formUnsubscribe/selector'

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import FormUnsubscribe from '../components/FormUnsubscribe'
import Form from '../components/Form'

function Unsubscribe() {
  const [width, setWidth] = useState(window?.innerWidth)
  const { textarea, doNotUnsubscribe, unsubscribe } = useSelector(selectFormUbscribe)

  useEffect(() => {
    const cb = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', cb)
    return () => window.removeEventListener('resize', cb)
  }, [])

  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  return (
    <StyledUnsubscribe className="unsubscribe">
      <Helmet>
        <title>Отписаться от рассылки – Скилла</title>
        <meta
          name="description"
          content="На этой странице можно отписаться от нашей крутой рассылки. Хотя, конечно, лучше не надо"
        />
        <meta name="keywords" content="skilla, рассылка, бизнес" />
      </Helmet>
      <Navbar page="business" type="unsubscribe" activePage="unsubscribe" />

      <header className={textarea ? 'header header-mt' : 'header'}>
        {width > 768 && (
          <div className="container">
            <div className="header__inner">
              {!doNotUnsubscribe && !unsubscribe && (
                <div className={textarea ? 'header__content textarea' : 'header__content'}>
                  {width > 768 ? (
                    <h1 className="header__title">
                      Неужели отпишетесь <br></br> от нашей крутой рассылки?
                    </h1>
                  ) : (
                    <h1 className="header__title">Неужели отпишетесь от нашей крутой рассылки?</h1>
                  )}

                  <p className="description-unsubscribe">Расскажете почему? Расставаться всегда грустно :(</p>

                  <div className={width > 768 ? 'container' : 'container-full'}>
                    {!doNotUnsubscribe && !unsubscribe && <FormUnsubscribe textarea={textarea} />}
                  </div>
                </div>
              )}

              {doNotUnsubscribe && (
                <div className="header__content good">
                  {width > 768 && (
                    <h1 className="header__title">
                      Мы рады, что вы решили <br></br> остаться с нами!
                    </h1>
                  )}

                  {width <= 768 && <h1 className="header__title">Мы рады, что вы решили остаться с нами!</h1>}

                  <p className="description-unsubscribe good">Станьте частью большой семьи Скилла</p>

                  {doNotUnsubscribe && !unsubscribe && <Form page="business" type="unsubscribe" />}
                </div>
              )}

              {unsubscribe && (
                <div className="header__content unsubscribe">
                  <h1 className="header__title">Вы успешно отписались от рассылки</h1>

                  <p className="description-unsubscribe unsubscribe">
                    Но если передумаете — мы всегда рядом!
                  </p>
                </div>
              )}

              <div className="header__right">
                {!doNotUnsubscribe && !unsubscribe && (
                  <picture>
                    <source srcSet="/static/business/unsubscribe/sad-persons.webp" type="image/webp" />
                    <img
                      className={!textarea ? 'header__img' : 'header__img bottom'}
                      src="/static/business/unsubscribe/sad-persons.png"
                      alt="иллюстрация"
                    />
                  </picture>
                )}

                {doNotUnsubscribe && !unsubscribe && (
                  <picture>
                    <source srcSet="/static/business/unsubscribe/cheerful-persons.webp" type="image/webp" />
                    <img
                      className={doNotUnsubscribe ? 'header__img good' : 'header__img'}
                      src="/static/business/unsubscribe/cheerful-persons.png"
                      alt="иллюстрация"
                    />
                  </picture>
                )}

                {unsubscribe && (
                  <picture>
                    <source srcSet="/static/business/unsubscribe/illustration.webp" type="image/webp" />
                    <img
                      className="header__img"
                      src="/static/business/unsubscribe/illustration.png"
                      alt="иллюстрация"
                    />
                  </picture>
                )}
              </div>
            </div>
          </div>
        )}

        {width <= 768 && (
          <>
            {!doNotUnsubscribe && !unsubscribe && (
              <>
                <div className="container">
                  <div className={textarea ? 'header__content textarea' : 'header__content'}>
                    <h1 className="header__title">Неужели отпишетесь от нашей крутой рассылки?</h1>

                    <p className="description-unsubscribe">
                      Расскажете почему? Расставаться всегда грустно :(
                    </p>
                  </div>
                </div>
                {!doNotUnsubscribe && !unsubscribe && <FormUnsubscribe textarea={textarea} />}
              </>
            )}

            {doNotUnsubscribe && (
              <>
                <div className="container">
                  <div className="header__content good">
                    <h1 className="header__title">
                      Мы рады, что вы решили <br></br> остаться с нами!
                    </h1>

                    <p className="description-unsubscribe good">Стань частью большой семьи Скилла</p>
                  </div>
                </div>
                {doNotUnsubscribe && !unsubscribe && <Form page="business" type="unsubscribe" />}
              </>
            )}

            {unsubscribe && (
              <div className="container">
                <div className="header__content unsubscribe">
                  <h1 className="header__title">Вы успешно отписались от рассылки</h1>

                  <p className="description-unsubscribe unsubscribe">
                    Но если передумаете — мы всегда рядом!
                  </p>
                </div>
              </div>
            )}

            {!doNotUnsubscribe && !unsubscribe && (
              <picture>
                <source srcSet="/static/business/unsubscribe/sad-persons-mobile.webp" type="image/webp" />
                <img
                  className="header__img"
                  src="/static/business/unsubscribe/sad-persons-mobile.png"
                  alt="иллюстрация"
                />
              </picture>
            )}

            {doNotUnsubscribe && !unsubscribe && (
              <picture>
                <source
                  srcSet="/static/business/unsubscribe/cheerful-persons-mobile.webp"
                  type="image/webp"
                />
                <img
                  className={doNotUnsubscribe ? 'header__img doNotUnsubscribe' : 'header__img'}
                  src="/static/business/unsubscribe/cheerful-persons-mobile.png"
                  alt="иллюстрация"
                />
              </picture>
            )}

            {unsubscribe && (
              <picture>
                <source srcSet="/static/business/unsubscribe/illustration-mobile.webp" type="image/webp" />
                <img
                  className="header__img unsubscribe"
                  src="/static/business/unsubscribe/illustration-mobile.png"
                  alt="иллюстрация"
                />
              </picture>
            )}
          </>
        )}
      </header>

      <Footer page="business" type="faq" title="unsubscribe" />
    </StyledUnsubscribe>
  )
}

const StyledUnsubscribe = styled.div`
  .header {
    position: relative;
    padding-bottom: 50px;

    &-mt {
      padding-bottom: 100px;

      @media (max-width: 768px) {
        padding-bottom: 0;
      }
    }

    background: radial-gradient(146.1% 692.06% at 98.79% 0%, rgba(0, 80, 41, 0.81) 0%, rgba(0, 7, 41, 0) 100%),
      #000729;

    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.15);
    &:before {
      content: url('/static/business/lents/lent-19.png');
      display: block;
      left: 0;
      bottom: -45px;
      width: 100%;
      position: absolute;

      text-align: center;
      z-index: 11;
      @media (max-width: 768px) {
        bottom: -35px;
      }
    }

    @media (max-width: 1600px) {
      padding-bottom: 125px;
    }
    @media (max-width: 768px) {
      padding-bottom: initial;
    }
  }

  .header__inner {
    display: flex;
    justify-content: space-between;
  }

  .header__content {
    width: 26.5%;
    padding: 200px 0 45px;
    &.good {
      width: 32%;
      @media (max-width: 768px) {
        margin-bottom: 32px;
        width: 100%;
      }
    }

    &.unsubscribe {
      padding: 377px 0 0;
      @media (max-width: 768px) {
        padding: 170px 0 0;
        margin-bottom: 190px;
      }
    }

    @media (max-width: 1600px) {
      padding: 170px 0 45px;
      width: initial;
    }

    @media (max-width: 768px) {
      padding: 170px 0 0;
      margin-bottom: 50px;
      width: 100%;
    }
  }

  .header__title {
    width: 807px;
    line-height: 130%;

    color: #fff;
    font-weight: 800;
    font-size: 54px;

    @media (max-width: 768px) {
      max-width: 343px;
      width: 100%;
      font-weight: 800;
      font-size: 36px;
      line-height: 120%;
    }
  }

  .description-unsubscribe {
    width: 688px;
    font-weight: 400;
    font-size: 22px;
    color: #fff;
    line-height: 130%;
    margin-bottom: 54px;
    margin-top: 24px;

    &.good {
      top: 55%;
    }

    @media (max-width: 1600px) {
      margin-bottom: 12px;
      font-size: 14px;
    }

    @media (max-width: 768px) {
      position: static;
      font-size: 18px;
      margin-bottom: 32px;
      width: 100%;
    }
  }

  .header-form {
    padding: 24px;
    background: #303550;
    border-radius: 12px;
    width: max-content;
    margin: 40px 0 0 0;
    position: relative;
    z-index: 14;

    &.modalAnswer {
      background: transparent;
      padding: 0;
      position: relative;
      z-index: 14;
      @media (max-width: 768px) {
        width: 343px;
        margin: 32px auto;
      }
    }

    @media (max-width: 768px) {
      margin: 30px 0;
      width: 100%;
      padding: 20px;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
    }
  }

  .header__form-result {
    color: #fff;
    padding: 14px;
  }

  .header-form__flex {
    display: flex;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .header-form__input {
    font-size: 18px;
    display: block;
    height: 50px;
    background: rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 12.5px 13px;
    border-radius: 4px 0 0 4px;
    width: 275px;
    color: #fff;
    &.error {
      border: 1px solid red;
    }

    &.opacity {
      background: rgba(0, 0, 0, 0.2);
      border: 1px solid rgba(255, 255, 255, 0.2);
    }

    &::placeholder {
      color: #fff;
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .header-form__button {
    margin-left: -4px;
    display: flex;
    align-items: center;
    height: 50px;
    padding: 12.5px 24px;
    font-size: 18px;
    line-height: 120%;
    font-weight: 600;
    cursor: pointer;
    background-color: #00ff88;
    color: #000;
    border-radius: 4px;
    border: 0;
    transition: background-color 0.3s ease;
    font-family: inherit;

    & span {
      margin-left: 12px;
    }

    &:hover {
      background-color: #00cc6e;
    }

    @media (max-width: 768px) {
      width: 100%;
      margin-left: 0;
      margin-top: 20px;
      font-size: 16px;
      justify-content: center;
    }
  }

  .header-form__labels {
    display: flex;
    grid-gap: 32px;
    margin-top: 18px;
  }

  .header-form__label {
    font-size: 16px;
    line-height: 120%;
    color: #c7c9d1;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .header__img {
    position: relative;
    z-index: 10;

    &.bottom {
      height: 100%;
      bottom: -100px;
    }

    &.good {
      position: relative;
      right: -68px;
      bottom: -45px;

      @media (max-width: 1600px) {
        bottom: -105px;
      }

      @media (max-width: 768px) {
        position: relative;
        right: 0;
        bottom: -210px;
        width: 100%;
      }
    }

    &.doNotUnsubscribe {
      margin-top: 0;
      margin-bottom: 30px;
    }

    &.unsubscribe {
      @media (max-width: 768px) {
        position: relative;
        bottom: 22px;
        right: 0;
      }
    }

    @media (max-width: 1600px) {
      width: 1000px;
      right: 500px;
      bottom: -100px;
    }

    @media (max-width: 768px) {
      position: static;
      width: 100%;
      margin-top: -285px;
    }
  }

  .header-form__checkbox {
    margin: 0 8px 0 0;
    width: 16px;
    height: 16px;
    cursor: pointer;

    &:checked::before {
      border: 1px solid #00ff88;
      background: url("data:image/svg+xml;charset=UTF-8,%3csvg width='6' height='7' viewBox='0 0 6 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='3' cy='3.5' r='3' fill='%2300FF88'/%3e%3c/svg%3e ")
        no-repeat center #303550;
      background-position: 4px 3.5px;
    }

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: #303550;
      border: 1px solid rgba(199, 201, 209, 0.3);
      border-radius: 50%;
    }
  }

  .header__info {
    color: #786c6c99;
    padding-top: 115px;

    @media (max-width: 768px) {
      font-size: 13px;
      position: absolute;
      bottom: 20px;
      padding: 0;
      margin-left: 16px;
      z-index: 10;
    }
  }

  .modal--- {
    position: fixed;
    z-index: 60;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 7, 41, 0.4);
  }

  .modal__content {
    width: 688px;
    background-color: #303550;
    color: #fff;
    position: relative;
    text-align: center;
  }

  .modal__cross {
    position: absolute;
    right: 24px;
    top: 24px;
  }

  .modal__button {
    cursor: pointer;
    padding: 0 50px;
    background-color: #00ff88;
    color: #000729;
    border: 0;
    border-radius: 4px;
    font-size: 18px;
    line-height: 48px;
    font-weight: 500;
    font-family: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: auto;
    transition: background-color 0.3s ease;

    &::after {
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      margin-left: 20px;
      background: url("data:image/svg+xml;charset=UTF-8,%3csvg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.5 4L11.09 5.41L16.67 11H4.5V13H16.67L11.09 18.59L12.5 20L20.5 12L12.5 4Z' fill='%23000729'/%3e%3c/svg%3e ")
        no-repeat center top / cover;
    }

    &:hover {
      background-color: #00cc6e;
    }
  }

  .modal__title {
    font-size: 36px;
    line-height: 120%;
    font-weight: 800;
    max-width: 450px;
    margin: auto;
    margin-top: 64px;
    margin-bottom: 16px;
  }

  .modal__text {
    font-size: 18px;
    line-height: 120%;
    color: #e3e4e8;
    max-width: 450px;
    margin: auto;
  }

  .modal__input {
    width: 300px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    padding: 10.5px 13px;
    font-family: inherit;
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;
    color: #fff;

    &::placeholder {
      font-family: inherit;
      color: #abadba;
    }
  }

  .modal__input_step1 {
    margin-top: 56px;
    margin-bottom: 15px;
  }

  .modal__input_step2 {
    margin: 55px 0 135px 0;
  }

  .modal__input_menu {
    display: none;
    height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    position: absolute;
    bottom: -200px;
    cursor: pointer;
    z-index: 10;

    &.active {
      display: block;
    }
  }

  .modal__input_city_step1 {
    margin-bottom: 70px !important;
  }

  .modal__input_city {
    margin: auto;
    position: relative;
    width: 300px;

    &:focus-within .modal__input_menu {
      display: block;
    }

    &::after {
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      position: absolute;
      right: 12px;
      top: 0;
      bottom: 0;
      margin: auto;
      background: url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.5 9L12 15L17.5 9H6.5Z' fill='%23ABADBA'/%3e%3c/svg%3e ")
        no-repeat center top / cover;
      cursor: pointer;
    }
  }

  .modal__input_city-item {
    padding: 10.5px 13px;
    background-color: #262a40;
    font-size: 18px;
    color: rgba(171, 173, 186, 1);
    font-weight: 500;
    text-align: left;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #44485c;
    }
  }

  .modal__question {
    max-width: 450px;
    margin: auto;
    margin-top: 45px;

    & .header-form__checkbox::before {
      background-color: #303550;
    }

    & .header-form__checkbox:checked::before {
      background-color: #303550;
    }
  }

  .modal__input_step3 {
    width: 100%;
    resize: none;
    height: 105px;
    margin-bottom: 55px;
    outline: none;

    @media (max-width: 768px) {
      max-width: 343px;
      width: 100%;
    }
  }

  .modal__button_step3::after {
    display: none;
  }

  .modal__question-text {
    font-size: 22px;
    line-height: 120%;
    margin-bottom: 17px;
    text-align: left;
  }

  .modal__progress {
    margin-top: 52px;
    display: flex;
    width: 100%;
    height: 6px;
    background-color: #abadba;

    & span {
      display: block;
      width: 25%;
      height: 6px;
      background-color: #00cc6e;
    }
  }

  .modal__back {
    position: absolute;
    left: 24px;
    top: 24px;
  }

  .modal-video {
    width: 450px;
    margin: 40px auto 55px auto;
  }

  .modal-video__content {
    text-align: left;
  }

  .modal-video__title {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 4px;
    font-weight: 700;
    margin-top: 20px;
  }

  .modal-video__description {
    font-size: 16px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.7);
  }

  .modal-video__video {
    height: 255px;
  }

  @media screen and (max-width: 495px) {
    .modal__title {
      font-size: 24px;
      margin-bottom: 12px;
    }

    .modal__text {
      font-size: 16px;
      padding: 0 10px;
    }

    .modal__question {
      margin-top: 30px;
    }

    .modal__question-text {
      font-size: 16px;
      font-weight: 500;
      padding-left: 16px;
    }

    .header-form__labels {
      padding-left: 0;
    }

    .modal--- .header-form__labels {
      padding-left: 16px;
    }

    .modal__content,
    .modal-video,
    .modal-video__content {
      max-width: 343px;
      width: 100%;
    }

    .modal-video__content {
      text-align: center;
    }

    .modal__input_step2 {
      margin: 55px 0 70px 0;
    }
  }
`

export default Unsubscribe
