import cn from 'classnames';
import s from '../steps.module.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import {
	setAddData,
	setAddStep,
} from '../../../../../store/reducer/lk/formExam/slice';

import { selectLkFormExam } from '../../../../../store/reducer/lk/formExam/selector';
import Button from '../../Button/Button';
import Form from '../../Form';

const Step30 = () => {
	const [width, setWidth] = useState(window?.innerWidth);
	const dispatch = useDispatch();
	const { data, step } = useSelector(selectLkFormExam);
	const [isSendForm, setIsSendForm] = useState(false);
	const [isError85, setError85] = useState(false);

	useEffect(() => {
		localStorage.setItem('stepLkExam', JSON.stringify(step));
		localStorage.setItem('dataExam', JSON.stringify(data));
	}, []);

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);
    
	const schema = yup
		.object({
			['Сколько минимум нужно набрать исполнителей, чтобы запустить рекламу в Яндекс?']: yup.string(),
            ['За какой минимальный срок возможно запуститься при активной обратной связи с бизнес-ассистентами?']: yup.string(),
            ['Что необходимо прислать для присвоения бренда?']: yup.string(),
		})
		.required();

	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
		watch,
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			['В какой момент запускается контекстная реклама Яндекс?']: data['В какой момент запускается контекстная реклама Яндекс?'],
			['За какой минимальный срок возможно запуститься при активной обратной связи с бизнес-ассистентами?']: data['За какой минимальный срок возможно запуститься при активной обратной связи с бизнес-ассистентами?'],
			['Что необходимо прислать для присвоения бренда?']: data['Что необходимо прислать для присвоения бренда?'],
		},
	});

	const ckbox85 = watch('В какой момент запускается контекстная реклама Яндекс?');

	useEffect(() => {
		if (!ckbox85?.length) {
			setError85(true);
		} else {
			setError85(false);
		}
	}, [ckbox85]);
	
	const onSubmit = data => {
		setIsSendForm(true);

		if (!isError85) {
			dispatch(setAddStep(31));
			dispatch(setAddData(data));
			setIsSendForm(false);

			localStorage.setItem('dataExam', JSON.stringify(data));
			localStorage.setItem('stepLkExam', JSON.stringify(30));
		}
	};

	return (
		<div className={s.step30}>
      <div className={s.wrapper_heading}>
			  <h5>Запуск</h5>
			</div>
			<Form onSubmit={handleSubmit(onSubmit)}>

                <div className={s.form_item}>
					<h4 className={s.form_title}>85. В какой момент запускается контекстная реклама Яндекс?</h4>

					<div className={s.question85}>
						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question85,
								})}
								{...register('В какой момент запускается контекстная реклама Яндекс?')}
								id='exam851'
								type='checkbox'
								name='В какой момент запускается контекстная реклама Яндекс?'
								autoComplete='off'
								value='Рекламу можно запустить параллельно с привлечением исполнителей'/>
							<label htmlFor='exam851' className={s.label}>
							Рекламу можно запустить параллельно с привлечением исполнителей
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question85,
								})}
								{...register('В какой момент запускается контекстная реклама Яндекс?')}
								id='exam852'
								type='checkbox'
								name='В какой момент запускается контекстная реклама Яндекс?'
								autoComplete='off'
								value='Перед включение рекламы можно набрать минимальное количество исполнителей (5-15 человек)'/>
							<label htmlFor='exam852' className={s.label}>
						      	Перед включение рекламы можно набрать минимальное количество исполнителей (5-15 человек)
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question85,
								})}
								{...register('В какой момент запускается контекстная реклама Яндекс?')}
								id='exam853'
								type='checkbox'
								name='В какой момент запускается контекстная реклама Яндекс?'
								autoComplete='off'
								value='Для запуска необходимо минимум 30 человек'/>
							<label htmlFor='exam853' className={s.label}>
							Для запуска необходимо минимум 30 человек
							</label>
						</div>

                        <div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question85,
								})}
								{...register('В какой момент запускается контекстная реклама Яндекс?')}
								id='exam854'
								type='checkbox'
								name='В какой момент запускается контекстная реклама Яндекс?'
								autoComplete='off'
								value='Реклама на клиентов включают до набора исполнителей'/>
							<label htmlFor='exam854' className={s.label}>
							Реклама на клиентов включают до набора исполнителей
							</label>
						</div>

						<p className={s.errorText2}>
							{isError85 &&
								isSendForm &&
								'Необходимо указать'}
						</p>
					</div>

					<p className={s.errorText}>
						{errors['В какой момент запускается контекстная реклама Яндекс?'] ? 'Необходимо указать' : ''}
					</p>
				</div>

                <div className={s.form_item}>
					<h4 className={s.form_title}>86. За какой минимальный срок возможно запуститься при активной обратной связи с бизнес-ассистентами?</h4>

					<div className={s.question86}>
                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question86,
								})}
								{...register('За какой минимальный срок возможно запуститься при активной обратной связи с бизнес-ассистентами?')}
								id='exam861'
								type='radio'
								name='За какой минимальный срок возможно запуститься при активной обратной связи с бизнес-ассистентами?'
								autoComplete='off'
								value='1 месяц'
							/>
							<label htmlFor='exam861' className={s.label}>
							1 месяц
							</label>
						</div>

						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question86,
								})}
								{...register('За какой минимальный срок возможно запуститься при активной обратной связи с бизнес-ассистентами?')}
								id='exam862'
								type='radio'
								name='За какой минимальный срок возможно запуститься при активной обратной связи с бизнес-ассистентами?'
								autoComplete='off'
								value='2 недели'
							/>
							<label htmlFor='exam862' className={s.label}>
							2 недели
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question86,
								})}
								{...register('За какой минимальный срок возможно запуститься при активной обратной связи с бизнес-ассистентами?')}
								id='exam863'
								type='radio'
								name='За какой минимальный срок возможно запуститься при активной обратной связи с бизнес-ассистентами?'
								autoComplete='off'
								value='5 дней'
							/>
							<label htmlFor='exam863' className={s.label}>
							5 дней
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question86,
								})}
								{...register('За какой минимальный срок возможно запуститься при активной обратной связи с бизнес-ассистентами?')}
								id='exam864'
								type='radio'
								name='За какой минимальный срок возможно запуститься при активной обратной связи с бизнес-ассистентами?'
								autoComplete='off'
								value='3 месяца'
							/>
							<label htmlFor='exam864' className={s.label}>
							3 месяца
							</label>
						</div>
					</div>

					<p className={s.errorText}>
						{errors['За какой минимальный срок возможно запуститься при активной обратной связи с бизнес-ассистентами?'] ? 'Необходимо указать' : ''}
					</p>
				</div>

                <div className={s.form_item}>
					<h4 className={s.form_title}>87. Что необходимо прислать для присвоения бренда?</h4>

					<div className={s.question87}>
                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question87,
								})}
								{...register('Что необходимо прислать для присвоения бренда?')}
								id='exam871'
								type='radio'
								name='Что необходимо прислать для присвоения бренда?'
								autoComplete='off'
								value='Виртуальные номера: 2 городских и 2 мобильных'
							/>
							<label htmlFor='exam871' className={s.label}>
							Виртуальные номера: 2 городских и 2 мобильных
							</label>
						</div>

						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question87,
								})}
								{...register('Что необходимо прислать для присвоения бренда?')}
								id='exam872'
								type='radio'
								name='Что необходимо прислать для присвоения бренда?'
								autoComplete='off'
								value='Логин и пароль для входа в личный кабинет Манго'
							/>
							<label htmlFor='exam872' className={s.label}>
							Логин и пароль для входа в личный кабинет Манго
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question87,
								})}
								{...register('Что необходимо прислать для присвоения бренда?')}
								id='exam873'
								type='radio'
								name='Что необходимо прислать для присвоения бренда?'
								autoComplete='off'
								value='Адрес офиса'
							/>
							<label htmlFor='exam873' className={s.label}>
							Адрес офиса
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question87,
								})}
								{...register('Что необходимо прислать для присвоения бренда?')}
								id='exam874'
								type='radio'
								name='Что необходимо прислать для присвоения бренда?'
								autoComplete='off'
								value='Все варианты верные'
							/>
							<label htmlFor='exam874' className={s.label}>
							Все варианты верные
							</label>
						</div>
					</div>

					<p className={s.errorText}>
						{errors['За какой минимальный срок возможно запуститься при активной обратной связи с бизнес-ассистентами?'] ? 'Необходимо указать' : ''}
					</p>
				</div>

                
				<div className={s.btns}>
        <Button callback={() => dispatch(setAddStep(29))} back>
						{width > 575 ? (
							'   Назад'
						) : (
							<img src='/static/lk/IconBackward.svg' alt='назад' />
						)}
					</Button>
					<Button>Далее 30 из 35</Button>
				</div>
			</Form>

			<img
				className={s.illustration}
				src='/static/lk/illustration/illustration.png'
				alt='Иллюстрация'
			/>
		</div>
	);
};

export default Step30;
