
import cn from 'classnames';
import s from '../steps.module.scss';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
	setAddData,
	setAddStep,
} from '../../../../../store/reducer/lk/formExam/slice';

import { selectLkFormExam } from '../../../../../store/reducer/lk/formExam/selector';
import Button from '../../Button/Button';
import Form from '../../Form';

const Step11 = () => {
	const [width, setWidth] = useState(window?.innerWidth);
	const dispatch = useDispatch();
	const { data, step } = useSelector(selectLkFormExam);
	const [isError29, setError29] = useState(false);
	const [isError30, setError30] = useState(false);
	const [isError31, setError31] = useState(false);
	const [isSendForm, setIsSendForm] = useState(false);

	useEffect(() => {
		localStorage.setItem('stepLkExam', JSON.stringify(step));
		localStorage.setItem('dataExam', JSON.stringify(data));
	}, []);

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
		watch,
	} = useForm({
		defaultValues: {
			['Основные источники привлечения исполнителей:']: data['Основные источники привлечения исполнителей:'],
			['На каких площадках Skilla помогает разместить объявления по привлечению клиентов?']: data['На каких площадках Skilla помогает разместить объявления по привлечению клиентов?'],
			['В самостоятельном поиске клиентов, что эффективно делать?']: data['В самостоятельном поиске клиентов, что эффективно делать?'],
		},
	});
	
	const ckbox29 = watch('Основные источники привлечения исполнителей:');
	const ckbox30 = watch('На каких площадках Skilla помогает разместить объявления по привлечению клиентов?');
	const ckbox31 = watch('В самостоятельном поиске клиентов, что эффективно делать?');
	
	useEffect(() => {
		if (!ckbox29?.length) {
			setError29(true);
		} else {
			setError29(false);
		}
	}, [ckbox29]);

	useEffect(() => {
		if (!ckbox30?.length) {
			setError30(true);
		} else {
			setError30(false);
		}
	}, [ckbox30]);

	useEffect(() => {
		if (!ckbox31?.length) {
			setError31(true);
		} else {
			setError31(false);
		}
	}, [ckbox31]);

	const onSubmit = data => {
		setIsSendForm(true);

		if (!isError29 && !isError30 && !isError31) {
			dispatch(setAddStep(12));
			dispatch(setAddData(data));
			setIsSendForm(false);

			localStorage.setItem('dataExam', JSON.stringify(data));
			localStorage.setItem('stepLkExam', JSON.stringify(11));
		}
	};

	return (
		<div className={s.step11}>
      <div className={s.wrapper_heading}>
			  <h5>Реклама</h5>
			</div>
			<Form onSubmit={handleSubmit(onSubmit)}>

				

                <div className={s.form_item}>
					<h4 className={s.form_title}>
					29. Основные источники привлечения исполнителей:
					</h4>

					<div className={s.question29}>
						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question29,
								})}
								{...register('Основные источники привлечения исполнителей:')}
								id='exam221'
								type='checkbox'
								name='Основные источники привлечения исполнителей:'
								autoComplete='off'
								value='Авито'/>
							<label htmlFor='exam221' className={s.label}>
							Авито
							</label>
						</div>

                        <div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question29,
								})}
								{...register('Основные источники привлечения исполнителей:')}
								id='exam292'
								type='checkbox'
								name='Основные источники привлечения исполнителей:'
								autoComplete='off'
								value='HH, SuperJob и т.д.'/>
							<label htmlFor='exam292' className={s.label}>
							HH, SuperJob и т.д.
							</label>
						</div>

                        <div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question29,
								})}
								{...register('Основные источники привлечения исполнителей:')}
								id='exam293'
								type='checkbox'
								name='Основные источники привлечения исполнителей:'
								autoComplete='off'
								value='Контекстная реклама'/>
							<label htmlFor='exam293' className={s.label}>
							Контекстная реклама
							</label>
						</div>

                        <div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question29,
								})}
								{...register('Основные источники привлечения исполнителей:')}
								id='exam294'
								type='checkbox'
								name='Основные источники привлечения исполнителей:'
								autoComplete='off'
								value='Бесплатные газеты'/>
							<label htmlFor='exam294' className={s.label}>
							Бесплатные газеты
							</label>
						</div>

                        <div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question29,
								})}
								{...register('Основные источники привлечения исполнителей:')}
								id='exam295'
								type='checkbox'
								name='Основные источники привлечения исполнителей:'
								autoComplete='off'
								value='Наружная реклама (листовки, общественный транспорт, остановки, столбы)'/>
							<label htmlFor='exam295' className={s.label}>
							Наружная реклама (листовки, общественный транспорт, остановки, столбы)
							</label>
						</div>

                        <div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question29,
								})}
								{...register('Основные источники привлечения исполнителей:')}
								id='exam296'
								type='checkbox'
								name='Основные источники привлечения исполнителей:'
								autoComplete='off'
								value='Группы в социальных сетях и мессенджерах (ВК, Телеграм, WhatsApp)'/>
							<label htmlFor='exam296' className={s.label}>
							Группы в социальных сетях и мессенджерах (ВК, Телеграм, WhatsApp)
							</label>
						</div>

						<p className={s.errorText2}>
							{isError29 &&
								isSendForm &&
								'Необходимо указать'}
						</p>
					</div>
				</div>

				<div className={s.form_item}>
					<h4 className={s.form_title}>30. На каких площадках Skilla помогает разместить объявления по привлечению клиентов?</h4>
                    <div className={s.question30}>
						
						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question30,
								})}
								{...register('На каких площадках Skilla помогает разместить объявления по привлечению клиентов?')}
								id='exam301'
								type='checkbox'
								name='На каких площадках Skilla помогает разместить объявления по привлечению клиентов?'
								autoComplete='off'
								value='Skilla размещает объявления на любых площадках по запросу партнера'/>
							<label htmlFor='exam301' className={s.label}>
							Skilla размещает объявления на любых площадках по запросу партнера
							</label>
						</div>

                        <div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question30,
								})}
								{...register('На каких площадках Skilla помогает разместить объявления по привлечению клиентов?')}
								id='exam302'
								type='checkbox'
								name='На каких площадках Skilla помогает разместить объявления по привлечению клиентов?'
								autoComplete='off'
								value='Skilla производит первоначальную публикацию объявлений на Авито'/>
							<label htmlFor='exam302' className={s.label}>
							Skilla производит первоначальную публикацию объявлений на Авито
							</label>
						</div>

                        <div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question30,
								})}
								{...register('На каких площадках Skilla помогает разместить объявления по привлечению клиентов?')}
								id='exam303'
								type='checkbox'
								name='На каких площадках Skilla помогает разместить объявления по привлечению клиентов?'
								autoComplete='off'
								value='Skilla создает и ведёт рекламные кампании на Яндекс.Директ'/>
							<label htmlFor='exam303' className={s.label}>
							Skilla создает и ведёт рекламные кампании на Яндекс.Директ
							</label>
						</div>

                        <div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question30,
								})}
								{...register('На каких площадках Skilla помогает разместить объявления по привлечению клиентов?')}
								id='exam304'
								type='checkbox'
								name='На каких площадках Skilla помогает разместить объявления по привлечению клиентов?'
								autoComplete='off'
								value='Skilla настраивает таргетированную рекламу в соц. сетях'/>
							<label htmlFor='exam304' className={s.label}>
							Skilla настраивает таргетированную рекламу в соц. сетях
							</label>
						</div>
						<p className={s.errorText2}>
							{isError30 &&
								isSendForm &&
								'Необходимо указать'}
						</p>
					</div>
				
				</div>

				<div className={s.form_item}>
					<h4 className={s.form_title}>31. В самостоятельном поиске клиентов, что эффективно делать?</h4>
                    <div className={s.question31}>
						
						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question31,
								})}
								{...register('В самостоятельном поиске клиентов, что эффективно делать?')}
								id='exam311'
								type='checkbox'
								name='В самостоятельном поиске клиентов, что эффективно делать?'
								autoComplete='off'
								value='Отправлять ежемесячно красивые email-письма по всем компаниям в городе'/>
							<label htmlFor='exam311' className={s.label}>
							Отправлять ежемесячно красивые email-письма по всем компаниям в городе
							</label>
						</div>

                        <div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question31,
								})}
								{...register('В самостоятельном поиске клиентов, что эффективно делать?')}
								id='exam312'
								type='checkbox'
								name='В самостоятельном поиске клиентов, что эффективно делать?'
								autoComplete='off'
								value='Направлять маркет-кит и КП по эл. почте по всем компаниям в городе'/>
							<label htmlFor='exam312' className={s.label}>
							Направлять маркет-кит и КП по эл. почте по всем компаниям в городе
							</label>
						</div>

                        <div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question31,
								})}
								{...register('В самостоятельном поиске клиентов, что эффективно делать?')}
								id='exam313'
								type='checkbox'
								name='В самостоятельном поиске клиентов, что эффективно делать?'
								autoComplete='off'
								value='Отправлять массовую рассылку СМС с предложением по услугам'/>
							<label htmlFor='exam313' className={s.label}>
							Отправлять массовую рассылку СМС с предложением по услугам
							</label>
						</div>

                        <div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question31,
								})}
								{...register('В самостоятельном поиске клиентов, что эффективно делать?')}
								id='exam314'
								type='checkbox'
								name='В самостоятельном поиске клиентов, что эффективно делать?'
								autoComplete='off'
								value='Посещать интересующие компании, предлагая свои услуги лично с маркет-китом и КП'/>
							<label htmlFor='exam314' className={s.label}>
							Посещать интересующие компании, предлагая свои услуги лично с маркет-китом и КП
							</label>
						</div>

                        <div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question31,
								})}
								{...register('В самостоятельном поиске клиентов, что эффективно делать?')}
								id='exam315'
								type='checkbox'
								name='В самостоятельном поиске клиентов, что эффективно делать?'
								autoComplete='off'
								value='Осуществлять холодный обзвон'/>
							<label htmlFor='exam315' className={s.label}>
							Осуществлять холодный обзвон
							</label>
						</div>

						<p className={s.errorText2}>
							{isError31 &&
								isSendForm &&
								'Необходимо указать'}
						</p>
					</div>
				</div>

				<div className={s.btns}>
        <Button callback={() => dispatch(setAddStep(10))} back>
						{width > 575 ? (
							'   Назад'
						) : (
							<img src='/static/lk/IconBackward.svg' alt='назад' />
						)}
					</Button>
					<Button>Далее 11 из 35</Button>
				</div>
			</Form>

			<img
				className={s.illustration}
				src='/static/lk/illustration/illustration.png'
				alt='Иллюстрация'
			/>
		</div>
	);
};

export default Step11;

