import cn from 'classnames';
import { useEffect, useState, memo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
	getLkCancelTraining,
	getLkTrainingInfo,
	getUser,
	getViewMenu,
} from '../../../Api';
import { selectLKAuth } from '../../../store/reducer/lk/authChech/selector';
import { setAddUser } from '../../../store/reducer/lk/authChech/slice.js';
import { selectLKSidebar } from '../../../store/reducer/lk/sidebar/selector.js';
import { selectLkTraining } from '../../../store/reducer/lk/training/selector';

import { selectLKContract } from '../../../store/reducer/lk/contract/selector';
import { setIsCheckFromPage } from '../../../store/reducer/lk/contract/slice.js';
import { selectLKPayment } from '../../../store/reducer/lk/payment/selector';
import {
	setAddCheckedDateInput,
	setAddLoading,
	setAddTrainingInfo,
} from '../../../store/reducer/lk/training/slice.js';
import DatePicker from './components/datePicker/DatePicker';
import ModalCode from './components/modalCode/ModalCode';
import PlacePicker from './components/placePicker/PlacePicker';
import Reservation from './components/reservation/Reservation';
import s from './training.module.scss';

const Training = memo(({ endDatePremium }) => {
	const [finishedDate, setFinishedDate] = useState(false);
	const [width, setWidth] = useState(window?.innerWidth);
	const [clientWithoutCours, setClientWithoutCours] = useState(false)
	const dispatch = useDispatch();
	const { user } = useSelector(selectLKAuth);
	const { activeLink, socketData } = useSelector(selectLKSidebar);
	const { trainingInfo } = useSelector(selectLkTraining);

	const { persent } = useSelector(selectLKContract);
	const { paymentInfo } = useSelector(selectLKPayment);

	const statusPayment = paymentInfo?.payment?.status;

	const status = trainingInfo?.training?.status;


	const dateWaiting = trainingInfo?.training?.date;

	const optionDay = {
		day: 'numeric',
	};

	const optionWaiting = {
		day: 'numeric',
		month: 'long',
		year: 'numeric',
	};

	const optionsAll = {
		day: 'numeric',
		month: 'long',
		year: 'numeric',
	};

	const optionsAllForApi = {
		day: 'numeric',
		month: 'numeric',
		year: 'numeric',
	};

	const getDayWaiting = new Date(dateWaiting);

	const dateTransform = getDayWaiting.toLocaleDateString(
		'ru-RU',
		optionWaiting
	);

	const now = new Date();

	const today = new Date(
		now.getFullYear(),
		now.getMonth(),
		now.getDate()
	).valueOf();

	const otherDay = new Date(getDayWaiting).valueOf();

	useEffect(() => {
		user?.user?.menu[7].name == 'Договор-оферта' ? setClientWithoutCours(true) : setClientWithoutCours(false)
	  }, [user?.user?.menu[7].name])

	useEffect(() => {
		if (otherDay < today - 86400000) {
			// 24*60*60*1000
			// раньше чем вчера
		} else if (otherDay < today) {
			setFinishedDate(true);
			// вчера
		} else {
			// сегодня или потом
		}
	}, []);

	const { register, handleSubmit } = useForm({});

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.scroll(0, 0);
		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

	useEffect(() => {
		const checkViewMenu = async () => {
			try {
				const { data } = await getViewMenu(user?.user?.code, /* activeLink */9);
			} catch (e) {
				console.log(e);
			}
		};

		checkViewMenu();
	}, []);

	const getUserInfo = async () => {
		try {
			const { data } = await getUser(user?.user?.code);

			dispatch(setAddUser(data));
			localStorage.setItem('user', JSON.stringify(data));
		} catch (e) {
			console.log(e);
		}
	};

	const onSubmit = () => {
		const cancelTraining = async () => {
			try {
				const { data } = await getLkCancelTraining(user?.user?.code);
				getInfoTraining();
				getUserInfo();
			} catch (e) {
				console.log(e);
			}
		};

		cancelTraining();
	};

	const dateProgram = (elem = 0) => {
		const date1 = trainingInfo?.training?.dates?.[elem][0];
		const date2 = trainingInfo?.training?.dates?.[elem][1];

		const getDay1 = new Date(date1);
		const getDay2 = new Date(date2);

		const dateTransform1 = getDay1.toLocaleDateString('ru-RU', optionDay);
		const dateTransform2 = getDay2.toLocaleDateString('ru-RU', optionsAll);

		const result = `${dateTransform1}-` + dateTransform2.replace('г.', '');

		return result;
	};

	const getDateForApi = (elem = 0) => {
		const date1 = trainingInfo?.training?.dates[elem][0];

		const getDay1 = new Date(date1);

		const dateTransform1 = getDay1.toLocaleDateString(
			'ru-RU',
			optionsAllForApi
		);

		dispatch(setAddCheckedDateInput(true));

		const result = dateTransform1;

		return result;
	};

	useEffect(() => {
		dispatch(setAddLoading(true));
		getInfoTraining();
	}, [socketData]);

	const getInfoTraining = async () => {
		try {
			const { data } = await getLkTrainingInfo(user?.user?.code);
			dispatch(setAddLoading(false));
			dispatch(setAddTrainingInfo(data));
			localStorage.setItem('trainingInfo', JSON.stringify(data));
		} catch (e) {
			console.log(e);
			dispatch(setAddLoading(false));
		}
	};

	const toGoVipPage = () => {
		dispatch(setIsCheckFromPage(true));
		localStorage.setItem('isCheckFromPage', JSON.stringify(true));
		window.open('/premium', '_blank');
	};

	if (status !== 'waiting' && status !== 'finished') {
		return (
			<>
				{statusPayment &&
					persent &&
					statusPayment !== 'prepaid' &&
					statusPayment !== 'paid' &&
					persent <= 4 &&
					endDatePremium &&
					(width > 575 ? (
						<header onClick={toGoVipPage} className={s.header}>
							<img src='/static/lk/background.png' alt='Баннер' />

							<div className={s.header_btn}>Узнать больше</div>
						</header>
					) : (
						<header className={s.header}>
							<div onClick={toGoVipPage}>
								<img
									style={{ width: '100%' }}
									src='/static/lk/background-mobile.png'
									alt='Баннер'
								/>
							</div>
						</header>
					))}

				<div className={s.training}>
					<ModalCode page='training' getInfoTraining={getInfoTraining} />
					<div className={s.main_content}>
						<h4 className={s.subtitle}>
							{width > 575 ? `${clientWithoutCours ? 9 : 10}. Обучение` : `Шаг ${clientWithoutCours ? 9 : 10}`}
						</h4>

						<h1 className={`${s.title} lk-title`}>
							Выбери дату участия в программе «Запуск офиса официального
							партнера»
						</h1>

						<p className={s.descr}>
							Запись возможна при наличии свободных мест
						</p>

						<div className={s.top}>
							<DatePicker
								dateProgram={dateProgram}
								getDateForApi={getDateForApi}
							/>
							<PlacePicker />

							{statusPayment !== 'prepaid' && statusPayment !== 'paid' && (
								<div className={s.disabled}>
									<p>Бронирование недоступно до внесения предоплаты</p>
									<button disabled>Забронировать места</button>
								</div>
							)}
						</div>

						{width > 575 && (
							<img
								className={s.illustration}
								src='/static/lk/illustration/training.png'
								alt='иллюстрация'
							/>
						)}
					</div>

					{(statusPayment === 'prepaid' || statusPayment === 'paid') && (
						<div className={s.footer}>
							<Reservation />
						</div>
					)}
				</div>
			</>
		);
	}

	if (status === 'waiting' || status !== 'finished') {
		return (
			<>
				{statusPayment &&
					persent &&
					statusPayment !== 'prepaid' &&
					statusPayment !== 'paid' &&
					persent <= 4 &&
					width > 575 && (
						<header className={s.header}>
							<img src='/static/lk/background.png' alt='Баннер' />

							<a
								onClick={toGoVipPage}
								href='/premium'
								target='_blank'
								className={s.header_btn}
							>
								Узнать больше{' '}
							</a>
						</header>
					)}

				<div className={s.training}>
					<ModalCode getInfoTraining={getInfoTraining} />
					<div
						className={cn(s.main_content, {
							[s.main_content_waiting]: status === 'waiting',
						})}
					>
						<div className={s.wrapper_title}>
							<img src='/static/lk/iconTime.svg' alt='Иконка' />
							<h4 className={s.subtitle_waiting}>
								{width > 575 ? `${clientWithoutCours ? 9 : 10}. Обучение` : `Шаг ${clientWithoutCours ? 9 : 10}`}
							</h4>
						</div>

						{width > 575 ? (
							<h1 className={`${s.title} lk-title`}>
								Ждем в офисе Skilla <br></br> в Санкт-Петербурге
							</h1>
						) : (
							<h1 className={`${s.title} lk-title`}>
								Ждем в офисе Skilla в Санкт-Петербурге
							</h1>
						)}

						{width > 575 ? (
							<div className={s.info_waiting}>
								<p>{dateTransform.replace('г.', '')}</p>
								<p>Санкт-Петербург, Энергетиков проспект, д. 10А, офис 416</p>
							</div>
						) : (
							<div className={s.info_waiting}>
								<p>{dateTransform.replace('г.', '')}</p>
								<p>
									Санкт-Петербург, <br></br> Энергетиков проспект, д. 10А, офис
									416
								</p>
							</div>
						)}

						<img
							className={s.illustration}
							src={
								width > 575
									? '/static/lk/illustration/training_tall.png'
									: '/static/lk/illustration/training-mobile.png'
							}
							alt='иллюстрация'
						/>

						<form onSubmit={handleSubmit(onSubmit)}>
							<button
								{...register('cancel')}
								className={cn(s.cancel_btn, {
									[s.cancel_btn_hide]: finishedDate,
								})}
							>
								Отменить запись
							</button>
						</form>
					</div>
				</div>
			</>
		);
	}
})

export default Training;
