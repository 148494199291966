import styled from 'styled-components';
import { memo, useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  setAddStep,
  setAddSendBusiness,
  setAddPhoneBusiness,
  setAddEmailBusiness,
  setAddNameBusiness,
  setAddMessage,
  setAddSendDiagnostics,
} from '../store/reducer/form/slice';
import Button from './Button';
import { setAddOnClickForm } from '../store/reducer/main/slice';

const ModalAnswer = memo(function ModalAnswer({
  phone,
  step,
  nameBusiness,
  phoneBusiness,
  formSendBusiness,
  formSendDiagnostics,
  setAddOnClick,
  messageWhy,
}) {
  const dispatch = useDispatch();
  const [width, setWidth] = useState(window?.innerWidth);

  const returnForm = useCallback(() => {
    dispatch(setAddStep(0));
    dispatch(setAddSendBusiness(false));
    dispatch(setAddSendDiagnostics(false));
    dispatch(setAddPhoneBusiness(''));
    dispatch(setAddEmailBusiness(''));
    dispatch(setAddNameBusiness(''));
    dispatch(setAddMessage(''));
  }, [step, phone, phoneBusiness, nameBusiness, formSendBusiness, formSendDiagnostics, messageWhy]);

  const completeForm = (e) => {
    e.preventDefault();
    setAddOnClick(true);
    dispatch(setAddOnClickForm(true));
  };

  useEffect(() => {
    const cb = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', cb);

    return () => window.removeEventListener('resize', cb);
  }, []);

  return (
    <>
      <StyledModalAnswer>
        <div className="content">
          <div className="thanks">
            {(nameBusiness !== '') & (nameBusiness !== null)
              ? `Спасибо, ${nameBusiness}`
              : 'Спасибо'}
          </div>

          {width <= 768 ? (
            <p className="description">Мы свяжемся с тобой в ближайшее время по номеру {phone}</p>
          ) : (
            <p className="description">
              Мы свяжемся с тобой в ближайшее время <br></br> по номеру {phone}
            </p>
          )}
        </div>

        <Button
          text={step === 4 ? 'Отправить еще раз' : 'Дополнить анкету'}
          callback={step === 4 ? returnForm : completeForm}
        />
      </StyledModalAnswer>

      <StyledRepeat>
        {step === 4 ? (
          ''
        ) : (
          <div className="repeat-wrapper">
            <button onClick={returnForm} type="button" className="repeat">
              Отправить заявку еще раз
            </button>
            <svg
              className="repeat__icon"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8.85769 3.95146L3.70081 9.10833L2.9937 8.40123L8.15053 3.24441L4.40794 3.24441L4.40794 2.24441L9.85769 2.2444L9.85769 7.69415H8.85769L8.85769 3.95146Z"
                fill="white"
              />
            </svg>
          </div>
        )}
      </StyledRepeat>
    </>
  );
});

const StyledModalAnswer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  background: #303550;
  border-radius: 12px;
  width: 593px;
  padding: 24px;

  @media (max-width: 768px) {
    display: block;
    width: 343px;
    margin: 0 auto;
  }

  .content {
    max-width: 300px;
    width: 100%;
  }

  .thanks {
    font-weight: 800;
    font-size: 22px;
    line-height: 120%;
    margin-bottom: 8px;
    color: #fff;

    @media (max-width: 768px) {
      font-size: 18px;
    }
  }

  .description {
    font-weight: 400;
    font-size: 18px;
    line-height: 130%;
    color: #c7c9d1;

    @media (max-width: 768px) {
      font-size: 14px;
      margin-bottom: 15px;
    }
  }

  .button-wrapper {
    position: relative;
    @media (max-width: 768px) {
      width: 250px;
    }
  }

  .button-wrapper {
    position: relative;
    @media (max-width: 575px) {
      width: 100%;
    }
  }

  .icon {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);

    @media (max-width: 768px) {
      right: 30px;
    }

    @media (max-width: 575px) {
      right: 50px;
    }
  }

  .button {
    background: #00ff88;
    color: #000729;
    font-weight: 600;
    font-size: 17px;
    line-height: 130%;
    padding: 0.72em 3.11em 0.72em 1.33em;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s linear;
    font-family: inherit;

    &:hover {
      background: #00cc6e;
    }

    @media (max-width: 768px) {
      padding: 0.55em 3.11em 0.55em 1.33em;
    }
  }
`;

const StyledRepeat = styled.div`
  .repeat-wrapper {
    max-width: 250px;
    display: flex;
    align-items: baseline;

    &:hover {
      .repeat {
        color: #ccc;
      }
      .repeat__icon {
        path {
          fill: #ccc;
        }
      }
    }

    @media (max-width: 768px) {
      margin-left: 16px;
      padding-bottom: 16px;
    }
  }
  .repeat {
    margin-top: 16px;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s linear;
  }

  .repeat__icon {
    margin-left: 8px;
    cursor: pointer;
    path {
      transition: all 0.2s linear;
    }
  }
`;

export default ModalAnswer;
