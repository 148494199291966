import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getlkBookPaymentForm } from '../../../../Api';
import { selectLKAuth } from '../../../../store/reducer/lk/authChech/selector';
import { setAddInfoBook } from '../../../../store/reducer/lk/book/slice.js';
import { setAddActiveLink } from '../../../../store/reducer/lk/sidebar/slice';
import SpinnerWaiting from '../../Payment/pages/waiting/spinner/SpinnerWaiting';
import s from '../../Payment/pages/waiting/waiting.module.scss';

const Waiting = () => {
	const { user } = useSelector(selectLKAuth);
	const dispatch = useDispatch();

	let interval = null;

	const fetchInfoBook = async () => {
		try {
			const { data } = await getlkBookPaymentForm(user?.user?.code);
			dispatch(setAddInfoBook(data));

			data?.payment?.status !== 'waiting' && returnPage();
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		updateDate();
	}, []);

	const updateDate = () => {
		interval = setInterval(() => {
			fetchInfoBook();
		}, 2000);
	};

	const stopInterval = int => {
		clearInterval(int);
	};

	const returnPage = () => {
		stopInterval(interval);
		window.location.href = '/lk';
		dispatch(setAddActiveLink('book'));
		localStorage.setItem('activeLink', JSON.stringify('book'));
	};

	useEffect(() => {
		fetchInfoBook();
	}, []);

	return (
		<div className={s.waiting}>
			<a onClick={returnPage} className={s.return}>
				Вернуться в личный кабинет
			</a>

			<div className={s.container}>
				<div className={s.wrapper_title}>
					<h4 className={s.subtitle_done}>Ожидаем поступления средств</h4>
				</div>
				<SpinnerWaiting />
			</div>
		</div>
	);
};

export default Waiting;
