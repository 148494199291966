import { useEffect, useState } from 'react'
import s from './comments.module.scss'

const Comments = () => {
  const [width, setWidth] = useState(window?.innerWidth)

  useEffect(() => {
    const cb = () => {
      setWidth(window.innerWidth)
    }

    window.scroll(0, 0)
    window.addEventListener('resize', cb)

    return () => window.removeEventListener('resize', cb)
  }, [])

  return (
    <div className={s.comments}>
      <h3 className={s.title}>Избранные рецензии</h3>

      <div className={s.items}>
        <div className={s.item}>
          <div className={s.wrapper}>
            <img className={s.img} src="/static/lk/book/comments/1.jpg" alt="изображение" />
            {width <= 575 && (
              <div className={s.info}>DJ Цветкоff российский композитор, диджей и продюсер</div>
            )}
          </div>

          <div className={s.content}>
            <p>
              «Кирилл построил уникальную партнерскую сеть, он всегда двигается вперед, является примером для
              многих предпринимателей. Если вы хотите добиться успеха, читайте книгу Кирилла, в вашей жизни
              обязательно наступят перемены в лучшую сторону. Потрясающая история успеха!»
            </p>

            {width > 575 && <div>DJ Цветкоff российский композитор, диджей и продюсер</div>}
          </div>
        </div>

        <div className={s.item}>
          <div className={s.wrapper}>
            <img className={s.img} src="/static/lk/book/comments/2.jpg" alt="изображение" />

            {width <= 575 && (
              <div className={s.info}>
                Константин Михайлович Иванов профессор, доктор технических наук, ректор БГТУ«ВОЕНМЕХ»
              </div>
            )}
          </div>

          <div className={s.content}>
            <p>
              «Кирилл не просто выпускник, который получил знания и опыт в нашем престижном университете.
              Такие ребята — предприниматели меняют мир и делают жизнь людей комфортнее. Уверен, что мы станем
              свидетелями дальнейшего развития его бизнес-империи Скилла в ближайшем будущем!»
            </p>

            {width > 575 && (
              <div>Константин Михайлович Иванов профессор, доктор технических наук, ректор БГТУ«ВОЕНМЕХ»</div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Comments
