import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectMain } from '../../../../store/reducer/main/selector.js';
import { setIsModalBookProcess } from '../../../../store/reducer/main/slice.js';

import s from './modal.module.scss';

const Modal = () => {
	const [width, setWidth] = useState(window?.innerWidth);
	const { modalBookProcess, pageBook, statusBook, typeBook } =
		useSelector(selectMain);

	const dispatch = useDispatch();

	const modalRef = useRef(null);
	const overlayRef = useRef(null);

	const status = statusBook?.status;
	const resultFalure = statusBook?.result;



	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

	useEffect(() => {
		const handleClickBody = e => {
			const path = e.path || (e.composedPath && e.composedPath());

			if (
				path.includes(overlayRef.current) &&
				!path.includes(modalRef.current)
			) {
				hideModal();
			}
		};

		document.body.addEventListener('click', handleClickBody);

		return () => document.body.removeEventListener('click', handleClickBody);
	}, []);

	const hideModal = () => {
		dispatch(setIsModalBookProcess(false));
		document.body.style.overflow = '';
		document.body.removeAttribute('style');
		localStorage.setItem('modalBookProcess', JSON.stringify(false));
	};

	return (
		<div
			ref={overlayRef}
			className={cn(s.overlay, {
				[s.overlay_active]: modalBookProcess,
			})}
		>
			<div
				ref={modalRef}
				className={cn(s.modal, {
					[s.modal_success]: status === 'paid',
					[s.modal_success_customer]:
						status === 'paid' &&
						(pageBook
							? pageBook === 'customer'
							: window.location.pathname.substring(1) === 'book'),
					[s.modal_success_worker]: status === 'paid' && pageBook === 'worker',
					[s.modal_failure]: resultFalure === 'failure',
				})}
			>
				<div className={s.inner}>
					<img
						onClick={hideModal}
						className={s.close}
						src='/static/icons/iconCloseWhite.svg'
						alt='закрыть'
					/>
					{(status === 'waiting' || !status) && resultFalure === 'success' && (
						<div className={s.content}>
							<div className={s.wrapper_title}>
								{pageBook === 'business' && (
									<img src='/static/book/iconTime.svg' alt='Иконка' />
								)}

								{(pageBook
									? pageBook === 'customer'
									: window.location.pathname.substring(1) === 'book') && (
									<img src='/static/book/iconTime-customer.svg' alt='Иконка' />
								)}

								{pageBook === 'worker' && (
									<img src='/static/book/iconTime-worker.svg' alt='Иконка' />
								)}

								<h4
									className={cn(s.subtitle, {
										[s.subtitle_customer]:
											status === 'waiting' &&
											(pageBook
												? pageBook === 'customer'
												: window.location.pathname.substring(1) === 'book'),

										[s.subtitle_worker]:
											status === 'waiting' && pageBook === 'worker',
									})}
								>
									Идет процесс оплаты
								</h4>
							</div>

							<p className={s.descr}>Подождите, пожалуйста</p>
						</div>
					)}

					{status === 'paid' &&
						typeBook === 'book' &&
						resultFalure === 'success' && (
							<div className={s.content}>
								<div className={s.wrapper_title}>
									<img src='/static/book/iconTime-success.svg' alt='Иконка' />

									<h2 className={s.title_success}>Оплата прошла успешно!</h2>
								</div>

								<p className={s.descr}>
									В ближайшее время книга будет отправлена на указанный адрес
								</p>

								{
									<Link onClick={hideModal} to='/' className={s.btn_success}>
										Вернуться на главную
									</Link>
								}
							</div>
						)}

					{status === 'paid' &&
						typeBook === 'ebook' &&
						resultFalure === 'success' && (
							<div className={s.content}>
								<div className={s.wrapper_title}>
									<img src='/static/book/iconTime-success.svg' alt='Иконка' />

									<h2 className={s.title_success}>Оплата прошла успешно!</h2>
								</div>

								<p className={s.descr}>Книга отправлена на указанную почту</p>
							</div>
						)}

					{resultFalure === 'failure' && (
						<div className={s.content}>
							<div className={s.wrapper_title}>
								<img src='/static/book/iconTime-failure.svg' alt='Иконка' />

								<h2 className={s.title_failure}>Ошибка!</h2>
							</div>

							<p className={s.descr}>
								Не удалось получить информацию об оплате
							</p>

							<button onClick={hideModal} className={s.btn_failure}>
								Попробовать снова
							</button>
						</div>
					)}

					{typeBook === 'book' && (
						<img
							src={
								width > 575
									? '/static/book/cover-modal.png'
									: '/static/lk/book/forModal-mobile.png'
							}
							alt='изображение'
						/>
					)}

					{typeBook === 'ebook' && (
						<img
							src={
								width > 575
									? '/static/book/cover-modal-el.png'
									: '/static/book/cover-modal-el-mobile.png'
							}
							alt='изображение'
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default Modal;
