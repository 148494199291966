import './loadingPage.scss';
import { useSelector } from 'react-redux';
import { selectMain } from '../../store/reducer/main/selector';

function LoadingPage() {
  const { activeLoadingPage } = useSelector(selectMain);

  return (
    <div
      style={{
        backgroundColor:
          activeLoadingPage === 'business' || window.location.pathname.indexOf('business') === 1
            ? '#00361C'
            : activeLoadingPage === 'worker' || window.location.pathname.indexOf('worker') === 1
            ? '#500D88'
            : activeLoadingPage === 'customer' || window.location.pathname.indexOf('customer') === 1
            ? '#001784'
            : '',
      }}
      className="loader">
      <div className="loader__icon">
        {activeLoadingPage === 'business' || window.location.pathname.indexOf('business') === 1 ? (
          <img src="/static/loading-page/business.gif" alt="Загрузка..." />
        ) : activeLoadingPage === 'customer' ||
          window.location.pathname.indexOf('customer') === 1 ? (
          <img src="/static/loading-page/customer.gif" alt="Загрузка..." />
        ) : activeLoadingPage === 'worker' || window.location.pathname.indexOf('worker') === 1 ? (
          <img src="/static/loading-page/worker.gif" alt="Загрузка..." />
        ) : (
          <img src="/static/loading-page/business.gif" alt="Загрузка..." />
        )}
      </div>
    </div>
  );
}

export default LoadingPage;
