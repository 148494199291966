import cn from 'classnames';
import { memo } from 'react';
import s from './spinnerDots.module.scss';

const SpinnerWaiting = memo(({ page }) => {
	return (
		<div className={cn(s.lds_ellipsis, {})}>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	);
});

export default SpinnerWaiting;
