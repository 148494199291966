import cn from 'classnames'
import { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectLKContract } from '../../../store/reducer/lk/contract/selector'
import { setIsCheckFromPage } from '../../../store/reducer/lk/contract/slice.js'
import { selectLKPayment } from '../../../store/reducer/lk/payment/selector'
import { selectLkFormExam } from '../../../store/reducer/lk/formExam/selector'
import { setAddStep } from '../../../store/reducer/lk/formExam/slice.js'
import { setAddStatus } from '../../../store/reducer/lk/formExam/slice'
import { selectLKAuth } from '../../../store/reducer/lk/authChech/selector.js'
import { selectLKSidebar } from '../../../store/reducer/lk/sidebar/selector.js'
import { getViewMenu } from '../../../Api'
import { getLkExam } from '../../../Api'
import UlItem from '../ui/UlItem/UlItem'
import s from './exam.module.scss'

import Step1 from './steps/Step1/StepEx1'
import Step2 from './steps/Step2/StepEx2'
import Step3 from './steps/Step3/StepEx3'
import Step4 from './steps/Step4/StepEx4'
import Step5 from './steps/Step5/StepEx5'
import Step6 from './steps/Step6/StepEx6'
import Step7 from './steps/Step7/StepEx7'
import Step8 from './steps/Step8/StepEx8'
import Step9 from './steps/Step9/StepEx9'
import Step10 from './steps/Step10/StepEx10'
import Step11 from './steps/step11/StepEx11'
import Step12 from './steps/Step12/StepEx12'
import Step13 from './steps/Step13/StepEx13'
import Step14 from './steps/Step14/StepEx14'
import Step15 from './steps/Step15/StepEx15'
import Step16 from './steps/Step16/StepEx16'
import Step17 from './steps/Step17/StepEx17'
import Step18 from './steps/Step18/StepEx18'
import Step19 from './steps/Step19/StepEx19'
import Step20 from './steps/Step20/StepEx20'
import Step21 from './steps/Step21/StepEx21'
import Step22 from './steps/Step22/StepEx22'
import Step23 from './steps/Step23/StepEx23'
import Step24 from './steps/Step24/StepEx24'
import Step25 from './steps/Step25/StepEx25'
import Step26 from './steps/Step26/StepEx26'
import Step27 from './steps/Step27/StepEx27'
import Step28 from './steps/Step28/StepEx28'
import Step29 from './steps/Step29/StepEx29'
import Step30 from './steps/Step30/StepEx30'
import Step31 from './steps/Step31/StepEx31'
import Step32 from './steps/Step32/StepEx32'
import Step33 from './steps/Step33/StepEx33'
import Step34 from './steps/Step34/StepEx34'
import Step35 from './steps/Step35/StepEx35'
import Done from './steps/Done/DoneEx'

const Exam = ({ endDatePremium }) => {
  const [width, setWidth] = useState(window?.innerWidth)
  const [isTestStart, setIsTestStart] = useState(true)
  const [clientWithoutCours, setClientWithoutCours] = useState(false)
  const dispatch = useDispatch()

  const { user } = useSelector(selectLKAuth)
  const { activeLink } = useSelector(selectLKSidebar)
  const { step, status, incorrect } = useSelector(selectLkFormExam)
  const { persent } = useSelector(selectLKContract)
  const { paymentInfo } = useSelector(selectLKPayment)
  const { socketData } = useSelector(selectLKSidebar)
  const statusPayment = paymentInfo?.payment?.status
  console.log(activeLink)

  const statusForm = status?.exam?.status


	useEffect(() => {
		user?.user?.menu[7].name == 'Договор-оферта' ? setClientWithoutCours(true) : setClientWithoutCours(false)
	  }, [user?.user?.menu[7].name])

  useEffect(() => {
    getLkExam(user?.user?.code)
      .then(({ data }) => {
        dispatch(setAddStatus(data))
        localStorage.setItem('statusExam', JSON.stringify(data))
      })
      .catch((e) => {
        console.log(e)
      })
  }, [socketData])

  useEffect(() => {
    const checkViewMenu = async () => {
      try {
        const { data } = await getViewMenu(user?.user?.code, 11)
      } catch (e) {
        console.log(e)
      }
    }

    checkViewMenu()
  }, [])

  useEffect(() => {
    const cb = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', cb)
    window.scroll(0, 0)

    return () => window.removeEventListener('resize', cb)
  }, [])

  useEffect(() => {
    if (step > 0) {
      setIsTestStart(false)
    }
  }, [step])

  useEffect(() => {
    if (statusForm == 'success' || statusForm == 'failed') {
      dispatch(setAddStep(36))
    }
  }, [statusForm])

  const toGoVipPage = () => {
    dispatch(setIsCheckFromPage(true))
    localStorage.setItem('isCheckFromPage', JSON.stringify(true))
    window.open('/premium', '_blank')
  }

  function startTest() {
    if (statusForm == 'waiting') {
      setIsTestStart(false)
      dispatch(setAddStep(1))
    }
  }

  return (
    <>
      {statusPayment &&
        persent &&
        statusPayment !== 'prepaid' &&
        statusPayment !== 'paid' &&
        persent <= 4 &&
        endDatePremium &&
        (width > 770 ? (
          <header onClick={toGoVipPage} className={s.header}>
            <img src="/static/lk/background.png" alt="Баннер" />

            <div className={s.header_btn}>Узнать больше</div>
          </header>
        ) : (
          <header className={s.header}>
            <div onClick={toGoVipPage}>
              <img style={{ width: '100%' }} src="/static/lk/background-mobile.png" alt="Баннер" />
            </div>
          </header>
        ))}

      {isTestStart && statusForm !== 'success' && statusForm !== 'failed' ? (
        <div className={s.exam}>
          <div className={cn(s.heading, s.heading_waiting)}>
            <div className={s.wrapper_heading}>
              <h5>{clientWithoutCours ? 11 : 12}. Экзамен</h5>
            </div>

            {width > 575 ? (
              <h2>
                Добро пожаловать <br></br> на экзамен, предприниматель!
              </h2>
            ) : (
              <h2>Добро пожаловать на экзамен, предприниматель!</h2>
            )}
          </div>

          <ul className={s.list}>
            <h4>Проверка знаний на право открыть официальный партнерский офис Скилла Работа</h4>
            <UlItem>
              <div className={s.done}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z" fill="#002CFB" />
                  <path d="M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z" fill="#002CFB" />
                  <path d="M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z" fill="#002CFB" />
                </svg>
              </div>

              <span>Тестирование займет около 60 минут</span>
            </UlItem>

            <UlItem>
              <div className={s.done}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z" fill="#002CFB" />
                  <path d="M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z" fill="#002CFB" />
                  <path d="M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z" fill="#002CFB" />
                </svg>
              </div>

              <span>Можно пользоваться конспектами</span>
            </UlItem>

            <UlItem>
              <div className={s.done}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z" fill="#002CFB" />
                  <path d="M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z" fill="#002CFB" />
                  <path d="M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z" fill="#002CFB" />
                </svg>
              </div>

              <span>Можно советоваться с другими обучающимися</span>
            </UlItem>

            <UlItem>
              <div className={s.done}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z" fill="#002CFB" />
                  <path d="M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z" fill="#002CFB" />
                  <path d="M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z" fill="#002CFB" />
                </svg>
              </div>

              <span>Нельзя обращаться за помощью к сотрудникам Скилла</span>
            </UlItem>

            <UlItem>
              <div className={s.done}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z" fill="#002CFB" />
                  <path d="M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z" fill="#002CFB" />
                  <path d="M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z" fill="#002CFB" />
                </svg>
              </div>

              <span>Некоторые вопросы предполагают один ответ, а некоторые — несколько</span>
            </UlItem>

            <UlItem>
              <div className={s.done}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z" fill="#002CFB" />
                  <path d="M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z" fill="#002CFB" />
                  <path d="M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z" fill="#002CFB" />
                </svg>
              </div>

              <span>Для успешной сдачи экзамена требуется набрать не менее 70 баллов из 100</span>
            </UlItem>

            <UlItem>
              <div className={s.done}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z" fill="#002CFB" />
                  <path d="M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z" fill="#002CFB" />
                  <path d="M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z" fill="#002CFB" />
                </svg>
              </div>

              <span>
                В случае неудовлетворительного результата, бизнес-ассистент укажет на ошибки и предоставит
                повторную попытку
              </span>
            </UlItem>
          </ul>

          {statusForm !== 'waiting' && (
            <p className={s.text_bottom}>Доступ к тестированию будет открыт после прохождения обучения</p>
          )}

          <button
            className={statusForm == 'waiting' ? s.btn : `${s.btn_disabled} ${s.btn}`}
            onClick={startTest}
          >
            Начать тестирование
          </button>

          <img
            className={cn(s.illustration)}
            src={
              width > 770 ? '/static/lk/illustration/access.png' : '/static/lk/illustration/access-mobile.png'
            }
            alt="иллюстрация"
          />
        </div>
      ) : (
        <div className={s.exam}>
          {step == 1 && <Step1 />}
          {step == 2 && <Step2 />}
          {step == 3 && <Step3 />}
          {step == 4 && <Step4 />}
          {step == 5 && <Step5 />}
          {step == 6 && <Step6 />}
          {step == 7 && <Step7 />}
          {step == 8 && <Step8 />}
          {step == 9 && <Step9 />}
          {step == 10 && <Step10 />}
          {step == 11 && <Step11 />}
          {step == 12 && <Step12 />}
          {step == 13 && <Step13 />}
          {step == 14 && <Step14 />}
          {step == 15 && <Step15 />}
          {step == 16 && <Step16 />}
          {step == 17 && <Step17 />}
          {step == 18 && <Step18 />}
          {step == 19 && <Step19 />}
          {step == 20 && <Step20 />}
          {step == 21 && <Step21 />}
          {step == 22 && <Step22 />}
          {step == 23 && <Step23 />}
          {step == 24 && <Step24 />}
          {step == 25 && <Step25 />}
          {step == 26 && <Step26 />}
          {step == 27 && <Step27 />}
          {step == 28 && <Step28 />}
          {step == 29 && <Step29 />}
          {step == 30 && <Step30 />}
          {step == 31 && <Step31 />}
          {step == 32 && <Step32 />}
          {step == 33 && <Step33 />}
          {step == 34 && <Step34 />}
          {step == 35 && <Step35 />}
          {(step == 36 || statusForm === 'success' || statusForm === 'failed') && <Done clientWithoutCours={clientWithoutCours}/>}
        </div>
      )}
    </>
  )
}

export default Exam
