import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLkTraining } from '../../../../../store/reducer/lk/training/selector';
import { setAddDateForApi } from '../../../../../store/reducer/lk/training/slice';
import Spinner from '../../../../SpinnerSmall';
import s from '../../training.module.scss';

const DatePicker = ({ dateProgram, getDateForApi }) => {
	const [width, setWidth] = useState(window?.innerWidth);
	const dispatch = useDispatch();

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

	const { isLoading, dateForApi, checkedDateInput, isSendData, trainingInfo } =
		useSelector(selectLkTraining);

	return (
		<>
			{!isLoading && trainingInfo?.training?.dates ? (
				<>
					<div className={s.item}>
						<div className={s.radio}>
							<input
								className={cn(s.input, {})}
								onClick={() => dispatch(setAddDateForApi(getDateForApi(0)))}
								id='radio'
								type='radio'
								name='radio'
								defaultValue={dateForApi}
								autoComplete='off'
							/>

							<label htmlFor='radio' className={s.label}>
								Ближайшая программа
								<p className={s.date}>{dateProgram(0)}</p>
							</label>
						</div>

						<p className={s.place}>
							{width > 575 ? 'Мало мест' : 'Мало мест'}
						</p>
					</div>

					<div className={s.item}>
						<div className={s.radio}>
							<input
								className={cn(s.input, {})}
								onClick={() => dispatch(setAddDateForApi(getDateForApi(1)))}
								id='radio2'
								type='radio'
								name='radio'
								autoComplete='off'
								defaultValue={dateForApi}
							/>

							<label htmlFor='radio2' className={s.label}>
								Следующая программа
								<p className={s.date}>{dateProgram(1)}</p>
							</label>
						</div>

						<p className={s.place}>
							{width > 575 ? 'Много мест' : 'Много мест'}
						</p>
					</div>
				</>
			) : (
				<Spinner widthSize={130} heightSize={130} />
			)}

			{!checkedDateInput && isSendData && (
				<div className={s.error}>Необходимо указать дату</div>
			)}
		</>
	);
};

export default DatePicker;
