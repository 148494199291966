import s from "../steps.module.scss";
import cn from "classnames";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  setAddStep,
  setAddData,
} from "../../../../../store/reducer/lk/form/slice";
import { selectLkForm } from "../../../../../store/reducer/lk/form/selector";

import Form from "../../Form";
import Button from "../../Button/Button";

const Step7 = () => {
  const [width, setWidth] = useState(window?.innerWidth);
  const dispatch = useDispatch();
  const { data, step } = useSelector(selectLkForm);

  useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

  useEffect(() => {
    localStorage.setItem("stepLk", JSON.stringify(step));
    localStorage.setItem("data", JSON.stringify(data));
  }, []);

  const schema = yup
    .object({
      ["Есть ли у вас уже команда для реализации этого проекта?"]: yup
        .string()
        .required(),

      ["Главная причина вашего интереса к бизнесу со Skilla?"]: yup
        .string()
        .required(),

      ["Укажите свои сильные стороны, которые, как вам кажется, помогут в этом проекте"]:
        yup.string().required(),

      ["Укажите свои сильные стороны, которые, как вам кажется, помогут в этом проекте"]:
        yup.string().required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ["Есть ли у вас уже команда для реализации этого проекта?"]:data["Есть ли у вас уже команда для реализации этого проекта?"],

      ["Главная причина вашего интереса к бизнесу со Skilla?"]:data["Главная причина вашего интереса к бизнесу со Skilla?"],

      ["Укажите свои сильные стороны, которые, как вам кажется, помогут в этом проекте"]:data["Укажите свои сильные стороны, которые, как вам кажется, помогут в этом проекте"],

      ["Укажите свои сильные стороны, которые, как вам кажется, помогут в этом проекте"]:data["Укажите свои сильные стороны, которые, как вам кажется, помогут в этом проекте"],
    },
  });

  const onSubmit = (data) => {
    dispatch(setAddStep(8));
    dispatch(setAddData(data));

    localStorage.setItem("data", JSON.stringify(data));
    localStorage.setItem("stepLk", JSON.stringify(8));
  };

  return (
    <div className={s.step7}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={s.form_item}>
          <h4 className={s.form_title}>
            Есть ли у вас уже команда для реализации этого проекта?
          </h4>

          <textarea
            {...register("Есть ли у вас уже команда для реализации этого проекта?")}
            style={{ marginTop: 0 }}
            className={cn(s.textarea, {
              [s.error]:
                errors[
                  "Есть ли у вас уже команда для реализации этого проекта?"
                ],
            })}
            placeholder="Развернутый ответ увеличит вероятность одобрения кандидата на партнерство"
            name="Есть ли у вас уже команда для реализации этого проекта?"
          ></textarea>
        </div>

        <div className={s.form_item}>
          <h4 className={s.form_title}>
            Главная причина вашего интереса к бизнесу со Skilla?
          </h4>

          <textarea
            {...register("Главная причина вашего интереса к бизнесу со Skilla?")}
            style={{ marginTop: 0 }}
            className={cn(s.textarea, {
              [s.error]:
                errors["Главная причина вашего интереса к бизнесу со Skilla?"],
            })}
            placeholder="Развернутый ответ увеличит вероятность одобрения кандидата на партнерство"
            name="Главная причина вашего интереса к бизнесу со Skilla?"
          ></textarea>
        </div>

        <div className={s.form_item}>
          <h4 className={s.form_title}>
            Укажите свои сильные стороны, которые, как вам кажется, помогут в
            этом проекте
          </h4>

          <textarea
            {...register("Укажите свои сильные стороны, которые, как вам кажется, помогут в этом проекте")}
            style={{ marginTop: 0 }}
            className={cn(s.textarea, {
              [s.error]:
                errors["Укажите свои сильные стороны, которые, как вам кажется, помогут в этом проекте"],
            })}
            placeholder="Развернутый ответ увеличит вероятность одобрения кандидата на партнерство"
            name="Укажите свои сильные стороны, которые, как вам кажется, помогут в этом проекте"
          ></textarea>
        </div>
        

        <div style={{ marginTop: "284px" }} className={s.btns}>
          <Button callback={() => dispatch(setAddStep(6))} back>
           {width > 575 ? ' Назад' : <img src='/static/lk/IconBackward.svg' alt='назад' />}
          </Button>
          <Button>Далее 7 из 8</Button>
        </div>
      </Form>

      <img
        className={s.illustration}
        src="/static/lk/illustration/illustration.png"
        alt="Иллюстрация"
      />
    </div>
  );
};

export default Step7;
