
import './formLive.scss';

function FormLive({showQuestion, clickOption, live_title, live_subtitle, option, answer }) {
  const { option1, option2, option3, option4 } = option;

  const items = [
    { question: option1, active: 0 },
    { question: option2, active: 1 },
    { question: option3, active: 2 },
    { question: option4, active: 3 },
  ];

  return (
    <div className="formLive">
      <h4 className="formLive__title">{clickOption  ? 'Правильный ответ' : live_subtitle}</h4>
      <h3 className="formLive__subtitle">{live_title}</h3>

      <div className={clickOption ? 'answer' : 'formLive__items items-formLive'}>
        {clickOption
          ? answer
          : items.map((item, i) => {
              return (
                <button
                  onClick={() => showQuestion(true)}
                  key={item.question}
                  className='items-formLive__item'>
                  {item.question}
                </button>
              );
            })}
      </div>
    </div>
  );
}

export default FormLive;
