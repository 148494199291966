import s from './application.module.scss';

import { useEffect, useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCheckApp, getViewMenu } from '../../../Api';
import { selectLKAuth } from '../../../store/reducer/lk/authChech/selector';
import { setCheckDownloadedApp } from '../../../store/reducer/lk/authChech/slice';
import { setIsCheckFromPage } from '../../../store/reducer/lk/contract/slice.js';
import { selectLKSidebar } from '../../../store/reducer/lk/sidebar/selector';

import { selectLKContract } from '../../../store/reducer/lk/contract/selector';
import { selectLKPayment } from '../../../store/reducer/lk/payment/selector';

import UlItem from '../ui/UlItem/UlItem';

const Application = memo(({ endDatePremium }) => {
	const [width, setWidth] = useState(window?.innerWidth);
	const { user, checkDownloadedApp } = useSelector(selectLKAuth);
	const { activeLink, socketData } = useSelector(selectLKSidebar);
	console.log(activeLink)

	const { persent } = useSelector(selectLKContract);
	const { paymentInfo } = useSelector(selectLKPayment);

	const statusPayment = paymentInfo?.payment?.status;


	const dispatch = useDispatch();

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);
		window.scroll(0, 0);

		return () => window.removeEventListener('resize', cb);
	}, []);

	useEffect(() => {
		window.scroll(0, 0);
	}, []);

	useEffect(() => {
		const checkApp = async () => {
			try {
				const { data } = await getCheckApp(user?.user?.code);
				dispatch(setCheckDownloadedApp(data.result));
			} catch (e) {
				console.log(e);
			}
		};

		checkApp();
	}, [socketData]);

	useEffect(() => {
		const checkViewMenu = async () => {
			try {
				const { data } = await getViewMenu(user?.user?.code, activeLink);
			} catch (e) {
				console.log(e);
			}
		};

		checkViewMenu();
	}, []);

	const phoneFormat = (s, plus = true) => {
		const startsWith = plus ? '+7' : '8';

		let phone = s.replace(/[^0-9]/g, '');
		if (phone.startsWith('7') && plus) {
			phone = phone.substr(1);
		}
		if (phone.startsWith('8')) {
			phone = phone.substr(1);
		}

		return phone.replace(
			/(\d{3})(\d{3})(\d{2})(\d{2})/g,
			`${startsWith} $1 $2-$3-$4`
		);
	};

	const toGoVipPage = () => {
		dispatch(setIsCheckFromPage(true));
		localStorage.setItem('isCheckFromPage', JSON.stringify(true));
		window.open('/premium', '_blank');
	};

	console.log(checkDownloadedApp)

	return (
		<>
			{statusPayment &&
				persent &&
				statusPayment !== 'prepaid' &&
				statusPayment !== 'paid' &&
				persent <= 4 &&
				endDatePremium &&
				(width > 575 ? (
					<header onClick={toGoVipPage} className={s.header}>
						<img src='/static/lk/background.png' alt='Баннер' />

						<div className={s.header_btn}>Узнать больше</div>
					</header>
				) : (
					<header className={s.header}>
						<div onClick={toGoVipPage}>
							<img
								style={{ width: '100%' }}
								src='/static/lk/background-mobile.png'
								alt='Баннер'
							/>
						</div>
					</header>
				))}

			<div className={s.application}>
				{checkDownloadedApp === 'failure' && (
					<div className={s.wrapper_step1}>
						<div className={s.wrapper_title}>
							<img src='/static/lk/iconTime.svg' alt='Иконка' />
							{width > 575 ? (
								<h4 className={s.subtitle_step1}>3. Мобильное приложение</h4>
							) : (
								<h4 className={s.subtitle_step1}>Шаг 3</h4>
							)}
						</div>

						{width > 575 ? (
							<h1 className={`${s.title} lk-title`}>
								Скачай приложение, чтобы понять, как исполнители получают заказы
							</h1>
						) : (
							<>
								<h1 className={`${s.title} lk-title`}>Скачай приложение</h1>

								<p className={s.descr_mobile}>
									Чтобы понять, как исполнители получают заказы
								</p>
							</>
						)}
					</div>
				)}

				{checkDownloadedApp === 'success' && (
					<div className={s.wrapper}>
						<div className={s.wrapper_title}>
							<img src='/static/lk/iconDoneBig.svg' alt='Иконка' />
							{width > 575 ? (
								<h4 className={s.subtitle_step2}>3. Мобильное приложение</h4>
							) : (
								<h4 className={s.subtitle_step2}>Шаг 3</h4>
							)}
						</div>

						{width > 575 ? (
							<h1 className={`${s.title} lk-title`}>
								Skilla Работа в телефоне — ты в сильной команде!{' '}
							</h1>
						) : (
							<h1 className={`${s.title} lk-title`}>
								Skilla Работа <br></br> в телефоне — ты <br></br> в сильной
								команде!{' '}
							</h1>
						)}

						<p className={s.descr}>
							Приложение для подработки по гибкому графику. Тысячи заказов
							каждый день в России
						</p>
					</div>
				)}

				{checkDownloadedApp === 'failure' && (
					<ul className={s.list}>
						<UlItem>
							<div className={s.done}>
								<svg
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z'
										fill='#002CFB'
									/>
									<path
										d='M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z'
										fill='#002CFB'
									/>
									<path
										d='M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z'
										fill='#002CFB'
									/>
								</svg>
							</div>

							<span>Скачай бесплатное приложение Skilla Работа</span>
						</UlItem>

						<UlItem>
							<div className={s.done}>
								<svg
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z'
										fill='#002CFB'
									/>
									<path
										d='M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z'
										fill='#002CFB'
									/>
									<path
										d='M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z'
										fill='#002CFB'
									/>
								</svg>
							</div>

							<span>
								Укажи при входе свой номер{' '}
								{phoneFormat(
									user?.user?.phones[0]?.phone || user?.user?.phones[1]?.phone
								)}
							</span>
						</UlItem>

						<UlItem>
							<div className={s.done}>
								<svg
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z'
										fill='#002CFB'
									/>
									<path
										d='M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z'
										fill='#002CFB'
									/>
									<path
										d='M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z'
										fill='#002CFB'
									/>
								</svg>
							</div>

							<span>Обнови страницу для проверки</span>
						</UlItem>
					</ul>
				)}

				{checkDownloadedApp === 'success' && width > 575 && (
					<>
						<ul className={s.list}>
							<UlItem>
								<div className={s.done}>
									<svg
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											d='M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z'
											fill='#002CFB'
										/>
										<path
											d='M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z'
											fill='#002CFB'
										/>
										<path
											d='M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z'
											fill='#002CFB'
										/>
									</svg>
								</div>
								<span>Никакого резюме</span>
							</UlItem>

							<UlItem>
								<div className={s.done}>
									<svg
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											d='M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z'
											fill='#002CFB'
										/>
										<path
											d='M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z'
											fill='#002CFB'
										/>
										<path
											d='M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z'
											fill='#002CFB'
										/>
									</svg>
								</div>

								<span>Работа в любом месте</span>
							</UlItem>

							<UlItem>
								<div className={s.done}>
									<svg
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											d='M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z'
											fill='#002CFB'
										/>
										<path
											d='M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z'
											fill='#002CFB'
										/>
										<path
											d='M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z'
											fill='#002CFB'
										/>
									</svg>
								</div>

								<span>Без рекламы. Без платных подписок.</span>
							</UlItem>

							<UlItem>
								<div className={s.done}>
									<svg
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											d='M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z'
											fill='#002CFB'
										/>
										<path
											d='M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z'
											fill='#002CFB'
										/>
										<path
											d='M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z'
											fill='#002CFB'
										/>
									</svg>
								</div>

								<span>Чат поддержки</span>
							</UlItem>
						</ul>

						<div className={s.vc}>
							<a
								href='https://vc.ru/growth/627594-kak-zapusk-mobilnogo-prilozheniya-skilla-rabota-prevratilsya-iz-fakapa-v-proryv-i-izmenil-rasklad-sil-na-rynke-v-300-mlrd'
								target='_blank'
							>
								<img src='/static/lk/vc.png' alt='vc' />
							</a>

							<p>
								Как запуск мобильного приложения Skilla Работа для самозанятых
								превратился из факапа в прорыв и изменил расклад сил на рынке в
								300 миллиардов
							</p>

							<a
								href='https://vc.ru/growth/627594-kak-zapusk-mobilnogo-prilozheniya-skilla-rabota-prevratilsya-iz-fakapa-v-proryv-i-izmenil-rasklad-sil-na-rynke-v-300-mlrd'
								target='_blank'
								className={s.btn}
							>
								Читать статью на vc.ru
							</a>
						</div>
					</>
				)}

				{checkDownloadedApp === 'success' && width <= 575 && (
					<>
						<ul className={s.list}>
							<UlItem>
								<div className={s.done}>
									<svg
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											d='M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z'
											fill='#002CFB'
										/>
										<path
											d='M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z'
											fill='#002CFB'
										/>
										<path
											d='M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z'
											fill='#002CFB'
										/>
									</svg>
								</div>
								<div className={s.wrapper_mobile}>
									<span>Никакого резюме</span>
								</div>
							</UlItem>

							<UlItem>
								<div className={s.done}>
									<svg
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											d='M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z'
											fill='#002CFB'
										/>
										<path
											d='M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z'
											fill='#002CFB'
										/>
										<path
											d='M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z'
											fill='#002CFB'
										/>
									</svg>
								</div>

								<div className={s.wrapper_mobile}>
									<span>Работа в любом месте</span>
								</div>
							</UlItem>

							<UlItem>
								<div className={s.done}>
									<svg
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											d='M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z'
											fill='#002CFB'
										/>
										<path
											d='M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z'
											fill='#002CFB'
										/>
										<path
											d='M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z'
											fill='#002CFB'
										/>
									</svg>
								</div>

								<div className={s.wrapper_mobile}>
									<span>Без рекламы</span>
								</div>
							</UlItem>

							<UlItem>
								<div className={s.done}>
									<svg
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											d='M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z'
											fill='#002CFB'
										/>
										<path
											d='M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z'
											fill='#002CFB'
										/>
										<path
											d='M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z'
											fill='#002CFB'
										/>
									</svg>
								</div>

								<div className={s.wrapper_mobile}>
									<span>Поддержка 24/7</span>
								</div>
							</UlItem>
						</ul>
						<div className={s.vc}>
							<a
								href='https://vc.ru/growth/627594-kak-zapusk-mobilnogo-prilozheniya-skilla-rabota-prevratilsya-iz-fakapa-v-proryv-i-izmenil-rasklad-sil-na-rynke-v-300-mlrd'
								target='_blank'
							>
								<img src='/static/lk/vc.png' alt='vc' />
							</a>

							<p>
								Как запуск мобильного приложения Skilla Работа для самозанятых
								превратился из факапа в прорыв и изменил расклад сил на рынке в
								300 миллиардов
							</p>

							<a
								href='https://vc.ru/growth/627594-kak-zapusk-mobilnogo-prilozheniya-skilla-rabota-prevratilsya-iz-fakapa-v-proryv-i-izmenil-rasklad-sil-na-rynke-v-300-mlrd'
								target='_blank'
								className={s.btn}
							>
								Читать статью на vc.ru
							</a>
						</div>
					</>
				)}

				{checkDownloadedApp === 'failure' && (
					<div className={s.brands}>
						<div className={s.brand}>
							<a
								href='https://apps.apple.com/ru/app/skilla-work/id1531442328'
								target='_bkank'
							>
								<img src='/static/lk/apple.png' alt='apple' />
							</a>
						</div>

						<div className={s.brand}>
							<a
								href='https://play.google.com/store/apps/details?id=com.skilla.Skilla'
								target='_blank'
							>
								<img src='/static/lk/google.png' alt='google' />
							</a>
						</div>

						<div className={s.brand}>
							<a
								href='https://appgallery.huawei.com/#/app/C103862195?channelId=Skilla%20Official&id=1f01b81b103942dc8b9033f135eff478&s=38C79299AC884CC8EAC853E9E0FBDDCF2F32B4BC9BE70C49CE2B51F0D56F567D&detailType=0&v='
								target='_blank'
							>
								<img src='/static/lk/appGallery.png' alt='appGallery' />
							</a>
						</div>
					</div>
				)}

				{checkDownloadedApp === 'failure' && (
					<div className={s.vc}>
						<a
							href='https://vc.ru/growth/627594-kak-zapusk-mobilnogo-prilozheniya-skilla-rabota-prevratilsya-iz-fakapa-v-proryv-i-izmenil-rasklad-sil-na-rynke-v-300-mlrd'
							target='_blank'
						>
							<img src='/static/lk/vc.png' alt='vc' />
						</a>

						<p>
							Как запуск мобильного приложения Skilla Работа для самозанятых
							превратился из факапа в прорыв и изменил расклад сил на рынке в
							300 миллиардов
						</p>

						<a
							href='https://vc.ru/growth/627594-kak-zapusk-mobilnogo-prilozheniya-skilla-rabota-prevratilsya-iz-fakapa-v-proryv-i-izmenil-rasklad-sil-na-rynke-v-300-mlrd'
							target='_blank'
							className={s.btn}
						>
							Читать статью на vc.ru
						</a>
					</div>
				)}

				{checkDownloadedApp === 'failure' ? (
					<img
						className={s.illustation}
						src={
							width > 575
								? '/static/lk/illustration/illustrationAppStep1.png'
								: '/static/lk/illustration/illustrationAppStep1-mobile.png'
						}
						alt='Иллюстрация'
					/>
				) : (
					<img
						className={`${s.illustation} ${s.illustation_success}`}
						src={
							width > 575
								? '/static/lk/illustration/illustrationAppStep1.png'
								: '/static/lk/illustration/illustrationAppStep1-mobile.png'
						}
						alt='Иллюстрация'
					/>
				)}

				{checkDownloadedApp === 'failure' && width > 575 && (
					<div className={s.qr}>
						<img src='/static/lk/qr.png' alt='qr' />
					</div>
				)}
			</div>
		</>
	);
})

export default Application;
