import classNames from 'classnames'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { Helmet } from 'react-helmet-async'

function HeaderPolitics({ activeTarget }) {
  const [link, setLink] = useState('skilla.ru')
  const location = window.location.href
  useEffect(() => {
    console.log(location)
    const site = `${location}`
    console.log(site)
    const domen = site.slice(8, -9)
    console.log(domen)
    setLink(domen)
  }, [location])

  return (
    <>
      <Helmet>
        <title>Политика конфиденциальности</title>
        {/*<meta name="title" content="Аутсорсинг персонала на территории РФ и СНГ" />
        <meta
          name="description"
          content="Предоставляем услуги аутсорсинга персонала на территории РФ и СНГ. Аутсорсинг персонала от Скилла: подбор персонала, выполнение заказов крупных ритейлеров, грузчики, клининг, обслуживание ТЦ"
        />
        <meta
          name="keywords"
          content="аутсорсинг, аутсорсинг персонала, подбор персонала, компании аутсорсинг, поиск сотрудников, найм персонала, временные работники, специалисты, бизнес, компания, услуги, работа, вакансии, фриланс, remote work, подбор кадров"
        />
        <meta name="robots" content="index, follow" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="Russian" />
        <link rel="canonical" href="https://skilla.ru/customer/" />
        <script className="structured-data-list" type="application/ld+json">
          {JSON.stringify(schemaData)}
        </script>
        Open Graph
        <meta property="og:type" content="business.business" />
        <meta property="og:title" content="Аутсорсинг персонала на территории РФ и СНГ" />
        <meta
          property="og:description"
          content="Предоставляем услуги аутсорсинга персонала на территории РФ и СНГ. Аутсорсинг персонала от Скилла: подбор персонала, выполнение заказов крупных ритейлеров, грузчики, клининг, обслуживание ТЦ"
        />
        <meta property="og:url" content="https://skilla.ru/customer" />
        <meta property="og:image" content="https://skilla.ru/static/customer/technologies/mw.webp" />
        <meta property="og:site_name" content="Скилла Работа" />
        <meta property="og:locale" content="ru_RU" />*/}
      </Helmet>
      <header
        className={classNames({
          'header header--customer': activeTarget === 'customer',
          'header header--worker': activeTarget === 'worker',
          'header header--business': activeTarget === 'business',
        })}
      >
        <div className="container">
          <div className="header__content">
            <h1 className="header__title">Информация о политике конфиденциальности</h1>
            <p className="header__description">
              Мы признаем важность конфиденциальности информации. В этом документе описывается, какую личную
              информацию мы получаем и собираем, когда Вы пользуетесь сайтом {`${link}.`} Мы надеемся, что эти
              сведения помогут Вам принимать осознанные решения в отношении предоставляемой нам личной
              информации.
              <br />
              <br />
              <b>Общедоступная информация</b>
              <br />
              Если Вы просто просматриваете сайт без регистрации, информация о Вас не публикуется на сайте.
              Когда Вы пишете или редактируете материалы на сайте, вы публикуете каждое слово из того, что
              написали, и эта информация будет храниться и показываться другим посетителям проекта. Это
              касается статей, материалов, личных страниц пользователей, комментариев и т.п.
              <br />
              <br />
              <b>Идентификация посетителей</b>
              <br />
              Вы можете зарегистрироваться на сайте, а можете не регистрироваться. Если Вы зарегистрировались,
              Вы будете идентифицироваться Вашим именем участника. Это может быть ваше настоящее имя (если вы
              этого хотите) или Вы можете предпочесть публиковаться под псевдонимом — тем именем, которым
              представились при создании учётной записи. Другие зарегистрированные посетители сайта смогут
              посмотреть данные, указанные Вами при регистрации.
              <br />
              <br />
              <b>Электронная почта</b>
              <br />
              Адрес электронной почты, указываемый Вами при регистрации, не показывается другим посетителям
              сайта. Мы можем сохранять сообщения электронной почте и другие письма, оправленные
              пользователями, чтобы обрабатывать вопросы пользователей, отвечать на запросы и совершенствовать
              наши службы.
              <br />
              <br />
              <b>Цели сбора и обработки персональной информации пользователей</b>
              <br />
              На главной странице присутствует возможность записаться. Ваше добровольное согласие оставить
              свои контакты для связи подтверждается путем ввода Вашего Имени и E-mail и прочих данных в форму
              обратной связи. Имя используется для личного обращения к Вам, а e-mail — для отправления Вам
              писем, содержащих новости компании "Скилла". А так же предложения принять участие в
              маркетинговых исследованиях, полезные материалы по тематике. Пользователь предоставляет свои
              данные добровольно, после чего ему высылается письмо, в котором необходимо дополнительно
              подтвердить подписку на предлагаемую рассылку.
              <br />
              <br />
              <b>Условия обработки и её передачи третьим лицам</b>
              <br />
              Ваши Имя и Е-мейл используются строго в соответствии с политикой конфиденциальности сервиса{' '}
              {`${link}`} и хранятся на его защищенных серверах. Ваши Имя и Е-мейл никогда, ни при каких
              условиях не будут переданы третьим лицам, исключая случаи, которые связаны с исполнением
              законодательства. В каждом письме присутствует ссылка «отписаться от рассылки», с помощью
              которой Вы можете отказаться от получения вышеописанной информации в любое время и полностью
              удалить из базы данных Ваши Имя и Е-мейл.
              <br />
              <br />
              <b>Протоколирование</b>
              <br />
              При каждом посещении сайта наши серверы автоматически записывают информацию, которую Ваш браузер
              передает при посещении веб-страниц. Как правило эта информация включает запрашиваемую
              веб-страницу, IP-адрес компьютера, тип браузера, языковые настройки браузера, дату и время
              запроса, а также один или несколько файлов cookie, которые позволяют точно идентифицировать Ваш
              браузер.
              <br />
              <br />
              <b>Куки (Cookie)</b>
              <br />
              На сайте происходит сбор данных о посетителях с помощью сервисов Яндекс Метрика на главной
              странице. Эти данные служат для сбора информации о действиях посетителей на сайте, для улучшения
              качества его содержания и возможностей. В любое время Вы можете изменить параметры в настройках
              Вашего браузера таким образом, чтобы браузер перестал сохранять все файлы cookie, а так же
              оповещал их об отправке. При этом следует учесть, что в этом случае некоторые сервисы и функции
              могут перестать работать.
              <br />
              <br />
              <b>Изменение Политики конфиденциальности</b>
              <br />
              На этой странице Вы сможете узнать о любых изменениях данной политики конфиденциальности. В
              особых случаях, Вам будет выслана информация на Ваш e-mail.
              <br />
              <br />
              <b>Обратная связь</b>
              <br />
              Вы можете задать любые вопросы, написав на e-mail: info@skilla.ru
            </p>
          </div>
        </div>
      </header>
    </>
  )
}

export default HeaderPolitics
