import React, { useEffect, useState, memo } from 'react'
import { XAxis, YAxis, CartesianGrid, Tooltip, Area, AreaChart, Line, LineChart } from 'recharts'
import { getRevenueGraph } from '../Api'
import { useParams } from 'react-router-dom'

const CustomTooltip = memo(function CustomTooltip({ active, payload, label, type }) {
  if (active && payload && payload.length) {
    return (
      <div className="chart-tooltip">
        <p className="chart-tooltip__title">{label}</p>
        {type === 'article' ? (
          <p className="chart-tooltip__revenue">
            {payload[0].payload.revenue.toLocaleString('ru-RU')} руб
            <br></br> <span>Выручка</span>
          </p>
        ) : type !== 'customer' ? (
          <p className="chart-tooltip__revenue">
            Выручка: {payload[0].payload.revenue.toLocaleString('ru-RU')} ₽
          </p>
        ) : (
          ''
        )}

        {type === 'article' ? null : (
          <p className="chart-tooltip__orders">
            Заказов: {payload[0].payload.ordersReal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
          </p>
        )}
      </div>
    )
  }

  return null
})

const Chart = memo(function Chart({ page, dataPartner, type }) {
  const isCustomer = page === 'customer' ? true : false
  const [isLoading, setIsLoading] = useState(true)
  const [chartData, setChartData] = useState([])
  const [chartDataArticle, setChartDataArticle] = useState([])
  const [searchCityType, setSearchCityType] = useState('year')
  const [width, setWidth] = useState(window?.innerWidth)

  const { alias } = useParams()

  useEffect(() => {
    let chartArticle = []

    dataPartner &&
      dataPartner.map((item) => {
        const dateMut = new Date(item.date)

        const monthMas = [
          'Янв',
          'Фев',
          'Март',
          'Апр',
          'Май',
          'Июнь',
          'Июль',
          'Авг',
          'Сент',
          'Окт',
          'Нояб',
          'Дек',
        ]
        chartArticle.push({
          ...item,
          revenue: item.revenue,
          date: `${monthMas[dateMut.getMonth()]} ${dateMut.getFullYear()}`,
        })
      })

    setChartDataArticle(chartArticle)
  }, [alias])

  useEffect(() => {
    setIsLoading(true)

    getRevenueGraph(searchCityType)
      .then((response) => {
        let mas = []

        response.data.forEach((item) => {
          const date = new Date(item.date)

          const monthMas = [
            'Янв',
            'Фев',
            'Март',
            'Апр',
            'Май',
            'Июнь',
            'Июль',
            'Авг',
            'Сент',
            'Окт',
            'Нояб',
            'Дек',
          ]

          mas.push({
            revenue: !isCustomer ? item.revenue : null,
            orders: item.orders * 500,
            ordersReal: item.orders,
            date: `${monthMas[date.getMonth()]} ${date.getFullYear()}`,
          })
        })

        setChartData(mas)
        setIsLoading(false)
      })
      .catch((e) => console.log(e))
  }, [searchCityType])

  const CustomizedLabel = ({ x, y, value, index, step, zeroInclude }) => {
    let flag = false

    if (zeroInclude && index === 0) {
      flag = true
    } else {
      flag = (index + 1) % step === 0
    }

    return page !== 'article' ? (
      <text x={x} y={y} dy={-10} fill="#fff" fontSize={width > 768 ? 16 : 14}>
        {searchCityType !== 'year' || width <= 768
          ? flag
            ? value.toString().slice(0, 3) + ' млн'
            : null
          : value.toString().slice(0, 3) + ' млн'}
      </text>
    ) : (
      <text x={x} y={y} dy={-4} fill="#000" fontSize={width > 768 ? 18 : 16}>
        {value.toString().length >= 7
          ? flag
            ? `${
                value
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  .slice(0, 4) + ' млн'
              }`
            : null
          : value.toString().length <= 6
            ? flag
              ? `${
                  value
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    .slice(0, 3) + ' тыс'
                }`
              : null
            : ''}
      </text>
    )
  }

  const CustomizedLabelOrders = ({ x, y, value, index, step, zeroInclude }) => {
    let flag = false

    if (zeroInclude && index === 0) {
      flag = true
    } else {
      flag = (index + 1) % step === 0
    }
    if (!isCustomer)
      return (
        <text x={x} y={y} dy={-60} fill="#fff" fontSize={width > 768 ? 16 : 13}>
          {searchCityType !== 'year' || width <= 768
            ? flag
              ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
              : null
            : value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
        </text>
      )

    if (isCustomer)
      return (
        <text x={x} y={y} dy={-7} fill="#ffffff" fontSize={width > 768 ? 16 : 13}>
          {searchCityType !== 'year' || width <= 768
            ? flag
              ? (value / 500).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
              : null
            : (value / 500).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
        </text>
      )
  }

  useEffect(() => {
    const anchor = window.location.hash.split('#')[1]
    if (anchor) {
      const anchorEl = document.getElementById(anchor)
      if (anchorEl) {
        anchorEl.scrollIntoView({
          top: anchorEl.offsetTop,
          behavior: 'smooth',
        })
      }
    }
  })

  const changeSearchCityType = (type) => {
    setSearchCityType(type)
  }

  useEffect(() => {
    const cb = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', cb)

    return () => window.removeEventListener('resize', cb)
  }, [])

  const revenues = () => {
    const total = chartData.map((item) => item.revenue).reduce((a, b) => a + b, 0)

    const numStr = total.toString()
    if (searchCityType === 'year') {
      const firstTwoDigits = numStr.slice(0, 2)
      return `${firstTwoDigits[0]},${firstTwoDigits[1]} млрд руб в год`
    } else if (searchCityType === '2year') {
      const firstThreeDigits = numStr.slice(0, 2)
      return `${firstThreeDigits[0]},${firstThreeDigits[1]} млрд руб за два года`
    } else {
      const firstThreeDigits = numStr.slice(0, 3)
      return `${firstThreeDigits[0]}${firstThreeDigits[1]},${firstThreeDigits[2]} млрд руб за три года`
    }
  }

  if (page === 'article') {
    return (
      <>
        <AreaChart
          width={width > 1600 ? 1007 : width <= 768 ? 375 : 953}
          height={270}
          margin={{
            top: 30,
            right: 0,
            left: 0,
            bottom: 0,
          }}
          data={chartDataArticle}
        >
          <CartesianGrid />
          <XAxis dataKey="date" dy={8.5} dx={20} />
          <YAxis YAxis type="number" domain={[0, chartDataArticle]} />

          <Tooltip content={<CustomTooltip type="article" />} />

          <Area
            type="linear"
            dataKey="revenue"
            fill="rgba(0, 204, 10, 0.3)"
            stroke="#00CC6E"
            activeDot={{ stroke: '#647FFF', strokeWidth: '3px' }}
            dot={{ fill: '#000', strokeWidth: width > 768 ? '5px' : '1px' }}
            label={<CustomizedLabel step={width > 768 ? 2 : 5} zeroInclude={true} />}
          />
        </AreaChart>

        <div className="chart__info-article article-info">
          <div className="article-info__text">Выручка предпринимателя по месяцам</div>
          <div className="article-info__wrapper">
            <span>Данные онлайн из</span>
            <a href="https://skilla.ru/lk" target="_bkank" className="chart__link">
              <img className="article-info__img" src="/static/article/icon/skilla.png" alt="Skilla" />
            </a>
            <span className="icon arrow-chart"></span>
          </div>
        </div>
      </>
    )
  }

  return (
    <section className="chart">
      <div className="container container_maps">
        <div className="maps__text">
          {isCustomer ? (
            <>
              <h2 id="trigger/8" className="maps__title" style={{ color: '#fff' }}>
                Актуальная статистика проведенных работ — <span />
                показатель надежности Скилла
              </h2>
              <p className="maps__description">75 городов, 350 офисов партнеров, 10 часовых поясов</p>
            </>
          ) : type === 'about' ? (
            <>
              <h2 className="maps__title" style={{ color: '#fff' }}>
                <span>Ежегодный график</span> отчётности Скилла с 2010 года
              </h2>
              <p className="maps__description">
                Онлайн-аналитика выручки и заказов, формируемая с помощью собственной CRM-системы компании -
                Skilla IS
              </p>
            </>
          ) : (
            <>
              <h2 id="trigger/3" className="maps__title" style={{ color: '#fff' }}>
                <span>{revenues()}</span> — стань частью партнёрской сети Скилла в России
              </h2>
              <p className="maps__description">75 городов, 350 офисов партнеров, 10 часовых поясов</p>
            </>
          )}
        </div>
        <div className="maps__tabs">
          <button
            className={searchCityType === 'year' ? 'maps__tab maps__tab_active' : 'maps__tab'}
            onClick={() => changeSearchCityType('year')}
          >
            Год
          </button>
          <button
            className={searchCityType === '2year' ? 'maps__tab maps__tab_active' : 'maps__tab'}
            onClick={() => changeSearchCityType('2year')}
          >
            Два года
          </button>
          <button
            className={searchCityType === '3year' ? 'maps__tab maps__tab_active' : 'maps__tab'}
            onClick={() => changeSearchCityType('3year')}
          >
            Три года
          </button>
        </div>
      </div>
      <div className="container_smail">
        <div className="chart__chart">
          {isLoading && (
            <div className="chart__loader">
              <div className="lds-dual-ring white"></div>
            </div>
          )}
          {!isLoading && isCustomer && (
            <AreaChart
              width={width > 1600 ? 1400 : width <= 768 ? 343 : width <= 1600 ? 1200 : 1200}
              height={345}
              data={chartData}
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <defs>
                <linearGradient id="colorCustomer" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#001A97" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#001A97" stopOpacity={0} />
                </linearGradient>
              </defs>
              <CartesianGrid />
              <XAxis dataKey="date" />
              <Tooltip content={<CustomTooltip type="customer" />} />
              <Area
                strokeWidth={2}
                type="linear"
                dataKey="orders"
                fillOpacity={0.3}
                fill="url(#colorCustomer)"
                stroke="#3357FF"
                activeDot={{ stroke: '#647FFF', strokeWidth: '3px' }}
                dot={{ fill: '#647FFF', strokeWidth: '0' }}
                label={
                  <CustomizedLabelOrders
                    step={
                      width > 768
                        ? 3
                        : width <= 768 && searchCityType === 'year'
                          ? 5
                          : width <= 768 && searchCityType === '2year'
                            ? 5
                            : width <= 768 && searchCityType === '3year'
                              ? 10
                              : 5
                    }
                    zeroInclude={true}
                  />
                }
              />

              <Area
                type="linear"
                dataKey="ordersReal"
                fillOpacity={0.3}
                fill="url(#colorCustomer)"
                stroke="#001A97"
              />
            </AreaChart>
          )}
          {!isLoading && !isCustomer && (
            <LineChart
              width={width > 1600 ? 1400 : width <= 768 ? 343 : width <= 1600 ? 1200 : 1200}
              height={345}
              data={chartData}
              margin={{
                top: 10,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />

              <Tooltip content={<CustomTooltip />} />
              <Line
                type="linear"
                dataKey="revenue"
                stroke="#ffffff"
                label={
                  <CustomizedLabel
                    step={
                      width > 768
                        ? 3
                        : width <= 768 && searchCityType === 'year'
                          ? 5
                          : width <= 768 && searchCityType === '2year'
                            ? 5
                            : width <= 768 && searchCityType === '3year'
                              ? 10
                              : 5
                    }
                    zeroInclude={true}
                  />
                }
              />

              <Line
                strokeWidth={2}
                type="linear"
                fill="rgba(0, 82, 204, 0.9)"
                dataKey="revenue"
                stroke="rgba(0, 204, 110, 1)"
                activeDot={{
                  stroke: '#00FF88',
                  fill: '#00CC6E',
                  strokeWidth: '3px',
                }}
                dot={{
                  fill: '#00CC6E',
                  stroke: '#00FF88',
                  strokeWidth: '0px',
                }}
              />

              <Line
                strokeWidth={2}
                type="linear"
                dataKey="orders"
                stroke="#FFD500"
                activeDot={{
                  stroke: '#FFE666',
                  fill: '#FFD500',
                  strokeWidth: '3px',
                }}
                dot={{
                  stroke: '#FFD500',
                  fill: '#FFD500',
                  strokeWidth: '0',
                }}
              />

              <Line
                type="linear"
                dataKey="ordersReal"
                stroke="#ffffff"
                label={
                  <CustomizedLabelOrders
                    step={
                      width > 768
                        ? 3
                        : width <= 768 && searchCityType === 'year'
                          ? 5
                          : width <= 768 && searchCityType === '2year'
                            ? 5
                            : width <= 768 && searchCityType === '3year'
                              ? 10
                              : 5
                    }
                    zeroInclude={true}
                  />
                }
              />
            </LineChart>
          )}

          {!isLoading && !isCustomer && (
            <div className="chart__info">
              <div className="chart-legend">
                <span className="chart-legend__revenue">Выручка</span>
                <span className="chart-legend__orders">Заказы</span>
              </div>
              <a href="https://skilla.ru/lk" target="_blank" className="chart__link">
                Данные онлайн из
                <picture>
                  <source srcSet="/static/customer/chart/icon.webp" type="image/webp" />
                  <img src="/static/customer/chart/icon.png" alt="Skilla" />
                </picture>
                <span className="icon arrow-chart"></span>
              </a>
            </div>
          )}
          {!isLoading && isCustomer && (
            <a href="https://skilla.ru/lk" target="_blank" className="chart__link">
              Данные онлайн из{' '}
              <picture>
                <source srcSet="/static/customer/chart/icon.webp" type="image/webp" />
                <img src="/static/customer/chart/icon.png" alt="Skilla" />
              </picture>
              <span className="icon arrow-chart"></span>
            </a>
          )}
        </div>
      </div>
    </section>
  )
})

export default Chart
