import { memo } from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'
import ScheduleItem from '../scheduleItem/ScheduleItem'
import s from './sliderEvents.module.scss'

const SliderEvents = memo(({ cat_id, events }) => {
  const settings = {
    className: 'events-slide',
    infinite: false,
    variableWidth: true,
    speed: 300,
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: false,
    arrows: true,

    responsive: [
      {
        breakpoint: 575,
        settings: {
          infinite: false,
          speed: 300,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        },
      },
    ],
  }

  return (
    <div className={s.sliderEvents}>
      <StyledSlider>
        <Slider {...settings}>
          {events?.map((item) => (
            <ScheduleItem component={'nextEvents'} key={item.id} cat_id={cat_id} {...item} />
          ))}
        </Slider>
      </StyledSlider>
    </div>
  )
})

const StyledSlider = styled.div`
  .events-slide {
    position: relative;

    .slick-slide {
      margin-right: 8px;
    }

    .slick-list {
      &::after {
        content: '';
        position: absolute;
        right: -1px;
        top: 0;
        z-index: 100;
        width: 100px;
        height: 100%;
        background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
      }
    }

    .slick-prev,
    .slick-next {
      position: absolute;
      top: -40px;
      background-color: transparent;
    }

    .slick-next {
      right: 0;
    }

    .slick-prev {
      left: inherit;
      right: 30px;
    }

    .slick-prev:before {
      content: url('/static/lk/slideArrowLeft.svg');
    }
    .slick-next:before {
      content: url('/static/lk/slideArrowRight.svg');
    }
  }
`

export default SliderEvents
