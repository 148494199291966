import styled from 'styled-components'

export const Lent = ({ deg, page, margin, marginMobile }) => {
  return (
    <Container deg={deg} page={page} margin={margin} marginMobile={marginMobile}>
      <SkillaContainer>
        <img src="/static/logo-lent.svg" alt="Logo" />
      </SkillaContainer>
      <IconContainer>
        <Icon>
          <svg
            fill="#fff"
            width="16px"
            height="16px"
            viewBox="0 0 0.3 0.3"
            xmlns="http://www.w3.org/2000/svg"
            id="arrow"
          >
            <path d="M0.166 0.046a0.02 0.02 0 0 1 0.028 0l0.09 0.09a0.02 0.02 0 0 1 0 0.028l-0.09 0.09a0.02 0.02 0 0 1 -0.028 -0.028L0.22 0.17H0.03a0.02 0.02 0 0 1 0 -0.04H0.22L0.166 0.074a0.02 0.02 0 0 1 0 -0.028" />
          </svg>
        </Icon>
      </IconContainer>
      <Text>Просто работай</Text>
      <IconContainer>
        <Icon>
          <svg
            fill="#fff"
            width="16px"
            height="16px"
            viewBox="0 0 0.3 0.3"
            xmlns="http://www.w3.org/2000/svg"
            id="arrow"
          >
            <path d="M0.166 0.046a0.02 0.02 0 0 1 0.028 0l0.09 0.09a0.02 0.02 0 0 1 0 0.028l-0.09 0.09a0.02 0.02 0 0 1 -0.028 -0.028L0.22 0.17H0.03a0.02 0.02 0 0 1 0 -0.04H0.22L0.166 0.074a0.02 0.02 0 0 1 0 -0.028" />
          </svg>
        </Icon>
      </IconContainer>
      <SkillaContainer>
        <img src="/static/logo-lent.svg" alt="Logo" />
      </SkillaContainer>
      <IconContainer>
        <Icon>
          <svg
            fill="#fff"
            width="16px"
            height="16px"
            viewBox="0 0 0.3 0.3"
            xmlns="http://www.w3.org/2000/svg"
            id="arrow"
          >
            <path d="M0.166 0.046a0.02 0.02 0 0 1 0.028 0l0.09 0.09a0.02 0.02 0 0 1 0 0.028l-0.09 0.09a0.02 0.02 0 0 1 -0.028 -0.028L0.22 0.17H0.03a0.02 0.02 0 0 1 0 -0.04H0.22L0.166 0.074a0.02 0.02 0 0 1 0 -0.028" />
          </svg>
        </Icon>
      </IconContainer>
      <Text>Просто работай</Text>
      <IconContainer>
        <Icon>
          <svg
            fill="#fff"
            width="16px"
            height="16px"
            viewBox="0 0 0.3 0.3"
            xmlns="http://www.w3.org/2000/svg"
            id="arrow"
          >
            <path d="M0.166 0.046a0.02 0.02 0 0 1 0.028 0l0.09 0.09a0.02 0.02 0 0 1 0 0.028l-0.09 0.09a0.02 0.02 0 0 1 -0.028 -0.028L0.22 0.17H0.03a0.02 0.02 0 0 1 0 -0.04H0.22L0.166 0.074a0.02 0.02 0 0 1 0 -0.028" />
          </svg>
        </Icon>
      </IconContainer>
      <SkillaContainer>
        <img src="/static/logo-lent.svg" alt="Logo" />
      </SkillaContainer>
      <IconContainer>
        <Icon>
          <svg
            fill="#fff"
            width="16px"
            height="16px"
            viewBox="0 0 0.3 0.3"
            xmlns="http://www.w3.org/2000/svg"
            id="arrow"
          >
            <path d="M0.166 0.046a0.02 0.02 0 0 1 0.028 0l0.09 0.09a0.02 0.02 0 0 1 0 0.028l-0.09 0.09a0.02 0.02 0 0 1 -0.028 -0.028L0.22 0.17H0.03a0.02 0.02 0 0 1 0 -0.04H0.22L0.166 0.074a0.02 0.02 0 0 1 0 -0.028" />
          </svg>
        </Icon>
      </IconContainer>
      <Text>Просто работай</Text>
      <IconContainer>
        <Icon>
          <svg
            fill="#fff"
            width="16px"
            height="16px"
            viewBox="0 0 0.3 0.3"
            xmlns="http://www.w3.org/2000/svg"
            id="arrow"
          >
            <path d="M0.166 0.046a0.02 0.02 0 0 1 0.028 0l0.09 0.09a0.02 0.02 0 0 1 0 0.028l-0.09 0.09a0.02 0.02 0 0 1 -0.028 -0.028L0.22 0.17H0.03a0.02 0.02 0 0 1 0 -0.04H0.22L0.166 0.074a0.02 0.02 0 0 1 0 -0.028" />
          </svg>
        </Icon>
      </IconContainer>
      <SkillaContainer>
        <img src="/static/logo-lent.svg" alt="Logo" />
      </SkillaContainer>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 56px;
  transform: rotate(${(props) => props.deg});
  background ${(props) =>
    props.page === 'customer'
      ? `radial-gradient(55.7% 97% at 0% 3%, #000e50 21.6%, rgba(0, 23, 134, 0) 80.42%),
      radial-gradient(20.81% 43.51% at 87.01% 11.68%, #001fb2 0%, rgba(0, 23, 134, 0) 100%),
      radial-gradient(66.95% 140.01% at 66.95% 90.11%, #000e50 0%, #001786 42.05%);`
      : props.page === 'business'
        ? `radial-gradient(41.09% 72.88% at 10.68% 15.98%, #00341c 0%, rgba(0, 91, 48, 0) 100%),
      radial-gradient(37.63% 76.37% at 100% 0%, #007e43 0%, rgba(0, 91, 48, 0) 100%),
      radial-gradient(79.17% 174.71% at 79.17% 78.17%, #00361c 0%, #006636 100%);`
        : props.page === 'worker'
          ? `radial-gradient(63.02% 48.11% at 50% 23.37%, #4c0f7b 0%, rgba(85, 20, 142, 0) 100%),
      radial-gradient(22.68% 53.3% at 96.51% -3.22%, #4c1279 0%, rgba(191, 119, 255, 0) 100%),
      
      radial-gradient(45.31% 97.19% at -0.62% 55.77%, #cc79ff 0%, rgba(123, 34, 197, 0) 100%),
      radial-gradient(70.6% 284.26% at 81.04% 82.79%, #2f0058 0%, #8800ff 100%)`
          : null};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding: 0 10px;
  box-sizing: border-box;
  margin-top: ${(props) => props.margin};
z-index: 1;

    @media (max-width: 1600px) {
        margin:${(props) => props.marginMobile} 0 0 0;
    }

  @media (max-width: 768px) {
    height: 35px;
    gap: 5px;
      margin:${(props) => props.marginMobile} 0 0 0;
  }
`

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
`

const SkillaContainer = styled.div`
  display: flex;
  align-items: center;
  width: 170px;
  height: 38px;

  img {
    width: 170px;
    height: 38px;

    @media (max-width: 768px) {
      width: 80px;
      height: 38px;
    }
  }

  @media (max-width: 768px) {
    width: 80px;
    height: 38px;
  }
`

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 18px;
`

const Text = styled.div`
  color: white;
  font-size: 16px;
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  word-wrap: break-word;
  text-align: center;
  display: inline-block;
  padding: 0 10px;

  @media (max-width: 768px) {
    font-size: 10px;
    padding: 0;
  }
`
