import cn from 'classnames';
import { useEffect, useState } from 'react';
import ReactInputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import {
	getBookPaymentForm,
	getBookPaymentStatus,
	saveBookInfo,
} from '../../../../Api';
import SpinnerDots from '../../../../components/spinnerDots/SpinnerDots';
import { selectMain } from '../../../../store/reducer/main/selector.js';
import s from './form.module.scss';
import './form.scss';

import {
	setAddbookPaymentForm,
	setAddOrderId,
	setAddStatusBook,
	setAddTypeBook,
	setAddWaitingStatusPayBook,
	setIsModalBookProcess,
} from '../../../../store/reducer/main/slice';

const Form = () => {
	const [name, setName] = useState('');
	const [phone, setPhone] = useState('');
	const [comment, setComments] = useState('');
	const [email, setEmail] = useState('');

	const [width, setWidth] = useState(window?.innerWidth);
	const [activeTab, setActiveTab] = useState(1);

	const {
		pageBook,
		statusBook,
		waitingStatusPayBook,
		bookPaymentForm,
		typeBook,
		orderId,
	} = useSelector(selectMain);

	const tabs = ['Печатное издание', 'Электронная версия'];
	const phoneRegExp = /^\+7 \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}$/;
	const emailRegExp =
		/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

	const status = statusBook?.status;

	const dispatch = useDispatch();

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

	useEffect(() => {
		const chechTypeBook = async () => {
			const { data } = await getBookPaymentForm(typeBook);

			fetchInfoBook(null, data?.payment?.order_id);
			dispatch(setAddbookPaymentForm(data));

			if (data.result === 'success') {
				dispatch(setAddOrderId(data?.payment?.order_id));

				localStorage.setItem(
					'orderId',
					JSON.stringify(data?.payment?.order_id)
				);
			}
		};

		chechTypeBook();
	}, [activeTab]);

	useEffect(() => {
		fetchInfoBook(null, orderId);
	}, [status]);

	useEffect(() => {
		if (waitingStatusPayBook && status !== 'paid') {
			updateDate();
		}
	}, [waitingStatusPayBook, status]);

	const onClickTab = (index, type) => {
		setActiveTab(index);
		dispatch(setAddTypeBook(type));
	};

	const updateDate = () => {
		let timeId = setInterval(() => {
			fetchInfoBook(timeId, orderId);
		}, 5000);
	};

	const fetchInfoBook = async (timeId, id) => {
		try {
			const { data } = await getBookPaymentStatus(id);

			dispatch(setAddStatusBook(data));
			localStorage.setItem('statusBook', JSON.stringify(data));

			if (data?.payment?.status === 'paid') {
				localStorage.removeItem('waitingStatusPayBook');
				dispatch(setAddWaitingStatusPayBook(false));
				clearInterval(timeId);
			}

			if (window.location.pathname.substring(1) !== 'book') {
				localStorage.removeItem('waitingStatusPayBook');
				dispatch(setAddWaitingStatusPayBook(false));
				clearInterval(timeId);
			}
		} catch (e) {
			console.log(e);
			clearInterval(timeId);
			localStorage.removeItem('waitingStatusPayBook');
		}
	};

	const addStyleBtn = () => {
		const el = document.getElementById('bookPaymentButton2');

		if (pageBook ? pageBook === 'customer' : window.location.pathname.substring(1) === 'book'
		) {
			el && el.classList.add('btn_blue');
		}

		if (pageBook === 'business') {
			el && el.classList.add('btn_green');
		}

		if (pageBook === 'worker') {
			el && el.classList.add('btn_purple');
		}

		if (activeTab === 1) {
			el && el.classList.add('second_btn');
		}
	};

	setTimeout(() => {
		addStyleBtn();
	});

	const openModal = () => {
		dispatch(setIsModalBookProcess(true));
		localStorage.setItem('modalBookProcess', JSON.stringify(true));
		document.body.style.overflow = 'hidden';
	};

	useEffect(() => {
		const el = document.getElementById('bookPaymentButton2');

		if ((activeTab === 0 && name && phone.match(phoneRegExp) && comment) || (activeTab === 1 && email.match(emailRegExp) && phone.match(phoneRegExp))
		) {
			el && el.removeAttribute('disabled');
			(el && (pageBook ? pageBook === 'customer' : window.location.pathname.substring(1) === 'book') && (el.style.background = '#002cfb'));
			el && pageBook === 'business' && (el.style.background = '#00ff88');
			el && pageBook === 'worker' && (el.style.background = '#8800ff');
		} else {
			setTimeout(() => {
				el && el.setAttribute('disabled', 'disabled');
			});
			el && (el.style.background = '#a2a9b4');
		}

		const cb = () => {
			saveBookInfo(
				typeBook,
				bookPaymentForm?.payment?.order_id,
				name,
				phone,
				email,
				comment
			);

			setName('');
			setPhone('');
			setComments('');
			setEmail('');

			openModal();

			fetchInfoBook(null, bookPaymentForm?.payment?.order_id || orderId);

			setTimeout(() => {
				dispatch(setAddWaitingStatusPayBook(true));
				localStorage.setItem('waitingStatusPayBook', JSON.stringify(true));
			});
		};

		el?.addEventListener('click', cb);

		return () => el?.removeEventListener('click', cb);
	}, [activeTab, name, phone, email, comment]);

	return (
		<div className={s.form}>
			<h2 className={s.title}>Купить книгу</h2>

			<div className={s.tabs}>
				{tabs.map((item, i) => (
					<button
						onClick={() => onClickTab(i, i === 0 ? 'book' : 'ebook')}
						className={cn(s.tab, {
							[s.active]: i === activeTab,
							[s.tab_active_customer]: (pageBook ? pageBook === 'customer' : window.location.pathname.substring(1) === 'book') && i === activeTab,
							[s.tab_active_business]:
								pageBook === 'business' && i === activeTab,
							[s.tab_active_worker]: pageBook === 'worker' && i === activeTab,
						})}
						key={item}
					>
						{item}
					</button>
				))}
			</div>

			{width <= 575 && (
				<img
					className={s.img}
					src={'/static/book/cover-big-mobile.png'}
					alt='изображение'
				/>
			)}

			{activeTab === 0 && (
				<div>
					<div>
						<h3 className={s.subtitle}>Получатель</h3>

						<div className={s.inputs}>
							<input
								className={s.input}
								onChange={e => setName(e.target.value)}
								value={name}
								name='name'
								type='text'
								placeholder='ФИО'
								autoComplete='off'
								maxLength={45}
							/>

							<ReactInputMask
								className={s.input}
								onChange={e => setPhone(e.target.value)}
								value={phone}
								name='number'
								mask='+7 (\999) 999-99-99'
								maskChar=''
								placeholder='+7 (___) ___-__-__'
								autoComplete='off'
							/>

							<textarea
								className={cn(s.input, s.textarea)}
								onChange={e => setComments(e.target.value)}
								value={comment}
								name='comment'
								placeholder='Город, улица, номер дома, кв/офис'
								autoComplete='off'
								maxLength={110}
							></textarea>
						</div>
						<p className={s.descr}>
							ФИО и адрес необходимы для доставки книги по Почте России, срок
							доставки от 3-х дней
						</p>
					</div>

					<div
						className={cn(s.price, {
							[s.price_customer]: pageBook ? pageBook === 'customer' : window.location.pathname.substring(1) === 'book',
							[s.price_business]: pageBook === 'business',
							[s.price_worker]: pageBook === 'worker',
						})}
					>
						<h5>Итого: </h5>

						{!bookPaymentForm?.payment?.sum ? (
							<SpinnerDots />
						) : (
							<p>{bookPaymentForm?.payment?.sum.toLocaleString('ru-RU')} ₽</p>
						)}
					</div>

					<div
						className={s.formApi}
						dangerouslySetInnerHTML={{ __html: bookPaymentForm?.payment?.form }}
					/>
				</div>
			)}

			{activeTab === 1 && (
				<div>
					<div>
						<h3 className={s.subtitle}>Куда отправить</h3>

						<div className={s.inputs}>
							<input
								className={s.input}
								onChange={e => setEmail(e.target.value)}
								value={email}
								name='email'
								type='text'
								placeholder='Email'
								autoComplete='off'
							/>

							<ReactInputMask
								className={s.input}
								onChange={e => setPhone(e.target.value)}
								value={phone}
								name='number'
								mask='+7 (\999) 999-99-99'
								maskChar={false}
								alwaysShowMask={false}
								placeholder='+7 (___) ___-__-__'
								autoComplete='off'
							/>
						</div>
						<p className={s.descr}>
							Книга будет отправлена на указанный email после оплаты
						</p>
					</div>

					<div
						className={cn(s.price, {
							[s.price_customer]: pageBook ? pageBook === 'customer' : window.location.pathname.substring(1) === 'book',
							[s.price_business]: pageBook === 'business',
							[s.price_worker]: pageBook === 'worker',
						})}
					>
						<h5>Итого: </h5>

						{!bookPaymentForm?.payment?.sum ? (
							<SpinnerDots />
						) : (
							<p>{bookPaymentForm?.payment?.sum.toLocaleString('ru-RU')} ₽</p>
						)}
					</div>

					<div
						className={s.formApi}
						dangerouslySetInnerHTML={{ __html: bookPaymentForm?.payment?.form }}
					/>
				</div>
			)}
		</div>
	);
};

export default Form;
