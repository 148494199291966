import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLkBook } from '../../../../../store/reducer/lk/book/selector.js';
import {
	setAddWaitingBook,
	setOpenModal,
} from '../../../../../store/reducer/lk/book/slice';
import Button from '../../../ui/Button/Button';
import Cover from '../cover/Cover';
import s from './buy.module.scss';
import { getLkViewContent } from '../../../../../Api/index.js';
import { selectLKAuth } from '../../../../../store/reducer/lk/authChech/selector.js';
import { postBookInterest } from '../../../../../Api/index.js';

const Buy = () => {
	const [width, setWidth] = useState(window?.innerWidth);

	const { InfoBook } = useSelector(selectLkBook);

	const dispatch = useDispatch();
	

	const status = InfoBook?.payment?.status;
	const newPrice = InfoBook?.payment?.sum;
	const oldPrice = InfoBook?.payment?.old_sum;

	const options = {
		day: 'numeric',
		month: 'long',
	};

	const endSale = InfoBook?.payment?.date_end_sale;

	const date = new Date(endSale);
	const dateFormat = date.toLocaleDateString('ru-RU', options);

	const { user } = useSelector(selectLKAuth);
	const userCode = user?.user?.code;

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

	const buyBook = () => {
		dispatch(setOpenModal(true));
		dispatch(setAddWaitingBook(false));
		localStorage.setItem('isModal', JSON.stringify(true));
		document.body.style.overflow = 'hidden';
	};
	
	const buyLitres = () => {
		window.open('https://www.litres.ru/book/kirill-uporov/genom-predprinimatelya-kak-paren-iz-glubinki-sozdal-s-nulya-69193522/', '_blank');
	};

	const handleBookInterest = () => {
        postBookInterest(userCode)
		.then(res => console.log(res))
		.catch(err => console.log(err))
	}

	return (
		<div className={s.buy}>
			<div className={s.info}>
				<h2>ГЕНОМ ПРЕДПРИНИМАТЕЛЯ</h2>

				<h5>
					Как парень из глубинки создал с нуля миллиардный бизнес в России
				</h5>

				<p>Кирилл Упоров</p>
			</div>

			{width <= 575 && <Cover />}

			<div className={s.btns}>
				<div className={s.wrapper}>
					{status && status === 'paid' && (
						<a
							className={s.readAll}
							href='https://skilla.ru/get-book/'
							target='_blank'
							page='book'
						>
							Читать книгу полностью
						</a>
					)}

					{status && status !== 'paid' && (
						<a  onClick={handleBookInterest}
							className={s.readSnippet}
							href='https://skilla.ru/docs/book_demo.pdf'
							target='_blank'
							page='book'
						>
							Читать фрагмент
						</a>
					)}

					<Button handleClick={buyLitres} page='book' type='white'>
						Купить на Литрес.ру
					</Button>
				</div>

				{status &&
					status !== 'paid' &&
					(oldPrice != newPrice ? (
						<Button
							handleClick={buyBook}
							page='book'
							type='white'
							width={'100%'}
						>
							Купить со скидкой 50% до {dateFormat}
						</Button>
					) : (
						<Button
							handleClick={buyBook}
							page='book'
							type='white'
							width={'100%'}
						>
							Купить книгу
						</Button>
					))}
			</div>

			<div className={s.descr}>
				<p>
					Искренняя и вдохновляющая история успеха, рассказанная российским
					предпринимателем Кириллом Упоровым. Он создал технологичную компанию с
					нуля и заложил основы фундамента успеха для сотен предпринимателей,
					которые выстраиваются в очередь, чтобы стать частью его
					бизнес-империи.
				</p>

				<p>
					Эта книга — не просто история успеха. Кирилл Упоров честно и открыто
					рассказывает о создании бизнеса в современных реалиях, делясь успехами
					и не скрывая провалы. Он объясняет простым языком, что бизнес — это
					свобода и новое качество жизни, а заниматься им может каждый, если
					поймет как использовать свой геном предпринимателя.
				</p>

				<p>
					Может быть, именно эта история поможет тебе сделать первый шаг на пути
					к предпринимательству и переменам в жизни?
				</p>
			</div>

			{/* {width <= 575 && (
				<div className={s.logos}>
					<img src='/static/lk/book/logo-mobile.png' alt='логотип' />
					<img src='/static/lk/book/logo2-mobile.png' alt='логотип' />
					<img src='/static/lk/book/logo3-mobile.png' alt='логотип' />
				</div>
			)} */}
		</div>
	);
};

export default Buy;
