import { Data } from '@react-google-maps/api'

export function timeNow() {
  const date = new Date()
  const time = date.month + date.day + date.time
  console.log(time)
  return time
}

export function comparisonDateNewBp(dateBp) {
  const date = new Date('2024-02-07')
  const date2 = new Date(dateBp)

  if (dateBp === '' || date2 > date) {
    return true
  } else {
    return false
  }
}

export const formatDate = (dateString) => {
  const [year, month, day] = dateString.split('-')
  return `${day}.${month}.${year}`
}

export const dayMonthFormat = (date, dayNum) => {
  const dateAnketa = new Date(date);
  dateAnketa.setDate(dateAnketa.getDate() + dayNum);
  const day = dateAnketa.getDate()
  const month = dateAnketa.getMonth();

  let fMonth;
  switch (month) {
    case 0: fMonth = "января"; break;
    case 1: fMonth = "февраля"; break;
    case 2: fMonth = "марта"; break;
    case 3: fMonth = "апреля"; break;
    case 4: fMonth = "мая"; break;
    case 5: fMonth = "июня"; break;
    case 6: fMonth = "июля"; break;
    case 7: fMonth = "августа"; break;
    case 8: fMonth = "сентября"; break;
    case 9: fMonth = "октября"; break;
    case 10: fMonth = "ноября"; break;
    case 11: fMonth = "декабря"; break;
    default:
  }

  return `${day} ${fMonth}`

}
