import { sendForm } from '../../Api';
import { useState, memo, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactInputMask from 'react-input-mask';
import { selectMain } from '../../store/reducer/main/selector';
import { selectForm } from '../../store/reducer/form/selector';
import {
  setAddFormSubmit,
  setAddSendBusiness,
  setAddPhoneBusiness,
  setAddStep,
  setAddIdCopy,
  setAddLoading,
} from '../../store/reducer/form/slice';
import { setAddOnClickForm } from '../../store/reducer/main/slice';

import RadioGroup from '../RadioGroup';
import FormModal from '../FormModal';
import ModalAnswer from '../ModalAnswer';
import Spinner from '../Spinner';
import './articlesForm.scss';

const ArticlesForm = memo(function ArticlesForm({ page, title, subtitle }) {
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState(false);
  const [formSend, setFormSend] = useState(false);
  const [inn, setInn] = useState(null);
  const [message, setMessage] = useState();
  const [addOnClick, setAddOnClick] = useState(false);
  const [isFormSubmit, setIsFormSubmit] = useState(false);
  const [reqCode, setReqCode] = useState(JSON.parse(localStorage.getItem('reqCode')));

  const dispatch = useDispatch();
  const isMounted = useRef(false);
  const { activeLink } = useSelector(selectMain);

  const {
    loading,
    step,
    phoneBusiness,
    formSendBusiness,
    nameBusiness,
    isExpPeoples,
    isExpBusiness,
    messageWhy,
    emailBusiness,
    id,
    idCopy,
  } = useSelector(selectForm);

  const { activeTarget, sendRequest, utm, onClickForm } = useSelector(selectMain);

  useEffect(() => {
    if (isMounted.current) {
      localStorage.setItem('id', JSON.stringify(id));
      localStorage.setItem('idCopy', JSON.stringify(idCopy));
      localStorage.setItem('phoneBusiness', JSON.stringify(phoneBusiness));
      localStorage.setItem('formSendBusiness', JSON.stringify(formSendBusiness));
      localStorage.setItem('step', JSON.stringify(step));
      localStorage.setItem('nameBusiness', JSON.stringify(nameBusiness));
      localStorage.setItem('emailBusiness', JSON.stringify(emailBusiness));
      localStorage.setItem('messageWhy', JSON.stringify(messageWhy));
      localStorage.setItem('reqCode', JSON.stringify(reqCode));
    }
    isMounted.current = true;
  }, [id, idCopy, phoneBusiness, formSendBusiness, step, nameBusiness, emailBusiness, messageWhy, reqCode]);

  const submitFormDefault = (e) => {
    e.preventDefault();
    let error = false;
    if (phone.match(/^\+7 \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}$/)) {
      setPhoneError(false);
    } else {
      setPhoneError(true);
      error = true;
    }
    if (!error) {
      setFormSend(true);
      sendForm(phone, activeLink, message, inn, id, utm)
        .then((response) => {
          if (response.data.result === 'success') {
            setReqCode(response.data.req.code);
            dispatch(setAddFormSubmit(true));
            setFormSend(true);
            return true;
          } else {
            setFormSend(false);
            return false;
          }
        })
        .catch((e) => {
          setFormSend(false);
          console.log(e);
          return false;
        });
    }
  };

  const submitForm = (e) => {
    e.preventDefault();
    let error = false;
    if (phoneBusiness.match(/^\+7 \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}$/)) {
      setPhoneError(false);
    } else {
      setPhoneError(true);
      error = true;
    }
    if (!error) {
      dispatch(setAddLoading(true));
      sendForm(phoneBusiness, activeLink, message, inn, id, utm)
        .then((response) => {
          if (response.data.result === 'success') {
            setReqCode(response.data.req.code);
            setAddFormSubmit(true);
            setAddOnClick(true);
            dispatch(setAddSendBusiness(true));
            dispatch(setAddLoading(false));
            dispatch(setAddIdCopy(id));
            dispatch(setAddOnClickForm(true));
            if (id !== idCopy) {
              window.ym(90102156, 'reachGoal', 'submitting');
            }
            return true;
          } else {
            dispatch(setAddSendBusiness(false));
            return false;
          }
        })
        .catch((e) => {
          dispatch(setAddSendBusiness(false));
          console.log(e);
          return false;
        });
    }
  };

  if (page === 'partners')
    return !isFormSubmit && !addOnClick ? (
      <div className={!formSendBusiness ? 'articlesForm-footer' : ''}>
        <div
          className={
            formSendBusiness
              ? 'articlesForm-footer__content active'
              : 'articlesForm-footer__content'
          }>
          {!formSendBusiness && <h4 className="articlesForm-footer__title">{title}</h4>}
          {!formSendBusiness && <p className="articlesForm-footer__descr">{subtitle}</p>}

          {loading ? (
            <Spinner />
          ) : (
            <form method="GET" onSubmit={submitForm} className="articlesForm__form">
              {formSendBusiness && !onClickForm && (
                <form>
                  <ModalAnswer
                    phone={phoneBusiness}
                    step={step}
                    nameBusiness={nameBusiness}
                    phoneBusiness={phoneBusiness}
                    formSendBusiness={formSendBusiness}
                    messageWhy={messageWhy}
                    sendRequest={sendRequest}
                    setAddOnClick={setAddOnClick}
                  />
                </form>
              )}
              {!formSendBusiness && (
                <>
                  <ReactInputMask
                    className={
                      phoneError
                        ? 'articlesForm__input-error'
                        : 'articlesForm__input articlesForm__input--footer'
                    }
                    mask="+7 (\999) 999-99-99"
                    maskChar=""
                    alwaysShowMask={false}
                    placeholder="+7 (___) ___-__-__"
                    value={phoneBusiness}
                    required
                    onChange={(e) => dispatch(setAddPhoneBusiness(e.target.value))}
                  />
                  <div className="articlesForm__btn-wrapper">
                    <button
                      onClick={() => dispatch(setAddStep(1))}
                      className="articlesForm__btn articlesForm__btn--footer">
                      Оставить заявку
                    </button>
                  </div>
                  <RadioGroup defaultValue="telegram" />
                </>
              )}
            </form>
          )}
        </div>

        {!formSendBusiness ? (
          <div className="articlesForm-footer__img">
            <img src="/static/article/man.png" alt="Изображение" />
          </div>
        ) : (
          ''
        )}
      </div>
    ) : (
      <FormModal
        isFormSubmit={isFormSubmit}
        setIsFormSubmit={setIsFormSubmit}
        setAddOnClick={setAddOnClick}
        activeTarget={activeTarget}
        nameBusiness={nameBusiness}
        reqCode={reqCode}
        isExpPeoples={isExpPeoples}
        isExpBusiness={isExpBusiness}
        messageWhy={messageWhy}
        emailBusiness={emailBusiness}
        step={step}
        phoneBusiness={phoneBusiness}
        setAddStep={setAddStep}
      />
    );

  return (
    <div className="articlesForm-footer">
      <div className="articlesForm-footer__content">
        {!formSend && <h4 className="articlesForm-footer__title">{title}</h4>}
        {!formSend && <p className="articlesForm-footer__descr">{subtitle}</p>}

        <form action="" onSubmit={submitFormDefault} className="articlesForm__form">
          {formSend && (
            <div className="articlesForm__form-result articlesForm__form-result--footer">
              Спасибо. Ваша заявка принята.
            </div>
          )}
          {!formSend && (
            <>
              <ReactInputMask
                className={
                  phoneError
                    ? 'articlesForm__input-error'
                    : 'articlesForm__input articlesForm__input--footer'
                }
                mask="+7 (\999) 999-99-99"
                maskChar=""
                alwaysShowMask={false}
                placeholder="+7 (___) ___-__-__"
                value={phone}
                required
                onChange={(e) => setPhone(e.target.value)}
              />
              <button className="articlesForm__btn articlesForm__btn--footer">
                Оставить заявку
              </button>

              <RadioGroup defaultValue="telegram" />
            </>
          )}
        </form>
      </div>

      <div className="articlesForm-footer__img">
        <img src="/static/article/man.png" alt="Изображение" />
      </div>
    </div>
  );
});

export default ArticlesForm;
