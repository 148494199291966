import cn from 'classnames';
import parse from 'html-react-parser';
import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAddActiveLink } from '../../../../store/reducer/lk/sidebar/slice';
import { selectLKContract } from '../../../../store/reducer/lk/contract/selector';
import { selectLKAuth } from '../../../../store/reducer/lk/authChech/selector';
import Skeleton from '../../../skeleton/Skeleton';
import s from './premiumDone.module.scss';

const PremiumDone = memo(({ statusPayment, prepayStatus }) => {
	const { user } = useSelector(selectLKAuth);
	const [width, setWidth] = useState(window?.innerWidth);
	const [showContract, setShowContract] = useState(false);
	const [clientWithoutCours, setClientWithoutCours] = useState(false)
	const { isLoading, contractText } = useSelector(selectLKContract);
	const { date, contractNumber } = useSelector(selectLKContract);
	const dispatch = useDispatch();

	useEffect(() => {
		user?.user?.menu[7].name == 'Договор-оферта' ? setClientWithoutCours(true) : setClientWithoutCours(false)
	  }, [user?.user?.menu[7].name])

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

	const goPaymentPage = () => {
		dispatch(setAddActiveLink(9));
		localStorage.setItem('activeLink', JSON.stringify(9));
	};
	
	const options = {
		day: 'numeric',
		month: 'numeric',
	};

	const dateContract = date;

	const dateContractFormat = new Date(dateContract);

	const dateContractTransform = dateContractFormat.toLocaleDateString(
		'ru-RU',
		options
	);
	const yearNow = new Date().getUTCFullYear();

	return (
		<div className={cn(s.premiumDone, {})}>
			{!showContract ? (
				<>
					<div className={s.wrapper_title}>
						<img src='/static/lk/iconDoneBig.svg' alt='Иконка' />
						<h4 className={s.subtitle}>
							{width > 576 ? `${clientWithoutCours ? 7 : 8}. Договор-оферта` : `Шаг ${clientWithoutCours ? 7 : 8}`}
						</h4>
					</div>

					<h1 className={`${s.title} lk-title`}>Премиум-статус зарезервирован!</h1>
					
					<p className={s.descr2}>
						Текст договора можно посмотреть тут{' '}
						<a
							onClick={() => setShowContract(true)}
							className={s.contract_text}
							target='_blank'
						>
							Договор №{contractNumber} от {dateContractTransform}.{yearNow}
						</a>
					</p>

					{statusPayment !== 'prepaid' && statusPayment !== 'paid' ? (
						<p className={s.descr}>
							Если в течение 7-ми дней не внести предоплату, Skilla аннулирует
							договор. Премиум статус в будущем будет доступен только за ежемесячную
							плату
						</p>
					) : (
						<p className={s.descr}>Ты успешно зарезервировал Премиум-статус</p>
					)}

					{statusPayment !== 'prepaid' && statusPayment !== 'paid' && (
						<button onClick={goPaymentPage} className={s.btn}>
							{width > 575
								? 'Внести предоплату и сохранить Премиум'
								: 'Внести предоплату'}
						</button>
					)}

					{prepayStatus && <div
						className={cn(s.bottom, {
							[s.bottom_margin]:
								statusPayment === 'prepaid' || statusPayment === 'paid',
						})}
					>
						<div>
							<img src='/static/lk/iconAlertBlack.svg' alt='иконка' />
							<span>Никакого риска!</span>
						</div>

						<p>
							Skilla вернет 100% предоплаты в любой момент по твоему запросу (Статья 6
							Гарантия возврата средств, пункт 6.1. Договора)
						</p>
					</div>}

					<img
						className={s.illustation}
						src={
							width > 575
								? '/static/lk/founder.png'
								: '/static/lk/founder-mobile.png'
						}
						alt='Основатель'
					/>
				</>
			) : !isLoading && contractText ? (
				<div className={s.contract_vipStatus}>
					<button className={s.back} onClick={() => setShowContract(false)}>
						<img src='/static/lk/back.png' alt='Назад' />
					</button>
					<div className={s.inner}>
						<p>{parse(contractText)}</p>
					</div>
				</div>
			) : (
				<Skeleton />
			)}
		</div>
	);
});

export default PremiumDone;
