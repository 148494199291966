import cn from "classnames";
import s from "./ulItem.module.scss";

const UlItem = ({ children, contract }) => {
  return (
    <li
      className={cn(s.ulItem, {
        [s.ulItem_contract]: contract,
      })}
    >
      {children}
    </li>
  );
};

export default UlItem;
