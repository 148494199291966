import UlItem from '../ui/UlItem/UlItem';
import s from './zoom.module.scss';

import { useEffect, useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLkGetZoom, getReqZoom, getViewMenu } from '../../../Api/';
import { selectLKAuth } from '../../../store/reducer/lk/authChech/selector';
import { selectLKSidebar } from '../../../store/reducer/lk/sidebar/selector';
import { selectLkZoom } from '../../../store/reducer/lk/zoom/selector';
import { setAddStatusZoom } from '../../../store/reducer/lk/zoom/slice';

import { selectLKContract } from '../../../store/reducer/lk/contract/selector';
import { setIsCheckFromPage } from '../../../store/reducer/lk/contract/slice.js';
import { selectLKPayment } from '../../../store/reducer/lk/payment/selector';

const Zoom = memo(({ endDatePremium }) => {
	const [width, setWidth] = useState(window?.innerWidth);
	const [video, setVideo] = useState(false);

	const dispatch = useDispatch();
	const { user } = useSelector(selectLKAuth);
	const { activeLink, socketData } = useSelector(selectLKSidebar);
	const { statusZoom } = useSelector(selectLkZoom);

	const sendResult = statusZoom;
	const checkStatus = sendResult?.zoom?.status;
	const managerName = statusZoom?.zoom?.manager_name;
	const managerPosition = statusZoom?.zoom?.manager_position;
	const managerAvatar = statusZoom?.zoom?.manager_avatar;

	const dateMeet = statusZoom?.zoom?.date;

	const { persent} = useSelector(selectLKContract);
	const { paymentInfo } = useSelector(selectLKPayment);

	const statusPayment = paymentInfo?.payment?.status;


	const options = {
		day: 'numeric',
		month: 'long',
	};

	const date = new Date(dateMeet);
	const dateFormat = date.toLocaleDateString('ru-RU', options);
	const munutes = date.getMinutes();
	const hourMinute = `${date.getHours()}:${
		munutes == 0 ? `${munutes}0` : munutes
	}`;

	useEffect(() => {
		getViewMenu(user?.user?.code, activeLink).then(({}) => {});
	}, []);

	useEffect(() => {
		getLkGetZoom(user?.user?.code).then(({ data }) => {
			dispatch(setAddStatusZoom(data));
			localStorage.setItem('statusZoom', JSON.stringify(data));
		});
	}, [socketData]);

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);
		window.scroll(0, 0);

		return () => window.removeEventListener('resize', cb);
	}, []);

	const onReqZoom = () => {
		getReqZoom(user?.user?.code)
			.then(({ data }) => {
				if (data.result === 'success') {
					getLkGetZoom(user?.user?.code).then(({ data }) => {
						dispatch(setAddStatusZoom(data));
						localStorage.setItem('statusZoom', JSON.stringify(data));
					});
				}
			})
			.catch(e => console.log(e));
	};

	const toGoVipPage = () => {
		dispatch(setIsCheckFromPage(true));
		localStorage.setItem('isCheckFromPage', JSON.stringify(true));
		window.open('/premium', '_blank');
	};

	return (
		<>
			{statusPayment &&
				persent &&
				statusPayment !== 'prepaid' &&
				statusPayment !== 'paid' &&
				persent <= 4 &&
				endDatePremium &&
				(width > 575 ? (
					<header onClick={toGoVipPage} className={s.header}>
						<img src='/static/lk/background.png' alt='Баннер' />

						<div className={s.header_btn}>Узнать больше</div>
					</header>
				) : (
					<header className={s.header}>
						<div onClick={toGoVipPage}>
							<img
								style={{ width: '100%' }}
								src='/static/lk/background-mobile.png'
								alt='Баннер'
							/>
						</div>
					</header>
				))}

			<div className={s.zoom}>
				{checkStatus !== 'finished' && checkStatus !== 'waiting' && (
					<div className={s.wrapper_step1}>
						<div className={s.wrapper_title}>
							<img src='/static/lk/iconTime.svg' alt='Иконка' />
							<h4 className={s.subtitle_step1}>
								{width > 575 ? '5. Zoom с экспертом' : 'Шаг 5'}
							</h4>
						</div>

						<h1 className={`${s.title} lk-title`}>
							Давай запланируем <br></br> Zoom-встречу с экспертом
						</h1>
					</div>
				)}

				{checkStatus === 'waiting' && (
					<div className={s.wrapper_step1}>
						<div className={s.wrapper_title}>
							<img src='/static/lk/iconTime.svg' alt='Иконка' />
							<h4 className={s.subtitle_step1}>
								{width > 575 ? '5. Zoom с экспертом' : 'Шаг 5'}
							</h4>
						</div>

						<h1 className={`${s.title} lk-title`}>
							Встреча запланирована на {dateFormat} в {hourMinute}
						</h1>
					</div>
				)}

				{checkStatus === 'finished' && (
					<div className={s.wrapper_step1}>
						<div className={s.wrapper_title}>
							<img src='/static/lk/iconDoneBig.svg' alt='Иконка' />
							<h4 className={s.subtitle_done}>
								{width > 575 ? '5. Zoom с экспертом' : 'Шаг 5'}
							</h4>
						</div>

						<h1 className={`${s.title_done} lk-title`}>
							Zoom-встреча успешно состоялась {dateFormat} в {hourMinute}
						</h1>
					</div>
				)}

				{(checkStatus === 'finished' || checkStatus === 'waiting') && (
					<div className={s.expert}>
						{managerAvatar && <img src={managerAvatar} alt={''} />}

						<div className={s.info}>
							<div className={s.prof}>{managerPosition}</div>
							<div className={s.name}>{managerName}</div>
						</div>
					</div>
				)}

				{checkStatus !== 'finished' && checkStatus !== 'waiting' && (
					<ul className={s.list}>
						<UlItem>
							<div className={s.done}>
								<svg
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z'
										fill='#002CFB'
									/>
									<path
										d='M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z'
										fill='#002CFB'
									/>
									<path
										d='M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z'
										fill='#002CFB'
									/>
								</svg>
							</div>

							<span>Определишься подходит ли тебе бизнес со Skilla</span>
						</UlItem>

						<UlItem>
							<div className={s.done}>
								<svg
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z'
										fill='#002CFB'
									/>
									<path
										d='M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z'
										fill='#002CFB'
									/>
									<path
										d='M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z'
										fill='#002CFB'
									/>
								</svg>
							</div>

							<span>Поймешь сколько сможешь зарабатывать в городе</span>
						</UlItem>

						<UlItem>
							<div className={s.done}>
								<svg
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z'
										fill='#002CFB'
									/>
									<path
										d='M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z'
										fill='#002CFB'
									/>
									<path
										d='M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z'
										fill='#002CFB'
									/>
								</svg>
							</div>

							<span>Получишь помощь в заполнении анкеты на партнерство</span>
						</UlItem>
					</ul>
				)}

				{checkStatus !== 'finished' && checkStatus !== 'waiting' && (
					<div className={s.video}>
						{video ? (
							<iframe
								className='header__video-iframe'
								width={width <= 575 ? '100%' : '478'}
								height={width <= 575 ? '172' : '272'}
								src='https://www.youtube.com/embed/MzX6ND6Mnqk'
								title='YouTube video player'
								frameborder='0'
								allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
								allowFullScreen
							></iframe>
						) : (
							<picture>
								<source
									srcSet='/static/business/diagnostics/success.webp'
									type='image/webp'
								/>
								<img
									className={s.pict}
									style={{ cursor: 'pointer' }}
									onClick={() => setVideo(true)}
									width={width <= 575 ? '100%' : '478'}
									height={width <= 575 ? '172' : '272'}
									src='/static/business/diagnostics/success.png'
									alt='Видео'
								/>
							</picture>
						)}
					</div>
				)}

				<img
					className={s.illustation}
					src='/static/lk/illustration/illustrationTwoPeople.png'
					alt='Иллюстрация'
				/>

				{(sendResult === 'failure' || !checkStatus) && (
					<button onClick={onReqZoom} className={s.btn}>
						Запланируйте скорее
					</button>
				)}

				{checkStatus === 'request' && (
					<div className={s.sended}>
						<img src='/static/lk/IconThumb-up.svg' alt='Иконка' />
						<div className={s.text_sended}>
							Запрос отправлен, эксперт скоро свяжется с тобой
						</div>
					</div>
				)}
			</div>
		</>
	);
})

export default Zoom;
