import React, { lazy, Suspense, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes, useLocation } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { selectForm } from './store/reducer/form/selector'
import { setAddId } from './store/reducer/form/slice'
import { selectMain } from './store/reducer/main/selector'
import {
  setAddActiveBurgerColorLink,
  setAddActiveColorLink,
  setAddActiveLink,
  setAddEid,
  setAddHideNavBar,
  setAddUtm,
} from './store/reducer/main/slice'
import { userActions } from './store/reducer/userSlice'

import LoadingPage from './components/loadingPage/LoadingPage'
import ArticlesClient from './pages/articlesClient/ArticlesClient.jsx'
import ArticlesPartner from './pages/articlesPartner/ArticlesPartner'
import ArticlesService from './pages/articlesService/ArticlesService'
import Book from './pages/bookForSkilla/Book'
import CustomerCases from './pages/customerСases/CustomerСases'
import Diagnostics from './pages/Diagnostics'
import PoliticsPage from './pages/PoliticsPage.jsx'
import LegalPage from './components/LegalPage.jsx'
import PoliticApp from './pages/PoliticApp'
import Unsubscribe from './pages/Unsubscribe'
import Vip from './pages/Vip/Vip'

import WaitingForBook from './components/lk/Book/page/WaitingForBook.jsx'
import Waiting from './components/lk/Payment/pages/waiting/Waiting'
import Spinner from './components/Spinner'
import Enter from './pages/EnterLk/Enter'
import Lk from './pages/lk/Lk'
import { selectLkBook } from './store/reducer/lk/book/selector'

const CustomerPage = lazy(() => import(/* webpackChunkName: "CustomerPage" */ './pages/CustomerPage.jsx'))
const Orders = lazy(() => import(/* webpackChunkName: "Orders" */ './pages/orders/Orders'))
const Geography = lazy(() => import(/* webpackChunkName: "Geography" */ './pages/Geography'))
const Technologies = lazy(() => import(/* webpackChunkName: "Technologies" */ './pages/Technologies'))
const Contracts = lazy(() => import(/* webpackChunkName: "Contracts" */ './pages/Contracts'))
const Faq = lazy(() => import(/* webpackChunkName: "Faq" */ './pages/Faq'))
const BusinessPage = lazy(() => import(/* webpackChunkName: "BusinessPage" */ './pages/BusinessPage'))
const BusinessPageCopy = lazy(
  () => import(/* webpackChunkName: "BusinessPageCopy" */ './pages/BusinessPageCopy'),
)
const Founder = lazy(() => import(/* webpackChunkName: "Founder" */ './pages/Founder'))
const PartnerCase = lazy(
  () => import(/* webpackChunkName: "PartnerCase" */ './pages/partnerCase/PartnerCase'),
)
const Training = lazy(() => import(/* webpackChunkName: "Training" */ './pages/training/Training'))
const Heroes = lazy(() => import(/* webpackChunkName: "Heroes" */ './pages/heroes/Heroes'))
const HeroesInterview = lazy(
  () => import(/* webpackChunkName: "HeroesInterview" */ './pages/heroesInterview/HeroesInterview'),
)
const Reviews = lazy(() => import(/* webpackChunkName: "Reviews" */ './pages/reviews/Reviews'))
const About = lazy(() => import(/* webpackChunkName: "About" */ './pages/About'))
const BusinessFranchise = lazy(
  () => import(/* webpackChunkName: "BusinessFranchise" */ './pages/BusinessFranchise'),
)
const WorkerPage = lazy(() => import(/* webpackChunkName: "WorkerPage" */ './pages/WorkerPage'))
const PerformersReviews = lazy(
  () => import(/* webpackChunkName: "PerformersReviews" */ './pages/performersReviews/PerformersReviews'),
)
const NotFound = lazy(() => import(/* webpackChunkName: "NotFound" */ './pages/NotFound'))
const DownloadPage = lazy(() => import(/* webpackChunkName: "DownloadPage" */ './pages/WorkerDownload'))
const Franchise2023 = lazy(() => import(/* webpackChunkName: "BusinessFranchise" */ './pages/Franchise2023'))

const Roots = () => {
  const dispatch = useDispatch()
  const { activeTarget, activeLink, activeColorLink, hideNavBar, activeBurgerColor, activeLoadingPage } =
    useSelector(selectMain)
  const { onClick, id, formSendBusiness, formSendCustomer, isFormSubmitDefault } = useSelector(selectForm)
  const { isLoadingFooterBook } = useSelector(selectLkBook)
  let queryDict = {}
  let eid = {}
  const isMounted = useRef(false)
  let { pathname } = useLocation()

  window.location.search
    .substring(1)
    .split('&')
    .map((item) => {
      let param = item.split('=')
      queryDict[param[0]] = param[1]
      eid[param[0]] = param[1]
    })

  useEffect(() => {
    if (queryDict['utm_source']) {
      dispatch(setAddUtm(queryDict))
    }
  }, [])

  useEffect(() => {
    if (queryDict['eid']) {
      dispatch(setAddEid(eid))
    }
  }, [])

  useEffect(() => {
    dispatch(userActions.changeToken('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9'))
  }, [])

  useEffect(() => {
    if (id === '') {
      dispatch(setAddId(uuidv4()))
    }
  }, [])

  if (
    window.location.pathname.substring(1) === 'business/franchise' ||
    window.location.pathname.substring(1) === 'business/franchise2'
  ) {
    dispatch(setAddHideNavBar(true))
  } else {
    dispatch(setAddHideNavBar(false))
  }

  useEffect(() => {
    if (isMounted.current) {
      localStorage.setItem('activeTarget', JSON.stringify(activeTarget))
      localStorage.setItem('activeColorLink', JSON.stringify(activeColorLink))
      localStorage.setItem('activeBurgerColor', JSON.stringify(activeBurgerColor))
    }
    isMounted.current = true
  }, [activeTarget, activeColorLink, activeBurgerColor])

  useEffect(() => {
    dispatch(setAddActiveLink(`www.skilla.ru${pathname}`))
    dispatch(setAddActiveColorLink(`www.skilla.ru${pathname}`))
    dispatch(setAddActiveBurgerColorLink(`www.skilla.ru${pathname}`))
  }, [pathname])

  if (localStorage.getItem('signed') === 'undefined') {
    localStorage.removeItem('signed')
  }

  if (localStorage.getItem('persent') === 'undefined') {
    localStorage.removeItem('persent')
  }

  if (localStorage.getItem('isContract') === 'undefined') {
    localStorage.removeItem('isContract')
  }

  if (localStorage.getItem('is_online') === 'undefined') {
    localStorage.removeItem('is_online')
  }

  if (localStorage.getItem('isCheckFromPage') === 'undefined') {
    localStorage.removeItem('isCheckFromPage')
  }

  return (
    <>
      {isLoadingFooterBook && <Spinner roots />}

      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<LoadingPage />}>
              <CustomerPage activeTarget={activeTarget} />
            </Suspense>
          }
        />
        <Route
          path="/customer"
          element={
            <Suspense fallback={<LoadingPage />}>
              <CustomerPage activeTarget={activeTarget} onClick={onClick} />
            </Suspense>
          }
        />
        <Route
          path="/customer/orders"
          element={
            <Suspense fallback={<LoadingPage />}>
              <Orders activeTarget={activeTarget} onClick={onClick} />
            </Suspense>
          }
        />
        <Route
          path="/customer/geography"
          element={
            <Suspense fallback={<LoadingPage />}>
              <Geography />
            </Suspense>
          }
        />
        <Route
          path="/customer/technologies"
          element={
            <Suspense fallback={<LoadingPage />}>
              <Technologies formSendCustomer={formSendCustomer} />
            </Suspense>
          }
        />
        <Route
          path="/customer/contracts"
          element={
            <Suspense fallback={<LoadingPage />}>
              <Contracts />
            </Suspense>
          }
        />
        <Route
          path="/customer/faq"
          element={
            <Suspense fallback={<LoadingPage />}>
              <Faq page="customer" />
            </Suspense>
          }
        />

        <Route
          path="/business"
          element={
            <Suspense fallback={<LoadingPage />}>
              <BusinessPage
                activeTarget={activeTarget}
                onClick={onClick}
                formSendBusiness={formSendBusiness}
              />
            </Suspense>
          }
        />
        <Route
          path="/business/franchise2"
          element={
            <Suspense fallback={<LoadingPage />}>
              <BusinessPageCopy
                hideNavBar={hideNavBar}
                activeTarget={activeTarget}
                onClick={onClick}
                formSendBusiness={formSendBusiness}
              />
            </Suspense>
          }
        />
        <Route
          path="/business/founder"
          element={
            <Suspense fallback={<LoadingPage />}>
              <Founder onClick={onClick} isFormSubmitDefault={isFormSubmitDefault} />
            </Suspense>
          }
        />
        <Route
          path="/business/partners"
          element={
            <Suspense fallback={<LoadingPage />}>
              <PartnerCase formSendBusiness={formSendBusiness} />
            </Suspense>
          }
        />
        <Route
          path="/business/training"
          element={
            <Suspense fallback={<LoadingPage />}>
              <Training formSendBusiness={formSendBusiness} />
            </Suspense>
          }
        />
        <Route
          path="/business/heroes"
          element={
            <Suspense fallback={<LoadingPage />}>
              <Heroes formSendBusiness={formSendBusiness} />
            </Suspense>
          }
        />
        <Route
          path="/business/heroes/interview"
          element={
            <Suspense fallback={<LoadingPage />}>
              <HeroesInterview formSendBusiness={formSendBusiness} />
            </Suspense>
          }
        />
        <Route
          path="/business/reviews"
          element={
            <Suspense fallback={<LoadingPage />}>
              <Reviews formSendBusiness={formSendBusiness} />
            </Suspense>
          }
        />
        <Route
          path="/business/faq"
          element={
            <Suspense fallback={<LoadingPage />}>
              <Faq page="business" onClick={onClick} />
            </Suspense>
          }
        />
        <Route
          path="/business/about"
          element={
            <Suspense fallback={<LoadingPage />}>
              <About formSendBusiness={formSendBusiness} />
            </Suspense>
          }
        />

        <Route
          path="/business/franchise"
          element={
            <Suspense fallback={<LoadingPage />}>
              <BusinessFranchise onClick={onClick} hideNavBar={hideNavBar} />
            </Suspense>
          }
        />
        <Route path="/business/unsubscribe" element={<Unsubscribe />} />
        <Route path="/business/diagnostics" element={<Diagnostics />} />

        <Route
          path="/worker"
          element={
            <Suspense fallback={<LoadingPage />}>
              <WorkerPage activeTarget={activeTarget} />
            </Suspense>
          }
        />
        <Route
          path="/worker/orders"
          element={
            <Suspense fallback={<LoadingPage />}>
              <Orders activeTarget={activeTarget} onClick={onClick} />
            </Suspense>
          }
        />
        <Route
          path="/worker/performers/reviews"
          element={
            <Suspense fallback={<LoadingPage />}>
              <PerformersReviews />
            </Suspense>
          }
        />
        <Route path="/worker/cases" element={<CustomerCases />} />
        <Route
          path="/worker/faq"
          element={
            <Suspense fallback={<LoadingPage />}>
              <Faq page="worker" />
            </Suspense>
          }
        />
        <Route
          path="/worker/download"
          element={
            <Suspense fallback={<LoadingPage />}>
              <DownloadPage />
            </Suspense>
          }
        />

        <Route
          path="/partners/:alias"
          element={<ArticlesPartner activeLink={activeLink} formSendBusiness={formSendBusiness} />}
        />
        <Route
          path="/services/:alias"
          element={<ArticlesService activeTarget={activeTarget} activeLink={activeLink} />}
        />
        <Route
          path="/clients/:alias"
          element={<ArticlesClient activeTarget={activeTarget} activeLink={activeLink} />}
        />

        <Route path="/politics" element={<PoliticsPage activeTarget={activeTarget} />} />
        <Route path="/legal" element={<LegalPage activeTarget={activeTarget} />} />
        <Route path="/worker/download/politic" element={<PoliticApp activeTarget={activeTarget} />} />

        <Route
          path="/enter"
          element={
            <div>
              <Enter />
            </div>
          }
        />
        <Route
          path="/lk"
          element={
            <div className="bg-lk">
              <Lk />
            </div>
          }
        />
        <Route
          path="/lk/payment"
          element={
            <div className="bg-lk">
              <Waiting />
            </div>
          }
        />
        <Route
          path="/lk/bookPayment"
          element={
            <div className="bg-lk">
              <WaitingForBook />
            </div>
          }
        />
        <Route path="/premium" element={<Vip />} />
        <Route path="/book" element={<Book />} />

        <Route path="/franchise2023" element={<Franchise2023 />} />

        <Route
          path="*"
          element={
            <Suspense>
              <NotFound />
            </Suspense>
          }
        />
      </Routes>
    </>
  )
}

export default Roots
