import cn from 'classnames';
import s from '../steps.module.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import {
	setAddData,
	setAddStep,
} from '../../../../../store/reducer/lk/formExam/slice';

import { selectLkFormExam } from '../../../../../store/reducer/lk/formExam/selector';
import Button from '../../Button/Button';
import Form from '../../Form';

const Step28 = () => {
	const [width, setWidth] = useState(window?.innerWidth);
	const dispatch = useDispatch();
	const { data, step } = useSelector(selectLkFormExam);
	const [isError79, setError79] = useState(false);
    const [isError81, setError81] = useState(false);
	const [isSendForm, setIsSendForm] = useState(false);

	useEffect(() => {
		localStorage.setItem('stepLkExam', JSON.stringify(step));
		localStorage.setItem('dataExam', JSON.stringify(data));
	}, []);

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);
    
	const schema = yup
		.object({
			['В какой форме налогообложения мы регистрируем ООО?']: yup.string(),
		})
		.required();

	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
		watch,
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			['Что нужно зарегистрировать для работы?']: data['Что нужно зарегистрировать для работы?'],
			['В какой форме налогообложения мы регистрируем ООО?']: data['В какой форме налогообложения мы регистрируем ООО?'],
			['Для каких целей регистрируется ИП на УСН 6%']: data['Для каких целей регистрируется ИП на УСН 6%'],
		},
	});

	
	const ckbox79 = watch('Что нужно зарегистрировать для работы?');
    const ckbox81 = watch('Для каких целей регистрируется ИП на УСН 6%');
	
	useEffect(() => {
		if (!ckbox79?.length) {
			setError79(true);
		} else {
			setError79(false);
		}
	}, [ckbox79]);

    useEffect(() => {
		if (!ckbox81?.length) {
			setError81(true);
		} else {
			setError81(false);
		}
	}, [ckbox81]);

	const onSubmit = data => {
		setIsSendForm(true);

		if (!isError79 && !isError81) {
			dispatch(setAddStep(29));
			dispatch(setAddData(data));
			setIsSendForm(false);

			localStorage.setItem('dataExam', JSON.stringify(data));
			localStorage.setItem('stepLkExam', JSON.stringify(28));
		}
	};

	return (
		<div className={s.step28}>
      <div className={s.wrapper_heading}>
			  <h5>Запуск</h5>
			</div>
			<Form onSubmit={handleSubmit(onSubmit)}>

            <div className={s.form_item}>
					<h4 className={s.form_title}>
					79. Что нужно зарегистрировать для работы?
					</h4>

					<div className={s.question79}>
						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question79,
								})}
								{...register('Что нужно зарегистрировать для работы?')}
								id='exam791'
								type='checkbox'
								name='Что нужно зарегистрировать для работы?'
								autoComplete='off'
								value='Самозанятость в приложении "Мой налог"'/>
							<label htmlFor='exam791' className={s.label}>
							Самозанятость в приложении "Мой налог"
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question79,
								})}
								{...register('Что нужно зарегистрировать для работы?')}
								id='exam792'
								type='checkbox'
								name='Что нужно зарегистрировать для работы?'
								autoComplete='off'
								value='ИП УСН 6%'/>
							<label htmlFor='exam792' className={s.label}>
							ИП УСН 6%
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question79,
								})}
								{...register('Что нужно зарегистрировать для работы?')}
								id='exam793'
								type='checkbox'
								name='Что нужно зарегистрировать для работы?'
								autoComplete='off'
								value='ООО ОСНО 20%'/>
							<label htmlFor='exam793' className={s.label}>
							ООО ОСНО 20%
							</label>
						</div>

                        <div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question79,
								})}
								{...register('Что нужно зарегистрировать для работы?')}
								id='exam794'
								type='checkbox'
								name='Что нужно зарегистрировать для работы?'
								autoComplete='off'
								value='ИП ОСНО 20%'/>
							<label htmlFor='exam794' className={s.label}>
							ИП ОСНО 20%
							</label>
						</div>

						<p className={s.errorText2}>
							{isError79 &&
								isSendForm &&
								'Необходимо указать'}
						</p>
					</div>
				</div>

                <div className={s.form_item}>
					<h4 className={s.form_title}>80. В какой форме налогообложения мы регистрируем ООО?</h4>

					<div className={s.question80}>
                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question80,
								})}
								{...register('В какой форме налогообложения мы регистрируем ООО?')}
								id='exam801'
								type='radio'
								name='В какой форме налогообложения мы регистрируем ООО?'
								autoComplete='off'
								value='Основная система налогообложения — ОСНО'
							/>
							<label htmlFor='exam801' className={s.label}>
							Основная система налогообложения — ОСНО
							</label>
						</div>

						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question80,
								})}
								{...register('В какой форме налогообложения мы регистрируем ООО?')}
								id='exam802'
								type='radio'
								name='В какой форме налогообложения мы регистрируем ООО?'
								autoComplete='off'
								value='Упрощенная система налогообложения — УСН'
							/>
							<label htmlFor='exam802' className={s.label}>
							Упрощенная система налогообложения — УСН
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question80,
								})}
								{...register('В какой форме налогообложения мы регистрируем ООО?')}
								id='exam803'
								type='radio'
								name='В какой форме налогообложения мы регистрируем ООО?'
								autoComplete='off'
								value='Автоматизированная система налогообложения — АУСН'
							/>
							<label htmlFor='exam803' className={s.label}>
							Автоматизированная система налогообложения — АУСН
							</label>
						</div>

					</div>

					<p className={s.errorText}>
						{errors['В какой форме налогообложения мы регистрируем ООО?'] ? 'Необходимо указать' : ''}
					</p>
				</div>

                <div className={s.form_item}>
					<h4 className={s.form_title}>
					81. Для каких целей регистрируется ИП на УСН 6%
					</h4>

					<div className={s.question81}>
						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question81,
								})}
								{...register('Для каких целей регистрируется ИП на УСН 6%')}
								id='exam811'
								type='checkbox'
								name='Для каких целей регистрируется ИП на УСН 6%'
								autoComplete='off'
								value='Для оплаты небольшого налога в 6% со всех поступлений от клиентов'/>
							<label htmlFor='exam811' className={s.label}>
							Для оплаты небольшого налога в 6% со всех поступлений от клиентов
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question81,
								})}
								{...register('Для каких целей регистрируется ИП на УСН 6%')}
								id='exam812'
								type='checkbox'
								name='Для каких целей регистрируется ИП на УСН 6%'
								autoComplete='off'
								value='Для того, чтобы не отчитываться перед налоговой за свои расходы'/>
							<label htmlFor='exam812' className={s.label}>
							Для того, чтобы не отчитываться перед налоговой за свои расходы
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question81,
								})}
								{...register('Для каких целей регистрируется ИП на УСН 6%')}
								id='exam813'
								type='checkbox'
								name='Для каких целей регистрируется ИП на УСН 6%'
								autoComplete='off'
								value='Для простых условий снятия денежных средств с расчетного счета'/>
							<label htmlFor='exam813' className={s.label}>
							Для простых условий снятия денежных средств с расчетного счета
							</label>
						</div>

                        <div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question81,
								})}
								{...register('Для каких целей регистрируется ИП на УСН 6%')}
								id='exam814'
								type='checkbox'
								name='Для каких целей регистрируется ИП на УСН 6%'
								autoComplete='off'
								value='Для перевода оплаты через расчетный счет всем исполнителям'/>
							<label htmlFor='exam814' className={s.label}>
							Для перевода оплаты через расчетный счет всем исполнителям
							</label>
						</div>

						<p className={s.errorText2}>
							{isError81 &&
								isSendForm &&
								'Необходимо указать'}
						</p>
					</div>
				</div>

				<div className={s.btns}>
        <Button callback={() => dispatch(setAddStep(27))} back>
						{width > 575 ? (
							'   Назад'
						) : (
							<img src='/static/lk/IconBackward.svg' alt='назад' />
						)}
					</Button>
					<Button>Далее 28 из 35</Button>
				</div>
			</Form>

			<img
				className={s.illustration}
				src='/static/lk/illustration/illustration.png'
				alt='Иллюстрация'
			/>
		</div>
	);
};

export default Step28;
