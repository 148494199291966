import './button.scss'

const Button = ({ children, onCkick, className }) => {
	return (
		<button className={className} onClick={onCkick}>
			{children}
		</button>
	);
};

export default Button;
