import classNames from 'classnames'
import { useEffect } from 'react'
import { useState } from 'react'

function HeaderLegal({ activeTarget }) {
  const [link, setLink] = useState('skilla.ru')
  const location = window.location.href
  useEffect(() => {
    console.log(location)
    const site = `${location}`
    console.log(site)
    const domen = site.slice(8, -9)
    console.log(domen)
    setLink(domen)
  }, [location])

  return (
    <header
      className={classNames({
        'header header--customer': activeTarget === 'customer',
        'header header--worker': activeTarget === 'worker',
        'header header--business': activeTarget === 'business',
      })}
    >
      <div className="container">
        <div className="header__content">
          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
            <p style={{ textAlign: 'end' }} className="header__description">
              Утверждено Приказом
              <br />
              Генерального директора ООО «Скилла Инновации»
              <br />
              ИНН 4706050219, КПП 780601001, ОГРН 1224700011733
              <br />
              № 2024-08 от «27» августа <br />
              2024, версия 2. <br />
              Адрес публикации: <br />{' '}
              <a href="https://skilla.ru/legal" style={{ color: 'white', hover: 'black' }}>
                https://skilla.ru/legal
              </a>
              <br />
              <br />
            </p>
          </div>
          <h2 style={{ maxWidth: '100%', textAlign: 'center' }} className="header__title">
            Пользовательское соглашение
            <br />
            Платформы Скилла Работа
          </h2>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <p className="header__description">
              Дата вступления в силу 28.08.2024 г.
              <br />
              <br />
            </p>
          </div>
          <p className="header__description">
            Общество с ограниченной ответственностью ООО «Скилла Инновации» (ИНН 4706050219, КПП 780601001,
            ОГРН 1224700011733), являющееся “Лицензиаром”, предлагает физическим лицам и юридическим лицам -
            выступающим в качестве “Лицензиатов”, заключить на определенных ниже условиях Пользовательское
            соглашение платформы Скилла Работа (далее — «Пользовательское соглашение»). Предложение является
            публичной офертой в соответствии с п. 1 ст. 435, п. 2 ст. 437 Гражданского кодекса Российской
            Федерации.
            <br />
            <br />
            Совершая конклюдентные действия по вводу номера телефона в специальное поле на Сайте
            https://skilla.ru, вводу Пароля, нажатию кнопки «Зарегистрироваться» или «Далее», скачиванию и
            установке Мобильного приложения в соответствии со ст. 438 Гражданского Кодекса Российской
            Федерации вы подтверждаете, что вы поняли и безоговорочно приняли (акцептовали) все условия
            последней версии Пользовательского соглашения, доступной вам на Сайте и в Личном кабинете
            Мобильного приложения, без каких-либо исключений и/ или ограничений. Если вы не согласны с
            Пользовательским соглашением, пожалуйста, не пользуетесь Платформой. Пользовательское соглашение,
            заключаемое путем акцепта настоящей оферты, не требует двустороннего подписания и действительно в
            электронном виде.
            <br />
            <br />
            Использование функций Платформы Скилла Работа возможно при наличии доступа устройства к сети
            Интернет, Вы самостоятельно получаете и оплачиваете такой доступ на условиях и по тарифам своего
            оператора связи или провайдера доступа к сети Интернет.
            <br />
            <br />
            <h3 style={{ width: '100%', textAlign: 'center' }}>Термины и определения</h3>
            <br />
            Термины, используемые в настоящем Пользовательском соглашении, имеют следующие значения:
            <br />
            <br />
            <b>Авторизованное устройство — </b> компьютер, смартфон, планшет или иное устройство для доступа к
            Платформе, на котором Клиент прошел успешную Аутентификацию.
            <br />
            <br />
            <b>Авторизованный номер —</b> номер мобильного телефона Клиента, указанный им для акцепта
            Пользовательского соглашения и регистрации. Данные, полученные с/ отправленные на указанный номер
            телефона, считаются безусловно направленными/ полученными Клиентом. В случае утери устройства,
            привязанного к Авторизованному номеру, или смены Авторизованного номера Клиент должен сообщить об
            этом Скилла Работа для прохождения повторной идентификации с новым номером телефона.
            <br />
            <br />
            <b>Авторизованная электронная почта —</b> электронная почта Клиента, указанная им для целей
            акцепта Пользовательского соглашения и двойной идентификации. Данные, полученные с/ отправленные
            на указанную электронную почту, считаются безусловно направленными/ полученными Клиентом. В случае
            утери доступа к Авторизованной электронной почте, или смены Авторизованной электронной почты
            Клиент должен сообщить об этом Скилла Работа для прохождения повторной идентификации с новой
            электронной почтой.
            <br />
            <br />
            <b>Автоматизация сдельности —</b> дополнительный модуль Платформы, расширяющий (модифицирующий)
            функциональность Платформы, а именно предоставляющий доступ к аналитической информации о
            Пользователях (Информатор), а также доступ к функционалу «Ежедневные задания» и «Зачет встречных
            требований», позволяющему автоматизировать полный цикл сотрудничества с Пользователями.
            <br />
            <br />
            <b>Аутентификация —</b> процедура проверки принадлежности лицу прав доступа к Аккаунту Клиента.
            Аутентификация считается пройденной в случае соответствия предъявленных Авторизованного номера или
            Авторизованной электронной почты и Пароля, а при наличии технической возможности Аутентификация
            считается пройденной при входе с Авторизованного устройства. Без успешной Аутентификации Сеанс
            связи не устанавливается.
            <br />
            <br />
            <b>Аккаунт —</b> учетная запись Уполномоченного лица Компании или Пользователя, создаваемая в
            момент регистрации на Платформе, позволяющая Компании или Пользователю воспользоваться функциями
            Личного кабинета. Действия, совершаемые через Аккаунт после Аутентификации, признаются юридически
            значимыми и совершенными конкретным Клиентом. Уполномоченное лицо Компании, действуя через
            Аккаунт, действует от имени, в интересах и за счет Компании.
            <br />
            <br />
            <b>Активный пользователь —</b> пользователь, который совершил любое действие, связанное с
            подписанием документа ПЭП на Платформе.
            <br />
            <br />
            <b>База данных —</b> дополнительный модуль Платформы, расширяющий (модифицирующий)
            функциональность Платформы, а именно предоставляющий доступ к информации о Пользователях,
            собираемых из открытых официальных источников для Компании, осуществляющей сотрудничество с такими
            Пользователями.
            <br />
            <br />
            <b>Банк —</b> любой банк, являющийся партнером Скилла Работа на основании заключенного между
            Банком и Скилла Работа договора, осуществляющий расчеты для Скилла Работа и Клиентов Скилла Работа
            в рамках банковской деятельности.
            <br />
            <br />
            <b>Договор —</b> договор, заключаемый между Компанией и Пользователем на Платформе. В случае, если
            Пользователь является самозанятым, предмет договора должен соответствовать Закону РФ от 27.11.2018
            № 422-ФЗ. Скилла Работа не является стороной в этом Договоре и не несет обязательств, вытекающих
            из него. Фактическое исполнение по данному Договору осуществляется Компанией или(и) Пользователем.
            Количество Договоров между Компанией и Пользователем, заключаемых в рамках работы Платформы между
            Клиентами, не ограничено. Договор между Компанией и Пользователем подписывается Клиентами с
            помощью ПЭП. ПЭП.
            <br />
            <br />
            <b>Дополнительный модуль —</b> модуль, содержащий дополнительные функции Платформы и
            предоставляемый за отдельное вознаграждение.
            <br />
            <br />
            <b>Заказ —</b> оферта, содержащая существенные условия сделки, оформленная Компанией на Платформе
            или в системе Компании. Детали заказа отображаются в интерфейсе Платформы, определяются Компанией
            в момент направления Заказа и акцептуются Пользователем в момент принятия Заказа. Заказ
            оформляется в рамках Договора. В рамках одного Договора может быть оформлен один Заказ.
            <br />
            <br />
            <b>Интерфейс ассистирования —</b> дополнительный модуль Платформы, расширяющий (модифицирующий)
            функциональность Платформы, а именно предоставляющий Компании доступ к базе данных с
            рекомендациями относительно построения работы с Пользователями.
            <br />
            <br />
            <b>Скилла Работа —</b> Общество с ограниченной ответственностью ООО «Скилла Инновации» (ИНН
            4706050219, КПП 780601001, ОГРН 1224700011733), Лицензиар. Скилла Работа на условиях
            Пользовательского соглашения предоставляет Клиентам неисключительную лицензию на принадлежащую ему
            Платформу Скилла Работа и мобильное приложение Скилла Работа, являющееся составной частью
            Программы для ЭВМ «Skilla IS» . Скилла Работа действует в качестве уполномоченного оператора
            электронной площадки по смыслу Федерального закона от 27.11.2018 № 422-ФЗ «О проведении
            эксперимента по установлению специального налогового режима «Налог на профессиональный доход» и
            Протокола информационного обмена, порядка информационного взаимодействия, требования (критерии) к
            операторам электронных площадок и кредитным организациям, осуществляющим взаимодействие с
            налоговыми органами.
            <br />
            <br />
            <b>Клиент (Клиенты) —</b> Компания и Пользователь
            <br />
            <br />
            <b>Компания —</b> юридическое лицо или индивидуальный предприниматель, использующее Платформу. Все
            действия от имени Компании совершаются Уполномоченными лицами Компании.
            <br />
            <br />
            <b>Конструктор договоров —</b> дополнительный модуль Платформы, расширяющий (модифицирующий)
            функциональность Платформы, а именно предоставляющий Компании возможность выявления недостающих
            элементов и дополнения шаблонов договоров.
            <br />
            <br />
            <b>Лицензионный договор —</b> договор по форме Скилла Работа о присоединении Компании к настоящему
            Пользовательскому соглашению, содержащий сведения о Компании, Спецификацию, а также иные
            положения, которые хотят согласовать стороны.
            <br />
            <br />
            <b>Личный кабинет —</b> модуль Платформы, позволяющий Клиенту управлять своим Аккаунтом, в том
            числе, размещать, изменять или удалять данные и иную информацию, указанную при создании Аккаунта,
            получать информацию, создавать и принимать электронные документы, а также использовать иные
            функции Платформы.
            <br />
            <br />
            <b>Маркетплейс —</b> модуль Платформы, позволяющий Компаниям размещать предложения о
            сотрудничестве на Платформе, а Пользователям просматривать предложения всех Компаний и отзываться
            на такие предложения. Маркетплейс не предназначен для размещения предложений о сотрудничестве вне
            Платформы.
            <br />
            <br />
            <b>Мобильное приложение —</b> модуль Платформы, мобильное приложение «Скилла Работа» для iOS и
            Android, исключительные права принадлежат ООО «Скилла Инновации» (свидетельство №2022668389 /
            Программа для ЭВМ). Мобильное приложение позволяет Пользователям оперативно получать информацию от
            Компаний и возможность подписывать электронные документы ПЭП. Посредством Мобильного приложения
            Пользователи могут получать юридически значимые электронные сообщения и Электронные документы.
            Мобильное приложение скачивается на устройство Пользователя посредством сторонних Магазинов
            приложений.
            <br />
            <br />
            <b>Модерация —</b> просмотр Скилла Работа размещаемой или планируемой к размещению на Сайте
            Пользователем информации или на Маркетплейсе Компанией объявления о сотрудничестве, на предмет
            соответствия положениям настоящего Пользовательского соглашения и законодательства Российской
            Федерации.
            <br />
            <br />
            <b>Налоговая копилка —</b> модуль Платформы, позволяющий резервировать денежные средства
            Самозанятого для уплаты налога на профессиональный доход. Функционал предоставляется Скилла
            Работа.
            <br />
            <br />
            <b>Номинальный счет —</b> счет, открытый Скилла Работа в Банке для совершения Операций (Площадка
            Скилла Работа является владельцем счета), совершаемых за счет Компаний. Бенефициарами по
            Номинальному счету выступают Компании. Пользователь в статусе самозанятого является бенефициаром
            денежных средств на суммы, подлежащие перечислению в налоговые органы. Распорядителем Номинального
            счета является Скилла Работа.
            <br />
            <br />
            <b>Операция —</b> операция с денежными средствами, права на которые принадлежат Компании,
            совершаемая Скилла Работа, Банком или Технологическим партнером по распоряжению Компании в пользу
            Пользователя(ей) (получателя денежных средств). Операция совершается во исполнение обязательств
            Компании по Договору между ней и соответствующим(и) Пользователем(ями). Информации об Операциях
            отображается на Платформе.
            <br />
            <br />
            <b>Отчет Скилла Работа —</b> сводная ведомость, составляемая Скилла Работа по итогам Отчетного
            периода, содержащая расчет вознаграждения Скилла Работа.
            <br />
            <br />
            <b>Отчетный период —</b> период времени равный календарному месяцу.
            <br />
            <br />
            <b>Пароль —</b> одноразовый код, который: (1) направляется в СМС-сообщении на Авторизованный номер
            по запросу Клиента и(или) (2) направляется в письме на Авторизованную электронную почту по запросу
            Клиента и(или) (3) направляется в Push-уведомлении на Авторизованный номер по запросу Клиента
            и(или) (4) задается Клиентом самостоятельно.
            <br />
            <br />
            <b>Платформа —</b> программное обеспечение, платформа Скилла Работа (программа для ЭВМ),
            исключительные права на которую принадлежат ООО «Скилла Инновации» (свидетельство №2022668389 /
            Программа для ЭВМ). Платформа позволяет осуществлять контакт, сотрудничать, управлять юридически
            значимыми сообщениями и Электронными документами, в том числе: (а) размещать и изменять информацию
            в Личном кабинете, управлять настройками Личного кабинета, (б) загружать и подписывать Электронные
            документы, (в) размещать, принимать и исполнять Задания, (г) формировать и выгружать отчеты и
            статистику, (д) формировать и выгружать уведомления для надзорных органов, (е) давать Распоряжения
            и производить Операции. Платформа включает в себя интерфейс, исходный код, аудиовизуальные
            изображения и иные элементы, необходимые для ее надлежащего функционирования. Платформа
            зарегистрирована в реестре российского ПО - реестровая запись №16297 в реестре от 23.01.2023,
            произведена на основании поручения Министерства цифрового развития, связи и массовых коммуникаций
            Российской Федерации по протоколу заседания экспертного совета
            <br />
            <br />
            <b>Пользователь —</b> физическое лицо со статусом Самозанятого, или индивидуальный
            предприниматель, начавшее взаимодействовать с Скилла Работа любым доступным способом, в том числе
            оставляющее комментарии на Сайте или зарегистрированное на Платформе и предлагающее свои услуги/
            заключающее сделки с одной или несколькими Компаниями.
            <br />
            <br />
            <b>ПЭП —</b> простая электронная подпись, используемая при совершении юридически значимых действий
            посредством Платформы. Ключ ПЭП состоит из открытой и закрытой части. Открытой частью Ключа ПЭП
            признается Авторизованный номер или Авторизованная электронная почта. Закрытой частью Ключа ПЭП
            признается Пароль. Активация Ключа ПЭП происходит в момент совпадения Авторизованного номера или
            Авторизованной электронной почты и Пароля. Простая электронная подпись является аналогом
            собственноручной подписи Сторон. К положениям Пользовательского соглашения, регулирующим ПЭП,
            применяются положения Федерального закона «Об электронной подписи» № 63-ФЗ от 06.04.2011 г.
            <br />
            <br />
            <b>Распоряжение —</b> однозначно понимаемое действие, которое осуществляется на Платформе Клиентом
            Скилла Работа для совершения Операций с денежными средствами, находящимися на Номинальном счете,
            бенефициаром по которым выступает Компания или Пользователь (только в части средств налога
            самозанятого).
            <br />
            <br />
            <b>Расчетный счет —</b> счет, открытый Компанией в Банке для совершения Операций, совершаемых за
            счет Компании.
            <br />
            <br />
            <b>Реквизиты для выплат —</b> номер карты и/или номер личного счета и/или номер расчетного счета,
            указанный(ые) Пользователем в Личном кабинете, для получения им денежных средств по Договору
            <br />
            <br />
            <b>Сайт —</b> расположенный в сети Интернет по адресу www.skilla.ru или поддомены данного сайта,
            прочие страницы входа, и являющийся составным объектом интеллектуальной собственности в составе
            программ для ЭВМ и других программных средств, баз данных, графического контента и других
            произведений, объединенных для обеспечения нормального функционирования Сайта и использования его
            возможностей для использования различного функционала Платформы. Сайт является составной частью
            Платформы.
            <br />
            <br />
            <b>Самозанятый —</b> лицо, которое применяет в своей деятельности специальный налоговый режим
            "Налог на профессиональный доход" в соответствии с налоговым законодательствомРФ, а также лица,
            которые ранее присутствовали на электронной площадке, но, утратив статус таких налогоплательщиков,
            завершают расчеты по состоявшимся ранее операциям (в период правомерного использования
            специального налогового режима) и реализуют свои законные интересы на получение информации о
            состоявшихся ранее сделках, решениях, расчетах, в т.ч. об уплате налога на профессиональный доход
            в бюджет. Самозанятый может выступать в роли Пользователя.
            <br />
            <br />
            <b>Сеанс связи —</b> период времени, в течение которого авторизованный Клиент пользуется Личным
            кабинетом.Для начала первого Сеанса связи необходимо успешно пройти Аутентификацию. После этого
            при наличии технической возможности у Платформы и(или) Клиента Клиент остается авторизованным до
            тех пор, пока он не вышел из Аккаунта/ не сменил Авторизованное устройство.
            <br />
            <br />
            <b>СМС-сообщение —</b> короткое текстовое сообщение, состоящее из букв и (или) символов, набранных
            в определенной последовательности, предназначенное для передачи по сети подвижной связи от Скилла
            Работа Клиентам и содержащее информацию, необходимую для использования Клиентами функционала
            Платформы.
            <br />
            <br />
            <b>Стороны —</b> совместное наименование Скилла Работа, Компании и Пользователя.
            <br />
            <br />
            <b>Тестовый период —</b> период бесплатного использования Платформы Компанией, с целью
            ознакомления со всеми возможностями Платформы и принятия решения о приобретении прав на
            использование Платформы. Тестовый период не позволяет совершать сделки и заключать договоры с
            реальными Пользователями Платформы. По окончании Тестового периода все данные из тестового
            аккаунта Компании удаляются безвозвратно. Скилла Работа не несет ответственности за работу
            Тестового аккаунта, за ожидания Компании, за действия третьих лиц.
            <br />
            <br />
            <b>Технологический партнер —</b> партнер Банка по осуществлению расчетов и выплат Банка на
            основании договора между партнером и Банком.
            <br />
            <br />
            <b>Токен —</b> уникальная последовательность символов (закрытый ключ) предоставляемая Платформой
            верифицированному Уполномоченному лицу для подписания документов и получения юридически значимых
            сообщений при подключении Компании к Платформе посредством API. Токен приравнивается к ПЭП.
            <br />
            <br />
            <b>Уполномоченное лицо —</b> лицо, уполномоченное действовать от имени, за счет и в интересах
            Компании в пределах возложенных на него полномочий.
            <br />
            <br />
            <b>Чат —</b> модуль Платформы, являющийся защищенным официальным каналом связи Скилла Работа и
            Клиента.
            <br />
            <br />
            <b>Электронный документ —</b> информация в электронной форме, имеющая уникальный идентификатор,
            предназначенная для подписания Электронной подписью любой из Сторон.
            <br />
            <br />
            <b>Законодательство —</b> действующее законодательство Российской Федерации. Иные термины
            используются в соответствии с Законодательством и обычаями делового оборота.
            <br />
            <h3 style={{ width: '100%', textAlign: 'center' }}>
              Порядок принятия и изменения Пользовательского соглашения
            </h3>
            <br />
            Клиент принимает Пользовательское соглашение путем его акцепта. Акцепт считается предоставленным в
            результате совершения Пользователем следующих действий: - ввод номера телефона Пользователя в
            соответствующем функциональном поле на Сайте; - ввод Пароля, полученного на Авторизованный номер
            или Авторизованную почту; - нажатие на кнопку «Зарегистрироваться» или «Далее».
            <br />
            <br />
            Акцепт также может быть предоставлен в результате совершения Пользователем одного из следующих
            действий: <br />
            <br />- заполнение формы на Сайте и нажатии соответствующей кнопки для отправки данных Скилла
            Работа; <br />
            <br />
            - предоставление своих контактных данных на сторонних сервисах с целью осуществления контакта с
            Скилла Работа; <br />
            <br />
            - отправка / размещение комментария на Сайте; <br />
            <br />
            - осуществление звонка на номер телефона Скилла Работа.
            <br />
            <br />
            Пользовательское соглашение вступает в силу с даты акцепта и действует до расторжения Сторонами/до
            момента отзыва оферты Скилла Работа. Скилла Работа имеет право изменить Пользовательское
            соглашение в одностороннем порядке без предварительного уведомления. Изменения вступают в силу на
            следующий день с момента размещения на Сайте новой редакции Пользовательского соглашения. Клиенты
            обязуются самостоятельно знакомиться с актуальной редакцией Пользовательского соглашения каждый
            раз перед началом использования Платформы. Использование Платформы означает согласие Клиента с
            новой редакцией Пользовательского соглашения.
            <br />
            <br />
            Принятие изменений Пользовательского соглашения, представленных в виде новой редакции
            Пользовательского соглашения размещенной на Сайте, осуществляется посредством совершения акцепта
            при каждой Аутентификации Клиента, нажатием им кнопки «Далее», в связи с чем Пользователь
            обязуется знакомиться с положениями Пользовательского соглашения при каждом входе на Платформу. В
            случае несогласия с каким-либо положением Пользовательского соглашения и/или приложений к нему
            (при наличии) Клиент обязан уведомить Скилла Работа о желании прекратить использование Платформы и
            удалить Аккаунт посредством обращения в техническую службу поддержки или по электронной почте,
            указанной в реквизитах Пользовательского соглашения.
            <br />
            <br />
            <h3 style={{ width: '100%', textAlign: 'center' }}>Аккаунт Клиента</h3>
            <br />
            Единоличный исполнительный орган Компании должен самостоятельно контролировать конфиденциальность
            данных, с помощью которых можно зайти в Аккаунт, и наличие формальных полномочий у лица, которому
            он передает соответствующие доступы. Наличие данных для доступа в Аккаунт подтверждает
            предоставление лицу, работающему с Аккаунтом Компании, формальных полномочий на совершение любых
            действий на Платформе.
            <br />
            <br />
            Единоличный исполнительный орган Компании при предоставлении третьему лицу доступа к Аккаунту
            Компании должен указать запрашиваемые Платформой персональные данные этого лица. При этом он
            гарантирует, что им было заблаговременно получено согласие этого лица на обработку указанных
            данных Скилла Работа на условиях Пользовательского соглашения и проведена идентификация такого
            лица.
            <br />
            <br />
            Единоличный исполнительный орган Компании при предоставлении доступа третьему лицу к Аккаунту
            Компании подтверждает выдачу ему доверенности в соответствии с ч.4 ст.185 ГК РФ. Единоличный
            исполнительный орган Компании или его представитель по доверенности, в интерфейсе Платформы
            подписывает доверенность на Уполномоченное лицо с правами, в соответствии с предоставленной ему
            ролью.
            <br />
            <br />
            Для передачи запросов Скилла Работа, содержащих персональные данные или конфиденциальные данные
            Компании или Пользователя, Компания обязуется использовать Чат.
            <br />
            <br />
            Клиенты обязаны соблюдать конфиденциальность в отношении данных для Аутентификации. До момента
            сообщения Клиентом о том, что его Аккаунтом завладели третьи лица, все Электронные документы,
            исходящие от этого Клиента, признаются подписанными им.
            <br />
            <br />
            Скилла Работа вправе дополнительно требовать предоставить доверенности на третьих лиц на бумажном
            носителе.
            <br />
            <br />
            В Личном кабинете Пользователь обязуется указать Реквизиты для выплат. Указание Реквизитов для
            выплат в Личном кабинете признается волеизъявлением Пользователя на получение денежных средств на
            указанные Реквизиты для выплат. В случае противоречия банковских реквизитов Пользователя в Акте и
            в Личном кабинете, приоритет имеют Реквизиты для выплат, указанные в Личном кабинете.
            <br />
            <br />
            Пользователь вправе указать в Личном кабинете номер карты третьего лица для получения денежных
            средств. При этом Пользователь проинформирован о том, что в случае указания номера карты третьего
            лица обязанность по уплате «Налога на профессиональный доход» лежит на Пользователе.
            <br />
            <br />
            Клиенты должны использовать Платформу лично и/или предоставлять доступ к Аккаунту только формально
            уполномоченным на то лицам. Клиент не вправе сообщать или иным образом передавать свои данные,
            раскрытие которых может повлечь доступ третьих лиц к Аккаунту Клиента.
            <br />
            <br />
            При достижении Самозанятым максимального размера дохода за текущий год, установленного
            законодательством для деятельности Самозанятого, его обслуживание как Пользователя со статусом
            Самозанятого приостанавливается до начала следующего года (без расторжения настоящего
            Пользовательского соглашения). По договоренности такой Самозанятый вправе продолжить использовать
            Платформу как Пользователь без статуса Самозанятого с соответствующими формальными и налоговыми
            последствиями.
            <br />
            <br />
            В случае изменения налогового статуса Пользователя (утраты Пользователем – физическим лицом
            статуса Самозанятого/ переход физического лица на использование статуса индивидуального
            предпринимателя/ переход индивидуального предпринимателя на режим Самозанятого или на
            использование статуса физического лица – Самозанятого) он должен незамедлительно сообщить об этом
            Скилла Работа, направив письмо на почту support@skilla.ru и обновив информацию на Платформе, а
            также уведомить Компании, с которыми он взаимодействует посредством Платформы.
            <br />
            <br />
            Размещая на Платформе любую информацию/материалы, Клиент дает Скилла Работа согласие на хранение
            такой информации/материалов в период действия Пользовательского соглашения и в течение 5 (пяти)
            лет с даты его расторжения.
            <br />
            <br />
            Используя Платформу, Клиент дает свое согласие на получение информационных сообщений о:
            <br />
            <br />
            Платформе от Скилла Работа, а также на получение СМС-сообщений с кодами доступа на Платформу,
            Паролей ПЭП, и иной информацией, необходимой для использования Клиентом функционала Платформы.
            Клиент вправе отказаться от получения информационных сообщений путем использования
            соответствующего функционала Платформы или следуя инструкциям, указанным в полученном сообщении.
            Клиент вправе отказаться от получения СМС- сообщений, уведомив Скилла Работа о своем желании
            посредством обращения в службу поддержки или по электронной почте, указанной в реквизитах
            Пользовательского соглашения. Отказ Клиента от получения СМС-сообщений делает невозможным
            использование функционала Платформы.
            <br />
            <br />
            Клиент соглашается с тем, что Скилла Работа имеет право проводить статистические опросы
            Пользователей Платформы с обезличиванием персональных данных и использовать такие данные для
            улучшения функционирования Платформы, а также может передавать обезличенные результаты таких
            опросов Компаниям.
            <br />
            <br />
            Скилла Работа имеет право публиковать наименование Компании, а также информацию о сотрудничестве с
            Компанией: на Платформе, на своем сайте и лендингах, в презентациях, на мероприятиях проводимых
            Скилла Работа, в социальных сетях, в статьях на сайтах принадлежащих третьим лицам. Компания дает
            свое согласие Скилла Работа на публикацию и сопровождение информации о сотрудничестве с Компанией
            с упоминанием наименования Компании, ссылками на сайт Компании и ее страниц в социальных сетях, а
            также дает согласие на воспроизведение в такой информации или отдельно логотипов Компании:
            зарегистрированных ей товарных знаков и иных обозначений, используемых Компанией.
            <br />
            <br />
            Персональные данные Пользователей и Уполномоченных лиц, указанные ими в Аккаунте, могут
            использоваться Скилла Работа для направления им информационных сообщений о Платформе и об
            изменениях на ней посредством Авторизованного номера и/или Авторизованной электронной почты.
            <br />
            <br />
            При размещении материалов и комментариев в любых разделах Сайта Пользователь гарантирует, что
            такое размещение не нарушает положения законодательства Российской Федерации и права третьих лиц,
            а также принимает на себя ответственность за возможные нарушения и обязуется возместить любые
            убытки Скилла Работа, понесенные в случае таких нарушений.
            <br />
            <br />
            Скилла Работа вправе осуществлять Модерацию в случаях, когда информация, размещаемая
            Пользователем, пропагандирует ненависть и/или дискриминацию людей по расовому, этническому,
            половому, религиозному, социальному признакам; пропагандирует употребление наркотических и прочих
            средств, наносящих вред здоровью; призывает к бесчеловечному обращению с животными; нарушает права
            национальных и иных меньшинств; содержит призывы к насильственным действиям; нарушает права
            авторов и иных владельцев интеллектуальных прав; нарушает иные нормы законодательства Российской
            Федерации и права третьих лиц.
            <br />
            <br />
            Удаление Аккаунта происходит посредством обращения в техническую службу поддержки и подтверждения
            своей личности, в течение 30 (Тридцати) рабочих дней с даты обращения Пользователя. Для удаления
            Аккаунта и расторжения Пользовательского соглашения у Пользователя не должно быть не выполненных,
            но принятых Заказов и не расторгнутых договоров.
            <br />
            <br />
            <h3 style={{ width: '100%', textAlign: 'center' }}>Лицензионные условия</h3>
            <br />
            Скилла Работа безвозмездно на условиях простой (неисключительной) лицензии предоставляет
            Пользователю право использования функционала Компании и Исполнителя, в том числе путем
            предоставления удаленного доступа к Платформе, а Пользователь обязуется использовать Платформу в
            соответствии с условиями Пользовательского соглашения.
            <br />
            <br />
            Скилла Работа на условиях простой (неисключительной) лицензии предоставляет Компании право
            использования, в том числе путем предоставления удаленного доступа к Платформе, вознаграждение за
            доступ к которой определяется для Компании в Приложении №1 к Пользовательскому соглашению, а также
            отображается в Личном кабинете Компании, а Компания обязуется использовать Платформу в
            соответствии с условиями Пользовательского соглашения.
            <br />
            <br />
            Использование, в том числе путем предоставления удаленного доступа к Дополнительным модулям
            Платформы возможно за отдельное вознаграждение. Право использования Дополнительных модулей
            возникает в момент подключения Дополнительного модуля посредством Платформы.
            <br />
            <br />
            Доступ к Платформе разрешается на территории всего мира.
            <br />
            <br />
            Срок использования, в том числе путем предоставления удаленного доступа, определяется на срок
            действия Пользовательского соглашения. Использование, в том числе путем предоставления удаленного
            доступа к Платформе допускается следующим способом использование (эксплуатация) Платформы по ее
            прямому назначению.
            <br />
            <br />
            Действие простой (неисключительной) лицензии по Пользовательскому соглашению распространяется на
            все последующие обновления/ новые версии Платформы.
            <br />
            <br />
            Стороны договорились, что отчет, предусмотренный ст. 1237 ГК РФ, об использовании Платформы по
            Пользовательскому соглашению Клиентом не предоставляется.
            <br />
            <br />
            <h3 style={{ width: '100%', textAlign: 'center' }}>Ограничение прав использования</h3>
            <br />
            Клиент обязуется не совершать следующие действия:
            <br />
            <br />
            - «Вскрывать» или производить «обратное проектирование», деассемблировать и/или декомпилировать
            (преобразовывать объектный (машиночитаемый) код в исходный текст) никакие части Платформы, за
            исключением случаев, когда возможность осуществления такой деятельности прямо предусмотрена
            действующим законодательством РФ.
            <br />
            <br />
            - Модифицировать Платформу, в том числе вносить изменения в объектный код программ или каким-то
            образом изменять функционирование Платформы и/или любой ее части, за исключением тех изменений,
            которые вносятся средствами, включенными в Платформы (средствами интерфейса).
            <br />
            <br />
            - Создавать условия для использования Платформы лицами, не имеющими прав на использование данного
            программного обеспечения, а именно: сдавать в аренду, лизинг, или предоставлять на условиях
            сублицензии, дистрибьюторам, торговым посредникам, изготовителям комплектного оборудования, а
            также иным третьим сторонам.
            <br />
            <br />
            - Вносить какие бы то ни было изменения в сведения о производителе Платформы, в том числе изменять
            или удалять название производителя, название Платформы, встроенные в Платформу тексты и
            графические элементы, логотип «Скилла Работа» и иные зарегистрированные товарные знаки Скилла
            Работа и третьих лиц.
            <br />
            <br />
            - Использовать Платформу не предусмотренным Пользовательским соглашением способом.
            <br />
            <br />
            - Рассылать посредством Платформы массовые электронные сообщения коммерческого, рекламного и иного
            характера, не согласованные (не запрошенные) получателем информации (Спам).
            <br />
            <br />
            - Заключать сделки между Самозанятым и Компанией, если Самозанятый является или был работником
            данной Компании менее, чем за два года до заключения сделки. Запрещается использовать Платформу
            для замены существовавших трудовых отношений на гражданско- правовые отношения с Самозанятым.
            <br />
            <br />
            - Приобретать у Самозанятого подакцизные товары и/или товары/работы/услуги, для реализации которых
            у Самозанятого нет требуемых Законодательством лицензий/аккредитаций.
            <br />
            <br />
            - Заключать мнимые и притворные сделки.
            <br />
            <br />
            - Использовать Платформу для получения необоснованной налоговой выгоды, налогового мошенничества,
            уклонения от налогообложения, легализации (отмывания) доходов, полученных преступным путем,
            финансирования терроризма,экстремистской деятельности, коррупции, незаконного финансирования
            избирательных кампаний, политических партий, общественных организаций и иных операций в нарушение
            действующего законодательства.
            <br />
            <br />
            - Предоставлять третьему лицу (включая Компанию) доступ в мобильное приложение «Мой налог» и/или
            предоставлять третьему лицу портативное устройство Самозанятого (мобильный телефон или компьютер,
            включая планшетный компьютер), через который Самозанятый имеет доступ к указанному мобильному
            приложению.
            <br />
            <br />
            - Передавать третьим лицам информацию, которая необходима для его аутентификации и известна только
            Клиенту. В случае, если указанная информация стала известна третьим лицам, Клиент должен сообщить
            об этом Скилла Работа. Скилла Работа не несет ответственность за неправомерные действия третьих
            лиц, а также их доступ к информации Личного кабинета и Аккаунта Клиента в случае если информация,
            необходимая для входа в Личный кабинет и аутентификации Клиента стала известна третьим лицам не по
            вине Скилла Работа.
            <br />
            <br />
            - Использовать виртуальные телефонные номера или арендовать телефонные номера третьих лиц для
            авторизации на Платформе, в том числе с помощью сторонних сервисов предоставления таких номеров. В
            случае использования таких номеров и сервисов, Клиент несет самостоятельно всю полноту
            ответственности за последствия такого использования.
            <br />
            <br />
            <br />
            В случае обнаружения нарушения Клиентом запретов, указанных в Разделе “Ограничение прав
            использования” Пользовательского соглашения, Скилла Работа вправе приостановить или прекратить
            использование Клиентом Платформы, расторгнуть с ним Пользовательское соглашение в одностороннем
            порядке. При этом Скилла Работа вправе требовать возмещения убытков.
            <br />
            <br />
            В случае, если Компания самостоятельно или с привлечением третьих лиц внесла правки в исходный код
            Дополнительного модуля, в том числе модуля сопряжения или обработчика, Скилла Работа не несет
            ответственности за правильность работы такого Дополнительного модуля и его негативного влияния на
            действия всей Платформы и систем Компании. В случае выявления таких правок, Компания обязуется
            возместить Скилла Работа все причиненные такими правками убытки. В случае, если для исправления
            правок Компании понадобится доработка Скилла Работа, такая доработка будет произведена Скилла
            Работа на возмездной основе.
            <br />
            <br />
            Все объекты, доступные на Платформе, в том числе элементы дизайна, текст, графические изображения,
            иллюстрации, видео, программы для ЭВМ, базы данных, музыка, звуки и другие объекты, а также любой
            контент, размещенный на Платформе, являются объектами исключительных прав Скилла Работа.
            <br />
            <br />
            Использование контента, а также каких-либо иных элементов Платформы возможно только в рамках
            Пользовательского соглашения. Никакие элементы Платформы, а также любой контент, размещенный на
            Платформе, не могут быть использованы иным образом без предварительного разрешения Скилла Работа.
            <br />
            <br />
            <h3 style={{ width: '100%', textAlign: 'center' }}>Лицензионное вознаграждение</h3>
            <br />
            Лицензионное вознаграждение Скилла Работа за использование Платформы Скилла Работа исчисляется за
            каждое переданное посредством Платформы распоряжение Компании-Лицензиата.
            <br />
            <br />
            Компания подтверждает и безусловно соглашается с тем, что Скилла Работа вправе списывать
            причитающееся ей лицензионное вознаграждение в соответствии с Пользовательским соглашением из сумм
            денежных средств, перечисленных Компанией на Номинальный счет, при передаче в Банк каждого
            распоряжения Скилла Работа.
            <br />
            <br />
            В случае осуществления Скилла Работа Распоряжения Компании по повторному совершению операции в
            рамках одной сделки с Пользователем Скилла Работа списывает причитающееся ей лицензионное
            вознаграждение в размере не менее минимального тарифа за операцию согласно Приложению №1 к
            Пользовательскому соглашению.
            <br />
            <br />
            Окончательный размер лицензионного вознаграждения отображается в личном кабинете Компании и при
            создании Заказа.
            <br />
            <br />
            Лицензионное вознаграждение списывается в момент подписания Клиентами Акта к Договору или
            признания Акта к Договору подписанным в одностороннем порядке, при этом списание лицензионного
            вознаграждения Скилла Работа является первичным к выполнению Распоряжения Компании по совершению
            операции.
            <br />
            <br />
            Пользователь использует Платформу, в том числе путем предоставления удаленного доступа, на
            условиях «открытой лицензии» (ст. 1286.1 ГК РФ), что является безвозмездным для Пользователя
            (физического лица) и не противоречит ст. 1235 ГК РФ.
            <br />
            <br />
            За использование Дополнительных модулей или дополнительного функционала Платформы, в том числе
            путем предоставления удаленного доступа, Пользователь оплачивает лицензионное вознаграждение ,
            размер которого указывается в Личном кабинете при попытке использования такого Дополнительного
            модуля или дополнительного функционала.
            <br />
            <br />
            Лицензионное вознаграждение за использование Дополнительного модуля или дополнительного
            функционала Платформы, в том числе путем предоставления удаленного доступа, Пользователем подлежит
            оплате при использовании такого Дополнительного модуля или дополнительного функционала.
            <br />
            <br />
            В случае, если Пользователь имеет статус Самозанятого, он имеет право безвозмездно подключить
            Дополнительный модуль «Налоговая копилка», а также отключить Дополнительный модуль «Налоговая
            копилка» и запросить возврат средств в связи с таким закрытием.
            <br />
            <br />
            Размеры лицензионного вознаграждения за использование Платформы и Дополнительных модулей, в том
            числе путем предоставления удаленного доступа, для Компании указываются в Приложении №1 к
            Пользовательскому соглашению, а также отображается в Личном кабинете.
            <br />
            <br />
            Компания обязуется уплачивать Скилла Работа лицензионное вознаграждение, начиная с даты получения
            доступа к Дополнительному модулю или дополнительному функционалу в интерфейсе Платформы, такая
            дата также является датой предоставления Платформы.
            <br />
            <br />
            Компания подтверждает и безусловно соглашается с тем, что Скилла Работа вправе списывать
            причитающееся ей лицензионное вознаграждение , в соответствии с Пользовательским соглашением из
            сумм денежных средств, перечисленных Компанией на Номинальный счет, при передаче в Банк каждого
            распоряжения Скилла Работа.
            <br />
            <br />
            При недостаточности средств для списания в размере лицензионного вознаграждения на Номинальном
            счете Скилла Работа вправе приостановить или ограничить доступ Компании к Платформе. В случае
            неуплаты лицензионного вознаграждения Компанией в течение 3 (трех) календарных месяцев подряд,
            Скилла Работа имеет право расторгнуть Пользовательское соглашение в одностороннем порядке, без
            уведомления Компании, в течение 1 (одного) рабочего дня и произвести блокировку доступа Компании к
            Платформе.
            <br />
            <br />
            <br />
            В связи с бессрочностью настоящих правоотношений, Скилла Работа вправе в одностороннем порядке не
            чаще одного раза в год инициировать изменение размера лицензионного вознаграждения выплачиваемого
            Компанией, изменив Приложение №1, а также направив соответствующее уведомление Компании
            посредством Платформы с указанием предполагаемой даты вступления изменения в силу, но не менее,
            чем за 30 (Тридцать) календарных дней до даты вступления изменения в силу.
            <br />
            <br />
            В случае несогласия с новым размером лицензионного вознаграждения Скилла Работа Компания обязуется
            прекратить до вступления в силу изменений использование Платформы и/или Дополнительных модулей, а
            также в тот же срок направить уведомление о расторжении Пользовательского соглашения.
            <br />
            <br />
            В случае возникновения споров между Сторонами относительно сумм вознаграждений Лицензиара Скилла
            Работа Компания признает информационные системы Платформы в качестве достоверного источника для
            проверки исчисляемого и списываемого лицензионного вознаграждения Скилла Работа.
            <br />
            <br />
            Стоимость лицензионного вознаграждения по настоящему Пользовательскому соглашению не возвращается
            Компании-Лицензиату и не подлежит перерасчету Скилла Работа в случае расторжения Пользовательского
            соглашения по инициативе любой из Сторон.
            <br />
            <br />
            Лицензионное вознаграждение не облагается НДС в соответствии с пп. 26 п. 2 ст. 149 Налогового
            кодекса РФ.
            <br />
            <h3 style={{ width: '100%', textAlign: 'center' }}>Отчеты Скилла Работа</h3>
            <br />
            Отчет о размерах лицензионного вознаграждения Лицензиара указывается в Личном кабинете Компании в
            разделе Сводка.
            <br />
            <br />
            Раздел Сводка содержит информацию о движении денежных средств по распоряжению Компании по
            совершению операции, включая лицензионное вознаграждение Скилла Работа за использование Платформы.
            <br />
            <br />
            Размер Лицензионного вознаграждения Лицензиара указывается в момент создания Заказа в Личном
            кабинете Компании.
            <br />
            <br />
            В случае возникновения вопросов относительно размеров лицензионного вознаграждения Лицензиара
            Компания вправе направить соответствующее письмо на адрес электронной почты support@skilla.ru.
            <br />
            <br />
            Ежемесячный отчет лицензионного вознаграждения Лицензиара пополняется в разделе Сводка в личном
            кабинете Компании в режиме реального времени.
            <br />
            <br />
            Ежемесячно, не позднее 5-го (пятого) числа месяца, следующего за отчетным периодом, Скилла Работа
            на основании имеющейся у нее информации формирует Отчет по итогам месяца, в котором указывается
            размер лицензионного вознаграждения, и размещает его в Личном кабинете Компании.
            <br />
            <br />
            В течение 5 (пяти) рабочих дней со дня размещения Скилла Работа-Лицензиаром в Личном кабинете
            Компании Отчета Компания проверяет и согласовывает Отчет путем направления сообщения о
            согласовании Отчета на адрес электронной почты support@skilla.ru либо при несогласии с данными,
            указанными в Отчете, в тот же срок предоставляет мотивированные возражения, направляя их на адрес
            электронной почты support@skilla.ru.
            <br />
            <br />
            Если в течение 5 (пяти) рабочих дней, предлагаемых по условиям Пользовательского соглашения
            Компании для согласования Отчета или дачи мотивированных возражений по нему, Компания не направила
            на адрес электронной почты support@skilla.ru сообщение о согласовании Отчета или не представила
            мотивированные возражения, Отчет считается принятым Компанией в полном объеме и без замечаний, в
            том числе в части расчета вознаграждения Лицензиара, услуги оказаны надлежащим образом и подлежат
            оплате.
            <br />
            <br />
            В случае расторжения Пользовательского соглашения, Скилла-Работа и Компания делают сверку расчетов
            и погашают денежные обязательства, выявленные в результате сверки, в течение 30 календарных дней с
            момента получения извещения одной из Сторон о намерении расторгнуть Пользовательское соглашение.
            <br />
            <br />
            <h3 style={{ width: '100%', textAlign: 'center' }}>Заверения об обстоятельствах</h3>
            <br />
            Акцептуя Пользовательское соглашение, Клиент заверяет о следующих обстоятельствах:
            <br />
            <br />
            - Клиент обладает всеми необходимыми правами, позволяющими ему акцептовать Пользовательское
            соглашение, осуществлять регистрацию и использовать Платформу; <br />
            <br />
            - Клиент указывает достоверную информацию о себе в объемах, необходимых для использования
            Платформы. При заполнении информации в Аккаунте, Клиент обязуется не указывать сведения,
            относящиеся к расовой и национальной принадлежности, политическим, религиозным и философским
            воззрениям и др. <br />
            <br />
            - Акцептуя Пользовательское соглашение, Компания заверяет о следующих обстоятельствах: Компания
            заверяет, что соблюдает требования законодательства в части ведения налогового и бухгалтерского
            учета, полноты, точности и достоверности отражения операций в учете, исполнения налоговых
            обязательств по начислению и уплате налогов и сборов, а также требования трудового
            законодательства и законодательства о социальном обеспечении, в том числе в части полноты
            отражения в учете начислений и выплат работникам, полноты уплачиваемых страховых взносов. <br />
            <br />
            - Компания ведет и своевременно подает в налоговые и иные государственные органы финансовую,
            налоговую, статистическую и иную государственную отчетность в соответствии с законодательством РФ.
            <br />
            <br />
            - Компания заверяет, что на момент заключения Пользовательского соглашения, путем подписания
            Лицензионного договора, не имеет просроченной задолженности по налогам и сборам, превышающей 500
            000 руб.
            <br />
            <br />
            - Компания заверяет и гарантирует Скилла Работа, что на момент заключения Пользовательского
            соглашения в налоговых периодах, в течение которых совершаются операции в рамках Пользовательского
            соглашения:
            <br />
            <br />
            - основной целью совершения сделки (совершения операций) не являются неуплата (неполная уплата)
            и/или зачет (возврат) суммы налога;
            <br />
            <br />
            - не осуществляет и не будет осуществлять уменьшение налоговой базы и/или суммы подлежащего уплате
            налога, страховых взносов в результате искажения сведений о фактах хозяйственной жизни
            (совокупности таких фактов), об объектах налогообложения и отчислений, в том числе за счет
            дробления бизнеса и/или необоснованного применения специальных налоговых режимов.
            <br />
            <br />
            - Компания подтверждает, что в период действия Пользовательского соглашения она надлежащим образом
            исполняет обязанности налогового агента по НДФЛ, а также уплачивает страховые взносы в
            соответствии с законодательством РФ в отношении выплат в адрес физических лиц, привлекаемых для
            оказания услуг посредством Платформы.
            <br />
            <br />
            - Компания заверяет и гарантирует Скилла Работа, что на момент предоставления номеров телефона
            третьих лиц, для целей сотрудничества с ними на Платформе, руководствуясь требованиями
            Федерального закона от 07.07.2003 г. № 126-ФЗ «О связи», Компания получила согласия таких третьих
            лиц на отправку им СМС-сообщений Скилла Работа посредством Платформы. Согласия третьих лиц должны
            быть выражены посредством совершения ими действий, однозначно идентифицирующих этих третьих лиц и
            позволяющих достоверно установить их волеизъявление на получение СМС-сообщений от Скилла Работа.
            Согласия должны быть получены на срок действия Пользовательского соглашения, а также на пять лет
            после его расторжения Компанией. По требованию Скилла Работа Компания должна предоставлять
            согласия третьих лиц в течение одного рабочего дня. В случае непредставления или несвоевременного
            предоставления Компанией согласий третьих лиц, Компания обязуется возместить Скилла Работа все
            убытки, в случае нарушения Компанией обязательств, предусмотренных настоящим пунктом
            Пользовательского соглашения.
            <br />
            <br />
            Стороны исходят из того, что Скилла Работа полагается на предоставленные Компанией заверения и
            гарантии.
            <br />
            <br />
            Клиент обязуется возместить Скилла Работа все имущественные потери, включая документально
            подтвержденные досудебные и судебные расходы, сумму доначисленного и уплаченного налога на прибыль
            организаций, страховых взносов, НДФЛ и/или НДС в связи с нарушением Клиентом заверений и гарантий,
            указанных в Пользовательском соглашении.
            <br />
            <br />
            <h3 style={{ width: '100%', textAlign: 'center' }}>Заключение и исполнение Договора</h3>
            <br />
            Условия Договора определяются Компанией и Пользователем самостоятельно. Договор и документы к нему
            подписывается ПЭП посредством Платформы. Скилла Работа рекомендует Компании и Пользователю
            разместить в Договоре информацию о его заключении посредством Платформы, а также об использовании
            сторонами ПЭП.
            <br />
            <br />
            Компания и Пользователь совместно определяют размер вознаграждения в порядке, определенном в
            Договоре. Оплата производится посредством Платформы. Оплата считается произведенной в момент
            списания размещенных денежных средств на Номинальном счете Компании на Пользователя.
            <br />
            <br />
            Используя функционал Платформы, позволяющий признавать акт к Договору подписанным в одностороннем
            порядке, Компания и Пользователь, заверяют, что Договор, заключенный между ними содержит положения
            о возможности признания односторонне подписанных актов юридически значимыми. Кроме того, Клиенты
            понимают и принимают, что они используют данный функционал под свою ответственность и должны сами
            обеспечивать легитимность исполнения созданных с помощью функционала актов.
            <br />
            <br />
            <h3 style={{ width: '100%', textAlign: 'center' }}>Формирование Задания</h3>
            <br />
            Компания вправе предварительно размещать предложение с условиями, на которых она готова
            осуществить сотрудничество с Пользователем на Платформе.
            <br />
            <br />
            Компания не вправе добавлять в предложения о сотрудничестве ссылки на сторонние сайты, ресурсы и
            сервисы в сети Интернет.
            <br />
            <br />
            Скилла Работа вправе осуществлять модерацию, а именно осуществлять проверку и/или вносить
            изменения в направленное Компанией предложение о сотрудничестве. В случае внесения изменений,
            Скилла Работа согласует измененный вариант предложения о сотрудничестве с Компанией. Общий срок
            модерации на стороне Скилла Работа составляет 7 (Семь) календарных дней.
            <br />
            <br />
            Скилла Работа не несет ответственности за содержание предложения о сотрудничестве, представленное
            Компанией.
            <br />
            <br />
            Скилла Работа вправе отказать Компании в публикации предложения о сотрудничестве без объяснения
            причин.
            <br />
            <br />
            Пользователь имеет право откликнуться на любое предложение любой Компании с помощью раздела «Поиск
            предложений».
            <br />
            <br />
            После того, как Пользователь откликнулся на предложение, Компания имеет право принять его отклик и
            направить ему на согласование и подписание Договор.
            <br />
            <br />
            После подписания сторонами Договора между Компанией и Пользователем, Компания может формировать в
            интерфейсе Платформы и отправлять Пользователю Заказ. Отправляя Заказ Пользователю, Компания
            направляет Пользователю оферту. Отменить Заказ без согласования с Пользователем, Компания может до
            акцепта такого Заказа Пользователем.
            <br />
            <br />
            Пользователь обязуется ознакомиться со всеми условиями, содержащимися в Заказе. Нажатие
            авторизованным Пользователем в интерфейсе Платформы кнопки «Принять», означает акцепт оферты, а
            также согласие Пользователя со всеми условиями конкретного Заказа, размещенного в интерфейсе
            Платформы. После принятия Заказа Пользователем, оно становится для сторон обязательным для
            исполнения и влечет юридические последствия. Пользователь может отказаться от Заказа, нажав в
            интерфейсе Платформы кнопку «Отклонить».
            <br />
            <br />
            После исполнения Заказа Пользователем, Компания принимает такое исполнение, нажав на
            соответствующую кнопку в интерфейсе Платформы. Принятие исполненного по Заказу Компанией, является
            основанием для права требования выплаты вознаграждения Пользователем.
            <br />
            <br />
            Выбирая определенную Компанию, Пользователь дает свое согласие на направление ей Скилла Работа
            персональных данных Пользователя, включая контактную информацию.
            <br />
            <br />
            Информация о Заказах доступна в Личном кабинете.
            <br />
            <br />
            <h3 style={{ width: '100%', textAlign: 'center' }}>Исполнение распоряжений Компании</h3>
            <br />
            Компания посредством Платформы передает Скилла Работа сведения, для оформления Распоряжения на
            совершение Операций по Номинальному счету, при этом сама дает Распоряжения на совершение операций.
            Осуществление данных действий является выражением воли Компании на формирование Распоряжений и
            совершение операций. Компания посредством Платформы отслеживает статус исполнения Распоряжений,
            получает информацию об операциях по Номинальному счету или счету Компании, открытом в Банке.
            <br />
            <br />
            Скилла Работа посредством Платформы и с помощью Технологического партнера и Банка проводит
            Операции на основании Распоряжений, полученных от Компании в соответствии с условиями
            Пользовательского соглашения.
            <br />
            <br />
            Данную обязанность Скилла Работа или Банк исполняет только при поступлении от Компании на
            Номинальный счет денежных средств в размере, необходимом для исполнения ее Распоряжений или при
            наличии на счете Компании, открытом в Банке достаточного количества денежных средств.
            <br />
            <br />
            Скилла Работа обязана в течение 10 (Десяти) рабочих дней с момента получения соответствующего
            запроса от Компании предоставить ей информацию об обстоятельствах совершения спорных Операций
            только в случае, если Компанией была получена претензия от Пользователя в отношении исполнения
            обязательств Компании по Договору.
            <br />
            <br />
            Для совершения Операций Скилла Работа использует Номинальный счет или счет Компании, открытый в
            Банке. Скилла Работа не несет ответственности в случае отказа Банка и Технологического партнера
            проводить Операции. При этом Скилла Работа обязуется своевременно передавать Компании
            соответствующую информацию, а также запросы Банка и Технологического партнера.
            <br />
            <br />
            Компания для совершения Операций должна предоставлять Скилла Работа запрашиваемые документы и
            информацию в полном объеме, требуемом Банком.
            <br />
            <br />
            Скилла Работа вправе не принимать и/или не исполнять Распоряжение Компании и/или приостановить
            совершение Операций:
            <br />
            <br />
            - в случае нарушения Компанией своей обязанности по уплате Скилла Работа вознаграждения, в том
            числе недостаточности средств для списания в размере вознаграждения Скилла Работа на Номинальном
            счете;
            <br />
            <br />
            - в случае возникновения у Скилла Работа сомнений в подлинности поступающих к нему Распоряжений, о
            чем она уведомляет Компанию в разумный срок. Совершение Операций возобновляется с момента
            получения Скилла Работа от Компании подтверждения подлинности Распоряжений и возможности принять
            их к исполнению;
            <br />
            <br />
            - в случае, если Скилла Работа получила Распоряжение, которое не позволяет совершить Операцию
            (отсутствует надлежащий Пользователь, некорректные данные и т.п.);
            <br />
            <br />
            - в случае, если у Скилла Работа нет подтверждения прав соответствующего Уполномоченного лица
            распоряжаться денежными средствами Компании;
            <br />
            <br />
            - в случае, если исполнение Распоряжения противоречит, нарушает и/или может повлечь нарушение
            Скилла Работа законодательства Российской Федерации, прав Пользователей и/или третьих лиц;
            <br />
            <br />
            - в случае, если Компания не исполняет иные обязательства, предусмотренные Пользовательским
            соглашением;
            <br />
            <br />
            - в случае отсутствия/недостаточности денежных средств Компании на Номинальном счете или счете
            Компании открытом в Банке;
            <br />
            <br />
            - по требованию Банка. В указанном случае для совершения Операции Компании и(или) Пользователю
            может быть необходимо предоставить поясняющую информацию и надлежаще заверенные копии документов,
            позволяющих Банку однозначно убедиться в законности совершаемых Операций;
            <br />
            <br />- в случае, если Скилла Работа получила Распоряжение на совершение Операции, превышающей
            лимиты Компании и/или Пользователя в соответствии с Правилами по выставлению лимитов для Клиентов
            Платформы, размещенными на официальном сайте Скилла Работа по адресу{' '}
            <a
              style={{ color: '#ffff', textDecoration: 'underline' }}
              href="https://skilla.ru/legal"
              target="_blank"
            >
              https://skilla.ru/legal
            </a>
            .
            <br />
            <br />
            <br />
            <h3 style={{ width: '100%', textAlign: 'center' }}>Полномочия Скилла Работа</h3>
            <br />
            В случае, когда в роли Пользователя выступает Самозанятый, в зависимости от того посредством
            Расчетного или Номинального счета подключена Компания и чей токен используется, Скилла Работа,
            может при предоставлении одному из них Самозанятым соответствующих прав в порядке, предусмотренном
            законодательством, а также при наличии соответствующей технической возможности:
            <br />
            <br />
            (а) осуществление информационного обмена с налоговыми органами, включая получение от налоговых
            органов сведений, полученных налоговыми органами при применении такими физическими лицами
            специального налогового режима. Порядок реализации таких полномочий Скилла Работа устанавливаются
            законодательством и требованиями налоговых органов Российской Федерации.
            <br />
            <br />
            (б) от имени Самозанятого декларировать доходы Самозанятого, в порядке, установленном
            законодательством РФ,
            <br />
            <br />
            (в) формировать акт о реализации товаров, работ, услуг Самозанятого,
            <br />
            <br />
            (г) участвовать в расчетах между Самозанятыми и Компаниями,
            <br />
            <br />
            (д) по распоряжению Самозанятого перечислять налог на профессиональный доход в бюджет с
            использованием Дополнительного модуля «Налоговая копилка».
            <br />
            <br />
            Самозанятый является бенефициаром Номинального счета, в случае, если функционал предоставляется
            Скилла Работа, только в части средств, подлежащих перечислению в ИФНС, включая сумму налога на
            профессиональный доход, который был удержан с использованием Дополнительного модуля «Налоговая
            копилка» из вознаграждения такого Самозанятого, до момента перечисления налога в бюджет.
            <br />
            <br />
            Скилла Работа не является стороной Договора между Клиентами, в связи с чем она не регулирует и не
            контролирует соответствие указанного договора требованиям законодательства Российской Федерации, а
            также факт и последствия заключения, исполнения и расторжения такого Договора.
            <br />
            <br />
            <br />
            Скилла Работа не рассматривает претензии Клиентов, касающиеся неисполнения (ненадлежащего
            исполнения) Клиентами своих обязательств по Договору.
            <br />
            <br />
            Клиент согласен с тем, что Скилла Работа может полностью или частично уступать третьим лицам свои
            права и обязанности по Пользовательскому соглашению, предварительно известив об этом Клиента одним
            из следующих способов:
            <br />
            <br />
            (а) посредством размещения Пользовательского соглашения в новой редакции на Сайте, при этом датой
            получения уведомления считается дата акцепта Клиентомсоответствующих изменений в Пользовательское
            соглашение, определяемая в соответствии с настоящим Пользовательским соглашением; и/или
            <br />
            <br />
            (б) посредством размещения уведомления на Сайте, при этом датой получения уведомления Клиентом
            считается 3 (Третий) календарный день с момента размещения соответствующей информации на Сайте;
            и/или
            <br />
            <br />
            (в) посредством направления уведомления по адресу электронной почты Клиента, указанной им ранее
            Скилла Работа, при этом датой получения уведомления Клиентом считается следующий день с момента
            направления Скилла Работа Клиенту соответствующей информации на электронную почту. Скилла Работа
            вправе по своему усмотрению осуществлять выбор способа уведомления Клиента о полной или частичной
            уступке третьим лицам своих прав и обязанностей по Пользовательскому соглашению.
            <br />
            <br />
            Уведомление Клиента в обязательном порядке должно содержать информацию о лице, которому Скилла
            Работа уступает свои права. При одновременном уведомлении Клиента Скилла Работа несколькими
            различными способами датой получения уведомления Клиента считается дата, наступившая раньше
            остальных в соответствии с настоящим Пользовательским соглашением.
            <br />
            <br />
            Скилла Работа не занимается правовым анализом товаров, работ и услуг на предмет выявления в них
            признаков незаконной деятельности или нарушения налогового законодательства, а также не несет
            ответственность за нарушение Клиентами законодательства. При этом Скилла Работа вправе (но не
            обязана) предпринимать меры, направленные на пресечение подобного поведения Клиентов в случае,
            если он выявит признаки такого поведения, а также проинформирует Клиентов о наличии таких
            признаков.
            <br />
            <br />
            Скилла Работа не отвечает за достоверность информации, представленной Самозанятым/ его
            представителем при его постановке на учет в налоговом органе в порядке, установленном Федеральным
            законом от 27.11.2018 № 422-ФЗ, а также при использовании Платформы. Скилла Работа не несет
            обязательств по проверке данной информации сверх тех обязанностей, которые могут вытекать из
            указанного федерального закона.
            <br />
            <br />
            Скилла Работа вправе требовать от Клиента внести изменения в информацию/материалы в его Личном
            кабинете. Причинами такого требования могут быть, включая, но не ограничиваясь, следующие
            обстоятельства: подозрение, что размещаемые материалы нарушают права третьих лиц на
            интеллектуальную собственность; обращения Клиентов с жалобами; подозрение, что размещаемые
            материалы нарушают законодательство РФ; не соответствуют общепринятым критериям нравственности; не
            соответствуют внутренней политике Платформы.
            <br />
            <br />
            Скилла Работа оставляет за собой право на свое усмотрение ограничить доступ Клиента к Платформе
            (или к определенным функциям Платформы, если это возможно технологически) при неоднократном
            нарушении Пользовательского соглашения или(и) требований законодательства или(и) прав и законных
            интересов третьих лиц, либо применить к Клиенту иные меры с целью соблюдения Пользовательского
            соглашения, а также требований законодательства или прав и законных интересов третьих лиц.
            Ограничение доступа к Платформе или к определенным функциям Платформы может осуществляться Скилла
            Работа для всех юридических лиц, с общим “id” (внутренний индикатор Компаний принятый Скилла
            Работа) и имеющих доступ к программному обеспечению Скилла Работа в случае, если одно из таких
            юридических лиц нарушает положения Пользовательского соглашения или(и) требований законодательства
            или(и) прав и законных интересов третьих лиц.
            <br />
            <br />
            Скилла Работа устанавливает личность Клиента, если это необходимо в целях выполнения требований
            нормативных актов по противодействию легализации денежных средств, полученных преступным путем.
            <br />
            <br />
            Скилла Работа устанавливает личность Клиента, если это необходимо в целях выполнения требований
            нормативных актов по противодействию легализации денежных средств, полученных преступным путем.
            <br />
            <br />
            Клиент должен предоставить Скилла Работа по ее запросу все необходимые документы, в том числе –
            документы для заключения Пользовательского соглашения. В противном случае Скилла Работа вправе
            приостановить обслуживание Клиента / отказать ему в заключении Пользовательского соглашения.
            <br />
            <br />
            <h3 style={{ width: '100%', textAlign: 'center' }}>Персональные данные</h3>
            <br />
            Платформа представляет собой программное обеспечение, включающее информационную систему,
            оператором которой является Скилла Работа. Обработка персональных данных и иной информации,
            получаемой Cкилла Работа, происходит в соответствии с Политикой обработки и защиты персональных
            данных, размещенной на официальном сайте Скилла Работа, Законом «О защите персональных данных», а
            также в соответствии с иным действующим Законодательством.
            <br />
            <br />
            Компания, как оператор персональных данных своих контрагентов - Пользователей и своих
            Уполномоченных лиц поручает Скилла Работа обработку передаваемых персональных данных Пользователей
            и уполномоченных лиц на Платформе, включая сбор, запись, систематизацию, накопление, хранение,
            уточнение, извлечение, использование, передачу, обезличивание, уничтожение, которые будут
            загружены Компанией на Платформу, для целей исполнения Договора, а также между указанным
            Пользователем - субъектом персональных данных и Скилла Работа, для корректного обслуживания
            Компании и указанных субъектов персональных данных на Платформе. Компания гарантирует, что
            получила согласия субъектов персональных данных на передачу их персональных данных Скилла Работа в
            соответствии с Федеральным законом «О защите персональных данных». Данное согласие должно быть
            взято на срок действия Пользовательского соглашения, а также на пять лет после его расторжения.
            <br />
            <br />
            Клиенты должны своевременно информировать Скилла Работа об изменении персональных данных,
            реквизитов, данных, ранее предоставленных Скилла Работа, а также данных для направления
            уведомлений. Ненаправление Клиентом такого уведомления об отсутствии этих изменений признается
            подтверждением Клиентом действительности и неизменности ранее сообщенных данных.
            <br />
            <br />
            Компания обязана уведомить Скилла Работа о смене единоличного исполнительного органа в течение 3
            (Трех) рабочих дней с даты регистрации такой смены.
            <br />
            <br />
            Компания обязана уведомить Скилла Работа об увольнении Уполномоченного лица действующего по
            доверенности с правом передоверия в течение 1 (Одного) рабочего дня с даты увольнения.
            <br />
            <br />
            Пользователь, для исполнения настоящего Пользовательского соглашения, а также для совершения
            расчетов с помощью номинального счета площадки, дает согласие на передачу персональных данных,
            переданных Скилла Работа, потенциальным и текущим контрагентам - Компаниям, присутствующим на
            Платформе, а также передачу персональных данных непосредственно в АО «Точка» и в ООО "Банк Точка",
            налоговым органам, органам миграционной службы Российской Федерации и, в случаях установленных
            законодательством Российской Федерации, иным органам власти Российской Федерации и
            <br />
            <br />
            Клиенты осознают и соглашаются с тем, что персональные данные, профиль Клиента, а также информация
            в рамках процессов заключения/ исполнения/ изменения/ расторжения договоров, заключенных на
            Платформе, могут быть доступны другим Клиентам, с которыми первые взаимодействуют на Платформе.
            Размещая такую информацию на Платформе, Клиент дает согласие на то, что она может быть доступна
            другим Клиентам, в случае наличия взаимодействия между такими Клиентами.
            <br />
            <br />
            Клиент соглашается с тем, что Скилла Работа вправе хранить загруженную на Платформу информацию,
            включая действия, совершенные на Платформе в течение пяти лет с даты расторжения Пользовательского
            соглашения на условиях Пользовательского соглашения.
            <br />
            <br />
            <h3 style={{ width: '100%', textAlign: 'center' }}>Простая электронная подпись</h3>
            <br />
            Клиент, акцептуя Пользовательское соглашение, дает свое согласие на создание для него ПЭП для
            подписания юридически значимых документов с Скилла Работа, а также для подписания юридически
            значимых документов с другими Клиентами. Данные ПЭП будут использоваться исключительно для
            взаимодействия на Платформе.
            <br />
            <br />
            Клиент соглашается, что все действия, совершенные на Платформе считаются совершенными Клиентом.
            <br />
            <br />
            Стороны признают, что все юридические значимые документы между ними, а также между Клиентами,
            включая Договоры, Заказы, документы об их изменении или расторжении, а также закрывающие
            бухгалтерские документы по таким Договорам (в случае необходимости) считаются подписанными ПЭП
            соответствующей Стороны.
            <br />
            <br />
            При отправке Клиентом Электронного документа на Платформе, вместе с Электронным документом в
            автоматическом режиме отправляется также уникальная последовательность символов. При получении
            Электронного документа Платформа в автоматическом режиме проверяет соответствие полученной
            уникальной последовательности символов, имеющейся на Платформе, тем самым подтверждает подлинность
            ПЭП.
            <br />
            <br />
            Стороны соглашаются с тем, что документы, подписанные ПЭП, имеют равную юридическую силу с
            документами, составленными на бумажном носителе и подписанными собственноручной подписью.
            Документ, подписанный ПЭП, порождает права и обязательства Сторон, если он соответствует условиям,
            определенным Пользовательским соглашением. Свидетельством того, что документ, подписанный ПЭП,
            получен второй Стороной, является отображение соответствующего Электронного документа (сообщения)
            в интерфейсе Платформы у Скилла Работа и/или в интерфейсе Личного кабинета.
            <br />
            <br />
            В качестве доказательств, подтверждающих авторизацию Клиента при использовании Платформы, а также
            подписание им ПЭП документов/ сообщений/ команд, переданных посредством Платформы, являются
            протоколы (логи) действий Клиента и происходящих в связи с этим событий, а также личная переписка
            между Пользователем и Компанией, Клиентом и Скилла Работа. Стороны признают, что выгрузка логов из
            автоматической системы протоколирования является надлежащим доказательством в случае судебного
            разбирательства для целей установления факта подписания Электронных документов и совершения
            каких-либо действий на Платформе.
            <br />
            <br />
            Стороны признают, что ПЭП, используемая на Платформе, соответствует следующим критериям:
            <br />
            <br />
            - позволяет определить лицо, подписавшее Электронный документ;
            <br />
            <br />
            - позволяет обнаружить факт внесения изменений в Электронный документ после момента его
            подписания.
            <br />
            <br />
            Стороны при использовании Ключа ПЭП обязуются соблюдать его конфиденциальность:
            <br />
            <br />
            - не передавать доступ к открытой части Ключа ПЭП неуполномоченным третьим лицам;
            <br />
            <br />
            - хранить в тайне полученную закрытую часть Ключа ПЭП, не раскрывать и не передавать ее
            неуполномоченным третьим лицам;
            <br />
            <br />
            - принимать все возможные меры, предотвращающие нарушение конфиденциальности и несанкционированное
            использование Ключа ПЭП.
            <br />
            <br />
            Стороны признают, что реализованные на Платформе механизмы формирования и использования ПЭП
            достаточны для обеспечения конфиденциальности информации, защиты от несанкционированного доступа и
            подтверждения авторства Клиента при использовании им ПЭП при условии соблюдения Сторонами
            необходимо мер конфиденциальности, а также, что:
            <br />
            <br />
            - Электронный документ, подписанный ПЭП, подписан этим Клиентом (подтверждение авторства
            документа);
            <br />
            <br />
            - Факт подписания Электронного документа ПЭП подтверждается формированием на подписанном
            Электронном документе отображения ПЭП, содержащей данные о подписанте, дате и времени подписания
            документа.
            <br />
            <br />
            Порядок подписания документов ПЭП представляет собой взаимодействие Платформы, Компании и
            Пользователя:
            <br />
            <br />
            - Компания формирует Электронный документ и самостоятельно загружает его на Платформу, выбирая, с
            каким Пользователем она планирует его подписать;
            <br />
            <br />
            - Компания подписывает Электронный документ своей ПЭП, подтверждая свое действие введением Пароля,
            направленного на Авторизованный номер или Авторизованную электронную почту, или посредством
            использования Токена;
            <br />
            <br />
            - После подписания Электронного документа Компанией, Пользователь подписывает его своей ПЭП,
            подтверждая своё действие введением Пароля, направленного на Авторизованный номер, или же при
            подписании посредством Мобильного приложения, Пользователь авторизовавшись со своего
            Авторизованного устройства и введя для входа Пароль, формирует ПЭП нажатием кнопки «подписать»,
            при этом Платформа проверяет пришел ли запрос на подписание с Авторизованного номера, если да, то
            ПЭП формируется и представляется на Электронном документе;
            <br />
            - После каждой итерации подписания Электронного документа Платформа формирует ПЭП соответствующего
            Клиента в виде записи в конце Электронного документа, подписываемого Сторонами;
            <br />
            <br />
            Выбирая соответствующую опцию на Платформе для подписания Электронного документа, Клиент
            посредством встроенного автоматического механизма Платформы присоединяет к нему свою ПЭП. Для
            подписания Электронного документа Клиент использует техническую возможность Платформы по
            формированию ПЭП в соответствии с процессом.
            <br />
            <br />
            Проверка ПЭП осуществляется на странице проверки подписи Платформы с помощью уникального номера
            ПЭП или уникального идентификатора Электронного документа, которые вводятся в соответствующее поле
            и сравнения результатов с данными ПЭП размещенными в Электронном документе. В случае идентичности
            сравниваемых данных ПЭП считается проверенной и действительной.
            <br />
            <br />
            В случае несогласия Клиента с результатом проверки ПЭП ему необходимо в течение одного рабочего
            дня с момента обнаружения спорности Электронного документа обратиться с опротестованием к Скилла
            Работа. По результатам обращения Скилла Работа проводит дополнительную проверку и в течение 10
            (десяти) рабочих дней выносит заключение о подлинности оспариваемой ПЭП.
            <br />
            <br />
            Лицо, подписывающее Электронный документ ПЭП определяется посредством используемой им открытой
            части ключа ПЭП - Авторизованного номера или Авторизованной электронной почты. ПЭП содержится в
            самом Электронном документе, подписываемом Сторонами, и содержит следующую информацию, позволяющую
            определить Стороны: наименование Компании и ее ИНН, ФИО Уполномоченного лица Компании и реквизиты
            доверенности (при действии посредством доверенности), ФИО Пользователя и его ИНН.
            <br />
            <br />
            <h3 style={{ width: '100%', textAlign: 'center' }}>Условия конфиденциальности</h3>
            <br />
            Скилла Работа и Клиенты (далее - Стороны) пришли к соглашению о том, что в течение срока действия
            Пользовательского соглашения, а также в течение 5 (пяти) лет по окончании его действия, обязуются
            обеспечить конфиденциальность информации и данных, получаемых друг от друга в связи с исполнением
            Договора и использованием Платформы, за исключением информации и данных, являющихся
            общедоступными.
            <br />
            <br />
            Под Конфиденциальной информацией подразумевается любая научно-техническая, технологическая,
            производственная, юридическая, финансово-экономическая или иная информация, в том числе
            составляющая секреты производства (ноу-хау), которая имеет действительную или потенциальную
            коммерческую ценность в силу неизвестности ее третьим лицам, к которой нет свободного доступа на
            законном основании (далее — «Конфиденциальная информация»).
            <br />
            <br />
            Под разглашением Конфиденциальной информации понимается любое действие или бездействие Стороны, в
            результате которого Конфиденциальная информация в любой возможной форме (устной, письменной,
            электронной, иной форме, в том числе с использованием технических средств) становится известной
            третьим лицам, без предварительного письменного согласия другой Стороны.
            <br />
            <br />
            Стороны обязуются не разглашать Конфиденциальную информацию третьим лицам без получения
            предварительного письменного согласия Стороны, являющейся владельцем Конфиденциальной информации.
            <br />
            <br />
            За несанкционированное разглашение Конфиденциальной информации, Стороны возмещают реальный ущерб,
            подтвержденный документально.
            <br />
            <br />
            Пользовательского соглашения не применяется в случаях:
            <br />
            <br />
            - информация, которая может быть запрошена компетентными органами в установленном
            законодательством РФ порядке;
            <br />
            <br />
            - информация, подлежащаю разглашению по вступившему в законную силу судебному решению.
            Распространение информации неограниченному кругу лиц только о факте заключения Пользовательского
            соглашения не требует согласия Сторон.
            <br />
            <br />
            Стороны несут ответственность за убытки, которые могут быть причинены другой Стороне в результате
            разглашения Конфиденциальной информации или несанкционированного использования Конфиденциальной
            информации в нарушение настоящих условий, за исключением случаев ее раскрытия, предусмотренных в
            разделе Условия конфиденциальности.
            <br />
            <br />
            <h3 style={{ width: '100%', textAlign: 'center' }}>Ответственность</h3>
            <br />
            Платформа предоставляется Клиенту «как есть» (as is) в соответствии с общепринятым в международной
            практике принципом. Это означает, что за проблемы, возникающие в процессе установки, обновления,
            поддержки и эксплуатации Платформы (в том числе проблемы совместимости с другими программными
            продуктами (пакетами, драйверами и др.), несоответствия результатов использования Платформы
            ожиданиям Клиента и т.п.), Скилла Работа ответственности не несет. Клиент должен понимать, что
            несет полную ответственность за возможные негативные последствия, вызванные несовместимостью или
            конфликтами Платформы с другими программными продуктами, установленными на Авторизованном
            устройстве Клиента.
            <br />
            <br />
            Скилла Работа не несет ответственности за любые виды убытков, произошедшие вследствие
            использования Клиентом Платформы или отдельных частей (модулей) Платформы.
            <br />
            <br />
            Скилла Работа не несет ответственность за невозможность использования Платформы Клиентом ввиду
            технических проблем, возникших не по вине Скилла Работа. В таких случаях Скилла Работа обязуется
            уведомить об этом Клиента посредством размещения информации на Сайте и/или в Личном кабинете.
            <br />
            <br />
            Скилла Работа не несет ответственности за какие-либо перебои в обслуживании, связанные с
            неполадками с оборудованием, системами подачи электроэнергии и/или линиями связи.
            <br />
            <br />
            <h3 style={{ width: '100%', textAlign: 'center' }}>Заключительные положения</h3>
            <br />
            Скилла Работа вправе отозвать Пользовательское соглашение - оферту на основании статьи 436
            Гражданского кодекса Российской Федерации. Пользовательское соглашение считается прекращенным с
            момента получения отзыва. Отзыв осуществляется путем размещения соответствующей информации на
            Платформе или Сайте.
            <br />
            <br />
            Пользовательское соглашение может быть расторгнуто досрочно:
            <br />
            <br />
            - по взаимному соглашению Сторон;
            <br />
            <br />
            - по инициативе Скилла Работа в одностороннем внесудебном порядке в случае нарушения Клиентом
            условий Пользовательского соглашения;
            <br />
            <br />
            - по инициативе Пользователя посредством направления уведомления в Чат или по электронной почте,
            указанной в реквизитах Пользовательского соглашения, не позднее, чем за 30 (Тридцать) календарных
            дней до предполагаемой даты расторжения при этом у Пользователя не должно быть невыполненных, но
            принятых Заказов и не расторгнутых договоров;
            <br />
            <br />
            - по инициативе Компании посредством направления уведомления в Чат или по электронной почте,
            указанной в реквизитах Пользовательского соглашения, не позднее, чем за 30 (Тридцать) календарных
            дней до предполагаемой даты расторжения.
            <br />
            <br />
            В случае расторжения Пользовательского соглашения по любому основанию, по письменному запросу
            Клиента, Скилла Работа предоставляет архив всех подписанных таким Клиентом документов в формате
            PDF, а также реестр всех таких документов с указанием уникальных идентификаторов документов.
            <br />
            <br />
            Денежные средства Клиента размещенные на Номинальном счету, после расторжения Пользовательского
            соглашения и после проведения окончательных расчетов будут возвращены клиенту на его счет в
            течение 30 (Тридцати) календарных дней с даты расторжения Пользовательского соглашения и
            проведения окончательных расчетов.
            <br />
            <br />
            Взаимодействие между Сторонами осуществляется через службу поддержки.
            <br />
            <br />
            В случае возникновения судебного спора между Клиентами по обязательствам, вытекающим из
            заключенных Договоров, Клиент, являющийся инициатором судебного разбирательства, обязуется
            уведомить об этом Скилла Работа не позднее 10 (десяти) календарных дней с даты подачи искового
            заявления.
            <br />
            <br />
            Возможные споры и разногласия по поводу исполнения Пользовательского соглашения регулируются
            Сторонами посредством направления претензии. Претензия направляется на реквизиты указанные в
            Пользовательского соглашении (для Скилла Работа) и в Аккаунте (для Клиента).
            <br />
            <br />
            Срок рассмотрения претензии — не более 30 (тридцати) календарных дней с момента ее получения.
            <br />
            <br />
            Неурегулированные споры в рамках Пользовательского соглашения передаются на рассмотрение в суд по
            месту нахождения Скилла Работа, если иная подсудность прямо не предусмотрена Законодательством.
            <br />
            <br />
            <br />
            <br />
            Реквизиты Скилла Работа:
            <br />
            <br />
            Общество с ограниченной ответственностью «Скилла Инновации»
            <br />
            ИНН 4706050219
            <br />
            ОГРН 1224700011733
            <br />
            Email: support@skilla.ru
            <br />
            Юр. Адрес: 195027, г Санкт-Петербург, пр-кт Энергетиков, д 10 литера а, помещ 1Н офис 415
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <h3 style={{ width: '100%', textAlign: 'center' }}>
              Приложение №1 к Пользовательскому соглашению платформы Скилла Работа
            </h3>
            <br />
            Тарифы для Компаний на предоставление доступа к платформе Скилла Работа, действующие с 01 января
            2024 года
            <br />
            <br />
            <table border="1" cellPadding="10" cellSpacing="0">
              <thead>
                <tr>
                  <th>Тариф</th>
                  <th>Размер вознаграждения с одной Операции</th>
                  <th>Условия тарифа</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Тариф "PRO-партнер"</td>
                  <td>0,6%, но не менее 49 рублей</td>
                  <td>Наличие дополнительного модуля "PRO-Партнер"</td>
                </tr>
                <tr>
                  <td>Тариф "Партнер"</td>
                  <td>1,5%, но не менее 100 рублей</td>
                  <td>Наличие дополнительного модуля "Партнер"</td>
                </tr>
                <tr>
                  <td>Тариф "Партнер Урал"</td>
                  <td>1,3%, но не менее 55 рублей</td>
                  <td>Наличие дополнительного модуля "Партнер Урал"</td>
                </tr>
                <tr>
                  <td>Тариф "Партнер Центральный регион"</td>
                  <td>1,55%, но не менее 75 рублей</td>
                  <td>Наличие дополнительного модуля "Партнер Центральный регион"</td>
                </tr>
                <tr>
                  <td>Тариф "Партнер Дальний восток"</td>
                  <td>1,8%, но не менее 55 рублей</td>
                  <td>Наличие дополнительного модуля "Партнер Дальний восток"</td>
                </tr>
                <tr>
                  <td>Тариф "Партнер Юг"</td>
                  <td>1,5%, но не менее 55 рублей</td>
                  <td>Наличие дополнительного модуля "Партнер Юг"</td>
                </tr>
                <tr>
                  <td>Тариф "Индивидуальный"</td>
                  <td>2,5%, но не менее 75 рублей</td>
                  <td>Наличие дополнительного модуля "Индивидуальный"</td>
                </tr>
                <tr>
                  <td>Тариф "Базовый"</td>
                  <td>3,0%, но не менее 100 рублей</td>
                  <td>Наличие дополнительного модуля "Базовый"</td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
          </p>
        </div>
      </div>
    </header>
  )
}

export default HeaderLegal
