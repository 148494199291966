import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { getUser } from '../../../../../Api';
import { setAddUser } from '../../../../../store/reducer/lk/authChech/slice';
import { selectLkForm } from '../../../../../store/reducer/lk/form/selector';
import {
	setAddData,
	setAddStep,
} from '../../../../../store/reducer/lk/form/slice';
import s from '../steps.module.scss';
import a from '../../../Contract/contract.module.scss';
import { selectLKAuth } from '../../../../../store/reducer/lk/authChech/selector';
import { Spinner } from '../../../../spinner/Spinner';
import Button from '../../Button/Button';
import Form from '../../Form';

const Step8 = () => {
	const [width, setWidth] = useState(window?.innerWidth);
	const dispatch = useDispatch();
	const { data, step } = useSelector(selectLkForm);
	const { user } = useSelector(selectLKAuth);
	const [loading, setLoading] = useState(false);
    const [chek1, setChek1] = useState(false);
	const [chek2, setChek2] = useState(false);
	const [chek3, setChek3] = useState(false);
	const [buttonActive, setButtonActive] = useState(true);
    console.log(chek1, chek2, chek3)
	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

	useEffect(() => {
        if(chek1 && chek2 && chek3) {
			setButtonActive(false)
		} else {
			setButtonActive(true)
		}
	},[chek1, chek2, chek3])

	useEffect(() => {
		localStorage.setItem('stepLk', JSON.stringify(step));
		localStorage.setItem('data', JSON.stringify(data));
	}, []);

	const schema = yup
		.object({
			['Есть решение управляющей (головной) компании, которое вы считаете глупым?']:
				yup.string().required(),

			['Ваши действия в случае, если вы получили жалобу от клиента на качество конечного продукта вашей компании']:
				yup.string().required(),
		})
		.required();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			['Есть решение управляющей (головной) компании, которое вы считаете глупым?']:
				data[
				'Есть решение управляющей (головной) компании, которое вы считаете глупым?'
				],

			['Ваши действия в случае, если вы получили жалобу от клиента на качество конечного продукта вашей компании']:
				data[
				'Ваши действия в случае, если вы получили жалобу от клиента на качество конечного продукта вашей компании'
				],
		},
	});

	const onSubmit = dataEnd => {
		setLoading(true);
		dispatch(setAddData({ ...data, ...dataEnd }));
		const body = new FormData();

		const form = [{ ...data, ...dataEnd }];

		body.append('form', JSON.stringify(form));

		axios({
			method: 'post',
			url: `https://api.skilla.ru/skilla/lkSendForm?code=${user.user.code}`,
			headers: { Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9` },
			data: body,
		}).then(({ data }) => {
			if (data.result === 'success') {
				getUser(user?.user?.code).then(({ data }) => {
					dispatch(setAddUser(data));
					localStorage.setItem('user', JSON.stringify(data));
				});
				setLoading(false);
				dispatch(setAddStep(9));
				dispatch(setAddData({}));
				localStorage.removeItem('data');
				localStorage.setItem('stepLk', JSON.stringify(9));
			}
		});
	};

	function handleCheck1() {
		if(chek1) {
			setChek1(false);
		} else {
			setChek1(true);
		}
	};

	function handleCheck2() {
		if(chek2) {
			setChek2(false);
		} else {
			setChek2(true);
		}
	}

	function handleCheck3() {
		if(chek3) {
			setChek3(false);
		} else {
			setChek3(true);
		}
	}


	return (
		<div className={s.step8}>
			{loading && <Spinner />}
			<Form onSubmit={handleSubmit(onSubmit)}>
				<div className={s.form_item}>
					<h4 className={s.form_title}>
						Есть решение управляющей (головной) компании, которое вы считаете
						глупым, бессмысленным или неправильным. Будете ли вы его выполнять?
						Почему?
					</h4>

					<textarea
						{...register(
							'Есть решение управляющей (головной) компании, которое вы считаете глупым?'
						)}
						style={{ marginTop: 0 }}
						className={cn(s.textarea, {
							[s.error]:
								errors[
								'Есть решение управляющей (головной) компании, которое вы считаете глупым?'
								],
						})}
						placeholder='Развернутый ответ увеличит вероятность одобрения кандидата на партнерство'
						name='Есть решение управляющей (головной) компании, которое вы считаете глупым?'
					></textarea>
				</div>

				<div className={s.form_item}>
					<h4 className={s.form_title}>
						Ваши действия в случае, если вы получили жалобу от клиента на
						качество конечного продукта вашей компании
					</h4>

					<textarea
						{...register(
							'Ваши действия в случае, если вы получили жалобу от клиента на качество конечного продукта вашей компании'
						)}
						style={{ marginTop: 0 }}
						className={cn(s.textarea, {
							[s.error]:
								errors[
								'Ваши действия в случае, если вы получили жалобу от клиента на качество конечного продукта вашей компании'
								],
						})}
						placeholder='Развернутый ответ увеличит вероятность одобрения кандидата на партнерство'
						name='Ваши действия в случае, если вы получили жалобу от клиента на качество конечного продукта вашей компании'
					></textarea>
				</div>

				<div className={s.form_item}>
					<h4 className={s.form_title}>Что-нибудь хотите еще добавить?</h4>

					<textarea
						{...register('Что-нибудь хотите еще добавить?')}
						style={{ marginTop: 0 }}
						className={s.textarea}
						placeholder='Необязательно'
						name='Что-нибудь хотите еще добавить?'
					></textarea>
				</div>

				<div className={a.checkbox}>
					<input
						className={a.input}
						{...register('checkbox1', {
							required: true,
						})}
						id='checkbox1'
						type='checkbox'
						name='checkbox1'
						autoComplete='off'
					/>
					<label style={{maxWidth: '710px'}} onClick={handleCheck1}
						htmlFor='checkbox1'
						className={cn(a.label, {
							[a.error]: errors.checkbox2,
						})}
					>
						Отправляя анкету, я разрешаю хранение, обработку персональных данных
					</label>
				</div>

				<div className={a.checkbox}>
					<input
						className={a.input}
						{...register('checkbox2', {
							required: true,
						})}
						id='checkbox2'
						type='checkbox'
						name='checkbox2'
						autoComplete='off'
					/>
					<label onClick={handleCheck2}
						htmlFor='checkbox2'
						className={cn(a.label, {
							[a.error]: errors.checkbox2,
						})}
					>
						Отправляя анкету, я подтверждаю достоверность данных
					</label>
				</div>

				<div className={a.checkbox}>
					<input
						className={a.input}
						{...register('checkbox3', {
							required: true,
						})}
						id='checkbox3'
						type='checkbox'
						name='checkbox3'
						autoComplete='off'
					/>
					<label style={{maxWidth: '760px'}}  onClick={handleCheck3}
						htmlFor='checkbox3'
						className={cn(a.label, {
							[a.error]: errors.checkbox2,
						})}
					>
						Отправляя анкету, я соглашаюсь с <a target='_blank' href='https://skilla.ru/politics' className={s.linkcheck}>условиями и Политикой конфиденциальности</a> 
					</label>
				</div>


				<div style={{ marginTop: '38px' }} className={s.btns}>
					<Button callback={() => dispatch(setAddStep(7))} back>
						{width > 575 ? 'Назад' : <img src='/static/lk/IconBackward.svg' alt='назад' />}
					</Button>
					<Button active={buttonActive}>{width > 575 ? 'Отправить анкету на проверку' : 'Отправить анкету'}</Button>
				</div>
			</Form>

			<img
				className={s.illustration}
				src='/static/lk/illustration/illustration.png'
				alt='Иллюстрация'
			/>
		</div>
	);
};

export default Step8;
