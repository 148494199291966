import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';
import styled from 'styled-components';
import { selectMain } from '../../../../store/reducer/main/selector.js';
import s from './cover.module.scss';

const Cover = () => {
	const [width, setWidth] = useState(window?.innerWidth);
	const { pageBook } = useSelector(selectMain);

	const settings = {
		className: 'book-slide',
		dotsClass:
			pageBook === 'business'
				? 'slick-dots'
				: (
						pageBook
							? pageBook === 'customer'
							: window.location.pathname.substring(1) === 'book'
				  )
				? 'slick-dots_customer'
				: pageBook === 'worker'
				? 'slick-dots_worker'
				: 'slick-dots',
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: true,
		arrows: false,
	};

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

	return (
		<div className={s.cover}>
			{width > 575 && (
				<img className={s.img} src='/static/book/cover.png' alt='изображение' />
			)}

			{width > 575 && (
				<div className={s.pages}>
					<img src='/static/book/page1.png' alt='изображение' />
					<img src='/static/book/page2.png' alt='изображение' />
					<img src='/static/book/page3.png' alt='изображение' />
				</div>
			)}

			{width <= 575 && (
				<StyledSlider>
					<Slider {...settings}>
						<img src='/static/lk/book/cover-mobile.jpg' alt='изображение' />
						<img src='/static/book/page1.png' alt='изображение' />
						<img src='/static/book/page2.png' alt='изображение' />
						<img src='/static/book/page3.png' alt='изображение' />
					</Slider>
				</StyledSlider>
			)}

			{/* {width > 575 && (
				<div className={s.logos}>
					<img src='/static/lk/book/logo.png' alt='логотип' />
					<img src='/static/lk/book/logo2.png' alt='логотип' />
					<img src='/static/lk/book/logo3.png' alt='логотип' />
				</div>
			)} */}
		</div>
	);
};

const StyledSlider = styled.div`
	.slick-dots {
		display: flex !important;
		justify-content: space-between;
		position: relative;
		grid-gap: 8px;
		margin-top: -16px;

		& li {
			background-color: #8e9aa8;
			display: block;
			width: 100%;
			height: 4px;
			border-radius: 0;
			margin: 0;

			&.slick-active {
				background: #00cc6e;
			}

			& button {
				padding: 0;
				width: 100%;
				height: 100%;
				opacity: 0;
			}
		}
	}

	.slick-dots_customer {
		display: flex !important;
		justify-content: space-between;
		position: relative;
		grid-gap: 8px;

		& li {
			background-color: #8e9aa8;
			display: block;
			width: 100%;
			height: 4px;
			border-radius: 0;
			margin: 0;

			&.slick-active {
				background: #002cfb;
			}

			& button {
				padding: 0;
				width: 100%;
				height: 100%;
				opacity: 0;
			}
		}
	}

	.slick-dots_worker {
		display: flex !important;
		justify-content: space-between;
		position: relative;
		grid-gap: 8px;

		& li {
			background-color: #8e9aa8;
			display: block;
			width: 100%;
			height: 4px;
			border-radius: 0;
			margin: 0;

			&.slick-active {
				background: #8800ff;
			}

			& button {
				padding: 0;
				width: 100%;
				height: 100%;
				opacity: 0;
			}
		}
	}

	li {
		padding: 0;
	}

	ul {
		padding: 0;
	}
`;

export default Cover;
