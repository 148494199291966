import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectLKPayment } from '../../../../../store/reducer/lk/payment/selector.js';
import { useDispatch } from 'react-redux';
import s from '../../payment.module.scss';
import Button from '../../ui/Button/Buttons';
import { setAddActiveLink } from '../../../../../store/reducer/lk/sidebar/slice.js'

const Done = ({clientWithoutCours}) => {
	const [width, setWidth] = useState(window?.innerWidth);
	const { paymentInfo } = useSelector(selectLKPayment);
	const dispatch = useDispatch()

	const status = paymentInfo?.payment?.status;


	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);
		window.scroll(0, 0);

		return () => window.removeEventListener('resize', cb);
	}, []);

	const options = {
		day: 'numeric',
		month: 'numeric',
		year: 'numeric',
	};

	const returnDate = paymentInfo?.payment?.refund_date;

	const date = new Date(returnDate);
	const dateFormat = date.toLocaleDateString('ru-RU', options);

	const goTrainingPage = () => {
		dispatch(setAddActiveLink(10))
		localStorage.setItem('activeLink', JSON.stringify(10));
	}

	return (
		<div className={s.done}>
			{status === 'prepaid' && (
				<div className={s.payment}>
					<div className={s.wrapper_title}>
						<img src='/static/lk/iconDoneBig.svg' alt='Иконка' />
						<h4 className={s.subtitle_done}>{clientWithoutCours ? 8 : 9}. Предоплата</h4>
					</div>

					<h1 className={`${s.title} lk-title`}>Предоплата внесена</h1>

					<p className={s.descr}>Запишись на обучение в Санкт-Петербурге</p>

					{
						<Button onCkick={goTrainingPage} className='btn_record'>Запись на обучение</Button>
					}

					{/* <div className={s.details}>
						<h5>Полная оплата возможна только по реквизитам:</h5>
						<ul>
							<li>ООО "СКИЛЛА ИННОВАЦИИ"</li>
							<li> ИНН 4706050219</li>
							<li>КПП 470601001</li>
							<li>Банк АО "ТИНЬКОФФ БАНК"</li>
							<li>БИК банка 044525974</li>
							<li>Расчетный счет 40702810810001131177</li>
							<li>Корреспондентский счет банка 30101810145250000974</li>
						</ul>
					</div> */}

					{width > 575 && (
						<div className={s.bottom}>
							<div>
								<img src='/static/lk/iconAlertBlack.svg' alt='иконка' />
								<span>Никакого риска!</span>
							</div>

							<p>
								Skilla вернет 100% предоплаты в любой момент по твоему запросу
								(Статья 6 Гарантия возврата средств, пункт 6.1. Договора)
							</p>
						</div>
					)}

					{width > 575 && (
						<img
							className={s.illustation}
							src='/static/lk/founder2.png'
							alt='Основатель'
						/>
					)}
				</div>
			)}

			{status === 'paid' && (
				<div className={s.payment}>
					<div className={s.wrapper_title}>
						<img src='/static/lk/iconDoneBig.svg' alt='Иконка' />
						<h4 className={s.subtitle_done}>{clientWithoutCours ? 8 : 9}. Предоплата</h4>
					</div>

					<h1 className={`${s.title} lk-title`}>Договор оплачен</h1>

					<p className={s.descr}>Запишись на обучение в Санкт-Петербурге</p>
					<Button onCkick={goTrainingPage} className='btn_lookData'>Выбрать даты обучения</Button>
					{width > 575 && (
						<img
							className={s.illustation}
							src='/static/lk/founder2.png'
							alt='Основатель'
						/>
					)}
				</div>
			)}

			{status === 'returned' && (
				<div className={s.payment}>
					<div className={s.wrapper_title}>
						<img src='/static/lk/iconWarning.svg' alt='Иконка' />
						<h4 className={s.subtitle_return}>9. Предоплата</h4>
					</div>

					<h1 className={`${s.title} lk-title`}>Сделан возврат</h1>

					<p className={s.descr_return}>
						Был совершен возврат средств по договору. Срок зачисления на счет до
						3 банковских дней.
					</p>

					<div className={s.info_return}>
						<h5>Отправлен возврат</h5>

						<div>
							{paymentInfo?.payment?.refund_sum.toLocaleString('ru-RU')} ₽{' '}
							{(dateFormat !== 'Invalid Date' || !dateFormat) && 'от'}{' '}
							{dateFormat !== 'Invalid Date' || !dateFormat ? dateFormat : ''}
						</div>
					</div>

					{width > 575 && (
						<img
							className={s.illustation}
							src='/static/lk/founder2.png'
							alt='Основатель'
						/>
					)}
				</div>
			)}
		</div>
	);
};

export default Done;
