import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import cn from 'classnames';
import Cover from '../cover/Cover';
import FormSmall from '../formSmall/FormSmall';
import s from './buy.module.scss';
import { selectMain } from '../../../../store/reducer/main/selector';

const Buy = () => {
	const [width, setWidth] = useState(window?.innerWidth);
	const {pageBook} = useSelector(selectMain)

	const dispatch = useDispatch();

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

	return (
		<div className={s.buy}>
			<div className={s.info}>
				<h2 className={cn(s.title, {
					[s.title_customer]: pageBook ? pageBook === 'customer' : window.location.pathname.substring(1) === 'book',
					[s.title_business]: pageBook === 'business',
					[s.title_worker]: pageBook === 'worker'
				})}>ГЕНОМ ПРЕДПРИНИМАТЕЛЯ</h2>

				<h5 className={s.subtitle}>
					Как парень из глубинки создал с нуля миллиардный бизнес в России
				</h5>

				<p className={cn(s.name, {
					[s.name_customer]: pageBook ? pageBook === 'customer' : window.location.pathname.substring(1) === 'book',
					[s.name_business]: pageBook === 'business',
					[s.name_worker]: pageBook === 'worker'
				})}>Кирилл Упоров</p>
			</div>

			{width <= 575 && <Cover />}

			<div className={s.descr}>
				<p>
					Искренняя и вдохновляющая история успеха, рассказанная российским
					предпринимателем Кириллом Упоровым. Он создал технологичную компанию с
					нуля и заложил основы фундамента успеха для сотен предпринимателей,
					которые выстраиваются в очередь, чтобы стать частью его
					бизнес-империи.
				</p>

				{width > 575 && (
					<>
						<p>
							Эта книга — не просто история успеха. Кирилл Упоров честно и
							открыто рассказывает о создании бизнеса в современных реалиях,
							делясь успехами и не скрывая провалы. Он объясняет простым языком,
							что бизнес — это свобода и новое качество жизни, а заниматься им
							может каждый, если поймет как использовать свой геном
							предпринимателя.
						</p>

						<p>
							Может быть, именно эта история поможет тебе сделать первый шаг на
							пути к предпринимательству и переменам в жизни?
						</p>
					</>
				)}
			</div>

			<FormSmall />

			{/* {width <= 575 && (
				<div className={s.logos}>
					<img src='/static/lk/book/logo-mobile.png' alt='логотип' />
					<img src='/static/lk/book/logo2-mobile.png' alt='логотип' />
					<img src='/static/lk/book/logo3-mobile.png' alt='логотип' />
				</div>
			)} */}
		</div>
	);
};

export default Buy;
