import cn from 'classnames';
import s from '../steps.module.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import {
	setAddData,
	setAddStep,
} from '../../../../../store/reducer/lk/formExam/slice';

import { selectLkFormExam } from '../../../../../store/reducer/lk/formExam/selector';
import Button from '../../Button/Button';
import Form from '../../Form';

const Step33 = () => {
	const [width, setWidth] = useState(window?.innerWidth);
	const dispatch = useDispatch();
	const { data, step } = useSelector(selectLkFormExam);
	const [isError94, setError94] = useState(false);
	const [isSendForm, setIsSendForm] = useState(false);

	useEffect(() => {
		localStorage.setItem('stepLkExam', JSON.stringify(step));
		localStorage.setItem('dataExam', JSON.stringify(data));
	}, []);

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);
    
	const schema = yup
		.object({
			['С какой компанией нужно заключить договор на подключение IP телефонии?']: yup.string(),
            ['Какие виртуальные номера необходимо приобрести?']: yup.string(),
		})
		.required();

	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
		watch,
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			['С какой компанией нужно заключить договор на подключение IP телефонии?']: data['С какой компанией нужно заключить договор на подключение IP телефонии?'],
			['Для чего нужно подключать телефонию и проводить интеграции?']: data['Для чего нужно подключать телефонию и проводить интеграции?'],
			['Какие виртуальные номера необходимо приобрести?']: data['Какие виртуальные номера необходимо приобрести?'],
		},
	});

	
	const ckbox94 = watch('Для чего нужно подключать телефонию и проводить интеграции?');
    
	
	useEffect(() => {
		if (!ckbox94?.length) {
			setError94(true);
		} else {
			setError94(false);
		}
	}, [ckbox94]);

	const onSubmit = data => {
		setIsSendForm(true);

		if (!isError94) {
			dispatch(setAddStep(34));
			dispatch(setAddData(data));
			setIsSendForm(false);

			localStorage.setItem('dataExam', JSON.stringify(data));
			localStorage.setItem('stepLkExam', JSON.stringify(33));
		}
	};

	return (
		<div className={s.step33}>
      <div className={s.wrapper_heading}>
			  <h5>Телефония</h5>
			</div>
			<Form onSubmit={handleSubmit(onSubmit)}>

                <div className={s.form_item}>
					<h4 className={s.form_title}>93. С какой компанией нужно заключить договор на подключение IP телефонии?</h4>

					<div className={s.question93}>
                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question93,
								})}
								{...register('С какой компанией нужно заключить договор на подключение IP телефонии?')}
								id='exam931'
								type='radio'
								name='С какой компанией нужно заключить договор на подключение IP телефонии?'
								autoComplete='off'
								value='Ростелеком'
							/>
							<label htmlFor='exam931' className={s.label}>
							Ростелеком
							</label>
						</div>

						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question93,
								})}
								{...register('С какой компанией нужно заключить договор на подключение IP телефонии?')}
								id='exam932'
								type='radio'
								name='С какой компанией нужно заключить договор на подключение IP телефонии?'
								autoComplete='off'
								value='Манго телеком'
							/>
							<label htmlFor='exam932' className={s.label}>
							Манго телеком
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question93,
								})}
								{...register('С какой компанией нужно заключить договор на подключение IP телефонии?')}
								id='exam933'
								type='radio'
								name='С какой компанией нужно заключить договор на подключение IP телефонии?'
								autoComplete='off'
								value='Билайн'
							/>
							<label htmlFor='exam933' className={s.label}>
							Билайн
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question93,
								})}
								{...register('С какой компанией нужно заключить договор на подключение IP телефонии?')}
								id='exam934'
								type='radio'
								name='С какой компанией нужно заключить договор на подключение IP телефонии?'
								autoComplete='off'
								value='МТС'
							/>
							<label htmlFor='exam934' className={s.label}>
							МТС
							</label>
						</div>
					</div>

					<p className={s.errorText}>
						{errors['С какой компанией нужно заключить договор на подключение IP телефонии?'] ? 'Необходимо указать' : ''}
					</p>
				</div>

                <div className={s.form_item}>
					<h4 className={s.form_title}>
					94. Для чего нужно подключать телефонию и проводить интеграции?
					</h4>

					<div className={s.question94}>
						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question94,
								})}
								{...register('Для чего нужно подключать телефонию и проводить интеграции?')}
								id='exam941'
								type='checkbox'
								name='Для чего нужно подключать телефонию и проводить интеграции?'
								autoComplete='off'
								value='Для подсчета статистики звонков с рекламы'/>
							<label htmlFor='exam941' className={s.label}>
							Для подсчета статистики звонков с рекламы
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question94,
								})}
								{...register('Для чего нужно подключать телефонию и проводить интеграции?')}
								id='exam942'
								type='checkbox'
								name='Для чего нужно подключать телефонию и проводить интеграции?'
								autoComplete='off'
								value='Телефония не нужна, все звонки будут идти на личный номер'/>
							<label htmlFor='exam942' className={s.label}>
							Телефония не нужна, все звонки будут идти на личный номер
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question94,
								})}
								{...register('Для чего нужно подключать телефонию и проводить интеграции?')}
								id='exam943'
								type='checkbox'
								name='Для чего нужно подключать телефонию и проводить интеграции?'
								autoComplete='off'
								value='Для просмотра статистики вызовов, в том числе пропущенных звонков'/>
							<label htmlFor='exam943' className={s.label}>
							Для просмотра статистики вызовов, в том числе пропущенных звонков
							</label>
						</div>

                        <div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question94,
								})}
								{...register('Для чего нужно подключать телефонию и проводить интеграции?')}
								id='exam944'
								type='checkbox'
								name='Для чего нужно подключать телефонию и проводить интеграции?'
								autoComplete='off'
								value='Для  определения номера входящего звонка (Наименование компании и имени представителя)'/>
							<label htmlFor='exam944' className={s.label}>
							Для  определения номера входящего звонка (Наименование компании и имени представителя)
							</label>
						</div>

                        <div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question94,
								})}
								{...register('Для чего нужно подключать телефонию и проводить интеграции?')}
								id='exam945'
								type='checkbox'
								name='Для чего нужно подключать телефонию и проводить интеграции?'
								autoComplete='off'
								value='Для мгновенного просмотра повторных заказов контрагента'/>
							<label htmlFor='exam945' className={s.label}>
							Для мгновенного просмотра повторных заказов контрагента
							</label>
						</div>

						<p className={s.errorText2}>
							{isError94 &&
								isSendForm &&
								'Необходимо указать'}
						</p>
					</div>
				</div>

                <div className={s.form_item}>
					<h4 className={s.form_title}>95. Какие виртуальные номера необходимо приобрести?</h4>

					<div className={s.question95}>
                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question95,
								})}
								{...register('Какие виртуальные номера необходимо приобрести?')}
								id='exam951'
								type='radio'
								name='Какие виртуальные номера необходимо приобрести?'
								autoComplete='off'
								value='Виртуальный городской для Яндекс'
							/>
							<label htmlFor='exam951' className={s.label}>
							Виртуальный городской для Яндекс
							</label>
						</div>

						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question95,
								})}
								{...register('Какие виртуальные номера необходимо приобрести?')}
								id='exam952'
								type='radio'
								name='Какие виртуальные номера необходимо приобрести?'
								autoComplete='off'
								value='Виртуальный городской для приложения Skilla Работа'
							/>
							<label htmlFor='exam952' className={s.label}>
							Виртуальный городской для приложения Skilla Работа
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question95,
								})}
								{...register('Какие виртуальные номера необходимо приобрести?')}
								id='exam953'
								type='radio'
								name='Какие виртуальные номера необходимо приобрести?'
								autoComplete='off'
								value='Виртуальный мобильный для Авито вакансии (поиск исполнителей)'
							/>
							<label htmlFor='exam953' className={s.label}>
							Виртуальный мобильный для Авито вакансии (поиск исполнителей)
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question95,
								})}
								{...register('Какие виртуальные номера необходимо приобрести?')}
								id='exam954'
								type='radio'
								name='Какие виртуальные номера необходимо приобрести?'
								autoComplete='off'
								value='Виртуальный мобильный для Авито услуг (поиск клиентов)'
							/>
							<label htmlFor='exam954' className={s.label}>
							Виртуальный мобильный для Авито услуг (поиск клиентов)
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question95,
								})}
								{...register('Какие виртуальные номера необходимо приобрести?')}
								id='exam955'
								type='radio'
								name='Какие виртуальные номера необходимо приобрести?'
								autoComplete='off'
								value='Все варианты верные'
							/>
							<label htmlFor='exam955' className={s.label}>
							Все варианты верные
							</label>
						</div>
					</div>

					<p className={s.errorText}>
						{errors['Какие виртуальные номера необходимо приобрести?'] ? 'Необходимо указать' : ''}
					</p>
				</div>

                
				<div className={s.btns}>
        <Button callback={() => dispatch(setAddStep(32))} back>
						{width > 575 ? (
							'   Назад'
						) : (
							<img src='/static/lk/IconBackward.svg' alt='назад' />
						)}
					</Button>
					<Button>Далее 33 из 35</Button>
				</div>
			</Form>

			<img
				className={s.illustration}
				src='/static/lk/illustration/illustration.png'
				alt='Иллюстрация'
			/>
		</div>
	);
};

export default Step33;
