import cn from 'classnames'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import Advantage from '../../components/advantage/Advantage'
import {
  fifthSection,
  firstSection,
  fourthSection,
  secondSection,
  thirdSection,
} from '../../components/advantage/advantage.data'
import Footer from '../../components/Footer'
import Form from '../../components/Form'
import { selectLKAuth } from '../../store/reducer/lk/authChech/selector.js'
import { selectLKContract } from '../../store/reducer/lk/contract/selector'
import { selectLkForm } from '../../store/reducer/lk/form/selector'
import s from './vip.module.scss'

const Vip = () => {
  const [width, setWidth] = useState(window?.innerWidth)
  const { isCheckFromPage } = useSelector(selectLKContract)
  const { status } = useSelector(selectLkForm)
  const { user } = useSelector(selectLKAuth)

  useEffect(() => {
    const cb = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', cb)
    window.scroll(0, 0)

    return () => window.removeEventListener('resize', cb)
  }, [])

  const options = {
    day: 'numeric',
    month: 'long',
  }
  const date = status?.form?.date

  const handleCheckStart = new Date(date)

  handleCheckStart.setDate(handleCheckStart.getDate() + 7)

  const endPremium = handleCheckStart.toLocaleDateString('ru-RU', options)
  const yearNow = new Date().getUTCFullYear()

  const dateToday = new Date().getDate()

  const removeDate = handleCheckStart.getDate() - dateToday

  return (
    <div className={s.vip}>
      <Helmet>
        <title>Привелегии для избранных</title>
      </Helmet>
      <div className={s.intro}>
        <a className={s.logo} href="/business" target="_blank">
          <img
            src={width > 575 ? '/static/vip/logo-header.png' : '/static/vip/logo-mobile.svg'}
            alt="логотип"
          />
        </a>

        <div
          className={cn(s.info, {
            [s.info_margin]: !isCheckFromPage || !user?.user?.code || (isCheckFromPage && user?.user?.code),
          })}
        >
          <h4>{width > 575 ? 'Привелегии для избранных' : 'Преимущества для избранных'}</h4>
          <h1>Прокачай статус и получи х10 к возможностям!</h1>
        </div>

        {!isCheckFromPage && !user?.user?.code && (
          <StyledFormModal>
            <Form page="business" type="vip" />
          </StyledFormModal>
        )}

        <div className={s.price}>
          {width > 575 && user?.user?.code && (
            <>
              <h3>27 500 ₽/мес</h3>
              <h2>Бесплатно Навсегда</h2>
            </>
          )}

          {removeDate >= 0 && width > 575 && (
            <p>
              Предложение действительно при заключении договора и оплате только до {endPremium} {yearNow}
            </p>
          )}

          {removeDate >= 0 && width <= 575 && (
            <p>
              Предложение действительно до {endPremium} {yearNow}
            </p>
          )}
        </div>

        <img
          className={s.founder}
          src={width > 575 ? '/static/vip/founder.png' : '/static/vip/founder-mobile.png'}
          alt="основатель"
        />
      </div>

      <section className={s.firstSection}>
        <Advantage
          subtitle="Преимущество №1"
          title="Экономия на услугах"
          item={firstSection}
          section="firstSection"
        />
      </section>

      <section className={s.secondSection}>
        <Advantage
          subtitle="Преимущество №2"
          title="Помощь и постоянное развитие"
          item={secondSection}
          section="secondSection"
        />
      </section>

      <section className={s.thirdSection}>
        <Advantage
          subtitle="Преимущество №3"
          title="Развлечения и отдых с лучшими предпринимателями"
          item={thirdSection}
          section="thirdSection"
        />
      </section>

      <section className={s.fourthSection}>
        <Advantage
          subtitle="Преимущество №4"
          title="Персональный сервис"
          item={fourthSection}
          section="fourthSection"
        />
      </section>

      <section className={s.fifthSection}>
        <Advantage
          subtitle="Преимущество №5"
          title="Наставничество от предпринимателей с выручкой от 100 млн"
          item={fifthSection}
          section="fifthSection"
        />
      </section>
      <StyledFormModal>
        {' '}
        <Footer page="business" type="vip" />
      </StyledFormModal>
    </div>
  )
}

const StyledFormModal = styled.div`
  form {
    margin-bottom: 20px;

    &.modalAnswer {
      position: relative;
      z-index: 10;
      margin-top: 30px;

      @media (max-width: 575px) {
        padding: 0;
      }
    }
  }
  .modal--- {
    position: fixed;
    z-index: 20;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 7, 41, 0.4);
  }

  .modal__content {
    width: 688px;
    background-color: #303550;
    color: #fff;
    position: relative;
    text-align: center;

    @media (max-width: 768px) {
      width: 375px;
    }
  }

  .modal__cross {
    position: absolute;
    right: 24px;
    top: 24px;
  }

  .modal__button {
    cursor: pointer;
    padding: 0 50px;
    background-color: #00ff88;
    color: #000729;
    border: 0;
    border-radius: 4px;
    font-size: 18px;
    line-height: 48px;
    font-weight: 500;
    font-family: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: auto;
    transition: background-color 0.3s ease;

    &::after {
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      margin-left: 20px;
      background: url("data:image/svg+xml;charset=UTF-8,%3csvg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.5 4L11.09 5.41L16.67 11H4.5V13H16.67L11.09 18.59L12.5 20L20.5 12L12.5 4Z' fill='%23000729'/%3e%3c/svg%3e ")
        no-repeat center top / cover;
    }

    &:hover {
      background-color: #00cc6e;
    }
  }

  .modal__title {
    font-size: 36px;
    line-height: 120%;
    font-weight: 800;
    max-width: 450px;
    margin: auto;
    margin-top: 64px;
    margin-bottom: 16px;
  }

  .modal__text {
    font-size: 18px;
    line-height: 120%;
    color: #e3e4e8;
    max-width: 450px;
    margin: auto;
  }

  .modal__input {
    width: 300px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    padding: 10.5px 13px;
    font-family: inherit;
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;
    color: #fff;

    &::placeholder {
      font-family: inherit;
      color: #abadba;
    }
  }

  .modal__input_step1 {
    margin-top: 56px;
    margin-bottom: 15px;
  }

  .modal__input_step2 {
    margin: 55px 0 135px 0;
  }

  .modal__input_menu {
    display: none;
    height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    position: absolute;
    bottom: -200px;
    cursor: pointer;
    z-index: 10;

    &.active {
      display: block;
    }
  }

  .modal__input_city_step1 {
    margin-bottom: 70px !important;
  }

  .modal__input_city {
    margin: auto;
    position: relative;
    width: 300px;

    &:focus-within .modal__input_menu {
      display: block;
    }

    &::after {
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      position: absolute;
      right: 12px;
      top: 0;
      bottom: 0;
      margin: auto;
      background: url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.5 9L12 15L17.5 9H6.5Z' fill='%23ABADBA'/%3e%3c/svg%3e ")
        no-repeat center top / cover;
      cursor: pointer;
    }
  }

  .modal__input_city-item {
    padding: 10.5px 13px;
    background-color: #262a40;
    font-size: 18px;
    color: rgba(171, 173, 186, 1);
    font-weight: 500;
    text-align: left;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #44485c;
    }
  }

  .modal__question {
    max-width: 450px;
    margin: auto;
    margin-top: 45px;

    & .header-form__checkbox::before {
      background-color: #303550;
    }

    & .header-form__checkbox:checked::before {
      background-color: #303550;
    }
  }

  .modal__input_step3 {
    width: 100%;
    resize: none;
    height: 105px;
    margin-bottom: 55px;
    outline: none;

    @media (max-width: 768px) {
      max-width: 343px;
      width: 100%;
    }
  }

  .modal__button_step3::after {
    display: none;
  }

  .modal__question-text {
    font-size: 22px;
    line-height: 120%;
    margin-bottom: 17px;
    text-align: left;
  }

  .modal__progress {
    margin-top: 52px;
    display: flex;
    width: 100%;
    height: 6px;
    background-color: #abadba;

    & span {
      display: block;
      width: 25%;
      height: 6px;
      background-color: #00cc6e;
    }
  }

  .modal__back {
    position: absolute;
    left: 24px;
    top: 24px;
  }

  .modal-video {
    width: 450px;
    margin: 40px auto 55px auto;
  }

  .modal-video__content {
    text-align: left;
  }

  .modal-video__title {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 4px;
    font-weight: 700;
    margin-top: 20px;
  }

  .modal-video__description {
    font-size: 16px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.7);
  }

  .tabs__content_flex {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 25px;
  }

  .modal-video__video {
    height: 255px;
  }

  .modal-img {
    width: 700px;
    height: 700px;
    object-fit: cover;
  }

  @media screen and (max-width: 575px) {
    form {
      margin-bottom: 31px;
    }
    .modal__title {
      font-size: 24px;
      margin-bottom: 12px;
    }

    .modal__text {
      font-size: 16px;
      padding: 0 10px;
    }

    .modal__question {
      margin-top: 30px;
    }

    .modal__question-text {
      font-size: 16px;
      font-weight: 500;
      padding-left: 16px;
    }

    .header-form__labels {
      display: grid;
      grid-gap: 25px;
      padding-left: 0;
      grid-template-columns: repeat(3, 1fr);
      margin-top: 24px;
      margin-bottom: 24px;
    }

    .modal--- .header-form__labels {
      padding-left: 16px;
    }

    .modal__content,
    .modal-video,
    .modal-video__content {
      max-width: 343px;
      width: 100%;
    }

    .modal-video__content {
      text-align: center;
    }

    .modal__input_step2 {
      margin: 55px 0 70px 0;
    }
  }
`

export default Vip
