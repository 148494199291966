import cn from 'classnames';
import s from './step1.module.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { getUser } from '../../../../../Api';
import { setAddUser } from '../../../../../store/reducer/lk/authChech/slice';
import {
	setAddData,
	setAddStep,
} from '../../../../../store/reducer/lk/form/slice';

import ReactInputMask from 'react-input-mask';
import { selectLKAuth } from '../../../../../store/reducer/lk/authChech/selector';
import { selectLkForm } from '../../../../../store/reducer/lk/form/selector';
import Button from '../../Button/Button';
import Form from '../../Form';

const Step1 = ({ scrollToTop }) => {
	const [width, setWidth] = useState(window?.innerWidth);
	const [showMobileState, setShowMobileStep] = useState(
		JSON.parse(localStorage.getItem('showMobileState')) || false
	);
	const dispatch = useDispatch();
	const { data, step } = useSelector(selectLkForm);
	const { user } = useSelector(selectLKAuth);

	useEffect(() => {
		window.scroll(0, 0);
	}, [showMobileState]);

	useEffect(() => {
		localStorage.setItem('stepLk', JSON.stringify(step));
	}, []);

	useEffect(() => {
		const getUserInfo = async () => {
			try {
				const { data } = await getUser(user?.user?.code);

				dispatch(setAddUser(data));
				localStorage.setItem('user', JSON.stringify(data));
			} catch (e) {
				console.log(e);
			}
		};

		if (user?.user?.code) {
			getUserInfo();
		}
	}, []);

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

	const showStep1MobileInfo = bol => {
		setShowMobileStep(bol);
		localStorage.setItem('showMobileState', JSON.stringify(bol));
	};

	const schema = yup
		.object({
			['Фамилия']: yup.string().min(1),

			['Имя']: yup.string().min(1),

			['Отчество']: yup.string().min(1),

			['Серия']: yup.string().min(4).required(),

			['Номер']: yup.string().min(6).required(),

			['Дата выдачи']: yup.string().length(10),

			['Кем выдан']: yup.string().min(1),

			['Код подразд']: yup.string().length(7),

			['Место рождения']: yup.string().min(1),

			['Дата рождения']: yup.string().length(10),

			['Адрес регистрации']: yup.string().min(1),
		})
		.required();

	const {
		register,
		handleSubmit,

		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			['Фамилия']: data['Фамилия'],
			['Имя']: data['Имя'],
			['Отчество']: data['Отчество'],
			['Серия']: data['Серия'],
			['Номер']: data['Номер'],
			['Дата выдачи']: data['Дата выдачи'],
			['Кем выдан']: data['Кем выдан'],
			['Код подразд']: data['Код подразд'],
			['Место рождения']: data['Место рождения'],
			['Дата рождения']: data['Дата рождения'],
			['Адрес регистрации']: data['Адрес регистрации'],
		},
	});

	const onSubmit = data => {
		dispatch(setAddData(data));
		dispatch(setAddStep(2));

		localStorage.setItem('data', JSON.stringify(data));
		localStorage.setItem('stepLk', JSON.stringify(2));
	};


	return (
		<div className={s.step1}>
			{width > 575 && (
				<div className={s.left}>
					<div className={s.header}>
						<h3 className={s.subtitle}>6. Анкета партнера</h3>
						<h1 className={`${s.title} lk-title`}>Заполни анкету</h1>

						<p className={s.descr}>
							От достоверности и полноты содержимого ответов будет зависеть
							возможность заработка в партнерской сети Skilla
						</p>
					</div>

					<Form onSubmit={handleSubmit(onSubmit)}>
						<div className={s.form_item}>
							<h4 className={s.form_title}>ФИО</h4>

							<div className={s.fullName}>
								<input
									className={cn(s.input, {
										[s.error]: errors['Фамилия'],
									})}
									{...register('Фамилия', { required: true })}
									type='text'
									name='Фамилия'
									placeholder='Иванов'
									maxLength='30'
									autoComplete='off'
								/>

								<input
									className={cn(s.input, {
										[s.error]: errors['Имя'],
									})}
									{...register('Имя', { required: true })}
									type='text'
									name='Имя'
									placeholder='Иван'
									maxLength='30'
									autoComplete='off'
								/>

								<input
									className={cn(s.input, {
										[s.error]: errors['Отчество'],
										[s.input_patronymic]: 'patronymic',
									})}
									{...register('Отчество', { required: true })}
									type='text'
									name='Отчество'
									placeholder='Иванович'
									maxLength='30'
									autoComplete='off'
								/>
							</div>
						</div>

						<div className={s.form_item}>
							<h4 className={s.form_title}>Паспортные данные</h4>

							<div className={s.passport}>
								<div>
									<input
										className={cn(s.input, {
											[s.error]: errors['Серия'],
										})}
										{...register('Серия', { required: true })}
										name='Серия'
										placeholder='Серия'
										type='number'

										autoComplete='off'
									/>

									<input
										className={cn(s.input, {
											[s.error]: errors['Номер'],
										})}
										{...register('Номер', { required: true })}
										name='Номер'
										type='number'

										placeholder='Номер'
										autoComplete='off'
									/>

									<ReactInputMask
										className={cn(s.input, {
											[s.error]: errors['Дата выдачи'],
											[s.input_date]: 'date',
										})}
										{...register('Дата выдачи', { required: true })}
										name='Дата выдачи'
										placeholder='Дата выдачи'
										mask={'99.99.9999'}
										maskChar={false}
										autoComplete='off'
									/>
								</div>

								<input
									className={cn(s.input, {
										[s.error]: errors['Кем выдан'],
										[s.input_who]: 'who',
									})}
									{...register('Кем выдан', { required: true })}
									type='text'
									name='Кем выдан'
									placeholder='Кем выдан'
									maxLength='90'
									autoComplete='off'
								/>

								<ReactInputMask
									className={cn(s.input, {
										[s.error]: errors['Код подразд'],
										[s.input_code]: 'code',
									})}
									{...register('Код подразд', { required: true })}
									name='Код подразд'
									mask={'999-999'}
									maskChar={false}
									placeholder='Код подразд.'
									autoComplete='off'
								/>
							</div>
						</div>

						<div className={s.form_item}>
							<h4 className={s.form_title}>Дата и место рождения</h4>

							<div className={s.birthPlace}>
								<input
									className={cn(s.input, {
										[s.error]: errors['Место рождения'],
										[s.input_birthPlace]: 'birthPlace',
									})}
									{...register('Место рождения', { required: true })}
									type='text'
									name='Место рождения'
									placeholder='Место рождения (из паспорта)'
									maxLength='90'
									autoComplete='off'
								/>

								<div className={s.input_wrapper}>
									<img src='/static/lk/iconCalendar.svg' alt='Иконка' />

									<ReactInputMask
										className={cn(s.input, {
											[s.error]: errors['Дата рождения'],
											[s.input_calendar]: 'calendar',
										})}
										{...register('Дата рождения', { required: true })}
										name='Дата рождения'
										placeholder='__.__.____'
										mask={'99.99.9999'}
										maskChar={false}
										autoComplete='off'
									/>
								</div>
							</div>
						</div>

						<div className={s.form_item}>
							<h4 className={s.form_title}>Адрес регистрации (прописка)</h4>

							<div className={s.address}>
								<input
									className={cn(s.input, {
										[s.error]: errors['Адрес регистрации'],
										[s.input_address]: 'address',
									})}
									{...register('Адрес регистрации', { required: true })}
									type='text'
									name='Адрес регистрации'
									placeholder='Развернутый ответ увеличит вероятность одобрения кандидата на партнерство'
									maxLength='90'
									autoComplete='off'
								/>
							</div>
						</div>

						<div style={{ textAlign: 'center' }}>
							<Button scrollToTop={scrollToTop}>Далее 1 из 8</Button>
						</div>
					</Form>
				</div>
			)}

			{width <= 575 && showMobileState && (
				<div className={s.left}>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<div className={s.form_item}>
							<h4 className={s.form_title}>ФИО</h4>

							<div className={s.fullName}>
								<input
									className={cn(s.input, {
										[s.error]: errors['Фамилия'],
									})}
									{...register('Фамилия', { required: true })}
									type='text'
									name='Фамилия'
									placeholder='Иванов'
									maxLength='30'
									autoComplete='off'
								/>

								<input
									className={cn(s.input, {
										[s.error]: errors['Имя'],
									})}
									{...register('Имя', { required: true })}
									type='text'
									name='Имя'
									placeholder='Иван'
									maxLength='30'
									autoComplete='off'
								/>

								<input
									className={cn(s.input, {
										[s.error]: errors['Отчество'],
										[s.input_patronymic]: 'patronymic',
									})}
									{...register('Отчество', { required: true })}
									type='text'
									name='Отчество'
									placeholder='Иванович'
									maxLength='30'
									autoComplete='off'
								/>
							</div>
						</div>

						<div className={s.form_item}>
							<h4 className={s.form_title}>Паспортные данные</h4>

							<div className={s.passport}>
								<div>
									<input
										className={cn(s.input, {
											[s.error]: errors['Серия'],
										})}
										{...register('Серия', { required: true })}
										type='number'
										name='Серия'
										placeholder='Серия'
										maxLength='12'
										autoComplete='off'
									/>

									<input
										className={cn(s.input, {
											[s.error]: errors['Номер'],
										})}
										{...register('Номер', { required: true })}
										type='number'
										name='Номер'
										placeholder='Номер'
										maxLength='12'
										autoComplete='off'
									/>

									<ReactInputMask
										className={cn(s.input, {
											[s.error]: errors['Дата выдачи'],
											[s.input_date]: 'date',
										})}
										{...register('Дата выдачи', { required: true })}
										name='Дата выдачи'
										placeholder='Дата выдачи'
										mask={'99.99.9999'}
										maskChar={false}
										autoComplete='off'
									/>
								</div>

								<input
									className={cn(s.input, {
										[s.error]: errors['Кем выдан'],
										[s.input_who]: 'who',
									})}
									{...register('Кем выдан', { required: true })}
									type='text'
									name='Кем выдан'
									placeholder='Кем выдан'
									maxLength='90'
									autoComplete='off'
								/>

								<ReactInputMask
									className={cn(s.input, {
										[s.error]: errors['Код подразд'],
										[s.input_code]: 'code',
									})}
									{...register('Код подразд', { required: true })}
									name='Код подразд'
									mask={'999-999'}
									maskChar={false}
									placeholder='Код подразд.'
									autoComplete='off'
								/>
							</div>
						</div>

						<div className={s.form_item}>
							<h4 className={s.form_title}>Дата и место рождения</h4>

							<div className={s.birthPlace}>
								<input
									className={cn(s.input, {
										[s.error]: errors['Место рождения'],
										[s.input_birthPlace]: 'birthPlace',
									})}
									{...register('Место рождения', { required: true })}
									type='text'
									name='Место рождения'
									placeholder='Место рождения (из паспорта)'
									maxLength='90'
									autoComplete='off'
								/>

								<div className={s.input_wrapper}>
									<img src='/static/lk/iconCalendar.svg' alt='Иконка' />

									<ReactInputMask
										className={cn(s.input, {
											[s.error]: errors['Дата рождения'],
											[s.input_calendar]: 'calendar',
										})}
										{...register('Дата рождения', { required: true })}
										name='Дата рождения'
										placeholder='__.__.____'
										mask={'99.99.9999'}
										maskChar={false}
										autoComplete='off'
									/>
								</div>
							</div>
						</div>

						<div className={s.form_item}>
							<h4 className={s.form_title}>Адрес регистрации (прописка)</h4>

							<div className={s.address}>
								<input
									className={cn(s.input, {
										[s.error]: errors['Адрес регистрации'],
										[s.input_address]: 'address',
									})}
									{...register('Адрес регистрации', { required: true })}
									type='text'
									name='Адрес регистрации'
									placeholder='Развернутый ответ увеличит вероятность одобрения кандидата на партнерство'
									maxLength='90'
									autoComplete='off'
								/>
							</div>
						</div>

						<div className={s.wrapper_mobile}>
							<button
								className={s.back}
								onClick={() => showStep1MobileInfo(false)}
							>
								<img src='/static/lk/IconBackward.svg' alt='назад' />
							</button>
							<Button scrollToTop={scrollToTop}>Далее 1 из 8</Button>
						</div>
					</Form>
				</div>
			)}

			{width > 575 && (
				<div>
					<div className={s.right} style={{ marginBottom: '28px' }}>
						<div className={s.right_title}>
							<h3>
								Для чего заполнять анкету?
							</h3>
						</div>

						<div className={s.right_descr}>
							<p>
								Каждый партнер перед сотрудничеством проходит многоступенчатую
								оценку. Обычно, только 25% соискателей получают положительную
								оценку.
							</p>

							<p>
								Каждый потенциальный партнер обязан предоставить свои достоверные
								персональные данные (если партнер является ООО, то данные
								директора и учредителей): паспортные данные, ИНН, СНИЛС и др.
								Сервис проверяет партнера на наличие уголовных судимостей:
								погашенных и непогашенных, объявлений банкротом, проблем с
								судебными приставами (принудительных изъятий имущества) и многое
								др. Если выявятся негативные факторы, которые могут помешать
								бизнесу со Skilla — сервис откажет на данной стадии.
							</p>
						</div>
					</div>

					<div className={s.right}>
						<div className={s.right_title}>

							<h3>
								Что проверяет Skilla?
							</h3>
						</div>

						<div className={s.right_container}>
							<ul className={s.list}>
								<li>
									<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
										<path d="M3.375 8.25L2.25 9.45631L6.75 14.25L15.75 5.625L14.625 4.5L6.75 12L3.375 8.25Z" fill="#002CFB" />
									</svg>
									<p>Суды общей юрисдикции</p>
								</li>

								<li>
									<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
										<path d="M3.375 8.25L2.25 9.45631L6.75 14.25L15.75 5.625L14.625 4.5L6.75 12L3.375 8.25Z" fill="#002CFB" />
									</svg>
									<p>Арбитражные суды</p>
								</li>

								<li>
									<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
										<path d="M3.375 8.25L2.25 9.45631L6.75 14.25L15.75 5.625L14.625 4.5L6.75 12L3.375 8.25Z" fill="#002CFB" />
									</svg>
									<p>Банкротства</p>
								</li>

								<li>
									<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
										<path d="M3.375 8.25L2.25 9.45631L6.75 14.25L15.75 5.625L14.625 4.5L6.75 12L3.375 8.25Z" fill="#002CFB" />
									</svg>
									<p>Коррупция</p>
								</li>

								<li>
									<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
										<path d="M3.375 8.25L2.25 9.45631L6.75 14.25L15.75 5.625L14.625 4.5L6.75 12L3.375 8.25Z" fill="#002CFB" />
									</svg>
									<p>Организации</p>
								</li>

								<li>
									<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
										<path d="M3.375 8.25L2.25 9.45631L6.75 14.25L15.75 5.625L14.625 4.5L6.75 12L3.375 8.25Z" fill="#002CFB" />
									</svg>
									<p>Реестр самозанятых</p>
								</li>

								<li>
									<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
										<path d="M3.375 8.25L2.25 9.45631L6.75 14.25L15.75 5.625L14.625 4.5L6.75 12L3.375 8.25Z" fill="#002CFB" />
									</svg>
									<p>Приостановления по счетам</p>
								</li>

								<li>
									<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
										<path d="M3.375 8.25L2.25 9.45631L6.75 14.25L15.75 5.625L14.625 4.5L6.75 12L3.375 8.25Z" fill="#002CFB" />
									</svg>
									<p>Задолженности по ФССП</p>
								</li>

								<li>
									<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
										<path d="M3.375 8.25L2.25 9.45631L6.75 14.25L15.75 5.625L14.625 4.5L6.75 12L3.375 8.25Z" fill="#002CFB" />
									</svg>
									<p>Розыск ФССП</p>
								</li>
							</ul>

							<ul style={{ width: '400px' }} className={s.list}>
								<li>
									<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
										<path d="M3.375 8.25L2.25 9.45631L6.75 14.25L15.75 5.625L14.625 4.5L6.75 12L3.375 8.25Z" fill="#002CFB" />
									</svg>
									<p>Розыск МВД</p>
								</li>

								<li>
									<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
										<path d="M3.375 8.25L2.25 9.45631L6.75 14.25L15.75 5.625L14.625 4.5L6.75 12L3.375 8.25Z" fill="#002CFB" />
									</svg>
									<p>Розыск ФСИН</p>
								</li>

								<li>
									<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
										<path d="M3.375 8.25L2.25 9.45631L6.75 14.25L15.75 5.625L14.625 4.5L6.75 12L3.375 8.25Z" fill="#002CFB" />
									</svg>
									<p>Налоговые задолженности</p>
								</li>

								<li>
									<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
										<path d="M3.375 8.25L2.25 9.45631L6.75 14.25L15.75 5.625L14.625 4.5L6.75 12L3.375 8.25Z" fill="#002CFB" />
									</svg>
									<p>Залоги</p>
								</li>

								<li>
									<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
										<path d="M3.375 8.25L2.25 9.45631L6.75 14.25L15.75 5.625L14.625 4.5L6.75 12L3.375 8.25Z" fill="#002CFB" />
									</svg>
									<p>Реестр недобросовестных поставщиков</p>
								</li>

								<li>
									<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
										<path d="M3.375 8.25L2.25 9.45631L6.75 14.25L15.75 5.625L14.625 4.5L6.75 12L3.375 8.25Z" fill="#002CFB" />
									</svg>
									<p>ФСГС</p>
								</li>

								<li>
									<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
										<path d="M3.375 8.25L2.25 9.45631L6.75 14.25L15.75 5.625L14.625 4.5L6.75 12L3.375 8.25Z" fill="#002CFB" />
									</svg>
									<p>Реестр дисквалифицированных</p>
								</li>

								<li>
									<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
										<path d="M3.375 8.25L2.25 9.45631L6.75 14.25L15.75 5.625L14.625 4.5L6.75 12L3.375 8.25Z" fill="#002CFB" />
									</svg>
									<p>Список террористов</p>
								</li>

								<li>
									<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
										<path d="M3.375 8.25L2.25 9.45631L6.75 14.25L15.75 5.625L14.625 4.5L6.75 12L3.375 8.25Z" fill="#002CFB" />
									</svg>
									<p>Реестр иностранных агентов</p>
								</li>


							</ul>
						</div>
					</div>
				</div>



			)}

			{width <= 575 && !showMobileState && (
				<div>
					<div className={s.right}>
						<div className={s.right_title}>
							<img src='/static/lk/iconInfo.svg' alt='Иконка' />
							<h3>
								<span style={{ marginBottom: '6px', display: 'inline-block' }}>
									Для чего заполнять анкету?
								</span>{' '}

							</h3>
						</div>

						<div className={s.right_descr}>
							<p>
								Каждый партнер перед сотрудничеством проходит многоступенчатую
								оценку. Обычно, только 25% соискателей получают положительную
								оценку.
							</p>

							<p>
								Каждый потенциальный партнер обязан предоставить свои достоверные
								персональные данные (если партнер является ООО, то данные
								директора и учредителей): паспортные данные, ИНН, СНИЛС и др.
								Сервис проверяет партнера на наличие уголовных судимостей:
								погашенных и непогашенных, объявлений банкротом, проблем с
								судебными приставами (принудительных изъятий имущества) и многое
								др. Если выявятся негативные факторы, которые могут помешать
								бизнесу со Skilla — сервис откажет на данной стадии.
							</p>
						</div>
					</div>
					<div style={{marginTop: '12px'}} className={s.right}>
						<div className={s.right_title}>
						<img src='/static/lk/iconInfo.svg' alt='Иконка' />
							<h3>Что проверяет Skilla?</h3>
						</div>

						<ul className={s.list}>
							<li>
								<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
									<path d="M3.375 8.25L2.25 9.45631L6.75 14.25L15.75 5.625L14.625 4.5L6.75 12L3.375 8.25Z" fill="#002CFB" />
								</svg>
								<p>Суды общей юрисдикции</p>
							</li>

							<li>
								<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
									<path d="M3.375 8.25L2.25 9.45631L6.75 14.25L15.75 5.625L14.625 4.5L6.75 12L3.375 8.25Z" fill="#002CFB" />
								</svg>
								<p>Арбитражные суды</p>
							</li>

							<li>
								<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
									<path d="M3.375 8.25L2.25 9.45631L6.75 14.25L15.75 5.625L14.625 4.5L6.75 12L3.375 8.25Z" fill="#002CFB" />
								</svg>
								<p>Банкротства</p>
							</li>

							<li>
								<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
									<path d="M3.375 8.25L2.25 9.45631L6.75 14.25L15.75 5.625L14.625 4.5L6.75 12L3.375 8.25Z" fill="#002CFB" />
								</svg>
								<p>Коррупция</p>
							</li>

							<li>
								<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
									<path d="M3.375 8.25L2.25 9.45631L6.75 14.25L15.75 5.625L14.625 4.5L6.75 12L3.375 8.25Z" fill="#002CFB" />
								</svg>
								<p>Организации</p>
							</li>

							<li>
								<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
									<path d="M3.375 8.25L2.25 9.45631L6.75 14.25L15.75 5.625L14.625 4.5L6.75 12L3.375 8.25Z" fill="#002CFB" />
								</svg>
								<p>Реестр самозанятых</p>
							</li>

							<li>
								<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
									<path d="M3.375 8.25L2.25 9.45631L6.75 14.25L15.75 5.625L14.625 4.5L6.75 12L3.375 8.25Z" fill="#002CFB" />
								</svg>
								<p>Приостановления по счетам</p>
							</li>

							<li>
								<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
									<path d="M3.375 8.25L2.25 9.45631L6.75 14.25L15.75 5.625L14.625 4.5L6.75 12L3.375 8.25Z" fill="#002CFB" />
								</svg>
								<p>Задолженности по ФССП</p>
							</li>

							<li>
								<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
									<path d="M3.375 8.25L2.25 9.45631L6.75 14.25L15.75 5.625L14.625 4.5L6.75 12L3.375 8.25Z" fill="#002CFB" />
								</svg>
								<p>Розыск ФССП</p>
							</li>

							<li>
								<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
									<path d="M3.375 8.25L2.25 9.45631L6.75 14.25L15.75 5.625L14.625 4.5L6.75 12L3.375 8.25Z" fill="#002CFB" />
								</svg>
								<p>Розыск МВД</p>
							</li>

							<li>
								<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
									<path d="M3.375 8.25L2.25 9.45631L6.75 14.25L15.75 5.625L14.625 4.5L6.75 12L3.375 8.25Z" fill="#002CFB" />
								</svg>
								<p>Розыск ФСИН</p>
							</li>

							<li>
								<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
									<path d="M3.375 8.25L2.25 9.45631L6.75 14.25L15.75 5.625L14.625 4.5L6.75 12L3.375 8.25Z" fill="#002CFB" />
								</svg>
								<p>Налоговые задолженности</p>
							</li>

							<li>
								<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
									<path d="M3.375 8.25L2.25 9.45631L6.75 14.25L15.75 5.625L14.625 4.5L6.75 12L3.375 8.25Z" fill="#002CFB" />
								</svg>
								<p>Залоги</p>
							</li>

							<li>
								<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
									<path d="M3.375 8.25L2.25 9.45631L6.75 14.25L15.75 5.625L14.625 4.5L6.75 12L3.375 8.25Z" fill="#002CFB" />
								</svg>
								<p>Реестр недобросовестных поставщиков</p>
							</li>

							<li>
								<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
									<path d="M3.375 8.25L2.25 9.45631L6.75 14.25L15.75 5.625L14.625 4.5L6.75 12L3.375 8.25Z" fill="#002CFB" />
								</svg>
								<p>ФСГС</p>
							</li>

							<li>
								<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
									<path d="M3.375 8.25L2.25 9.45631L6.75 14.25L15.75 5.625L14.625 4.5L6.75 12L3.375 8.25Z" fill="#002CFB" />
								</svg>
								<p>Реестр дисквалифицированных</p>
							</li>

							<li>
								<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
									<path d="M3.375 8.25L2.25 9.45631L6.75 14.25L15.75 5.625L14.625 4.5L6.75 12L3.375 8.25Z" fill="#002CFB" />
								</svg>
								<p>Список террористов</p>
							</li>

							<li>
								<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
									<path d="M3.375 8.25L2.25 9.45631L6.75 14.25L15.75 5.625L14.625 4.5L6.75 12L3.375 8.25Z" fill="#002CFB" />
								</svg>
								<p>Реестр иностранных агентов</p>
							</li>

						</ul>
					</div>
				</div>

			)}

			{width <= 575 && !showMobileState && (
				<button
					onClick={() => showStep1MobileInfo(true)}
					className={s.btn_mobile}
				>
					Заполнить анкету партнера
				</button>
			)}

			{width <= 575 && !showMobileState && (
				<div className={s.header}>
					<h3 className={s.subtitle}>Шаг 6</h3>
					<h1 className={`${s.title} lk-title`}>Заполни анкету</h1>

					<p className={s.descr}>
						От достоверности и полноты содержимого ответов будет зависеть
						возможность заработка в партнерской сети Skilla
					</p>
				</div>
			)}
		</div>
	);
};

export default Step1;
