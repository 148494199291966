import cn from 'classnames'
import s from '../steps.module.scss'

import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'
import { setAddData, setAddStep } from '../../../../../store/reducer/lk/formExam/slice'

import { selectLkFormExam } from '../../../../../store/reducer/lk/formExam/selector'
import Button from '../../Button/Button'
import Form from '../../Form'

const Step10 = () => {
  const [width, setWidth] = useState(window?.innerWidth)
  const dispatch = useDispatch()
  const { data, step } = useSelector(selectLkFormExam)
  const [isError28, setError28] = useState(false)
  const [isSendForm, setIsSendForm] = useState(false)

  useEffect(() => {
    localStorage.setItem('stepLkExam', JSON.stringify(step))
    localStorage.setItem('dataExam', JSON.stringify(data))
  }, [])

  useEffect(() => {
    const cb = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', cb)

    return () => window.removeEventListener('resize', cb)
  }, [])

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm({
    defaultValues: {
      ['Кто ищет клиентов для моей компании?']: data['Кто ищет клиентов для моей компании?'],
    },
  })

  const ckbox28 = watch('Кто ищет клиентов для моей компании?')

  useEffect(() => {
    if (!ckbox28?.length) {
      setError28(true)
    } else {
      setError28(false)
    }
  }, [ckbox28])

  const onSubmit = (data) => {
    setIsSendForm(true)

    if (!isError28) {
      dispatch(setAddStep(11))
      dispatch(setAddData(data))
      setIsSendForm(false)

      localStorage.setItem('dataExam', JSON.stringify(data))
      localStorage.setItem('stepLkExam', JSON.stringify(10))
    }
  }

  return (
    <div className={s.step10}>
      <div className={s.wrapper_heading}>
        <h5>Роли. Директор. Скилла</h5>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={s.form_item}>
          <h4 className={s.form_title}>28. Кто ищет клиентов для моей компании?</h4>

          <div className={s.question28}>
            <div className={s.checkbox}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question28,
                })}
                {...register('Кто ищет клиентов для моей компании?')}
                id="exam281"
                type="checkbox"
                name="Кто ищет клиентов для моей компании?"
                autoComplete="off"
                value="Всех клиентов предоставляет Скилла"
              />
              <label htmlFor="exam281" className={s.label}>
                Всех клиентов предоставляет Скилла
              </label>
            </div>

            <div className={s.checkbox}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question28,
                })}
                {...register('Кто ищет клиентов для моей компании?')}
                id="exam282"
                type="checkbox"
                name="Кто ищет клиентов для моей компании?"
                autoComplete="off"
                value="Клиентов я ищу самостоятельно"
              />
              <label htmlFor="exam282" className={s.label}>
                Клиентов я ищу самостоятельно
              </label>
            </div>

            <div className={s.checkbox}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question28,
                })}
                {...register('Кто ищет клиентов для моей компании?')}
                id="exam283"
                type="checkbox"
                name="Кто ищет клиентов для моей компании?"
                autoComplete="off"
                value="Скилла помогает с тендерами и госзаказами"
              />
              <label htmlFor="exam283" className={s.label}>
                Скилла помогает с тендерами и госзаказами
              </label>
            </div>

            <div className={s.checkbox}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question28,
                })}
                {...register('Кто ищет клиентов для моей компании?')}
                id="exam284"
                type="checkbox"
                name="Кто ищет клиентов для моей компании?"
                autoComplete="off"
                value="Контактный центр"
              />
              <label htmlFor="exam284" className={s.label}>
                Контактный центр
              </label>
            </div>

            <p className={s.errorText2}>{isError28 && isSendForm && 'Необходимо указать'}</p>
          </div>
        </div>

        <div className={s.btns}>
          <Button callback={() => dispatch(setAddStep(9))} back>
            {width > 575 ? '   Назад' : <img src="/static/lk/IconBackward.svg" alt="назад" />}
          </Button>
          <Button>Далее 10 из 35</Button>
        </div>
      </Form>

      <img className={s.illustration} src="/static/lk/illustration/illustration.png" alt="Иллюстрация" />
    </div>
  )
}

export default Step10
