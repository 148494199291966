import cn from 'classnames';
import { useEffect, useState } from 'react';
import s from './advantage.module.scss';

const Advantage = ({ subtitle, title, item, section }) => {
	const [width, setWidth] = useState(window?.innerWidth);
	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);
		window.scroll(0, 0);

		return () => window.removeEventListener('resize', cb);
	}, []);
	return (
		<div
			className={cn(s.advantage, {
				[s.firstSection]: section === 'firstSection',
				[s.secondSection]: section === 'secondSection',
				[s.thirdSection]: section === 'thirdSection',
				[s.fourthSection]: section === 'fourthSection',
				[s.fifthSection]: section === 'fifthSection',
			})}
		>
			<p className={s.subtitle}>{subtitle}</p>

			<h2 className={s.title}>{title}</h2>

			<ul className={s.list}>
				{item?.map(item => (
					<li key={item} className={s.item}>
						<img
							src={
								width > 575
									? '/static/vip/done.svg'
									: '/static/vip/done-mobile.svg'
							}
							alt='иконка'
						/>
						{item}
					</li>
				))}
			</ul>
		</div>
	);
};

export default Advantage;
