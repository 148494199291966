import cn from 'classnames'
import s from '../steps.module.scss'

import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'
import { setAddData, setAddStep } from '../../../../../store/reducer/lk/formExam/slice'

import { selectLkFormExam } from '../../../../../store/reducer/lk/formExam/selector'
import Button from '../../Button/Button'
import Form from '../../Form'

const Step4 = () => {
  const [width, setWidth] = useState(window?.innerWidth)
  const dispatch = useDispatch()
  const { data, step } = useSelector(selectLkFormExam)
  const [isSendForm, setIsSendForm] = useState(false)

  useEffect(() => {
    localStorage.setItem('stepLkExam', JSON.stringify(step))
    localStorage.setItem('dataExam', JSON.stringify(data))
  }, [])

  useEffect(() => {
    const cb = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', cb)

    return () => window.removeEventListener('resize', cb)
  }, [])

  const schema11 = yup
    .object({
      ['Как работать с физическими лицами?']: yup.string(),
      ['Как работать с юридическими лицами?']: yup.string(),
      ['Можно ли в договоре с заказчиком указывать чел/часы?']: yup.string(),
    })
    .required()

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm({
    resolver: yupResolver(schema11),
    defaultValues: {
      ['Как работать с физическими лицами?']: data['Как работать с физическими лицами?'],
      ['Как работать с юридическими лицами?']: data['Как работать с юридическими лицами?'],
      ['Можно ли в договоре с заказчиком указывать чел/часы?']:
        data['Можно ли в договоре с заказчиком указывать чел/часы?'],
    },
  })

  const onSubmit = (data) => {
    setIsSendForm(true)

    dispatch(setAddStep(5))
    dispatch(setAddData(data))
    setIsSendForm(false)

    localStorage.setItem('dataExam', JSON.stringify(data))
    localStorage.setItem('stepLkExam', JSON.stringify(4))
  }

  return (
    <div className={s.step4}>
      <div className={s.wrapper_heading}>
        <h5>Скилла. Бизнес-модель</h5>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={s.form_item}>
          <h4 className={s.form_title}>10. Как работать с физическими лицами?</h4>

          <div className={s.question10}>
            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question10,
                })}
                {...register('Как работать с физическими лицами?')}
                id="exam101"
                type="radio"
                name="Как работать с физическими лицами?"
                autoComplete="off"
                value="Без договора, за наличный расчет или перевод на дебетовую карту"
              />

              <label htmlFor="exam101" className={s.label}>
                Без договора, за наличный расчет или перевод на дебетовую карту
              </label>
            </div>

            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question10,
                })}
                {...register('Как работать с физическими лицами?')}
                id="exam102"
                type="radio"
                name="Как работать с физическими лицами?"
                autoComplete="off"
                value="Только по договору с переводом денежных средств на р/с организации"
              />
              <label htmlFor="exam102" className={s.label}>
                Только по договору с переводом денежных средств на р/с организации
              </label>
            </div>

            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question10,
                })}
                {...register('Как работать с физическими лицами?')}
                id="exam103"
                type="radio"
                name="Как работать с физическими лицами?"
                autoComplete="off"
                value="По договору, за наличный или безналичный расчет  без онлайн-кассы"
              />
              <label htmlFor="exam103" className={s.label}>
                По договору, за наличный или безналичный расчет без онлайн-кассы
              </label>
            </div>
          </div>

          <p className={s.errorText}>
            {errors['Как работать с физическими лицами?'] ? 'Необходимо указать' : ''}
          </p>
        </div>

        <div className={s.form_item}>
          <h4 className={s.form_title}>11. Как работать с юридическими лицами?</h4>

          <div className={s.question11}>
            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question11,
                })}
                {...register('Как работать с юридическими лицами?')}
                id="exam111"
                type="radio"
                name="Как работать с юридическими лицами?"
                autoComplete="off"
                value="Без договора, за наличный расчет или перевод на дебетовую карту"
              />

              <label htmlFor="exam111" className={s.label}>
                Без договора, за наличный расчет или перевод на дебетовую карту
              </label>
            </div>

            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question11,
                })}
                {...register('Как работать с юридическими лицами?')}
                id="exam112"
                type="radio"
                name="Как работать с юридическими лицами?"
                autoComplete="off"
                value="По договору, за наличный или безналичный расчет при наличии онлайн-кассы"
              />
              <label htmlFor="exam112" className={s.label}>
                По договору, за наличный или безналичный расчет при наличии онлайн-кассы
              </label>
            </div>

            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question11,
                })}
                {...register('Как работать с юридическими лицами?')}
                id="exam113"
                type="radio"
                name="Как работать с юридическими лицами?"
                autoComplete="off"
                value="Только по договору с переводом денежных средств на р/с организации"
              />
              <label htmlFor="exam113" className={s.label}>
                Только по договору с переводом денежных средств на р/с организации
              </label>
            </div>

            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question11,
                })}
                {...register('Как работать с юридическими лицами?')}
                id="exam114"
                type="radio"
                name="Как работать с юридическими лицами?"
                autoComplete="off"
                value="По договору, за наличный или безналичный расчет  без онлайн-кассы"
              />
              <label htmlFor="exam114" className={s.label}>
                По договору, за наличный или безналичный расчет без онлайн-кассы
              </label>
            </div>
          </div>

          <p className={s.errorText}>
            {errors['Как работать с юридическими лицами?'] ? 'Необходимо указать' : ''}
          </p>
        </div>

        <div className={s.form_item}>
          <h4 className={s.form_title}>12. Можно ли в договоре с заказчиком указывать чел/часы?</h4>

          <div className={s.question12}>
            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question12,
                })}
                {...register('Можно ли в договоре с заказчиком указывать чел/часы?')}
                id="exam121"
                type="radio"
                name="Можно ли в договоре с заказчиком указывать чел/часы?"
                autoComplete="off"
                value="Да, так как мы предоставляем персонал"
              />
              <label htmlFor="exam121" className={s.label}>
                Да, так как мы предоставляем персонал
              </label>
            </div>

            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question12,
                })}
                {...register('Можно ли в договоре с заказчиком указывать чел/часы?')}
                id="exam122"
                type="radio"
                name="Можно ли в договоре с заказчиком указывать чел/часы?"
                autoComplete="off"
                value="Нет, так как мы предоставляем услуги"
              />
              <label htmlFor="exam122" className={s.label}>
                Нет, так как мы предоставляем услуги
              </label>
            </div>
          </div>

          <p className={s.errorText}>
            {errors['Можно ли в договоре с заказчиком указывать чел/часы?'] ? 'Необходимо указать' : ''}
          </p>
        </div>

        <div className={s.btns}>
          <Button callback={() => dispatch(setAddStep(3))} back>
            {width > 575 ? '   Назад' : <img src="/static/lk/IconBackward.svg" alt="назад" />}
          </Button>
          <Button>Далее 4 из 35</Button>
        </div>
      </Form>

      <img className={s.illustration} src="/static/lk/illustration/illustration.png" alt="Иллюстрация" />
    </div>
  )
}

export default Step4
