import s from './failed.module.scss'
import { useState, useEffect } from 'react';

const Failed = () => {
	const [width, setWidth] = useState(window?.innerWidth);

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

	return (
		<div className={s.failed}>
			<div className={s.wrapper_title}>
				<img src='/static/lk/iconWarning.svg' alt='Иконка' />
				<h4 className={s.subtitle}>{width > 575 ? '8. Договор-оферта' : 'Шаг 8'}</h4>
			</div>

			<h1 className={`${s.title} lk-title`}>
				Невозможно заключить договор онлайн
			</h1>

			<p className={s.descr}>
				Дистанционно нельзя заключить договор с юридическим лицом или ИП.
				Свяжись с менеджером для согласования подписания договора или изменения
				реквизитов.
			</p>

			<img
				className={s.illustation}
				src={width > 575 ? '/static/lk/illustration/illustrationBad.png' : '/static/lk/illustration/illustrationBad-mobile.png'}
				alt='иллюстрация'
			/>
		</div>
	);
};

export default Failed;
