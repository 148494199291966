import React, { memo, useEffect, useState } from 'react';
import { Area, AreaChart, CartesianGrid, Tooltip, XAxis } from 'recharts';
import {
	getLkCommissionGraph,
	getLkWorkersGraph,
	getRevenueGraph,
} from '../../../Api';
import Spinner from '../../SpinnerSmall';
import s from './graph.module.scss';
import './recharts.scss'

const CustomTooltip = memo(function CustomTooltip({
	active,
	payload,
	label,
	type,
}) {
	if (active && payload && payload.length && type === 'sale') {
		return (
			<div className={s.chart_tooltip}>
				<p className={s.chart_tooltip__title}>{label}</p>

				<p className={s.chart_tooltip__revenue}>
					Выручка: {payload[0]?.payload?.revenue?.toLocaleString('ru-RU')} ₽
				</p>
			</div>
		);
	}

	if (active && payload && payload.length && type === 'order') {
		return (
			<div className={s.chart_tooltip}>
				<p className={s.chart_tooltip__title}>{label}</p>

				<p className={s.chart_tooltip__revenue}>
					Заказов:{' '}
					{payload[0]?.payload?.orders
						?.toLocaleString('ru-RU')
						.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
				</p>
			</div>
		);
	}

	if (active && payload && payload.length && type === 'commission') {
		return (
			<div className={s.chart_tooltip}>
				<p className={s.chart_tooltip__title}>{label}</p>

				<p className={s.chart_tooltip__revenue}>
					Комиссия: {payload[0]?.payload?.orders?.toLocaleString('ru-RU')} ₽
				</p>
			</div>
		);
	}

	if (active && payload && payload.length && type === 'worker') {
		return (
			<div className={s.chart_tooltip}>
				<p className={s.chart_tooltip__title}>{label}</p>

				<p className={s.chart_tooltip__revenue}>
					Исполнители: {payload[0]?.payload?.orders?.toLocaleString('ru-RU')}
				</p>
			</div>
		);
	}

	return null;
});

const Chart = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingWorker, setIsLoadingWorker] = useState(false);
	const [isLoadingSale, setIsLoadingSale] = useState(false);
	const [isLoadingOrder, setIsLoadingOrder] = useState(false);

	const [chartData, setChartData] = useState([]);
	const [chartDataWorker, setChartDataWorker] = useState([]);
	const [chartDataSale, setChartDataSale] = useState([]);
	const [chartDataOrder, setChartDataOrder] = useState([]);
	const [width, setWidth] = useState(window?.innerWidth);

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

	useEffect(() => {
		setIsLoadingSale(true);

		getRevenueGraph()
			.then(response => {
				let mas = [];

				response?.data?.forEach(item => {
					const date = new Date(item.date);

					const monthMas = [
						'Янв',
						'Фев',
						'Март',
						'Апр',
						'Май',
						'Июнь',
						'Июль',
						'Авг',
						'Сент',
						'Окт',
						'Нояб',
						'Дек',
					];

					mas.push({
						revenue: item.revenue,
						orders: item.orders,

						date: `${monthMas[date.getMonth()]} ${date.getFullYear()}`,
					});
				});

				setChartDataSale(mas);
				setIsLoadingSale(false);
			})
			.catch(e => {
				setIsLoadingSale(false);
				console.log(e);
			});
	}, []);

	useEffect(() => {
		setIsLoadingOrder(true);

		getRevenueGraph()
			.then(response => {
				let mas = [];

				response?.data?.forEach(item => {
					const date = new Date(item.date);

					const monthMas = [
						'Янв',
						'Фев',
						'Март',
						'Апр',
						'Май',
						'Июнь',
						'Июль',
						'Авг',
						'Сент',
						'Окт',
						'Нояб',
						'Дек',
					];

					mas.push({
						orders: item.orders,
						date: `${monthMas[date.getMonth()]} ${date.getFullYear()}`,
					});
				});

				setChartDataOrder(mas);
				setIsLoadingOrder(false);
			})
			.catch(e => {
				setIsLoadingOrder(false);
				console.log(e);
			});
	}, []);

	useEffect(() => {
		setIsLoading(true);

		getLkCommissionGraph()
			.then(response => {
				let mas = [];

				response?.data?.graph?.forEach(item => {
					const date = new Date(item.date);

					const monthMas = [
						'Янв',
						'Фев',
						'Март',
						'Апр',
						'Май',
						'Июнь',
						'Июль',
						'Авг',
						'Сент',
						'Окт',
						'Нояб',
						'Дек',
					];

					mas.push({
						orders: item.sum,
						date: `${monthMas[date.getMonth()]} ${date.getFullYear()}`,
					});
				});

				setChartData(mas);
				setIsLoading(false);
			})
			.catch(e => {
				setIsLoading(false);
				console.log(e);
			});
	}, []);

	useEffect(() => {
		setIsLoadingWorker(true);

		getLkWorkersGraph()
			.then(response => {
				let mas = [];

				response?.data?.graph?.forEach(item => {
					const date = new Date(item.date);

					const options = {
						day: 'numeric',
						month: 'numeric',
					};

					const dateTransform = date.toLocaleDateString('ru-RU', options);

					mas.push({
						orders: item.num,
						date: `${dateTransform}`,
					});
				});

				setChartDataWorker(mas);
				setIsLoadingWorker(false);
			})
			.catch(e => {
				setIsLoadingWorker(false);
				console.log(e);
			});
	}, []);

	const CustomizedLabelOrders = ({ x, y, value, index, step, zeroInclude }) => {
		let flag = false;

		if (zeroInclude && index === 0) {
			flag = true;
		} else {
			flag = (index + 1) % step === 0;
		}

		return width < 900 ? (
			<text x={x} y={y} dy={-20} fill='#71869D' fontWeight={600} fontSize={16}>
				{value.toLocaleString().length >= 7 && flag
					? `${value.toLocaleString('ru-RU').slice(0, 3)} млн`
					: null}
			</text>
		) : (
			<text
				x={x}
				y={y}
				dy={-25}
				fill='#71869D'
				fontWeight={600}
				fontSize={width > 768 ? 16 : 14}
			>
				{`${value?.toLocaleString('ru-RU').slice(0, 3)} млн`}
			</text>
		);
	};

	const CustomizedLabelOrders2 = ({
		x,
		y,
		value,
		index,
		step,
		zeroInclude,
	}) => {
		let flag = false;

		if (zeroInclude && index === 0) {
			flag = true;
		} else {
			flag = (index + 1) % step === 0;
		}

		return (
			<text
				x={x}
				y={y}
				dy={width > 1000 ? -22 : 
					-30}
				fill='#71869D'
				fontWeight={600}
				fontSize={width > 768 ? 16 : 14}
			>
				{value.toString().length >= 7
					? flag
						? `${
								value
									.toString()
									.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
									.slice(0, 4) + ' млн'
						  }`
						: null
					: value.toString().length <= 6
					? flag
						? `${
								value
									.toString()
									.replace(
										/\B(?=(\d{3})+(?!\d))/g,
										value.toString().length > 4 ? ' ' : ','
									)
									.slice(0, 3) + ' тыс'
						  }`
						: null
					: ''}
			</text>
		);
	};

	const CustomizedLabelWorker = ({ x, y, value, index, step, zeroInclude }) => {
		let flag = false;

		if (zeroInclude && index === 0) {
			flag = true;
		} else {
			flag = (index + 1) % step === 0;
		}

		return (
			<text
				x={x}
				y={y}
				dy={-12}
				fill='#71869D'
				fontWeight={600}
				fontSize={width > 768 ? 16 : 14}
			>
				{value.toLocaleString().length >= 7 && flag
					? `${value.toLocaleString('ru-RU')}`
					: null}
			</text>
		);
	};

	return (
		<>
			<section className={s.chart}>
				<div className={s.container_maps}>
					<div className={s.text}>
						<h2 className={s.title}>
							Доходы от продаж <span />
						</h2>
						<p className={s.descr}>
							График показывает выручку партнерских офисов по месяцам (то есть
							сколько денег заплатили за услуги клиенты-компании)
						</p>
					</div>
				</div>

				<div className={s.container}>
					<div className={s.chart__chart}>
						{isLoadingSale && (
							<div className={s.chart__loader}>
								<Spinner widthSize={40} heightSize={40} />
							</div>
						)}
						{!isLoadingSale && (
							<AreaChart
								width={
									width > 1800
										? 1350
										: width > 1700
										? 1200
										: width > 1600
										? 1150
										: width > 1500
										? 1130
										: width > 1400
										? 1050
										: width > 1200
										? 900
										: width > 1100
										? 800
										: width > 1000
										? 700
										: width > 900
										? 600
										: width > 770
										? 500
										: width > 590
										? 600
										: width > 550
										? 520
										: width > 500
										? 480
										: width > 450
										? 450
										: width > 400
										? 410
										: 350
								}
								height={300}
								data={chartDataSale}
								margin={{
									top: 10,
									right: width > 575 ? 60 : 40,
									left: width > 575 ? 60 : 40,
									bottom: 0,
								}}
							>
								<defs>
									<linearGradient
										id='colorCustomer3'
										x1='0'
										y1='0'
										x2='0'
										y2='1'
									>
										<stop
											offset='30%'
											stopColor='#00CC6E80'
											stopOpacity={0.9}
										/>
										<stop
											offset='70%'
											stopColor='#00CC6E80'
											stopOpacity={0.2}
										/>
									</linearGradient>
								</defs>
								<CartesianGrid
									horizontal={false}
									strokeDasharray='3 3'
									stroke='#ccc'
								/>
								<XAxis dataKey='date' dy={5} dx={width > 575 ? 0 : 0} />

								<Tooltip content={<CustomTooltip type='sale' />} />

								<Area
									strokeWidth={4}
									type='linear'
									dataKey='revenue'
									fillOpacity={0.3}
									fill='url(#colorCustomer3)'
									stroke='#00CC6E'
									activeDot={{ stroke: '#00CC6E', strokeWidth: '10px' }}
									dot={{
										fill: '#00CC6E',
										strokeWidth: width > 768 ? '10px' : '5px',
									}}
									label={
										<CustomizedLabelOrders
											step={width > 768 ? 1 : 2}
											zeroInclude={true}
										/>
									}
								/>

								<Area
									type='linear'
									dataKey='revenue'
									fillOpacity={0.3}
									fill='url(#colorCustomer3)'
									stroke='#00CC6E'
								/>
							</AreaChart>
						)}
					</div>
				</div>
			</section>

			<section className={s.chart}>
				<div className={s.container_maps}>
					<div className={s.text}>
						<h2 className={s.title}>
							Выполнено заказов <span />
						</h2>
						<p className={s.descr}>
							График показывает количество заказов, которые выполнены
							исполнителями партнерской сети
						</p>
					</div>
				</div>

				<div className={s.container}>
					<div className={s.chart__chart}>
						{isLoadingOrder && (
							<div className={s.chart__loader}>
								<Spinner widthSize={40} heightSize={40} />
							</div>
						)}
						{!isLoadingOrder && (
							<AreaChart
								width={
									width > 1800
										? 1350
										: width > 1700
										? 1200
										: width > 1600
										? 1150
										: width > 1500
										? 1130
										: width > 1400
										? 1050
										: width > 1200
										? 900
										: width > 1100
										? 800
										: width > 1000
										? 700
										: width > 900
										? 600
										: width > 770
										? 500
										: width > 590
										? 600
										: width > 550
										? 520
										: width > 500
										? 480
										: width > 450
										? 450
										: width > 400
										? 410
										: 350
								}
								height={380}
								data={chartDataOrder}
								margin={{
									top: 150,
									right: width > 575 ? 60 : 40,
									left: width > 575 ? 60 : 40,
									bottom: 0,
								}}
							>
								<defs>
									<linearGradient
										id='colorCustomer4'
										x1='0'
										y1='0'
										x2='0'
										y2='1'
									>
										<stop
											offset='30%'
											stopColor='#3357FFB2'
											stopOpacity={0.9}
										/>
										<stop
											offset='70%'
											stopColor='#3357FFB2'
											stopOpacity={0.2}
										/>
									</linearGradient>
								</defs>
								<CartesianGrid
									horizontal={false}
									strokeDasharray='3 3'
									stroke='#ccc'
								/>
								<XAxis dataKey='date' dy={1} dx={width > 575 ? 0 : 0} />

								<Tooltip content={<CustomTooltip type='order' />} />

								<Area
									strokeWidth={4}
									type='linear'
									dataKey='orders'
									fillOpacity={0.3}
									fill='url(#colorCustomer4)'
									stroke='#3357FF'
									activeDot={{ stroke: '#3357FF', strokeWidth: '10px' }}
									dot={{
										fill: '#3357FF',
										strokeWidth: width > 768 ? '10px' : '5px',
									}}
									label={
										<CustomizedLabelOrders2
											step={width > 1024 ? 1 : 2}
											zeroInclude={true}
										/>
									}
								/>

								<Area
									type='linear'
									dataKey='orders'
									fillOpacity={0.3}
									fill='url(#colorCustomer4)'
									stroke='#3357FF'
								/>
							</AreaChart>
						)}
					</div>
				</div>
			</section>

			<section className={s.chart}>
				<div className={s.container_maps}>
					<div className={s.text}>
						<h2 className={s.title}>
							Комиссия партнерской сети <span />
						</h2>
						<p className={s.descr}>
							Ежемесячная комиссия в рублях, которую заработала партнерская
							сеть, соединяя заказчиков и исполнителей
						</p>
					</div>
				</div>

				<div className={s.container}>
					<div className={s.chart__chart}>
						{isLoading && (
							<div className={s.chart__loader}>
								<Spinner widthSize={40} heightSize={40} />
							</div>
						)}
						{!isLoading && (
							<AreaChart
								width={
									width > 1800
										? 1350
										: width > 1700
										? 1200
										: width > 1600
										? 1150
										: width > 1500
										? 1130
										: width > 1400
										? 1050
										: width > 1200
										? 900
										: width > 1100
										? 800
										: width > 1000
										? 700
										: width > 900
										? 600
										: width > 770
										? 500
										: width > 590
										? 600
										: width > 550
										? 520
										: width > 500
										? 480
										: width > 450
										? 450
										: width > 400
										? 410
										: 350
								}
								height={300}
								data={chartData}
								margin={{
									top: 30,
									right: width > 575 ? 60 : 40,
									left: width > 575 ? 60 : 40,
									bottom: 0,
								}}
							>
								<defs>
									<linearGradient
										id='colorCustomer'
										x1='0'
										y1='0'
										x2='0'
										y2='1'
									>
										<stop
											offset='30%'
											stopColor='#F89500CC'
											stopOpacity={0.9}
										/>
										<stop
											offset='70%'
											stopColor='#F89500CC'
											stopOpacity={0.2}
										/>
									</linearGradient>
								</defs>
								<CartesianGrid
									horizontal={false}
									strokeDasharray='3 3'
									stroke='#ccc'
								/>
								<XAxis dataKey='date' dy={5} dx={width > 575 ? 0 : 0} />

								<Tooltip content={<CustomTooltip type='commission' />} />

								<Area
									strokeWidth={4}
									type='linear'
									dataKey='orders'
									fillOpacity={0.3}
									fill='url(#colorCustomer)'
									stroke='#F89500CC'
									activeDot={{ stroke: '#F89500CC', strokeWidth: '5px' }}
									dot={{
										fill: '#F89500CC',
										strokeWidth: width > 768 ? '10px' : '10px',
									}}
									label={
										<CustomizedLabelOrders
											step={width > 1024 ? 1 : 2}
											zeroInclude={true}
											x={120}
										/>
									}
								/>

								<Area
									type='linear'
									dataKey='orders'
									fillOpacity={0.3}
									fill='url(#colorCustomer)'
									stroke='#F89500CC'
								/>
							</AreaChart>
						)}
					</div>
				</div>
			</section>

			<section className={s.chart}>
				<div className={s.container_maps}>
					<div className={s.text}>
						<h2 className={s.title}>
							Уникальных исполнителей в приложении Skilla Работа <span />
						</h2>
						<p className={s.descr}>
							График показывает прирост уникального числа исполнителей,
							использующих фирменное приложение Skilla Работа
						</p>
					</div>
				</div>

				<div className={s.container}>
					<div className={s.chart__chart}>
						{isLoadingWorker && (
							<div className={s.chart__loader}>
								<Spinner widthSize={40} heightSize={40} />
							</div>
						)}
						{!isLoadingWorker && (
							<AreaChart
								width={
									width > 1800
										? 1350
										: width > 1700
										? 1200
										: width > 1600
										? 1150
										: width > 1500
										? 1130
										: width > 1400
										? 1050
										: width > 1200
										? 900
										: width > 1100
										? 800
										: width > 1000
										? 700
										: width > 900
										? 600
										: width > 770
										? 500
										: width > 590
										? 600
										: width > 550
										? 520
										: width > 500
										? 480
										: width > 450
										? 450
										: width > 400
										? 410
										: 350
								}
								height={300}
								data={chartDataWorker}
								margin={{
									top: 30,
									right: width > 575 ? 60 : 40,
									left: width > 575 ? 60 : 40,
									bottom: 0,
								}}
							>
								<defs>
									<linearGradient
										id='colorCustomer2'
										x1='0'
										y1='0'
										x2='0'
										y2='1'
									>
										<stop
											offset='30%'
											stopColor='#A033FFB2'
											stopOpacity={0.9}
										/>
										<stop
											offset='70%'
											stopColor='#A033FFB2'
											stopOpacity={0.2}
										/>
									</linearGradient>
								</defs>
								<CartesianGrid
									horizontal={false}
									strokeDasharray='3 3'
									stroke='#ccc'
								/>
								<XAxis dataKey='date' dy={5} />

								<Tooltip content={<CustomTooltip type='worker' />} />

								<Area
									strokeWidth={4}
									type='linear'
									dataKey='orders'
									fillOpacity={0.3}
									fill='url(#colorCustomer2)'
									stroke='#A033FFB2'
									activeDot={{ stroke: '#A033FFB2', strokeWidth: '10px' }}
									dot={{
										fill: '#A033FFB2',
										strokeWidth: width > 768 ? '10px' : '1px',
									}}
									label={
										<CustomizedLabelWorker
											step={width > 1500 ? 1 
												  :width > 485 ? 2
											      : 3}
											zeroInclude={true}
										/>
									}
								/>

								<Area
									type='linear'
									dataKey='orders'
									fillOpacity={0.3}
									fill='url(#colorCustomer2)'
									stroke='#A033FFB2'
								/>
							</AreaChart>
						)}
					</div>
				</div>
			</section>
		</>
	);
};

export default Chart;
