import cn from 'classnames';
import s from '../steps.module.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import {
	setAddData,
	setAddStep,
} from '../../../../../store/reducer/lk/formExam/slice';

import { selectLkFormExam } from '../../../../../store/reducer/lk/formExam/selector';
import Button from '../../Button/Button';
import Form from '../../Form';

const Step13 = () => {
	const [width, setWidth] = useState(window?.innerWidth);
	const dispatch = useDispatch();
	const { data, step } = useSelector(selectLkFormExam);
	const [isSendForm, setIsSendForm] = useState(false);

	useEffect(() => {
		localStorage.setItem('stepLkExam', JSON.stringify(step));
		localStorage.setItem('dataExam', JSON.stringify(data));
	}, []);

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

	const schema35= yup
		.object({
			['Что такое контекстная реклама в Яндекс?']: yup.string(),
            ['За что я плачу в контекстной рекламе?']: yup.string(),
            ['Имеет ли значение средняя позиция показа объявлений в контекстной рекламе?']: yup.string(),
		})
		.required();

	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
		watch,
	} = useForm({
		resolver: yupResolver(schema35),
		defaultValues: {
			['Что такое контекстная реклама в Яндекс?']: data['Что такое контекстная реклама в Яндекс?'],
			['За что я плачу в контекстной рекламе?']: data['За что я плачу в контекстной рекламе?'],
			['Имеет ли значение средняя позиция показа объявлений в контекстной рекламе?']: data['Имеет ли значение средняя позиция показа объявлений в контекстной рекламе?'],
		},
	});

	const onSubmit = data => {
		dispatch(setAddStep(14));
		dispatch(setAddData(data));
		localStorage.setItem('dataExam', JSON.stringify(data));
		localStorage.setItem('stepLkExam', JSON.stringify(13));
		
	};

	return (
		<div className={s.step13}>
      <div className={s.wrapper_heading}>
			  <h5>Реклама</h5>
			</div>
			<Form onSubmit={handleSubmit(onSubmit)}>

				<div className={s.form_item}>
					<h4 className={s.form_title}>35. Что такое контекстная реклама в Яндекс?</h4>

					<div className={s.question35}>
						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question35,
								})}
								{...register('Что такое контекстная реклама в Яндекс?')}
								id='exam351'
								type='radio'
								name='Что такое контекстная реклама в Яндекс?'
								autoComplete='off'
								value='Платные объявления Яндекс со значком "реклама"'
							/>

							<label htmlFor='exam351' className={s.label}>
							Платные объявления Яндекс со значком "реклама"
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question35,
								})}
								{...register('Что такое контекстная реклама в Яндекс?')}
								id='exam352'
								type='radio'
								name='Что такое контекстная реклама в Яндекс?'
								autoComplete='off'
								value='Всплывающая реклама, которая везде преследует клиента'
							/>

							<label htmlFor='exam352' className={s.label}>
							Всплывающая реклама, которая везде преследует клиента
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question35,
								})}
								{...register('Что такое контекстная реклама в Яндекс?')}
								id='exam353'
								type='radio'
								name='Что такое контекстная реклама в Яндекс?'
								autoComplete='off'
								value='Продвижение сайта для его поднятия на первые позиции в поисковой выдаче'
							/>

							<label htmlFor='exam353' className={s.label}>
							Продвижение сайта для его поднятия на первые позиции в поисковой выдаче
							</label>
						</div>
					</div>

					<p className={s.errorText}>
						{errors['Что такое контекстная реклама в Яндекс?'] ? 'Необходимо указать' : ''}
					</p>
				</div>

				<div className={s.form_item}>
					<h4 className={s.form_title}>36. За что я плачу в контекстной рекламе?</h4>

					<div className={s.question36}>
                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question36,
								})}
								{...register('За что я плачу в контекстной рекламе?')}
								id='exam361'
								type='radio'
								name='За что я плачу в контекстной рекламе?'
								autoComplete='off'
								value='За показы объявлений'
							/>
							<label htmlFor='exam361' className={s.label}>
							За показы объявлений
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question36,
								})}
								{...register('За что я плачу в контекстной рекламе?')}
								id='exam362'
								type='radio'
								name='За что я плачу в контекстной рекламе?'
								autoComplete='off'
								value='За клики по объявлениям'
							/>
							<label htmlFor='exam362' className={s.label}>
							За клики по объявлениям
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question36,
								})}
								{...register('За что я плачу в контекстной рекламе?')}
								id='exam363'
								type='radio'
								name='За что я плачу в контекстной рекламе?'
								autoComplete='off'
								value='За звонки'
							/>
							<label htmlFor='exam363' className={s.label}>
							За звонки
							</label>
						</div>
					</div>

					<p className={s.errorText}>
						{errors['За что я плачу в контекстной рекламе?'] ? 'Необходимо указать' : ''}
					</p>
				</div>

                <div className={s.form_item}>
					<h4 className={s.form_title}>37. Имеет ли значение средняя позиция показа объявлений в контекстной рекламе?</h4>

					<div className={s.question37}>
                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question37,
								})}
								{...register('Имеет ли значение средняя позиция показа объявлений в контекстной рекламе?')}
								id='exam371'
								type='radio'
								name='Имеет ли значение средняя позиция показа объявлений в контекстной рекламе?'
								autoComplete='off'
								value='Да, необходимо следить за средней позицией показа, чтобы показываться на верхних строках поисковой выдачи'
							/>
							<label htmlFor='exam371' className={s.label}>
							Да, необходимо следить за средней позицией показа, чтобы показываться на верхних строках поисковой выдачи
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question37,
								})}
								{...register('Имеет ли значение средняя позиция показа объявлений в контекстной рекламе?')}
								id='exam372'
								type='radio'
								name='Имеет ли значение средняя позиция показа объявлений в контекстной рекламе?'
								autoComplete='off'
								value='Нет, на любой позиции показа будут клиенты'
							/>
							<label htmlFor='exam372' className={s.label}>
							Нет, на любой позиции показа будут клиенты
							</label>
						</div>
					</div>

					<p className={s.errorText}>
						{errors['Имеет ли значение средняя позиция показа объявлений в контекстной рекламе?'] ? 'Необходимо указать' : ''}
					</p>
				</div>

				<div className={s.btns}>
        <Button callback={() => dispatch(setAddStep(12))} back>
						{width > 575 ? (
							'   Назад'
						) : (
							<img src='/static/lk/IconBackward.svg' alt='назад' />
						)}
					</Button>
					<Button>Далее 13 из 35</Button>
				</div>
			</Form>

			<img
				className={s.illustration}
				src='/static/lk/illustration/illustration.png'
				alt='Иллюстрация'
			/>
		</div>
	);
};

export default Step13;
