import './button.scss'

const Button = ({inputRef, children, onCkick, className, disabled}) => {
   return (
      <button disabled={disabled} ref={inputRef} className={className} onClick={onCkick}>
         {children}
      </button>
   );
}

export default Button;