import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getlkBookPaymentForm, saveRequest } from '../../../../../Api';
import { selectLKAuth } from '../../../../../store/reducer/lk/authChech/selector.js';
import { selectLkBook } from '../../../../../store/reducer/lk/book/selector.js';
import {
	setAddInfoBook,
	setAddWaitingBook,
	setOpenModal,
} from '../../../../../store/reducer/lk/book/slice';

import s from './modal.module.scss';

const Modal = () => {
	const [width, setWidth] = useState(window?.innerWidth);
	const { isModal, InfoBook, waitingBook } = useSelector(selectLkBook);
	const { user } = useSelector(selectLKAuth);
	const [email, setEmail] = useState('');

	const dispatch = useDispatch();

	const modalRef = useRef(null);
	const overlayRef = useRef(null);

	const options = {
		day: 'numeric',
		month: 'long',
	};

	const endSale = InfoBook?.payment?.date_end_sale

	const date = new Date(endSale);
	const dateFormat = date.toLocaleDateString('ru-RU', options);

	const newPrice = InfoBook?.payment?.sum;
	const oldPrice = InfoBook?.payment?.old_sum;
	const status = InfoBook?.payment?.status;

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

	useEffect(() => {
		fetchInfoBook();
	}, [status]);

	useEffect(() => {
		if (waitingBook && status !== 'paid') {
			updateDate();
		}
	}, [waitingBook, status]);

	useEffect(() => {
		const handleClickBody = e => {
			const path = e.path || (e.composedPath && e.composedPath());

			if (
				path.includes(overlayRef.current) &&
				!path.includes(modalRef.current)
			) {
				hideModal();
			}
		};

		document.body.addEventListener('click', handleClickBody);

		return () => document.body.removeEventListener('click', handleClickBody);
	}, []);

	useEffect(() => {
		const el = document.getElementById('bookPaymentButton');

		if (
			email.match(/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/)
		) {
			el && el.removeAttribute('disabled');
			el && (el.style.background = '#3357ff');
		} else {
			setTimeout(() => {
				el && el.setAttribute('disabled', 'disabled');
			});
			el && (el.style.background = '#a2a9b4');
		}

		const cb = () => {
			saveRequest('book_email', email, user?.user?.code);
			setEmail('');
			fetchInfoBook();

			setTimeout(() => {
				dispatch(setAddWaitingBook(true));
				localStorage.setItem('waitingBook', JSON.stringify(true));
			});
		};

		el?.addEventListener('click', cb);

		return () => el?.removeEventListener('click', cb);
	}, [email]);

	const hideModal = () => {
		dispatch(setOpenModal(false));
		document.body.style.overflow = '';
		document.body.removeAttribute('style');
		localStorage.setItem('isModal', JSON.stringify(false));
	};

	const fetchInfoBook = async timeId => {
		try {
			const { data } = await getlkBookPaymentForm(user?.user?.code);

			dispatch(setAddInfoBook(data));

			if (data?.payment?.status === 'paid') {
				localStorage.removeItem('waitingBook');
				dispatch(setAddWaitingBook(false));
				clearInterval(timeId);
			}
		} catch (e) {
			console.log(e);
			localStorage.removeItem('waitingBook');
			dispatch(setAddWaitingBook(false));
			clearInterval(timeId);
			setEmail('');
		}
	};

	const updateDate = () => {
		let timeId = setInterval(() => {
			fetchInfoBook(timeId);
		}, 5000);
	};


	return (
		<div
			ref={overlayRef}
			className={cn(s.overlay, {
				[s.overlay_active]: isModal,
			})}
		>
			<div ref={modalRef} className={s.modal}>
				<img
					onClick={hideModal}
					className={s.close}
					src='/static/icons/cross.png'
					alt='закрыть'
				/>
				{(status === 'waiting' || !status) && !waitingBook && (
					<div className={s.content}>
						<div className={s.wrapper_title}>
							<img src='/static/lk/iconTime.svg' alt='Иконка' />
							{width > 575 ? (
								<h4 className={s.subtitle}>Покупка электронной книги</h4>
							) : (
								<h4 className={s.subtitle}>
									Покупка <br></br> электронной книги
								</h4>
							)}
						</div>

						<h2 className={s.title}>Геном предпринимателя</h2>

						<p className={s.descr}>
							Книга будет доступна в личном кабинете и отправлена на почту после
							оплаты
						</p>

						{width > 575 &&
							(oldPrice != newPrice ? (
								<div className={s.price}>
									<span className={s.price_new}>{InfoBook?.payment?.sum}₽</span>
									<span className={s.price_old}>
										{InfoBook?.payment?.old_sum}₽
									</span>

									<span className={s.sale}>50% скидка до {dateFormat}</span>
								</div>
							) : (
								<div className={s.price}>
									<span className={s.price_new}>
										{InfoBook?.payment?.old_sum}₽
									</span>
								</div>
							))}

						<div className={s.wrapper}>
							<div className={s.input}>
								<input
									onChange={e => setEmail(e.target.value)}
									value={email}
									name='email'
									required
									autoComplete='off'
									placeholder='email@email.ru'
								/>
							</div>

							<div
								className={s.formApi}
								dangerouslySetInnerHTML={{ __html: InfoBook?.payment?.form }}
							/>
						</div>
					</div>
				)}

				{waitingBook && (
					<div className={s.content}>
						<h2 className={s.title_waiting}>Выполняется процесс оплаты</h2>
					</div>
				)}

				{status === 'paid' && !waitingBook && (
					<div className={s.content}>
						<h2 className={s.title_success}>Оплата прошла успешно!</h2>

						{
							<a
								href='https://skilla.ru/get-book/'
								target='_blank'
								className={s.btn_success}
							>
								Читать книгу
							</a>
						}
					</div>
				)}
				{status === 'failure' && !waitingBook && (
					<div className={s.content}>
						<h2 className={s.title_failure}>
							Не удалось получить информацию об оплате!
						</h2>

						<button className={s.btn_failure}>Попробовать снова</button>
					</div>
				)}

				{status === 'waiting' && (
					<img
						src={
							width > 575
								? '/static/lk/book/forModal.png'
								: '/static/lk/book/forModal-mobile.png'
						}
						alt='изображение'
					/>
				)}

				{width <= 575 &&
					status !== 'paid' &&
					status !== 'failure' &&
					!waitingBook &&
					(oldPrice != newPrice ? (
						<div className={s.price}>
							<span className={s.price_new}>{InfoBook?.payment?.sum}₽</span>
							<span className={s.price_old}>{InfoBook?.payment?.old_sum}₽</span>

							<span className={s.sale}>50% скидка до {dateFormat}</span>
						</div>
					) : (
						<div className={s.price}>
							<span className={s.price_new}>{InfoBook?.payment?.old_sum}₽</span>
						</div>
					))}

				{(status === 'paid' || status === 'failure') && (
					<img
						src={
							width > 575
								? '/static/lk/book/forModal-2.png'
								: '/static/lk/book/forModal-2-mobile.png'
						}
						alt='изображение'
					/>
				)}
			</div>
		</div>
	);
};

export default Modal;
