import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLkАccessInformation, getViewMenu } from '../../../Api';
import { selectLkAccess } from '../../../store/reducer/lk/access/selector.js';
import { setAddInformation } from '../../../store/reducer/lk/access/slice.js';
import { selectLKAuth } from '../../../store/reducer/lk/authChech/selector.js';
import { selectLKContract } from '../../../store/reducer/lk/contract/selector';
import { selectLKSidebar } from '../../../store/reducer/lk/sidebar/selector';
import { selectLKPayment } from '../../../store/reducer/lk/payment/selector';
import { setIsCheckFromPage } from '../../../store/reducer/lk/contract/slice.js';
import s from './access.module.scss';
import Done from './screens/done/Done';
import Success from './screens/success/Success';
import Waiting from './screens/waiting/Waiting';

const Access = memo(({ endDatePremium }) => {
	const [width, setWidth] = useState(window?.innerWidth);
	const [clientWithoutCours, setClientWithoutCours] = useState(false)
	const { user } = useSelector(selectLKAuth);
	const { activeLink, socketData } = useSelector(selectLKSidebar);
	const { information } = useSelector(selectLkAccess);
	const dispatch = useDispatch();

	const { persent } = useSelector(selectLKContract);
	const { paymentInfo } = useSelector(selectLKPayment);
	const statusPayment = paymentInfo?.payment?.status;
	const status = information?.access?.status;

	useEffect(() => {
		user?.user?.menu[7].name == 'Договор-оферта' ? setClientWithoutCours(true) : setClientWithoutCours(false)
	  }, [user?.user?.menu[7].name])


	useEffect(() => {
		const checkViewMenu = async () => {
			try {
				const { data } = await getViewMenu(user?.user?.code, /* activeLink */10);
			} catch (e) {
				console.log(e);
			}
		};

		checkViewMenu();
	}, []);

	useEffect(() => {
		const getAccessInfo = async () => {
			const { data } = await getLkАccessInformation(user?.user?.code);

			dispatch(setAddInformation(data));
			localStorage.setItem('information', JSON.stringify(data));
		};

		getAccessInfo();
	}, [socketData]);

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);
		window.scroll(0, 0);

		return () => window.removeEventListener('resize', cb);
	}, []);

	const toGoVipPage = () => {
		dispatch(setIsCheckFromPage(true));
		localStorage.setItem('isCheckFromPage', JSON.stringify(true));
		window.open('/premium', '_blank');
	};


	return (
		<>
			{statusPayment &&
				persent &&
				statusPayment !== 'prepaid' &&
				statusPayment !== 'paid' &&
				persent <= 4 &&
				endDatePremium &&
				(width > 575 ? (
					<header onClick={toGoVipPage} className={s.header}>
						<img src='/static/lk/background.png' alt='Баннер' />

						<div className={s.header_btn}>Узнать больше</div>
					</header>
				) : (
					<header className={s.header}>
						<div onClick={toGoVipPage}>
							<img
								style={{ width: '100%' }}
								src='/static/lk/background-mobile.png'
								alt='Баннер'
							/>
						</div>
					</header>
				))}
			<div className={s.access}>
				{status === 'waiting' && <Waiting width={width} clientWithoutCours={clientWithoutCours}/>}
				{status === 'required_confirm' && <Success width={width} user={user} clientWithoutCours={clientWithoutCours}/>}
				{status === 'confirmed' && (
					<Done width={width} information={information} clientWithoutCours={clientWithoutCours}/>
				)}
			</div>
		</>
	);
});

export default Access;
