import React, { useState } from 'react'
import styled from 'styled-components'
import ReactInputMask from 'react-input-mask'

const ModalWindow = ({
  isOpen,
  onRequestClose,
  submitModalForm,
  inn,
  setInn,
  phone,
  onPhoneNumber,
  phoneError,
}) => {
  const [companyName, setCompanyName] = useState('')
  const [kpp, setKpp] = useState('')
  const [ogrn, setOgrn] = useState('')
  const [address, setAddress] = useState('')
  const [lastName, setLastName] = useState('')
  const [firstName, setFirstName] = useState('')
  const [patronymic, setPatronymic] = useState('')
  const [email, setEmail] = useState('')

  const handleModalSubmit = (e) => {
    e.preventDefault()

    // Собираем данные из полей формы модального окна
    const modalData = {
      companyName,
      kpp,
      ogrn,
      address,
      lastName,
      firstName,
      patronymic,
      email,
    }

    // Передаем собранные данные в функцию submitModalForm
    submitModalForm(modalData)
  }

  return isOpen ? (
    <StyledForm>
      <div className="modal-overlay">
        <div className="modal-content">
          <div className="modal_text">
            <h2 className="modal_head">Создание личного кабинета</h2>
            <p className="modal_desk"></p>
          </div>
          <button onClick={onRequestClose} className="modal-close-button">
            <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.5 1L1.5 18M1.5 1L18.5 18" stroke="#ADBFDF" strokeWidth="2" />
            </svg>
          </button>
          <form onSubmit={handleModalSubmit} className="modal-form">
            <input
              type="text"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              placeholder="Выбор компании"
              required
            />
            <input
              type="text"
              value={inn}
              onChange={(e) => setInn(e.target.value)}
              placeholder="ИНН"
              required
            />
            <input
              type="text"
              value={kpp}
              onChange={(e) => setKpp(e.target.value)}
              placeholder="КПП"
              required
            />
            <input
              type="text"
              value={ogrn}
              onChange={(e) => setOgrn(e.target.value)}
              placeholder="ОГРН"
              required
            />
            <input
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder="Юридический адрес"
              required
            />
            <input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder="Фамилия"
              required
            />
            <input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="Имя"
              required
            />
            <input
              type="text"
              value={patronymic}
              onChange={(e) => setPatronymic(e.target.value)}
              placeholder="Отчество"
              required
            />
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="E-mail"
              required
            />
            <ReactInputMask
              className={phoneError ? 'header__input error' : 'header__input'}
              mask="+7 (\999) 999-99-99"
              maskChar=""
              alwaysShowMask={false}
              placeholder="+7 (___) ___-__-__"
              value={phone}
              required
              style={{ color: '#fff' }}
              onChange={({ target: { value } }) => onPhoneNumber(value)}
            />
            <button type="submit" className="modal-submit-button">
              Зарегистрировать заказчика
            </button>
          </form>
        </div>
      </div>
    </StyledForm>
  ) : null
}

const StyledForm = styled.div`
  .modal_text {
    padding-left: 27px;
    color: white;
    margin-bottom: 20px;
  }
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //background: rgba(0, 0, 0, 0.5);
    background: #ffffff33;

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .modal-content {
    background: #00104a;
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    width: 100%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    position: relative;
  }

  .modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #ececec;
  }

  .modal-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .modal-form input {
    width: 90%;
    background: #000e3f;
    color: #ececec;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #333e66;
    border-radius: 4px;

    font-size: 16px;
  }

  .modal-submit-button {
    width: 100%;
    padding: 15px;
    background-color: #002cfb;
    color: #9397a6;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .modal-submit-button:hover {
    background-color: #0055cc;
  }
`

export default ModalWindow
