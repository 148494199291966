import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLkPaymentInformation } from '../../../../../Api';
import { selectLKAuth } from '../../../../../store/reducer/lk/authChech/selector';
import { setAddPaymentInfo } from '../../../../../store/reducer/lk/payment/slice.js';
import { setAddActiveLink } from '../../../../../store/reducer/lk/sidebar/slice';
import SpinnerWaiting from './spinner/SpinnerWaiting';
import s from './waiting.module.scss';

const Waiting = () => {
	const { user } = useSelector(selectLKAuth);
	const dispatch = useDispatch();

	let interval = null;

	useEffect(() => {
		updateDate();
	}, []);

	const updateDate = () => {
		interval = setInterval(() => {
			getPaymentInfo();
		}, 2000);
	};

	const stopInterval = int => {
		clearInterval(int);
	};

	const returnPage = () => {
		stopInterval(interval);
		window.location.href = '/lk';
		dispatch(setAddActiveLink(8));
		localStorage.setItem('activeLink', JSON.stringify(8));
	};

	const getPaymentInfo = async () => {
		try {
			const { data } = await getLkPaymentInformation(user?.user?.code);
			dispatch(setAddPaymentInfo(data));

			data?.payment?.status !== 'waiting' && returnPage();
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<div className={s.waiting}>
			<a onClick={returnPage} className={s.return}>
				Вернуться в личный кабинет
			</a>

			<div className={s.container}>
				<div className={s.wrapper_title}>
					<h4 className={s.subtitle_done}>Ожидаем поступления средств</h4>
				</div>
				<SpinnerWaiting />
			</div>
		</div>
	);
};

export default Waiting;
