export const firstSection = [
	'Перечисляй в Скилла 4 вместо 6% от ежемесячной выручки (по договорам с процентами)',
	'Бесплатно передавай все звонки от исполнителей в Контактный-центр Скилла',
	'Бесплатно получай подготовку и сдачу налоговой, бухгалтерской отчетности профессиональным бухгалтером в Скилла',
];

export const secondSection = [
	'Безусловный приоритет в чате поддержки',
	'Закрепление одного бизнес-ассистента по выбору и изменение в любое время',
	'48 занятий в год в формате живых-вебинаров с возможностью задать вопрос эксперту и домашними заданиями (1 занятие в неделю)',
	'6 занятий в год с топ-менеджерами или мастер-классы от основателя Скилла',
	'Работа с психологом-коучем (1 занятие/мес)',
];

export const thirdSection = [
	'Бизнес-завтраки в городах России (5 встреч в год)',
	'Совместный отдых в мини-командах (загородный отдых на выходные, спортивные мероприятия, игры в Мафию, Монополию, Денежный поток)',
	'Совместные поездки заграницу: Турция, Египет, Сейшелы, Кипр и т.д.',
	'Поездки по России: Роза хутор в Сочи, рыбалка на Камчатке, поход на озеро Байкал, северное сияние в Мурманске, полет на вертолетах в Карелию, баня в Алтае и т.д.',
	'Корпоративная баня в разных городах: Москва, Санкт-Петербург, Новосибирск, Нижний Новгород, Казань',
	'Горнолыжный отдых: Грузия, Турция (Эрзурум), Австрия и т.д.',
];

export const fourthSection = [
	'Организация всех поездок (заказ и выкуп билетов, подбор трансфера, бронирование отелей)',
	'Организация фотосъемок и личных фотосессий во время отдыха и заграничных путешествий',
	'Работа с пожеланиями при планировании путешествий (выбор страны, города, даты мероприятий)',
	'Решение любых вопросов на месте персональным event-менеджером',
];

export const fifthSection = [
	'Работа в мини-командах',
	'Личный опыт из первых рук',
	'Обратная связь по развитию бизнеса',
	'Курирование модератором',
];
