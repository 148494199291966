import cn from 'classnames'
import s from '../steps.module.scss'

import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'
import { setAddData, setAddStep } from '../../../../../store/reducer/lk/formExam/slice'

import { selectLkFormExam } from '../../../../../store/reducer/lk/formExam/selector'
import Button from '../../Button/Button'
import Form from '../../Form'

const Step2 = () => {
  const [width, setWidth] = useState(window?.innerWidth)
  const dispatch = useDispatch()
  const { data, step } = useSelector(selectLkFormExam)
  const [isError4, setError4] = useState(false)
  const [isError5, setError5] = useState(false)
  const [isSendForm, setIsSendForm] = useState(false)

  useEffect(() => {
    localStorage.setItem('stepLkExam', JSON.stringify(step))
    localStorage.setItem('dataExam', JSON.stringify(data))
  }, [])

  useEffect(() => {
    const cb = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', cb)

    return () => window.removeEventListener('resize', cb)
  }, [])

  const schema6 = yup.object({
    ['Какие услуги мы можем оказывать:']: yup.string().required(),
  })

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm({
    resolver: yupResolver(schema6),
    defaultValues: {
      ['Почему клиенты выбирают нас?:']: data['Почему клиенты выбирают нас?:'],
      ['Почему проще начинать бизнес с предоставления именно низкоквалифицированного персонала?']:
        data['Почему проще начинать бизнес с предоставления именно низкоквалифицированного персонала?'],
      ['Какие услуги мы можем оказывать:']: data['Какие услуги мы можем оказывать:'],
    },
  })

  const ckbox4 = watch('Почему клиенты выбирают нас?:')
  const ckbox5 = watch(
    'Почему проще начинать бизнес с предоставления именно низкоквалифицированного персонала?',
  )
  localStorage.removeItem('data')

  useEffect(() => {
    if (!ckbox4?.length) {
      setError4(true)
    } else {
      setError4(false)
    }
  }, [ckbox4])

  useEffect(() => {
    if (!ckbox5?.length) {
      setError5(true)
    } else {
      setError5(false)
    }
  }, [ckbox5])

  const onSubmit = (data) => {
    setIsSendForm(true)

    if (!isError4 && !isError5) {
      dispatch(setAddStep(3))
      dispatch(setAddData(data))
      setIsSendForm(false)

      localStorage.setItem('dataExam', JSON.stringify(data))
      localStorage.setItem('stepLkExam', JSON.stringify(2))
    }
  }

  return (
    <div className={s.step2}>
      <div className={s.wrapper_heading}>
        <h5>Скилла. Бизнес-модель</h5>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={s.form_item}>
          <h4 className={s.form_title}>4. Почему клиенты выбирают нас?</h4>

          <div className={s.question4}>
            <div className={s.checkbox}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question4,
                })}
                {...register('Почему клиенты выбирают нас?:')}
                id="exam41"
                type="checkbox"
                name="Почему клиенты выбирают нас?:"
                autoComplete="off"
                value="Экономия содержания работников"
              />
              <label htmlFor="exam41" className={s.label}>
                Экономия содержания работников
              </label>
            </div>

            <div className={s.checkbox}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question4,
                })}
                {...register('Почему клиенты выбирают нас?:')}
                id="exam42"
                type="checkbox"
                name="Почему клиенты выбирают нас?:"
                autoComplete="off"
                value="Перекладывание материальной ответственности"
              />
              <label htmlFor="exam42" className={s.label}>
                Перекладывание материальной ответственности
              </label>
            </div>

            <div className={s.checkbox}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question4,
                })}
                {...register('Почему клиенты выбирают нас?:')}
                id="exam43"
                type="checkbox"
                name="Почему клиенты выбирают нас?:"
                autoComplete="off"
                value="Приятный голос у менеджера"
              />
              <label htmlFor="exam43" className={s.label}>
                Приятный голос у менеджера
              </label>
            </div>

            <div className={s.checkbox}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question4,
                })}
                {...register('Почему клиенты выбирают нас?:')}
                id="exam44"
                type="checkbox"
                name="Почему клиенты выбирают нас?:"
                autoComplete="off"
                value="Экономия на налогах"
              />
              <label htmlFor="exam44" className={s.label}>
                Экономия на налогах
              </label>
            </div>

            <div className={s.checkbox}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question4,
                })}
                {...register('Почему клиенты выбирают нас?:')}
                id="exam45"
                type="checkbox"
                name="Почему клиенты выбирают нас?:"
                autoComplete="off"
                value="Яркие и красивые картинки на сайте"
              />
              <label htmlFor="exam45" className={s.label}>
                Яркие и красивые картинки на сайте
              </label>
            </div>

            <div className={s.checkbox}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question4,
                })}
                {...register('Почему клиенты выбирают нас?:')}
                id="exam46"
                type="checkbox"
                name="Почему клиенты выбирают нас?:"
                autoComplete="off"
                value="Проектные работы. Сезонность"
              />
              <label htmlFor="exam46" className={s.label}>
                Проектные работы. Сезонность
              </label>
            </div>

            <p className={s.errorText2}>{isError4 && isSendForm && 'Необходимо указать'}</p>
          </div>
        </div>

        <div className={s.form_item}>
          <h4 className={s.form_title}>
            5. Почему проще начинать бизнес с предоставления именно низкоквалифицированного персонала?
          </h4>

          <div className={s.question5}>
            <div className={s.checkbox}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question5,
                })}
                {...register(
                  'Почему проще начинать бизнес с предоставления именно низкоквалифицированного персонала?',
                )}
                id="exam51"
                type="checkbox"
                name="Почему проще начинать бизнес с предоставления именно низкоквалифицированного персонала?"
                autoComplete="off"
                value="Проще контроль за итогами работы"
              />
              <label htmlFor="exam51" className={s.label}>
                Проще контроль за итогами работы
              </label>
            </div>

            <div className={s.checkbox}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question5,
                })}
                {...register(
                  'Почему проще начинать бизнес с предоставления именно низкоквалифицированного персонала?',
                )}
                id="exam52"
                type="checkbox"
                name="Почему проще начинать бизнес с предоставления именно низкоквалифицированного персонала?"
                autoComplete="off"
                value="Отсутствие специальных требований к началу работы"
              />
              <label htmlFor="exam52" className={s.label}>
                Отсутствие специальных требований к началу работы
              </label>
            </div>

            <div className={s.checkbox}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question5,
                })}
                {...register(
                  'Почему проще начинать бизнес с предоставления именно низкоквалифицированного персонала?',
                )}
                id="exam53"
                type="checkbox"
                name="Почему проще начинать бизнес с предоставления именно низкоквалифицированного персонала?"
                autoComplete="off"
                value="Универсальность"
              />
              <label htmlFor="exam53" className={s.label}>
                Универсальность
              </label>
            </div>

            <div className={s.checkbox}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question5,
                })}
                {...register(
                  'Почему проще начинать бизнес с предоставления именно низкоквалифицированного персонала?',
                )}
                id="exam54"
                type="checkbox"
                name="Почему проще начинать бизнес с предоставления именно низкоквалифицированного персонала?"
                autoComplete="off"
                value="Выше маржинальность"
              />
              <label htmlFor="exam54" className={s.label}>
                Выше маржинальность
              </label>
            </div>

            <div className={s.checkbox}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question5,
                })}
                {...register(
                  'Почему проще начинать бизнес с предоставления именно низкоквалифицированного персонала?',
                )}
                id="exam55"
                type="checkbox"
                name="Почему проще начинать бизнес с предоставления именно низкоквалифицированного персонала?"
                autoComplete="off"
                value="Высокая ответственность исполнителей"
              />
              <label htmlFor="exam55" className={s.label}>
                Высокая ответственность исполнителей
              </label>
            </div>

            <div className={s.checkbox}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question5,
                })}
                {...register(
                  'Почему проще начинать бизнес с предоставления именно низкоквалифицированного персонала?',
                )}
                id="exam56"
                type="checkbox"
                name="Почему проще начинать бизнес с предоставления именно низкоквалифицированного персонала?"
                autoComplete="off"
                value="Отсутствие предпринимательских рисков"
              />
              <label htmlFor="exam56" className={s.label}>
                Отсутствие предпринимательских рисков
              </label>
            </div>

            <p className={s.errorText2}>{isError5 && isSendForm && 'Необходимо указать'}</p>
          </div>
        </div>

        <div className={s.form_item}>
          <h4 className={s.form_title}>6. Какие услуги мы можем оказывать:</h4>

          <div className={s.question6}>
            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question6,
                })}
                {...register('Какие услуги мы можем оказывать:')}
                id="exam61"
                type="radio"
                name="Какие услуги мы можем оказывать:"
                autoComplete="off"
                value="Услуги оператора поломоечнной машины в ТРК"
              />

              <label htmlFor="exam61" className={s.label}>
                Услуги оператора поломоечнной машины в ТРК
              </label>
            </div>

            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question6,
                })}
                {...register('Какие услуги мы можем оказывать:')}
                id="exam62"
                type="radio"
                name="Какие услуги мы можем оказывать:"
                autoComplete="off"
                value="Высадка растений, оформление клумб"
              />
              <label htmlFor="exam62" className={s.label}>
                Высадка растений, оформление клумб
              </label>
            </div>

            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question6,
                })}
                {...register('Какие услуги мы можем оказывать:')}
                id="exam63"
                type="radio"
                name="Какие услуги мы можем оказывать:"
                autoComplete="off"
                value="Сборка и комплектование товаров к доставке"
              />
              <label htmlFor="exam63" className={s.label}>
                Сборка и комплектование товаров к доставке
              </label>
            </div>

            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question6,
                })}
                {...register('Какие услуги мы можем оказывать:')}
                id="exam64"
                type="radio"
                name="Какие услуги мы можем оказывать:"
                autoComplete="off"
                value="Сбор урожая"
              />
              <label htmlFor="exam64" className={s.label}>
                Сбор урожая
              </label>
            </div>

            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question6,
                })}
                {...register('Какие услуги мы можем оказывать:')}
                id="exam65"
                type="radio"
                name="Какие услуги мы можем оказывать:"
                autoComplete="off"
                value="Техническая помощь на мероприятиях"
              />
              <label htmlFor="exam65" className={s.label}>
                Техническая помощь на мероприятиях
              </label>
            </div>

            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question6,
                })}
                {...register('Какие услуги мы можем оказывать:')}
                id="exam66"
                type="radio"
                name="Какие услуги мы можем оказывать:"
                autoComplete="off"
                value="Все варианты верны"
              />
              <label htmlFor="exam66" className={s.label}>
                Все варианты верны
              </label>
            </div>
          </div>

          <p className={s.errorText}>
            {errors['Какие услуги мы можем оказывать:'] ? 'Необходимо указать' : ''}
          </p>
        </div>

        <div className={s.btns}>
          <Button callback={() => dispatch(setAddStep(1))} back>
            {width > 575 ? '   Назад' : <img src="/static/lk/IconBackward.svg" alt="назад" />}
          </Button>
          <Button>Далее 2 из 35</Button>
        </div>
      </Form>

      <img className={s.illustration} src="/static/lk/illustration/illustration.png" alt="Иллюстрация" />
    </div>
  )
}

export default Step2
