import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAuthCheck, getAuthLk } from '../../../../Api';
import { Spinner } from '../../../../components/spinner/Spinner';
import { selectLKAuth } from '../../../../store/reducer/lk/authChech/selector';
import {
	setAddAuth,
	setAddUser,
	setHelpSupport,
	setAddPageName
} from '../../../../store/reducer/lk/authChech/slice';
import { setAddActiveLink } from '../../../../store/reducer/lk/sidebar/slice.js';
import Button from '../Button/Button';
import s from './start.module.scss';

const Start = () => {
	const [width, setWidth] = useState(window?.innerWidth);
	const [showMobileBtn, setShowMobileBtn] = useState(false);
	const [valueInputLength, setValueInputLength] = useState(0);
	const [count, setCount] = useState(30);
	const [smsFailure, setSmsFailure] = useState(false);
	const [smsRepeat, setSmsRepeat] = useState(false);
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);

	const { user, phoneNumber, helpSupport, phoneСonfirmed, namePage } =
		useSelector(selectLKAuth);
	const dispatch = useDispatch();

	const inputRef = useRef(null);
	const navigate = useNavigate();

	useEffect(() => {
		dispatch(setAddPageName('start'))
	}, [])


	useEffect(() => {
		handleSmsCode();
	}, []);

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);
		window.scroll(0, 0);

		return () => window.removeEventListener('resize', cb);
	}, []);

	const stopCount = interval => {
		setSmsRepeat(true);
		clearInterval(interval);
		setCount(30);
	};

	const handleSmsCode = () => {
		const interval = setInterval(() => {
			setCount(prev => (prev !== 0 ? prev - 1 : stopCount(interval)));
		}, 1000);
	};

	const getSmsCode = async phoneNumber => {
		handleSmsCode();
		setSmsRepeat(false);
		try {
			const { data } = await getAuthLk(phoneNumber);

			if (data.result === 'failure') {
				setSmsFailure(true);
			}
		} catch (e) {
			console.log(e);
		}
	};

	const { register, handleSubmit, reset } = useForm({});

	const getLastLinkMenu = data => {
		const menuLinks = [];

		for (let key in data) {
			menuLinks.push(data[key]);
		}

		const result = menuLinks?.filter(
			item => item.status !== 'disabled' && item.status !== 'hidden'
		);
		const lastLink = result.at(-1);

		dispatch(setAddActiveLink(lastLink?.id));
		localStorage.setItem('activeLink', JSON.stringify(lastLink?.id));
	};

	const checkInputLength = (input, input2, input3, input4) => {
		setValueInputLength(input + input2 + input3 + input4);

		if (input || input2 || input3 || input4) {
			setError(false);
		}
	};

	const onSubmit = ({ input, input2, input3, input4 }) => {
		input && input2 && input3 && input4 && width > 575 && setLoading(true);
		input && width <= 575 && setLoading(true);

		const getAuth = async () => {
			try {
				const { data } = await getAuthCheck(
					
					phoneNumber,
					width > 575 ? input + input2 + input3 + input4 : input
				);
				
				if (width > 575 ? input && input2 && input3 && input4 : input) {
					if (data.result === 'success') {
						dispatch(setAddUser(data));
						console.log(data)
						if (data?.user?.menu?.['1']?.status === 'finished') {
							getLastLinkMenu(data?.user?.menu);
						} else {
							dispatch(setAddActiveLink(1));
							localStorage.setItem('activeLink', JSON.stringify(1));
						}

						setError(false);
						setLoading(false);

						localStorage.setItem('user', JSON.stringify(data));
						localStorage.removeItem('auth');
					} else {
						reset();
						setError(true);
						setLoading(false);
						setValueInputLength('');
						setShowMobileBtn(false);
					}
				}
			} catch (e) {
				console.log(e);
			}
		};

		if (width > 575 ? input && input2 && input3 && input4 : input) {
			getAuth();
		}

		checkInputLength(input, input2, input3, input4);
	};

	const maxLengthCheck = x => {
		if (x.value.length > x.maxLength) x.value = x.value.slice(0, x.maxLength);
	};

	function jump(x) {
		const ml = x.getAttribute('maxlength');

		if (ml && x.value.length >= ml) {
			do {
				x = x.nextSibling;
			} while (x && !/text/.test(x.type));

			if (x && /text/.test(x.type)) {
				x.focus();
			}
		}
	}

	function jumpMobile(x) {
		const ml = x.getAttribute('maxlength');

		if (ml && x.value.length >= ml) {
			inputRef.current.focus();
			setShowMobileBtn(true);
		} else {
			setShowMobileBtn(false);
			setError(false);
		}

		maxLengthCheck(x);
	}

	const onClickBack = () => {
		dispatch(setAddAuth(false));
		dispatch(setAddActiveLink(1));
		localStorage.removeItem('activeLink');
		localStorage.removeItem('stepLk');
		localStorage.removeItem('user');
		navigate('/enter');
	};

	return (
		<>
			{loading && <Spinner />}
			<div
				className={classNames(s.start, {
					[s.start_noCode]: helpSupport,
					[s.start_phoneСonfirmed]: phoneСonfirmed,
				})}
			>
				{!user?.user?.code && !helpSupport ? (
					<h1 className={`${s.title} lk-title`}>
						{width > 575 ? 'Введи код из СМС' : 'Код из СМС'}
					</h1>
				) : helpSupport ? (
					<div className={s.noCode}>
						<img src='/static/lk/iconWarning.svg' alt='Иконка' />
						<h1 className={`${s.title} lk-title`}>Не приходит код?</h1>
					</div>
				) : phoneСonfirmed ? (
					<div className={s.noCode}>
						<img src='/static/lk/iconWarning.svg' alt='Иконка' />
						<h1 className={`${s.title} lk-title`}>Телефон уже подтвержден</h1>
					</div>
				) : (
					''
				)}

				{width > 575 && !smsRepeat && !smsFailure ? (
					<p className={s.descr}>
						Запросить повторно <span>{count}</span> сек
					</p>
				) : smsRepeat && width > 575 ? (
					<button onClick={() => getSmsCode(phoneNumber)} className={s.repeat}>
						Запросить повторно
					</button>
				) : width > 575 && smsFailure && !helpSupport ? (
					<Button
						onCkick={() => dispatch(setHelpSupport(true))}
						className={'btn-noSms'}
					>
						Не приходит СМС
					</Button>
				) : (width > 575 && helpSupport) || phoneСonfirmed ? (
					<div className={s.info}>
						<p>
							Позвони по номеру <a href='tel:88003331721'>8 800 333 17 21</a> и
							мы поможем зарегистрироваться
						</p>
					</div>
				) : (
					''
				)}

				{!helpSupport && width > 575 && (
					<form
						onChange={handleSubmit(onSubmit)}
						onSubmit={handleSubmit(onSubmit)}
						className={classNames(s.form)}
					>
						<input
							className={classNames(s.input, {
								[s.error]: error,
							})}
							{...register('input')}
							autoFocus
							name='input'
							type='text'
							onChange={e => jump(e.target)}
							maxLength='1'
							autoComplete='off'
						/>
						<input
							className={classNames(s.input, {
								[s.error]: error && width > 575,
							})}
							{...register('input2')}
							name='input2'
							type='text'
							onChange={e => jump(e.target)}
							maxLength='1'
							autoComplete='off'
						/>
						<input
							className={classNames(s.input, {
								[s.error]: error && width > 575,
							})}
							{...register('input3')}
							name='input3'
							type='text'
							onChange={e => jump(e.target)}
							maxLength='1'
							autoComplete='off'
						/>
						<input
							className={classNames(s.input, {
								[s.error]: error && width > 575,
							})}
							{...register('input4')}
							name='input4'
							type='text'
							maxLength='1'
							autoComplete='off'
						/>

						<div className={s.buttons}>
							<Button onCkick={onClickBack} className={`btn-back`}>
								Назад
							</Button>
							<Button
								className={classNames('btn-send', {
									['disabled']: String(valueInputLength).length !== 4,
									['active']: String(valueInputLength).length === 4,
								})}
							>
								Отправить
							</Button>
						</div>
					</form>
				)}

				{!helpSupport && width <= 575 && (
					<form
						onChange={handleSubmit(onSubmit)}
						onSubmit={handleSubmit(onSubmit)}
						className={classNames(s.form, {
							[s.error]: error,
						})}
					>
						<input
							className={classNames(s.input)}
							{...register('input')}
							autoFocus
							onChange={e => jumpMobile(e.target)}
							name='input'
							type='number'
							maxLength='4'
							autoComplete='off'
						/>

						<div className={s.buttons}>
							<Button onCkick={onClickBack} className={`btn-back`}>
								Назад
							</Button>
							<Button
								inputRef={inputRef}
								className={classNames('btn-send', {
									['disabled']: !showMobileBtn,
									['active']: showMobileBtn,
								})}
							>
								Отправить
							</Button>
						</div>
					</form>
				)}

				{width <= 575 && !smsRepeat && !smsFailure ? (
					<p className={s.descr}>
						Запросить повторно <span>{count}</span> сек
					</p>
				) : smsRepeat && width <= 575 ? (
					<button onClick={() => getSmsCode(phoneNumber)} className={s.repeat}>
						Запросить повторно
					</button>
				) : width <= 575 && smsFailure && !helpSupport ? (
					<Button
						onCkick={() => dispatch(setHelpSupport(true))}
						className={'btn-noSms'}
					>
						Не приходит СМС
					</Button>
				) : (width <= 575 && helpSupport) || phoneСonfirmed ? (
					<>
						<div className={s.info}>
							<p>
								Позвони по номеру <br></br>
								<a href='tel:88003331721'>8 800 333 17 21</a> <br></br> и мы
								поможем зарегистрироваться
							</p>
						</div>

						<img
							className={s.illustration}
							src='/static/lk/illustration/illustrationBad-mobile.png'
							alt='Иллюстрация'
						/>
					</>
				) : (
					''
				)}
			</div>
		</>
	);
};

export default Start;
