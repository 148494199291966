import { memo, useCallback, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import s from './adminPanel.module.scss';

const AdminPanel = memo(({ information }) => {
	const [valueLogin, setValueLogin] = useState(information?.access?.login);
	const [valuePassword, setValuePassword] = useState(
		information?.access?.password
	);
	const [copiedLogin, setCopiedLogin] = useState(false);
	const [copiedPassword, setCopiedPassword] = useState(false);

	const onClickCopyLogin = useCallback(() => {
		setValueLogin(information?.access?.login);
	}, []);

	const onClickCopyPassword = useCallback(() => {
		setValuePassword(information?.access?.password);
	}, []);


	const onCopyLogin = useCallback(() => {
		setCopiedLogin(true);

		setTimeout(() => {
			setCopiedLogin(false);
		}, [2000]);
	}, []);

	const onCopyPassword = useCallback(() => {
		setCopiedPassword(true);

		setTimeout(() => {
			setCopiedPassword(false);
		}, [2000]);
	}, []);

	return (
		<div className={s.panel}>
			<h5>Административная панель</h5>

			<div className={s.item}>
				<p>Ссылка для авторизации</p>

				<a href='https://lk.skilla.ru' target='_blank' className={s.field}>
					https://lk.skilla.ru
				</a>
			</div>

			<div className={s.item}>
				<p>Логин</p>
				<CopyToClipboard onCopy={onCopyLogin} text={valueLogin}>
					<div onClick={onClickCopyLogin} className={s.field}>
						{information?.access?.login}
						{copiedLogin ? (
							<span className={s.copyText}>Скопировано</span>
						) : null}
					</div>
				</CopyToClipboard>
			</div>

			<div className={s.item}>
				<p>Пароль</p>
				<CopyToClipboard onCopy={onCopyPassword} text={valuePassword}>
					<div onClick={onClickCopyPassword} className={s.field}>
						{information?.access?.password}
						{copiedPassword ? (
							<span className={s.copyText}>Скопировано</span>
						) : null}
					</div>
				</CopyToClipboard>
			</div>
		</div>
	);
});

export default AdminPanel;
