import s from './questionary.module.scss';
import Done from './steps/Done/Done';
import Step1 from './steps/Step1/Step1';
import Step2 from './steps/Step2/Step2';
import Step3 from './steps/Step3/Step3';
import Step4 from './steps/Step4/Step4';
import Step5 from './steps/Step5/Step5';
import Step6 from './steps/Step6/Step6';
import Step7 from './steps/Step7/Step7';
import Step8 from './steps/Step8/Step8';

import { memo, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getViewMenu } from '../../../Api';
import { selectLKAuth } from '../../../store/reducer/lk/authChech/selector';
import { selectLkForm } from '../../../store/reducer/lk/form/selector';
import { setAddStep } from '../../../store/reducer/lk/form/slice.js';
import { selectLKSidebar } from '../../../store/reducer/lk/sidebar/selector';

import { selectLKContract } from '../../../store/reducer/lk/contract/selector';
import { setIsCheckFromPage } from '../../../store/reducer/lk/contract/slice.js';
import { selectLKPayment } from '../../../store/reducer/lk/payment/selector';

const Questionary = memo(({ endDatePremium }) => {
	const { user } = useSelector(selectLKAuth);
	const [width, setWidth] = useState(window?.innerWidth);
	const { activeLink } = useSelector(selectLKSidebar);
	const { step, status } = useSelector(selectLkForm);
	const dispatch = useDispatch();
  
	const statusForm = status?.form?.status;

	console.log(status, statusForm)

	const { persent } = useSelector(selectLKContract);
	const { paymentInfo } = useSelector(selectLKPayment);
	const statusPayment = paymentInfo?.payment?.status;

	console.log(localStorage.getItem('stepLk'), step);

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);
		window.scroll(0, 0);

		return () => window.removeEventListener('resize', cb);
	}, []);

	useEffect(() => {
		window.scroll(0, 0);
	}, []);

	useEffect(() => {
		const checkViewMenu = async () => {
			try {
				const { data } = await getViewMenu(user?.user?.code, activeLink);
			} catch (e) {
				console.log(e);
			}
		};

		checkViewMenu();
	}, []);

	useEffect(() => {
		if (step == 9 && !statusForm) {
			dispatch(setAddStep(1));
			localStorage.setItem('stepLk', JSON.stringify(1));
		}
	}, []);
    



	const toGoVipPage = () => {
		dispatch(setIsCheckFromPage(true));
		localStorage.setItem('isCheckFromPage', JSON.stringify(true));
		window.open('/premium', '_blank');
	};

	return (
		<>
			{statusPayment &&
				persent &&
				statusPayment !== 'prepaid' &&
				statusPayment !== 'paid' &&
				persent <= 4 &&
				endDatePremium &&
				(width > 770 ? (
					<header onClick={toGoVipPage} className={s.header}>
						<img src='/static/lk/background.png' alt='Баннер' />

						<div className={s.header_btn}>Узнать больше</div>
					</header>
				) : (
					<header className={s.header}>
						<div onClick={toGoVipPage}>
							<img
								style={{ width: '100%' }}
								src='/static/lk/background-mobile.png'
								alt='Баннер'
							/>
						</div>
					</header>
				))}

			<div className={s.questionary}>
				{step == 1 && !statusForm && <Step1 />}
				{step == 2 && !statusForm && <Step2 />}
				{step == 3 && !statusForm && <Step3 />}
				{step == 4 && !statusForm && <Step4 />}
				{step == 5 && !statusForm && <Step5 />}
				{step == 6 && !statusForm && <Step6 />}
				{step == 7 && !statusForm && <Step7 />}
				{step == 8 && !statusForm && <Step8 />}
				{(step == 9 || statusForm) && <Done />}
			</div>
		</>
	);
});

export default Questionary;
