import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userSlice from "./reducer/userSlice";
import mainSlice from "./reducer/main/slice";
import reviewsSlice from "./reducer/reviews/slice";
import officesSlice from "./reducer/geography/slice";
import formSlice from "./reducer/form/slice";
import formUnsubscribe from "./reducer/formUnsubscribe/slice";
import formWorkerSlice from "./reducer/formWorker/slice";
import lkAuthSlice from "./reducer/lk/authChech/slice";
import lkSidebar from "./reducer/lk/sidebar/slice";
import lkZoom from "./reducer/lk/zoom/slice";
import lkForm from './reducer/lk/form/slice';
import lkFormExam from './reducer/lk/formExam/slice'
import lkContract from './reducer/lk/contract/slice'
import lkPayment from './reducer/lk/payment/slice'
import lkTraining from './reducer/lk/training/slice'
import lkSuccessSlice from './reducer/lk/access/slice'
import lkCalendarSlice from './reducer/lk/calendar/slice'
import lkBookSlice from './reducer/lk/book/slice'

export const rootReducer = combineReducers({
  user: userSlice,
  mainSlice,
  reviewsSlice,
  officesSlice,
  formSlice,
  formUnsubscribe,
  formWorkerSlice,
  lkAuthSlice,
  lkSidebar,
  lkZoom,
  lkForm,
  lkFormExam,
  lkContract,
  lkPayment,
  lkTraining,
  lkSuccessSlice,
  lkCalendarSlice,
  lkBookSlice
});

export const store = configureStore({
  reducer: rootReducer,
});
