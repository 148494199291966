import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { getCities, getDataConfirm } from '../../../../Api';
import { selectLKAuth } from '../../../../store/reducer/lk/authChech/selector';
import { setAddActiveLink } from '../../../../store/reducer/lk/sidebar/slice';
import { setAddPageName } from '../../../../store/reducer/lk/authChech/slice.js'
import Button from '../Button/Button';
import s from './check.module.scss';

const Check = () => {
	const [width, setWidth] = useState(window?.innerWidth);
	const [modalMenu, setModalMenu] = useState(false);
	const [activeCity, setActiveCity] = useState(null);
	const [allCity, setAllCity] = useState(false);
	const [cityArr, setCityArr] = useState([]);
	const [checkBox, setCheckBox] = useState(true);
	const [checkBox2, setCheckBox2] = useState(true);
	console.log()
	const dispatch = useDispatch();
	const { user } = useSelector(selectLKAuth);

	useEffect(() => {
		dispatch(setAddPageName('check'))
	}, [])


	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);
		window.scroll(0, 0);

		return () => window.removeEventListener('resize', cb);
	}, []);

	useEffect(() => {
		const cities = async () => {
			try {
				const { data } = await getCities();

				setCityArr(data);
			} catch (err) {
				console.log(
					'Ошибка ' + err.name + ':' + err.message + '\n' + err.stack
				);
			}
		};

		cities();
	}, []);

	const handleCheck = () => {
		checkBox ? setCheckBox(false) : setCheckBox(true)
	}

	const handleCheck2 = () => {
		checkBox2 ? setCheckBox2(false) : setCheckBox2(true)
	}

	const schema = yup.object({
		name: yup.string().required(),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		watch,
	} = useForm({
		resolver: yupResolver(schema),
	});

	const hasCity = watch('city');

	const onSubmit = ({ name, city }) => {
		const dataConfirm = async code => {
			try {
				const { data } = await getDataConfirm(code, name, city);

				dispatch(setAddActiveLink(2));
			} catch (e) {
				console.log(e);
			}
		};

		dataConfirm(user?.user?.code);
	};

	const showModalMenu = (bol, type, value, i) => {
		setModalMenu(bol);
		setValue(type, value);
		setActiveCity(i);
		setAllCity(false);
	};

	return (
		<div className={s.check}>
			<h1 className={`${s.title} lk-title`}>Проверяем данные</h1>

			<p className={s.descr}>
				{width > 575 ? 'Убедись, что все верно' : 'Все верно?'}
			</p>

			<form onSubmit={handleSubmit(onSubmit)} className={s.form}>
				<div className={s.item}>
					<label className={s.label} htmlFor='name'>
						Имя
					</label>
					<input
						className={cn(s.input, {
							[s.input_error]: errors.name,
						})}
						{...register('name')}
						name='name'
						id='name'
						type='text'
						placeholder='Имя'
						defaultValue={user?.user?.name}
						autoComplete='off'
					/>
				</div>

				<div className={s.item}>
					<label className={s.label} htmlFor='city'>
						Твой город
					</label>
					<div className={s.wrapperInput}>
						<input
							className={s.input}
							{...register('city')}
							onFocus={() => setModalMenu(true)}
							name='city'
							id='city'
							type='text'
							placeholder={user?.user?.city}
							defaultValue={user?.user?.city}
							autoComplete='off'
						/>

						{!allCity ? (
							<svg
								onClick={() => setAllCity(true)}
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path d='M6.5 9L12 15L17.5 9H6.5Z' fill='#71869D' />
							</svg>
						) : (
							<svg
								onClick={() => setAllCity(false)}
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path d='M6.5 15L12 9L17.5 15H6.5Z' fill='#71869D' />
							</svg>
						)}

						{modalMenu && (
							<ul className={s.menu}>
								{cityArr
									?.filter(city =>
										city.name?.toLowerCase().includes(hasCity?.toLowerCase())
									)
									.map((item, i) => (
										<li
											onClick={() => showModalMenu(false, 'city', item.name, i)}
											key={item.name}
											className={cn(s.city, {
												[s.city_active]:
													hasCity == item.name || i == activeCity,
											})}
										>
											{item.name}
										</li>
									))}
							</ul>
						)}

						{allCity && (
							<ul className={s.menu}>
								{cityArr?.map((item, i) => (
									<li
										onClick={() => showModalMenu(false, 'city', item.name, i)}
										key={item.name}
										className={cn(s.city, {
											[s.city_active]: hasCity == item.name,
										})}
									>
										{item.name}
									</li>
								))}
							</ul>
						)}
					</div>
				</div>

				{width > 575 ? (
					<div className={s.container_check}>
						<div className={`${s.checkbox} ${checkBox && s.checkbox_checked}`}>
							<input
								className={s.input_check}
								checked={checkBox}
								id='checkbox1'
								type='checkbox'
								name='checkbox1'
								autoComplete='off'
							/>
							<label onClick={handleCheck}
								htmlFor='checkbox1'
								className={s.label}
							>
								Даю согласие на обработку персональных данных
							</label>
						</div>

						<div className={`${s.checkbox} ${checkBox2 && s.checkbox_checked}`}>
							<input
								className={s.input_check}
								checked={checkBox2}
								id='checkbox2'
								type='checkbox'
								name='checkbox2'
								autoComplete='off'
							/>
							<label onClick={handleCheck2}
								htmlFor='checkbox2'
								className={s.label}
							>
								Соглашаюсь на получение сообщений для входа в Личный кабинет, рекламного типа и статусов
							</label>
						</div>

						<Button disabled={checkBox && checkBox2 ? false : true} className={`btn-material`}>Перейти к материалам</Button>
					</div>

				) : (
					<div className={s.wrapperBtn}>
						<Button disabled={checkBox && checkBox2 ? false : true}>Перейти к материалам</Button>
					</div>
				)}

				{width <= 575 && <div >
						<div className={`${s.checkbox} ${checkBox && s.checkbox_checked}`}>
							<input
								className={s.input_check}
								checked={checkBox}
								id='checkbox1'
								type='checkbox'
								name='checkbox1'
								autoComplete='off'
							/>
							<label onClick={handleCheck}
								htmlFor='checkbox1'
								className={s.label}
							>
								Даю согласие на обработку персональных данных
							</label>
						</div>

						<div className={`${s.checkbox} ${checkBox2 && s.checkbox_checked}`}>
							<input
								className={s.input_check}
								checked={checkBox2}
								id='checkbox2'
								type='checkbox'
								name='checkbox2'
								autoComplete='off'
							/>
							<label onClick={handleCheck2}
								htmlFor='checkbox2'
								className={s.label}
							>
								Соглашаюсь на получение сообщений для входа в Личный кабинет, рекламного типа и статусов
							</label>
						</div>
					</div>}
			</form>
			
		</div>
	);
};

export default Check;
