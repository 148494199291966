import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
	getLkContract,
	getLkContractSign,
	getLkContractSignCheck,
} from '../../../Api';
import { selectLKAuth } from '../../../store/reducer/lk/authChech/selector';
import { setHelpSupport } from '../../../store/reducer/lk/authChech/slice';
import { selectLKContract } from '../../../store/reducer/lk/contract/selector';
import {
	setAddIsOnline,
	setAddIsPersent,
	setAddLoading,
	setAddResultContract,
	setAddShowModalForm,
	setAddSigned,
} from '../../../store/reducer/lk/contract/slice';
import Spinner from '../../Spinner';
import Button from '../Auth/Button/Button';
import s from './smsCode.module.scss';

const SmsCode = () => {
	const [width, setWidth] = useState(window?.innerWidth);
	const [valueInputLength, setValueInputLength] = useState(0);
	const [count, setCount] = useState(30);
	const [smsFailure, setSmsFailure] = useState(false);
	const [smsRepeat, setSmsRepeat] = useState(false);
	const [error, setError] = useState(false);
	const { user, phoneNumber, helpSupport, phoneСonfirmed } =
		useSelector(selectLKAuth);
	const { numberReg, showModalForm, isLoading } = useSelector(selectLKContract);

	const { register, handleSubmit, reset } = useForm({});

	let num = numberReg.slice(16);
	const transformNumber = (num = '*' + num);

	const dispatch = useDispatch();
	const refModal = useRef(null);

	useEffect(() => {
		setCount(30);
		handleSmsCode();
	}, []);

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

	useEffect(() => {
		const handleClickBody = e => {
			const path = e.path || (e.composedPath && e.composedPath());

			if (refModal.current && path && !path.includes(refModal.current)) {
				closeModalForm();
			}
		};

		document.body.addEventListener('click', handleClickBody);

		return () => document.body.removeEventListener('click', handleClickBody);
	}, []);

	function jump(x) {
		const ml = x.getAttribute('maxlength');

		if (ml && x.value.length >= ml) {
			do {
				x = x.nextSibling;
			} while (x && !/text/.test(x.type));

			if (x && /text/.test(x.type)) {
				x.focus();
			}
		}
	}

	const getContractInfo = async () => {
		try {
			const { data } = await getLkContract(user?.user?.code);
			dispatch(setAddSigned(data?.contract?.status));
			dispatch(setAddIsOnline(data?.contract?.is_online));
			dispatch(setAddIsPersent(data?.contract?.persent));
		} catch (e) {
			console.log(e);
		}
	};


	const onSubmit = ({ input, input2, input3, input4 }) => {
		dispatch(setAddLoading(true));

		const contractSignCheck = async () => {
			try {
				const { data } = await getLkContractSignCheck(
					user?.user?.code,
					numberReg,
					input + input2 + input3 + input4
				);

				dispatch(setAddResultContract(data.result));

				if (data.result === 'success') {
					localStorage.removeItem('isContract');
					closeModalForm();
					setError(false);
					getContractInfo();
				} else {
					reset();
					setError(true);
					dispatch(setAddLoading(false));
					setValueInputLength('');
				}
			} catch (e) {
				console.log(e);
			}
		};

		contractSignCheck();
	};

	const getSmsCode = async () => {
		handleSmsCode();
		setSmsRepeat(false);
		try {
			const { data } = await getLkContractSign(user?.user?.code, numberReg);

			if (data.result === 'failure') {
				setSmsFailure(true);
			}
		} catch (e) {
			console.log(e);
		}
	};

	const stopCount = interval => {
		setSmsRepeat(true);
		clearInterval(interval);
		setCount(30);
	};

	const handleSmsCode = () => {
		const interval = setInterval(() => {
			setCount(prev => (prev !== 0 ? prev - 1 : stopCount(interval)));
		}, 1000);
	};

	const checkInputLength = ({ input, input2, input3, input4 }) => {
		setValueInputLength(input + input2 + input3 + input4);

		if (input || input2 || input3 || input4) {
			setError(false);
		}
	};

	const closeModalForm = () => {
		dispatch(setAddLoading(false));
		dispatch(setAddShowModalForm(false));
		setCount(30);
		document.body.classList.remove('overlay');
		
	};

	return (
		<div
			className={classNames(s.overlay, {
				[s.overlay_active]: showModalForm,
			})}
		>
			{!isLoading ? (
				<form
					ref={refModal}
					onChange={handleSubmit(checkInputLength)}
					onSubmit={handleSubmit(onSubmit)}
					className={s.form}
				>
					<div onClick={closeModalForm} className={s.close}>
						<img src='/static/lk/iconClose.svg' alt='закрыть' />
					</div>

					<h4 className={s.title}>Код отправлен на {transformNumber}</h4>
					<div className={s.wrapper}>
						<div className={s.inputs}>
							<input
								className={classNames(s.input, {
									[s.error]: error,
								})}
								{...register('input')}
								name='input'
								type='text'
								onChange={e => jump(e.target)}
								maxLength='1'
								autoComplete='off'
							/>
							<input
								className={classNames(s.input, {
									[s.error]: error,
								})}
								{...register('input2')}
								name='input2'
								type='text'
								onChange={e => jump(e.target)}
								maxLength='1'
								autoComplete='off'
							/>
							<input
								className={classNames(s.input, {
									[s.error]: error,
								})}
								{...register('input3')}
								name='input3'
								type='text'
								onChange={e => jump(e.target)}
								maxLength='1'
								autoComplete='off'
							/>
							<input
								className={classNames(s.input, {
									[s.error]: error,
								})}
								{...register('input4')}
								name='input4'
								type='text'
								maxLength='1'
								autoComplete='off'
							/>
						</div>
						<div className={s.check_code}>
							{!smsRepeat && !smsFailure ? (
								<p className={s.descr}>
									Запросить повторно <span>{count}</span> сек
								</p>
							) : smsRepeat ? (
								<button
									onClick={() => getSmsCode(phoneNumber)}
									className={s.repeat}
								>
									Запросить повторно
								</button>
							) : smsFailure && !helpSupport ? (
								<Button
									onCkick={() => dispatch(setHelpSupport(true))}
									className={'btn-noSms'}
								>
									Не приходит СМС
								</Button>
							) : helpSupport || phoneСonfirmed ? (
								<div className={s.info}>
									<p>
										Позвони по номеру{' '}
										<a href='tel:88003331721'>8 800 333 17 21</a> и мы поможем
									</p>
								</div>
							) : (
								''
							)}
						</div>
					</div>

					<div className={s.buttons}>
						<Button
							className={classNames('btn-send', {
								['disabled']: String(valueInputLength).length !== 4,
								['active']: String(valueInputLength).length === 4,
							})}
						>
							Подтвердить
						</Button>
					</div>
				</form>
			) : (
				<Spinner />
			)}
		</div>
	);
};

export default SmsCode;
