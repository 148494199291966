import cn from 'classnames';
import s from '../steps.module.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import {
	setAddData,
	setAddStep,
} from '../../../../../store/reducer/lk/formExam/slice';

import { selectLkFormExam } from '../../../../../store/reducer/lk/formExam/selector';
import Button from '../../Button/Button';
import Form from '../../Form';

const Step24 = () => {
	const [width, setWidth] = useState(window?.innerWidth);
	const dispatch = useDispatch();
	const { data, step } = useSelector(selectLkFormExam);
	const [isSendForm, setIsSendForm] = useState(false);

	useEffect(() => {
		localStorage.setItem('stepLkExam', JSON.stringify(step));
		localStorage.setItem('dataExam', JSON.stringify(data));
	}, []);

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

	const schema = yup
		.object({
			['Нужен ли Вам лист учета рабочего времени?']: yup.string(),
            ['На объекте оплата раз в месяц, работы выполняются каждый день, за какой период должен быть лист учета?']: yup.string(),
            ['При наличном расчете от клиента, как исполнителям отдать остаток Директору?']: yup.string(),
		})
		.required();

	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
		watch,
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			['Нужен ли Вам лист учета рабочего времени?']: data['Нужен ли Вам лист учета рабочего времени?'],
			['На объекте оплата раз в месяц, работы выполняются каждый день, за какой период должен быть лист учета?']: data['На объекте оплата раз в месяц, работы выполняются каждый день, за какой период должен быть лист учета?'],
			['При наличном расчете от клиента, как исполнителям отдать остаток Директору?']: data['При наличном расчете от клиента, как исполнителям отдать остаток Директору?'],
		},
	});


	const onSubmit = data => {
		setIsSendForm(true);

			dispatch(setAddStep(25));
			dispatch(setAddData(data));
			setIsSendForm(false);

			localStorage.setItem('dataExam', JSON.stringify(data));
			localStorage.setItem('stepLkExam', JSON.stringify(24));
		
	};

	return (
		<div className={s.step24}>
      <div className={s.wrapper_heading}>
			  <h5>Исполнители. Менеджер по персоналу</h5>
			</div>
			<Form onSubmit={handleSubmit(onSubmit)}>

				<div className={s.form_item}>
					<h4 className={s.form_title}>67. Нужен ли Вам лист учета рабочего времени?</h4>

					<div className={s.question67}>
                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question67,
								})}
								{...register('Нужен ли Вам лист учета рабочего времени?')}
								id='exam671'
								type='radio'
								name='Нужен ли Вам лист учета рабочего времени?'
								autoComplete='off'
								value='Лист учета не нужен, у меня есть акт выполненных работ'
							/>
							<label htmlFor='exam671' className={s.label}>
							Лист учета не нужен, у меня есть акт выполненных работ
							</label>
						</div>

						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question67,
								})}
								{...register('Нужен ли Вам лист учета рабочего времени?')}
								id='exam672'
								type='radio'
								name='Нужен ли Вам лист учета рабочего времени?'
								autoComplete='off'
								value='Лист учета нужен, это моя гарантия от претензий клиента'
							/>
							<label htmlFor='exam672' className={s.label}>
							Лист учета нужен, это моя гарантия от претензий клиента
							</label>
						</div>
					</div>

					<p className={s.errorText}>
						{errors['Нужен ли Вам лист учета рабочего времени?'] ? 'Необходимо указать' : ''}
					</p>
				</div>

                <div className={s.form_item}>
					<h4 className={s.form_title}>68. На объекте оплата раз в месяц, работы выполняются каждый день. За какой период должен быть лист учета?</h4>

					<div className={s.question68}>
                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question68,
								})}
								{...register('На объекте оплата раз в месяц, работы выполняются каждый день, за какой период должен быть лист учета?')}
								id='exam681'
								type='radio'
								name='На объекте оплата раз в месяц, работы выполняются каждый день, за какой период должен быть лист учета?'
								autoComplete='off'
								value='Раз в месяц'/>
							<label htmlFor='exam681' className={s.label}>
							Раз в месяц
							</label>
						</div>

						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question68,
								})}
								{...register('На объекте оплата раз в месяц, работы выполняются каждый день, за какой период должен быть лист учета?')}
								id='exam682'
								type='radio'
								name='На объекте оплата раз в месяц, работы выполняются каждый день, за какой период должен быть лист учета?'
								autoComplete='off'
								value='Ежедневно, за каждый выполненный заказ'/>
							<label htmlFor='exam682' className={s.label}>
							Ежедневно, за каждый выполненный заказ
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question68,
								})}
								{...register('На объекте оплата раз в месяц, работы выполняются каждый день, за какой период должен быть лист учета?')}
								id='exam683'
								type='radio'
								name='На объекте оплата раз в месяц, работы выполняются каждый день, за какой период должен быть лист учета?'
								autoComplete='off'
								value='Можно раз в неделю'/>
							<label htmlFor='exam683' className={s.label}>
							Можно раз в неделю
							</label>
						</div>
					</div>

					<p className={s.errorText}>
						{errors['На объекте оплата раз в месяц, работы выполняются каждый день, за какой период должен быть лист учета?'] ? 'Необходимо указать' : ''}
					</p>
				</div>

                <div className={s.form_item}>
					<h4 className={s.form_title}>69. При наличном расчете от клиента, как исполнителям отдать остаток Директору?</h4>

					<div className={s.question69}>
                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question69,
								})}
								{...register('При наличном расчете от клиента, как исполнителям отдать остаток Директору?')}
								id='exam691'
								type='radio'
								name='При наличном расчете от клиента, как исполнителям отдать остаток Директору?'
								autoComplete='off'
								value='Перевести с карты на карту'
							/>
							<label htmlFor='exam691' className={s.label}>
							Остаток отдавать не  нужно, деньги остаются как аванс
							</label>
						</div>

						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question69,
								})}
								{...register('При наличном расчете от клиента, как исполнителям отдать остаток Директору?')}
								id='exam692'
								type='radio'
								name='При наличном расчете от клиента, как исполнителям отдать остаток Директору?'
								autoComplete='off'
								value='Положить остаток на карту Директора (Тинькофф банк) через банкомат или любую другую точку для пополнения'
							/>
							<label htmlFor='exam692' className={s.label}>
							Положить остаток на карту Директора (Тинькофф банк) через банкомат или любую другую точку для пополнения
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question69,
								})}
								{...register('При наличном расчете от клиента, как исполнителям отдать остаток Директору?')}
								id='exam693'
								type='radio'
								name='При наличном расчете от клиента, как исполнителям отдать остаток Директору?'
								autoComplete='off'
								value='Перевести с карты на карту'
							/>
							<label htmlFor='exam693' className={s.label}>
							Перевести с карты на карту
							</label>
						</div>
					</div>

					<p className={s.errorText}>
						{errors['При наличном расчете от клиента, как исполнителям отдать остаток Директору?'] ? 'Необходимо указать' : ''}
					</p>
				</div>

				<div className={s.btns}>
                    <Button callback={() => dispatch(setAddStep(23))} back>
						{width > 575 ? (
							'   Назад'
						) : (
							<img src='/static/lk/IconBackward.svg' alt='назад' />
						)}
					</Button>
					<Button>Далее 24 из 35</Button>
				</div>
			</Form>

			<img
				className={s.illustration}
				src='/static/lk/illustration/illustration.png'
				alt='Иллюстрация'
			/>
		</div>
	);
};

export default Step24;
