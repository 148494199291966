import cn from 'classnames';
import s from '../steps.module.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import {
	setAddData,
	setAddStep,
} from '../../../../../store/reducer/lk/formExam/slice';

import { selectLkFormExam } from '../../../../../store/reducer/lk/formExam/selector';
import Button from '../../Button/Button';
import Form from '../../Form';

const Step22 = () => {
	const [width, setWidth] = useState(window?.innerWidth);
	const dispatch = useDispatch();
	const { data, step } = useSelector(selectLkFormExam);
	const [isError61, setError61] = useState(false);
    const [isError63, setError63] = useState(false);
	const [isSendForm, setIsSendForm] = useState(false);

	useEffect(() => {
		localStorage.setItem('stepLkExam', JSON.stringify(step));
		localStorage.setItem('dataExam', JSON.stringify(data));
	}, []);

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

	const schema = yup
		.object({
			['Кто контролирует работу на заказе в бригаде от 2 человек?']: yup.string(),
		})
		.required();

	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
		watch,
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			['Каким способом Менеджер по персоналу информирует исполнителей о заказе?']: data['Каким способом Менеджер по персоналу информирует исполнителей о заказе?'],
			['Кто контролирует работу на заказе в бригаде от 2 человек?']: data['Кто контролирует работу на заказе в бригаде от 2 человек?'],
			['Способы подтверждения готовности выхода на заказ']: data['Способы подтверждения готовности выхода на заказ'],
		},
	});

	
	const ckbox61 = watch('Каким способом Менеджер по персоналу информирует исполнителей о заказе?');
    const ckbox63 = watch('Способы подтверждения готовности выхода на заказ');
	
	useEffect(() => {
		if (!ckbox61?.length) {
			setError61(true);
		} else {
			setError61(false);
		}
	}, [ckbox61]);

    useEffect(() => {
		if (!ckbox63?.length) {
			setError63(true);
		} else {
			setError63(false);
		}
	}, [ckbox63]);

	const onSubmit = data => {
		setIsSendForm(true);

		if (!isError61 && !isError63) {
			dispatch(setAddStep(23));
			dispatch(setAddData(data));
			setIsSendForm(false);

			localStorage.setItem('dataExam', JSON.stringify(data));
			localStorage.setItem('stepLkExam', JSON.stringify(22));
		}
	};

	return (
		<div className={s.step22}>
      <div className={s.wrapper_heading}>
			  <h5>Исполнители. Менеджер по персоналу</h5>
			</div>
			<Form onSubmit={handleSubmit(onSubmit)}>

                <div className={s.form_item}>
					<h4 className={s.form_title}>
					61. Каким способом Менеджер по персоналу информирует исполнителей о заказе?
					</h4>

					<div className={s.question61}>
						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question61,
								})}
								{...register('Каким способом Менеджер по персоналу информирует исполнителей о заказе?')}
								id='exam611'
								type='checkbox'
								name='Каким способом Менеджер по персоналу информирует исполнителей о заказе?'
								autoComplete='off'
								value='PUSH уведомление (для исполнителей с приложением)'/>
							<label htmlFor='exam611' className={s.label}>
							PUSH уведомление (для исполнителей с приложением)
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question61,
								})}
								{...register('Каким способом Менеджер по персоналу информирует исполнителей о заказе?')}
								id='exam612'
								type='checkbox'
								name='Каким способом Менеджер по персоналу информирует исполнителей о заказе?'
								autoComplete='off'
								value='СМС (для исполнителей без смартфона)'/>
							<label htmlFor='exam612' className={s.label}>
							СМС (для исполнителей без смартфона)
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question61,
								})}
								{...register('Каким способом Менеджер по персоналу информирует исполнителей о заказе?')}
								id='exam613'
								type='checkbox'
								name='Каким способом Менеджер по персоналу информирует исполнителей о заказе?'
								autoComplete='off'
								value='Мессенджеры (Telegram, WhatsApp, Viber)'/>
							<label htmlFor='exam613' className={s.label}>
							Мессенджеры (Telegram, WhatsApp, Viber)
							</label>
						</div>

                        <div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question61,
								})}
								{...register('Каким способом Менеджер по персоналу информирует исполнителей о заказе?')}
								id='exam614'
								type='checkbox'
								name='Каким способом Менеджер по персоналу информирует исполнителей о заказе?'
								autoComplete='off'
								value='Эл.почта (автоматическая рассылка о заказе)'/>
							<label htmlFor='exam614' className={s.label}>
							Эл.почта (автоматическая рассылка о заказе)
							</label>
						</div>

                        <div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question61,
								})}
								{...register('Каким способом Менеджер по персоналу информирует исполнителей о заказе?')}
								id='exam615'
								type='checkbox'
								name='Каким способом Менеджер по персоналу информирует исполнителей о заказе?'
								autoComplete='off'
								value='Звонок (при срочном выезде на заказ)'/>
							<label htmlFor='exam615' className={s.label}>
							Звонок (при срочном выезде на заказ)
							</label>
						</div>

						<p className={s.errorText2}>
							{isError61 &&
								isSendForm &&
								'Необходимо указать'}
						</p>
					</div>
				</div>

                <div className={s.form_item}>
					<h4 className={s.form_title}>62. Кто контролирует работу на заказе в бригаде от 2 человек?</h4>

					<div className={s.question62}>
                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question62,
								})}
								{...register('Кто контролирует работу на заказе в бригаде от 2 человек?')}
								id='exam621'
								type='radio'
								name='Кто контролирует работу на заказе в бригаде от 2 человек?'
								autoComplete='off'
								value='Заказчик на объекте'
							/>
							<label htmlFor='exam621' className={s.label}>
							Заказчик на объекте
							</label>
						</div>

						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question62,
								})}
								{...register('Кто контролирует работу на заказе в бригаде от 2 человек?')}
								id='exam622'
								type='radio'
								name='Кто контролирует работу на заказе в бригаде от 2 человек?'
								autoComplete='off'
								value='Логист удаленно из офиса'
							/>
							<label htmlFor='exam622' className={s.label}>
							Логист удаленно из офиса
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question62,
								})}
								{...register('Кто контролирует работу на заказе в бригаде от 2 человек?')}
								id='exam623'
								type='radio'
								name='Кто контролирует работу на заказе в бригаде от 2 человек?'
								autoComplete='off'
								value='Назначенный старший смены по заказу из бригады'
							/>
							<label htmlFor='exam623' className={s.label}>
							Назначенный старший смены по заказу из бригады
							</label>
						</div>
					</div>

					<p className={s.errorText}>
						{errors['Кто контролирует работу на заказе в бригаде от 2 человек?'] ? 'Необходимо указать' : ''}
					</p>
				</div>

                <div className={s.form_item}>
					<h4 className={s.form_title}>
					63. Способы подтверждения готовности выхода на заказ
					</h4>

					<div className={s.question63}>
						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question63,
								})}
								{...register('Способы подтверждения готовности выхода на заказ')}
								id='exam631'
								type='checkbox'
								name='Способы подтверждения готовности выхода на заказ'
								autoComplete='off'
								value='Позвонить или написать СМС менеджеру по персоналу, если нет приложения'/>
							<label htmlFor='exam631' className={s.label}>
							Позвонить или написать СМС менеджеру по персоналу, если нет приложения
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question63,
								})}
								{...register('Способы подтверждения готовности выхода на заказ')}
								id='exam632'
								type='checkbox'
								name='Способы подтверждения готовности выхода на заказ'
								autoComplete='off'
								value='Взять заказ в работу, если назначен через Автоподбор (исполнитель автоматически отобразится в системе)'/>
							<label htmlFor='exam632' className={s.label}>
							Взять заказ в работу, если назначен через Автоподбор (исполнитель автоматически отобразится в системе)
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question63,
								})}
								{...register('Способы подтверждения готовности выхода на заказ')}
								id='exam633'
								type='checkbox'
								name='Способы подтверждения готовности выхода на заказ'
								autoComplete='off'
								value='Сообщать о готовности не нужно'/>
							<label htmlFor='exam633' className={s.label}>
							Сообщать о готовности не нужно
							</label>
						</div>

						<p className={s.errorText2}>
							{isError63 &&
								isSendForm &&
								'Необходимо указать'}
						</p>
					</div>
				</div>

				<div className={s.btns}>
        <Button callback={() => dispatch(setAddStep(21))} back>
						{width > 575 ? (
							'   Назад'
						) : (
							<img src='/static/lk/IconBackward.svg' alt='назад' />
						)}
					</Button>
					<Button>Далее 22 из 35</Button>
				</div>
			</Form>

			<img
				className={s.illustration}
				src='/static/lk/illustration/illustration.png'
				alt='Иллюстрация'
			/>
		</div>
	);
};

export default Step22;
