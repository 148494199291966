import cn from 'classnames';
import s from './button.module.scss';

const Button = ({
	icon,
	iconType,
	children,
	handleClick,
	type = 'blue',
	width,
	page,
}) => {
	return (
		<button
			style={{ width }}
			className={cn(s.button, s[type], {
				[s.skilla_book]: page === 'skilla-book',
				[s.position]: page === 'book',
				[s.flex]: icon,
			})}
			onClick={handleClick}
		>
			{children}

			{icon && <span className={cn(iconType, s.icon)}></span>}
		</button>
	);
};

export default Button;
