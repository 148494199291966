import { useParams, Link } from 'react-router-dom'
import { getArticleClient, getListAll } from '../../Api'
import { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import parse from 'html-react-parser'
import classNames from 'classnames'

import Slider from 'react-slick'
import ArticlesForm from '../../components/articleForm/ArticlesForm'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import ArticleBtns from '../../components/articleBtns/ArticleBtns'
import SliderArticle from '../../components/sliderArticle/SliderArticle'
import FormLive from '../../components/formLive/FormLive'
import { Helmet } from 'react-helmet-async'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

function ArticlesClient({ activeTarget }) {
  const { alias } = useParams()
  const [data, setData] = useState({})
  const [content, setContent] = useState('')
  const [otherClient, setOtherClient] = useState([])
  const [index, setIndex] = useState(2)
  const [clickOption, setClickOption] = useState(false)
  const [width, setWidth] = useState(window?.innerWidth)

  const {
    name,
    main_photo,
    main_photo_mobile,
    subname,
    business_title,
    business_subtitle,
    live_title,
    live_subtitle,
    option1,
    option2,
    option3,
    option4,
    answer,
    title,
  } = data

  const nextArticle = () => {
    showQuestion(false)
    if (otherClient.length !== index + 1) {
      setIndex((prev) => prev + 1)
    } else {
      setIndex(0)
    }
  }

  useEffect(() => {
    getArticleClient(alias)
      .then(({ data }) => {
        setData(data.article)
        setContent(data.article.content)
      })

      .catch((e) => {
        console.log(e)
      })

    window.scroll(0, 0)
  }, [alias, index, otherClient])

  useEffect(() => {
    getListAll('client_case')
      .then((response) => {
        setOtherClient(response.data.results)
      })
      .catch((e) => {
        console.log(e)
      })
  }, [])

  useEffect(() => {
    const cb = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', cb)

    return () => window.removeEventListener('resize', cb)
  }, [])

  const settings = {
    className:
      activeTarget === 'customer'
        ? 'article-slide article-slider-customer'
        : 'article-slide article-slider-worker',
    infinite: true,
    variableWidth: true,
    speed: 300,
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
  }

  const showQuestion = useCallback((bool) => {
    setClickOption(bool)
  }, [])

  console.log()

  return (
    <div className="articles">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={business_title} />
      </Helmet>
      {activeTarget === 'customer' ? (
        <Navbar page="customer" activeTarget={activeTarget} type="article" activePage="article" />
      ) : (
        <Navbar page="worker" activeTarget={activeTarget} type="article" activePage="article" />
      )}

      <div className="articles__header articles__header--client  header-articles">
        <img
          className="articles__header-background"
          src={width > 768 ? main_photo : main_photo_mobile}
          alt={name}
        />
        <div className="container">
          <div className="header-articles__intro clients">
            <h1 className="title-header-h1">{name}</h1>
            <h2 className="title-header-h2">{subname}</h2>

            {data.services && data.services.length > 0 ? (
              <ArticleBtns type={'header'} keys="clients" />
            ) : null}
          </div>
        </div>
      </div>

      <StyledContainer>
        <div className="articles__content articles__content-padding">
          <div>{parse(content)}</div>

          <FormLive
            live_subtitle={live_subtitle}
            live_title={live_title}
            option={{ option1, option2, option3, option4 }}
            answer={answer}
            showQuestion={showQuestion}
            clickOption={clickOption}
          />
        </div>

        <div className="articles__static">
          {data.services && data.services.length > 0 ? <ArticleBtns type={'footer'} keys="clients" /> : null}

          <ArticlesForm title={business_title} subtitle={business_subtitle} />

          <div className="article__next-article article-next">
            <h3 className="article-next__title">Следующая статья</h3>

            {otherClient[index] && (
              <div className="article-next__wrapper">
                <img
                  className="article-next__banner clients"
                  src={otherClient[index].main_photo}
                  alt="Слудующая статья"
                />
                <div className="article-next__name">{otherClient[index].case_client_name}</div>
                <div className="article-next__descr">{otherClient[index].case_client_slogan}</div>

                <Link
                  onClick={nextArticle}
                  to={`/${otherClient[index].alias}`}
                  className={classNames({
                    'article-next__wrapper-btn article-next__wrapper-btn--customer':
                      activeTarget === 'customer',
                    'article-next__wrapper-btn article-next__wrapper-btn--worker': activeTarget === 'worker',
                    'article-next__wrapper-btn article-next__wrapper-btn--business':
                      activeTarget === 'business',
                  })}
                >
                  <span>Читать</span>

                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 10 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.29286 5.00003H0V4.00003H7.29285L4.64641 1.35359L5.35352 0.646484L9.20707 4.50004L5.35352 8.35359L4.64641 7.64648L7.29286 5.00003Z"
                      fill="#000729"
                    />
                  </svg>
                </Link>
              </div>
            )}
          </div>

          <div className="article__other-articles articles-other">
            <h2 className="articles-other__title">Читайте похожие статьи</h2>

            <Slider {...settings}>
              {otherClient.map((item) => (
                <SliderArticle key={item.alias} {...item} type="clients" activeTarget={activeTarget} />
              ))}
            </Slider>
          </div>
        </div>
      </StyledContainer>
      {activeTarget === 'customer' ? (
        <Footer page="customer" type="faq" />
      ) : (
        <Footer page="worker" type="faq" />
      )}
    </div>
  )
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1400px;
  margin: 0 auto;

  a {
    color: #009953;
  }

  .articles__content {
    padding-top: 70px;
    &-padding {
      @media (max-width: 768px) {
        padding-top: 320px;
      }
    }
  }

  .slick-next,
  .slick-prev {
    width: 40px;
    height: 40px;

    border-radius: 300px;
    background-color: #fff !important;

    z-index: 1;

    &:hover {
      background-color: #3357ff !important;
      border: none;
    }
  }

  .slick-arrow {
    background-color: #f9f9f9;
    transition: all 0.3s ease;
    border: 1px solid #0f1324;
  }

  .slick-prev::before {
    content: url('/static/article/icon/arrow-slide-left.svg');

    display: block;
  }

  .slick-prev:hover::before {
    content: url('/static/article/icon/arrow-slide-left-hover.svg');
  }

  .slick-next::before {
    content: url('/static/article/icon/arrow-slide.svg');

    display: block;
  }

  .slick-next:hover::before {
    content: url('/static/article/icon/arrow-slide-hover.svg');
  }

  .article-next__wrapper-btn {
    &--worker {
      &:hover {
        background-color: #8800ff;
        span {
          color: #fff;
        }

        svg {
          path {
            fill: #fff;
          }
        }
      }
    }

    &--customer {
      &:hover {
        background-color: #002cfb;
        span {
          color: #fff;
        }

        svg {
          path {
            fill: #fff;
          }
        }
      }
    }

    &--business {
      &:hover {
        background-color: #00ff88;
        span {
          color: #000;
        }

        svg {
          path {
            fill: #000;
          }
        }
      }
    }
  }

  .article-slider-customer {
    .slick-next,
    .slick-prev {
      width: 40px;
      height: 40px;

      border-radius: 300px;
      background-color: #fff !important;

      z-index: 1;

      &:hover {
        background-color: #002cfb !important;
        border: none;
      }
    }
  }

  .slick-track {
    display: flex;
  }

  .article-slider-worker {
    .slick-next,
    .slick-prev {
      width: 40px;
      height: 40px;

      border-radius: 300px;
      background-color: #fff !important;

      z-index: 1;

      &:hover {
        background-color: #8800ff !important;
        border: none;
      }
    }
  }

  .slick-slider {
    @media (max-width: 768px) {
      top: 60px;
    }
  }

  .slick-prev {
    @media (max-width: 768px) {
      left: 70px;
    }
  }

  .slick-next {
    @media (max-width: 768px) {
      left: 270px;
    }
  }

  .slick-arrow {
    background-color: #f9f9f9;
    transition: all 0.3s ease;
    border: 1px solid #0f1324;

    @media (max-width: 768px) {
      position: absolute;
      top: -30px;
    }
  }

  .articles__content {
    width: 100%;
  }

  .articles-other__title {
    margin: 16px 0 !important;
    @media (max-width: 768px) {
      width: 343px;
      margin: 16px 0;
      text-align: center;
    }
  }

  .articlesForm-footer__descr {
    @media (max-width: 768px) {
      margin: 0;
    }
  }

  .article-slide-client__slogan {
    margin: 20px 0;
  }
`

export default ArticlesClient
