import { useEffect, memo } from 'react';
import { useSelector } from 'react-redux';
import { selectLKAuth } from '../../../store/reducer/lk/authChech/selector';
import s from './auth.module.scss';
import Check from './Check/Check';
import Save from './Save/Save';
import Start from './Start/Start';

const Auth = memo(({endDatePremium}) => {
	const { user, helpSupport } = useSelector(selectLKAuth);
	const status = user?.user?.menu?.['1']?.status;

	useEffect(() => {
		window.scroll(0, 0);
	}, []);

	return (
		<div className={s.auth}>
			{!user?.user?.code && <Start />}

			{status === 'enabled' && <Check />}

			{status === 'finished' && <Save endDatePremium={endDatePremium}/>}

			{!helpSupport ? (
				<img
					className={s.illustration}
					src='/static/lk/illustration/illustration.png'
					alt='Иллюстрация'
				/>
			) : (
				<img
					className={s.illustration}
					src='/static/lk/illustration/illustrationBad.png'
					alt='Иллюстрация'
				/>
			)}
		</div>
	);
})

export default Auth;
