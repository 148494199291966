import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import s from './DocView.module.scss'


function DocView({link, height}) {
  console.log(link)
  const docs = [
    { uri: link, fileType: 'docx', fileName: 'договор' }
  ];

  return <DocViewer documents={docs}  pluginRenderers={DocViewerRenderers} style={{height: height}} className={s.doc}/>;
}

export default DocView;