import cn from 'classnames'
import s from '../steps.module.scss'

import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'
import { setAddData, setAddStep } from '../../../../../store/reducer/lk/formExam/slice'

import { selectLkFormExam } from '../../../../../store/reducer/lk/formExam/selector'
import Button from '../../Button/Button'
import Form from '../../Form'

const Step3 = () => {
  const [width, setWidth] = useState(window?.innerWidth)
  const dispatch = useDispatch()
  const { data, step } = useSelector(selectLkFormExam)
  const [isError7, setError7] = useState(false)
  const [isSendForm, setIsSendForm] = useState(false)

  useEffect(() => {
    localStorage.setItem('stepLkExam', JSON.stringify(step))
    localStorage.setItem('dataExam', JSON.stringify(data))
  }, [])

  useEffect(() => {
    const cb = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', cb)

    return () => window.removeEventListener('resize', cb)
  }, [])

  const schema8 = yup
    .object({
      ['Какой тип заказчиков наиболее интересен для нашего бизнеса?']: yup.string(),
      ['Какой процент наценки/маржинальность компании закладывается в ставку заказчику?']: yup.string(),
    })
    .required()

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm({
    resolver: yupResolver(schema8),
    defaultValues: {
      ['Какой тип заказчиков наиболее интересен для нашего бизнеса?']:
        data['Какой тип заказчиков наиболее интересен для нашего бизнеса?'],

      ['Какой процент наценки/маржинальность компании закладывается в ставку заказчику?']:
        data['Какой процент наценки/маржинальность компании закладывается в ставку заказчику?'],
      ['Почему исполнители работают у нас?']: data['Почему исполнители работают у нас?'],
    },
  })

  const ckbox7 = watch('Почему исполнители работают у нас?')

  useEffect(() => {
    if (!ckbox7?.length) {
      setError7(true)
    } else {
      setError7(false)
    }
  }, [ckbox7])

  const onSubmit = (data) => {
    setIsSendForm(true)

    if (!isError7) {
      dispatch(setAddStep(4))
      dispatch(setAddData(data))
      setIsSendForm(false)

      localStorage.setItem('dataExam', JSON.stringify(data))
      localStorage.setItem('stepLkExam', JSON.stringify(3))
    }
  }

  return (
    <div className={s.step3}>
      <div className={s.wrapper_heading}>
        <h5>Скилла. Бизнес-модель</h5>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={s.form_item}>
          <h4 className={s.form_title}>7. Почему исполнители работают у нас?</h4>

          <div className={s.question7}>
            <div className={s.checkbox}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question7,
                })}
                {...register('Почему исполнители работают у нас?')}
                id="exam71"
                type="checkbox"
                name="Почему исполнители работают у нас?"
                autoComplete="off"
                value="Удобная коммуникация"
              />
              <label htmlFor="exam71" className={s.label}>
                Удобная коммуникация
              </label>
            </div>

            <div className={s.checkbox}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question7,
                })}
                {...register('Почему исполнители работают у нас?')}
                id="exam72"
                type="checkbox"
                name="Почему исполнители работают у нас?"
                autoComplete="off"
                value="Официальное трудоустройство"
              />
              <label htmlFor="exam72" className={s.label}>
                Официальное трудоустройство
              </label>
            </div>

            <div className={s.checkbox}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question7,
                })}
                {...register('Почему исполнители работают у нас?')}
                id="exam73"
                type="checkbox"
                name="Почему исполнители работают у нас?"
                autoComplete="off"
                value="Не требуется специальные навыки и опыт"
              />
              <label htmlFor="exam73" className={s.label}>
                Не требуется специальные навыки и опыт
              </label>
            </div>

            <div className={s.checkbox}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question7,
                })}
                {...register('Почему исполнители работают у нас?')}
                id="exam74"
                type="checkbox"
                name="Почему исполнители работают у нас?"
                autoComplete="off"
                value="Социальный пакет"
              />
              <label htmlFor="exam74" className={s.label}>
                Социальный пакет
              </label>
            </div>

            <div className={s.checkbox}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question7,
                })}
                {...register('Почему исполнители работают у нас?')}
                id="exam75"
                type="checkbox"
                name="Почему исполнители работают у нас?"
                autoComplete="off"
                value="Подработка по удобному графику"
              />
              <label htmlFor="exam75" className={s.label}>
                Подработка по удобному графику
              </label>
            </div>

            <p className={s.errorText2}>{isError7 && isSendForm && 'Необходимо указать'}</p>
          </div>
        </div>

        <div className={s.form_item}>
          <h4 className={s.form_title}>8. Какой тип заказчиков наиболее интересен для нашего бизнеса?</h4>

          <div className={s.question8}>
            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question8,
                })}
                {...register('Какой тип заказчиков наиболее интересен для нашего бизнеса?')}
                id="exam81"
                type="radio"
                name="Какой тип заказчиков наиболее интересен для нашего бизнеса?"
                autoComplete="off"
                value="Частные лица"
              />

              <label htmlFor="exam81" className={s.label}>
                Частные лица
              </label>
            </div>

            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question8,
                })}
                {...register('Какой тип заказчиков наиболее интересен для нашего бизнеса?')}
                id="exam82"
                type="radio"
                name="Какой тип заказчиков наиболее интересен для нашего бизнеса?"
                autoComplete="off"
                value="Ключевые клиенты (юр. лица)"
              />
              <label htmlFor="exam82" className={s.label}>
                Ключевые клиенты (юр. лица)
              </label>
            </div>
          </div>

          <p className={s.errorText}>
            {errors['Какой тип заказчиков наиболее интересен для нашего бизнеса?']
              ? 'Необходимо указать'
              : ''}
          </p>
        </div>

        <div className={s.form_item}>
          <h4 className={s.form_title}>
            9. Какой процент наценки/маржинальность компании закладывается в ставку заказчику?
          </h4>

          <div className={s.question9}>
            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question9,
                })}
                {...register(
                  'Какой процент наценки/маржинальность компании закладывается в ставку заказчику?',
                )}
                id="exam91"
                type="radio"
                name="Какой процент наценки/маржинальность компании закладывается в ставку заказчику?"
                autoComplete="off"
                value="40-50%"
              />

              <label htmlFor="exam91" className={s.label}>
                40-50%
              </label>
            </div>

            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question9,
                })}
                {...register(
                  'Какой процент наценки/маржинальность компании закладывается в ставку заказчику?',
                )}
                id="exam92"
                type="radio"
                name="Какой процент наценки/маржинальность компании закладывается в ставку заказчику?"
                autoComplete="off"
                value="20%"
              />
              <label htmlFor="exam92" className={s.label}>
                20%
              </label>
            </div>

            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question9,
                })}
                {...register(
                  'Какой процент наценки/маржинальность компании закладывается в ставку заказчику?',
                )}
                id="exam93"
                type="radio"
                name="Какой процент наценки/маржинальность компании закладывается в ставку заказчику?"
                autoComplete="off"
                value="10-15%"
              />
              <label htmlFor="exam93" className={s.label}>
                10-15%
              </label>
            </div>

            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question9,
                })}
                {...register(
                  'Какой процент наценки/маржинальность компании закладывается в ставку заказчику?',
                )}
                id="exam94"
                type="radio"
                name="Какой процент наценки/маржинальность компании закладывается в ставку заказчику?"
                autoComplete="off"
                value="70%"
              />
              <label htmlFor="exam94" className={s.label}>
                70%
              </label>
            </div>
          </div>

          <p className={s.errorText}>
            {errors['Какой процент наценки/маржинальность компании закладывается в ставку заказчику?']
              ? 'Необходимо указать'
              : ''}
          </p>
        </div>

        <div className={s.btns}>
          <Button callback={() => dispatch(setAddStep(2))} back>
            {width > 575 ? '   Назад' : <img src="/static/lk/IconBackward.svg" alt="назад" />}
          </Button>
          <Button>Далее 3 из 35</Button>
        </div>
      </Form>

      <img className={s.illustration} src="/static/lk/illustration/illustration.png" alt="Иллюстрация" />
    </div>
  )
}

export default Step3
