import cn from 'classnames';
import s from '../steps.module.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import {
	setAddData,
	setAddStep,
} from '../../../../../store/reducer/lk/formExam/slice';

import { selectLkFormExam } from '../../../../../store/reducer/lk/formExam/selector';
import Button from '../../Button/Button';
import Form from '../../Form';

const Step25 = () => {
	const [width, setWidth] = useState(window?.innerWidth);
	const dispatch = useDispatch();
	const { data, step } = useSelector(selectLkFormExam);
	const [isError70, setError70] = useState(false);
	const [isError71, setError71] = useState(false);
	const [isError72, setError72] = useState(false);
	const [isSendForm, setIsSendForm] = useState(false);

	useEffect(() => {
		localStorage.setItem('stepLkExam', JSON.stringify(step));
		localStorage.setItem('dataExam', JSON.stringify(data));
	}, []);

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

	const schema = yup
		.object({
			['Кто контролирует работу на заказе в бригаде от 2 человек?']: yup.string(),
		})
		.required();

	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
		watch,
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			['Как исполнители сообщают, что закончили работу?']: data['Как исполнители сообщают, что закончили работу?'],
			['Нужно ли мне оформлять трудовые договоры с исполнителями?']: data['Нужно ли мне оформлять трудовые договоры с исполнителями?'],
			['Каким исполнителям можно перечислять оплату с р/с на банковскую карту?']: data['Каким исполнителям можно перечислять оплату с р/с на банковскую карту?'],
		},
	});


	const ckbox70 = watch('Как исполнители сообщают, что закончили работу?');
	const ckbox71 = watch('Нужно ли мне оформлять трудовые договоры с исполнителями?');
	const ckbox72 = watch('Каким исполнителям можно перечислять оплату с р/с на банковскую карту?');

	useEffect(() => {
		if (!ckbox70?.length) {
			setError70(true);
		} else {
			setError70(false);
		}
	}, [ckbox70]);

	useEffect(() => {
		if (!ckbox71?.length) {
			setError71(true);
		} else {
			setError71(false);
		}
	}, [ckbox71]);

	useEffect(() => {
		if (!ckbox72?.length) {
			setError72(true);
		} else {
			setError72(false);
		}
	}, [ckbox72]);

	const onSubmit = data => {
		setIsSendForm(true);

		if (!isError70 && !isError71 && !isError72) {
			dispatch(setAddStep(26));
			dispatch(setAddData(data));
			setIsSendForm(false);

			localStorage.setItem('dataExam', JSON.stringify(data));
			localStorage.setItem('stepLkExam', JSON.stringify(25));
		}
	};

	return (
		<div className={s.step25}>
			<div className={s.wrapper_heading}>
				<h5>Исполнители. Менеджер по персоналу</h5>
			</div>
			<Form onSubmit={handleSubmit(onSubmit)}>

				<div className={s.form_item}>
					<h4 className={s.form_title}>
						70. Как исполнители сообщают, что закончили работу?
					</h4>

					<div className={s.question70}>
						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question70,
								})}
								{...register('Как исполнители сообщают, что закончили работу?')}
								id='exam701'
								type='checkbox'
								name='Как исполнители сообщают, что закончили работу?'
								autoComplete='off'
								value='Нажимают в приложении кнопку "Я ухожу"' />
							<label htmlFor='exam701' className={s.label}>
								Нажимают в приложении кнопку "Я ухожу"
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question70,
								})}
								{...register('Как исполнители сообщают, что закончили работу?')}
								id='exam702'
								type='checkbox'
								name='Как исполнители сообщают, что закончили работу?'
								autoComplete='off'
								value='При отсутствии приложения старший смены звонит логисту и сообщает что закончили' />
							<label htmlFor='exam702' className={s.label}>
								При отсутствии приложения старший смены звонит менеджеру по персоналу и сообщает что закончили
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question70,
								})}
								{...register('Как исполнители сообщают, что закончили работу?')}
								id='exam703'
								type='checkbox'
								name='Как исполнители сообщают, что закончили работу?'
								autoComplete='off'
								value='Сообщать о том, что работы закончены, не нужно' />
							<label htmlFor='exam703' className={s.label}>
								Сообщать о том, что работы закончены, не нужно
							</label>
						</div>

						<p className={s.errorText2}>
							{isError70 &&
								isSendForm &&
								'Необходимо указать'}
						</p>
					</div>
				</div>

				<div className={s.form_item}>
					<h4 className={s.form_title}>71. Нужно ли мне оформлять трудовые договоры с исполнителями?</h4>

					<div className={s.question71}>
						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question71,
								})}
								{...register('Нужно ли мне оформлять трудовые договоры с исполнителями?')}
								id='exam711'
								type='radio'
								name='Нужно ли мне оформлять трудовые договоры с исполнителями?'
								autoComplete='off'
								value='Да, обязательно'
							/>
							<label htmlFor='exam711' className={s.label}>
								Да, обязательно
							</label>
						</div>

						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question71,
								})}
								{...register('Нужно ли мне оформлять трудовые договоры с исполнителями?')}
								id='exam712'
								type='radio'
								name='Нужно ли мне оформлять трудовые договоры с исполнителями?'
								autoComplete='off'
								value='Нет, в этом нет необходимости, т.к. я оказываю услугу и привлекаю третьих лиц'
							/>
							<label htmlFor='exam712' className={s.label}>
								Нет, в этом нет необходимости, т.к. я оказываю услугу и привлекаю третьих лиц
							</label>
						</div>

						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question71,
								})}
								{...register('Нужно ли мне оформлять трудовые договоры с исполнителями?')}
								id='exam713'
								type='radio'
								name='Нужно ли мне оформлять трудовые договоры с исполнителями?'
								autoComplete='off'
								value='Нет, я заключаю договор на возмездное оказание услуг только с самозанятыми'
							/>
							<label htmlFor='exam713' className={s.label}>
								Нет, я заключаю договор на возмездное оказание услуг только с самозанятыми
							</label>
						</div>

						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question71,
								})}
								{...register('Нужно ли мне оформлять трудовые договоры с исполнителями?')}
								id='exam714'
								type='radio'
								name='Нужно ли мне оформлять трудовые договоры с исполнителями?'
								autoComplete='off'
								value='SkillaРабота заключает договоры с самозанятыми'
							/>
							<label htmlFor='exam714' className={s.label}>
								SkillaРабота заключает договоры с самозанятыми
							</label>
						</div>
					</div>

					<p className={s.errorText}>
						{errors['Нужно ли мне оформлять трудовые договоры с исполнителями?'] ? 'Необходимо указать' : ''}
					</p>
				</div>

				<div className={s.form_item}>
					<h4 className={s.form_title}>
						72. Каким исполнителям можно перечислять оплату с р/с на банковскую карту?
					</h4>

					<div className={s.question72}>
						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question72,
								})}
								{...register('Каким исполнителям можно перечислять оплату с р/с на банковскую карту?')}
								id='exam721'
								type='checkbox'
								name='Каким исполнителям можно перечислять оплату с р/с на банковскую карту?'
								autoComplete='off'
								value='Самозанятым, по счету и чеку' />
							<label htmlFor='exam721' className={s.label}>
								Самозанятым, по счету и чеку
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question72,
								})}
								{...register('Каким исполнителям можно перечислять оплату с р/с на банковскую карту?')}
								id='exam722'
								type='checkbox'
								name='Каким исполнителям можно перечислять оплату с р/с на банковскую карту?'
								autoComplete='off'
								value='Всем исполнителям, кто попросит оплату на карту' />
							<label htmlFor='exam722' className={s.label}>
								Всем исполнителям, кто попросит оплату на карту
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question72,
								})}
								{...register('Каким исполнителям можно перечислять оплату с р/с на банковскую карту?')}
								id='exam723'
								type='checkbox'
								name='Каким исполнителям можно перечислять оплату с р/с на банковскую карту?'
								autoComplete='off'
								value='Оформленным исполнителям по трудовому договору с партнером' />
							<label htmlFor='exam723' className={s.label}>
								Оформленным исполнителям по трудовому договору с партнером
							</label>
						</div>

						<p className={s.errorText2}>
							{isError72 &&
								isSendForm &&
								'Необходимо указать'}
						</p>
					</div>
				</div>

				<div className={s.btns}>
					<Button callback={() => dispatch(setAddStep(24))} back>
						{width > 575 ? (
							'   Назад'
						) : (
							<img src='/static/lk/IconBackward.svg' alt='назад' />
						)}
					</Button>
					<Button>Далее 25 из 35</Button>
				</div>
			</Form>

			<img
				className={s.illustration}
				src='/static/lk/illustration/illustration.png'
				alt='Иллюстрация'
			/>
		</div>
	);
};

export default Step25;
