import s from "../steps.module.scss";
import cn from "classnames";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  setAddStep,
  setAddData,
} from "../../../../../store/reducer/lk/form/slice";
import { selectLkForm } from "../../../../../store/reducer/lk/form/selector";

import Form from "../../Form";
import Button from "../../Button/Button";

const Step4 = () => {
  const [width, setWidth] = useState(window?.innerWidth);
  const dispatch = useDispatch();
  const { data, step } = useSelector(selectLkForm);
  const [error, setError] = useState(false);
  const [error2, setError2] = useState(false);
  const [isSendForm, setIsSendForm] = useState(false);

  useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

  useEffect(() => {
    localStorage.setItem("stepLk", JSON.stringify(step));
    localStorage.setItem("data", JSON.stringify(data));
  }, []);

  const schema = yup
    .object({
      ["В какой сфере есть предпринимательский опыт (если есть), ваша роль в своем бизнесе?"]:yup.string().required()}).required();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ["В какой сфере есть предпринимательский опыт (если есть), ваша роль в своем бизнесе?"]:data["В какой сфере есть предпринимательский опыт (если есть), ваша роль в своем бизнесе?"],

      ["Есть ли у вас опыт работы по франшизе, лицензионному договору или дилерскому договору?"]:data["Есть ли у вас опыт работы по франшизе, лицензионному договору или дилерскому договору?"],

      ["Есть ли на данный момент успешный (приносящий доход) собственный бизнес?"]:data["Есть ли на данный момент успешный (приносящий доход) собственный бизнес?"],
    },
  });

  const hasTextinput = watch("Есть ли у вас опыт работы по франшизе, лицензионному договору или дилерскому договору? (Свой вариант)");

  const hasTextInput2 = watch("Есть ли на данный момент успешный (приносящий доход) собственный бизнес? (Свой вариант)");

  const hasRadioInput = watch("Есть ли у вас опыт работы по франшизе, лицензионному договору или дилерскому договору?");

  const hasRadioInput2 = watch("Есть ли на данный момент успешный (приносящий доход) собственный бизнес?");

  useEffect(() => {
    if (!hasRadioInput && !hasTextinput) {
      setError(true);
    } else if (hasRadioInput || hasTextinput) {
      setError(false);
    }

    if (!hasRadioInput2 && !hasTextInput2) {
      setError2(true);
    } else if (hasRadioInput2 || hasTextInput2) {
      setError2(false);
    }
  }, [hasRadioInput, hasRadioInput2, hasTextinput, hasTextInput2]);

  const onSubmit = (data) => {
    setIsSendForm(true);
    if (!error && !error2) {
      setIsSendForm(false);
      setIsSendForm(false);

      dispatch(setAddStep(5));
      dispatch(setAddData(data));

      localStorage.setItem("data", JSON.stringify(data));
      localStorage.setItem("stepLk", JSON.stringify(5));
    }
  };

  return (
    <div className={s.step4}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={s.form_item}>
          <h4 className={s.form_title}>
            В какой сфере есть предпринимательский опыт <br></br> (если есть),
            ваша роль в своем бизнесе?
          </h4>

          <div className={s.experience}>
            <textarea
              style={{ marginTop: 0 }}
              {...register("В какой сфере есть предпринимательский опыт (если есть), ваша роль в своем бизнесе?")}
              className={cn(s.textarea, {
                [s.error]:errors["В какой сфере есть предпринимательский опыт (если есть), ваша роль в своем бизнесе?"],
              })}
              placeholder="Развернутый ответ увеличит вероятность одобрения кандидата на партнерство"
              name="В какой сфере есть предпринимательский опыт (если есть), ваша роль в своем бизнесе?"
            ></textarea>
          </div>
        </div>

        <div className={s.form_item}>
          <h4 className={s.form_title}>
            Есть ли у вас опыт работы по франшизе, лицензионному договору или
            дилерскому договору?
          </h4>

          <div className={s.experience}>
            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.experience,
                })}
                {...register("Есть ли у вас опыт работы по франшизе, лицензионному договору или дилерскому договору?")}
                id="experience10"
                type="radio"
                name="Есть ли у вас опыт работы по франшизе, лицензионному договору или дилерскому договору?"
                autoComplete="off"
                value="Нет"
              />

              <label htmlFor="experience10" className={s.label}>
                Нет
              </label>
            </div>

            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.experience,
                })}
                {...register("Есть ли у вас опыт работы по франшизе, лицензионному договору или дилерскому договору?")}
                id="experience11"
                type="radio"
                name="Есть ли у вас опыт работы по франшизе, лицензионному договору или дилерскому договору?"
                autoComplete="off"
                value="Да"
              />
              <label htmlFor="experience11" className={s.label}>
                Да
              </label>
            </div>

            <p className={s.errorText}>
              {error &&isSendForm && "Необходимо указать, или напиши свой вариант"}
            </p>

            <textarea
              {...register("Есть ли у вас опыт работы по франшизе, лицензионному договору или дилерскому договору? (Свой вариант)")}
              className={s.textarea}
              placeholder="Свой вариант"
              name="Есть ли у вас опыт работы по франшизе, лицензионному договору или дилерскому договору? (Свой вариант)"
            ></textarea>
          </div>
        </div>

        <div className={s.form_item}>
          <h4 className={s.form_title}>
            Есть ли на данный момент успешный (приносящий доход) собственный
            бизнес?
          </h4>

          <div className={s.experience}>
            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.experience,
                })}
                {...register("Есть ли на данный момент успешный (приносящий доход) собственный бизнес?")}
                id="experience12"
                type="radio"
                name="Есть ли на данный момент успешный (приносящий доход) собственный бизнес?"
                autoComplete="off"
                value="Нет"
              />

              <label htmlFor="experience12" className={s.label}>
                Нет
              </label>
            </div>

            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.experience,
                })}
                {...register("Есть ли на данный момент успешный (приносящий доход) собственный бизнес?")}
                id="experience13"
                type="radio"
                name="Есть ли на данный момент успешный (приносящий доход) собственный бизнес?"
                autoComplete="off"
                value="Да"
              />
              <label htmlFor="experience13" className={s.label}>
                Да
              </label>
            </div>

            <p className={s.errorText}>
              {error2 && isSendForm && "Необходимо указать, или напиши свой вариант"}
            </p>

            <textarea
              {...register("Есть ли на данный момент успешный (приносящий доход) собственный бизнес? (Свой вариант)")}
              className={s.textarea}
              placeholder="Свой вариант"
              name="Есть ли на данный момент успешный (приносящий доход) собственный бизнес? (Свой вариант)"
            ></textarea>
          </div>
        </div>

       

        <div style={{ marginTop: "88px" }} className={s.btns}>
          <Button callback={() => dispatch(setAddStep(3))} back>
            {
              width > 575 ? 'Назад' : <img src='/static/lk/IconBackward.svg' alt='назад' />
            }
          </Button>
          <Button>Далее 4 из 8</Button>
        </div>
      </Form>

      <img
        className={s.illustration}
        src="/static/lk/illustration/illustration.png"
        alt="Иллюстрация"
      />
    </div>
  );
};

export default Step4;
