import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectLKAuth } from '../../../../store/reducer/lk/authChech/selector';
import {
	setAddAuth,
	setAddLogOut,
	setAddUser,
} from '../../../../store/reducer/lk/authChech/slice.js';
import { selectLkBook } from '../../../../store/reducer/lk/book/selector.js';
import { selectLKSidebar } from '../../../../store/reducer/lk/sidebar/selector';
import {
	setAddActiveLink,
	setShowSidebar,
} from '../../../../store/reducer/lk/sidebar/slice.js';
import { setAddStep } from '../../../../store/reducer/lk/form/slice';
import Item from '../item/Item';
import s from './list.module.scss';
import { click } from '@testing-library/user-event/dist/click';
import { postCallMe } from '../../../../Api';


const List = ({ menuDefault }) => {
	const { user } = useSelector(selectLKAuth);
	const { activeLink } = useSelector(selectLKSidebar);
	const { InfoBook, book } = useSelector(selectLkBook);
	const [loadingBook, setLoadingBook] = useState(false);
	const [notice, setNotice] = useState(false);
	const [widthLine, setWidthLine] = useState(0);
	const [hidButton, setHidButton] = useState(false)

	console.log(activeLink)

	const menuActive = user?.user?.menu;
	const userCode = user?.user?.code;

	
	const menu = [];
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const status = InfoBook?.payment?.status;
	console.log(menuActive)

	for (let key in menuActive) {
		menu.push(menuActive[key]);
	}

	console.log(menuActive, menu)

	const showGraph = () => {
		dispatch(setAddActiveLink('graph'));
		dispatch(setShowSidebar(false));
		document.body.classList.remove('overlay');

		localStorage.setItem('activeLink', JSON.stringify('graph'));
		localStorage.setItem('showMobile', JSON.stringify(false));
	};

	const showCalendar = () => {
		dispatch(setAddActiveLink('calendar'));
		dispatch(setShowSidebar(false));
		document.body.classList.remove('overlay');

		localStorage.setItem('activeLink', JSON.stringify('calendar'));
		localStorage.setItem('showMobile', JSON.stringify(false));
	};

	const callMe = () => {
		postCallMe(userCode)
		.then(res => {setNotice(true); setHidButton(true); console.log(res)})
		.catch(err => console.log(err));
		
		setTimeout(()=> {
			setNotice(false);
			setWidthLine(0);
		}, 3000);

		setTimeout(() => {
			setWidthLine(100)}, 
			100)
	}
	

	const onClicLogOut = () => {
		dispatch(setAddLogOut(true));
		dispatch(setAddAuth(false));
		dispatch(setAddActiveLink(1));
		dispatch(setAddUser({}));
		dispatch(setShowSidebar(false));
		
		localStorage.removeItem('activeLink');
		localStorage.removeItem('stepLk');
		localStorage.removeItem('user');
		localStorage.removeItem('auth');
		localStorage.removeItem('showMobile');
		localStorage.removeItem('data');
		localStorage.removeItem('showMobileState');

		navigate('/enter');
	};
     console.log(menuDefault)
	return (
		<>
			<ul className={s.list}>
				{(!user?.user?.code || menuActive?.['1']?.status !== 'finished') &&
					menuDefault?.map((item, i) => (
						<Item
							key={item.id}
							index={i + 1}
							activeLink={activeLink}
							{...item}
						/>
					))}

				{user?.user?.code &&
					menuActive?.['1']?.status === 'finished' &&
					menu?.map((item, i) => (
						<Item
							key={item.name}
							index={i + 1}
							activeLink={activeLink}
							{...item}
						/>
					))}
			</ul>

			{user?.user?.code && (
				<div className={s.footer}>
					<div className={s.footer_btns}>
						{(status === 'paid' || user?.user?.is_book) && (
							<a
								href='https://skilla.ru/get-book/'
								target='_blank'
								className={cn(s.btn, s.btn_book, {
									[s.btn_active]: activeLink === 'book',
								})}
							>
								<div className={s.wrapper3}>
									<img src='/static/lk/founderForSidebar.png' alt='' />

									<div className={s.info_book}>
										<p>Геном предпринимателя</p>
										<p>Кирилл Упоров</p>
									</div>
								</div>
							</a>
						)}

						<button
							onClick={showGraph}
							className={cn(s.btn, {
								[s.btn_active]: activeLink === 'graph',
							})}
						>
							Показатели сети онлайн
						</button>

						<button
							onClick={showCalendar}
							className={cn(s.btn, {
								[s.btn_active]: activeLink === 'calendar',
							})}
						>
							Календарь событий
						</button>

						<button style={{display: hidButton ? 'none' : 'block'}}
							onClick={callMe}
							className={cn(s.btn, s.btn_call)}
						>
							Позвоните мне
						</button>

						<div style={{display: notice ? '' : 'none'}} className={cn(s.notice)}>
							<div style={{width: `${widthLine}%`}} className={cn(s.notice__line)}></div>
							Мы уже попросили сотрудника связаться с вами в самое ближайшее время
							<svg onClick={() => {setNotice(false)}} className={cn(s.btn_close)} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 12 12" fill="none">
                                <path d="M10 2.80571L9.19429 2L6 5.19429L2.80571 2L2 2.80571L5.19429 6L2 9.19429L2.80571 10L6 6.80571L9.19429 10L10 9.19429L6.80571 6L10 2.80571Z" fill="#BFCBE0"/>
                            </svg>
						</div>
					</div>

					<div className={s.log_out}>
						<div className={s.wrapper}>
							<div className={s.wrapper2}>
								<img
									className={s.img}
									src='/static/lk/log_out.png'
									alt='Изображение'
								/>
								<div>
									<div className={s.name}>{user?.user?.name}</div>
									<div className={s.prof}>Предприниматель</div>
								</div>
							</div>

							<button onClick={onClicLogOut} className={s.btn_log_out}>
								<img src='/static/lk/iconLogout.svg' alt='Выйти' />
							</button>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default List;
