import s from './Course.module.scss';
import { content, exam, htmlLegal } from './content';
import { ReactComponent as CheckMarks } from '../../../image/lk/checkMarks.svg';
import { ReactComponent as CheckMarks2 } from '../../../image/lk/checkMarks2.svg';
import { ReactComponent as IconChewronSmall } from '../../../image/iconChewronSmall.svg';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
//selector
import { selectLKAuth } from '../../../store/reducer/lk/authChech/selector';
import { selectLKPayment } from '../../../store/reducer/lk/payment/selector';
import { selectLKContract } from '../../../store/reducer/lk/contract/selector';
//slice
import { setAddActiveLink } from '../../../store/reducer/lk/sidebar/slice';
import { setIsCheckFromPage } from '../../../store/reducer/lk/contract/slice';
import { useDispatch } from 'react-redux';
import { getViewMenu, getBusinessIntroContent, sendBusinessIntroContent, sendBusinessIntroResult } from '../../../Api';
//utils
import { dayMonthFormat } from '../../../utils/date';

const Baner = ({ endDatePremium, width }) => {
    const { paymentInfo } = useSelector(selectLKPayment);
    const { persent } =
        useSelector(selectLKContract);
    const statusPayment = paymentInfo?.payment?.status;

    const dispatch = useDispatch();

    const toGoVipPage = () => {
        dispatch(setIsCheckFromPage(true));
        localStorage.setItem('isCheckFromPage', JSON.stringify(true));
        window.open('/premium', '_blank');
    };

    return (
        <>
            {statusPayment &&
                persent &&
                statusPayment !== 'prepaid' &&
                statusPayment !== 'paid' &&
                persent <= 4 &&
                endDatePremium &&
                (width > 575 ? (
                    <header onClick={toGoVipPage} className={s.baner}>
                        <img src='/static/lk/background.png' alt='Баннер' />

                        <div className={s.baner_btn}>Узнать больше</div>
                    </header>
                ) : (
                    <header className={s.baner}>
                        <div onClick={toGoVipPage}>
                            <img
                                style={{ width: '100%' }}
                                src='/static/lk/background-mobile.png'
                                alt='Баннер'
                            />
                        </div>
                    </header>
                ))}
        </>
    )
}

const MenuItem = ({ el, available, activeItem, setActiveItem }) => {

    const handleActiveItem = (e) => {
        const id = Number(e.currentTarget.id);
        setActiveItem(id)
        localStorage.setItem('activeItem', id);
        window.scroll(0, 0)
    }


    return (
        <li onClick={handleActiveItem} id={el.id} className={`${s.item} ${el.id > available && s.item_disabled} ${activeItem == el.id && s.item_active} ${available == el.id && s.item_available}`}>
            {available !== 0 && <div className={`${s.point} ${el.id > available && s.point_disabled} ${activeItem == el.id && s.point_active} ${available == el.id && s.point_available}`}>
                <div><CheckMarks /></div>
                <p>{el.name}</p>
            </div>}

            {available == 0 && <div className={`${s.point} ${s.point_start}`}>
                <div><CheckMarks2 /></div>
                <p>{el.name}</p>
            </div>}

            <span>{el.duration} мин</span>
        </li>
    )
}

const Content = ({ el, activeItem, available, answers, setAnswers, result }) => {
    return (
        <div className={`${s.content}`}>
            <div className={s.content_header}>
                <h3>{el.name}</h3>
                {activeItem == 8 && available == 9 && <span className={`${result > 20 && s.done}`}>{result} из 30 баллов</span>}
            </div>

            {activeItem == 8 && available == 9 && <span>
                {result > 20 && `Отличный результат! Теперь можешь приступить к изучению договора.`}
                {result <= 20 && `В свободное время посмотри уроки еще раз, а сейчас можешь приступать к изучению договора.`}
            </span>}
            {activeItem < 7 && <p>{el.text1}</p>}
            {activeItem < 7 && el.video !== '' && <div className={s.video}>
                <iframe
                    id="ytplayer"
                    type="text/html"
                    width="100%"
                    height="100%"
                    src={el.video}
                    frameborder="0"
                    allow="clipboard-write; autoplay"
                    webkitAllowFullScreen
                    mozallowfullscreen
                    allowFullScreen
                ></iframe>
            </div>}

            {activeItem == 7 && <div className={s.legal} dangerouslySetInnerHTML={{ __html: htmlLegal }}></div>}
            {activeItem == 8 && <Exam available={available} answers={answers} setAnswers={setAnswers} />}
        </div>
    )
}

const Exam = ({ available, answers, setAnswers }) => {


    return (
        <div className={s.exam}>
            {exam.map((el) => {
                return <Question el={el} answers={answers} setAnswers={setAnswers} available={available} />
            })}
        </div>
    )
};

const Question = ({ el, answers, setAnswers, available }) => {
    const [activePoint, setActivePoint] = useState(0);
    const [check, setCheck] = useState(false);
    const answer = el.answer;

    useEffect(() => {
        const result = answers.find(item => item.quest == el.id);
        setActivePoint(result?.answer ? result?.answer : 0)
    }, [answers, el])

    useEffect(() => {
        available == 9 ? setCheck(true) : setCheck(false);
        available == 9 && localStorage.setItem('answers', JSON.stringify(answers))
    }, [available])

    useEffect(() => {
        localStorage.setItem('answers', JSON.stringify(answers))
    }, [activePoint])

    const handleChosePoint = (e) => {
        const id = Number(e.currentTarget.id);
        setActivePoint(id);
        localStorage.setItem(`activePoint${el.id}`, id)
        const contain = answers.find(item => item.quest == el.id)

        if (contain) {
            contain.answer = id;
            contain.correct = id == answer ? 1 : 0;
        } else {
            setAnswers(prevState => [...prevState, { quest: el.id, answer: id, correct: id == answer ? 1 : 0 }])
        }

    }

    return (
        <div className={s.question}>
            <div className={s.question_header}>
                <h3 className={s.num}>{el.id}. </h3><h3>{el.name}</h3>
            </div>

            <div className={s.answers}>
                {el.options.map((el) => {
                    const id = el.id;

                    return <div onClick={handleChosePoint} id={el.id} className={`${s.answer} ${check && s.answer_disabed} ${(check && el.id == activePoint && activePoint == answer && s.answer_succes)} ${(check && el.id == activePoint && activePoint !== answer) && s.answer_fail}`}>
                        <div id={el.id} className={`${s.radio} ${el.id == activePoint && s.radio_active} ${el.id == activePoint && check && activePoint == answer && s.radio_succes} ${el.id == activePoint && check && activePoint != answer && s.radio_fail}`}>
                            <div></div>
                        </div>
                        <p>{el.text}</p>
                    </div>
                })}
            </div>

        </div>
    )
}

const Course = ({ endDatePremium }) => {
    const [width, setWidth] = useState(window?.innerWidth)
    const [activeItem, setActiveItem] = useState(Number(localStorage.getItem('activeItem')) || 0);
    const [available, setAvailable] = useState(Number(localStorage.getItem('available')) || 0);
    const [availableSendResult, setAvailableSendResult] = useState(false);
    const [answers, setAnswers] = useState(JSON.parse(localStorage.getItem('answers')) || []);
    const [result, setResult] = useState(0);
    const [dataReservation, setDataReservation] = useState('');
    const [mobileMenu, setMobileMenu] = useState(false);
    const { user } = useSelector(selectLKAuth)
    const menuActive = user?.user?.menu;
    const dispatch = useDispatch();
    console.log(activeItem, available)
    const modalRef = useRef();

    useEffect(() => {
        const anketaDate = menuActive[6].date_change;
        setDataReservation(dayMonthFormat(anketaDate, 3))
    }, [menuActive])

    useEffect(() => {
        const cb = () => {
            setWidth(window.innerWidth)
        }

        window.addEventListener('resize', cb)
        window.scroll(0, 0)

        return () => window.removeEventListener('resize', cb)
    }, [])

    console.log(activeItem)

    useEffect(() => {
        answers.length == 30 ? setAvailableSendResult(true) : setAvailableSendResult(false)
    }, [answers])

    useEffect(() => {
        let result = 0
        answers?.forEach((el) => {
            result += el.correct
        })
        setResult(result)
    }, [answers])

    useEffect(() => {
        getBusinessIntroContent(user?.user?.code)
            .then(res => {
                const data = res.data.data;
                console.log(res);
                const stepInProgress = data && data.length > 0 ? Number(data[data.length - 1].content_id) : Number(localStorage.getItem('activeItem'));
                setAvailable(stepInProgress)
                localStorage.setItem('available', stepInProgress);

                if (data[data.length - 1]?.content_id == 8 && data[data.length - 1].status == 'completed') {
                    console.log('результаты', JSON.parse(data[data.length - 1].answers))
                    setAnswers(JSON.parse(data[data.length - 1].answers))
                    setAvailable(9)
                    localStorage.setItem('answers', data[data.length - 1].answers)
                }

                if (activeItem == 0) {
                    setActiveItem(stepInProgress == 9 ? 8 : stepInProgress)
                    localStorage.setItem('activeItem', stepInProgress);
                }


            })
            .catch(err => console.log(err))
    }, [])


    useEffect(() => {
        document.addEventListener('mouseup', closeModal);

        return () => document.removeEventListener('mouseup', closeModal);
    }, []);

    const closeModal = (e) => {
        e.stopPropagation()
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            setMobileMenu(false);
        }
    }

    const handleNextStep = () => {
        activeItem < 8 && setActiveItem(prevState => Number(prevState) + 1);
        activeItem < 8 && localStorage.setItem('activeItem', activeItem + 1);
        available < 9 && available == activeItem && setAvailable(prevState => Number(prevState) + 1)
        available < 9 && available == activeItem && localStorage.setItem('available', available + 1);
        window.scroll(0, 0)
        activeItem == available && activeItem !== 8 && sendBusinessIntroContent(user?.user?.code, activeItem + 1, content[activeItem].name, 'in_progress')
        .then(res => {
            activeItem >= 1 && sendBusinessIntroContent(user?.user?.code, activeItem, content[activeItem - 1].name, 'completed')
        })
        .catch(err => console.log(err))

        if (activeItem == 8 && available == 8) {
            console.log('отправляем результат')
            sendBusinessIntroResult(user?.user?.code, 8 , 'Проверка знаний', 'completed', JSON.stringify(answers))
                .then(res => {
                    console.log(res)
                    checkViewMenu()
                })
                .catch(err => console.log(err))
        }

        if (available == 9 && activeItem == 8) {
            checkViewMenu()
            dispatch(setAddActiveLink(8));
            localStorage.setItem('activeLink', JSON.stringify(8));
        }
    }

    const handleBack = () => {
        activeItem <= 8 && setActiveItem(prevState => Number(prevState) - 1);
        activeItem <= 8 && localStorage.setItem('activeItem', activeItem - 1);
        available < 9 && activeItem == 1 && setAvailable(0)
        available < 9 && activeItem == 1 && localStorage.setItem('available', 0);
        window.scroll(0, 0)
    }

    const handleRestore = () => {
        localStorage.removeItem('available')
        localStorage.removeItem('activeItem')
        localStorage.removeItem('answers')
    }

    const handleOpenMenu = () => {
        mobileMenu ? setMobileMenu(false) : setMobileMenu(true)
    }

    const handleActiveItem = (e) => {
        const id = Number(e.currentTarget.id);
        setActiveItem(id)
        localStorage.setItem('activeItem', id)
        setMobileMenu(false);
        window.scroll(0, 0)
    }

    const checkViewMenu = async () => {
        try {
            const { data } = await getViewMenu(user?.user?.code, 12)
            console.log(data)
        } catch (e) {
            console.log(e)
        }
    }

    return (

        <>
            <Baner endDatePremium={endDatePremium} width={width} />

            <div className={s.course}>

                {((width < 768 && available == 0) || width > 768) && <div className={s.container}>
                    <div className={s.header}>
                        <div>
                            {/*   {available > 0 && available < 9 && <img src="/static/lk/course/iconArrow.svg" alt="Иконка" />} */}
                            {available == 9 && <img src="/static/lk/course/iconArrow2.svg" alt="Иконка" />}
                            <span className={available == 9 && s.done}>7. Введение в бизнес</span>
                        </div>
                        <p>Пройди вводный курс</p>
                    </div>

                    {available == 0 && <div className={s.sub}>
                        <p>
                            Обязательный бесплатный курс для кандидата в бизнес-партнеры, открывающего бизнес со Скилла Работа. Подписание договора, оплата партнерства возможна только после успешной проверки знаний.
                        </p>
                    </div>}

                    <div className={s.menu}>
                        <h3>Содержание</h3>

                        <ul className={s.list}>
                            {content.map(el => {
                                return <MenuItem key={el.id} el={el} activeItem={activeItem} available={available} setActiveItem={setActiveItem} />
                            })}

                        </ul>
                    </div>

                    {available == 0 && <div onClick={handleNextStep} className={`${s.button} ${s.button_start}`}>
                        <p>Начать обучение</p>
                    </div>}

                    {available == 0 && <div className={s.reserved}>
                        <p>Город забронирован до {dataReservation}</p>
                    </div>}
                </div>
                }

                {available == 0 && <img className={s.image} src="/static/lk/course/girl-book.jpg" alt="девушка с книгой" />}
                <div className={`${s.container} ${available == 0 && s.container_hidden} ${s.container_right}`}>

                    {width < 768 && <div className={s.header}>
                        <div>
                            {available == 9 && <img src="/static/lk/course/iconArrow2.svg" alt="Иконка" />}
                            <span className={available == 9 && s.done}>7. Введение в бизнес</span>
                        </div>
                        <p>Пройди вводный курс</p>

                        <div className={s.menu_mobile}>
                            <div className={s.menu_header} onClick={handleOpenMenu}>
                                <p className={`${s.text} ${s.text_active}`}>{content.find(el => el?.id == activeItem)?.name}</p>
                                <div className={`${s.arrow2} ${mobileMenu && s.arrow2_open}`}>
                                    <IconChewronSmall />
                                </div>

                            </div>
                            <ul ref={modalRef} className={`${s.menu_list} ${mobileMenu && s.menu_list_open}`}>
                                {content.map((el) => {
                                    return <li onClick={handleActiveItem} id={el.id}>
                                        <p className={`${s.text} ${activeItem == el.id && s.text_active}`}>{el.name}</p>
                                        {available > el.id && <div><CheckMarks /></div>}
                                    </li>
                                })}
                            </ul>
                        </div>
                    </div>
                    }

                    {content.map((el) => {
                        return activeItem == el.id && <Content el={el} activeItem={activeItem} available={available} answers={answers} setAnswers={setAnswers} result={result} />
                    })}

                    <div className={s.buttons}>
                        {width < 768 && activeItem !== 1 && !(available == 9 && activeItem == 8) && <button onClick={handleBack} className={s.button_back}>Назад</button>}
                        <button onClick={handleNextStep} disabled={!availableSendResult && activeItem == 8 && available !== 9 ? true : false} className={`${s.button}`}>
                            {activeItem !== 8 && `К следующему уроку`}
                            {activeItem == 8 && available !== 9 && `Проверить результаты`}
                            {available == 9 && activeItem == 8 && `Изучить договор`}
                        </button>
                    </div>


{/* 
                    <button onClick={handleRestore}>очистить</button> */}

                </div>
            </div>
        </>


    )
};

export default Course;