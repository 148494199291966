import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'
import Footer from '../../components/Footer'
import Navbar from '../../components/Navbar'
import { selectMain } from '../../store/reducer/main/selector.js'
import s from './book.module.scss'
import Buy from './components/buy/Buy'
import Cover from './components/cover/Cover'
import Form from './components/form/Form'
import Modal from './components/modal/Modal'

const Book = () => {
  const [width, setWidth] = useState(window?.innerWidth)
  const { pageBook } = useSelector(selectMain)

  useEffect(() => {
    const cb = () => {
      setWidth(window.innerWidth)
    }

    window.scroll(0, 0)
    window.addEventListener('resize', cb)

    return () => window.removeEventListener('resize', cb)
  }, [])

  return (
    <>
      <Helmet>
        <title>Геном предпринимателя</title>
      </Helmet>

      <div className={s.book}>
        {(pageBook ? pageBook === 'customer' : window.location.pathname.substring(1) === 'book') && (
          <Navbar page="customer" activePage="main" />
        )}
        {pageBook === 'business' && <Navbar page="business" activePage="main" />}

        {pageBook === 'worker' && <Navbar page="worker" activePage="main" />}

        <Modal />

        <div className={s.top}>
          <div className={s.container}>
            <div className={s.inner}>
              {width > 575 && <Cover />}
              <Buy />
            </div>
          </div>

          {width > 575 && pageBook === 'business' ? (
            <img className={s.lent} src="/static/book/lent.png" alt="" />
          ) : width > 575 &&
            (pageBook ? pageBook === 'customer' : window.location.pathname.substring(1) === 'book') ? (
            <img className={s.lent} src="/static/book/lent-customer.png" alt="" />
          ) : width > 575 && pageBook === 'worker' ? (
            <img className={s.lent} src="/static/book/lent-worker.png" alt="" />
          ) : (
            ''
          )}
        </div>

        <div className={s.container}>
          <div className={s.inner}>
            <Form />

            {width > 575 && <img className={s.img} src={'/static/book/cover-big.png'} alt="изображение" />}
          </div>
        </div>

        {pageBook === 'business' && <Footer page="business" type="faq" book />}
        {(pageBook ? pageBook === 'customer' : window.location.pathname.substring(1) === 'book') && (
          <Footer page="customer" type="faq" book />
        )}
        {pageBook === 'worker' && <Footer page="worker" type="faq" book />}
      </div>
    </>
  )
}
export default Book
