import { memo } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import './sliderArticle.scss';

const sliderArticle = memo(function SliderPartner({
  type,
  name,
  case_partner_photo,
  case_partner_slogan,
  case_client_image1,
  case_client_image2,
  case_client_image3,
  case_client_slogan,
  case_client_logo,
  service_icon,
  alias,
  key,
  showQuestion,
  activeTarget,
}) {

  if (type === 'partners') {
    return (
      <Link
        key={key}
        to={`/${alias}`}
        onClick={() => showQuestion(false)}
        className="slider__image article-slide-partner">
        <div className="article-slide-partner__inner">
          <div className="article-slide-partner__wrapper">
            <img className="article-slide-partner__img" src={case_partner_photo} alt={name} />
          </div>
          <p className=" article-slide-partner__descr">{case_partner_slogan}</p>
          <div className=" article-slide-partner__name">{name}</div>
        </div>
      </Link>
    );
  }

  if (type === 'clients') {
    return (
      <Link
        key={key}
        to={`/${alias}`}
        onClick={() => showQuestion(false)}
        className="slider__image article-slide-client">
        <div className="article-slide-client__inner">
          <img className="article-slide-client__logo" src={case_client_logo} alt={name} />
          <div className="article-slide-client__wrapper">
            <p className="article-slide-client__slogan">{case_client_slogan}</p>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6.34315 6.3424V8.33644L14.2415 8.34351L5.63604 16.949L7.05025 18.3632L15.6557 9.75773L15.6628 17.6561H17.6569V6.3424H6.34315Z"
                fill="#C7C9D1"
              />
            </svg>
          </div>
          <div className="slider__image article-slide article-slide-client__img">
            <img src={case_client_image1} alt={name} />
            <img src={case_client_image2} alt={name} />
            <img src={case_client_image3} alt={name} />
          </div>
        </div>
      </Link>
    );
  }

  if (type === 'services') {
    return (
      <Link
        key={key}
        to={`/${alias}`}
        onClick={() => showQuestion(false)}
        className="slider__image article-slide-services">
        <div className="slider__image article-slide">
          <h5
            className={classNames({
              'article-slide-services__title article-slide-services__title--customer':
                activeTarget === 'customer',
              'article-slide-services__title article-slide-services__title--worker':
                activeTarget === 'worker',
              'article-slide-services__title article-slide-services__title--business':
                activeTarget === 'business',
            })}></h5>
          <img src={service_icon} alt={name} />
        </div>
        <div className="article-slide-services__name">{name}</div>
      </Link>
    );
  }
});

export default sliderArticle;
