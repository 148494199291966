import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { getList, getClientsTabs } from '../Api'
import { setAddActiveTarget } from '../store/reducer/main/slice'
import ArticleBtns from './articleBtns/ArticleBtns'

const Portfolio = ({ type, page, className }) => {
  const [isMoreButtonClicked, setIsMoreButtonClicked] = useState(false)
  const [clientsContent, setClientsContent] = useState(null)
  const [categories, setCategories] = useState([])
  const [width, setWidth] = useState(window?.innerWidth)

  const dispatch = useDispatch()

  useEffect(() => {
    getList('', 'client_case')
      .then((response) => {
        if (response.data.results.length) setClientsContent(response.data.results)
      })
      .catch((e) => {
        console.log(e)
      })
  }, [])

  useEffect(() => {
    getClientsTabs()
      .then((response) => {
        if (response.data.results.length) setCategories(response.data.results)
      })
      .catch((e) => {
        console.log(e)
      })
  }, [])

  useEffect(() => {
    const cb = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', cb)
    window.scroll(0, 0)

    return () => window.removeEventListener('resize', cb)
  }, [])

  console.log('className', className)

  if (type === 'block')
    return (
      <StyledPortfolioBlock className={className ? `container ${className}` : 'container'}>
        {width > 768 ? (
          <h3 className="portfolio__title">
            Имеем <span className="portfolio__span">богатый опыт</span> с представителями разных сфер
          </h3>
        ) : (
          <h3 className="portfolio__title">
            Активно обслуживаем сетевые компании. <span className="portfolio__span">Наши заказчики</span> по
            всей России
          </h3>
        )}
        <p className="portfolio__text">
          Скилла сотрудничает с компаниями на постоянной основе, подбирая самозанятых на заказы
        </p>
        <div className="portfolio__grid">
          {clientsContent &&
            clientsContent.map((client, index) => {
              // Определите, нужно ли отображать клиента на основе условия
              const shouldShow = index < 4 || isMoreButtonClicked
              if (!shouldShow) return null

              return (
                <Link
                  key={client.alias}
                  onClick={() => dispatch(setAddActiveTarget('customer'))}
                  to={'/' + client.alias}
                >
                  <div className="portfolio-item">
                    <div className="portfolio-item__details">
                      <img
                        src={client.case_client_logo}
                        alt={client.case_client_name}
                        className="portfolio-item__logo"
                      />
                      <p className="portfolio-item__text">
                        {client.case_client_slogan}
                        <span className="arrow-right-top-big icon"></span>
                      </p>
                    </div>

                    <div className="portfolio-item__images">
                      {client.case_client_image1 && (
                        <img src={client.case_client_image1} alt="" className="portfolio-item__image" />
                      )}
                      {client.case_client_image2 && (
                        <img src={client.case_client_image2} alt="" className="portfolio-item__image" />
                      )}
                      {client.case_client_image3 && (
                        <img src={client.case_client_image3} alt="" className="portfolio-item__image" />
                      )}
                    </div>
                  </div>
                </Link>
              )
            })}
        </div>
        {!isMoreButtonClicked && (
          <button className="portfolio__more" onClick={() => setIsMoreButtonClicked(true)}>
            Показать еще
          </button>
        )}
      </StyledPortfolioBlock>
    )

  if (page === 'customerCases')
    return (
      <StyledForCustomerCases className={className ? `container ${className}` : 'container'}>
        <h2 className="portfolio__title">Кейсы заказчиков-компаний</h2>
        <ArticleBtns page={'customerCases'} categories={categories} />
        <div className="portfolio__grid">
          {clientsContent &&
            clientsContent.map((client) => (
              <Link
                key={client.alias}
                onClick={() => dispatch(setAddActiveTarget('worker'))}
                to={'/' + client.alias}
              >
                <div className="portfolio-item">
                  <div>
                    <img
                      src={client.case_client_logo}
                      alt={client.case_client_name}
                      className="portfolio-item__logo"
                    />
                    <p className="portfolio-item__text">
                      {client.case_client_slogan}
                      <span className="arrow-right-top-big icon"></span>
                    </p>
                  </div>

                  <div className="portfolio-item__images">
                    {client.case_client_image1 && (
                      <img src={client.case_client_image1} alt="" className="portfolio-item__image" />
                    )}
                    {client.case_client_image2 && (
                      <img src={client.case_client_image2} alt="" className="portfolio-item__image" />
                    )}
                    {client.case_client_image3 && (
                      <img src={client.case_client_image3} alt="" className="portfolio-item__image" />
                    )}
                  </div>
                </div>
              </Link>
            ))}
        </div>
      </StyledForCustomerCases>
    )

  return (
    <StyledPortfolio id="3.4.6" className="portfolio">
      <div className="container">
        <h3 className="portfolio__title">
          Имеем <span className="portfolio__span">богатый опыт</span> с представителями разных сфер
        </h3>

        <p className="portfolio__text">
          Более 50000 заказчиков размещают заказы на платформе Скилла ежедневно
        </p>

        <div className="portfolio__grid">
          {clientsContent &&
            clientsContent.map((client, index) => {
              // Определите, нужно ли отображать клиента на основе условия
              const shouldShow = index < 4 || isMoreButtonClicked
              if (!shouldShow) return null

              return (
                <Link
                  key={client.alias}
                  onClick={() => dispatch(setAddActiveTarget('customer'))}
                  to={'/' + client.alias}
                >
                  <div className="portfolio-item">
                    <div className="portfolio-item__details">
                      <img
                        src={client.case_client_logo}
                        alt={client.case_client_name}
                        className="portfolio-item__logo"
                      />
                      <p className="portfolio-item__text">
                        {client.case_client_slogan}
                        <span className="arrow-right-top-big icon"></span>
                      </p>
                    </div>

                    <div className="portfolio-item__images">
                      {client.case_client_image1 && (
                        <img src={client.case_client_image1} alt="" className="portfolio-item__image" />
                      )}
                      {client.case_client_image2 && (
                        <img src={client.case_client_image2} alt="" className="portfolio-item__image" />
                      )}
                      {client.case_client_image3 && (
                        <img src={client.case_client_image3} alt="" className="portfolio-item__image" />
                      )}
                    </div>
                  </div>
                </Link>
              )
            })}
        </div>

        {!isMoreButtonClicked && (
          <button className="portfolio__more" onClick={() => setIsMoreButtonClicked(true)}>
            Показать еще
          </button>
        )}
      </div>
    </StyledPortfolio>
  )
}

const StyledPortfolio = styled.section`
  margin: 135px 0 85px 0;
  color: #fff;

  .portfolio__title {
    font-size: 40px;
    line-height: 55px;
    font-weight: 800;
    margin-bottom: 15px;
    max-width: 925px;
  }

  .portfolio__text {
    font-size: 22px;
    line-height: 28px;
    color: #e3e4e8;
    max-width: 570px;
  }

  .portfolio__grid {
    margin: 55px auto 0; /* Центрирование по горизонтали */
    display: grid;
    grid-template-columns: repeat(2, 569px); /* Используйте repeat для повторения колонки */
    grid-gap: 24px;
    max-width: 1200px; /* Установите максимальную ширину для центрирования */
    width: 100%;

    @media (max-width: 1600px) {
      grid-template-columns: repeat(2, 439px);
    }

    & > a {
      text-decoration: none;
    }
  }

  .portfolio-item {
    cursor: pointer;
    border-radius: 12px;
    padding: 24px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #303550;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #4d5268;

      & .portfolio-item__text span {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1.34315 0.34118V2.33522L9.24153 2.34229L0.636039 10.9478L2.05025 12.362L10.6557 3.75651L10.6628 11.6549H12.6569V0.34118H1.34315Z' fill='white'/%3e%3c/svg%3e");
      }
    }
  }

  .portfolio-item__logo {
    display: block;
    margin-bottom: 16px;
  }

  .portfolio-item__text {
    font-size: 18px;
    line-height: 120%;
    color: #e3e4e8;
    display: flex;
    justify-content: space-between;
  }

  .portfolio-item__images {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 8px;
    margin-top: 32px;

    img {
      max-width: 168px;
      width: 100%;
      object-fit: cover;
    }
  }

  .portfolio__more {
    padding: 12.5px 45px;
    background-color: transparent;
    border: 1px solid #c7c9d1;
    color: #c7c9d1;
    font-size: 18px;
    line-height: 120%;
    font-weight: 600;
    font-family: inherit;
    cursor: pointer;
    border-radius: 4px;
    display: block;
    margin: 40px auto 0 auto;
    transition:
      background-color 0.3s ease,
      color 0.3s ease;

    &:hover {
      background-color: #fff;
      color: #000729;
    }
  }

  @media screen and (max-width: 1200px) {
    margin: 70px 0;

    .portfolio__title {
      font-size: 32px;
      margin-bottom: 12px;
      line-height: 120%;
    }

    .portfolio__text {
      font-size: 16px;
    }

    .portfolio__grid {
      grid-template-columns: 1fr 1fr;
      grid-gap: 15px;
      margin-top: 30px;
      /*margin-left: 30px;
      margin-right: 30px;*/
    }

    .portfolio-item {
      width: 100%;
      padding: 10px;
      border-radius: 5px;
    }

    .portfolio-item__text {
      font-size: 14px;

      & span {
        margin-left: 5px;
      }
    }

    .portfolio-item__logo {
      margin-bottom: 12px;
      height: 20px;
    }

    .portfolio-item__images {
      margin-top: 12px;
      grid-gap: 5px;
    }

    .portfolio-item__image {
      width: 100%;
    }

    .portfolio__more {
      width: 100%;
      margin-top: 15px;
      font-size: 14px;
      padding: 10px 25px;
    }
  }

  @media screen and (max-width: 768px) {
    margin: 70px 0;

    .portfolio__title {
      font-size: 32px;
      margin-bottom: 12px;
    }

    .portfolio__text {
      font-size: 16px;
    }

    .portfolio__grid {
      grid-template-columns: 1fr;
      grid-gap: 15px;
      margin-left: 0;
      margin-right: 0;
    }

    .portfolio-item {
      width: 315px;
      padding: 10px;
      border-radius: 5px;
    }

    .portfolio-item__text {
      font-size: 14px;

      & span {
        margin-left: 5px;
      }
    }

    .portfolio-item__logo {
      margin-bottom: 12px;
      height: 20px;
    }

    .portfolio-item__images {
      margin-top: 12px;
      grid-gap: 5px;
    }

    .portfolio-item__image {
      width: 100%;
    }

    .portfolio__more {
      width: 100%;
      margin-top: 15px;
      font-size: 14px;
      padding: 10px 25px;
    }
  }
`

const StyledPortfolioBlock = styled.div`
  color: #fff;

  .portfolio__title {
    font-size: 40px;
    line-height: 120%;
    font-weight: 800;
    margin-bottom: 15px;
    max-width: 925px;
  }

  .portfolio__text {
    font-size: 22px;
    line-height: 120%;
    color: #e3e4e8;
    max-width: 570px;
  }

  .portfolio__grid {
    margin: 55px auto 0; /* Центрирование по горизонтали */
    display: grid;
    grid-template-columns: repeat(2, 569px); /* Используйте repeat для повторения колонки */
    grid-gap: 24px;
    max-width: 1200px; /* Установите максимальную ширину для центрирования */
    width: 100%;

    @media (max-width: 1600px) {
      display: grid;
      grid-template-columns: repeat(2, 440px);
    }

    & > a {
      text-decoration: none;
      @media (max-width: 768px) {
        margin: 0 auto;
      }
    }
  }

  .portfolio-item {
    cursor: pointer;
    border-radius: 12px;
    padding: 24px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #303550;
    transition: background-color 0.3s ease;

    &__image {
      max-width: 168px;
      width: 100%;
      object-fit: cover;
    }

    &:hover {
      background-color: #4d5268;

      & .portfolio-item__text span {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1.34315 0.34118V2.33522L9.24153 2.34229L0.636039 10.9478L2.05025 12.362L10.6557 3.75651L10.6628 11.6549H12.6569V0.34118H1.34315Z' fill='white'/%3e%3c/svg%3e");
      }
    }
  }

  .portfolio-item__logo {
    display: block;
    margin-bottom: 16px;
  }

  .portfolio-item__text {
    font-size: 18px;
    line-height: 120%;
    color: #e3e4e8;
    display: flex;
    justify-content: space-between;
  }

  .portfolio-item__images {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 8px;
    margin-top: 32px;
  }

  .portfolio__more {
    padding: 12.5px 45px;
    background-color: transparent;
    border: 1px solid #c7c9d1;
    color: #c7c9d1;
    font-size: 18px;
    line-height: 120%;
    font-weight: 600;
    font-family: inherit;
    cursor: pointer;
    border-radius: 4px;
    display: block;
    margin: 40px auto 0 auto;
    transition:
      background-color 0.3s ease,
      color 0.3s ease;

    &:hover {
      background-color: #fff;
      color: #000729;
    }
  }

  @media screen and (max-width: 768px) {
    .portfolio__title {
      font-size: 32px;
      margin-bottom: 12px;
    }

    .portfolio__text {
      font-size: 16px;
    }

    .portfolio__grid {
      grid-template-columns: 1fr;
      grid-gap: 15px;
      margin-top: 30px;
    }

    .portfolio-item {
      width: 315px;
      padding: 10px;
      border-radius: 5px;
    }

    .portfolio-item__text {
      font-size: 14px;

      & span {
        margin-left: 5px;
      }
    }

    .portfolio-item__logo {
      margin-bottom: 12px;
      height: 20px;
    }

    .portfolio-item__images {
      margin-top: 12px;
      grid-gap: 5px;
    }

    .portfolio-item__image {
      width: 100%;
    }

    .portfolio__more {
      width: 100%;
      margin-top: 15px;
      font-size: 14px;
      padding: 10px 25px;
    }
  }
`

const StyledForCustomerCases = styled.section`
  color: #303550;

  .portfolio__title {
    font-size: 40px;
    line-height: 120%;
    font-weight: 800;
    margin-bottom: 24px;
    max-width: 925px;
  }

  .portfolio__text {
    font-size: 22px;
    line-height: 120%;
    color: #e3e4e8;
    max-width: 570px;
  }

  .portfolio__grid {
    margin-top: 55px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 24px;

    & > a {
      text-decoration: none;
    }

    @media (max-width: 1600px) {
      grid-template-columns: 1fr 1fr;
    }
  }

  .portfolio-item {
    max-width: 450px;
    width: 100%;
    cursor: pointer;
    border-radius: 12px;
    padding: 24px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    transition: all 0.2s ease;

    &:hover {
      box-shadow: 0px 2px 6px rgba(166, 166, 166, 0.25);

      .portfolio-item__text {
        color: #000;
      }

      & .portfolio-item__text span {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1.34315 0.34118V2.33522L9.24153 2.34229L0.636039 10.9478L2.05025 12.362L10.6557 3.75651L10.6628 11.6549H12.6569V0.34118H1.34315Z' fill='black'/%3e%3c/svg%3e");
      }
    }
  }

  .portfolio-item__logo {
    display: block;
    margin-bottom: 16px;
  }

  .portfolio-item__text {
    font-size: 18px;
    line-height: 120%;
    color: #303550;
    display: flex;
    justify-content: space-between;

    span {
      cursor: pointer;
      transition: all 0.2s ease;
    }
  }

  .portfolio-item__images {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 8px;
    margin-top: 32px;

    img {
      width: 129px;
      height: 96px;
    }
  }

  .portfolio__more {
    padding: 12.5px 45px;
    background-color: transparent;
    border: 1px solid #c7c9d1;
    color: #c7c9d1;
    font-size: 18px;
    line-height: 120%;
    font-weight: 600;
    font-family: inherit;
    cursor: pointer;
    border-radius: 4px;
    display: block;
    margin: 40px auto 0 auto;
    transition:
      background-color 0.3s ease,
      color 0.3s ease;

    &:hover {
      background-color: #fff;
      color: #000729;
    }
  }

  @media screen and (max-width: 768px) {
    .portfolio__title {
      font-size: 24px;
      margin-bottom: 12px;
    }

    .portfolio__text {
      font-size: 16px;
    }

    .portfolio__grid {
      grid-template-columns: 1fr;
      grid-gap: 15px;
      margin-top: 30px;
    }

    .portfolio-item {
      width: 343px;
      padding: 10px;
      border-radius: 5px;
    }

    .portfolio-item__text {
      font-size: 14px;

      & span {
        margin-left: 5px;
      }
    }

    .portfolio-item__logo {
      margin-bottom: 12px;
      height: 20px;
    }

    .portfolio-item__images {
      margin-top: 12px;
      grid-gap: 5px;
      overflow-x: auto;
    }

    .portfolio-item__image {
      width: 100%;
      margin-bottom: 12px;
    }

    .portfolio__more {
      width: 100%;
      margin-top: 15px;
      font-size: 14px;
      padding: 10px 25px;
    }
  }
`

export default Portfolio
