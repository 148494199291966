import { useEffect, useState } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import s from './cover.module.scss';

const Cover = () => {
	const [width, setWidth] = useState(window?.innerWidth);

	const settings = {
		className: 'book-slide',
		dotsClass: 'slick-dots',
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: true,
		arrows: false,
	};

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

	return (
		<div className={s.cover}>
			{width > 575 && (
				<img
					className={s.img}
					src='/static/lk/book/cover.jpg'
					alt='изображение'
				/>
			)}

			{width > 575 && (
				<div className={s.pages}>
					<img src='/static/lk/book/1.jpg' alt='изображение' />
					<img src='/static/lk/book/2.jpg' alt='изображение' />
					<img src='/static/lk/book/3.jpg' alt='изображение' />
				</div>
			)}

			{width <= 575 && (
				<StyledSlider>
					<Slider {...settings}>
						<img src='/static/lk/book/cover-mobile.jpg' alt='изображение' />
						<img src='/static/lk/book/1.jpg' alt='изображение' />
						<img src='/static/lk/book/2.jpg' alt='изображение' />
						<img src='/static/lk/book/3.jpg' alt='изображение' />
					</Slider>
				</StyledSlider>
			)}

			{/* {width > 575 && (
				<div className={s.logos}>
					<img src='/static/lk/book/logo.png' alt='логотип' />
					<img src='/static/lk/book/logo2.png' alt='логотип' />
					<img src='/static/lk/book/logo3.png' alt='логотип' />
				</div>
			)} */}
		</div>
	);
};

const StyledSlider = styled.div`
	.slick-dots {
		display: flex !important;
		justify-content: space-between;
		position: relative;
		grid-gap: 8px;
		margin-top: -30px;

		& li {
			background-color: #8e9aa8;
			display: block;
			width: 100%;
			height: 4px;
			border-radius: 0;
			margin: 0;

			&.slick-active {
				background-color: #3357ff;
			}

			& button {
				padding: 0;
				width: 100%;
				height: 100%;
				opacity: 0;
			}
		}
	}
`;

export default Cover;
