import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectLKSidebar } from '../../../store/reducer/lk/sidebar/selector';
import List from './list/List';
import s from './lkSidebar.module.scss';

const LkSidebar = () => {
	const { activeLink, showMobile } = useSelector(selectLKSidebar);
	const [width, setWidth] = useState(window?.innerWidth);
    
	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);
		window.scroll(0, 0);

		return () => window.removeEventListener('resize', cb);
	}, []);

	let vh = window.innerHeight * 0.01; // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
	document.documentElement.style.setProperty('--vh', `${vh}px`); // Then we set the value in the --vh custom property to the root of the document

	const menuDefault = [
		{ id: 1, name: 'Вход', status: 'active' },
		{ id: 2, name: 'Материалы', status: 'disabled' },
		{ id: 3, name: 'Моб. приложение', status: 'disabled' },
		{ id: 4, name: 'Бизнес-план', status: 'disabled' },
		{ id: 5, name: 'Zoom с экспертом', status: 'disabled' },
		{ id: 6, name: 'Анкета партнера', status: 'disabled' },
		{ id: 7, name: 'Введение в бизнес', status: 'disabled' },
		/* { id: 8, name: 'Ип', status: 'disabled' }, */
		{ id: 9, name: 'Договор-оферта', status: 'disabled' },
		{ id: 10, name: 'Обучение', status: 'disabled' },
		{ id: 11, name: 'Доступ в Skilla IS', status: 'disabled' },
		{ id: 12, name: 'Экзамен', status: 'disabled' },
	];

	return (
		<div
			className={cn(s.lkSidebar, {
				[s.hide]: width <= 770 && !showMobile,
			})}
		>
			<img className={s.logo} src='/static/lk/logo-skilla2.png' alt='Логотип' />

			<div className={s.container}>
				<List menuDefault={menuDefault} activeLink={activeLink} />
			</div>
		</div>
	);
};

export default LkSidebar;
