import cn from 'classnames'
import s from '../steps.module.scss'

import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'
import { setAddData, setAddStep } from '../../../../../store/reducer/lk/formExam/slice'

import { selectLkFormExam } from '../../../../../store/reducer/lk/formExam/selector'
import Button from '../../Button/Button'
import Form from '../../Form'

const Step6 = () => {
  const [width, setWidth] = useState(window?.innerWidth)
  const dispatch = useDispatch()
  const { data, step } = useSelector(selectLkFormExam)
  const [isError17, setError17] = useState(false)
  const [isSendForm, setIsSendForm] = useState(false)

  useEffect(() => {
    localStorage.setItem('stepLkExam', JSON.stringify(step))
    localStorage.setItem('dataExam', JSON.stringify(data))
  }, [])

  useEffect(() => {
    const cb = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', cb)

    return () => window.removeEventListener('resize', cb)
  }, [])

  const schema16 = yup
    .object({
      ['Какими качествами обладает истинный предприниматель?']: yup.string(),
      ['Необходимо ли Директору получить опыт на всех офисных позициях?']: yup.string(),
    })
    .required()

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm({
    resolver: yupResolver(schema16),
    defaultValues: {
      ['Какими качествами обладает истинный предприниматель?']:
        data['Какими качествами обладает истинный предприниматель?'],
      ['Что входит в функции и обязанности Директора?']:
        data['Что входит в функции и обязанности Директора?'],
      ['Необходимо ли Директору получить опыт на всех офисных позициях?']:
        data['Необходимо ли Директору получить опыт на всех офисных позициях?'],
    },
  })

  const ckbox17 = watch('Что входит в функции и обязанности Директора?')

  useEffect(() => {
    if (!ckbox17?.length) {
      setError17(true)
    } else {
      setError17(false)
    }
  }, [ckbox17])

  const onSubmit = (data) => {
    setIsSendForm(true)

    if (!isError17) {
      dispatch(setAddStep(7))
      dispatch(setAddData(data))
      setIsSendForm(false)

      localStorage.setItem('dataExam', JSON.stringify(data))
      localStorage.setItem('stepLkExam', JSON.stringify(6))
    }
  }

  return (
    <div className={s.step6}>
      <div className={s.wrapper_heading}>
        <h5>Роли. Директор. Скилла</h5>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={s.form_item}>
          <h4 className={s.form_title}>16. Какими качествами обладает истинный предприниматель?</h4>

          <div className={s.question16}>
            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question16,
                })}
                {...register('Какими качествами обладает истинный предприниматель?')}
                id="exam161"
                type="radio"
                name="Какими качествами обладает истинный предприниматель?"
                autoComplete="off"
                value="Не перекладывает ответственность на других: на близких, клиентов, рынок, сезонность, партнеров, сотрудников"
              />

              <label htmlFor="exam161" className={s.label}>
                Не перекладывает ответственность на других: на близких, клиентов, рынок, сезонность,
                партнеров, сотрудников
              </label>
            </div>

            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question16,
                })}
                {...register('Какими качествами обладает истинный предприниматель?')}
                id="exam162"
                type="radio"
                name="Какими качествами обладает истинный предприниматель?"
                autoComplete="off"
                value="Ставит четкие цели и добивается их реализации"
              />
              <label htmlFor="exam162" className={s.label}>
                Ставит четкие цели и добивается их реализации
              </label>
            </div>

            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question16,
                })}
                {...register('Какими качествами обладает истинный предприниматель?')}
                id="exam163"
                type="radio"
                name="Какими качествами обладает истинный предприниматель?"
                autoComplete="off"
                value="Не мыслит как наемный работник (не ждет когда что-то само произойдет, не работает по графику рабочего дня)"
              />
              <label htmlFor="exam163" className={s.label}>
                Не мыслит как наемный работник (не ждет когда что-то само произойдет, не работает по графику
                рабочего дня)
              </label>
            </div>

            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question16,
                })}
                {...register('Какими качествами обладает истинный предприниматель?')}
                id="exam164"
                type="radio"
                name="Какими качествами обладает истинный предприниматель?"
                autoComplete="off"
                value="Не думает, что он самый важный; без уважительного сотрудничества с другими людьми сложно достичь своих целей"
              />
              <label htmlFor="exam164" className={s.label}>
                Не думает, что он самый важный; без уважительного сотрудничества с другими людьми сложно
                достичь своих целей
              </label>
            </div>

            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question16,
                })}
                {...register('Какими качествами обладает истинный предприниматель?')}
                id="exam165"
                type="radio"
                name="Какими качествами обладает истинный предприниматель?"
                autoComplete="off"
                value="Признает, что может чего-то не знать и не уметь, но хочет всему научиться"
              />
              <label htmlFor="exam165" className={s.label}>
                Признает, что может чего-то не знать и не уметь, но хочет всему научиться
              </label>
            </div>

            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question13,
                })}
                {...register('Какими качествами обладает истинный предприниматель?')}
                id="exam166"
                type="radio"
                name="Какими качествами обладает истинный предприниматель?"
                autoComplete="off"
                value="Все варианты верны"
              />
              <label htmlFor="exam166" className={s.label}>
                Все варианты верны
              </label>
            </div>
          </div>

          <p className={s.errorText}>
            {errors['Какими качествами обладает истинный предприниматель?'] ? 'Необходимо указать' : ''}
          </p>
        </div>

        <div className={s.form_item}>
          <h4 className={s.form_title}>17. Что входит в функции и обязанности Директора?</h4>

          <div className={s.question17}>
            <div className={s.checkbox}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question17,
                })}
                {...register('Что входит в функции и обязанности Директора?')}
                id="exam171"
                type="checkbox"
                name="Что входит в функции и обязанности Директора?"
                autoComplete="off"
                value="Поддержание дружеских отношений с ключевыми клиентами"
              />
              <label htmlFor="exam171" className={s.label}>
                Поддержание дружеских отношений с ключевыми клиентами
              </label>
            </div>

            <div className={s.checkbox}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question17,
                })}
                {...register('Что входит в функции и обязанности Директора?')}
                id="exam172"
                type="checkbox"
                name="Что входит в функции и обязанности Директора?"
                autoComplete="off"
                value="Переложить обязанность вести ключевых клиентов на Оператора"
              />
              <label htmlFor="exam172" className={s.label}>
                Переложить обязанность вести ключевых клиентов на Оператора
              </label>
            </div>

            <div className={s.checkbox}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question17,
                })}
                {...register('Что входит в функции и обязанности Директора?')}
                id="exam173"
                type="checkbox"
                name="Что входит в функции и обязанности Директора?"
                autoComplete="off"
                value="Получение опыта на офисных позициях"
              />
              <label htmlFor="exam173" className={s.label}>
                Получение опыта на офисных позициях
              </label>
            </div>

            <div className={s.checkbox}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question17,
                })}
                {...register('Что входит в функции и обязанности Директора?')}
                id="exam174"
                type="checkbox"
                name="Что входит в функции и обязанности Директора?"
                autoComplete="off"
                value="Контроль офисных сотрудников"
              />
              <label htmlFor="exam174" className={s.label}>
                Контроль офисных сотрудников
              </label>
            </div>

            <div className={s.checkbox}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question17,
                })}
                {...register('Что входит в функции и обязанности Директора?')}
                id="exam175"
                type="checkbox"
                name="Что входит в функции и обязанности Директора?"
                autoComplete="off"
                value="Делегирование всех процессов офисным сотрудникам без дальнейшего вмешательства"
              />
              <label htmlFor="exam175" className={s.label}>
                Делегирование всех процессов офисным сотрудникам без дальнейшего вмешательства
              </label>
            </div>

            <div className={s.checkbox}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question17,
                })}
                {...register('Что входит в функции и обязанности Директора?')}
                id="exam176"
                type="checkbox"
                name="Что входит в функции и обязанности Директора?"
                autoComplete="off"
                value="Контроль показателей в СRМ системе"
              />
              <label htmlFor="exam176" className={s.label}>
                Контроль показателей в СRМ системе
              </label>
            </div>

            <div className={s.checkbox}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question17,
                })}
                {...register('Что входит в функции и обязанности Директора?')}
                id="exam177"
                type="checkbox"
                name="Что входит в функции и обязанности Директора?"
                autoComplete="off"
                value="Все показатели контролирует Skilla, если что-то будет не так, они мне сообщат"
              />
              <label htmlFor="exam177" className={s.label}>
                Все показатели контролирует Skilla, если что-то будет не так, они мне сообщат
              </label>
            </div>

            <div className={s.checkbox}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question17,
                })}
                {...register('Что входит в функции и обязанности Директора?')}
                id="exam178"
                type="checkbox"
                name="Что входит в функции и обязанности Директора?"
                autoComplete="off"
                value="Снятие денежных средств со счетов для выплаты з/п"
              />
              <label htmlFor="exam178" className={s.label}>
                Снятие денежных средств со счетов для выплаты з/п
              </label>
            </div>

            <div className={s.checkbox}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question17,
                })}
                {...register('Что входит в функции и обязанности Директора?')}
                id="exam179"
                type="checkbox"
                name="Что входит в функции и обязанности Директора?"
                autoComplete="off"
                value="Проверка на прочность нервных клеток бизнес-ассистентов"
              />
              <label htmlFor="exam179" className={s.label}>
                Проверка на прочность нервных клеток бизнес-ассистентов
              </label>
            </div>

            <p className={s.errorText2}>{isError17 && isSendForm && 'Необходимо указать'}</p>
          </div>
        </div>

        <div className={s.form_item}>
          <h4 className={s.form_title}>
            18. Необходимо ли Директору получить опыт на всех офисных позициях?
          </h4>

          <div className={s.question18}>
            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question18,
                })}
                {...register('Необходимо ли Директору получить опыт на всех офисных позициях?')}
                id="exam181"
                type="radio"
                name="Необходимо ли Директору получить опыт на всех офисных позициях?"
                autoComplete="off"
                value="Да, это позволит качественно контролировать сотрудников в будущем"
              />
              <label htmlFor="exam181" className={s.label}>
                Да, это позволит качественно контролировать сотрудников в будущем
              </label>
            </div>

            <div className={s.radio}>
              <input
                className={cn(s.input, {
                  [s.error]: errors.question18,
                })}
                {...register('Необходимо ли Директору получить опыт на всех офисных позициях?')}
                id="exam182"
                type="radio"
                name="Необходимо ли Директору получить опыт на всех офисных позициях?"
                autoComplete="off"
                value="Нет, в этом нет необходимости, офисные сотрудники могут приступить к работе с первого дня"
              />
              <label htmlFor="exam182" className={s.label}>
                Нет, в этом нет необходимости, офисные сотрудники могут приступить к работе с первого дня
              </label>
            </div>
          </div>

          <p className={s.errorText}>
            {errors['Необходимо ли Директору получить опыт на всех офисных позициях?']
              ? 'Необходимо указать'
              : ''}
          </p>
        </div>

        <div className={s.btns}>
          <Button callback={() => dispatch(setAddStep(5))} back>
            {width > 575 ? '   Назад' : <img src="/static/lk/IconBackward.svg" alt="назад" />}
          </Button>
          <Button>Далее 6 из 35</Button>
        </div>
      </Form>

      <img className={s.illustration} src="/static/lk/illustration/illustration.png" alt="Иллюстрация" />
    </div>
  )
}

export default Step6
