import cn from 'classnames';
import { memo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { getLkReqAccess, getLkАccessInformation } from '../../../../../Api';
import { setAddModalCode } from '../../../../../store/reducer/lk/access/slice';
import {setAddInformation} from '../../../../../store/reducer/lk/access/slice.js'
import ModalCode from '../../../training/components/modalCode/ModalCode';
import Button from '../../../ui/Button/Button';
import UlItem from '../../../ui/UlItem/UlItem';
import s from '../../access.module.scss';

const Success = memo(({ width, user, clientWithoutCours }) => {
	const dispatch = useDispatch();

	const getAccessInfo = async () => {
		const { data } = await getLkАccessInformation(user?.user?.code);

		dispatch(setAddInformation(data));
		localStorage.setItem('information', JSON.stringify(data));
	};


	const { handleSubmit } = useForm({});

	const onSubmit = () => {
		document.body.classList.add('overlay');

		const getСontractSign = async () => {
			try {
				const { data } = await getLkReqAccess(user?.user?.code);

				dispatch(setAddModalCode(true));
			} catch (e) {
				console.log(e);
			}
		};

		getСontractSign();
	};

	return (
		<div className={s.success}>
			<ModalCode page='access' getAccessInfo={getAccessInfo}/>
			<div className={cn(s.heading, s.success_heading)}>
				<div className={s.wrapper_heading}>
					<img src='/static/lk/iconTime.svg' alt='иконка' />
					<h5>{width > 575 ? `${clientWithoutCours ? 10 : 11}. Доступ в Skilla IS` : `Шаг ${clientWithoutCours ? 10 : 11}`}</h5>
				</div>

				{width > 575 ? (
					<h2>
						Добро пожаловать <br></br> в Клуб предпринимателей!
					</h2>
				) : (
					<h2>Добро пожаловать в Клуб предпринимателей!</h2>
				)}
			</div>

			<ul className={s.list}>
				<h4>Ура! Тебе уже доступно</h4>
				<UlItem>
					<div className={s.done}>
						<svg
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z'
								fill='#002CFB'
							/>
							<path d='M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z' fill='#002CFB' />
							<path
								d='M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z'
								fill='#002CFB'
							/>
						</svg>
					</div>

					<span>Вход в Skilla IS как предприниматель</span>
				</UlItem>

				<UlItem>
					<div className={s.done}>
						<svg
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z'
								fill='#002CFB'
							/>
							<path d='M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z' fill='#002CFB' />
							<path
								d='M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z'
								fill='#002CFB'
							/>
						</svg>
					</div>

					<span>Все модули Программного комплекса</span>
				</UlItem>

				<UlItem>
					<div className={s.done}>
						<svg
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z'
								fill='#002CFB'
							/>
							<path d='M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z' fill='#002CFB' />
							<path
								d='M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z'
								fill='#002CFB'
							/>
						</svg>
					</div>

					<span>База знаний и обучающие материалы</span>
				</UlItem>

				<UlItem>
					<div className={s.done}>
						<svg
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z'
								fill='#002CFB'
							/>
							<path d='M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z' fill='#002CFB' />
							<path
								d='M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z'
								fill='#002CFB'
							/>
						</svg>
					</div>

					<span>Куратор в лице бизнес-ассистента</span>
				</UlItem>

				<UlItem>
					<div className={s.done}>
						<svg
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z'
								fill='#002CFB'
							/>
							<path d='M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z' fill='#002CFB' />
							<path
								d='M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z'
								fill='#002CFB'
							/>
						</svg>
					</div>

					<span>Чат поддержки для любых вопросов</span>
				</UlItem>
			</ul>

			<form onSubmit={handleSubmit(onSubmit)}>
				<Button type='blue'>Подтвердить доступ в Skilla IS</Button>
			</form>

			<p className={s.footer_text}>
				После входа смени пароль в течение 24 часов!
			</p>

			<img
				className={s.illustration}
				src={
					width > 575
						? '/static/lk/illustration/access.png'
						: '/static/lk/illustration/access-mobile.png'
				}
				alt='иллюстрация'
			/>
		</div>
	);
});
export default Success;
