import classNames from 'classnames';

function HeaderPoliticApp({ activeTarget }) {
	return (
		<header
			className={classNames({
				'header header--customer': activeTarget === 'customer',
				'header header--worker': activeTarget === 'worker',
				'header header--business': activeTarget === 'business',
			})}
		>
			<div className='container'>
				<div className='header__content'>
					<h1 className='header__title'>
						Политика обработки и хранения персональных данных
					</h1>

					<p className='header__description'>
						<b>1. Общие положения</b>
						<br />
						<br />
						1.1. Настоящая Политика обработки персональных данных (далее –
						«Политика») разработана и применяется Индивидуальным
						предпринимателем Упоровым Кириллом Андреевичем, ОГРНИП
						316470400072765 (далее - «Оператор») в соответствии с Федеральным
						законом от 27.07.2006 г. № 152-ФЗ «О персональных данных».
						<br />
						<br />
						1.2. Основные термины, используемые в настоящей Политике в
						соответствии с положениями Федерального закона от 27.07.2006 г. №
						152-ФЗ «О персональных данных»:
						<br />
						<br />
						а) персональные данные – любая информация, относящаяся прямо или
						косвенно к определенному или определяемому физическому лицу
						(субъекту персональных данных, субъекту);
						<br />
						<br />
						б) оператор персональных данных (оператор)– юридическое лицо или ИП
						самостоятельно или совместно с другими лицами организующие и (или)
						осуществляющие обработку персональных данных, а также определяющие
						цели обработки персональных данных, состав персональных данных,
						подлежащих обработке, действия (операции), совершаемые с
						персональными данными;
						<br />
						<br />
						в) обработка персональных данных – любое действие (операция) или
						совокупность действий (операций) с персональными данными,
						совершаемых с использованием средств автоматизации или без их
						использования;
						<br />
						<br />
						г) автоматизированная обработка персональных данных – обработка
						персональных данных с помощью средств вычислительной техники; д)
						предоставление персональных данных – действия, направленные на
						раскрытие персональных данных определенному лицу или определенному
						кругу лиц;
						<br />
						<br />
						е) блокирование персональных данных – временное прекращение
						обработки персональных данных (за исключением случаев, если
						обработка необходима для уточнения персональных данных);
						<br />
						<br />
						ж) уничтожение персональных данных – действия, в результате которых
						становится невозможным восстановить содержание персональных данных в
						информационной системе персональных данных и (или) в результате
						которых уничтожаются материальные носители персональных данных;
						<br />
						<br />
						з) обезличивание персональных данных – действия, в результате
						которых становится невозможным без использования дополнительной
						информации определить принадлежность персональных данных конкретному
						субъекту персональных данных;
						<br />
						<br />
						и) информационная система персональных данных – совокупность
						содержащихся в базах данных персональных данных и обеспечивающих их
						обработку информационных технологий и технических средств.
						<br />
						<br />
						1.3. Настоящая Политика применяется в отношении всех персональных
						данных, которые могут быть получены от Субъекта персональных данных
						Оператором посредством регистрации, авторизации, оформления заказа
						Субъектом персональных данных посредством сайта https://skilla.ru/worker
						(далее – «Сайт») и/или мобильного приложения Оператора (далее -
						«Приложение»).
						<br />
						<br />
						Далее по тексту «Сайт» и «Приложение» при совместном упоминание
						именуются «Сервис».
						<br />
						<br />
						1.4. Настоящая Политика определяет порядок и условия осуществления
						обработки персональных данных Субъектов, передавших свои
						персональные данные для обработки Оператору с использованием и без
						использования средств автоматизации, устанавливает процедуры,
						направленные на предотвращение нарушений законодательства Российской
						Федерации, связанных с обработкой персональных данных.
						<br />
						<br />
						1.5. Политика разработана с целью обеспечения защиты прав Субъектов
						при обработке их персональных данных.
						<br />
						<br />
						1.6. Оператор осуществляет обработку следующих персональных данных
						Субъекта: фамилия, имя, отчество; номер телефона; адрес электронной
						почты; почтовый адрес; данные об оказанных и оказываемых Субъекту
						услугах, история заказов Субъекта, обращений Субъекта к Оператору
						посредством использования Сервиса и других.
						<br />
						<br />
						1.7. При использовании Сервиса Субъектом персональных данных
						Оператор обрабатывает также иные обезличенные данные, которые
						автоматически передаются в процессе использования Сервиса
						посредством установленного на техническом устройстве Субъекта
						программного обеспечения: сведения об используемом браузере (или
						иной программе, с помощью которой осуществляется доступ к Сервису);
						IP-адрес; данные файлов cookie.
						<br />
						<br />
						<b>2. Цели сбора персональных данных</b>
						<br />
						<br />
						2.1. Оператор осуществляет обработку персональных данных Субъектов
						путем ведения баз данных автоматизированным, механическим, ручным
						способами в целях:
						<br />
						<br />
						2.1.1. обработки заказов, запросов или других действий Субъекта,
						связанных с регистрацией, авторизацией на Сервисе, осуществлением
						заказов через Сервис;
						<br />
						<br />
						2.1.2. оповещения об изменении заказов, пользовательского соглашения
						об условиях оказания услуг, порядка оказания услуг, перечня
						проводимых Оператором маркетинговых мероприятий и других.
						<br />
						<br />
						2.1.3. в иных целях в случае, если соответствующие действия
						Оператора не противоречат действующему законодательству,
						деятельности Оператора, и на проведение указанной обработки получено
						согласие Субъекта персональных данных.
						<br />
						<br />
						2.2. Данные, указанные в п. 1.7. настоящей Политики, обрабатываются
						в целях осуществления аналитики Сервиса, принципов использования
						Сервиса, совершенствования функционирования Сервиса, решения
						технических проблем Сервиса, разработки новых продуктов, расширения
						услуг, выявления популярности мероприятий и определения
						эффективности рекламных кампаний; обеспечения безопасности и
						предотвращения мошенничества, предоставления эффективной клиентской
						поддержки.
						<br />
						<br />
						2.3. Обработка персональных данных Субъекта осуществляется
						Оператором с момента получения согласия Субъекта персональных
						данных. Согласие на обработку персональных данных может быть дано
						Субъектом персональных данных в любой форме, позволяющей подтвердить
						факт получения согласия, если иное не установлено федеральным
						законом: в письменной, устной или иной форме, предусмотренной
						действующим законодательством, в том числе посредством совершения
						Субъектом персональных данных конклюдентных действий (акцепта
						размещенной на Сервисе оферты – пользовательского соглашения об
						условиях доставки). В случае отсутствия согласия Субъекта
						персональных данных на обработку его персональных данных, такая
						обработка не осуществляется.
						<br />
						<br />
						2.4. Персональные данные Субъектов получаются Оператором:
						<br />
						<br />
						2.4.1. посредством личной передачи Субъектом персональных данных при
						внесении сведений в формы в электронном виде на Сервисе Оператора;
						<br />
						<br />
						2.4.2. посредством личной передачи Субъектом персональных данных
						посредством сообщения в устной форме по телефону в процессе
						оформления заказа;
						<br />
						<br />
						2.4.3. иными способами, не противоречащими законодательству
						Российской Федерации и требованиям международного законодательства о
						защите персональных данных.
						<br />
						<br />
						2.5. Согласие на обработку персональных данных считается
						предоставленным посредством совершения Субъектом персональных данных
						любого действия или совокупности следующих действий:
						<br />
						<br />
						2.5.1. оформления, взятия в работу заказа на Сервисе Оператора;
						<br />
						<br />
						2.5.2. отправки посредством смс кода (сообщения) персональных данных
						при авторизации/регистрации, оформления Заказа на Сервисе Оператора.
						<br />
						<br />
						3. Правовые основания обработки персональных данных
						<br />
						<br />
						3.1. Оператор обрабатывает персональные данные Субъекта на
						основании:
						<br />
						<br />
						3.1.1. Пользовательского соглашения, размещенного на Сервисе
						Оператора;
						<br />
						<br />
						3.1.2. Устава Оператора.
						<br />
						<br />
						3.2. Оператор обрабатывает персональные данные Субъекта только в
						случае их отправки Субъектом через соответствующие электронные
						формы, расположенные на Сервисе Оператора или переданные лично
						субъектом посредством телефонной связи при оформлении Заказа.
						Отправляя свои персональные данные Оператору, Субъект выражает свое
						согласие с настоящей Политикой.
						<br />
						<br />
						3.3. Оператор обрабатывает обезличенные данные о Пользователе в
						случае, если Пользователь разрешил это в настройках браузера
						(включено сохранение файлов «cookie» и использование технологии
						JavaScript) в соответствии с положениями настоящей Политики.
						<br />
						<br />
						4. Порядок и условия обработки персональных данных
						<br />
						<br />
						4.1. Оператор осуществляет обработку персональных данных посредством
						совершения любого действия (операции) или совокупности действий
						(операций), включая следующие: сбор; запись; систематизация;
						накопление; хранение; уточнение (обновление, изменение); извлечение;
						использование; передача; обезличивание; блокирование; удаление;
						уничтожение.
						<br />
						<br />
						4.2. В соответствии с настоящей Политикой Оператор может
						осуществлять обработку персональных данных самостоятельно, а также с
						привлечением третьих лиц, которые привлекаются по поручению
						Оператора и осуществляют обработку для выполнения указанных в
						настоящей Политики целей.
						<br />
						<br />
						4.3. В случае поручения обработки персональных данных третьему лицу,
						объем передаваемых третьему лицу для обработки персональных данных и
						количество используемых этим лицом способов обработки должны быть
						минимально необходимым и для выполнения им своих обязанностей перед
						Оператором. В отношении обработки персональных данных третьим лицом
						устанавливается обязанность такого лица соблюдать конфиденциальность
						персональных данных и обеспечивать безопасность персональных данных
						при их обработке.
						<br />
						<br />
						4.4. В процессе предоставления услуг, при осуществлении
						внутрихозяйственной деятельности Оператор использует
						автоматизированную, с применением средств вычислительной техники,
						так и неавтоматизированную, с применением бумажного
						документооборота, обработку персональных данных.
						<br />
						<br />
						4.5. В отношении персональных данных Субъекта сохраняется
						конфиденциальность, кроме случаев добровольного предоставления
						Субъектом своих персональных данных неограниченному кругу лиц. В
						данном случае Субъект соглашается с тем, что часть его персональных
						данных становится общедоступной.
						<br />
						<br />
						5. Сведения о реализуемых требованиях к защите персональных данных
						<br />
						<br />
						5.1. Оператор защищает персональные данные Субъекта в соответствии с
						требованиями, предъявляемыми к защите такого рода информации, и
						несет ответственность за использование безопасных методов защиты
						такой информации.
						<br />
						<br />
						5.2. Для защиты персональных данных Субъекта, обеспечения их
						надлежащего использования и предотвращения несанкционированного
						и/или случайного доступа к ним третьими лицами, Оператор применяет
						необходимые и достаточные технические и административные меры.
						Предоставляемые Субъектом персональные данные хранятся на серверах с
						ограниченным доступом, расположенных в охраняемых помещениях.
						<br />
						<br />
						5.3. В случае подтверждения факта неточности персональных данных или
						неправомерности их обработки, персональные данные подлежат их
						актуализации Оператором, а обработка должна быть прекращена,
						соответственно.
						<br />
						<br />
						5.4. Субъект может в любой момент отозвать свое согласие на
						обработку персональных данных при условии, что подобная процедура не
						нарушает требований законодательства Российской Федерации.
						<br />
						<br />
						5.5. Согласие Субъекта персональных данных считается полученным в
						установленном действующем законодательством и настоящей Политикой
						порядке и действует до момента направления Субъектом персональных
						данных соответствующего заявления (уведомления) о прекращении
						обработки персональных данных по юридическому адресу Оператора:
						195027, Санкт-Петербург, пр. Энергетиков, д.10, оф.416
						<br />
						<br />
						5.5.1. В случае отзыва Субъектом персональных данных согласия на
						обработку его персональных данных, Оператор должен прекратить их
						обработку или обеспечить прекращение такой обработки (если обработка
						осуществляется другим лицом, действующим по поручению Оператора) и в
						случае, если сохранение персональных данных более не требуется для
						целей их обработки, уничтожить персональные данные или обеспечить их
						уничтожение (если обработка персональных данных осуществляется
						третьим лицом, действующим по поручению Оператора) в срок, не
						превышающий 30 (Тридцати) дней с даты поступления указанного отзыва,
						если иное не предусмотрено договором, стороной которого,
						выгодоприобретателем или поручителем по которому является Субъект,
						иным соглашением между Оператором и Субъектом персональных данных,
						либо если Оператор не вправе осуществлять обработку персональных
						данных без согласия Субъекта персональных данных на основаниях,
						предусмотренных Федеральным законом № 152-ФЗ «О персональных данных»
						от
						<br />
						<br />
						27.07.2006 г. или другими федеральными законами.
						<br />
						<br />
						6. Согласие на получение рекламной информации
						<br />
						<br />
						6.1. Согласие Субъекта персональных данных на получение рекламной
						информации, подтверждается посредством:
						<br />
						<br />
						6.1.1. оформления заказа на Сервисе Оператора;
						<br />
						<br />
						6.1.2. проставления на Сервисе в соответствующей форме отметки о
						согласии на обработку персональных данных;
						<br />
						<br />
						6.1.3. сообщения персональных данных в устной форме, при обращении
						по телефону Оператора в процессе оформлении заказа, означает
						согласие Субъекта персональных данных на получение от Оператора и
						привлеченных Оператором третьих лиц, по сетям электросвязи (по
						предоставленным номеру мобильного телефона и адресу электронной
						почты) информационных сообщений, а в том числе информации
						коммерческого рекламного характера (рекламы).
						<br />
						<br />
						6.2. Давая согласие, указанное в п. 6.1. настоящей Политики, Субъект
						персональных данных подтверждает, что действует по своей воле и в
						своем интересе, а также то, что указанные персональные данные
						являются достоверными.
						<br />
						<br />
						7. Заключительные положения
						<br />
						<br />
						7.1. Пользователь может получить любые разъяснения по интересующим
						вопросам, касающимся обработки его персональных данных, обратившись
						к Оператору с помощью электронной почты support@skilla.ru, либо на
						почтовый адрес Оператора: 195027, Санкт-Петербург, пр. Энергетиков,
						д.10, оф.416
						<br />
						<br />
						7.2. В настоящем документе будут отражены любые изменения Политики
						обработки персональных данных Оператором.
						<br />
						<br />
						7.3. Настоящая Политика размещена по адресу:
						https://skilla.ru/worker/download/politic
					</p>
				</div>
			</div>
		</header>
	);
}

export default HeaderPoliticApp;
