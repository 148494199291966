import cn from 'classnames';
import s from '../steps.module.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import {
	setAddData,
	setAddStep,
} from '../../../../../store/reducer/lk/formExam/slice';

import { selectLkFormExam } from '../../../../../store/reducer/lk/formExam/selector';
import Button from '../../Button/Button';
import Form from '../../Form';

const Step23 = () => {
	const [width, setWidth] = useState(window?.innerWidth);
	const dispatch = useDispatch();
	const { data, step } = useSelector(selectLkFormExam);
	const [isSendForm, setIsSendForm] = useState(false);

	useEffect(() => {
		localStorage.setItem('stepLkExam', JSON.stringify(step));
		localStorage.setItem('dataExam', JSON.stringify(data));
	}, []);

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

	const schema = yup
		.object({
			['Нужно ли контролировать выход новичков на заказ?']: yup.string(),
            ['Нужно ли контролировать выезд исполнителей на заказ?']: yup.string(),
            ['Что исполнители должны забрать у клиента на безналичном заказе?']: yup.string(),
		})
		.required();

	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
		watch,
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			['Нужно ли контролировать выход новичков на заказ?']: data['Нужно ли контролировать выход новичков на заказ?'],
			['Нужно ли контролировать выезд исполнителей на заказ?']: data['Нужно ли контролировать выезд исполнителей на заказ?'],
			['Что исполнители должны забрать у клиента на безналичном заказе?']: data['Что исполнители должны забрать у клиента на безналичном заказе?'],
		},
	});


	const onSubmit = data => {
		setIsSendForm(true);

			dispatch(setAddStep(24));
			dispatch(setAddData(data));
			setIsSendForm(false);

			localStorage.setItem('dataExam', JSON.stringify(data));
			localStorage.setItem('stepLkExam', JSON.stringify(23));
		
	};

	return (
		<div className={s.step23}>
      <div className={s.wrapper_heading}>
			  <h5>Исполнители. Менеджер по персоналу</h5>
			</div>
			<Form onSubmit={handleSubmit(onSubmit)}>

				<div className={s.form_item}>
					<h4 className={s.form_title}>64. Нужно ли контролировать выход новичков на заказ?</h4>

					<div className={s.question64}>
                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question64,
								})}
								{...register('Нужно ли контролировать выход новичков на заказ?')}
								id='exam641'
								type='radio'
								name='Нужно ли контролировать выход новичков на заказ?'
								autoComplete='off'
								value='Обязательно позвонить за 2-3 часа до заказа'
							/>
							<label htmlFor='exam641' className={s.label}>
							Обязательно позвонить за 2-3 часа до заказа
							</label>
						</div>

						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question64,
								})}
								{...register('Нужно ли контролировать выход новичков на заказ?')}
								id='exam642'
								type='radio'
								name='Нужно ли контролировать выход новичков на заказ?'
								autoComplete='off'
								value='Не нужно, я им доверяю'
							/>
							<label htmlFor='exam642' className={s.label}>
							Не нужно, я им доверяю
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question64,
								})}
								{...register('Нужно ли контролировать выход новичков на заказ?')}
								id='exam643'
								type='radio'
								name='Нужно ли контролировать выход новичков на заказ?'
								autoComplete='off'
								value='Достаточно контролировать исполнителей,  нажавших на кнопку "Я на месте" в приложении'
							/>
							<label htmlFor='exam643' className={s.label}>
							Достаточно контролировать исполнителей,  нажавших на кнопку "Я на месте" в приложении
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question64,
								})}
								{...register('Нужно ли контролировать выход новичков на заказ?')}
								id='exam644'
								type='radio'
								name='Нужно ли контролировать выход новичков на заказ?'
								autoComplete='off'
								value='Я буду просить их "Отметиться" в приложении за 1-2 часа до начала заказа'
							/>
							<label htmlFor='exam644' className={s.label}>
							Я буду просить их "Отметиться" в приложении за 1-2 часа до начала заказа
							</label>
						</div>
					</div>

					<p className={s.errorText}>
						{errors['Нужно ли контролировать выход новичков на заказ?'] ? 'Необходимо указать' : ''}
					</p>
				</div>

                <div className={s.form_item}>
					<h4 className={s.form_title}>65. Нужно ли контролировать выезд исполнителей на заказ?</h4>

					<div className={s.question65}>
                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question65,
								})}
								{...register('Нужно ли контролировать выезд исполнителей на заказ?')}
								id='exam651'
								type='radio'
								name='Нужно ли контролировать выезд исполнителей на заказ?'
								autoComplete='off'
								value='Нет, старший смены будет отслеживать их по геолокации в приложении'/>
							<label htmlFor='exam651' className={s.label}>
							Нет, старший смены будет отслеживать их по геолокации в приложении
							</label>
						</div>

						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question65,
								})}
								{...register('Нужно ли контролировать выезд исполнителей на заказ?')}
								id='exam652'
								type='radio'
								name='Нужно ли контролировать выезд исполнителей на заказ?'
								autoComplete='off'
								value='Не нужно, я им доверяю'/>
							<label htmlFor='exam652' className={s.label}>
							Не нужно, я им доверяю
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question65,
								})}
								{...register('Нужно ли контролировать выезд исполнителей на заказ?')}
								id='exam653'
								type='radio'
								name='Нужно ли контролировать выезд исполнителей на заказ?'
								autoComplete='off'
								value='Достаточно контролировать исполнителей,  нажавших на кнопку "Я на месте" в приложении'/>
							<label htmlFor='exam653' className={s.label}>
							Достаточно контролировать исполнителей,  нажавших на кнопку "Я на месте" в приложении
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question65,
								})}
								{...register('Нужно ли контролировать выезд исполнителей на заказ?')}
								id='exam654'
								type='radio'
								name='Нужно ли контролировать выезд исполнителей на заказ?'
								autoComplete='off'
								value='Я буду звонить исполнителям за 1 час до заказа'/>
							<label htmlFor='exam654' className={s.label}>
							Я буду звонить исполнителям за 1 час до заказа
							</label>
						</div>
					</div>

					<p className={s.errorText}>
						{errors['Нужно ли контролировать выезд исполнителей на заказ?'] ? 'Необходимо указать' : ''}
					</p>
				</div>

                <div className={s.form_item}>
					<h4 className={s.form_title}>66. Что исполнители должны забрать у клиента на безналичном заказе?</h4>

					<div className={s.question66}>
                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question66,
								})}
								{...register('Что исполнители должны забрать у клиента на безналичном заказе?')}
								id='exam661'
								type='radio'
								name='Что исполнители должны забрать у клиента на безналичном заказе?'
								autoComplete='off'
								value='Ничего, отработали и ушли'
							/>
							<label htmlFor='exam661' className={s.label}>
							Ничего, отработали и ушли
							</label>
						</div>

						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question66,
								})}
								{...register('Что исполнители должны забрать у клиента на безналичном заказе?')}
								id='exam662'
								type='radio'
								name='Что исполнители должны забрать у клиента на безналичном заказе?'
								autoComplete='off'
								value='Лист учета рабочего времени'
							/>
							<label htmlFor='exam662' className={s.label}>
							Лист учета рабочего времени
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question66,
								})}
								{...register('Что исполнители должны забрать у клиента на безналичном заказе?')}
								id='exam663'
								type='radio'
								name='Что исполнители должны забрать у клиента на безналичном заказе?'
								autoComplete='off'
								value='Деньги'
							/>
							<label htmlFor='exam663' className={s.label}>
							Деньги
							</label>
						</div>
					</div>

					<p className={s.errorText}>
						{errors['Что исполнители должны забрать у клиента на безналичном заказе?'] ? 'Необходимо указать' : ''}
					</p>
				</div>

				<div className={s.btns}>
                    <Button callback={() => dispatch(setAddStep(22))} back>
						{width > 575 ? (
							'   Назад'
						) : (
							<img src='/static/lk/IconBackward.svg' alt='назад' />
						)}
					</Button>
					<Button>Далее 23 из 35</Button>
				</div>
			</Form>

			<img
				className={s.illustration}
				src='/static/lk/illustration/illustration.png'
				alt='Иллюстрация'
			/>
		</div>
	);
};

export default Step23;
