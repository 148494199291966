import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { setAddActiveLoadingPage } from '../../store/reducer/main/slice'

import Navbar from '../../components/Navbar'
import Portfolio from '../../components/Portfolio'
import Footer from '../../components/Footer'

import './customerСases.scss'
import { Lent } from '../../components/Lent/Lent'

function CustomerСases() {
  const [showDOM, setShowDOM] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    const anchors = window.location.hash.split('#')[1]

    if (anchors) {
      setShowDOM(true)
    }

    const cb = () => {
      let scrollTop = document.documentElement.scrollTop

      if (scrollTop > 0) {
        setShowDOM(true)
      }
    }

    document.addEventListener('scroll', cb)

    return () => document.removeEventListener('scroll', cb)
  }, [])

  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  useEffect(() => {
    dispatch(setAddActiveLoadingPage('worker'))
  }, [])

  const schemaData = {
    '@context': 'https://schema.org/',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Приложение для самозанятых',
        item: 'https://skilla.ru/worker',
      },
      {
        '@type': 'ListItem',
        position: 2,
        name: 'Подработка самозанятым',
        item: 'https://skilla.ru/worker/orders',
      },
      {
        '@type': 'ListItem',
        position: 3,
        name: 'Отзывы о Скилла Работа',
        item: 'https://skilla.ru/worker/performers/reviews',
      },
      {
        '@type': 'ListItem',
        position: 4,
        name: 'Заказчики',
        item: 'https://skilla.ru/worker/cases',
      },
      {
        '@type': 'ListItem',
        position: 5,
        name: 'Вопрос - ответ',
        item: 'https://skilla.ru/worker/faq',
      },
    ],
  }

  return (
    <>
      <Helmet>
        <title>Где исполнители могут найти работу? | Примеры заказов и виды работ</title>
        <meta name="title" content="Где исполнители могут найти работу? | Примеры заказов и виды работ" />
        <meta
          name="description"
          content="Узнайте, какие виды работ выполняют исполнители в Скилла Работа, от строительства до уборки. Изучите примеры заказов и узнайте, какие требования и условия действуют для самозанятых. Найдите свою нишу и начните зарабатывать уже сегодня!"
        />
        <meta
          name="keywords"
          content="какие есть работы, какие виды работ, какие требования работы, какие условия работыработа для самозанятых, работа какого года, работы выполняемые самозанятыми, строительство, ремонт, дизайн, услуги, заказы, исполнители, фриланс, заработок, платформа, регистрация, работа офлайн"
        />
        <meta name="robots" content="index, follow" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="Russian" />
        <script className="structured-data-list" type="application/ld+json">
          {JSON.stringify(schemaData)}
        </script>
        {/*Open Graph*/}
        <meta property="og:type" content="business.business" />
        <meta
          property="og:title"
          content="Где исполнители могут найти работу? | Примеры заказов и виды работ"
        />
        <meta
          property="og:description"
          content="Узнайте, какие виды работ выполняют исполнители в Скилла Работа, от строительства до уборки. Изучите примеры заказов и узнайте, какие требования и условия действуют для самозанятых. Найдите свою нишу и начните зарабатывать уже сегодня!"
        />
        <meta property="og:url" content="https://skilla.ru/worker/cases" />
        <meta property="og:image" content="https://skilla.ru/static/worker/header/illustration2.png" />
        <meta property="og:site_name" content="Скилла Работа" />
        <meta property="og:locale" content="ru_RU" />
      </Helmet>
      <Navbar page="worker" activePage="customerCases" />

      <StyledHeader>
        <header className="header">
          <div className="container">
            <div className="header__content">
              <h1 className="header__title">Взгляни на своих будущих заказчиков</h1>
              <p className="header__description">
                70% заказчиков — федеральные компании, предоставляющие хорошие условия для работы и достойную
                оплату.
              </p>

              <button className="header__button" onClick={() => navigate('/worker/download')}>
                Скачать приложение
                <span className="download icon"></span>
              </button>
            </div>
          </div>

          <img
            src="/static/worker/customer-cases/bg.png"
            alt="иллюстрация"
            className="header__image bg"
            style={{ height: '100vh' }}
          />

          <picture>
            <source srcSet="/static/worker/customer-cases/illustration.png" type="image/png" />
            <img
              src="/static/worker/customer-cases/illustration.png"
              alt="иллюстрация"
              className="header__image"
            />
          </picture>
        </header>
        <Lent page={'worker'} />
      </StyledHeader>

      <section className="customerCases-block">
        <Portfolio page="customerCases" />
      </section>

      {showDOM && <Footer page="worker" type="faq" />}
    </>
  )
}

const StyledHeader = styled.div`
  .header {
    background: radial-gradient(63.02% 48.11% at 50% 23.37%, #4c0f7b 0%, rgba(85, 20, 142, 0) 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(22.68% 53.3% at 96.51% -3.22%, #4c1279 0%, rgba(191, 119, 255, 0) 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(31.72% 67.58% at -2.76% 107.52%, #ffe9c0 0%, rgba(123, 34, 197, 0) 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(45.31% 97.19% at -0.62% 55.77%, #cc79ff 0%, rgba(123, 34, 197, 0) 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(70.6% 284.26% at 81.04% 82.79%, #2f0058 0%, #8800ff 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    position: relative;
    margin: auto;
    padding-bottom: 190px;
    //height: 100vh;

    /*&:before {
      content: url('/static/worker/lents/Lent-6.png');
      display: block;
      width: 100%;
      overflow: hidden;
      position: absolute;
      bottom: -45px;
      text-align: center;
      z-index: 3;

      @media (min-width: 1900px) {
        content: url('/static/worker/lents/Lent-6Big.png');
        width: 120%;
        bottom: -83px;
        left: -232px;
      }

      @media (max-width: 768px) {
        content: '';
        background: url('/static/worker/lents/Lent-6.png');
        height: 50px;
        width: 1200px;
        background-size: contain;
        bottom: -20px;
      }
    }

    &:after {
      content: url('/static/worker/lents/Lent-7.png');
      display: block;
      width: 100%;
      overflow: hidden;
      position: absolute;
      bottom: -75px;
      text-align: center;
      z-index: 1;
      @media (min-width: 1900px) {
        content: url('');
      }

      @media (max-width: 768px) {
        content: '';
        background: url('/static/worker/lents/Lent-7.png');
        height: 50px;
        width: 1200px;
        background-size: contain;
        bottom: -20px;
      }
    }*/


    @media (max-width: 768px) {
      height: auto;
      padding: 150px 0 330px;
    }
  }

    .header__image {
      position: absolute;
      right: 0;
      bottom: 0;
      //height: 90vh;
      right: -120px;

      @media (max-width: 768px) {
        height: 300px;
        right: 0;
      }
    }
  }

  .header__content {
    max-width: 853px;
    width: 100%;
    position: relative;
    padding-top: calc(50vh - 220px);

    @media (max-width: 1600px) {
      padding-top: 150px;
    }

    @media (max-width: 768px) {
      padding-top: 0;
    }
  }

  .header__qr-code {
    margin-bottom: 64px;
  }

  .header__title {
    max-width: 688px;
    width: 100%;
    font-size: 54px;
    line-height: 120%;
    font-weight: 800;
    color: #fff;
    margin-bottom: 25px;

    & span {
      display: block;
    }

    @media (max-width: 768px) {
      font-size: 36px;
    }
  }

  .header__description {
    font-size: 22px;
    line-height: 120%;
    font-weight: 500;
    max-width: 690px;
    color: rgb(227, 228, 232);
    margin-bottom: 98px;

    & span {
      display: block;
    }

    @media (max-width: 768px) {
      font-size: 18px;
      margin-bottom: 0;
    }
  }

  .header__button {
    cursor: pointer;
    padding: 0 24px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.35);
    border: 0;
    font-family: inherit;
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: 600;
    font-size: 20px;
    line-height: 48px;
    margin-top: 64px;
    transition: background-color 0.3s ease;
    font-family: inherit;
    &:hover {
      background-color: #8800ff;
    }
    & span {
      margin-left: 24px;
    }

    @media (max-width: 768px) {
      position: relative;
      z-index: 2;
    }
  }

  @media(max-width: 768px) {
    .bg {
      display: none;
    }
  }
  
`

export default CustomerСases
