import cn from 'classnames';
import s from '../steps.module.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import {
	setAddData,
	setAddStep,
} from '../../../../../store/reducer/lk/formExam/slice';

import { selectLkFormExam } from '../../../../../store/reducer/lk/formExam/selector';
import Button from '../../Button/Button';
import Form from '../../Form';

const Step16 = () => {
	const [width, setWidth] = useState(window?.innerWidth);
	const dispatch = useDispatch();
	const { data, step } = useSelector(selectLkFormExam);
	const [isSendForm, setIsSendForm] = useState(false);

	useEffect(() => {
		localStorage.setItem('stepLkExam', JSON.stringify(step));
		localStorage.setItem('dataExam', JSON.stringify(data));
	}, []);

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

	const schema44 = yup
		.object({
			['Норма показателя кликабельности (CTR)']: yup.string(),
            ['Норма конверсии клик-звонок']: yup.string(),
            ['Норма конверсии звонок-заказ']: yup.string(),
		})
		.required();

	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
		watch,
	} = useForm({
		resolver: yupResolver(schema44),
		defaultValues: {
			['Норма показателя кликабельности (CTR)']: data['Норма показателя кликабельности (CTR)'],
			['Норма конверсии клик-звонок']: data['Норма конверсии клик-звонок'],
			['Норма конверсии звонок-заказ']: data['Норма конверсии звонок-заказ'],
		},
	});

	const onSubmit = data => {
		dispatch(setAddStep(17));
		dispatch(setAddData(data));
		localStorage.setItem('dataExam', JSON.stringify(data));
		localStorage.setItem('stepLkExam', JSON.stringify(16));
		
	};

	return (
		<div className={s.step16}>
      <div className={s.wrapper_heading}>
			  <h5>Реклама</h5>
			</div>
			<Form onSubmit={handleSubmit(onSubmit)}>

				<div className={s.form_item}>
					<h4 className={s.form_title}>44. Норма показателя кликабельности (CTR)</h4>

					<div className={s.question44}>
						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question44,
								})}
								{...register('Норма показателя кликабельности (CTR)')}
								id='exam441'
								type='radio'
								name='Норма показателя кликабельности (CTR)'
								autoComplete='off'
								value='0,9%'
							/>

							<label htmlFor='exam441' className={s.label}>
							0,9%
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question44,
								})}
								{...register('Норма показателя кликабельности (CTR)')}
								id='exam442'
								type='radio'
								name='Норма показателя кликабельности (CTR)'
								autoComplete='off'
								value='50%'
							/>

							<label htmlFor='exam442' className={s.label}>
							50%
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question44,
								})}
								{...register('Норма показателя кликабельности (CTR)')}
								id='exam443'
								type='radio'
								name='Норма показателя кликабельности (CTR)'
								autoComplete='off'
								value='7%'
							/>

							<label htmlFor='exam443' className={s.label}>
							7%
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question44,
								})}
								{...register('Норма показателя кликабельности (CTR)')}
								id='exam444'
								type='radio'
								name='Норма показателя кликабельности (CTR)'
								autoComplete='off'
								value='10-12%'
							/>

							<label htmlFor='exam444' className={s.label}>
							10-12%
							</label>
						</div>
					</div>

					<p className={s.errorText}>
						{errors['Норма показателя кликабельности (CTR)'] ? 'Необходимо указать' : ''}
					</p>
				</div>

				<div className={s.form_item}>
					<h4 className={s.form_title}>45. Норма конверсии клик-звонок</h4>

					<div className={s.question45}>
                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question45,
								})}
								{...register('Норма конверсии клик-звонок')}
								id='exam451'
								type='radio'
								name='Норма конверсии клик-звонок'
								autoComplete='off'
								value='50-70%'
							/>
							<label htmlFor='exam451' className={s.label}>
							50-70%
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question45,
								})}
								{...register('Норма конверсии клик-звонок')}
								id='exam452'
								type='radio'
								name='Норма конверсии клик-звонок'
								autoComplete='off'
								value='25-40% (20-30% Мск и Спб)'
							/>
							<label htmlFor='exam452' className={s.label}>
							25-40% (20-30% Мск и Спб)
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question45,
								})}
								{...register('Норма конверсии клик-звонок')}
								id='exam453'
								type='radio'
								name='Норма конверсии клик-звонок'
								autoComplete='off'
								value='90%'
							/>
							<label htmlFor='exam453' className={s.label}>
							90%
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question45,
								})}
								{...register('Норма конверсии клик-звонок')}
								id='exam454'
								type='radio'
								name='Норма конверсии клик-звонок'
								autoComplete='off'
								value='20%'
							/>
							<label htmlFor='exam454' className={s.label}>
							20%
							</label>
						</div>
					</div>

					<p className={s.errorText}>
						{errors['Норма конверсии клик-звонок'] ? 'Необходимо указать' : ''}
					</p>
				</div>

                <div className={s.form_item}>
					<h4 className={s.form_title}>46. Норма конверсии звонок-заказ</h4>

					<div className={s.question46}>
                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question46,
								})}
								{...register('Норма конверсии звонок-заказ')}
								id='exam461'
								type='radio'
								name='Норма конверсии звонок-заказ'
								autoComplete='off'
								value='10-30%'/>
							<label htmlFor='exam461' className={s.label}>
							10-30%
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question46,
								})}
								{...register('Норма конверсии звонок-заказ')}
								id='exam462'
								type='radio'
								name='Норма конверсии звонок-заказ'
								autoComplete='off'
								value='100%'
							/>
							<label htmlFor='exam462' className={s.label}>
							100%
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question46,
								})}
								{...register('Норма конверсии звонок-заказ')}
								id='exam463'
								type='radio'
								name='Норма конверсии звонок-заказ'
								autoComplete='off'
								value='50-70%'
							/>
							<label htmlFor='exam463' className={s.label}>
							50-70%
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question46,
								})}
								{...register('Норма конверсии звонок-заказ')}
								id='exam464'
								type='radio'
								name='Норма конверсии звонок-заказ'
								autoComplete='off'
								value='20%'
							/>
							<label htmlFor='exam464' className={s.label}>
							20%
							</label>
						</div>
					</div>

					<p className={s.errorText}>
						{errors['Норма конверсии звонок-заказ'] ? 'Необходимо указать' : ''}
					</p>
				</div>

				<div className={s.btns}>
        <Button callback={() => dispatch(setAddStep(15))} back>
						{width > 575 ? (
							'   Назад'
						) : (
							<img src='/static/lk/IconBackward.svg' alt='назад' />
						)}
					</Button>
					<Button>Далее 16 из 35</Button>
				</div>
			</Form>

			<img
				className={s.illustration}
				src='/static/lk/illustration/illustration.png'
				alt='Иллюстрация'
			/>
		</div>
	);
};

export default Step16;
